import config from "config";
import { observable, when } from "mobx";
import oldAppStore from "stores/app";

const appStore = observable({
  isSidebarOpen: false,
  isConfigFetched: false,

  stripe: null,
  config: {
    stripeToken: null,
  },

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    if (this.isSidebarOpen) {
      document.getElementById("sidebar").focus();
    }
  },
});

(async () => {
  if (!config.loadStripe) return;
  await when(() => appStore.isConfigFetched);

  let { stripeToken } = appStore.config;
  if (!stripeToken) return;

  let stripeScript = document.createElement("script");
  stripeScript.src = config.stripeUrl;
  stripeScript.onload = () => {
    window.Stripe.setPublishableKey(stripeToken);
    appStore.stripe = window.Stripe;
  };

  document.head.appendChild(stripeScript);
})();

oldAppStore.onChange(() => {
  if (oldAppStore.get("isConfigFetched")) {
    appStore.config.stripeToken = oldAppStore.get("config").token;
    appStore.isConfigFetched = true;
  }
});

module.exports = appStore;
