/* global Exim, React, moment */
import authStore from "stores/new-auth";
import synopsisStore from "stores/new-synopsis";
import { observer } from "mobx-react";
import { Link } from "react-router";
import {
  outboundScreenersDescriptionMessage,
  completedScreenerHeaders,
  outstandingScreenerHeaders,
  columnClasses,
} from "config";
import Table from "components/elements/Table";
import ActionDropdown from "components/elements/ActionDropdown";
import NotesButton from "components/elements/NotesButton";
import screeningStore from "stores/screening";

class ListScreenings extends React.Component {
  static contextTypes = {
    router: React.PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.single = !this.context.router.isActive("/health-screenings", true);
  }

  getActionButton(type, screener) {
    const { router } = this.props;
    const gotoScreener = (id) => () => router.push(`/health-screenings/${id}`);
    const gotoCheckinBiometrics = () =>
      router.push("/checkin/screen/biometrics");
    const values = [
      { id: "1", name: "View", handler: gotoScreener(screener.id) },
      { id: "2", name: "Screen", handler: gotoCheckinBiometrics },
    ];
    const isOutstanding = type === "outstanding";
    const { isProxying, patientId } = authStore.user;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isOutstanding && (
          <Link
            to={`/health-screenings/${screener.id}`}
            className="Button Button--primary"
          >
            {screener.status === "In Progress" ? "Continue" : "Start"}
          </Link>
        )}
        {!isOutstanding && !isProxying && (
          <Link
            to={`/health-screenings/${screener.id}`}
            className="Button Button--secondaryAction"
          >
            View
          </Link>
        )}
        {!isOutstanding && isProxying && (
          <ActionDropdown values={values} selected="1" withGlobalClick />
        )}
        {isProxying && (
          <NotesButton
            notes={screener.outbound_screening_notes || []}
            addNote={(note) =>
              screeningStore.addNewNote(patientId, screener.id, note)
            }
            deleteNote={({ id }) =>
              screeningStore.deleteNote(patientId, screener.id, id)
            }
          />
        )}
      </div>
    );
  }

  addActionButtons(type, screeners) {
    screeners.forEach((screener) => {
      screener.action = this.getActionButton(type, screener);
    });
  }

  renderDescription() {
    return (
      <div className="Health-Screening--description">
        {outboundScreenersDescriptionMessage.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    );
  }

  render() {
    // temporarily hide this feature behind feature flag
    if (!synopsisStore.enableOutboundPros) {
      this.props.router.push("/landing");
      return null;
    }

    const { outstandingScreeners, completedScreeners } = screeningStore;
    this.addActionButtons("outstanding", outstandingScreeners);
    this.addActionButtons("completed", completedScreeners);

    return (
      <div className="ContentInner">
        {this.renderDescription()}
        <div className="Health-Screening--tables">
          <Table
            noDataMessage="No Outstanding Question Sets"
            data={outstandingScreeners}
            columnClasses={columnClasses}
            title="TO DO"
            headers={outstandingScreenerHeaders}
          />
          <Table
            noDataMessage="No Completed Question Sets"
            data={completedScreeners}
            columnClasses={columnClasses}
            title="COMPLETED"
            headers={completedScreenerHeaders}
          />
        </div>
      </div>
    );
  }
}

module.exports = observer(ListScreenings);
