"use strict";
const slotsStore = require("stores/new-slots");
const synopsisStore = require("stores/new-synopsis");
const { observable } = require("mobx");
const { MomentRange, computed, roundTo } = require("lib/utils");
const { min, max, unzipWith } = require("lodash");
const oooStore = require("stores/ooo");

const roundTime = (raw) => {
  let to = moment.duration(30, "minutes");
  let { site } = synopsisStore;

  return moment.tz(roundTo(raw, to), site.zone);
};

const DEFAULT_FILTER = {
  type: "ACTIVE_PROVIDERS",
};

const calStore = observable({
  MIN_IN_PX: 2,

  _date: null,
  _providerFilter: DEFAULT_FILTER,

  creating: null,
  moving: null,

  isNow() {
    let { site } = synopsisStore;
    return site.today.isSame(this.date, this.mode);
  },

  next() {
    this.date = this.date.clone().add(1, this.mode);
  },

  prev() {
    this.date = this.date.clone().subtract(1, this.mode);
  },

  now() {
    let { site } = synopsisStore;
    this.date = site.today;
  },

  async fetchSlots() {
    let params = {};
    switch (this.mode) {
      case "day":
        params.date = this.date;
        break;
      case "week":
        params.dateRange = this.dateRange;
        break;
    }

    let { type, value } = this.providerFilter;
    switch (type) {
      case "BY_PROVIDER_TYPE":
        params.providerType = value;
        break;
      case "BY_PROVIDER_ID":
        params.providerId = value;
        break;
    }

    await slotsStore.fetchSlots(params);
  },

  async fetchOOOs() {
    let params = (() => {
      let { type, value } = this.providerFilter;
      let { site } = synopsisStore;

      switch (type) {
        case "BY_PROVIDER_TYPE":
          let providers = site.getProvidersForType(value);
          return { provider_ids: providers.map((prov) => prov.id) };

        case "BY_PROVIDER_ID":
          return { provider_ids: [value] };
      }

      return { clinic_ids: [site.id] };
    })();

    await oooStore.fetch(params);
  },

  getHeight(dur) {
    return dur.asMinutes() * this.MIN_IN_PX;
  },

  changeProviderFilter(filter) {
    this._providerFilter = filter;
  },
});

Object.defineProperty(calStore, "date", {
  get() {
    return this._date || synopsisStore.site.today;
  },
  set(val) {
    this._date = val;
  },
  enumerable: true,
  configurable: true,
});

computed(calStore, {
  get mode() {
    return this.providerFilter.type == "BY_PROVIDER_ID" ? "week" : "day";
  },

  get dateRange() {
    let start = this.date.clone().startOf("week");
    let end = this.date.clone().endOf("week");

    return new MomentRange(start, end);
  },

  get displayRange() {
    let { site } = synopsisStore;
    let ranges = this.days.map((day) => site.getWorkingRange(day.date));

    let start = roundTime(min(ranges.map((r) => r.start))).subtract(1, "hour");
    let end = roundTime(max(ranges.map((r) => r.end))).add(1, "hour");

    return new MomentRange(start, end);
  },

  get providerFilter() {
    let { type, value } = this._providerFilter;
    let { site } = synopsisStore;

    switch (type) {
      case "BY_PROVIDER_TYPE":
        if (!site.hasProvidersForType(value)) return DEFAULT_FILTER;
        break;
      case "BY_PROVIDER_ID":
        if (!site.hasProvider(value)) return DEFAULT_FILTER;
        break;
    }

    return { type, value };
  },

  get _dates() {
    let { site } = synopsisStore;

    switch (this.mode) {
      case "day":
        return this._providers.map(() => this.date);
      case "week":
        return this.dateRange.by(1, "day");
    }
  },

  get _providers() {
    let isActive = (provider) => {
      return slotsStore.hasSlots(provider, this.date);
    };

    let { type, value } = this.providerFilter;
    let { site } = synopsisStore;

    switch (type) {
      case "ACTIVE_PROVIDERS":
        return site.providers.filter(isActive);
      case "ALL_PROVIDERS":
        return site.providers.filter((provider) => !provider.gone);
      case "BY_PROVIDER_TYPE":
        return site.getProvidersForType(value).filter(isActive);
      case "BY_PROVIDER_ID":
        let provider = site.getProvider(value);
        return this._dates.map(() => provider);
    }
  },

  get days() {
    let zipped = [this._providers, this._dates];
    let { site } = synopsisStore;

    return unzipWith(zipped, (provider, date) => {
      return { provider, date };
    }).filter((day) => site.isWorkingDay(day.date));
  },
});

module.exports = calStore;
