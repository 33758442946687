request = require 'lib/request'
config  = require 'config'
chStore = require 'stores/checkin'

module.exports = store = Exim.createStore
  actions: [
    'setup'
    'fetch'
    'save'
    'clear'
    'removePrevious'
    'fetchForms'
  ]

  initial:
    screen:
      meta:
        about: {}
      data: []
    patient_id: null
    appointment_id: null
    appointment_at: null
    additional_data: {}
    forms: {
      steps: {}
      firstStep: ''
    }

  setup:
    on: (patient_id, appointment_id, appointment_at, additional_data) ->
      @additional_data = additional_data
      @data =
        patient_id: patient_id
        appointment_id: appointment_id or @get('appointment_id')
        appointment_at: appointment_at or @get('appointment_at')
      chStore.actions.changeState('screen', @data)
    did: ->
      @set @data
      @set { @additional_data } if @additional_data

  fetchForms:
    while: (formsLoading) ->
      @set {formsLoading}
    on: ->
      request.get('forms/screen')
    did: (forms) ->
      steps = forms.steps
      firstStep = null
      Object.keys(steps).forEach (key, index) ->
        item = steps[key]
        firstStep = item.name.toLowerCase() if index is 0
        item.list = item.sections.reduce (res, sect) ->
          res.concat sect.list
        , []
      forms.firstStep = firstStep
      @set {forms}

  fetch:
    on: (patient_id, type) ->
      request.get('patient/screen', {patient_id,type})
    did: (screen) ->
      @set {screen}

  save:
    while: (screenSaving) ->
      @set {screenSaving}
    on: (type, data) ->
      url = "patient/screen?#{request.serialize({type})}"
      request.post(url, data)
    did: (data) ->
      screen = @get 'screen'
      screen.data = data
      @set {screen}

  removePrevious:
    on: (metric) ->
      @metricId = metric.metric
      request.del("patient/screen/#{metric.id}")
    did: (resp) ->
      metricId = @metricId
      screen = @get('screen')
      screen.data = if resp.id
        screen.data.map (item) ->
          if item.metric is metricId
            resp
          else
            item
      else
        screen.data.filter (item) ->
          item.id isnt id
      @set 'screen', screen

  clear:
    on: ->
      @data =
        patient_id: null,
        appointment_id: 999999,
        appointment_at: new Date()
        additional_data: {}
      chStore.actions.changeState('list')
    did: ->
      @set @data
