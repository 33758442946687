{div, h4, p, button, ul, li, tch, strong} = Exim.DOM

EnableAppointmentRemindersModal = Exim.createView module.id,
  render: ->
    div className: 'DependentSignUpModal--content',
      h4 style: fontWeight: 'normal', 'Yes! Please text me appointment reminders or changes to my visit.'

      p {},
        strong {}, 'Your number will be kept private.'

      p null,
        'Text messages will be sent within 5 hours of your appointment, excluding night-time hours.'
      p null,
        'You can disable texting in your portal profile'
      p null,
        strong {}, 'Terms of Service: '
        'By entering your phone number, you acknowledge that you agree to the terms of service and '
        'are subscribe to receive text message appointment reminders until you send STOP to this subscription service. '
        'You confirm that you hold the account responding to the mobile phone number you have entered, '
        'or that you have the account holder\'s permission to use this service'
      p null,
        'Please note text messages may not be compatible with all handsets. '
        'Subscription enables you to receive appointment reminders. '
        'Standard Message & Data Rates may apply. '
        'To cancel, go to your profile and unsubscribe in the contact section.'

      div className: 'ModalFooterButtons Buttons',
        tch tagName: 'button', className: "Button Button--secondaryAction Button--expand", handler: (=> @props.response(false)), 'Decline'
        tch tagName: 'button', className: "Button Button--primary Button--expand", handler: (=> @props.response(true)), 'Agree'

module.exports = EnableAppointmentRemindersModal
