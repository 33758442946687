"use strict";
const { appStore } = require("stores");
const authStore = require("stores/new-auth");
const wellnessStore = require("stores/wellness").default;
const { observer } = require("mobx-react");
const ContentHeader = require("components/elements/content-header");
const Main = require("components/elements/main");
const Footer = require("components/app/Footer");
const { showModal } = require("components/elements/new-modal");
const ConfirmSurveyExit = require("components/modals/ConfirmSurveyExit");
const ConsentConfirmation = require("components/modals/ConsentConfirmation");
const ScreeningInteraction =
  require("components/screenings/ScreeningInteraction").default;

const getTemplateSurvey = (data = {}) => ({
  tmpl: data.screening_template,
  values:
    data.patient_screening_data && JSON.parse(data.patient_screening_data),
  completed: data.patient_screening_status === "complete" ? "display" : "edit",
});

class HRASurvey extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.location.query;
    this.state = { id, template: null, canLeave: true, showConsent: true };

    this.handleExit = this.handleExit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleGoTo = this.handleGoTo.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleCompleted = this.handleCompleted.bind(this);
    this.handleSaveConsent = this.handleSaveConsent(this);
    this.handleValueChanged = this.handleValueChanged.bind(this);
  }

  componentWillMount() {
    appStore.actions.setHeader(null);
  }

  async componentDidMount() {
    await wellnessStore.fetchProgram(this.state.id);

    this.props.router.setRouteLeaveHook(this.props.route, this.handleExit);

    this.setState({
      showConsent: !wellnessStore.program.consented,
      template: getTemplateSurvey(wellnessStore.program),
    });
  }

  handleValueChanged({ data, mode }) {
    this.setState({
      canLeave: false,
      template: { ...this.state.template, values: data, completed: mode },
    });
  }

  handleGoBack() {
    this.props.router.goBack();
  }

  handleGoTo(pathname = "/wellness/overview") {
    this.props.router.push(pathname);
  }

  handleCompleted() {
    return async () => {
      const { id: userId, isAdmin } = authStore.user;
      const requestData = { ...this.state.template, completed: "display" };
      await wellnessStore.saveProgram(this.state.id, requestData);
      await wellnessStore.getUpdatedPrograms(userId, isAdmin);
      this.setState({ canLeave: true }, () =>
        this.handleGoTo(`/wellness/result?id=${this.state.id}`)
      );
    };
  }

  handleSave(pathname) {
    return async () => {
      const { id: userId, isAdmin } = authStore.user;
      await wellnessStore.saveProgram(this.state.id, this.state.template);
      await wellnessStore.getUpdatedPrograms(userId, isAdmin);
      this.setState({ canLeave: true });
      this.handleGoTo(pathname);
    };
  }

  handleSaveConsent() {
    return async () => {
      const program = await wellnessStore.saveConsent(this.state.id);

      this.setState({
        showConsent: !program.consented,
        template: getTemplateSurvey(program),
      });
    };
  }

  handleExit(nextLocation) {
    if (this.state.canLeave || this.state.template.completed === "display")
      return true;

    showModal(
      <ConfirmSurveyExit onExit={this.handleSave(nextLocation.pathname)} />
    );
    return false;
  }

  isIE() {
    const ua = navigator.userAgent;
    const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
  }

  render() {
    if (!this.state.template) {
      return <div className="NewContent" />;
    }

    if (this.state.showConsent) {
      return (
        <ConsentConfirmation
          onClose={this.handleGoTo}
          onContinue={this.handleSaveConsent}
          consentText={wellnessStore.program.consent}
        />
      );
    }

    const isIE = this.isIE();

    return (
      <div className="NewContent">
        <ContentHeader title="Wellness Survey">
          {this.state.template.completed === "display" ? (
            <button
              onClick={this.handleGoBack}
              className="Button WellnessView-button-survey"
            >
              BACK
            </button>
          ) : (
            <button
              onClick={this.handleGoBack}
              className="Button WellnessView-button-survey"
            >
              EXIT SURVEY
            </button>
          )}
        </ContentHeader>
        <Main className="Main--wellness Wellness-Survey--wrap">
          {isIE && (
            <p className="IsIE">
              The Health Risk Assessment survey experience is optimized when it
              is taken on one of the recommended browsers instead of Internet
              Explorer. We recommend you click on the <strong>Browsers</strong>{" "}
              link to download the browser you prefer and launch the survey link
              via that browser to prevent issues related to Internet Explorer.{" "}
              <br />
              <a href="http://browsehappy.com/" target="_blank">
                {" "}
                Browsers{" "}
              </a>
            </p>
          )}
          <ScreeningInteraction
            onCompleted={this.handleCompleted()}
            onValueChanged={this.handleValueChanged}
            template={this.state.template}
          />
        </Main>
        {Footer()}
      </div>
    );
  }
}

module.exports = observer(HRASurvey);
