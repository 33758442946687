{cx} = Exim.helpers
{input, label, div} = Exim.DOM

Checkbox = Exim.createView module.id,
  propTypes:
    checked: React.PropTypes.bool
    className: React.PropTypes.string
    onChange: React.PropTypes.func

  getDefaultProps: ->
    className: ''
    checked: false

  focus: ->
    @refs.input?.focus()

  componentWillUpdate: (nextProps, nextState) ->
    if nextProps.checked isnt @props.checked
      nextState.checked = nextProps.checked

  getInitialState: ->
    checked: @props.checked

  onChange: (e) ->
    return unless e.which is 32 or e.which is 13 or e.button is 0
    e.preventDefault()
    e.stopPropagation()
    @refs.input.checked = !@refs.input.checked
    {checked} = @refs.input
    @setState {checked}
    @props.onChange?(checked)

  render: ->
    {className, size, style, isDisabled, 'aria-labelledby': ariaLabelledBy} = @props
    {checked} = @state
    type = 'checkbox'
    className += ' Checkbox--small' if size is 'small'
    checkboxInputId = if ariaLabelledBy then ariaLabelledBy else ''
    tabIndexValue = if !!isDisabled then null else 0

    label className: "Checkbox #{className}", style: style, htmlFor: "#{checkboxInputId}-checkbox-input",
      input {ref: 'input', type, className: 'Checkbox-input', checked, tabIndex: -1, 'aria-hidden': true, id: "#{checkboxInputId}-checkbox-input", disabled: !!isDisabled}
      div 'role': 'checkbox', className: 'Checkbox-rect', tabIndex: tabIndexValue, onKeyDown: @onChange, onClick: @onChange, 'aria-checked': checked, 'aria-labelledby': ariaLabelledBy

module.exports = Checkbox
