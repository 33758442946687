
utils       = require 'lib/utils'
{isCordova} = require 'config'

{cx} = utils

{h3, tch, fa, span, img, div} = Exim.DOM

IdentityProvider = Exim.createView module.id,
  listen: [
    'auth/oAuthProviderLinks'
  ]

  oAuthSignIn: (uri) -> (evt) =>
    return unless isCordova

    evt.preventDefault()
    utils.openOAuthProvider(uri)

  render: ->

    platform = window.device?.platform?.toLowerCase()

    templateParams =
      next: 'signup'

    if platform
      templateParams.platform = platform

    div className: 'SignUpAccount--identity-provider',
      h3 className: 'SignUpAccount--PaneTitle', 'Activate with your Employer Credentials'
      div className: 'OAuth--section', style: {flexGrow: 2, padding: '10px'},
        div className: 'OAuth--section--links',
          @state.oAuthProviderLinks?.map (prov) =>
            tch tagName: 'a', key: prov.key, href: utils.expandUriTemplate(prov.href, templateParams), className: cx('OAuthLink', {alt: prov.alt_display}), 'aria-label': "Sign In #{if prov.label then 'with ' + prov.label else ''} button", handler: @oAuthSignIn(utils.expandUriTemplate(prov.href, templateParams)),
              if prov.icon_name
                fa prov.icon_name
              else if prov.logo_url
                img className: cx('OAuthLink-logo', {alt: prov.alt_display}), alt: "#{prov.label} Logo", src: prov.logo_url, role: 'presentation', width: 'auto', height: '100%'
              span className: 'OAuthLink-label', prov.label


module.exports = IdentityProvider
