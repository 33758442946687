request = require 'lib/request'

module.exports = store = Exim.createStore
  path: 'patient'
  actions: [
    'fetch'
    'updateCoverage'
    'update'
    'changePassword'
    'resetPassword'
    'sendInvite'
    'sendVerification'
    'unrestrictAccount'
    'clearAccount'
    'fetchGraph'
    'fetchNotes'
    'clearNotes'
    'fetchPCPs'
    'toggleTextUpdates'
    'inactivate'
    'reactivate'
    'fetchLoginStats'
    'forcePatient'
    'fetchUnpaidCharges'
    'createCharge'
    'fetchPatientSummary'
    'updateEhrId'
  ]

  initial:
    patient: {}
    authorizations: {}
    graph: null
    notes: null
    pcps: {}
    loginStats: null
    unpaidCharges: []

  fetch:
    while: (state) ->
      @set patientLoading: state
    on: ->
      request.get('patient')
    did: (patient) ->
      @set({patient})

  updateCoverage:
    while: (state) ->
      @set updatingCoverage: state
    on: (id='') ->
      request.post("patient/#{id}/update-coverage", null, {return_response: true})
    did: (data) ->
      return data

  fetchPatientSummary:
    while: (state) ->
      @set summaryLoading: state
    on: (id='') ->
      request.get("patient/ehr/#{id}")
    did: (data) ->
      return data

  updateEhrId:
    while: (state) ->
      @set summaryLoading: state
    on: (id='') ->
      request.put("patient", {ehr_chart_id: id})
    did: (patient) ->
      @set {patient}

  fetchGraph:
    while: (state) ->
      @set graphLoading: state
    on: ->
      request.get('patient/graph')
    did: (data) ->
      @set graph: data
    didNot: (error) ->

  update:
    on: (patient) ->
      request.put('patient', patient)
    did: (patient) ->
      @actions.fetch()

  forcePatient:
    on: (data) ->
      request.post('patients/force', data)

  changePassword:
    on: (args) ->
      request.put('patient/password', args)

  resetPassword:
    on: ->
      request.post('patient/admin', {type: 'reset'})

  sendVerification:
    on: ->
      request.post('patient/admin', {type: 'verify'})

  unrestrictAccount:
    on: (user_id) ->
      request
        .post('patient/admin', {type: 'unrestrict', user: user_id})
        .then -> user_id
    did: (user_id) ->
      @actions.fetch() unless user_id

  clearAccount:
    on: (user_id) ->
      request
        .post('patient/admin', {type: 'clear', user: user_id})
        .then -> user_id
    did: (user_id) ->
      @actions.fetch() unless user_id

  sendInvite:
    on: ->
      request.post('patient/admin', {type: 'invite'})

  inactivate:
    on: ->
      request.post('patient/admin', {type: 'inactivate'})

  reactivate:
    on: ->
      request.post('patient/admin', {type: 'reactivate'})

  fetchNotes:
    while: (notesLoading) ->
      @set {notesLoading}
    on: (appointment_id) ->
      request.get('patient/notes', {appointment_id})
    did: (notes) ->
      @set {notes}
    didNot: (error) ->

  fetchLoginStats:
    on:  ->
      request.get('user/login_stats')
    did: (loginStats) ->
      @set {loginStats}
    didNot: (error) ->

  clearNotes: ->
    @set notes: null

  fetchPCPs:
    while: (pcpsLoading) ->
      @set {pcpsLoading}
    on: ->
      request.get('pcps')
    did: (pcps) ->
      @set {pcps}
    didNot: (error) ->

  toggleTextUpdates:
    on: (cell_texts) ->
      request.put('patient', {cell_texts})
    did: ->
      @actions.fetch()
    didNot: (error) ->

  fetchUnpaidCharges:
    on: (user_id) ->
      request.get "patient/#{user_id}/charges", {statuses: 'unpaid'}
    did: (unpaidCharges) ->
      @set {unpaidCharges}
    didNot: (error) ->

  createCharge:
    on: (user_id, amount, appointment_id) ->
      request.post "patient/#{user_id}/charges", {statuses: 'unpaid'}
