"use strict";
const authStore = require("stores/new-auth");
const wellnessStore = require("stores/wellness").default;
const patientStore = require("stores/patient");
const { observer } = require("mobx-react");
const Link = require("../link");
const { client } = require("config");

const WellnessLink = React.createClass({
  displayName: "WellnessLink",
  mixins: [patientStore.connect("patient")],

  componentWillMount() {
    const { id, isAdmin } = authStore.user;
    !isAdmin && wellnessStore.fetchActivePrograms(id);
    !isAdmin && wellnessStore.fetchPrograms(id);
  },

  render() {
    const { isAdmin } = authStore.user;
    const { relationship } = this.state.patient;

    const isADependant = relationship !== "Self";
    const isAmpClientSite = client === "applied" || client === "docker";

    if (isAdmin || !isAmpClientSite || isADependant) return null;

    return (
      <li className="SidebarNav-item">
        <Link to="/wellness">
          <span className="SidebarNavLink-icon">
            <span aria-hidden={true} className="icon fa fa-heartbeat" />
          </span>
          <span className="SidebarNavLink-text">Wellness</span>
        </Link>
      </li>
    );
  },
});

module.exports = observer(WellnessLink);
