"use strict";
const { observer } = require("mobx-react");
const calStore = require("stores/cal");
const synopsisStore = require("stores/new-synopsis");
const { showModal } = require("components/elements/new-modal");
const OOOModal = require("../helpers/ooo/ooo-modal");

const OOONav = () => {
  let { type, value } = calStore.providerFilter;
  if (type != "BY_PROVIDER_ID") return null;

  let showOOOModal = () => {
    let { site } = synopsisStore;
    let provider = site.getProvider(value);

    showModal(<OOOModal provider={provider} />);
  };

  return (
    <button className="ScheduleCalendarNavButton Button" onClick={showOOOModal}>
      <i className="fa fa-suitcase"></i>
      OOO Messages
    </button>
  );
};

module.exports = observer(OOONav);
