"use strict";
const { observable, transaction } = require("mobx");
const { computed, isArrayEql } = require("lib/utils");
const { flatMap } = require("lodash");
const Site = require("models/site");
const AppointmentType = require("models/appointment-type");
const request = require("lib/new-request");
const oldStore = require("stores/synopsis");

const synopsisStore = observable({
  _siteId: null,
  _sites: [],
  client: "",
  sites: [],
  allApptTypes: [],
  providerTypes: [],
  hasScheduling: false,
  globalIdAsPCP: false,
  hideLandingPageOfficeBooking: false,
  plans: {},
  isFetchingPlans: false,
  amat_hra: false,
  be_well: false,
  transitionBannerInfo: null,

  findSite(id) {
    return this.sites.find((site) => site.id == id);
  },

  findApptType(id) {
    return this.apptTypes.find((aT) => aT.id == id);
  },

  async fetchProviderTypes() {
    if (!this.providerTypes.length) {
      this.providerTypes = await request.get("/v1/provider-types");
    }
  },

  async fetchPlans() {
    try {
      this.isFetchingPlans = true;
      this.plans = await request.get("/v1/plans");
    } finally {
      this.isFetchingPlans = false;
    }
  },
});

computed(synopsisStore, {
  get site() {
    return this.findSite(this._siteId) || new Site();
  },

  get bookableApptTypes() {
    const { me } = require("stores/new-auth");
    let ret = this.allApptTypes;
    if (!me || !me.isAdmin) {
      let availableApptTypes = new Set();
      for (const site of this._sites) {
        for (const modality of site.modalities) {
          modality.list.forEach((id) => availableApptTypes.add(id));
        }
      }
      ret = ret.filter((apptType) => availableApptTypes.has(apptType.id));
    }

    return ret.map((raw) => AppointmentType.fromAPI(raw));
  },

  get apptTypes() {
    return [
      ...this.bookableApptTypes,
      ...AppointmentType.INTERNAL_TYPES,
      AppointmentType.OPEN,
    ];
  },

  get providers() {
    return flatMap(this.sites, (site) => site.providers);
  },

  get isFetched() {
    return this.sites.length > 0;
  },
});

oldStore.onChange(() => {
  transaction(() => {
    let newApptTypes = oldStore.get("allTypes") || [];
    let hasApptTypesChanged = !isArrayEql(
      newApptTypes,
      synopsisStore.bookableApptTypes,
      (s) => s.id
    );

    let newSites = oldStore.get("sites") || [];
    let hasSitesChanged = !isArrayEql(
      newSites,
      synopsisStore.sites,
      (s) => s.id
    );

    if (hasApptTypesChanged || hasSitesChanged) {
      synopsisStore._sites = newSites;
      synopsisStore.allApptTypes = newApptTypes;
      synopsisStore.sites = newSites.map((raw) => Site.fromAPI(raw));
    }

    let rawSite = oldStore.get("site");
    if (rawSite.id) {
      synopsisStore._siteId = rawSite.id;
    }

    synopsisStore.enableOutboundPros = oldStore.get("enable_outbound_pros");
    synopsisStore.hasScheduling = oldStore.get("xo_scheduling");
    synopsisStore.hasScreeningFramework = oldStore.get(
      "xo_screening_framework"
    );
    synopsisStore.client = oldStore.get("client");
    synopsisStore.globalIdAsPCP = oldStore.get("enable_pcp_enhancement");
    synopsisStore.hideLandingPageOfficeBooking = oldStore.get(
      "hide_landing_page_office_booking"
    );
    synopsisStore.amat_hra = oldStore.get("amat_hra");
    synopsisStore.be_well = oldStore.get("be_well");
    synopsisStore.transitionBannerInfo = oldStore.get("transition_banner_tile");
  });
});

module.exports = synopsisStore;
