appStore = require('stores/new-app')
{documentsStore} = require 'stores'
{isEdge} = require 'config'
{observer} = require('mobx-react')

ListLink   = require 'components/elements/ListLink'
Bouncer    = require 'components/mixins/Bouncer'
{features, touchDevice} = require 'config'

FontSizeToFit = require 'components/app/FontSizeToFit'

{cx} = Exim.helpers
{div, h1, nav, ul, i, input, fa, tch, header, a, span, label, button} = Exim.DOM

ContentHeader = React.createClass({
  displayName: 'ContentHeader',
  mixins: [
    Bouncer,
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    showDropdown: false
    searchFocused: false
    searchValue: @props.matcher or ''

  componentDidMount: ->
    @handleResize()
    @refs.sizeToFit.update()
    window.addEventListener('resize', @handleResize)

  componentDidUpdate: (prevProps) ->
    if @props.name isnt prevProps.name
      @refs.sizeToFit.update()
      @clearSearch()

  handleResize: (evt) ->
    if evt && width = evt.target?.window?.document?.width
      @setState mobile: width < 767
    else
      @setState mobile: document.body.clientWidth < 767

  componentWillUnmount: ->
    window.removeEventListener('resize', @handleResize)

  toggleExpanded: (force, value, isHover=false) -> (evt) =>
    if isHover and (isEdge or touchDevice)
      return console.log 'UserHeader#toggleExpanded: Exiting, is edge or touch device'
    @stopPropagation(evt)

    value = !!value

    setTimeout =>
      if force
        @setState showDropdown: value if @state.showDropdown isnt value
      else
        @setState showDropdown: !@state.showDropdown

  touchStart: (evt) ->
    @touched = true
    @toggleExpanded(false)(evt)
    return

  touchEnd: (evt) ->
    fn = =>
      @touched = false
    setTimeout fn, 20
    return

  mouseDown: (evt) ->
    unless @touched
      @toggleExpanded(false)(evt)
    return

  focusSearch: ->
    @setState {searchFocused: true}
    @refs.searchInput?.focus()

  blurSearch: (evt) ->
    evt.stopPropagation()
    @refs.searchInput?.blur()
    @setState {searchFocused: false}

  clearSearch: (evt) ->
    evt?.stopPropagation()
    @props.clearSearch?()
    @refs.searchInput?.value = ''
    @setState searchValue: ''

  stopPropagation: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()

  search: (evt) ->
    searchInput = evt.currentTarget
    {value} = searchInput
    if features.newApi
      @startBouncer(searchInput, => @props.search(value))
    else
      @props.search(value)
    @setState {searchValue: value}

  linkItem: (link, index) ->
    ListLink key: "link-#{index}", to: link.to, alsoActiveOn: link.alsoActiveOn, params: link.params, query: link.query, action: link.action, upload: link.upload, onlyActiveOnIndex: link.onlyActiveOnIndex, location: @props.location, uploading: @state[link.uploading], icon: link.icon, link.title

  linkAction: (link, index) ->
    if link.type
      link
    else
      ListLink key: "link-#{index}", type: 'action', to: link.to, alsoActiveOn: link.alsoActiveOn, params: link.params, query: link.query, action: link.action, upload: link.upload, onlyActiveOnIndex: link.onlyActiveOnIndex, location: @props.location, uploading: @state[link.uploading], icon: link.icon, disabled: link.disabled, link.title

  isActive: (path, indexRoute) ->
    @context.router.isActive path, indexRoute

  getActiveName: ->
    activeName = @props.name

    @props.links?.forEach (link) =>
      activeName = link.title if @isActive(link.to, link.onlyActiveOnIndex) or @props.location?.pathname?.match(link.alsoActiveOn)?[0]

    activeName

  handleMouseOver: (evt) ->
    if @props.noHover then null else @toggleExpanded(true, true, true)(evt)

  render: ->
    {name, links, actions, subNavFn,
    className, note, search, arrows, edit,
    screen, action, noSearch, clearSearch} = @props
    {showDropdown, searchValue, mobile} = @state
    subheader = [links, edit, note, search, arrows].some (item) -> item

    name = if name.type then name else @getActiveName()

    noSearchActive = noSearch and @context.router.isActive(noSearch)
    titleArgs = {
      'data-e2e': name
      className: "ContentHeader-title ContentHeader-title--with-chevron #{cx 'expanded': showDropdown}"
      onTouchStart: @touchStart
      onTouchEnd: @touchEnd
      onClick: @stopPropagation
      onMouseDown: @mouseDown
      onMouseOver: @handleMouseOver
      onMouseLeave: @toggleExpanded(true, undefined, true)
    }

    wrapperCls = cx
      'is-searchFocused': @state.searchFocused
      'no-subheader': !subheader

    header className: "ContentHeader #{wrapperCls} #{className or ''}", style: @props.style,
      div className: "ContentHeader-info #{cx 'no-links': !links}", ref: 'header',
        button {
          className: 'ContentHeader-collapser header-action',
          id: 'menu',
          'aria-expanded': appStore.isSidebarOpen,
          'aria-label': 'Toggle sidebar',
          'aria-controls': 'sidebar',
          onClick: () => appStore.toggleSidebar(),
        }, fa 'bars'
        div className: 'ContentHeader-wrap',
          div className: 'ContentHeader-group', 'data-e2e': "#{name}HeaderGroup",
            div titleArgs,
              h1 className: 'ContentHeader-text', id: name,
                FontSizeToFit ref: 'sizeToFit', name
              if links and links.length > 1
                [
                  div key: 'ContentHeader-chevron', className: 'ContentHeader-chevron',
                    fa 'caret-down'
                  div key: 'ContentHeader-dropdown', className: 'ContentHeader-dropdown',
                    links.filter((i) -> i).map @linkItem
                ]

          div className: 'ContentSearchWrapper u-flex u-flexJustifyEnd u-flexAlignCenter',
            if @state.searchFocused
              tch className: 'ContentSearch-close u-hidden-md-up', handler: @blurSearch,  
                fa 'times-circle'
            if search and !(noSearch and noSearchActive)
              placeholder = if mobile then 'Search' else "Search #{name}"
              div className: 'ContentSearch', key: 'search',
                input id: 'contentSearchInput', className: 'ContentSearch-input', type: 'search', ref: 'searchInput', defaultValue: searchValue, placeholder: placeholder, onChange: @search, onBlur: @blurSearch
                label htmlFor: 'contentSearchInput', className: 'invisible', 'Search'
                if clearSearch and !mobile and searchValue?.length
                  tch className: 'ContentSearch-clear', handler: @clearSearch, tabIndex: 0,
                    fa 'times'
            actions?.map @linkAction
      if typeof subNavFn is 'function'
        subNavFn(@state)
})

module.exports = observer(ContentHeader)
