import CheckIn from "./check-in";
import { Route } from "react-router";
import {
  WelcomeStep,
  AboutStep,
  ReasonStep,
  MedsStep,
  DentalStep,
  ConditionsStep,
  LifestyleStep,
  ScreeningsStep,
  ConsentsStep,
  CrossoverStep,
  ThanksStep,
} from "./steps";

module.exports = (
  <Route path="/checkin/steps" component={CheckIn}>
    <Route path="welcome" component={WelcomeStep} />
    <Route path="about" component={AboutStep} />
    <Route path="reason" component={ReasonStep} />
    <Route path="meds" component={MedsStep} />
    <Route path="dental" component={DentalStep} />
    <Route path="conditions" component={ConditionsStep} />
    <Route path="lifestyle" component={LifestyleStep} />
    <Route path="patient_screenings" component={ScreeningsStep} />
    <Route path="consents" component={ConsentsStep} />
    <Route path="crossover" component={CrossoverStep} />
    <Route path="thanks" component={ThanksStep} />
  </Route>
);
