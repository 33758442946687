{appStore, authStore, patientStore, synopsisStore, chargesStore} = require 'stores'

newAuthStore = require('stores/new-auth')
Fields       = require 'components/mixins/Fields'
Img          = require 'components/elements/Img'
Spinner      = require 'components/elements/Spinner'
NewSpinner = require('components/elements/new-spinner')
{uniqueLanguageOptions} = require('components/elements/language-select')
Footer       = require 'components/app/Footer'
ActionDropdown = require 'components/elements/ActionDropdown'
ConfirmationModal = require 'components/elements/ConfirmationModal'
UpdateEHRModal = require './UpdateEHRModal'
ContentHeader = require('components/elements/content-header')
Main = require('components/elements/main')
BackSwiper = require('components/elements/back-swiper')
{cx} = Exim.helpers
{observer} = require('mobx-react')
{withRouter, Link} = require('react-router')
{unlockAccount} = require('./helpers')
SOGIInfoPopUp = require('./SOGIInfoPopUp')
{showModal} = require('components/elements/new-modal')

BACK_TO = '/profile'

utils = require 'lib/utils'
config = require 'config'
{fa, main, div, spinner, h2, h3, select, option, span, p, a, input, tch, label, textarea, footer, button, i} = Exim.DOM

requiredPatient = ['first_name', 'last_name', 'birthdate_month', 'birthdate_day', 'birthdate_year', 'birthdate', 'gender', 'address', 'city', 'state', 'zip', 'emergency_name', 'emergency_phone', 'emergency_relationship', 'cell_phone']
requiredRaceAndEthnicity = ['first_name', 'last_name', 'birthdate_month', 'birthdate_day', 'birthdate_year', 'birthdate', 'gender', 'address', 'city', 'state', 'zip', 'emergency_name', 'emergency_phone', 'emergency_relationship', 'cell_phone', 'race', 'ethnicity']

EditProfile = React.createClass({
  displayName: 'EditProfile',
  mixins: [
    Fields
    patientStore.connect('patient', 'patientUpdating')
    authStore.connect('user', 'avatarUploading', 'avatarRemoving')
    chargesStore.connect('error')
    synopsisStore.connect('sites')
  ]

  dataName: 'patient'

  getInitialState: ->
    insuranceValues: {}
    isEmailTaken: false
    isKeyPressOnGender: false
    isKeyPressOnState: false
    isKeyPressOnContact: false
    isKeyPressOnLanguage: false
    isKeyPressOnPronouns: false
    isKeyPressOnGenderIdentity: false
    isKeyPressOnSexualOrientation: false
    isKeyPressOnRace: false
    isKeyPressOnEthnicity: false

  getInitialData: ->
    patient = @state.patient
    patient.insurance_plan ?= '' #update fails if these fields are nil
    patient.insurance_id   ?= ''
    dob = moment(patient.birthdate, config.formats.dob)
    month = config.monthsShort[ dob.month() ]
    month = month[0].toUpperCase() + month[1..-1] if month
    primary_contact = patient.primary_contact
    patient.birthdate_month ?= month
    patient.birthdate_day ?= dob.date()
    patient.birthdate_year ?= dob.year()
    patient

  componentWillMount: ->
    # I don't think this code is executing
    chargesStore.actions.clearError()
    patientStore.actions.fetch()

  renderActions: ->
    user = authStore.get('user')

    userActions = switch user.status
      when 'Unregistered' then [{handler: @invite, name: 'Send Invite'}]
      when 'Pending'      then [{handler: @verify, name: 'Resend Verification'}]
      when 'Active'       then [{handler: @reset , name: 'Reset Password'}]
      else []

    userActions.push({handler: @inactivate, name: 'Inactivate Account'}) if user.status in ['Active', 'Pending'] and (!user.admin and user.proxying)
    userActions.push({handler: @reactivate, name: 'Reactivate Account'}) if user.status is 'Inactive' and (!user.admin and user.proxying)
    userActions.push({handler: @showUpdateEHRModal, name: 'Update EHR ID'}) if ((user.staff or user.admin) and user.proxying)

    {user: newUser, me} = require('stores/new-auth')
    userActions.push({handler: @reactivate, name: 'Reactivate Account'}) if newUser.status is 'Ineligible' and (!newUser.isAdmin and newUser.isProxying) and newUser.canReactivateIneligibles
    userActions.push({handler: unlockAccount, name: 'Unlock Account'}) if me.isAdmin and newUser.isProxying

    userActions.push({handler: @whatsNew, name: "What's New"})
    userActions.unshift({handler: this.goBack, name: 'View Account'})

    return ActionDropdown
      className: 'u-flexChild ActionDropdown--xlarge ActionDropdown--titleInner ActionDropdown--white-bg'
      values: userActions
      needsTwoTabs: true

  componentDidMount: ->
    if config.isCordova
      appStore.actions.setGlobalFileChangeHandler(@selectFile)

    if @focusEmail = @props.location.query?.focusEmail
      @refs.email?.focus()
    else if @refs.uploadAvatar
      @refs.uploadAvatar?.focus()

  componentWillMount: ->
    appStore.actions.setHeader(null)

  componentWillUnmount: ->
    if config.isCordova
      appStore.actions.removeGlobalFileChangeHandler()

  showUpdateEHRModal: ->
    body = UpdateEHRModal {close: @closeModal, ehrId: patientStore.initial?.patient?.ehr_chart_id or ''}

    modal =
      className: 'UpdateEHRModal-modal'
      body: body
      noClose: true

    appStore.actions.showModal(modal)

  invite: ->
    {user} = @state
    action = => patientStore.actions.sendInvite().then =>
      @closeModal()
    @confirmationModal('Invite patient?',
                       "#{user.full_name} will receive an invitation email.",
                       'Send invite',
                       action)
  verify: ->
    action = => patientStore.actions.sendVerification().then =>
      @closeModal()
    @confirmationModal("Verify patient's account?",
                       'Account will be verified',
                       'Verify account',
                       action)
  reset: ->
    {user} = @state
    action = => patientStore.actions.resetPassword().then =>
      @closeModal()
    @confirmationModal("Reset patient's password?",
                       "#{user.full_name} will receive a password reset email.",
                       'Reset password',
                       action,
                       'Reset password',
                       'Password reset request sent',
                       'action-dropdown-show-additional-options')

  reactivate: ->
    {user, patient} = @state
    if patient.email
      action = => patientStore.actions.reactivate().then =>
        authStore.actions.fetchUser().then =>
          this.goBack()
        @closeModal()
      @confirmationModal('Reactivate this patient?',
                         "#{user.full_name} will be able to access #{if user.gender is 'M' then 'his' else 'her'} account.",
                         'Reactivate patient',
                         action)
    else
      @focusEmail = true
      @refs.email?.focus()

  inactivate: ->
    {user} = @state
    action = => patientStore.actions.inactivate().then =>
      authStore.actions.fetchUser().then =>
        this.goBack()
      @closeModal()
    @confirmationModal('Deactivate this patient?',
                       "#{user.full_name} will no longer be able to access #{if user.gender is 'M' then 'his' else 'her'} account.",
                       'Deactivate patient',
                       action)

  whatsNew: ->
    this.props.router.push pathname: '/whatsNew'

  validate: ->
    user = authStore.get('user')
    {hasRaceAndEthnicity} = newAuthStore
    
    if user.proxying
      @required = []
    else
      @required = if hasRaceAndEthnicity then requiredRaceAndEthnicity else requiredPatient

    fields = @getFields()
    valid = {}
    (@required ?= []).forEach (key) ->
      valid[key] = false
    result = {}
    errors = []

    for key, value of fields
      fn = @getValidator key

      if typeof fn is 'function' and fn value
        valid[key] = true if key in @required
        result[key] = value
      else
        valid[key] = !!value if key in @required

    Object.keys(valid).forEach (key) ->
      errors.push(key) unless valid[key]

    errors.concat @validateDob()
    @setState {errors}
    return errors

  updateEmail: ->
    if newAuthStore.isProxying
      {email} = this.state.patient
      {user} = newAuthStore

      if email != user.email
        return user.update({email})

    return Promise.resolve()

  update: (evt) ->
    evt?.preventDefault()
    document.activeElement.blur()

    return if @validate().length

    this.updateEmail().then(() =>
      {insuranceValues, insuranceUpdated} = @state
      patientParams = utils.clone(this.state.patient)
      patientParams.skip_insurance = true if insuranceValues and insuranceUpdated
      if insuranceUpdated
        {user} = newAuthStore
        synopsisStore.actions.updateInsurance(user.id, insuranceValues)
      patientStore.actions.update(patientParams).then(() =>
        authStore.actions.fetchUser()
        this.goBack()
      ).then(() =>
          utils.screenReaderSpeak("Profile successfully updated", "assertive")
      )
    , (err) =>
      if err.status == 400
        this.refs.email?.focus()
        this.setState({
          isEmailTaken: true,
        })
        utils.screenReaderSpeak("Error updating profile: this email address has already been taken", "assertive")
    )

  selectFile: (evt) ->
    [file] = evt.currentTarget.files
    authStore.actions.uploadAvatar({file})

  closeModal: ->
    appStore.actions.showModal(null)

  confirmationModal: (title, text, confirmText, onConfirm, srOnlyText = '', activatorElId, contentLabel = '') ->
    body = ConfirmationModal {title, text, confirmText, onCancel: @closeModal, onConfirm, srOnlyText, activatorElId, contentLabel}

    modal =
      className: 'Modal--small'
      darker: true
      body: body

    appStore.actions.showModal(modal)

  removeAvatar: (evt) ->
    onConfirm = => authStore.actions.removeAvatar().then =>
      document.querySelector('#avatar-file')?.value = ''
      @closeModal()

    @confirmationModal 'Delete profile photo?',
                       'Are you sure you want to delete your current photo forever?',
                       'Delete',
                       onConfirm,
                       '',
                       '',
                       'Delete profile photo'

  setDob: (date) ->
    formatted = moment(date).format(config.formats.dob)
    @updateData 'birthdate', formatted

  renderPrettyDOB: () ->
    { patient } = @state
    return "#{patient.birthdate_month}. #{patient.birthdate_day}, #{patient.birthdate_year}"

  clickInput: (e) ->
    e.stopPropagation()
    fileInput = if config.isCordova
      document.querySelector('#avatar-file')
    else
      @refs.fileinput
    fileInput.click()

  stopPropagation: (e) ->
    e.stopPropagation()

  setPrimaryContactDefault: (contactType) ->
    return (userInput) =>
      {patient} = @state

      return if patient.primary_contact and userInput

      if userInput
        patient.primary_contact = contactType
      else if contactType is patient.primary_contact
        patient.primary_contact = null
      else
        return

      @setState {patient}

  goBack: ->
    this.props.router.push(BACK_TO)

  toPhoto: ->
    this.props.router.push '/profile/edit/photo'

  getInsuranceKey: (ins, field) ->
    "#{config.insuranceTypeMapping[ins]}_#{field}"

  onInsuranceValueChange: (key) -> (evt) =>
    value = evt?.currentTarget.value
    { insuranceValues, insuranceUpdated } = @state
    insuranceValues[key] = value
    newState = {insuranceValues}
    newState.insuranceUpdated = true unless insuranceUpdated
    @setState newState

  onGenderKeyPress: (evt) ->
    if evt.keyCode is 38 or 40 or 17 or 13
      @setState isKeyPressOnGender: true

  onPronounsKeyPress: (evt) ->
    if evt.keyCode is 38 or 40 or 17 or 13
      @setState isKeyPressOnPronouns: true

  onGenderIdentityKeyPress: (evt) ->
    if evt.keyCode is 38 or 40 or 17 or 13
      @setState isKeyPressOnGenderIdentity: true

  onSexualOrientationPress: (evt) ->
    if evt.keyCode is 38 or 40 or 17 or 13
      @setState isKeyPressOnSexualOrientation: true

  onStateKeyPress: (evt) ->
    if evt.keyCode is 38 or 40 or 17 or 13
      @setState isKeyPressOnState: true

  onContactKeyPress: (evt) ->
    if evt.keyCode is 38 or 40 or 17 or 13
      @setState isKeyPressOnContact: true

  onLanguageKeyPress: (evt) ->
    if evt.keyCode is 38 or 40 or 17 or 13
      @setState isKeyPressOnLanguage: true

  onRaceKeyPress: (evt) ->
    if evt.keyCode is 38 or 40 or 17 or 13
      @setState isKeyPressOnRace: true

  onEthnicityKeyPress: (evt) ->
    if evt.keyCode is 38 or 40 or 17 or 13
      @setState isKeyPressOnEthnicity: true 

  sogiInfoIconClick: (evt) ->
    {hasRaceAndEthnicity, featureFlagsLoaded, user} = newAuthStore
    showRaceAndEthnicity = featureFlagsLoaded && hasRaceAndEthnicity
    evt.stopPropagation()
    showModal(React.createElement(SOGIInfoPopUp, rect: evt.target.getBoundingClientRect(), isCtm: (user.isAdmin or  user.isProxying), hasRaceAndEthnicity: showRaceAndEthnicity, showSexualOrientation: true, hideNameUsed: false))

  closeModal: ->
    appStore.actions.showModal(null)

  renderFooter: ->
    if this.state.patientUpdating
      return React.createElement(NewSpinner)

    return footer className: 'ProfileEdit-footer',
      button id: 'profile-edit-submit', className: 'Button Button--primary', onClick: this.update,
        'Update Account'
      React.createElement(Link, {
        className: 'Button',
        to: BACK_TO,
      }, 'Cancel')

  renderEmail: ->
    return React.createElement(React.Fragment, null,
      if @focusEmail
        div className: 'ProfileEdit-row ProfileEdit-row--note error',
          'Please enter an email address to reactivate the patient.'

      if this.state.isEmailTaken
        div id: 'profile-email-taken', className: 'ProfileEdit-row ProfileEdit-row--note error',
          'This email is already taken by another user.'

      div className: 'ProfileEdit-row',
        label htmlFor: 'email', 'Email'
        @field 'email', {
          id: 'email',
          readOnly: !newAuthStore.isProxying,
        }, {
          description: 'Email',
          onChange: @setPrimaryContactDefault('email'),
        }
    )

  render: ->
    {patient, user, insuranceValues, insuranceUpdated, isKeyPressOnGender, isKeyPressOnContact, isKeyPressOnState, isKeyPressOnLanguage} = @state
    isAmazonEmployee = user.employer is 'XOAmazon'
    {insurances, pronouns, gender_identity, sexual_orientation, race, ethnicity} = patient
    {hasSOGI,hasRaceAndEthnicity, featureFlagsLoaded} = newAuthStore
    langPlaceholder = 'Select Language'
    isAdultWithSOGI = featureFlagsLoaded and hasSOGI and !user.minor
    showRaceAndEthnicity = featureFlagsLoaded && hasRaceAndEthnicity
    gender = patient.gender
    genderLabel = if isAmazonEmployee or isAdultWithSOGI then 'Sex Assigned at Birth' else 'Gender'
    # disableSexAtBirthSelect = gender == 'M' or gender == 'F' or gender == 'I'

    isCork = user.default_site is 'crk'
    isSingapore = user.default_site in ['amk', 'inn']

    addressLabel = if isCork
      'City, Country, Postcode'
    else if isSingapore
      'City, Country, Zip'
    else
      'City, State, Zip'

    @required = @required?.filter (key) -> key isnt 'zip' if isCork or isSingapore

    preferredContacts = [{value: '', label: 'No Preference'}]
    if patient.email and not @err('email')
      preferredContacts.push({value: 'email', label: 'Email'})
    if patient.home_phone and not @err('home_phone')
      preferredContacts.push({value: 'home', label: 'Home Phone'})
    if patient.cell_phone and not @err('cell_phone')
      preferredContacts.push({value: 'cell', label: 'Mobile Phone'})
    if patient.work_phone and not @err('work_phone')
      preferredContacts.push({value: 'work', label: 'Work Phone'})

    isDefaultAvatar = user.avatar_url is null

    return React.createElement(React.Fragment, null,
      React.createElement(ContentHeader, {title: 'Edit Account'}, this.renderActions())
      React.createElement(Main, {className: 'Content ProfileEdit'},
        div className: 'Content-body Content-body--article',
          Spinner className: 'u-flexChild', state: !patient.id,
            React.createElement(BackSwiper, {to: BACK_TO})
            div className: "ContentInner #{cx 'is-proxying': user.proxying}",
              div className: 'ContentBlock ContentBlock--noTopMargin',
                div className: 'ProfileEdit-avatarRow',
                  div className: 'u-center',
                    Spinner state: @state.avatarUploading or @state.avatarRemoving,
                      Img alt: 'User Profile Avatar', src: utils.getAvatarUrl(user.avatar_url)
                  unless config.isCordova
                    input id: 'avatar-file', type: 'file', className: 'Input--fileHidden', onClick: @stopPropagation, onChange: @selectFile, ref: 'fileinput', tabIndex: -1, 'aria-hidden': 'true'
                  div className: 'ProfileEdit-avatarRowName',
                    user.full_name
                  div className: 'ProfileEdit-avatarRowButtons',
                    button className: 'Button Button--withMargin Button--pointer Button--primary', onClick: utils.a11yClick(@toPhoto), onKeyPress: utils.a11yClick(@toPhoto), ref: 'uploadAvatar',
                      span 'Upload New Picture'
                    button className: "Button Button--cancel #{cx 'is-disabled': isDefaultAvatar}", disabled: isDefaultAvatar, onClick: utils.a11yClick(@removeAvatar), onKeyPress: utils.a11yClick(@removeAvatar), 'Delete Picture'

              if (@state.errors.length > 0)
                div className: 'WarningBox-border-box',
                  i className: 'fa fa-exclamation-triangle WarningBox-orange-icon',
                  span className: 'WarningBox-text', 'Required information is missing. Please update your record.'

              div className: 'ContentBlock',
                if !isAdultWithSOGI
                  h3 ' Personal Information '

                # Display the SOGI tooltip if the member has the SOGI feature flag
                if isAdultWithSOGI
                  h3 ' Personal Information ',
                    span,
                      i className: 'fa fa-info-circle ProfileEdit-sogi-info-icon', onClick: @sogiInfoIconClick, onClose: @onClose, id: 'edit-account-sogi-info-icon', role:'button', 'aria-label':'Sexual Orientation and Gender Identity Information', tabIndex: '0', onKeyPress: utils.a11yClick(@sogiInfoIconClick)
                # You'll notice most of the field's descriptions are suffixed with 'blank' depending on whether that field is empty or not. An Accessibility effort aiming to
                # standardize how NVDA, JAWS and Voice Over read out empty text fields.

                if user.proxying
                  div className: 'ProfileEdit-row',
                    label htmlFor: 'first_name', 'Legal First Name'
                    div className: 'u-flex u-flexRow u-4of5',
                      @field 'first_name', id: 'first_name', className: 'Input u-flexChild', description: "Legal First Name#{if patient.first_name then '' else ', blank'}"
                else
                  div className: 'ProfileReadOnly-row',
                    label htmlFor: 'first_name', 'Legal First Name'
                    div className: 'u-flex u-flexRow u-4of5',
                      div className: 'ReadOnly u-flexChild', id: 'first_name', 'data-e2e': 'first_name', placeholder: 'Michael', description: 'Legal First Name', readOnly: true, "#{user.first_name}"

                div className: 'ProfileEdit-row',
                  label htmlFor: 'preferred_name', 'Name Used - optional'
                  @field 'preferred_name', id: 'preferred_name', description: "Name Used - optional #{if patient.preferred_name then '' else ', blank'}"

                div className: 'ProfileEdit-row',
                  label htmlFor: 'middle_name', 'Middle Name - optional'
                  div className: 'u-flex u-flexRow u-4of5',
                    @field 'middle_name', id: 'middle_name', className: 'Input u-flexChild', description: "Middle Name - optional #{if patient.middle_name then '' else ', blank'}"

                if user.proxying
                  div className: 'ProfileEdit-row',
                    label htmlFor: 'last_name', 'Last Name'
                    div className: 'u-flex u-flexRow u-4of5',
                      @field 'last_name', id: 'last_name', className: 'Input u-flexChild', description: "Last Name#{if patient.last_name then '' else ', blank'}"
                else
                  div className: 'ProfileReadOnly-row',
                    label htmlFor: 'last_name', 'Last Name'
                    div className: 'u-flex u-flexRow u-4of5',
                      div className: 'ReadOnly u-flexChild', id: 'last_name', 'data-e2e': 'last_name', placeholder: 'Michael', description: 'Legal First Name', readOnly: true, "#{user.last_name}"

                div className: 'ProfileEdit-row',
                  span className: 'ProfileEdit-rowLabel', genderLabel
                  div className: 'SimpleSelect SimpleSelect--angle',
                      select 'aria-label': genderLabel, className: "#{@err('gender')}", defaultValue: gender or '', ref: 'gender', onChange: @onDataChange('gender'), onKeyDown: @onGenderKeyPress,
                        option disabled: true, value: '', 'aria-label': 'Please select an option (required)', 'Please select an option (required)'
                        option value: 'F', 'aria-label': 'Female, 1 of 3', "Female#{if isKeyPressOnGender then ", 1 of 3" else ''}"
                        option value: 'M', 'aria-label': 'Male, 2 of 3', "Male#{if isKeyPressOnGender then ", 2 of 3" else ''}"
                        if isCork or isSingapore
                          option value: 'O', 'aria-label': 'Other, 3 of 3', "Other#{if isKeyPressOnGender then ", 3 of 3" else ''}"
                        else
                          option value: 'I', 'aria-label': 'Intersex, 3 of 3', "Intersex#{if isKeyPressOnGender then ", 3 of 3" else ''}"

                if user.proxying
                  div className: 'ProfileEdit-row',
                    span className: 'ProfileEdit-rowLabel', 'Date of Birth'
                    @dob()
                else
                  div className: 'ProfileReadOnly-row',
                    label htmlFor: 'birthdate', 'Date of Birth'
                    div className: 'ReadOnly u-flexChild', 'data-e2e': 'birthdate', readOnly: true, @renderPrettyDOB()

                if isAdultWithSOGI
                  div className: 'ProfileEdit-row',
                    span className: 'ProfileEdit-rowLabel', 'Pronouns - optional'
                    div className: 'SimpleSelect SimpleSelect--angle',
                        select 'aria-label': 'Pronouns - optional', defaultValue: pronouns, ref: 'pronouns', onChange: @onDataChange('pronouns'), onKeyDown: @onPronounsKeyPress,
                          option value: '', 'aria-label': 'Please select an option'
                          option value: 'she_her_hers', 'aria-label': 'She / Her / Hers, 1 of 4', "She / Her / Hers#{if isKeyPressOnGender then ", 1 of 4" else ''}"
                          option value: 'he_him_his', 'aria-label': 'He / Him / His, 2 of 4', "He / Him / His#{if isKeyPressOnGender then ", 2 of 4" else ''}"
                          option value: 'they_them_theirs', 'aria-label': 'They / Them / Theirs, 3 of 4', "They / Them / Theirs#{if isKeyPressOnGender then ", 3 of 4" else ''}"
                          option value: 'option_not_listed', 'aria-label': 'Option not listed (please inform provider), 4 of 4', "Option not listed (please inform provider)#{if isKeyPressOnGender then ", 4 of 4" else ''}"
                
                if isAdultWithSOGI
                  div className: 'ProfileEdit-row',
                    span className: 'ProfileEdit-rowLabel', 'Gender Identity - optional'
                    div className: 'SimpleSelect SimpleSelect--angle',
                        select 'aria-label': 'Gender Identity - optional', defaultValue: gender_identity, ref: 'gender_identity', onChange: @onDataChange('gender_identity'), onKeyDown: @onGenderIdentityKeyPress,
                          option value: '', 'aria-label': 'Please select an option'
                          option value: 'woman', 'aria-label': 'Woman, 1 of 9', "Woman#{if isKeyPressOnGender then ", 1 of 9" else ''}"
                          option value: 'man', 'aria-label': 'Man, 2 of 9', "Man#{if isKeyPressOnGender then ", 2 of 9" else ''}"
                          option value: 'transgender_woman', 'aria-label': 'Transgender woman / transfeminine, 3 of 9', "Transgender woman / transfeminine#{if isKeyPressOnGender then ", 3 of 9" else ''}"
                          option value: 'transgender_man', 'aria-label': 'Transgender man / transmasculine, 4 of 9', "Transgender man / transmasculine#{if isKeyPressOnGender then ", 4 of 9" else ''}"
                          option value: 'nonbinary', 'aria-label': 'Non-binary / genderqueer / gender fluid, 5 of 9', "Non-binary / genderqueer / gender fluid#{if isKeyPressOnGender then ", 5 of 9" else ''}"
                          option value: 'two_spirit', 'aria-label': 'Two-Spirit, 6 of 9', "Two-Spirit#{if isKeyPressOnGender then ", 6 of 9" else ''}"
                          option value: 'option_not_listed', 'aria-label': 'Option not listed (please inform provider), 7 of 9', "Option not listed (please inform provider)#{if isKeyPressOnGender then ", 7 of 9" else ''}"
                          option value: 'prefer_not_to_say', 'aria-label': 'Prefer not to say, 8 of 9', "Prefer not to say#{if isKeyPressOnGender then ", 8 of 9" else ''}"
                          option value: 'do_not_know', 'aria-label': "Don't know, 9 of 9", "Don't know#{if isKeyPressOnGender then ", 9 of 9" else ''}"
                
                if isAdultWithSOGI
                  div className: 'ProfileEdit-row',
                    span className: 'ProfileEdit-rowLabel', 'Sexual Orientation - optional'
                    div className: 'SimpleSelect SimpleSelect--angle',
                        select 'aria-label': 'Sexual Orientation - optional', defaultValue: sexual_orientation, ref: 'sexual_orientation', onChange: @onDataChange('sexual_orientation'), onKeyDown: @onSexualOrientationPress,
                          option value: '', 'aria-label': 'Please select an option'
                          option value: 'asexual', 'aria-label': 'Asexual, 1 of 9', "Asexual#{if isKeyPressOnGender then ", 1 of 9" else ''}"
                          option value: 'bisexual', 'aria-label': 'Bisexual, 2 of 9', "Bisexual#{if isKeyPressOnGender then ", 2 of 9" else ''}"
                          option value: 'gay', 'aria-label': 'Gay, 3 of 9', "Gay#{if isKeyPressOnGender then ", 3 of 9" else ''}"
                          option value: 'lesbian', 'aria-label': 'Lesbian, 4 of 9', "Lesbian#{if isKeyPressOnGender then ", 4 of 9" else ''}"
                          option value: 'straight', 'aria-label': 'Straight, 5 of 9', "Straight#{if isKeyPressOnGender then ", 5 of 9" else ''}"
                          option value: 'queer', 'aria-label': 'Queer, 6 of 9', "Queer#{if isKeyPressOnGender then ", 6 of 9" else ''}"
                          option value: 'do_not_know', 'aria-label': 'Don’t Know, 7 of 9', "Don’t Know#{if isKeyPressOnGender then ", 7 of 9" else ''}"
                          option value: 'option_not_listed', 'aria-label': 'Option not listed (please inform provider), 8 of 9', "Option not listed (please inform provider)#{if isKeyPressOnGender then ", 8 of 9" else ''}"
                          option value: 'prefer_not_to_say', 'aria-label': 'Prefer not to say, 9 of 9', "Prefer not to say#{if isKeyPressOnGender then ", 9 of 9" else ''}"
                
                if showRaceAndEthnicity
                 div className: 'ProfileEdit-row',
                    span className: 'ProfileEdit-rowLabel', 'Race'
                    div className: 'SimpleSelect SimpleSelect--angle',
                        select className: "#{@err('race')}", 'aria-label': 'Race', defaultValue: race, ref: 'race', onChange: @onDataChange('race'), onKeyDown: @onRaceKeyPress,
                          option value: '', 'aria-label': 'Please select an option', 'Please select an option'
                          option value: 'american_indian', 'aria-label': 'American/Indian or Alaskan native, 1 of 6', "American/Indian or Alaskan native#{if isKeyPressOnGender then ", 1 of 6" else ''}"
                          option value: 'asian', 'aria-label': 'Asian, 2 of 6', "Asian#{if isKeyPressOnGender then ", 2 of 6" else ''}"
                          option value: 'black', 'aria-label': 'Black or African American, 3 of 6', "Black or African American#{if isKeyPressOnGender then ", 3 of 6" else ''}"
                          option value: 'native_pacific', 'aria-label': 'Native Hawaiian or Other Pacific Islander, 4 of 6', "Native Hawaiian or Other Pacific Islander#{if isKeyPressOnGender then ", 4 of 6" else ''}"
                          option value: 'white', 'aria-label': 'White, 5 of 6', "White#{if isKeyPressOnGender then ", 5 of 6" else ''}"
                          option value: 'prefer_not_to_say', 'aria-label': 'Prefer not to say, 6 of 6', "Prefer Not to say#{if isKeyPressOnGender then ", 6 of 6" else ''}"

                if showRaceAndEthnicity
                 div className: 'ProfileEdit-row',
                    span className: 'ProfileEdit-rowLabel', 'Ethnicity'
                    div className: 'SimpleSelect SimpleSelect--angle',
                        select className: "#{@err('ethnicity')}", 'aria-label': 'Ethnicity', defaultValue: ethnicity, ref: 'ethnicity', onChange: @onDataChange('ethnicity'), onKeyDown: @onEthnicityKeyPress,
                          option value: '', 'aria-label': 'Please select an option', 'Please select an option'
                          option value: 'hispanic', 'aria-label': 'Hispanic or Latino, 1 of 3', "Hispanic or Latino#{if isKeyPressOnGender then ", 1 of 3" else ''}"
                          option value: 'not_hispanic', 'aria-label': 'Not Hispanic or Latino, 2 of 3', "Not Hispanic or Latino#{if isKeyPressOnGender then ", 2 of 3" else ''}"
                          option value: 'prefer_not_to_say', 'aria-label': 'Prefer not to say, 3 of 3', "Prefer Not to say#{if isKeyPressOnGender then ", 3 of 3" else ''}"

                div className: 'ProfileEdit-row',
                  span className: 'ProfileEdit-rowLabel', id: 'preferred-lang', 'Preferred Language - optional'
                  div className: 'SimpleSelect SimpleSelect--angle',
                    select 'aria-label': 'Preferred Language - optional', value: patient.preferred_language, onChange: @onDataChange('preferred_language'), onKeyDown: @onLanguageKeyPress,
                      option value: '', langPlaceholder
                      option 'aria-hidden': "true", disabled: true, "\u2500".repeat(langPlaceholder.length / 2)
                      uniqueLanguageOptions.map (lang, index) ->
                        iso = lang[2]
                        displayName = "#{if lang.local != lang.name then "#{lang.local} (#{lang.name})" else "#{lang.local}"}"
                        option value: iso, key: iso, 'aria-label': "#{lang.name}, #{index + 1} of #{uniqueLanguageOptions.length}",
                          "#{displayName}#{if isKeyPressOnLanguage then ", #{index + 1} of #{uniqueLanguageOptions.length}" else ''}"

                this.renderEmail()

              div className: 'ContentBlock',
                h3 'Contact Information'
                div className: 'ProfileEdit-row',
                  label htmlFor: 'address', 'Address'
                  div className: 'address-block',
                    @field 'address', id: 'address', className: 'Input u-fullWidth', description: "Address#{if patient.address then '' else ', blank'}"
                div className: 'ProfileEdit-row',
                  span className: 'ProfileEdit-rowLabel', addressLabel
                  div className: 'u-flex u-flexRow ProfileEdit-row--multiInput',
                    @field 'city', id: 'city', className: 'Input u-flexChild', description: "City#{if patient.city then '' else ', blank'}"
                    if isCork or isSingapore
                      @field 'state', id: 'state', className: 'Input u-flexChild', description: "State#{if patient.state then '' else ', blank'}"
                    else
                      div className: 'u-flexChild SimpleSelect SimpleSelect--angle',
                        select className: "u-flexChild #{@err('state')}", title: 'State', defaultValue: patient.state, ref: 'state', onChange: @onDataChange('state'), onKeyDown: @onStateKeyPress,
                          config.states.map (state, index) ->
                            option value: state, key: state, 'aria-label': "#{state}, #{index + 1} of #{config.states.length}",
                              "#{state}#{if isKeyPressOnState then ", #{index + 1} of #{config.states.length}" else ''}"

                    @field 'zip', id: 'zip', className: 'Input u-flexChild', description: "Zip code#{if patient.zip then '' else ', blank'}"

                div className: 'ProfileEdit-row',
                  label htmlFor: 'home_phone', 'Home Phone - optional'
                  @field 'home_phone', id: 'home_phone', onChange: @setPrimaryContactDefault('home'), description: "Home Phone#{if patient.home_phone then '' else ', blank'}"

                div className: 'ProfileEdit-row',
                  label htmlFor: 'cell_phone', 'Mobile Phone'
                  @field 'cell_phone', id: 'cell_phone', onChange: @setPrimaryContactDefault('cell'), description: "Mobile Phone#{if patient.cell_phone then '' else ', blank'}"

                div className: 'ProfileEdit-row',
                  label htmlFor: 'work_phone', 'Work Phone - optional'
                  @field 'work_phone', id: 'work_phone', onChange: @setPrimaryContactDefault('work'), description: "Work Phone#{if patient.work_phone then '' else ', blank'}"

                div className: 'ProfileEdit-row',
                  span className: 'ProfileEdit-rowLabel', 'Preferred Contact'
                  div className: 'SimpleSelect SimpleSelect--angle',
                    select 'aria-label': 'Preferred Contact', className: "#{@err('primary_contact')}", value: patient.primary_contact || '', ref: 'primary_contact', onChange: @onDataChange('primary_contact'), onKeyDown: @onContactKeyPress,
                      preferredContacts.map (contact, index) ->
                        option value: contact.value, key: contact.value, 'aria-label': "#{contact.label}, #{index + 1} of #{preferredContacts.length}",
                          "#{contact.label}#{if isKeyPressOnContact then ", #{index + 1} of #{preferredContacts.length}" else ''}"

              # Emergency Details
              div className: 'ContentBlock',
                h3 'Emergency Contact'
                div className: 'ProfileEdit-row',
                  label htmlFor: 'emergency_name', 'Name'
                  @field 'emergency_name', id: 'emergency_name', description: "Emergency Contact Name#{if patient.emergency_name then '' else ', blank'}", maxLength: 50

                div className: 'ProfileEdit-row',
                  label htmlFor: 'emergency_relationship', 'Relationship'
                  @field 'emergency_relationship', id: 'emergency_relationship', description: "Emergency Contact Relationship#{if patient.emergency_relationship then '' else ', blank'}", maxLength: 50

                div className: 'ProfileEdit-row',
                  label htmlFor: 'emergency_phone', 'Phone'
                  @field 'emergency_phone', id: 'emergency_phone', description: "Emergency Contact Phone#{if patient.emergency_phone then '' else ', blank'}"

              # Insurance Details
              if patient.can_see_insurance
                if insurances
                  div className: 'ContentBlock ContentBlock--bottomMargin',
                    h3 'Insurance Information'
                    div className: 'ProfileEdit-insuranceBlock',
                      Object.keys(insurances).map (insKey) =>
                        ins = insurances[insKey]
                        div key:insKey,
                          div className: 'ProfileEdit-insuranceBlockTitle', ins.label or 'Insurance'
                          Object.keys(ins).filter((key) => key isnt 'label').map (insFieldKey) =>
                            insFieldLabel = ins[insFieldKey].name
                            insFieldDbKey= @getInsuranceKey(insKey, insFieldKey)
                            insFieldValue = ins[insFieldKey].value
                            displayValue = if insuranceUpdated then insuranceValues[insFieldDbKey] else insFieldValue
                            div className: 'ProfileEdit-row',
                              label htmlFor: 'insFieldDbKey', insFieldLabel
                              if insFieldKey is 'notes'
                                textarea className: 'Input TextArea--small', "aria-label": "#{ins.label} #{insFieldLabel} #{if displayValue then '' else ', blank'}", id: insFieldDbKey, value: displayValue, onChange: @onInsuranceValueChange(insFieldDbKey)
                              else
                                input className: 'Input', id: insFieldDbKey, "aria-label": "#{ins.label} #{insFieldLabel} #{if displayValue then '' else ', blank'}", value: displayValue, onChange: @onInsuranceValueChange(insFieldDbKey)
                else
                div className: 'ContentBlock ContentBlock--bottomMargin',
                  h3 'Insurance Information'
                  div className: 'ProfileEdit-row',
                    label htmlFor: 'insurance_plan', 'Plan'
                    @field 'insurance_plan', id: 'insurance_plan', description: "Insurance Plan#{if patient.insurance_plan then '' else ', blank'}"

                  div className: 'ProfileEdit-row',
                    label htmlFor: 'insurance_holder', 'Policyholder'
                    @field 'insurance_holder', id: 'insurance_holder', description: "Insurance Policyholder#{if patient.insurance_holder then '' else ', blank'}"

                  div className: 'ProfileEdit-row',
                    label htmlFor: 'insurance_id', 'Policy Number'
                    @field 'insurance_id', id: 'insurance_id', placeholder: 'XCV3451324S', description: "Insurance Policy Number#{if patient.insurance_id then '' else ', blank'}"
              this.renderFooter()
          Footer() # TODO: footer should not be in <main>
      )
    )
})

module.exports = withRouter(observer(EditProfile))
