"use strict";
const { observer } = require("mobx-react");
const Img = require("components/elements/Img");
const { ISO_DATE } = require("lib/formats");
const calStore = require("stores/cal");

const renderPic = ({ provider }) => {
  return (
    <div
      key={provider.id}
      className="ScheduleCalendarHeader-cell ScheduleCalendarHeader-pic"
    >
      {Img({ type: "avatar", src: provider.shot, alt: "" })}
    </div>
  );
};

const renderName = ({ provider }) => {
  return (
    <div
      key={provider.id}
      className="ScheduleCalendarHeader-cell ScheduleCalendarHeader-name"
    >
      {provider.name}
    </div>
  );
};

const renderDate = ({ date }) => {
  let iso = date.format(ISO_DATE);
  return (
    <time
      key={iso}
      dateTime={iso}
      className="ScheduleCalendarHeader-cell ScheduleCalendarHeader-date"
    >
      {date.format("ddd, MMM D")}
    </time>
  );
};

const CalendarHeader = () => {
  let { mode, days } = calStore;

  switch (mode) {
    case "day":
      return (
        <React.Fragment>
          <div className="ScheduleCalendarHeader">{days.map(renderPic)}</div>
          <div className="ScheduleCalendarHeader ScheduleCalendarHeader--sticky">
            {days.map(renderName)}
          </div>
        </React.Fragment>
      );
    case "week":
      return (
        <div className="ScheduleCalendarHeader ScheduleCalendarHeader--sticky">
          {days.map(renderDate)}
        </div>
      );
  }
};

module.exports = observer(CalendarHeader);
