"use strict";
const { cx } = require("lib/utils");

class Search extends React.Component {
  clearInput() {
    this.props.onChange({
      target: {
        value: "",
      },
    });
  }

  render() {
    let { modifier, ...props } = this.props;
    let wrapperClass = cx("Search", modifier);

    return (
      <div className={wrapperClass}>
        <input className="Search-input" type="search" {...props} />
        <button
          className="Search-clear"
          aria-label="Clear search"
          onClick={() => this.clearInput()}
        />
      </div>
    );
  }
}

Search.defaultProps = {
  onChange: () => {},
};

module.exports = Search;
