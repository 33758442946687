import ProvidersModal from "./ProvidersModal";
import appStore from "stores/app";
import careTeamsStore from "stores/care-teams";

export const showProvidersProfiles = (props) => {
  if (props.isPhone || props.isZoom) {
    appStore.actions.showModal({
      title: "Provider Profiles",
      body: props.renderProvidersProfiles(),
    });
  } else {
    props.toggleModal();
  }
};

const getProvidersProfiles = (props) => {
  const { scheduleStore, profilePageProfiles } = props;

  let providerProfiles = profilePageProfiles || scheduleStore.providersProfiles;

  return providerProfiles.map((pp) => {
    return Object.assign({}, pp, {
      scheduleCallback: () => {
        const { scheduleStore } = props;
        let prov = scheduleStore.providers.find(
          (prov) => prov.globalId == pp.globalId
        );

        if (prov) {
          careTeamsStore.setSelectedProvider(prov);
          scheduleStore.changeApptSelect = false;
          scheduleStore.providerId = prov.id;
        }
      },
    });
  });
};

export const ProvidersProfile = (props) => {
  return ProvidersModal({
    profiles: getProvidersProfiles(props),
    isPhone: props.isPhone,
    isZoom: props.isZoom,
    closeModal: () => props.closeModal(),
    renderScheduler: props.renderScheduler,
    className: props.className,
  });
};
