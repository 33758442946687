{client} = require('config')

{div, h2, p, button, ul, li, tch, strong, ol, u} = Exim.DOM

DependentSignUpModal = Exim.createView module.id,
  render: ->
    if client == 'nearsite'
      div className: 'DependentSignUpModel--content',
        div null,
          p null,
            'Please enter the following in the '
            strong {}, 'Work Email '
            'input section:'
          ol style: {paddingLeft: '30px'},
            li 'Amazon employee dependents must '
              u {}, 'use the personal email of the primary Amazon employee' 
            li 'All other members must '
              u {}, 'use the primary policy holders work email address'
          p 'This will allow us to confirm your eligibility as a dependent on the policy.'
        p null,
          'The '
          strong {}, 'Contact Email'
          ' for your account (the 2nd email on this screen) is the email that you used on the prior screen to register.'
          ' This email will also receive the appointment reminders and other notifications from the clinic.'

        tch tagName: 'button', className: "Button Button--primary Button--large Button--expand", handler: @props.close, 'Close'
    else
      div className: 'DependentSignUpModel--content',
        div null,
          p null,
            'Please enter the following in the '
            strong {}, 'Work Email Address of the Eligible Employee:'
          ol style: {paddingLeft: '30px'},
            li 'The email address the primary policy holder provided to their employer'
            li 'All other members must use the primary policy holders work email address'
          p 'This will allow us to confirm your eligibility as a dependent on the policy.'
        p null,
          'The '
          strong {}, 'Contact Email'
          ' for your account (the 2nd email on this screen) is the email that you used on the prior screen to register.'
          ' This email will also receive the appointment reminders and other notifications from the clinic.'

        tch tagName: 'button', className: "Button Button--primary Button--large Button--expand", handler: @props.close, 'Close'

module.exports = DependentSignUpModal
