import { cx } from "lib/utils";
import { observer } from "mobx-react";
import authStore from "stores/new-auth";

const Main = (props) => {
  let { className = "", patientHeader, ...rest } = props;
  if (!authStore.isProxying) {
    patientHeader = false;
  }

  let mainClass = cx("Main", { patientHeader });

  return (
    <main id="mainContent" className={`${mainClass} ${className}`} {...rest} />
  );
};

module.exports = observer(Main);
