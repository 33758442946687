request = require 'lib/request'

module.exports = store = Exim.createStore
  path: 'medications'

  actions: [
    'fetch'
    'refill'
    'fetchForPatient'
    'clear'
  ]

  initial:
    medications: []
    patientMeds:
      items: []
    refilling: false
    medicationsLoading: true

  fetch:
    while: (medicationsLoading) ->
      @set {medicationsLoading}
    on: ->
      request.get('medications').catch => @set {medicationsLoading: false}
    did: (medications) ->
      # medications = mockupMeds
      @set {medications}
    didNot: ->
      console.error("Can't load meds.")

  fetchForPatient:
    while: (patientMedsLoading) ->
      @set {patientMedsLoading}
    on: ->
      request.get('patient/medications')
    did: (patientMeds) ->
      @set {patientMeds}

  refill:
    while: (refilling) ->
      @set {refilling}
    on: (med) ->
      @med = med
      request.put("medications/#{med.id}", med)
    did: ->
      oldMed = @med
      medications = @get('medications').map (med) ->
        med.status = 'Requested' if oldMed.id is med.id
        med
      @set {medications}
    didNot: (data) ->
      console.error 'Refill failed'

  clear: ->
    @set medications: [], patientMeds: {items: []}

mockupMeds = [
  {
    "id": "18578063",
    "medication": "Lisinopril 20 mg tablet",
    "dose": "1",
    "unit": "tablets",
    "frequency": "Every morning",
    "status": "Requested"
  },
  {
    "id": "18578062",
    "medication": "Lovastatin 20 mg tablet",
    "dose": "1",
    "unit": "tablets",
    "frequency": "Every night",
    "status": "Requested"
  },
  {
    "id": "18578065",
    "medication": "Metformin hcl 850 mg tablet",
    "dose": "1",
    "unit": "tablets",
    "frequency": "Twice a day",
    "status": "Requested"
  }
]
