"use strict";
const { formats } = require("config");
const { extendObservable } = require("mobx");
const { observer } = require("mobx-react");
const Note = require("models/appointment-note");
const { ISO_DATE } = require("lib/formats");
const { cx } = require("lib/utils");
const { withEscapeHandler } = require("components/elements/new-modal");

const PopUp = withEscapeHandler((props) => {
  return (
    <React.Fragment>
      <div className="AppointmentsNotesModal-overlay" onClick={props.close} />
      {props.children}
    </React.Fragment>
  );
});

const styleNote = (note) => {
  if (note.indexOf("CV19.c") === 0) {
    note = note
      .replace(`CV19.c: | `, ``)
      .replace(/\::|\?:|\#:/g, "\r:")
      .replace(/\["|\"]/g, "\r ")
      .replace(/\", "/g, "\r, ");
  } else if (note.indexOf("Covid 19") === 0) {
    note = note
      .replace(`Covid 19: | `, ``)
      .replace(/\::|\?:|\#:/g, "\r:")
      .replace(/\["|\"]/g, "\r ")
      .replace(/\", "/g, "\r, ");
  } else {
    return note;
  }

  // note = note.replace(`Covid 19: | `, ``).replace(/\::|\?:|\#:/g, '\r\:').replace(/\["|\"]/g, '\r\ ').replace(/\", "/g, '\r\, ')
  const notes = note.split(";").map((t, i) => {
    const text = t.split(`:`);
    return (
      <div key={i}>
        <strong>{text[0]}: </strong>
        {text[1]}
      </div>
    );
  });

  return notes;
};

const renderNote = (note) => {
  let typeClass = cx("AppointmentsNotesModal-type", note.type);

  return (
    <tr key={note.id}>
      <td>
        <div className={typeClass} />
      </td>
      <td className="AppointmentsNotesModal-text" title={note.text}>
        {styleNote(note.text)}
      </td>
      <td>{note.author.name}</td>
      <td>
        <time dateTime={note.createdAt.format(ISO_DATE)}>
          {note.createdAt.format(formats.notesDateFormat)}{" "}
          {note.appointment.zoneAbbr}
        </time>
      </td>
      {note.isDeletable && (
        <td>
          <button
            className="AppointmentsNotesModal-deleteNote"
            aria-label="Delete note"
            onClick={() => note.delete()}
          />
        </td>
      )}
    </tr>
  );
};

class AppointmentsNotesModal extends React.Component {
  constructor(props) {
    super(props);

    extendObservable(this, {
      _note: new Note(),
    });

    this.saveNote = async (evt) => {
      evt.preventDefault();
      if (!this._note.isSaveable) return;

      this._note.appointment = this.props.appointment;

      try {
        await this._note.post();
      } catch (err) {
        console.error(err);
        alert("Failed to create a note.");
      } finally {
        this._note = new Note();
      }
    };

    this.handleChange = (evt) => {
      this._note.text = evt.target.value;
    };
  }

  get style() {
    let { rect } = this.props;

    return {
      top: `${rect.bottom}px`,
      left: `${rect.right}px`,
    };
  }

  renderForm() {
    return (
      <form className="AppointmentsNotesModal-form" onSubmit={this.saveNote}>
        <input
          className="Input AppointmentsNotesModal-noteText"
          placeholder="New note..."
          aria-label="Note text"
          value={this._note.text}
          onChange={this.handleChange}
        />
        <button
          className="Button AppointmentsNotesModal-addNote"
          disabled={!this._note.isSaveable}
        >
          Add Note
        </button>
      </form>
    );
  }

  render() {
    let { abort, appointment } = this.props;

    return (
      <PopUp close={abort}>
        <div className="AppointmentsNotesModal" style={this.style}>
          {this.renderForm()}
          <table className="AppointmentsNotesModal-table">
            <tbody>{appointment.notes.map(renderNote)}</tbody>
          </table>
        </div>
      </PopUp>
    );
  }
}

module.exports = observer(AppointmentsNotesModal);
