{ div, button } = Exim.DOM
ProviderListItem = require './ProviderListItem'
{ addEscapeKeyListener, removeEscapeKeyListener } = require('lib/utils/escapeKeyListeners')
utils = require 'lib/utils'

ProvidersModal = Exim.createView module.id,
  handleModalClick: (e) ->
    e.nativeEvent.stopImmediatePropagation()
    e.preventDefault()

  componentDidMount: ->
    closeButton = document.getElementById('provider-bios-modal-close-button')
    closeButton?.focus()
    providerList = document.querySelector('.profiles-appointment-page')
    providerList?.scrollIntoView()
    addEscapeKeyListener(document, @props.closeModal)

  componentWillUnmount: ->
    activatorElement = document.getElementById('provider-bios-modal-activator')
    activatorElement?.focus()
    removeEscapeKeyListener(document, @props.closeModal)

  shouldShowButton: ->
    {isPhone, isZoom} = @props
    showButton = true
    if isPhone is true then showButton = false 
    if isZoom is true then showButton = false
    return showButton

  render: ->
    renderScheduler = this.props.renderScheduler
    {closeModal} = @props    
    div className: "ProvidersModal #{@props.className}", style: @props.style, onClick: @handleModalClick,
      div className: 'ProvidersModal-wrap',
        @shouldShowButton() && button className: 'ProvidersModal-close', id: 'provider-bios-modal-close-button', 'aria-label': 'Close Provider Bios Dialog', onClick: closeModal, onKeyPress: utils.a11yClick(closeModal)
        div className: 'ProvidersModal-list',
          this.props.profiles?.map (profile) ->
            ProviderListItem
              key: profile.globalId
              globalId: profile.globalId
              providerName: profile.providerName
              providerBio: profile.providerBio
              avatarUrl: profile.avatarUrl
              scheduleCallback: profile.scheduleCallback
              renderScheduler: renderScheduler
              closeModal: closeModal

module.exports = ProvidersModal
