"use strict";
const { cx, MomentRange } = require("lib/utils");
const { ISO_MONTH, ISO_WEEK, ISO_DATE } = require("lib/formats");
const { range } = require("lodash");
const { observer } = require("mobx-react");
const { client } = require("config");

const EN_DASH = "\u2013";
const { fullDate, fullDateWeekday } = require("config").formats;

const formatWeek = (start) => {
  let end = start.clone().add(6, "days");
  return `${start.format(fullDate)} ${EN_DASH} ${end.format(fullDate)}`;
};

const getDays = (start) => {
  let end = start.clone().endOf("week");
  let range = new MomentRange(start, end);

  return range.by(1, "day");
};

const renderWeekday = (day) => {
  let full = day.format("dddd");

  return (
    <span key={day} className="Calendar-weekday" title={full}>
      {full.charAt()}
    </span>
  );
};

const renderWeekdays = () => {
  let start = moment().startOf("week");

  return (
    <div className="Calendar-weekdays" aria-hidden="true">
      {getDays(start).map(renderWeekday)}
    </div>
  );
};

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      month: null,
    };
  }

  componentWillReceiveProps({ onMonthChange, date }) {
    if (onMonthChange || !date) return;
    if (date.isSame(this.state.month, "month")) return;

    this.setState({
      month: date.clone().startOf("month"),
    });
  }

  get month() {
    return (
      this.state.month ||
      this.props.month ||
      this.props.date.clone().startOf("month")
    );
  }

  updateMonth(month) {
    let { onMonthChange } = this.props;
    if (onMonthChange) {
      onMonthChange(month);
    } else {
      this.setState({ month });
    }
  }

  nextMonth() {
    this.updateMonth(this.month.clone().add(1, "month"));
  }

  prevMonth() {
    this.updateMonth(this.month.clone().subtract(1, "month"));
  }

  select(date) {
    if (!date.isSame(this.props.date, "day")) {
      this.props.onChange(date);
    }
  }

  renderNav() {
    let { month } = this;
    let isCurrentMonth = moment().format("MM YYYY") === month.format("MM YYYY");

    return (
      <nav className="Calendar-nav">
        <button
          className="Calendar-angle"
          onClick={() => this.prevMonth()}
          disabled={this.props.disablePrev && isCurrentMonth}
          aria-label="View previous month"
        >
          <i className="fa fa-angle-left" aria-hidden={this.props.disablePrev && isCurrentMonth}></i>
        </button>
        <h3 className="Calendar-month">
          <time dateTime={month.format(ISO_MONTH)}>
            {month.format("MMMM YYYY")}
          </time>
        </h3>
        <button
          className="Calendar-angle"
          onClick={() => this.nextMonth()}
          aria-label="View next month"
          data-e2e="view_next_month"
        >
          <i className="fa fa-angle-right"></i>
        </button>
      </nav>
    );
  }

  get weeks() {
    let start = this.month.clone().startOf("week");
    return range(6).map((week) => start.clone().add(week, "week"));
  }

  renderWeek(week) {
    let isWeek = this.props.mode == "week";
    let Tag = isWeek ? "button" : "div";
    let selected = isWeek && week.isSame(this.props.date, "week");
    let weekClass = cx("Calendar-week", { selected });

    return (
      <Tag
        key={week.format(ISO_WEEK)}
        className={weekClass}
        onClick={isWeek ? () => this.select(week) : null}
        aria-label={isWeek ? formatWeek(week) : null}
        aria-current={selected}
      >
        {getDays(week).map(this.renderDay, this)}
      </Tag>
    );
  }

  renderDay(day) {
    let isDay = this.props.mode == "day";
    let Tag = isDay ? "button" : "span";
    let selected = isDay && day.isSame(this.props.date, "day");
    let active = this.props.isActive(day);

    let dayClass = cx("Calendar-day", {
      selected,
      active,
      outside: !day.isSame(this.month, "month"),
      today: day.isSame(moment(), "day"),
    });

    return (
      <Tag
        key={day.format(ISO_DATE)}
        className={dayClass}
        onClick={isDay ? () => this.select(day) : null}
        aria-label={isDay ? day.format(fullDateWeekday) : null}
        aria-current={selected}
        aria-disabled={!active}
        aria-hidden={!active}
        tabIndex={active ? 0 : -1}
        data-e2e={day.format(ISO_DATE)}
        style={
          active && (client === "microsoft" || client === "docker")
            ? { color: "#ffffff" }
            : null
        }
      >
        {day.date()}
      </Tag>
    );
  }

  render() {
    let { modifier, mode } = this.props;

    return (
      <div className={cx("Calendar", modifier, mode)}>
        {this.renderNav()}
        {renderWeekdays()}
        <div className="Calendar-weeks">
          {this.weeks.map(this.renderWeek, this)}
        </div>
      </div>
    );
  }
}

Calendar.defaultProps = {
  date: null,
  month: null,
  mode: "day",
  disablePrev: false,
  isActive: () => true,
  onChange: () => {},
};

module.exports = observer(Calendar);
