"use strict";
const { observer } = require("mobx-react");
const { dateTime } = require("lib/utils");
const calStore = require("stores/cal");
const { MIN_IN_PX } = calStore;

const MIN_IN_MS = new Date(0).setMinutes(1);
const UPDATE_DELAY = (1 / MIN_IN_PX) * MIN_IN_MS;
const DATE_TIME_FORMAT = "dddd, MMMM Do YYYY, h:mm:ss a";

class FutureLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = { top: null };
  }

  get displayStart() {
    return dateTime(this.props.date, calStore.displayRange.start);
  }

  get displayEnd() {
    return dateTime(this.props.date, calStore.displayRange.end);
  }

  get startOfNextDay() {
    let now = moment();
    let isStartOfNextDay =
      this.displayEnd.format(DATE_TIME_FORMAT) ===
      now.startOf("day").format(DATE_TIME_FORMAT);
    return isStartOfNextDay;
  }

  componentWillMount() {
    let diff = this.displayStart - moment();
    this._id = setTimeout(() => this.updateTop(), diff);
  }

  componentWillUnmount() {
    clearTimeout(this._id);
  }

  updateDisplayEndDate() {
    let isStartOfNextDay = this.startOfNextDay;

    if (isStartOfNextDay) {
      let newDisplayEnd = this.displayEnd.subtract(1, "minutes").add(1, "days");
      return newDisplayEnd;
    }

    return this.displayEnd;
  }

  updateTop() {
    let now = moment();
    let endDateAndTime = this.updateDisplayEndDate();

    if (now > endDateAndTime) return;

    let dur = moment.duration(now - this.displayStart);
    this.setState({ top: dur.asMinutes() * MIN_IN_PX });
    this._id = setTimeout(() => this.updateTop(), UPDATE_DELAY);
  }

  render() {
    let { top } = this.state;
    if (top == null) return null;

    return (
      <div
        className="ScheduleCalendarDay-futureLine"
        style={{ top: `${top}px` }}
      />
    );
  }
}

module.exports = observer(FutureLine);
