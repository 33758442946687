"use strict";
const { observer } = require("mobx-react");
const { ISO_DATE } = require("lib/formats");
const calStore = require("stores/cal");
const CalendarNav = require("./calendar-nav");
const CalendarHeader = require("./calendar-header");
const Day = require("./Day");
const TimeLabels = require("./time-labels");

const renderDay = ({ provider, date }) => {
  let isoDate = date.format(ISO_DATE);

  return (
    <Day key={`${provider.id}/${isoDate}`} provider={provider} date={date} />
  );
};

class Schedule extends React.Component {
  renderDays() {
    let { duration } = calStore.displayRange;
    let style = {
      height: `${calStore.getHeight(duration)}px`,
    };

    return (
      <div className="ScheduleCalendar-days" style={style}>
        {calStore.days.map(renderDay)}
      </div>
    );
  }

  renderContent() {
    if (!calStore.days.length) {
      return calStore.providerFilter.type == "ACTIVE_PROVIDERS"
        ? "There are no active providers for this date."
        : "There are no providers for this date.";
    }

    return (
      <React.Fragment>
        <TimeLabels />
        <div className="ScheduleCalendar-wrapper">
          <CalendarHeader />
          {this.renderDays()}
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <CalendarNav />
        <div className="ScheduleCalendar-body">{this.renderContent()}</div>
      </React.Fragment>
    );
  }
}

module.exports = observer(Schedule);
