{div, tch, span, h2, fa, img, i, strong} = Exim.DOM

Widget = Exim.createView module.id,

  onWidgetClick: (e) ->
    e.stopPropagation()
    @props.onClick?()

  dismiss: (e) ->
    e.stopPropagation()
    @props.dismiss?()

  focus: ->
    @refs.widget.querySelector('.LandingWidgetAction')?.focus()

  render: ->
    {icon, title, body, actions, dismiss, innerHtml, more, titleClassName = '', ariaLabelledById = '', wrapperClassName = '', actionClassName = ''} = @props

    div className: "LandingWidget #{wrapperClassName}", ref: 'widget', onClick: @onWidgetClick,
      if dismiss
        tch tagName: 'button', id: ariaLabelledById, 'aria-label': "Close What's New widget", className: 'LandingWidget-dismiss', handler: @dismiss,
          i className: "fa fa-times", role: 'img', 'aria-labelledby': ariaLabelledById
      div className: 'LandingWidgetContent',
        div className: 'LandingWidgetHeader',
          if icon
            if icon is 'new'
              div className: 'LandingWidgetIcon LandingWidgetIcon--new'
            if icon is 'wellnessLandingWidgetIcon'
              img src: '/combo/images/hra/icon-heart-gray.png', className: 'WellnessLandingWidgetIcon'
            else
              div className: 'LandingWidgetIcon',
                fa icon
          if title
            h2 className: "LandingWidgetTitle #{titleClassName}", title
        if body
          div className: 'LandingWidgetBody',
            if typeof body is 'function' then body() else body
            if innerHtml
              div className: 'LandingWidgetBody-content', dangerouslySetInnerHTML: { __html: innerHtml }

        if more
          tch tagName: 'a', href: '#', 'aria-label': 'Read more about What\'s New', className: 'LandingWidgetBody-more', handler: more, 'Read more'

      if actions
        div className: 'LandingWidgetFooter', 'data-e2e': "#{title}WidgetFooter",
          actions.map (action) ->
            tch tagName: 'button', role: 'link', key: action.name, className: "LandingWidgetAction #{actionClassName} " + (if action.primary then "LandingWidgetAction--primary" else "LandingWidgetAction--secondary"), handler: action.handler,
              if action.primary then action.name else strong action.name
              span className: 'LandingWidgetIcon--button',
                if action.icon then fa action.icon


module.exports = Widget
