request       = require 'lib/request'
cache         = require 'lib/cache'
config        = require 'config'
patientStore  = require 'stores/patient'
ta            = require 'lib/touch-auth'
compressImage = require 'lib/compress-image'
{isEmpty, isEqual}     = require 'lodash'

MAX_AVATAR_SIZE = 3*1024*1024

getFileExt = (filename) ->
  /[^.]+$/.exec filename

postAvatar = (file, fname) ->
  form = new FormData
  if file
    filename = if file instanceof Blob and file.type is 'image/png'
      "avatar-#{Date.now().toString()}.png"
    else
      "avatar-#{Date.now().toString()}." + getFileExt fname

    form.append 'file', file, filename

  request.form('user/avatar', form)

compressAvatar = (file) ->
  compressImage(file).then((compressedImage) ->
    if compressedImage.size > MAX_AVATAR_SIZE
      compressAvatar file
    else
      Promise.resolve compressedImage
  )

updateRollbarPerson = (response) ->
  Rollbar?.configure({ payload: { person: { id: response.id } } })

module.exports = store = Exim.createStore
  path: 'auth'
  actions: [
    'checkFingerprintAuth'
    'checkStoredCreds'
    'fetchUser'
    'uploadAvatar'
    'removeAvatar'
    'signin'
    'signinFingerprintAuth'
    'signout'
    'signup'
    'forgot'
    'lookup'
    'xopEligibilityCheck'
    'setWorkEmail'
    'startPreflight'
    'endPreflight'
    'preloadImpersonation'
    'startImpersonation'
    'finishImpersonation'
    'updateProfile'
    'updateAccount'
    'checkPin'
    'clearPin'
    'loadInitialError'
    'clearError'
    'clearPasswordReset'
    'changeState'
    'verify'
    'resetPassword'
    'fetchOAuthProviderLinks'
    'setLastStep'
    'resetLastStep'
    'setFinishSigningUp'
    'resetFinishSigningUp'
    'fetchEmployers'
  ]

  initial:
    loggedIn: false
    user: {}
    impersonationTarget: null
    impersonatingUser: null
    error: false
    pinCorrect: false
    resetSuccess: false
    preflightCheckin: false
    canFingerprintAuth: false
    xopEligible: false
    workEmail: null
    storedAuth: null
    verificationEmail: ''
    oAuthProviderLinks: []
    metadata: { lastStep: 0 }
    finishSigningUp: false
    employers: []

  checkFingerprintAuth:
    on: ->
      ta.fingerprintSupported()
    did: (canFingerprintAuth) ->
      @set 'canFingerprintAuth', canFingerprintAuth

  checkStoredCreds:
    on: ->
      return unless cordova?.plugins?.SecureStorage

      new Promise (resolve, reject) =>
        ta.ssInit().then (ss) =>
          @set 'ss', ss
          ta.ssGet(ss, 'email').then resolve, () -> resolve(false)
        , () -> resolve(false)
    did: (email) ->
      @set 'storedAuth', email

  fetchUser:
    will: ->
      @set 'error', false
    on: ->
      request.get('user')
    did: (user) ->
      if !isEqual(user, @get('user'))
        if upd = @get('avatarUpdatedAt')
          user.avatar_url = "#{user.avatar_url}?#{upd}"

        data =
          error: false
          loggedIn: true
          user: user

        if user.proxying
          data.impersonationTarget = user
        if user.admin && !user.proxying
          data.impersonationTarget = null
          data.impersonatingUser = null

        @set data

        updateRollbarPerson(user)
        patientStore.actions.fetch() if user.proxying
    didNot: ->
      # Do nothing. We are not authenticated.

  signinFingerprintAuth:
    while: (signingIn) ->
      @set {signingIn}
    will: ->
      @set 'error', false
    on: ->
      ss = @get 'ss'
      new Promise (resolve, reject) ->
        ta.ssGetPair(ss, 'email', 'password').then((data) ->
          ta.fingerprintAuth("Signing in as: #{data.email}").then(
            () -> request.post('session', data)
          )
        ).then(resolve, reject)
    did: (response) ->
      @set
        error: false
        user: response
        loggedIn: true
      updateRollbarPerson(response)
    didNot: (resp) ->
      if resp is 'cancel'
        Promise.resolve()
      else if resp is 'mismatch'
        Promise.resolve(@set error: 'finger')
      else
        Promise.resolve(@set error: true) #tmp hack

  signin:
    while: (signingIn) ->
      @set {signingIn}
    on: (args, saveTouch) ->
      @_saveTouch = if saveTouch then args else null
      request.post('session', args)
    did: (response) ->
      @set
        error: false
        user: response
        loggedIn: true
      if window.utag
        window.utag.link({
          event_type: "login",
          tealium_event: "user_login",
          method: "email",
          user_logged_in: 1
        });

        


      synopsisStore = require './synopsis'
      synopsisStore.actions.fetch()
      updateRollbarPerson(response)

      ss = @get 'ss'
      if @_saveTouch
        ta.ssWritePair(ss, 'email', @_saveTouch.email, 'password', @_saveTouch.password)
      else
        ta.ssDeletePair(ss, 'email', 'password')
    didNot: (resp) ->
      Promise.resolve(@set error: resp.error)

  signout:
    on: ->
      req = request.del('session')
    did: (args) ->
      @set
        error: false
        user: {}
        loggedIn: false
        pinCorrect: false
        impersonationTarget: null
        impersonatingUser: null
      localStorage.removeItem('site')
      localStorage.removeItem('whatsNewDismissed')

  forgot:
    while: (sendingForgot) ->
      @set {sendingForgot}
    on: (email) ->
      request.post('user/forgot', {email})
    didNot: ({error}) ->
      @set error: error

  resetPassword:
    while: (sendingReset) ->
      @set {sendingReset}
    on: (code, password) ->
      request.post('user/password', {code, password})
    did: (resp) ->
      if resp.oops
        @set resetError: resp.oops
      else
        @set resetSuccess: true
    didNot: (err) ->
      @set resetSuccess: false

  lookup:
    while: (lookingUp) ->
      @set {lookingUp}
    on: (args) ->
      @creds = args
      request.post('user/lookup', {email: args.email})
    did: ->
      @set error: false, email: @creds.email, password: @creds.password
    didNot: (resp) ->
      Promise.resolve(@set error: resp.error)

  xopEligibilityCheck:
    while: (sendingXoEligibilityCheck) ->
      @set {sendingXoEligibilityCheck}
    on: (args) ->
      email = @get('workEmail')
      request.post('eligibility/search', {
        dob: args.dob,
        last_name: args.lastName,
        work_email: email,
        employee_id: args.employeeId,
        ssn: args.ssn
      })
    did: (data) ->
      {results} = data
      if isEmpty(results)
        @set xopEligible: false
      else
        @set xopEligible: data.results[0]['xop_eligible']
    didNot: ->
      Promise.resolve(@set error: true)

  setWorkEmail: (workEmail) ->
    @set xopEligible: false
    @set {workEmail}

  setLastStep: (lastStep) ->
    @set metadata: { lastStep }

  resetLastStep: ->
    @set metadata: { lastStep: 0 }

  setFinishSigningUp: ->
    @set finishSigningUp: true

  resetFinishSigningUp: ->
    @set finishSigningUp: false

  signup:
    while: (signingUp) ->
      @set {signingUp}
    on: (credentials) ->
      request.post('user', credentials)
    did: (data) ->
      @set {verificationEmail: data.verification_email}
      @reset 'error'
      if window.utag
        window.utag.link({
          event_type: "account_created",
          tealium_event: "account_created",
          plan_category: ""
          plan_type: ""
        });

    didNot: (resp) ->
      Promise.resolve(@set error: true)

  fetchOAuthProviderLinks:
    on: ->
      request.get('identity-providers')
    did: (response) ->
      { providers } = response
      @set { oAuthProviderLinks: providers }
    didNot: (resp) ->
      console.log resp

  fetchEmployers:
    on: ->
      request.get('employers')
    did: (response) ->
      # console.log("Employers here ",response)
      { employers } = response 
      
      @set { employers: response}
    didNot: (resp) ->
      console.log resp

  uploadAvatar:
    while: (avatarUploading) ->
      @set {avatarUploading}
    on: (args) ->
      {file, filename} = args
      unless filename
        filename = file.name
      compressAvatar(file).then((compressedAvatar) ->
        postAvatar compressedAvatar, filename
      )
    did: (data) ->
      now = Date.now()
      user = @get('user')
      user.avatar_url = data.avatar_url + "?#{now}"
      @set {user, avatarUpdatedAt: now}
    didNot: (resp) ->
      console.error resp

  removeAvatar:
    while: (avatarRemoving) ->
      @set {avatarRemoving}
    on: (args) ->
      request.del('user/avatar')
    did: (data) ->
      user = @get('user')
      user.avatar_url = data.avatar_url
      @set {user, avatarUpdatedAt: null}
    didNot: (resp) ->
      console.error resp

  startPreflight: ->
    @set preflightCheckin: true

  endPreflight: ->
    @set preflightCheckin: false

  preloadImpersonation: (user) ->
    user.patient_id = user?.outside_id
    user.gender = user?.gender?[0]
    user.full_name = user?.name
    cache.clear()
    @set impersonatingUser: user

  startImpersonation:
    while: (impersonationLoading) ->
      @set {impersonationLoading}
    will: (userId, appointment_id, appointment_at) ->
      request.post('impersonation', {id: userId, appointment_id, appointment_at})
    on: (patient) ->
      if patient.admin
        @actions.finishImpersonation()
        throw Error 'Patient is admin'
      else
        @set
          impersonationTarget: patient
          user: patient
          impersonatingUser: null
        cache.clear()
        patientStore.actions.fetch()
        patientStore.actions.fetchPCPs()

        scheduleStore = require('stores/schedule').default
        scheduleStore.fetchProvidersProfiles()

        synopsisStore = require './synopsis'
        synopsisStore.actions.fetch()
    didNot: (err) ->
      console.error err.message

  finishImpersonation:
    will: ->
      request.del('impersonation')
    on: (user) ->
      @set
        impersonationTarget: null
        user: user
        impersonatingUser: null
      cache.clear()
      patientStore.actions.fetch()

      synopsisStore = require './synopsis'
      synopsisStore.actions.fetch()

  setAvatar: (avatar) ->
    @set {avatar}

  updateProfile:
    on: (newProfile) ->
      p = config.defaultProfile

      Promise.resolve
        dob: p.dob
        employee_id: p.employee_id
        xoid: p.xoid

    did: (newProfile) ->
      @set 'profile', newProfile

  updateAccount:
    on: (newAccount) ->
      # TODO: perform an actual request
      new Promise (resolve) ->
        newAccount.current_password = null
        newAccount.new_password = null

        Object.keys(newAccount.consents).forEach (cons) ->
          newAccount.consents[cons] = new Date if newAccount.consents[cons]

        resolve newAccount
    did: (newAccount) ->
      @set 'account', newAccount

  checkPin:
    while: (checkingPin) ->
      @set {checkingPin}
    will: ->
      @reset 'error'
      # @reset 'pinCorrect'
    on: (pin) ->
      request.post 'checkin/pin', {pin}
    did: (response) ->
      @set pinCorrect: true, error: null
    didNot: (resp) ->
      @set pinCorrect: false, error: 'pin'

  clearPin: ->
    @set 'pinCorrect', false

  clearError:
    while: (clearingError) ->
      @set {clearingError}
    on:  ->
      @set 'error', null

  loadInitialError: (error) ->
    @set 'error', error

  clearPasswordReset: ->
    @set resetSuccess: false, resetError: false

  changeState: (state) ->
    user = @get('user')
    user.checkin_state = state
    @set 'user', user

  verify: ->
    request.post('patient/admin', {type: 'verify'})
