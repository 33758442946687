export const genderIdentityMappingArray = [
  {
    label: "Woman",
    value: "woman",
  },
  { label: "Man", value: "man" },
  {
    label: "Transgender woman / transfeminine",
    value: "transgender_woman",
  },
  {
    label: "Transgender man / transmasculine",
    value: "transgender_man",
  },
  {
    label: "Non-binary / genderqueer / gender fluid",
    value: "nonbinary",
  },
  { label: "Two-Spirit", value: "two_spirit" },
  {
    label: "Option not listed (please inform provider)",
    value: "option_not_listed",
  },
  {
    label: "Prefer not to say",
    value: "prefer_not_to_say",
  },
  {
    label: "Don’t know",
    value: "do_not_know",
  },
];

export const sexualOrientationMappingArray = [
  {
    label: "Asexual",
    value: "asexual",
  },
  {
    label: "Bisexual",
    value: "bisexual",
  },
  {
    label: "Gay",
    value: "gay",
  },
  {
    label: "Lesbian",
    value: "lesbian",
  },
  {
    label: "Straight",
    value: "straight",
  },
  {
    label: "Queer",
    value: "queer",
  },
  {
    label: "Don’t know",
    value: "do_not_know",
  },
  {
    label: "Option not listed (please inform provider)",
    value: "option_not_listed",
  },
  {
    label: "Prefer not to say",
    value: "prefer_not_to_say",
  },
];

export const pronounsMappingArray = [
  {
    label: "She / Her / Hers",
    value: "she_her_hers",
  },
  {
    label: "He / Him / His",
    value: "he_him_his",
  },
  {
    label: "They / Them / Theirs",
    value: "they_them_theirs",
  },
  {
    label: "Option not listed (please inform provider)",
    value: "option_not_listed",
  },
];

export const raceMappingArray = [
  {
    label: "American Indian or Alaskan Native",
    value: "american_indian",
  },
  {
    label: "Asian",
    value: "asian",
  },
  {
    label: "Black or African American",
    value: "black",
  },
  {
    label: "Native Hawaiian or Other Pacific Islander",
    value: "native_pacific",
  },
  {
    label: "White",
    value: "white",
  },
  {
    label: "Prefer not to say",
    value: "prefer_not_to_say",
  },
];

export const ethnicityMappingArray = [
  {
    label: "Hispanic or Latino",
    value: "hispanic",
  },
  {
    label: "Not Hispanic or Latino",
    value: "not_hispanic",
  },
  {
    label: "Prefer not to say",
    value: "prefer_not_to_say",
  },
];

export const singaporeEthnicityMappingArray = [
  {
    label: "Chinese",
    value: "chinese",
  },
  {
    label: "Malay",
    value: "malay",
  },
  {
    label: "Indian",
    value: "indian",
  },
  {
    label: "Others (unspecified)",
    value: "others",
  },
];
