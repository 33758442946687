{appStore} = require 'stores'
Disclaimer = require 'components/auth/Disclaimer'
attachHeader = require 'components/mixins/attachHeader'
AuthWrapper = require 'components/auth/auth-wrapper'
StandardNotification = require 'components/auth/signin/StandardNotification'
RetrofitNotification = require 'components/auth/signin/RetrofitNotification'
FacebookRetrofitNotification = require 'components/auth/signin/FacebookRetrofitNotification'
MetaBayAreaRetrofitNotification = require 'components/auth/signin/MetaBayAreaRetrofitNotification'

{Link} = Exim.Router
{cx}   = Exim.helpers
{div, span, p, form, input, button, a, tch, label, fa, img, strong} = Exim.DOM

SignInNotification = Exim.createView module.id,
  staticView: true
  noHeader: true
  fadeIn: true
  mixins: [attachHeader(null)]
  listen: ['auth/error', 'auth/loggedIn', 'auth/oAuthProviderLinks']

  contextTypes:
    router: React.PropTypes.object.isRequired

  componentDidMount: ->
    appStore.actions.setTitle('Sign In')

  componentWillUnmount: ->
    appStore.actions.setTitle()

  goToPortal: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    @context.router.push pathname: '/'

  render: ->
    {security} = appStore.get('config')
    user = @props.router.location.state.user
    { retrofit_signin_message, retrofit_date, fbny_retrofit_user, meta_bay_area_user } = user

    showRetrofitMessage = retrofit_signin_message
    retrofitDate = retrofit_date
    isFacebookNyUser = fbny_retrofit_user
    isMetaBayAreaUser = meta_bay_area_user

    return React.createElement(AuthWrapper, {title: 'Sign In To Your Account'},
      div className: 'AuthBlockGroup',
        div className: 'AuthCard AuthCard-SignIn',
          div className: "SignInForm SignInForm--withTouchID",
            div className: "SignInNotificationForm", ref: 'form',
              if showRetrofitMessage && isFacebookNyUser
                React.createElement(FacebookRetrofitNotification, { retrofitDate })
              else if isMetaBayAreaUser
                 React.createElement(MetaBayAreaRetrofitNotification, { retrofitDate })
              else if showRetrofitMessage
                React.createElement(RetrofitNotification, { retrofitDate })
              else
                React.createElement(StandardNotification, {})

        if Object.keys(security || {}).length
          Disclaimer()
    )

module.exports = SignInNotification
