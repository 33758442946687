{appStore, messagesStore, patientStore, authStore} = require 'stores'

newAuthStore  = require 'stores/new-auth'
PullToRefresh = require 'components/elements/PullToRefresh'
Search        = require 'components/mixins/Search'
Footer        = require 'components/app/Footer'
media         = require 'components/mixins/MediaQuery'
PatientHeader = require 'components/elements/PatientHeader'

config = require 'config'
utils = require 'lib/utils'
{cx} = Exim.helpers
{main, section, div, fa}  = Exim.DOM

Messages = Exim.createView module.id,
  mixins: [
    authStore.connect('user')
    patientStore.connect('patient')
    Search
    media(mobile: 'md')
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    isMounted: false

  getActions: ->
    {isViewingAsMigratedUser} = newAuthStore
    if isViewingAsMigratedUser
      return []
    return [{
      title: 'New Message',
      to: '/messages/new',
      icon: 'envelope',
      params: null,
      query: if @state.user.proxying then recipients: @state.patient.patient_id else {},
      key: 'messages-create',
    }]

  componentWillMount: ->
    authStore.actions.fetchUser()
    messagesStore.onChange @updateFetching
    appStore.actions.setHeader(name: '', actions: this.getActions(), noHover: true)

  searchKeys: ->
    return [
      'subject',
      (item) => item.from?.name,
      (item) => item.to?.map((m) => m.name).join(' '),
      (item) => moment(item.date).format('MM/DD/YY'),
    ]

  componentDidMount: ->
    search =
      if !@state.mobile and (@isActive('/messages', true) or @isActive('/messages/sent') or @isActive('messages/archived'))
        @onSearch
      else
        null

    actions =
      if this.isActive('/messages/new')
        null
      else
        this.getActions()

    appStore.actions.updateHeader({search, actions, clearSearch: @clearSearch})
    @setState isMounted: true

  componentWillUpdate: (nextProps, nextState) ->
    search =
      if !nextState.mobile and (@isActive('/messages', true) or @isActive('/messages/sent') or @isActive('messages/archived'))
        @onSearch
      else
        null

    actions =
      if this.isActive('/messages/new')
        null
      else
        this.getActions()

    appStore.actions.updateHeader({search, actions, clearSearch: @clearSearch})

  componentWillUnmount: ->
    unless @context.router.isActive('/messages')
      messagesStore.actions.clear()
      messagesStore.actions.clearMessage()
      messagesStore.actions.clearSavedMessage()
    messagesStore.offChange @updateFetching

  onLoad: ->
    if @state.isMounted
      @setState loaded: true

  onUnload: ->
    if @state.isMounted
      @setState loaded: false

  isActive: (path, indexRoute) ->
    @context.router.isActive path, indexRoute

  fetch: ->
    path = if @isActive('/messages', true)
      'received'
    else if @isActive('/sent')
      'sent'
    else if @isActive('/archived')
      'archived'
    else if @isActive('/flagged')
      'flagged'
    else if @isActive('/unread')
      'unread'

    messagesStore.actions.fetchMessages(path, 1) if path

  onSortChange: (sort) ->
    @setState {sort}

  updateFetching: ->
    if @state.isMounted
      messagesFetching = messagesStore.get('messagesFetching')
      @setState {messagesFetching}

  render: ->
    args =
      className: 'Content-body'
      tag: section
      onRefresh: @fetch
      isLoading: @state.loaded and @state.messagesFetching
      static: !(@isActive('/messages', true) or @isActive('/messages/sent', true))
    noPadding = (@isActive('/messages', true) or @isActive('/messages/sent') or @isActive('messages/archived'))

    main id: 'mainContent', className: "Content #{cx 'Content--withPatientHeader': @state.user.proxying}",
      PullToRefresh args,
        div className: 'ContentInnerWrapper',
          PatientHeader()
          div className: 'ContentInner',
            React.cloneElement @props.children, {matcher: @state.matcher, filterer: @filterer, loaded: @state.loaded, sort: @state.sort, @onLoad, @onUnload, @onSortChange, @onSearch, @clearSearch}
          Footer()

module.exports = Messages
