Switcher = require 'components/elements/Switcher'
Checkbox = require 'components/elements/Checkbox'
Spinner  = require 'components/elements/Spinner'
Select   = require 'components/elements/SimpleSelect'
utils    = require 'lib/utils'
config   = require 'config'
validate = require 'lib/validate'
{cx} = Exim.helpers
{div, input, textarea, spinner, tch} = Exim.DOM

re =
  phone: /phone/
  birthday: /dob|birthdate/
  email: /email/
  password: /password/
  gender: /gender/
  name: /name/

Fields =
  getInitialState: ->
    errors: []

  getFields: ->
    @state[@dataName]

  getValidator: (key) ->
    validate[key]

  getComplexChecker: (key) ->
    validate.complex[key]

  pushError: (name) ->
    {errors} = @state
    state = {errors}
    errors.push(name) unless errors.indexOf(name) >= 0
    @setState state

  clearError: (name) ->
    {errors} = @state
    if errors.length
      index = errors.indexOf(name)
      errors.splice(index, 1) if index >= 0
    @setState {errors}

  check: (key, value, tagName) ->
    fn = validate?[key]
    if fn
      fn value
    else if tagName is 'SELECT'
      if typeof value is 'string'
        value isnt '0' and value.length > 0
      else
        value > 0
    else if tagName is 'RADIO'
      value isnt undefined
    else
      !!value

  transformers:
    phone: (name, target) ->
      {value} = target

      formatted = utils.toPhone(value)
      data = @getFields()
      state = {}

      if formatted and formatted.length
        data[name] = formatted
        target.value = formatted

      state[@dataName] = data
      @setState state

  transform: (type, name) -> (event) =>
    trans = @transformers[type].bind(this)
    trans name, event.currentTarget

  filterErrors: (fieldName) ->
    @setState errors: @state.errors.filter((item) -> item isnt fieldName)

  formatCardNumber: (name) -> (evt) =>
    {value} = evt.currentTarget

    return if value.length > 19
    if value.length > @getFields()[name]?.length
      value =
        switch value.length
          when 4, 9, 14 then "#{value} "
          else value
       console.log value

    @updateData(name, value)

  checkMonth: (event) ->
    el = event.currentTarget
    months = config.monthsShort
    val = el.value.trim()
    num = parseInt(val, 10)
    val = switch
      when 1 <= num <= 12 then months[num - 1]
      when ~(num = months.indexOf(val[0..2].toLowerCase())) then months[num]
      else ''
    val = val[0].toUpperCase() + val[1..-1] if val
    @updateData('birthdate_month', val)
    el.value = val

  checkDay: (event) ->
    el = event.currentTarget
    val = el.value.trim()
    num = parseInt(el.value.trim(), 10)
    val = '' unless 1 <= num <= 31
    @updateData('birthdate_day', val)
    el.value = val

  checkYear: (event) ->
    el = event.currentTarget
    year = moment().year()
    yy = year % 100
    num = parseInt(el.value.trim(), 10)
    val = switch
      when (year - 100) <= num <= year then num
      when           yy <  num <  100  then num + 1900
      when           0  <= num <= yy   then num + 2000
      else ''
    @updateData('birthdate_year', val)
    el.value = val

  validateDob: ->
    errors = []
    try
      data = @getFields()
      dobm = data.birthdate_month
      dobd = data.birthdate_day
      doby = data.birthdate_year

      errors.push 'birthdate_month' if !dobm
      errors.push 'birthdate_day' if !dobd
      errors.push 'birthdate_year' if !doby

      nice = "#{dobm} #{dobd}, #{doby}"
      if ~(mon = config.monthsShort.indexOf(dobm[0..2].toLowerCase()))
        dobm = mon + 1
        dobd = parseInt(dobd, 10)
        doby = parseInt(doby, 10)
        dob  = moment(nice, 'MMM D, YYYY')

        errors.push 'birthdate_month' unless dob.month() + 1 is dobm
        errors.push 'birthdate_day' unless dob.date() is dobd
        errors.push 'birthdate_year' unless dob.year() is doby

        if (dob.month() + 1 is dobm) && (dob.date() is dobd) && (dob.year() is doby)
          @updateData 'birthdate', dob.format('MM/DD/YYYY')
    catch
    errors


  checkValidation: (field) -> (event) =>
    {value} = event.currentTarget
    checker = @getValidator field

    return unless checker and value.length

    if checker(value)
      state = {}
      state[field] = value
      @filterErrors field
    else
      @setState errors: @state.errors.concat(field)

  getFieldArgs: (name, options, useValue) ->
    args =
      ref: name,
      name: name,
      id: options?.id,
      className: (options?.className or 'Input') + " #{@err(name)}",
      'aria-invalid': (if name in @state.errors then true else false)
      'aria-label': name,
      placeholder: @errPlaceholder(name, options?.placeholder),
      disabled: options?.disabled,
      readOnly: options?.readOnly,
      onBlur: options?.onBlur,
    if useValue or options?.useValue
      args.value = @getInitialData()[name]
      args.onChange = @onDataChange(name, options?.onChange)
    else if !args.value
      args.defaultValue = @getInitialData()[name]
    if options?.onChange
      args.onChange = options.onChange
    if options?.description
      args['aria-label'] = options.description
    if options?.required
      args['aria-required'] = options?.required
    args.maxLength = options.maxLength if options?.maxLength
    args

  field: (type, name, args) ->
    # Allow 2-arity.
    if typeof name is 'object' or !name
      args = name
      name = type
      type = 'input'

    # Check type.
    tag = Exim.DOM[type]
    throw new TypeError "No such tag: #{type}" unless tag

    options = @getFieldArgs(name, args)
    options.type = 'email' if re.email.test(name)
    options.type = 'password' if re.password.test(name)

    blurs = []

    # Check .validate.
    if options.validate isnt false
      blurs.push @checkValidation(name)
      options.validate = null

    #format phone on blur
    if re.phone.test(name)
      blurs.push @transform('phone', name)

    blurs.push options.onBlur if options.onBlur

    options.onBlur = (event) =>
      @onDataChange(name, args?.onChange)(event)
      blurs.forEach((fn) -> fn(event))

    options.onFocus = =>
      @clearError(name)

    if config.isIpad
      options.onKeyUp = (evt) ->
        document.activeElement.style.opacity = 0.95
        fn = -> document.activeElement.style.opacity = 1
        setTimeout(fn)
        args.onKeyUp?(evt)

    delete options.validate
    tag options

  textarea: (name, opts={}) ->
    required = @required or []
    opts.required = "true" if name in required
    @field 'textarea', name, opts

  cardNumber: (name, opts={}) ->
    commonData = @getInitialData()
    className = (opts.className or '') + " #{@err(name)}"
    input type: 'text', className: className, value: commonData[name], placeholder: opts.placeholder, ref: name, onChange: @formatCardNumber(name)

  select: (name, options, opts={}) ->
    opts.angle ?= true
    values = options.map (o, i) ->
      val = switch typeof o
        when 'string' then options.indexOf(o) + 1
        when 'number' then o
        when 'object' then o.value

      value: val, label: "#{o}"

    values.unshift({ value: 0, label: 'Select an option (required)', disabled: true, selected: true }) if name in @required

    args = Object.assign {
      angle: opts.angle
      values
      idPath: 'value'
      "aria-required": true if name in @required
      label: (o) -> o.label
      className: @err(name)
    }, @getFieldArgs(name, opts, true)

    Select args

  switcher: (name, opts={}) ->
    Switcher
      checked: @getFields()[name]
      onChange: @onDataChange(name)
      className: opts.className
      error: name in @state.errors
      secondaryColor: opts.secondaryColor if opts.secondaryColor

  checkbox: (name, opts={}) ->
    className = opts.className or ''
    className += " #{cx error: name in @state.errors}"
    Checkbox
      ref: name
      type: 'checkbox'
      checked: @getFields()[name]
      onChange: @onDataChange(name)
      className: className
      'aria-labelledby': opts['aria-labelledby']

  disable: (evt) ->
    evt.currentTarget.style.pointerEvents = 'none'

  setPwField: (field) -> (evt) =>
    state = {}
    state["_#{field}"] = (if typeof(evt) is 'string' then evt else evt.currentTarget.value)
    @setState state

  pwApproval: (type) ->
    value = @state._password
    switch type
      when 'chars'
        if value?.length > 11
          return 'checked'
      when 'lower'
        if value and /[a-z]/.test value
          return 'checked'
      when 'upper'
        if value and /[A-Z]/.test value
          return 'checked'
      when 'number'
        if value and /\d/.test value
          return 'checked'
    ''

  button: (opts, title) ->
    data = @getFields()

    required = @required
    required = required.concat(opts.required) if opts?.required

    disabled = if required
      !required.every (key) =>
        target = data[key] or @state[key]
        if Array.isArray(target)
          target.length
        else
          target

    Spinner ignoreChildren: true, state: opts.state,
      div className: "Button #{opts.className or ''} #{cx {'is-disabled': disabled}}", onClick: opts.onSubmit, onMouseUp: @disable, title

  dob: (args={}) ->
    className = 'u-flex u-flexRow ProfileEdit-row--multiInput '
    className += args.className if args.className

    div {className},
      @field 'birthdate_month', className: "Input u-flexChild #{@err('birthdate_month')}", onBlur: @checkMonth, placeholder: 'MM', description: 'Birth Month'
      @field 'birthdate_day', className: "Input u-flexChild #{@err('birthdate_day')}", onBlur: @checkDay, placeholder: 'DD', description: 'Birth Day'
      @field 'birthdate_year', className: "Input u-flexChild #{@err('birthdate_year')}", onBlur: @checkYear, placeholder: 'YYYY', description: 'Birth Year'

  onDataChange: (name, onChange) -> (evt) =>
    if evt? and (typeof evt in ['number', 'string', 'boolean'] or evt.constructor is Date)
      value = evt
    else if evt?.currentTarget
      target = evt.currentTarget
      type = target.getAttribute('type')
      value = if type is 'checkbox' then evt.currentTarget.checked else evt.currentTarget.value
      tagName = target.tagName
    else
      value = evt?.value?.toString() or null
    @updateData(name, value, tagName)
    onChange?(value)

  updateData: (name, value, tagName) ->
    {errors} = @state
    data = @getFields()
    data[name] = value
    if (index = errors.indexOf(name)) >= 0
      errors.splice(index, 1) if @check(name, value, tagName)
    state = {}
    state[@dataName] = data
    state.errors = errors
    @setState state

  err: (name) ->
    if name in @state.errors then 'error' else ''

  errPlaceholder: (name, defaultPlaceholder) ->
    if name in @state.errors then "Please fill in #{utils.parseString(name).toLowerCase()}" else defaultPlaceholder

module.exports = Fields
