{appStore} = require 'stores'
ReactDOM = require 'react-dom'

{cx} = Exim.helpers
{div, span, tch, fa, iframe, h1} = Exim.DOM
CLOSE_DELAY = 20

ReactModal = require('react-modal')

Modal = Exim.createView module.id,
  propTypes:
    body: React.PropTypes.oneOfType([React.PropTypes.element, React.PropTypes.func])
    onOpen: React.PropTypes.func

  componentDidMount: ->
    # For some reason w/o the setTimeouts some of the dom selectors return null
    setTimeout(() -> 
      modalOverlay = document.querySelector('[aria-modal=true]')
      # this attribute is rendered by ReactModal but is flagged by axe scans
      # remove it from the dom
      modalOverlay.removeAttribute('aria-modal')
    , 0)
    closeButton = document.getElementById('close-modal-pseudo-button')
    if closeButton  
      setTimeout(() -> 
        closeButton?.focus()
      , 0)
    else
      setTimeout(() ->
        document.querySelector('#modal-title-heading')?.focus()
      ,)

  getInitialState: ->
    hidden: false

  getDefaultProps: ->
    className: ''

  close: (evt) ->
    return if @props.disableClose

    evt?.stopPropagation()
    evt?.preventDefault()

    @setState hidden: true
    @closeAfter(CLOSE_DELAY)

  closeAfter: (ms) ->
    fn = =>
      appStore.actions.showModal(null)
      @props.onClose?()
    setTimeout fn, ms

  preventClosing: (evt) ->
    evt.stopPropagation()

  openPrintWindow: ->
    new Promise (res) => 
      window.print()
    
  print: (evt) ->
    evt.stopPropagation()
    @openPrintWindow().then ->
    document.querySelector(".Modal-print").focus()

  render: ->
    {title, print, body, footer, className, ignoreAnimation, noClose, disableClose, frame, hideBackground, shouldCloseOnOverlayClick, contentLabel} = @props

    if body.props.contentLabel then contentLabel = body.props.contentLabel
    
    style = if hideBackground then { overlay: { backgroundColor: 'black' } } else {}
    animationClasses = cx 'hidden': @state.hidden, 'fadeIn': !ignoreAnimation
    contentLabelText = if contentLabel then "#{contentLabel} dialog" else if typeof title is 'string' then "#{title} dialog" else "Dialog"

    children = div className: "te",
      tch tagName: 'button', role: 'button', 'aria-label': "Close #{contentLabelText}", className: 'Modal-close', id: 'close-modal-pseudo-button', handler: @close, tabIndex: 0,
        div className: 'close-inner Button Button--small Button--close',
          fa 'times-circle'
      if title
        div className: 'Modal-title',
          if typeof title is 'string' then h1 className: 'Modal-title', id: 'modal-title-heading', style: { paddingLeft: '0px' },  title else title
          if print
            tch tagName: 'a', href: '#', className: 'Modal-print', handler: @print,
              fa 'print'
              span 'Print'
      div className: "Modal-body #{cx 'scroll-wrapper': !!frame}", onClick: @preventClosing,
        if body
          if typeof body is 'function' then body {@close} else body
        if frame
          iframe className: 'Modal-frame', src: frame
      if footer
        div className: 'Modal-footer',
          if typeof footer is 'function' then footer {@close} else footer

    return React.createElement(ReactModal, {
      isOpen: true,
      role: 'dialog',
      contentLabel: contentLabelText
      className: 'Modal-content',
      portalClassName: "Modal #{animationClasses} #{className}",
      overlayClassName: 'Modal-overlay',
      onRequestClose: this.close,
      shouldCloseOnOverlayClick,
      ariaHideApp: false,
    }, children)

module.exports = Modal
