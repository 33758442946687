{appStore, documentsStore, authStore} = require 'stores'
Img        = require 'components/elements/Img'
Zoom        = require 'components/elements/Zoom'
{cx} = Exim.helpers
{div, img, span, fa, a, video, source, tch, object, label, input} = Exim.DOM
utils = require 'lib/utils'
{formatDateSimple, age} = utils

ESCAPE_KEY = 27
ENTER_KEY = 13

DocumentVerification = Exim.createView module.id,
  mixins: [
    authStore.connect('user')
  ]

  openInWindow: (url) ->
    window.open(url, '_blank').focus()

  prevent: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()

  stopPropagation: (evt) ->
    evt.stopPropagation()

  endOfText: (evt) ->
    evt.currentTarget.value = evt.currentTarget.value

  handleKeyUp: (evt) ->
    key = evt.which
    if key is ESCAPE_KEY
      @refs.input.blur()

  saveTitle: ->
    {id, title} = @props.doc
    text = @refs.input.value

    if text isnt title
      documentsStore.actions.saveTitle({id, title: text})

  verify: (doc) ->
    {id} = doc
    documentsStore.actions.verify({id})

  delete: (doc) ->
    documentsStore.actions.remove(doc, true)

  cancel: ->
    @delete(@props.doc)
    @props.onClose?()

  complete: (evt) ->
    evt.preventDefault()
    @saveTitle()
    @verify(@props.doc)
    @props.onClose?()

  render: ->
    {admin, proxying, full_name, dob, gender, avatar_url} = @state.user
    {doc, mime, url} = @props
    {title} = doc

    div className: 'DocumentVerification',
      div className: 'DocumentVerificationContent',
        div className: 'DocumentPreview',
          div className: "DocumentPreview-mime DocumentPreview-mime-#{mime}",
            if mime is 'video'
              video className: 'video', controls: true, autoPlay: false,
                source src: url
                a href:url, 'Download Video'
            else if mime is 'pdf'
              div className: 'DocumentPreview-mime-pdf-object scroll-wrapper',
                object data: url, type: 'application/pdf', width: '100%', height: '100%', 'aria-label': "this is the #{mime} #{doc.title}",
                  a href: url, target: '_blank', url
            else if mime is 'document'
              tch tagName: 'a', href: '#', className: 'document-download', handler: @openInWindow.bind(this, url),
                fa 'file-text-o'
                'Open File'
            else
              Zoom null,#onClick: @open.bind(@, doc.next),
                Img className: "#{cx disabled: !doc.next}", force: true, src: url
      div className: 'DocumentVerificationSidebar',
        div className: 'DocumentVerificationInfo',
          div className: 'DocumentVerificationInfo-avatar',
            if avatar_url and avatar_url isnt '/images/image-placeholder.jpg'
              img alt: full_name, src: utils.getAvatarUrl(avatar_url)
            else
              randomColor = utils.getRandomColor(full_name)
              div className: 'no-image DefaultAvatar', style: {background: randomColor},
                utils.getFirstChars(full_name, true)
          div className: 'DocumentVerificationInfo-text',
            div className: 'DocumentVerificationInfo-primary',
              full_name
            div className: 'DocumentVerificationInfo-secondary',
              "#{formatDateSimple(dob)} (#{age(dob)}  years old)"
            div className: 'DocumentVerificationInfo-secondary',
              utils.gender(gender)
        div className: 'DocumentVerificationEdit',
          div className: 'DocumentVerificationEdit-title', 'Rename document'
          div className: 'DocumentVerificationEdit-input',
            input
              className: 'Input Input--expand'
              type: 'text'
              defaultValue: title
              ref: 'input'
              'data-e2e': 'document-verification-doc-name'
              onFocus: @endOfText
              onClick: @prevent
              onTouchEnd: @stopPropagation
              onKeyUp: @handleKeyUp
          div className: 'DocumentVerificationEdit-buttons',
            tch className: 'Button Button--pointer', handler: @cancel, 'Cancel'
            tch className: 'Button Button--pointer Button--primary', handler: @complete, 'Complete Upload'
        div className: 'DocumentVerificationSidebar-spacer'
        div className: 'DocumentVerificationTips',
          div className: 'DocumentVerificationTips-title',
            fa 'exclamation-triangle'
            'Verification Tips'
          div className: 'DocumentVerificationTips-list',
            div className: 'DocumentVerificationTips-listItem',
              label '1. '
              span 'Cross check document Name and DOB with patient details'
            div className: 'DocumentVerificationTips-listItem',
              label '2. '
              span 'Verify all pages for accuracy'

module.exports = DocumentVerification
