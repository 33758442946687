"use strict";

const { observer } = require("mobx-react");
const authStore = require("stores/new-auth");
const moment = require("moment");
import { client } from "config";

const endDate = moment("11/26/18", "MM-DD-YYYY");

const AWCHolidayBanner = () => {
  let { me } = authStore;
  if (me.isAdmin) return null;
  if (client !== "apple" && client !== "docker") return null;
  if (moment() > endDate) return null;

  return (
    <div className="AWCHolidayBanner">
      <span className="AWCHolidayBanner-message">
        The Apple Park Wellness Center will be closed during the Thanksgiving
        shutdown. If you need to schedule an appointment during this time, the
        Valley Green Wellness Center will be open.
      </span>
    </div>
  );
};

module.exports = observer(AWCHolidayBanner);
