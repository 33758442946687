"use strict";
module.exports = {
  AppointmentsLink: require("./appointments-link"),
  DocumentsLink: require("./documents-link"),
  EducationLink: require("./education-link"),
  ElationLink: require("./elation-link"),
  LaboratoriesLink: require("./laboratories-link"),
  MedicationsLink: require("./medications-link"),
  TrendsLink: require("./trends-link"),
  ScreeningsLink: require("./screenings-link"),
  WellnessLink: require("./wellness-link"),
  HealthScreeningLink: require("./health-screening-link"),
  BeWellLink: require("./be-well-link"),
};
