MessagesList = require './MessagesList'
Messages     = require 'components/mixins/Messages'

{appStore, messagesStore} = require 'stores'
{messageFlags} = require 'config'

utils = require 'lib/utils'
{cx}  = Exim.helpers
{div, tch, fa, span} = Exim.DOM

type = 'unread'

UnreadMessage = Exim.createView module.id,
  type: type

  mixins: [
    messagesStore.connect('zoneAbbr')
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  show: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    name = @props.data?.from?.name
    avatar_url = @props.data?.from?.avatar_url
    subject = @props.data?.subject
    @context.router.push pathname: "/messages/received/#{@props.data.id}", query: {name, avatar_url, subject, filter: type}

  getDefaultProps: ->
    data: subject: ''

  render: ->
    {data} = @props

    opts = cx
      'is-unread': data.unread
      attachments: data.document_ids?.length > 0

    flag = messageFlags.find((f) => f.id == data.flags)

    tch role: 'row', className: "TableBody-row MessagesList-item tr #{opts}", handler: @show,
      if flag
        div role: 'gridcell', style: {color: flag.color}, className: 'TableBody-column MessagesList-item-flag',
          fa flag.icon
      else
        div role: 'gridcell', className: 'TableBody-column MessagesList-item-flag MessagesList-item-flag--empty'
      div role: 'gridcell', className: 'TableBody-column MessagesList-item-title u-1of3',
        data.from.name
      div role: 'gridcell', className: 'TableBody-column MessagesList-item-subject u-1of3',
        if data.attachments
          span null,
            fa 'paperclip'
        utils.getSubject(data.subject)
      div role: 'gridcell', className: "TableBody-column MessagesList-item-dateTime u-1of3 #{cx 'no-flag': !flag}",
        utils.formatDateShort(data.date)
        " "
        @state.zoneAbbr

module.exports = Unread = Exim.createView module.id,
  mixins: [Messages]
  type: type

  componentWillMount: ->
    appStore.actions.updateHeader({name: 'Unread'})
    messagesStore.actions.displayTZ()

  componentDidMount: ->
    appStore.actions.setTitle('Unread Messages')

  componentWillUnmount: ->
    appStore.actions.setTitle()

  render: ->
    MessagesList
      messages: @getMessages()
      loading: @isFetching() and not @state.unreadMessagesFetched
      View: UnreadMessage
      fields: ['from', 'subject', 'received']
      pageControls: @pageControls
      page: @state[@type].page
      pages: @state[@type].pages
      type: @type
      sort: @props.sort
      onSortChange: @props.onSortChange
      onLoad: @props.onLoad
      onUnload: @props.onUnload
