import MenuItem from "@material-ui/core/MenuItem";
import MuiMenu from "@material-ui/core/Menu";
import ProviderSubMenu from "./ProviderMenu-submenu";
import careTeamsStore from "stores/care-teams";
import authStore from "stores/new-auth";
import { isEmpty } from "lodash";
import utils from "lib/utils";

class ProviderMenu extends React.Component {
  handleSubMenuClick = (item) => (event) => {
    const { schedulestore } = this.props;

    schedulestore.changeApptSelect = false;
    schedulestore.providerId = item.id !== undefined ? item.id : item.user;
    careTeamsStore.setSelectedProvider(item);
    schedulestore.setIsCtOnly(false);
    this.props.onClose();
  };

  renderMenuItem = (item, avatarURL) => {
    return (
      <MenuItem
        key={item.key || item.id}
        className="MenuItem"
        value={item.id}
        onClick={this.handleSubMenuClick(item)}
      >
        <img className="MenuItem-avatar" src={avatarURL} alt="" />
        <span className="MenuItem-provider">{item.title || item.name}</span>
      </MenuItem>
    );
  };

  restOfProviders = (allProviders, careTeamsArray) => {
    const { anyProvider } = this.props.schedulestore;
    const oldProviders = [...allProviders];

    careTeamsArray.forEach((element) => {
      oldProviders.forEach((oldProvider) => {
        if (element.user === oldProvider.id) {
          oldProviders.splice(oldProviders.indexOf(oldProvider), 1);
        }
      });
    });

    oldProviders.length > 0 && oldProviders.splice(0, 0, anyProvider);

    return oldProviders;
  };

  getURL = (item, hasItemMenu) => {
    if (hasItemMenu && item.title === "Care Team") {
      return "/combo/images/team/CTicon.svg";
    }
    if (item.title === "Care Team") {
      return "/combo/images/team/otherProvider.svg";
    }
    if (!item.shot) {
      return "/combo/images/team/photo-default.jpg";
    }
    return item.shot || "/images/team/photo-default.jpg";
  };

  renderProviderOptions = () => {
    const { schedulestore } = this.props;
    const provider = schedulestore.providers.slice(0, 1)[0];
    const careTeamsArray = schedulestore.careTeamProviders;
    const pcpID = authStore.user.xo_physician_global_id;
    const pcpEnabled =
      pcpID !== "dr-community-provider" && pcpID !== "dr-declined";
    const hasCareTeam = !isEmpty(careTeamsArray);
    const hasMenu = (item) =>
      item.hasOwnProperty("subMenuItems") && !isEmpty(item.subMenuItems);

    const otherProviders =
      hasCareTeam &&
      this.restOfProviders(schedulestore.providers.slice(1), careTeamsArray);
    const allProviders =
      hasCareTeam && pcpEnabled
        ? [
            {
              ...provider,
              key: "pcp",
              title: provider.name,
              shot: provider.shot,
            },
            {
              key: "careTeams",
              title: "Care Team",
              subMenuItems: careTeamsArray,
            },
            ...((!isEmpty(otherProviders) && [
              {
                key: "otherProviders",
                title: "Other Providers",
                subMenuItems: otherProviders,
              },
            ]) ||
              []),
          ]
        : schedulestore.providers;

    return allProviders.map((item) => {
      const hasMenuItems = hasMenu(item);
      const avatarURL = utils.getAvatarUrl(this.getURL(item, hasMenuItems));

      return !hasMenuItems ? (
        this.renderMenuItem(item, avatarURL)
      ) : (
        <ProviderSubMenu
          key={item.key}
          title={item.title}
          menuItems={item.subMenuItems}
          shot={avatarURL}
          handleSubMenuClick={this.handleSubMenuClick}
          schedulestore={schedulestore}
          careTeamsStore={careTeamsStore}
          onClose={this.props.onClose}
        />
      );
    });
  };

  render() {
    const { open, anchorElement, onClose, ...others } = this.props;

    return (
      <MuiMenu
        {...others}
        id="schedule-provider-select"
        className="MaterialSelect"
        classes={{ paper: "MuiMenu-paper" }}
        anchorEl={anchorElement}
        open={open}
        onClose={onClose}
      >
        {this.renderProviderOptions()}
      </MuiMenu>
    );
  }
}

export default ProviderMenu;
