import { Input } from ".";

const Radio = (props) => {
  let { children, ...inputProps } = props;

  return (
    <label className="NewRadio-wrapper">
      <div className="NewRadio">
        <Input type="radio" {...inputProps} />
        <div className="NewRadio-box" aria-hidden="true" />
      </div>
      {children}
    </label>
  );
};

export default Radio;
