import { observable, transaction } from "mobx";
import request from "lib/new-request";
import { computed } from "lib/utils";
import Appointment from "models/appointment";

const appointmentsStore = observable({
  all: [],
  _rawAll: [],
  isFetched: false,
  appointmentId: null,
  episodeId: null,
  pushingToXOP: false,
  requestedByMember: null,

  async fetch() {
    this._rawAll = await request.get("/v1/appointments");
    // We wrap this in a MobX transaction so that some of the computed mappings of collections,
    // e.g. the `Provider` models at a site, aren't recomputed for each appointment type.
    transaction(
      () => (this.all = this._rawAll.map((raw) => Appointment.fromAPI(raw)))
    );
    this.isFetched = true;
  },

  getRawAppt(id) {
    return this._rawAll.find((raw) => raw.id == id);
  },

  clear() {
    this.isFetched = false;
    this.all = [];
  },

  clearPushToXOPModalData() {
    this.episodeId = null;
    this.appointmentId = null;
    this.requestedByMember = null;
    this.pushingToXOP = false;
  },

  setEpisodeId(episodeId) {
    this.episodeId = episodeId;
  },

  setAppointmentId(appointmentId) {
    this.appointmentId = appointmentId;
  },

  setRequestedByMember(answer) {
    this.requestedByMember = answer;
  },

  async pushAppointmentToXOP() {
    if (!this.appointmentId) {
      return;
    }

    this.pushingToXOP = true;
    const requestBody = {
      appointment_id: this.appointmentId,
      initiated_by_member: this.requestedByMember === "Yes",
    };

    if (this.episodeId > 0) {
      requestBody.episode_id = this.episodeId;
    }

    try {
      await request.post(
        "/v1/episodes/create_or_assign_episode_to_an_appointment",
        requestBody
      );
      this.fetch();
    } catch (e) {
      throw e;
    } finally {
      this.pushingToXOP = false;
    }
  },
});

computed(appointmentsStore, {
  get canPushToXOP() {
    return this.episodeId !== null && this.requestedByMember !== null;
  },
});

export default appointmentsStore;
