"use strict";
const { observable } = require("mobx");
const { computed } = require("lib/utils");
const synopsisStore = require("./new-synopsis");
const authStore = require("./new-auth");
const request = require("lib/new-request");
const checkinStore = require("./checkin");
const { formats } = require("config");

const byProvider = (provider) => {
  if (!provider) return () => true;

  return (appt) => appt.provider_id == provider.id;
};

const byType = (id) => {
  if (!id) return () => true;

  return (appt) => appt.type_id == id;
};

const byMatcher = (matcher) => {
  if (!matcher) return () => true;

  return (appt) => {
    for (let key of ["patient_id", "legal_and_preferred_name", "starts_at", "employee_id"]) {
      let value = appt[key];
      if (matcher.test(value)) return true;
    }

    let dob = moment(appt.dob, formats.dob).format(formats.shortDob);
    return matcher.test(dob);
  };
};

const sorts = [
  {
    label: "None",
    fn: (a, b) => moment(a.appointment_at) - moment(b.appointment_at),
  },
  { label: "Unpaid", fn: (a, b) => a.amount - b.amount },
  { label: "Paid", fn: (a, b) => b.amount - a.amount },
];

const [sort] = sorts;

const apptsStore = observable({
  _date: null,
  forcedCheckinAppt: null,
  all: [],
  search: "",
  sorts,
  sort,
  filter: {
    provider: null,
    type: null,
  },
  isScheduleLoading: false,
  currentProviderFiltered: false,

  isNow() {
    let { site } = synopsisStore;
    return site.today.isSame(this.date, "day");
  },

  now() {
    let { site } = synopsisStore;
    this.date = site.today;
  },

  async fetchSchedule() {
    this.isScheduleLoading = true;

    try {
      let { site } = synopsisStore;

      if (!site.code) {
        this.all = [];
        return;
      }

      let { list } = await request.get("/v1/schedule", {
        site: site.code, // TODO: switch to `site.id`
        date: this.date.format("YYYYMMDD"), // TODO: switch to `ISO_DATE`
      });

      list.forEach((provider) => {
        if (
          !this.currentProviderFiltered &&
          authStore.user.full_name &&
          provider.name &&
          provider.name.split(",")[0] === authStore.user.full_name
        ) {
          this.currentProviderFiltered = true;
          this.filter.provider = provider;
        }

        provider.appointments.forEach((appt) => {
          appt.provider_id = provider.id;
          appt.type_id = appt.summary;
        });
      });
      this.all = list;
    } catch (err) {
      console.error(err);
      alert("Failed to load schedule");
    } finally {
      this.isScheduleLoading = false;
    }
  },

  async forceCheckIn({ id, router }) {
    const appt = await request.post(`v1/appointments/${id}/force_checkin`);

    await this.fetchSchedule();

    if (
      appt.disposition !== "complete" &&
      appt.checkin &&
      !!appt.checkin.data
    ) {
      this.forcedCheckinAppt = appt;
      const data = {
        appointment_id: appt.id,
        patient_id: appt.patient_id,
        reason: appt.reason,
      };
      await checkinStore.actions.changeState("welcome", data);
      await router.push({ pathname: "/checkin/steps/welcome" });
    }
  },

  async revokeCheckIn({ id }) {
    try {
      const appt = await request.post(`v1/appointments/${id}/revoke_checkin`);
    } catch (err) {
      console.error(err);
      alert(`An error occurred while attempting to revoke this checkin`);
    }
    await this.fetchSchedule();
  },

  async clearForCheckin() {
    this.all = [];
  },
});

computed(apptsStore, {
  get date() {
    return this._date || synopsisStore.site.today;
  },

  set date(val) {
    this._date = val;
  },

  get matcher() {
    let { search } = this;
    if (!search) return null;

    search = search.replace(/[^\w\s:]/g, "");

    return new RegExp(`\\b${search}`, "i");
  },

  get some() {
    return this.all
      .map((provider) => {
        let appointments = provider.appointments
          .filter(byProvider(this.filter.provider))
          .filter(byType(this.filter.type))
          .filter(byMatcher(this.matcher))
          .sort(this.sort.fn);

        return {
          ...provider,
          appointments,
        };
      })
      .filter((provider) => {
        return provider.appointments.length;
      });
  },
});

module.exports = apptsStore;
