"use strict";
const { observer } = require("mobx-react");
const screeningStore = require("stores/screening");
const ActionDropdown = require("components/elements/ActionDropdown");
import { startCase, uniq } from "lodash";

class ScreeningList extends React.Component {
  componentDidMount() {
    screeningStore.fetchTemplates();
  }

  renderScreeningListItem(template) {
    const interactionsLabel = uniq(
      template.template_interactions
        .filter((interaction) => interaction.status !== "deleted")
        .map((interaction) => startCase(interaction.interaction_type))
    ).join(", ");
    const editHandler = () =>
      this.props.router.push({ pathname: `/screenings/${template.id}` });

    return (
      <div
        key={template.id}
        className="TableBody-row"
        style={{ padding: "24px 0" }}
      >
        <div
          className="TableBody-column ScreeningsListItem-title"
          style={{ width: "17.5%" }}
        >
          <span>{template.service_line}</span>
        </div>
        <div
          className="TableBody-column ScreeningsListItem-title"
          data-e2e="name"
          style={{ width: "25%" }}
        >
          <span>{template.name}</span>
        </div>
        <div
          className="TableBody-column ScreeningsListItem-title"
          style={{ width: "17.5%" }}
        >
          <span>{template.owner_name}</span>
        </div>
        <div
          className="TableBody-column ScreeningsListItem-title"
          data-e2e="interactions"
          style={{ width: "15%" }}
        >
          <span>{interactionsLabel}</span>
        </div>
        <div
          className="TableBody-column ScreeningsListItem-title"
          data-e2e="status"
          style={{ width: "10%" }}
        >
          <span>{startCase(template.status)}</span>
        </div>
        <div
          className="TableBody-column ScreeningsListItem-title"
          style={{ width: "15%" }}
        >
          {template.status !== "deleted" && (
            <ActionDropdown
              className="ActionDropdown--gray"
              withGlobalClick={true}
              values={[
                {
                  name: template.status === "live" ? "View" : "Edit",
                  handler: editHandler,
                  description: "Edit screening",
                },
                {
                  name: "Archive",
                  handler: () => {
                    screeningStore.delete({
                      id: template.id,
                      status: template.status,
                    });
                  },
                  description: "Archive screening",
                },
              ]}
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Table Table--bordered">
        <div className="TableHeader ScreeningsHeader">
          <span
            className="TableHeader-column u-noselect"
            style={{ width: "17.5%" }}
          >
            <span>Service Line</span>
          </span>
          <span
            className="TableHeader-column u-noselect"
            style={{ width: "25%" }}
          >
            <span>Name</span>
          </span>
          <span
            className="TableHeader-column u-noselect"
            style={{ width: "17.5%" }}
          >
            <span>Owner</span>
          </span>
          <span
            className="TableHeader-column u-noselect"
            style={{ width: "15%" }}
          >
            <span>Interaction</span>
          </span>
          <span
            className="TableHeader-column u-noselect"
            style={{ width: "10%" }}
          >
            <span>Status</span>
          </span>
          <span
            className="TableHeader-column u-noselect"
            style={{ width: "15%" }}
          ></span>
        </div>
        <div className="TableBody">
          {screeningStore.screeningTemplates.map(
            this.renderScreeningListItem.bind(this)
          )}
        </div>
      </div>
    );
  }
}

module.exports = observer(ScreeningList);
