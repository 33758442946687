"use strict";
const authStore = require("stores/new-auth");
const { observer } = require("mobx-react");
const Link = require("../link");

const TrendsLink = () => {
  let { user } = authStore;
  if (!user.canReadTrends) return null;

  return (
    <li className="SidebarNav-item">
      <Link to="/trends">
        <span className="SidebarNavLink-icon">
          <span aria-hidden={true} className="icon fa fa-area-chart" />
        </span>
        <span className="SidebarNavLink-text">Trends</span>
      </Link>
    </li>
  );
};

module.exports = observer(TrendsLink);
