{appStore, authStore} = require 'stores'

MessagesList = require './MessagesList'
Messages     = require 'components/mixins/Messages'
Tooltip      = require 'components/elements/Tooltip'

{ actionToIconMap } = require './messageUtils'
{keyboardScrollTable}  = require('lib/utils/keyboardScrollTable')
utils = require 'lib/utils'
{cx}  = Exim.helpers
{fa, div, tch, span, td, tr} = Exim.DOM
type = 'archived'

ArchivedMessage = Exim.createView module.id,
  type: type

  contextTypes:
    router: React.PropTypes.object.isRequired

  show: ->
    subtype = if @props.data.from.id is authStore.get('user').id then 'sent' else 'received'
    page = @props.page
    @context.router.push pathname: "/messages/#{@type}/#{@props.data.id}", query: type: subtype, filter: type, page: page

  getInitialState: ->
    lastActionHover: false

  mouseEnter: (evt) ->
    @setState lastActionHover: true

  mouseLeave: (evt) ->
    @setState lastActionHover: false

  render: ->
    {data, matcher} = @props

    rawName = if data.from then data.from.name else 'Me'

    name = utils.highlightMatched(rawName, matcher)
    subject = utils.highlightMatched(utils.getSubject(data.subject), matcher)

    opts = cx
      'is-unread': data.unread
      attachments: data.document_ids?.length > 0

    tch tagName: 'div', role: 'row', className: "TableBody-row Archive-row MessagesList-item tr #{opts}", handler: @show, onEnter: @show, tabIndex: 0, id: @props.messageNumber, 'aria-label': "Row #{@props.messageNumber}, from #{data.from?.name}, subject #{data.subject}, received #{utils.formatDateShort(data.date)} #{if @state.zoneAbbr then (@state.zoneAbbr) else ''}, click to read full message",
      div role: 'gridcell', className: 'TableBody-column MessagesList-item-title u-1of3',
        if data.lurker
          fa 'user'
        name
        if data.last_action
          span className: 'MessageList-item-last-action', onMouseEnter: @mouseEnter, onMouseLeave: @mouseLeave,
            fa "mail-#{actionToIconMap(data.last_action)}"
            Tooltip({ type: 'text', text: utils.formatDateShort(data.last_action_at) }) if @state.lastActionHover
      div role: 'gridcell', className: 'TableBody-column MessagesList-item-subject u-1of3',
        if data.attachments
          span null,
            fa 'paperclip'
        subject
      div role: 'gridcell', className: 'TableBody-column MessagesList-item-dateTime u-1of3',
        utils.formatDateShort(data.date)

module.exports = Archived = Exim.createView module.id,
  mixins: [Messages]
  type: type

  componentWillMount: ->
    appStore.actions.updateHeader({name: 'Archived'})

  componentDidMount: ->
    appStore.actions.setTitle('Archived Messages')
    document.querySelector('#contentSearchInput').focus()
    document.addEventListener('keyup', keyboardScrollTable)

  componentWillUnmount: ->
    appStore.actions.setTitle()
    document.removeEventListener('keyup', keyboardScrollTable)

  render: ->
    MessagesList
      messages: @getMessages()
      loading: @isFetching() and not @state.archivedMessagesFetched
      View: ArchivedMessage
      fields: ['from', 'subject', 'received']
      pageControls: @pageControls
      page: @state[@type].page
      pages: @state[@type].pages
      type: @type
      sort: @props.sort
      onSortChange: @props.onSortChange
      onLoad: @props.onLoad
      onUnload: @props.onUnload
      onSearch: @props.onSearch
      clearSearch: @props.clearSearch
      matcher: @props.matcher
      applyFilter: @applyFilter
      filter: @state.filter
