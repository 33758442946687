import React from "react";
import PropTypes from "prop-types";
import { getAvatarUrl, getFirstChars } from "lib/utils";
import { getRandomDarkerColor } from "lib/random-darker-color";

const Avatar = ({ shot, name }) => {
  const url =
    shot == "/images/team/photo-default.jpg"
      ? "/combo/images/team/photo-default.jpg"
      : shot;

  const src = getAvatarUrl(url);
  const color = getRandomDarkerColor(name);

  return (
    <React.Fragment>
      {shot ? (
        <img alt={name} src={src} />
      ) : (
        <div className="no-image DefaultAvatar" style={{ background: color }}>
          {getFirstChars(name, true)}
        </div>
      )}
    </React.Fragment>
  );
};

Avatar.propTypes = {
  shot: PropTypes.string,
  name: PropTypes.string,
};

module.exports = Avatar;
