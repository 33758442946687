import { observer } from "mobx-react";
import { Modal } from "components/elements/new-modal";
import oooStore from "stores/ooo";

class DeleteOOOModal extends React.Component {
  constructor(props) {
    super(props);

    this.deleteOOO = async () => {
      let { ooo, abort } = this.props;

      await oooStore.delete(ooo);
      abort();
    };
  }

  render() {
    let { provider, ooo } = this.props;
    let title = `Delete OOO day(s) for ${provider.name}?`;

    return (
      <Modal
        title={title}
        className="OOOModal-confirm"
        close={this.props.abort}
      >
        <div>
          <p>
            Are you sure you want to delete Out of Office days for{" "}
            <span className="OOOModal-provider">{provider.name}</span>?
          </p>
          <div className="OOOModal-dates">{ooo.range.format("ll")}</div>
        </div>
        <footer className="ConfirmFooter">
          <div className="ConfirmFooter-buttons">
            <button className="Button" onClick={this.props.abort}>
              Cancel
            </button>
            <button className="Button ConfirmButton" onClick={this.deleteOOO}>
              Confirm
            </button>
          </div>
        </footer>
      </Modal>
    );
  }
}

module.exports = observer(DeleteOOOModal);
