import { Route } from "react-router";
import Wrapper from "./index";
import HraAuth from "./auth/auth";
import HraSurvey from "./survey/survey";
import HraResultsWrapper from "./results/results";

module.exports = (
  <Route path="/surveys/hra" component={Wrapper}>
    <Route path=":magicToken/verify" component={HraAuth} />
    <Route path=":magicToken/survey" component={HraSurvey} />
    <Route path=":magicToken/results" component={HraResultsWrapper} />
  </Route>
);
