d3Selection    = require 'd3-selection'
{sortBooleans} = require 'lib/utils'

{arc, pie} = require 'd3-shape'
{cx}       = Exim.helpers
{div}      = Exim.DOM

sizes =
  big:
    width: 200, height: 180, radius: Math.min(200, 180) / 2

color = (item) ->
  if item.good then '#ddd' else '#E02654'

Donut = Exim.createView module.id,

  componentDidMount: ->
    @update()
    document.getElementById('DonutGraph').addEventListener('mousemove', @handleMouseMove)

  componentDidUpdate: ->
    @update()

  shouldComponentUpdate: (nextProps) ->
    @props.data isnt nextProps.data || @props.showRed isnt nextProps.showRed

  componentWillUnmount: ->
    document.getElementById('DonutGraph').removeEventListener('mousemove', @handleMouseMove)

  handleMouseMove: (e) -> 
    [hoverA, hoverB] = document.getElementsByClassName('donut-hover')
    xVal = e.clientX
    yVal = e.clientY
    hoverA?.style.top = (yVal - hoverA.offsetHeight) - 14 + 'px'
    hoverA?.style.left = (xVal - (hoverA.offsetWidth/2)) + 'px'
    hoverB?.style.top = (yVal - hoverB.offsetHeight) - 14 + 'px'
    hoverB?.style.left = (xVal - (hoverB.offsetWidth/2)) + 'px'

  handleEnter: (e) ->
    hovers = document.getElementsByClassName('donut-hover')
    hovers[ if e.data.good then 1 else 0 ].style.visibility = 'visible'
  
  handleLeave: (e) ->
    [hoverA, hoverB] = document.getElementsByClassName('donut-hover')
    hoverA.style.visibility = 'hidden'
    hoverB.style.visibility = 'hidden'

  update: ->
    {data, size, showRed, flip} = @props
    {width, height, radius} = sizes[size]

    red_count = data.filter((item)->!item.good).length
    green_count = data.filter((item)->item.good).length
    angle_coef = if showRed then green_count else red_count
    count = data.length
    text = if showRed then red_count else green_count

    data = [{value: red_count, good: false}, {value: green_count, good: true}]

    targ = arc()
      .outerRadius(radius - 10)
      .innerRadius(radius - 40)
      .startAngle((d) -> d.startAngle - (angle_coef * Math.PI)/count)
      .endAngle((d) -> d.endAngle - (angle_coef * Math.PI)/count)

    pieEl = pie()
      .sort((a, b) -> sortBooleans(a.good, b.good, showRed))
      .value((d) -> d.value)

    el = @refs['circle-graph']
    el.innerHTML = ''
    svg = d3Selection.select(el).append('svg')
      .attr('width', width)
      .attr('height', height)
      .on('click', (d) -> flip())
    .append('g')
      .attr('transform', "translate(#{width / 2}, #{height / 2})")

    g = svg.selectAll('.arc')
      .data(pieEl(data))
    .enter().append('g')
      .attr('class', 'arc') 
      .on('mouseenter', @handleEnter)
      .on('mouseleave', @handleLeave)
      .on('focus', @handleEnter)
      .on('blur', @handleLeave)
      .attr('aria-labelledby', (d, i) -> 'hover-title-' + i)
      .attr('focusable', 'true')
      .attr('aria-label', (x) -> "You have #{x.data.value} #{if x.data.good then 'Successful' else 'Opportunity'} Focus Areas")

    g.append('title')
      .attr('id', (d, i) -> 'hover-title-' + i )
      .text((x) -> "You have #{x.data.value} #{if x.data.good then 'Successful' else 'Opportunity'} Focus Areas")

    g.append('path')
      .attr('d', targ)
      .style('fill', (d) -> color(d.data))

    g.append('text')
      .attr('transform', (d) -> "translate(#{targ.centroid(d)})")
      .attr('dy', '.35em')
      .style('text-anchor', 'middle')

    if red_count
      label = svg.append('text')
        .attr('class', "DonutGraph-value #{cx 'is-red': showRed}")
        .attr('text-anchor', 'middle')
        .attr('dy', '.35em')
        .text(text)
        .attr('aria-hidden', 'true')

    hoverRed = document.createElement('div')
    hoverRed.setAttribute('class', 'donut-hover hover-red')
    hoverRed.innerHTML = "You have #{data[0].value} Opportunity Focus Areas"

    hoverGreen = document.createElement('div')
    hoverGreen.setAttribute('class', 'donut-hover hover-green')
    hoverGreen.innerHTML = "You have #{data[1].value} Successful Focus Areas"

    el.appendChild(hoverRed)
    el.appendChild(hoverGreen)

  render: ->
    div className: 'DonutGraph', id: 'DonutGraph', ref: 'circle-graph', 'aria-label': @props.ariaLabel or ''

module.exports = Donut
