{authStore} = require 'stores'

Spinner     = require 'components/elements/Spinner'
SignupError = require 'components/auth/signup-error'
getFocusableElements = require('lib/utils/getFocusableElements').default
{ showAndSpeakMessage } = require('lib/utils')

{cx}   = Exim.helpers
{div, form, a, p, h1, tch, span} = Exim.DOM

Verify = Exim.createView module.id,
  listen: ['auth/error', 'auth/signinUp', 'auth/verificationEmail']

  contextTypes:
    router: React.PropTypes.object.isRequired

  componentDidMount: ->
    authStore.actions.resetFinishSigningUp()
    if @state.error
    # need to wait 2 seconds before setting focus to allow screen reader to finish reading 'X step loaded' annoucement for a11y purposes
      setTimeout(() ->
          text = document.getElementById('sign-up-error')
          text.focus()
      , 2000)
    else 
      setTimeout(() ->
          buttons = document.getElementById('sign-up-verify-actions')
          focusable = getFocusableElements(buttons)
          focusable[0]?.focus()
      , 2000)

  verify: ->
    showAndSpeakMessage("Verification email has been re-sent", "verify-message-container")
    authStore.actions.verify()

  goToSignUpAccount: ->
    @context.router.push pathname: '/signup/account'
    authStore.actions.resetLastStep()
    authStore.actions.resetFinishSigningUp()

  render: ->
    div className: 'SignUpVerify',
      Spinner state: @state.signingUp,
      if @state.error
        React.createElement(SignupError, {goToSignUpAccount: @goToSignUpAccount})
      else
        email = @state.verificationEmail
        form onSubmit: @verify,
          div className: 'SignUpVerify',
            div className: 'SignupSection-block--finish',
              h1 className: 'way-to-go', 'Way to go!'
              p null,
                "You're almost ready! Before you start using your account, please verify your email address by clicking the link in the email we have just sent to"
                a className: 'underline', href: "mailto:#{email}", email
              p "If you don't receive the message in a few minutes, please check your spam folder."

              div className: 'SignUpVerify--actions-wrapper',
                div className: 'SignUpVerify--actions', id: 'sign-up-verify-actions',
                  div className: "SignUpVerify--actions-message-container", id: "verify-message-container", "aria-live": "assertive",
                    null
                  tch tagName: 'button', className: 'Button Button--secondaryAction', handler: @verify, 
                    'Resend Verification Email'
                    span className: 'sr-only', 
                      "Before you start using your account, please verify your email address by clicking the link in the email we have just sent to #{email}. If you don't receive the message in a few minutes, please check your spam folder. To resend the verification email, click this button."
                  tch tagName: 'a', href: '/signin', className: 'Button Button--primary', 'Return to the Wellness Center'

module.exports = Verify
