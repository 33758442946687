"use strict";
const { PropTypes } = React;
const ReactDOM = require("react-dom");

/*
ASYNC Portal
- Uses the React ^16.0 Portal API to render components at different parts of the DOM tree
- Performs this asynchronously because of race conditions present in our app
- NOTE: Use the document object, not ReactDOM - React will automatically track this component in the Shadow DOM
*/
class AsyncPortal extends React.Component {
  /*
  STATE:
  target: Target DOMNode to mount children to
  */
  constructor(props) {
    super(props);
    this.state = {
      target: null,
    };
    this.findTarget = this.findTarget.bind(this);
  }
  // Prevents excessive re-renders and duplicate mounts
  findTarget() {
    if (!this.state.target) {
      const target = document.querySelector(this.props.selector);
      if (target) setTimeout(() => this.setState({ target }));
    }
  }
  render() {
    if (this.state.target) {
      return ReactDOM.createPortal(this.props.children, this.state.target);
    } else {
      this.findTarget();
      return null;
    }
  }
}

AsyncPortal.propTypes = {
  children: PropTypes.any,
  selector: PropTypes.string.isRequired,
};
AsyncPortal.defaultProps = {
  children: null,
  selector: "",
};

module.exports = AsyncPortal;
