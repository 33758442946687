request   = require 'lib/request'
metricsStore = require 'stores/metrics'

module.exports = timeline = Exim.createStore
  path: 'timeline'
  actions: [
    'fetch'
    'fetchOrders'
    'addOrder'
    'removeOrder'
    'clearOrder'
    'placeOrder'
    'create'
    'reply'
    'attach'
    'fetchNotReplied'
    'signOff'
    'update'
    'fetchEntries'
    'addEntry'
    'removeEntry'
  ]

  initial:
    posts: []
    notRepliedCount: 0
    orderTypes: []
    order: {}
    entries: []

  fetchNotReplied:
    while: (timelineFetching) ->
      @set {timelineFetching}
    on: ->
      request.get('timeline/not_replied')
    did: (resp) ->
      @set notRepliedCount: resp.count

  fetchEntries:
    while: (entriesFetching) ->
      @set {entriesFetching}
    on: ->
      request.get('timeline/entries')
    did: (entries) ->
      @set {entries}

  addEntry:
    while: (addingEntry) ->
      @set {addingEntry}
    on: (text) ->
      request.post('timeline/entries', {text})
    did: (entries) ->
      @set {entries}

  removeEntry:
    while: (removingEntry) ->
      @set {removingEntry}
    on: (id) ->
      @removedId = id
      request.del("timeline/entries/#{id}")
    did: (entries) ->
      removed = @removedId
      @set entries: @get('entries').filter (e) -> e.id isnt removed

  fetch:
    while: (timelineFetching) ->
      @set {timelineFetching}
    on: ->
      request.get('timeline')
    did: (posts) ->
      metric_types = metricsStore.get('types')
      find = (id) -> metric_types.filter((m)-> m.id is id)[0]

      posts.forEach (post) ->
        if post.replies.length
          post.replies.forEach (reply) ->
            if reply.reply_type is 'screening'
              body = JSON.parse(reply.body)
              newBody = []
              Object.keys(body).forEach (i) ->
                if metric_type = find(parseInt(i))
                  metric_type['value'] = body[i]
                  newBody.push metric_type
              reply.body = newBody

      @set {posts}

  create:
    while: (postCreating) ->
      @set {postCreating}
    on: (data) ->
      promise = Promise.resolve()
      data.type = 'post'
      if data.documents.length > 0
        uploads = data.documents.map (item) ->
          form = new FormData
          form.append 'file', item.file
          request.form('documents', form)
        promise = Promise.all uploads
      promise.then (uploadRes=[]) ->
        data.document_ids = uploadRes.map (doc) -> doc.id
        request.post('timeline', data)
    did: (response) ->
      @actions.fetch()

  reply:
    while: (replying) ->
      @set {replying}
    on: (id, data) ->
      request.post("timeline/#{id}/reply", data)
    did: (response) ->
      @actions.fetch()

  update:
    while: (updating) ->
      @set {updating}
    on: (id, body) ->
      data = {body}
      request.put("timeline/#{id}", data: data)

  attach:
    while: (attaching) ->
      @set {attaching}
    on: (id, data) ->
      promise = Promise.resolve()
      if data.documents.length > 0
        uploads = data.documents.map (item) ->
          form = new FormData
          form.append 'file', item.file
          request.form('documents', form)
        promise = Promise.all uploads
      promise.then (uploadRes=[]) ->
        data.document_ids = uploadRes.map (doc) -> doc.id
        request.post("timeline/#{id}/attach", data)
    did: (response) ->
      @actions.fetch()

  signOff:
    while: (signinOff) ->
      @set {signinOff}
    on: (id) ->
      request.put("timeline/#{id}/signoff")
    did: (response) ->
      @actions.fetch()

  fetchOrders: ->
    @set {orderTypes}

  addOrder: (item) ->
    order = @get('order')
    if orderType = order[item.type]
      orderType.push(item.name) if orderType.indexOf(item.name) < 0
    else
      order[item.type] = [item.name]
    @set {order, date: new Date}
    # console.log @get('test')

  removeOrder: (type, item) ->
    order = @get('order')
    order[type] = order[type].filter((i) -> i isnt item) if order[type]
    date = new Date
    @set {order, date}

  placeOrder:
    on: (postId) ->
      posts = @get('posts')
      order = @get('order')
      if post = posts.filter((post) -> post.id is postId)[0]
        if post.orders
          post.orders.push(order)
        else
          post.orders = [order]
      console.log post
      @set {posts}
    did: ->
      @actions.clearOrder()

  clearOrder: ->
    @set order: {}

orderExample = {
  immunization : [
    '005009 CBC w/ Diff'
    'Preventative Care'
    'SurePath Pap Smear w/ Reflex HPV'
  ]
  lab : [
    'H. Pylori Breath Test'
    'Sed Rate'
  ]
  procedure : [
    'Heptatic Panel'
  ]
}

orderTypes = [
  {name: 'Abscess incision & drainage', type: 'Procedure'},
  {name: 'Cerumen disimpaction', type: 'Procedure'},
  {name: 'Cryosurgery', type: 'Procedure'},
  {name: 'EKG', type: 'Procedure'},
  {name: 'Foreign body removal', type: 'Procedure'},
  {name: 'Laceration repair', type: 'Procedure'},
  {name: 'Pap test, female', type: 'Procedure'},
  {name: 'Pap test, male', type: 'Procedure'},
  {name: 'PPD skin test', type: 'Procedure'},
  {name: 'Skin excision, biopsy', type: 'Procedure'},
  {name: 'Skin excision, shave', type: 'Procedure'},
  {name: 'Spirometry', type: 'Procedure'},
  {name: 'Suture removal', type: 'Procedure'},
  {name: 'Wound care', type: 'Procedure'},

  {name: 'Albuterol nebulizer', type: 'Medication'},
  {name: 'Ceftriaxone (per 250mg)', type: 'Medication'},

  {name: 'Chem-14', type: 'Laboratory'},
  {name: 'FIT (Fecal Immunochemical Test)', type: 'Laboratory'},
  {name: 'Glucose', type: 'Laboratory'},
  {name: 'Hemoglobin A1C', type: 'Laboratory'},
  {name: 'Lipid Panel', type: 'Laboratory'},
  {name: 'Rapid BV', type: 'Laboratory'},
  {name: 'Rapid HIV', type: 'Laboratory'},
  {name: 'Rapid influenza A & B', type: 'Laboratory'},
  {name: 'Rapid mononucleosis', type: 'Laboratory'},
  {name: 'Rapid strep', type: 'Laboratory'},
  {name: 'Rapid trichomonas', type: 'Laboratory'},
  {name: 'Urinalysis', type: 'Laboratory'},
  {name: 'Urine Pregnancy (hCG)', type: 'Laboratory'},

  {name: 'dT', type: 'Immunizations'},
  {name: 'DTaP', type: 'Immunizations'},
  {name: 'Gardasil', type: 'Immunizations'},
  {name: 'Hepatitis A', type: 'Immunizations'},
  {name: 'Hepatitis B', type: 'Immunizations'},
  {name: 'Influenza, adult', type: 'Immunizations'},
  {name: 'Influenza, pediatric', type: 'Immunizations'},
  {name: 'Japanes Encephalitis Virus', type: 'Immunizations'},
  {name: 'Meningococcal', type: 'Immunizations'},
  {name: 'MMR', type: 'Immunizations'},
  {name: 'Pneumovax 23', type: 'Immunizations'},
  {name: 'Polio, inactivated 0.5ml', type: 'Immunizations'},
  {name: 'Tdap', type: 'Immunizations'},
  {name: 'Tetanus toxoid 0.5ml', type: 'Immunizations'},
  {name: 'Twinrix', type: 'Immunizations'},
  {name: 'Typhoid 0.5ml', type: 'Immunizations'},
  {name: 'Typhoid, live, oral', type: 'Immunizations'},
  {name: 'Varicella', type: 'Immunizations'},
  {name: 'Yellow Fever', type: 'Immunizations'},
  {name: 'Zoster', type: 'Immunizations'},
]
