"use strict";
const appStore = require("stores/new-app");
const { cx } = require("lib/utils");
const { observer } = require("mobx-react");

class ContentHeader extends React.Component {
  renderBurger() {
    return (
      <button
        className="NewContentHeader-sidebar"
        id="menu"
        aria-label="Toggle sidebar"
        aria-controls="sidebar"
        aria-expanded={appStore.isSidebarOpen}
        onClick={() => appStore.toggleSidebar()}
      />
    );
  }

  renderLogo() {
    let { logo, logoClass = "" } = this.props;
    return (
      <img
        className={`Auth-logo NewContentHeader--logo ${logoClass}`}
        alt=""
        {...logo}
      />
    );
  }

  render() {
    let {
      withSidebar,
      className = "",
      titleClass = "",
      withLogo = false,
    } = this.props;
    let headerClass = cx(
      "NewContentHeader",
      {
        withSidebar,
      },
      className
    );

    return (
      <header className={headerClass}>
        {withSidebar && this.renderBurger()}
        {withLogo && this.renderLogo()}
        <div className={cx("NewContentHeader-wrapper", className)}>
          <h1 className={`NewContentHeader-title ${titleClass}`}>
            {this.props.title}
          </h1>
          <div className="NewContentHeader-children">{this.props.children}</div>
        </div>
      </header>
    );
  }
}

ContentHeader.defaultProps = {
  withSidebar: true,
};

module.exports = observer(ContentHeader);
