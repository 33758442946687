import NativeSelect from "components/elements/native-select";
import get from "lodash/get";
import careTeamsStore from "stores/care-teams";

const getFirstProvider = (scheduleStore) => {
  return get(scheduleStore, "providers[0].id", null);
};

const getProviderList = (scheduleStore) => {
  const providerList = {};
  scheduleStore.providers.forEach((prov) => {
    providerList[prov.id] = prov.name;
  });
  return providerList;
};

const getFirstName = (scheduleStore) => {
  const list = getProviderList(scheduleStore);
  const firstProv = getFirstProvider(scheduleStore);
  return list[firstProv];
};

class ProvidersSelect extends React.Component {
  state = {
    selectedProvider: getFirstProvider(this.props.scheduleStore),
    selectedProviderName: getFirstName(this.props.scheduleStore),
    providerList: getProviderList(this.props.scheduleStore),
  };

  componentWillUpdate = (nextProps) => {
    const { selectedProvider } = this.state;
    const { scheduleStore } = this.props;

    const firstProvider = getFirstProvider(scheduleStore);
    if (
      !nextProps.scheduleStore.providerId &&
      selectedProvider !== firstProvider
    ) {
      this.setState({ selectedProvider: firstProvider });
    }
  };

  getName = (id) => {
    return this.state.providerList[id];
  };

  getSelectedProvider = (name) => {
    return Object.keys(this.state.providerList).find(provider => this.state.providerList[provider] === name)
  }

  handleChange = (event) => {
    const { scheduleStore } = this.props;

    this.setState({ selectedProvider: event.target.value });
    scheduleStore.providerId = event.target.value;
    scheduleStore.changeApptSelect = false;
    this.setState({ selectedProviderName: this.getName(event.target.value) });
    careTeamsStore.setSelectedProvider('')
  };

  render() {
    const { scheduleStore } = this.props;

    return (
      <div className="Appointments-provider-wrapper">
        <div className="Appointments-labelText" id='provider-label'>
          <strong>Provider</strong>
        </div>
        <NativeSelect
          id="focus-ref-select-provider"
          value={careTeamsStore.selectedProviderName ? this.getSelectedProvider(careTeamsStore.selectedProviderName) : this.state.selectedProvider}
          onChange={this.handleChange}
          aria-haspopup={true}
          aria-label={`${careTeamsStore.selectedProviderName ? careTeamsStore.selectedProviderName : this.state.selectedProviderName} selected, please navigate to the calendar to see availability`}
          data-e2e='provider-dropdown'
        >
          {scheduleStore.providers.map((prov) => {
            return (
              <option
                className='provider-select-item'
                id="focus-ref-provider-menu-item"
                key={prov.id}
                value={prov.id}
              >
                    {prov.name}
              </option>
            );
          })}
        </NativeSelect>
      </div>
    );
  }
}

export default ProvidersSelect;
