'use strict'
Step = require 'components/mixins/Step'
GlobalClick = require 'components/mixins/GlobalClick'
config = require 'config'
utils  = require 'lib/utils'

{cx} = Exim.helpers
{main, section, div, button, label, span, input, p, tch, h2} = Exim.DOM

Conditions = Exim.createView module.id,
  name: 'conditions'
  mixins: [Step, GlobalClick]  
 
  getInitialState: ->
    actionsTarget: null

  globalClick: ->
    @unSelect()

  componentDidMount: ->
    @refs["#{config.checkinConditions[0].id}-button"]?.focus()

  componentDidUpdate: (prevProps, prevState) ->
    unless @state.actionsTarget is prevState.actionsTarget
      if @state.actionsTarget
        @refs["#{@state.actionsTarget}-self"]?.focus()
      else
        @refs["#{prevState.actionsTarget}-button"]?.focus()

  showActionsOnEnter: (id) -> (evt) =>
    return unless evt.which is 13
    @showActions(id)()

  showActions: (id) -> =>
    @setState actionsTarget: if id is @state.actionsTarget then null else id

  unSelect: ->
    @setState actionsTarget: null

  toggleSibling: (id, sibling) -> =>
    prev = @state.stepData[id] or []
    prev = ['other'] if typeof prev is 'boolean'
    if prev.indexOf(sibling) >= 0
      prev = prev.filter((item)->item isnt sibling)
    else
      prev.push(sibling)
    @updateData id, prev

  clearSiblings: (id) -> (evt) =>
    if @state.stepData[id]?.length
      @updateData id, []
    else
      @setState actionsTarget: null

  prevent: (evt) ->
    evt.stopPropagation()

  clickPill: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    evt.currentTarget.querySelector('input').click()

  conditionsLabel: ->
    return 'Are there any relevant medical issues, hospitalizations or surgeries not mentioned above? If so, please list them.'

  render: ->
    return div className: 'CheckinForm',
      h2 className: "SectionHeading", 'Medical Conditions'
      span className: 'CheckinForm-row CheckinForm-row--desc CheckinForm-row--no-margin',
        'Do any of the following apply to you or your family members?'
      div className: 'ContentBlock', onClick: @prevent,
        div className: 'Table Table--bordered ConditionsTable',
          div className: 'TableBody noselect',
            config.checkinConditions.map (cond) =>
              if @state.actionsTarget is cond.id
                curr = @state.stepData[cond.id] or []
                curr = ['other'] if typeof curr is 'boolean'
                sizeClass = "u-1of#{config.checkinSiblings.length + 2}"

                div className: 'ConditionsTable-item TableBody-row editing', key: cond.id,
                  div role: 'button', 'aria-expanded': true, className: 'TableBody-column u-2of5', 'aria-label': "#{cond.name} #{curr.map(utils.capitalize).join(', ')}", onClick: @showActions(cond.id), onKeyUp: @showActionsOnEnter(cond.id), tabIndex: 0,
                    cond.name
                  div className: 'TableBody-column u-3of5 ConditionsTable-item-selection u-textRight', 'aria-hidden': true,
                    label curr.map(utils.capitalize).join(', ')
                  div className: 'TableBody-column u-fullWidth ConditionsTable-item-pills',
                    config.checkinSiblings.map (sibling) =>
                      checked = curr and sibling in curr

                      button {
                        className: "Pill sibling #{cx {checked}}",
                        ref: "#{cond.id}-#{sibling}",
                        'aria-pressed': checked,
                        key: sibling,
                        onClick: @toggleSibling(cond.id, sibling),
                      }, utils.capitalize(sibling)
              else
                curr = (@state.stepData?[cond.id]) or []
                curr = ['other'] if typeof curr is 'boolean'
                anon = typeof curr is 'boolean'
                isSelected = curr.length > 0
                condData = if typeof curr is 'string' then curr else curr.map(utils.capitalize).join(', ')
                div role: 'button', ref: "#{cond.id}-button", 'aria-expanded': false, 'aria-labelledby': "cond-name-#{cond.id} cond-data-#{cond.id}", className: "TableBody-row ConditionsTable-item #{cx 'is-selected': isSelected}", onClick: @showActions(cond.id), onKeyUp: @showActionsOnEnter(cond.id), key: cond.id, tabIndex: 0,
                  div className: 'TableBody-column u-2of5', id: "cond-name-#{cond.id}", 'aria-hidden': true, cond.name
                  div className: 'TableBody-column u-3of5', id: "cond-data-#{cond.id}", "aria-hidden": true, condData

      div className: 'ContentBlock',
        label className: 'ConditionsLabel', @conditionsLabel()
        @textarea 'other_issues', useValue: true, className: 'Input Input--expand TextArea--xSmall Input--resizeVertical', description: @conditionsLabel()

      this.renderCheckInFooter()

module.exports = Conditions
