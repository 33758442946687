class HraConsentMessage extends React.Component {
  render() {
    return (
      <div>
        The Applied Materials Wellness Incentive Program (the “Wellness
        Program”) is a voluntary wellness program available to all employees.
        The Wellness Program is administered according to federal rules
        permitting employer-sponsored wellness programs that seek to improve
        employee health or prevent disease, including the Americans with
        Disabilities Act of 1990, the Genetic Information Nondiscrimination Act
        of 2008, and the Health Insurance Portability and Accountability Act, as
        applicable, among others.
        <p>
          As part of your participation in the Wellness Program, the following
          Wellness Program data will be collected:
        </p>
        <ul>
          <li>
            Results of your Health Risk Assessment and your biometric screenings
            (if applicable)
          </li>
          <li>
            Information collected in connection with your participation in the
            Wellness Program
          </li>
        </ul>
        Crossover Health Medical Group, APC (“Crossover Health”) will facilitate
        the Wellness Program, by compiling, maintaining and reporting the
        Wellness Program data, and this information will be used to provide you
        with information to help you understand your current health and
        potential risks and for the administration of the Wellness Program.
        Additionally, this information may be used to contact you for purposes
        of suggesting health improvement activities and to offer you services
        through the Wellness Program. You also are encouraged to share your
        results or concerns with your own doctor.
        <p>
          In order to participate, you must read and agree to the terms below.
        </p>
        <p className="Hra-statement-header">
          <span>Participant Authorization</span>
        </p>
        By clicking the “I agree” button and participating in the Wellness
        Program, I agree to the following terms:
        <ul>
          <li>
            I understand that my specific health information, such as responses
            to the health-related questions asked in the Health Risk Assessment
            and any health concern or condition disclosed by participation, will
            be kept secure and confidential by Crossover Health. Crossover
            Health will not disclose any identifiable health information without
            my written consent unless required or permitted by law and as
            described in the Crossover Health’s privacy policies.
          </li>
          <li>
            If I elect to participate in Crossover Health programs, emails
            regarding my participation in the programs may be sent to the email
            address that I provide to Crossover Health unless I elect to
            unsubscribe from receiving such emails.
          </li>
          <li>
            If my organization offers incentives or rewards for participating in
            the Health Risk Assessment, I understand that my name, address, the
            fact that I participated in the Health Risk Assessment, and the date
            I complete the Health Risk Assessment may be shared with my employer
            health plan or Crossover Health contractor, and/or health plan
            administrator (however, my specific health information will not be
            disclosed). I understand that if I do not agree to the use and
            disclosure of this information for the purposes of reward
            administration, I cannot participate in the Wellness Program.
          </li>
          <li>
            In addition, Crossover Health may provide my employer health plan or
            Crossover Health contractor aggregate information as part of a group
            summary report (however, my specific health information will not be
            disclosed). An aggregate report means that no individual can be
            identified in the report.
          </li>
        </ul>
      </div>
    );
  }
}

export default HraConsentMessage;
