"use strict";
const { observer } = require("mobx-react");
const { autorun } = require("mobx");
const ReactDOM = require("react-dom");
const ForcePatientModal = require("components/elements/ForcePatientModal");
const authStore = require("stores/new-auth");
const synopsisStore = require("stores/new-synopsis");
const appStore = require("stores/app");
const { cx } = require("lib/utils");

const PatientCard = require("components/elements/patient-card");
const Patient = require("models/patient");

const MAX_PATIENTS = 30;
const MAX_HISTORY = 10;
const MIN_QUERY = 3;

const EMPTY_STATE = {
  isFocused: false,
  isPartial: false,
  query: "",
  results: [],
  selectedIndex: 0,
};

autorun(() => {
  let { me, user } = authStore;
  if (me.isAdmin) {
    me.fetchSearchHistory();
  }
});

class PatientSearch extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.state = EMPTY_STATE;

    this.handleFocus = () => {
      this.setState({ isFocused: true });
    };

    this.handleSelect = () => {
      if (this.isAddPatient) {
        this.handleAdd();
      } else {
        let patient = this.patients[this.state.selectedIndex];
        this.props.onSelect(patient);
        this.handleClear();
      }
    };

    this.handleBlur = (evt) => {
      let wrapper = this.wrapperRef.current;
      if (wrapper.contains(evt.relatedTarget)) return;

      this.handleClear();
    };

    this.handleKeyDown = (evt) => {
      let withModifier = evt.ctrlKey || evt.altKey || evt.metaKey;
      let { selectedIndex } = this.state;

      switch (evt.key) {
        case "ArrowDown": {
          let newIndex = withModifier
            ? this.patients.length - 1
            : this._resolveIndex(selectedIndex + 1);

          this.setState({ selectedIndex: newIndex });
          this.scrollIntoView();

          evt.preventDefault();
          break;
        }

        case "ArrowUp": {
          let newIndex = withModifier
            ? 0
            : this._resolveIndex(selectedIndex - 1);

          this.setState({ selectedIndex: newIndex });
          this.scrollIntoView();

          evt.preventDefault();
          break;
        }

        case "Enter":
          this.handleSelect();
          evt.preventDefault();
          break;

        case "Escape":
          this.handleClear();
          evt.preventDefault();
          break;
      }
    };

    this.handleChange = (evt) => {
      this.setState({ query: evt.target.value });
    };

    this.handleClear = () => {
      this.setState(EMPTY_STATE);

      setTimeout(() => {
        let el = document.activeElement;
        let wrapper = this.wrapperRef.current;
        if (wrapper && wrapper.contains(el)) {
          el.blur();
        }
      }, 50);
    };

    this.handleAdd = () => {
      this.handleClear();
      this.props.onPatientAdd()
    };
  }

  get isHistoryMode() {
    return !this.state.query && authStore.me.hasSearchHistory;
  }

  get isSearchMode() {
    return this.state.query.trim().length >= MIN_QUERY;
  }

  get patients() {
    if (this.isHistoryMode) {
      let { me } = authStore;
      return me.searchHistory.slice(0, MAX_HISTORY);
    }

    return this.isSearchMode ? this.state.results : [];
  }

  get isOpen() {
    return this.state.isFocused && (this.isHistoryMode || this.isSearchMode);
  }

  get isAddPatient() {
    return (
      this.isSearchMode &&
      synopsisStore.hasScheduling &&
      !this.state.results.length
    );
  }

  componentDidUpdate(_, prevState) {
    if (this.state.query != prevState.query) {
      if (this.isSearchMode) {
        this.fetchResults();
      } else {
        this.clearResults();
      }
    }
  }

  scrollIntoView() {
    setTimeout(() => {
      let card = this.$selectedCard;
      if (card) {
        ReactDOM.findDOMNode(card).scrollIntoView({
          block: "nearest",
        });
      }
    }, 50);
  }

  _resolveIndex(index) {
    let { length } = this.patients;
    return index < 0 ? index + length : index % length;
  }

  async fetchResults() {
    let patients = await Patient.findAll(this.state.query);
    
    this.setState({
      isPartial: patients.length > MAX_PATIENTS,
      results: patients.slice(0, MAX_PATIENTS),
      selectedIndex: 0,
    });
  }

  clearResults() {
    this.setState({
      isPartial: false,
      results: [],
      selectedIndex: 0,
    });
  }

  renderClear() {
    return (
      <button
        className="PatientSearch-clear"
        aria-label="Clear search"
        onClick={this.handleClear}
      />
    );
  }

  renderPatients() {
    const renderPatient = (patient, index) => {
      let isSelected = index == this.state.selectedIndex;
      let ref = (el) => (this.$selectedCard = el);

      return (
        <PatientCard
          key={patient.id}
          modifier="search"
          ref={isSelected ? ref : null}
          patient={patient}
          isFull={this.isSearchMode}
          isSelected={isSelected}
          isSelectable={true}
          onClick={this.handleSelect}
          onMouseEnter={() => {
            this.setState({ selectedIndex: index });
          }}
        />
      );
    };

    return (
      <div className="PatientSearch-wrapper">
        <div className="PatientSearch-patients">
          {this.renderAddPatient()}
          {this.patients.map(renderPatient)}
          {this.renderRefineNote()}
        </div>
      </div>
    );
  }

  renderRefineNote() {
    if (!this.isSearchMode) return;
    if (!this.state.isPartial) return;

    return <div className="PatientSearch-refine">Refine your search...</div>;
  }

  renderAddPatient() {
    if (!this.isAddPatient) return;

    // Can't use <button> here because of Safari focus weirdness
    return (
      <div className="PatientSearch-add" tabIndex={0} onClick={this.handleAdd}>
        Add New Patient
      </div>
    );
  }

  render() {
    let { query } = this.state;
    let { modifier } = this.props;
    let wrapperClass = cx("PatientSearch", modifier, {
      open: this.isOpen,
    });

    return (
      <label
        className={wrapperClass}
        ref={this.wrapperRef}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        <input
          className="PatientSearch-input"
          value={query}
          type="search"
          placeholder="Find Patient..."
          aria-label="Find Patient..."
          autoComplete="off"
          autoCapitalize="off"
          spellCheck="false"
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
        />
        {query && this.renderClear()}
        {this.isOpen && this.renderPatients()}
      </label>
    );
  }
}

PatientSearch.defaultProps = {
  onSelect: () => {},
  onPatientAdd: () => {},
};

module.exports = observer(PatientSearch);
