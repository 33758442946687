{labsStore} = require 'stores'

Lab     = require './Lab'
utils   = require 'lib/utils'
IsEmpty = require 'components/elements/IsEmpty'
sort    = require 'components/mixins/sort'

{cx}  = Exim.helpers
{div, span, tch, strong} = Exim.DOM

All = Exim.createView module.id,
  mixins: [
    labsStore.connect('labs', 'labsLoading')
    sort(type: 'collected_at', asc: false, dates: ['collected_at'])
  ]

  componentWillMount: ->
    labsStore.actions.fetch()
    labsStore.actions.displayTZ()

  prevent: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()

  # Hack to make screen readers announce filter state on toggle
  toggleFilter: (sortKey, sortDirection, filterLabel) ->
    @refs.hidden?.innerHTML = "#{filterLabel} columnheader sorted #{sortDirection}" if sortDirection
    @sort(sortKey)

  render: ->
    titleSortDirection = @getAriaSortDirection('title')
    resultsSortDirection = @getAriaSortDirection('collected_at')

    div className: 'content-wrapper',
      div className: 'content',
        div className: 'table-block', onClick: @prevent,
          div className: 'Table LabsTable Table--bordered noselect',
            div className: 'TableHeader LabsHeader u-hidden-md-down',
              tch tagName: 'span', tabIndex: 0, 'aria-sort': titleSortDirection, 'aria-label': "Labs columnheader #{titleSortDirection or ''}", className: 'TableHeader-column u-3of5 LabsHeader-column title', key: 'labs', onEnter: @toggleFilter('title', titleSortDirection, 'Labs'), handler: @toggleFilter('title', titleSortDirection, 'Labs'),
                div className: "sort #{@isSorted('title')}",
                  'Labs'
              tch tagName: 'span', tabIndex: 0, 'aria-sort': resultsSortDirection, 'aria-label': "Result Date columnheader #{resultsSortDirection or ''}", className: 'TableHeader-column TableHeader-column--pullRight u-1of5 LabsHeader-column resulted', key: 'results', onEnter: @toggleFilter('collected_at', resultsSortDirection, 'Result Date'), handler: @toggleFilter('collected_at', resultsSortDirection, 'Result Date'),
                div className: "sort #{@isSorted('collected_at')}",
                  strong 'Result Date'
              div 'aria-live': 'polite', className: 'AriaLiveForScreenReaders', ref: 'hidden', ''
            IsEmpty className: 'TableBody LabsBody items', ifEmpty: 'No labs.', loading: labsStore.get('labsLoading'),
              @state.labs.sort(@getSortFn()).map (doc) ->
                Lab data: doc, key: doc.id

module.exports = All
