import authStore from "stores/new-auth";
import { Modal } from "components/elements";
import { shortWeekdays } from "config";
import { flatten, values } from "lodash";
import { timeUnits } from "lib/utils";

const isMatch = (w, appt) => isMatchByDay(w, appt) && isMatchByType(w, appt);
const isMatchByDay = (w, appt) => {
  if (w.days == "all") return true;

  let apptDay = appt.startAt.day();
  return w.days.some((day) => apptDay == shortWeekdays.indexOf(day));
};

const parseTime = (raw) => moment(raw, "HH:mm");
const isMatchByType = (w, appt) => {
  let now = moment();

  switch (w.type) {
    case "duration_after_start_of_appointment":
      return now - appt.startAt > moment.duration(w.duration);

    case "duration_before_start_of_appointment":
      return appt.startAt - now < moment.duration(w.duration);

    case "duration_before_time_of_day":
      let time = parseTime(w.time_of_day);
      let apptDay = appt.startAt.clone().set(timeUnits(time));

      return apptDay - now < moment.duration(w.duration);
  }

  return false;
};

const formatDuration = (raw) => {
  let dur = moment.duration(raw);

  for (let unit of ["hour", "minute"]) {
    let num = dur.as(unit);
    if (Number.isInteger(num)) {
      return `${num} ${unit}`;
    }
  }
};

const formatTime = (raw) => {
  let time = parseTime(raw);
  let tokens = time.minutes() ? "H:mm a" : "H a";
  return time.format(tokens);
};

const formatWindow = (w) => {
  switch (w.type) {
    case "duration_before_start_of_appointment":
      return `the ${formatDuration(
        w.duration
      )} window before their scheduled visit`;

    case "duration_before_time_of_day":
      return `the ${formatDuration(w.duration)} window prior to ${formatTime(
        w.time_of_day
      )} the day of visit`;
  }
};

const formatFee = (fee) => {
  return fee.amount.toLocaleString("en-US", {
    style: "currency",
    currency: fee.currency,
  });
};

class CancelApptModal extends React.Component {
  constructor() {
    super();

    this.reasonRef = React.createRef();
    this.state = {
      isCanceling: false,
      isFetchingPolicy: false,
      policy: {
        late_cancellation: { windows: [] },
        no_show: { windows: [] },
        triggers: {},
      },
    };
  }

  /*
  Accessibility fix:  A visually imperceptible CSS transition occurs when keyboard focus is lost in certain modals that make use of 
  this Modal component. Therefore, we add an event listener to assign focus back to the modal in question on "transitionend". This 
  keeps keyboard focus inside the modal until the modal is closed. The event listener is removed when the component unmounts
  */
  focusCloseButton() {
    const closeButton = document.querySelector(".NewModal-close");
    if (closeButton) {
      closeButton.focus();
    }
  }

  manageFocus() {
    document.addEventListener("transitionend", (e) => this.focusCloseButton());
  }

  async componentDidMount() {
    let { me } = authStore;
    if (me.isAdmin) {
      this.manageFocus();
      return;
    }
    let { appointment } = this.props;
    this.setState({ isFetchingPolicy: true });

    try {
      this.setState({
        policy: await appointment.getCancellationPolicy(),
      });
    } finally {
      this.setState({ isFetchingPolicy: false });
      this.manageFocus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("transitionend", (e) =>
      this.focusCloseButton()
    );
  }

  get displayType() {
    let { me } = authStore;
    if (me.isAdmin) {
      switch (this.props.type) {
        case "late_cancellation":
          return "Late Cancellation";
        case "no_show":
          return "No Show";
      }
    } else if (this.missWindow || this.isNoShow) {
      return "Late Cancellation";
    }

    return "Cancellation";
  }

  get missWindow() {
    let { policy } = this.state;
    let { appointment } = this.props;
    // if there's no missed appointment policy in place state.policy will
    // be an empty object, and we need to escape the function
    if (Object.keys(policy).length === 0) {
      return false;
    }
    return policy.late_cancellation.windows.find((window) =>
      isMatch(window, appointment)
    );
  }

  get isNoShow() {
    let { policy } = this.state;
    let { appointment, type } = this.props;
    if (type == "no_show") return true;
    // if there's no missed appointment policy in place state.policy will
    // be an empty object, and we need to escape the function
    if (Object.keys(policy).length === 0) {
      return false;
    }
    return policy.no_show.windows.some((window) =>
      isMatch(window, appointment)
    );
  }

  async cancelAppt() {
    if (this.state.isCanceling) return;

    let { appointment, resolve, abort } = this.props;
    resolve(
      (async () => {
        this.setState({ isCanceling: true });
        const params = {
          reason: this.reasonRef.current.value,
          no_show: this.isNoShow,
        };
        if (authStore.me.isAdmin && this.props.type == "late_cancellation") {
          params.cancel_type = "late_cancel";
        }

        let cancelMessage;
        try {
          await appointment.cancel(params);
          cancelMessage = "Appointment cancelled successfully";
        } catch (err) {
          console.error(err);
          cancelMessage = "Failed to cancel the appointment";
        } finally {
          this.setState({ isCanceling: false });
          if (this.props.close) {
            this.props.close(cancelMessage);
          } else {
            abort();
          }
        }
      })()
    );
  }

  renderFeeWarning() {
    let window = this.missWindow;
    if (!window) return;

    const { appointment } = this.props;
    let { policy } = this.state;
    let triggers = flatten(values(policy.triggers[0]));
    let fee = triggers.find((t) => t.type == "charge_fee");
    // https://www.pivotaltracker.com/n/projects/2235641/stories/165796053
    const sitesWithoutWarnings = ["Spring, TX (Spring)"];
    const renderFeeWarning = !sitesWithoutWarnings.some(
      (site) => appointment.site.name === site
    );

    return (
      <div className="CancelApptModal-warning">
        <p>
          Due to the timing of this cancellation, this will be considered a
          late cancellation.
        </p>
        {renderFeeWarning && (
          <p>
            According to our Late Cancellation policy, members
            cancelling/rescheduling within {formatWindow(window)} will be
            charged a {formatFee(fee)} fee. If this is your first late 
            cancellation this year, the fee will be waived. If you’ve already
            received one fee waiver this year, you will be charged{" "}
            {formatFee(fee)}.
          </p>
        )}
        <p>Are you certain you want to cancel this appointment?</p>
      </div>
    );
  }

  renderContent() {
    let { me } = authStore;
    let { appointment } = this.props;

    return (
      <React.Fragment>
        {me.isAdmin ? (
          <p className="CancelApptModal-name">
            Patient name: <b>{appointment.patient.name}</b>
          </p>
        ) : (
          this.renderFeeWarning()
        )}
        <textarea
          className="CancelApptModal-reason"
          disabled={this.state.isCanceling}
          autoFocus
          placeholder={`${this.displayType} reason (optional)`}
          ref={this.reasonRef}
          onKeyUp={(evt) => {
            if (evt.metaKey && evt.key == "Enter") {
              evt.preventDefault();
              this.cancelAppt();
            }
          }}
        />
        <div className="CancelApptModal-footer">
          <button
            className="Button Button--cancel"
            disabled={this.state.isCanceling}
            onClick={this.props.close ? this.props.close : this.props.abort}
          >
            Close
          </button>
          <button
            className="Button Button--primary"
            disabled={this.state.isCanceling}
            onClick={() => this.cancelAppt()}
          >
            Confirm
          </button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.isFetchingPolicy) return null;

    return (
      <>
        <span tabIndex="0"></span>
        <Modal
          title={this.displayType}
          activatorId="action-dropdown-show-additional-options"
          elementId={this.props.appointment.id}
          close={this.props.close ? this.props.close : this.props.abort}
          className="CancelApptModal"
        >
          {this.renderContent()}
        </Modal>
        <span tabIndex="0"></span>
      </>
    );
  }
}

module.exports = CancelApptModal;
