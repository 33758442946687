utils = require 'lib/utils'

sort = (opts) ->
  opts.asc ?= false
  opts.type ?= 'starts_at'
  opts.dates ?= ['created_at', 'date', 'starts_at', 'start_at']
  opts.label ?= utils.capitalize opts.type
  opts.arrays ?= []
  opts.objects ?= []
  prevType = opts.type

  {
    getInitialState: ->
      sort: {type: opts.type, asc: opts.asc, label: opts.label}

    sort: (type, label, asc) -> (evt) =>
      evt?.preventDefault()
      unless asc?
        {asc} = @state.sort
        asc = not asc if type is prevType
      prevType = type
      @setState sort: {asc, type, label}

    getSortFn: (sort=@state.sort) ->
      {type, asc} = sort
      objToStr = (a) -> Object.keys(a).map((i) -> a[i]).join(' ')
      compare = (a, b, aId, bId) ->
        diff = if type in opts.dates then moment(b).toDate() - moment(a).toDate()
        else if type in opts.arrays then a.join('').localeCompare b.join('')
        else if type in opts.objects then objToStr(a).localeCompare objToStr(b)
        else (a or '').localeCompare b or ''
        diff = aId.toString().localeCompare bId.toString() if diff is 0 and aId? and bId?
        return diff
      fn = if bool = opts.bool
        (a, b) ->
          boolCompare = (item) ->
            if typeof bool is 'function' then bool(item) else item[bool]
          if boolCompare(a) is boolCompare(b)
            if asc
              compare b[type], a[type], b.id, a.id
            else
              compare a[type], b[type], a.id, b.id
          else
            if boolCompare(a) then -1 else 1
      else if asc
        (a, b) ->
          compare b[type], a[type], b.id, a.id
      else
        (a, b) -> compare a[type], b[type], a.id, b.id

    isSorted: (type) ->
      if @state.sort.type is type
        "TableHeader-column--sorted TableHeader-column--sorted-#{if @state.sort.asc then 'ascend' else 'desc'}"
      else
        ''

    getAriaSortDirection: (type) ->
      return null unless @state.sort.type is type

      if @state.sort.asc
        "ascending"
      else
        "descending"
  }

module.exports = sort
