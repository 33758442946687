"use strict";
const { observer } = require("mobx-react");
const slotsStore = require("stores/new-slots");
const calStore = require("stores/cal");
const calcClusters = require("./calc-clusters");
const SlotView = require("./slot");
const SlotCreator = require("./slot-creator");
const SlotDroppable = require("./slot-droppable");
const FutureLine = require("./future-line");
const { flatMap } = require("lodash");
const { cx, onlyTime } = require("lib/utils");
const Slot = require("models/slot");
const { showModal } = require("components/elements/new-modal");
const SlotModal = require("./slot-modal");
const DeleteOOOModal = require("./delete-ooo-modal");
const oooStore = require("stores/ooo");

const byStartAndDuration = (a, b) => {
  return a.startTime - b.startTime || b.duration - a.duration;
};

const renderLane = (lane, at, lanes) => {
  return lane.slots.map((slot) => {
    return (
      <SlotView key={slot.id} slot={slot} lane={at} lanesCount={lanes.length} />
    );
  });
};

class Day extends React.Component {
  async forceSlot() {
    let { provider, date } = this.props;

    let slot = new Slot({ provider, date });
    slot.duration = moment.duration(30, "minutes");
    slot.startTime = onlyTime(slot.site.now).ceil(30, "minutes");

    try {
      await slot.post();
      slotsStore.addSlot(slot);
      showModal(<SlotModal slot={slot.saveState()} />);
    } catch (err) {
      console.error(err);
      alert("Failed to create a slot.");
    }
  }

  renderClosed(modifier, ms) {
    let closedClass = cx("ScheduleCalendarDay-closed", modifier);
    let dur = moment.duration(ms);
    let style = {
      height: `${calStore.getHeight(dur)}px`,
    };

    return (
      <div
        className={closedClass}
        style={style}
        onClick={() => this.forceSlot()}
      />
    );
  }

  renderSlots() {
    let { provider, date } = this.props;
    let slots = slotsStore.getSlots(provider, date);
    let clusters = calcClusters(slots);

    return flatMap(clusters, (cluster) => {
      let lanes = cluster.calcLanes().sort(byStartAndDuration);

      return flatMap(lanes, renderLane);
    });
  }

  renderOOOs(OOOs) {
    let { provider, date } = this.props;
    let closedClass = cx("ScheduleCalendarDay-closed", "ooo");

    let renderOOO = (ooo) => {
      let showDeleteModal = () => {
        showModal(<DeleteOOOModal provider={provider} ooo={ooo} />);
      };

      return (
        <button
          key={ooo.id}
          className="ScheduleCalendarDay-ooo"
          onClick={showDeleteModal}
        >
          OOO: {ooo.range.format("M/D")}
        </button>
      );
    };

    return (
      <div className="ScheduleCalendarDay">
        {OOOs.map(renderOOO)}
        <div className={closedClass} />
        <FutureLine date={date} />
      </div>
    );
  }

  render() {
    let { provider, date } = this.props;
    let OOOs = oooStore.findOOOs(provider, date);
    if (OOOs.length) return this.renderOOOs(OOOs);

    let workingRange = provider.site.getWorkingRange(date);
    let { displayRange } = calStore;

    return (
      <div className="ScheduleCalendarDay">
        <SlotCreator {...this.props} />
        {this.renderClosed("start", workingRange.start - displayRange.start)}
        {this.renderSlots()}
        {this.renderClosed("end", displayRange.end - workingRange.end)}
        <FutureLine date={date} />
        <SlotDroppable {...this.props} />
      </div>
    );
  }
}

module.exports = observer(Day);
