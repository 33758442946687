{appStore, authStore, medicationsStore} = require 'stores'
attachHeader       = require 'components/mixins/attachHeader'
Item               = require 'components/medications/Item'
sort               = require 'components/mixins/sort'
PullToRefresh      = require 'components/elements/PullToRefresh'
IsEmpty            = require 'components/elements/IsEmpty'
utils              = require 'lib/utils'
Footer             = require 'components/app/Footer'
PatientHeader      = require 'components/elements/PatientHeader'

{cx} = Exim.helpers
{main, section, div, span, tch, table, th, tr} = Exim.DOM

Medications = Exim.createView module.id,
  displayName: 'Medications'


  mixins: [
    authStore.connect('user')
    sort(type: 'medication', asc: false)
    attachHeader(name: 'Medications')
  ]

  listen: [
    'medications/medications', 'medications/medicationsLoading'
  ]

  getInitialState: ->
    selected: null

  componentWillMount: ->
    medicationsStore.actions.fetch().catch ->

  componentDidMount: ->
    appStore.actions.setTitle('Medications')
    document.querySelector('.medication').focus()

  componentWillUnmount: ->
    appStore.actions.setTitle()
    medicationsStore.actions.clear()

  componentDidUpdate: (nextProps, nextState) ->
    if (nextState.user.id != @state.user.id)
      medicationsStore.actions.fetch()

    if @state.selected
      @refs[@state.selected]?.focus()

  toggleSelected: (id) ->
    @setState selected: if id is @state.selected then null else id

  toggleFilter: (sortKey) ->
    @sort(sortKey)

  render: ->
    medsLoading = medicationsStore.get('medicationsLoading')
    medTableHeaders = ['medication', 'instructions', 'quantity']
    if @state.medications?.filter((med) -> med.order_date isnt null).length > 0
      medTableHeaders = medTableHeaders.concat('order date')
      medTableHeaders = medTableHeaders.concat('actions')
      tableHeaderColumnClass = 'TableHeader-detailed-col'

    main id: 'mainContent', className: "Content #{cx 'Content--withPatientHeader': @state.user.proxying}",
      PullToRefresh tag: section, className: 'Content-body Meds-body', onRefresh: medicationsStore.actions.fetch, isLoading: medsLoading,
        div className: 'ContentInnerWrapper',
          PatientHeader()
          div className: 'ContentInner',
            table role: 'table', className: 'Table Table--fullWidth Table--bordered', 'aria-label': 'Medications', 'aria-colcount': medTableHeaders?.length, 'aria-rowcount': @state.medications?.length,
              tr role: 'row', className: 'TableHeader MedsHeader',
                medTableHeaders.map (key, i) =>
                  keyClass = key.replace(' ', '_')

                  sortKey = if key is 'quantity'
                    'qty'
                  else if key is 'instructions'
                    'dose_instructions'
                  else if key is 'order date'
                    'order_date'
                  else
                    key

                  sortDirection = @getAriaSortDirection(sortKey)
                  filterLabel = utils.capitalize(key)
                  tch
                    tagName: 'th',
                    role: if key is 'actions' then 'div' else 'button',
                    tabIndex: 0,
                    'aria-sort': sortDirection,
                    'aria-label': "Column #{i + 1} #{filterLabel} #{if sortDirection then "sorted #{sortDirection}" else ''}",
                    className: if key is 'actions' then "TableHeader-column u-textLeft" else "TableHeader-column #{tableHeaderColumnClass} u-1of4 u-noselect #{keyClass} #{cx 'u-textLeft': ['instructions', 'order date', 'quantity'].includes(key)}",
                    onEnter: if key is 'actions' then '' else @toggleFilter(sortKey, sortDirection, filterLabel),
                    handler: if key is 'actions' then '' else @toggleFilter(sortKey, sortDirection, filterLabel),
                    key: key,
                    div className: "sort #{@isSorted(sortKey)}",
                      span className: 'TableHeader-column', utils.capitalize key
              div 'aria-live': 'polite', className: 'AriaLiveForScreenReaders', ref: 'hidden', ''
              IsEmpty className: 'TableBody', ifEmpty: 'No medications', loading: medsLoading,
                @state.medications?.sort(@getSortFn()).map (doc) =>
                  Item
                    ref: doc.id
                    key: doc.id
                    data: doc
                    selected: doc.id is @state.selected
                    onSelect: @toggleSelected
          Footer()

module.exports = Medications
