{div, h2, p, button, span, ul, li, tch} = Exim.DOM

WhichSignInTypeModal = Exim.createView module.id,
  listen: ['app/config']

  contextTypes:
    router: React.PropTypes.object.isRequired

  goToSignUp: ->
    @props.close()
    @context.router.push pathname: '/signup'

  render: ->
    title = @state.config?.frontend?.app?.title4 or 'Crossover Health Center'

    div className: 'WhichSignInTypeModal',
      h2 className: 'WhichSignInType--header', 'Which Sign In is for me?'
      div className: 'WhichSignIn--activate-note',
        span, 'Prior activation is required: ',
        tch tagName: 'a', href: '#', className: 'Auth--informational-link', handler: @goToSignUp, 'Activate here'
      div className: 'SignInInfo',
        p null,
          "Sign In to your #{title} account with your Employee credentials"
        ul null,
          li null, 'Your work email, name, and security credentials will be passed to the portal'
          li null, 'There is no need to create/remember separate passwords or user IDs'
        p null,
          "Or Sign In using account credentials you have previously created on the #{title} portal"
        ul null,
          li null, 'Use the credentials you specified when you Activated your portal account'

        h2 className: 'SignInInfo-header', 'What about my privacy?'
        p null,
          "Only you can access your information in the #{title} portal; your employer will not have access to your information"

      tch tagName: 'button', className: "SignInInfo-button Button Button--primary Button--large Button-extend Button--auth", handler: @props.close, 'Close'

module.exports = WhichSignInTypeModal
