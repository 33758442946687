{authStore, chargesStore} = require 'stores'
oldAppStore = require 'stores/app'
appStore = require 'stores/new-app'
Spinner = require 'components/elements/Spinner'
Checkbox = require '../elements/Checkbox'
BackSwiper = require('components/elements/back-swiper')
{Link, withRouter} = require('react-router')
{observer} = require('mobx-react')
{client} = require 'config'
utils = require 'lib/utils'

{cx} = Exim.helpers
{div, span, main, tch, fa, input, label, strong, h2, button} = Exim.DOM

cardNumRegex = /^(\d{0,4}\s?){0,4}$/
BACK_TO = '/profile'

errorInfo = {
  cardNumber: {
    id: 'cc-num-error',
    message: 'Invalid Credit Card number',
  },
  expiry: {
    id: 'expiration-date-error',
    message: 'Invalid Expiration Date',
  },
  cvc: {
    id: 'cvc-error',
    message: 'Invalid CVC Security Code',
  }
}

AddPaymentMethod = React.createClass({
  displayName: 'AddPaymentMethod',
  mixins: [
    authStore.connect('user'),
    chargesStore.connect('error'),
  ],

  getInitialState: ->
    loading: false
    cardName: ''
    cardNumber: ''
    expiryYear: ''
    expiryMonth: ''
    cvc: ''
    errors: []
    expiry: ''
    cardType: 'personal'
    primary: false

  componentWillMount: ->
    oldAppStore.actions.setHeader(name: 'Add Payment Method')

  componentDidMount: ->
    @refs.ccname?.focus()

  componentWillUpdate: (nextProps, nextState) ->
    if nextState.error isnt @state.error
      nextState.loading = false

  announceValidationErrors: ->
    {errors} = @state
    screenReaderErrorMessage = "Validation Errors: "
    for error in errors
      screenReaderErrorMessage = screenReaderErrorMessage + " " + errorInfo[error]['message']
    if errors.length > 0
      utils.screenReaderSpeak(screenReaderErrorMessage, "assertive")

  updateCardName: (evt) ->
    @setState {cardName: evt.target.value}

  updateCardNumber: (evt) ->
    {value} = evt.currentTarget
    return unless cardNumRegex.test value
    return if value.length > 19
    if value.length > @state.cardNumber.length
      value =
        switch value.length
          when 4, 9, 14 then "#{value} "
          else value
    @updateData('cardNumber', value)

  blurCardNumber: (evt) ->
    { cardNumber } = @state
    if cardNumber
      @validateInput('cardNumber')(evt)
    @announceValidationErrors()

  updateCvc: (evt) ->
    {value} = evt.currentTarget
    return unless /^\d{0,4}$/.test value
    return if value.length > 4
    @updateData('cvc', value)

  updateExpiryMonth: (evt) ->
    {value} = evt.currentTarget
    wasNumber = !isNaN(+value.slice(0, -1))
    return if wasNumber and (not /^\d{0,2}\s?$/.test(value) or value.length > 2)
    @updateData('expiryMonth', value)
    if value.length is 2
      @refs['exp-year'].focus()

  blurExpiryMonth: (evt) ->
    { expiryMonth, expiryYear } = @state
    currValue = evt.currentTarget.value
    validMonth = parseInt(currValue) > 0 and parseInt(currValue) <= 12
    if currValue? and +currValue isnt 0 and currValue.toString().length is 1
      updated = "0#{currValue}"
      @updateData('expiryMonth', updated)
    if expiryMonth and expiryYear or !validMonth and expiryMonth
      @validateInput('expiryMonth')(evt)
    @announceValidationErrors()

  updateExpiryYear: (evt) ->
    {value} = evt.currentTarget
    nan = isNaN(value)
    return if value.length > 4 or nan
    @updateData('expiryYear', value)
    if value.length is 4
      @refs.cvv.focus()

  blurExpiryYear: (evt) ->
    { expiryYear } = @state
    currValue = evt.currentTarget.value
    if currValue?
      updated = switch currValue.toString().length
        when 2 then "20#{currValue}"
        when 1 then "201#{currValue}"
        else false
      @updateData('expiryYear', updated) if updated
    if expiryYear
      @validateInput('expiryMonth')(evt)
      @validateInput('expiryYear')(evt)
    @announceValidationErrors()

  blurCVC: (evt) ->
    fields = [
      'cardNumber'
      'expiryMonth'
      'expiryYear'
      'cvc'
    ]
    i = 0
    while i < fields.length
      @validateInput(fields[i])(evt)
      i++
    @announceValidationErrors()

  updateData: (name, value) ->
    {errors} = @state
    errName = if name in ['expiryMonth', 'expiryYear'] then 'expiry' else name
    if (index = errors.indexOf(errName)) >= 0
      errors.splice(index, 1) if @check(errName, value)
    state = {}
    state[name] = value
    state.errors = errors
    @setState state

  check: (name, value) ->
    {card} = appStore.stripe
    {cardNumber, expiryMonth, expiryYear, cvc} = @state
    switch name
      when 'cardNumber'
        card.validateCardNumber(cardNumber)
      when 'expiry'
        card.validateExpiry(expiryMonth, expiryYear)
      when 'cvc'
        card.validateCVC(cvc)

  validateInput: (name, value) -> (evt) =>
    {errors, error} = @state
    chargesStore.actions.clearError() if error
    errName = if name in ['expiryMonth', 'expiryYear'] then 'expiry' else name
    if @check(errName, @state[name])
      if (index = errors.indexOf(errName)) >= 0
        errors.splice(index, 1)
    else
      errors.push errName unless errName in errors

    @setState {errors}

  chargeDisabled: ->
    errors = @validate()
    errors.length

  validate: ->
    {card} = appStore.stripe
    {cardNumber, expiryMonth, expiryYear, cvc} = @state
    errors = []
    errors.push 'cardNumber' unless card.validateCardNumber(cardNumber)
    errors.push 'cvc' unless card.validateCVC(cvc)
    errors.push 'expiry' unless card.validateExpiry(expiryMonth, expiryYear)
    errors

  saveCard: (evt) ->
    errors = @validate()

    return if errors.length

    {cardNumber, expiryMonth, expiryYear, cvc} = @state

    @setState loading: true

    appStore.stripe.card.createToken({
      number: cardNumber
      cvc: cvc
      exp_month: expiryMonth
      exp_year: expiryYear
    }, @stripeResponseHandler)

  stripeResponseHandler: (status, response) ->
    if response.error
      @setState {error: response.error.message}
      console.log response.error.message
    else
      {id} = @state.user
      {card} = response
      cardName = if @state.cardType is 'hsafsa' then 'HSA/FSA' else @state.cardName
      chargesStore.actions.addCard(id, cardName, card?.brand, card?.id, @state.primary, card?.last4, response?.id).then =>
        this.backTo()

  backTo: ->
    this.props.router.push(BACK_TO)
    alert('Payment method has been added.')

  onTypeSelect: (type) -> =>
    @setState cardType: type

  handlePrimaryCheckbox: ->
    @setState primary: !@state.primary

  render: ->
    return null if !appStore.stripe
    {errors, error, cardType, loading} = @state
    cardTypes = [{key: 'personal', title: 'Personal Card'}, {key: 'hsafsa', title: 'HSA/FSA Card'}]
    addCardTitlesStyle = if (client == 'microsoft' || client == 'docker') then {color: "#575757"} else null

    main id: 'mainContent', className: 'Content',
      div className: 'Content-body',
        React.createElement(BackSwiper, {to: BACK_TO})
        div className: 'ContentInner AddPayment',
          React.createElement(Link, {
            className: 'Button Button--back',
            to: BACK_TO,
            role: 'button',
          }, 'Cancel Add Payment Method')

          div className: 'AddPaymentTitle', h2 id: 'add-new-card', 'aria-label': 'add new card', 'Add new card'

          div className: 'AddPaymentForm',
            div className: 'AddPaymentCardTypes',
              tch tagName: 'ul', style: {padding: 0}, 'aria-labelledby': 'add-new-card',
                cardTypes.map ({key, title}) =>
                  tch key: key, className: 'AddPaymentCardType', handler: @onTypeSelect(key), tagName: 'li',
                    input type: "radio", name: "card-types", value: key, id: key, className: "AddPaymentCardType-radio #{cx selected: cardType is key}"
                    label htmlFor: key, style: addCardTitlesStyle,
                      title

            div className: 'PaymentModalBody',
              div className: 'InputAddonWrapper',
                div className: 'InputAddon',
                  fa 'user'
                input ref: 'card-name', type: 'text', id:'ccname', ref: 'ccname', name:'cardName', className: 'Input Input--expand', placeholder: 'Card nickname', 'aria-label': 'Card nickname', value: @state.cardName, onChange: @updateCardName
              div className: 'InputAddonWrapper', key: 'card-name',
                div className: 'InputAddon',
                  fa 'credit-card'
                input ref: 'card-n', type: 'text', id:'ccnumber', name:'cardNumber', autoComplete: 'cc-number', className: "Input Input--expand #{cx 'payment-input-error': 'cardNumber' in errors}", placeholder: 'Card number', 'aria-label': 'Card number', 'data-stripe': 'number', value: @state.cardNumber, onChange: @updateCardNumber, onBlur: @blurCardNumber, ref: 'ccnumber'
              div className: 'PaymentModal-row', key: 'control-row',
                input id:'cc-exp-month', name: 'cc-exp-month', type: 'number', min: '0', max: '12', ref: 'exp-month', autoComplete: 'cc-exp-month', className: "Input Input--expand #{cx 'payment-input-error': 'expiry' in errors}", placeholder: 'MM', value: @state.expiryMonth, onChange: @updateExpiryMonth, onBlur: @blurExpiryMonth, 'aria-label': 'Expiration Month'
                input id:'cc-exp-year', type: 'number', min: '0', ref: 'exp-year', autoComplete: 'cc-exp-year', className: "Input Input--expand #{cx 'payment-input-error': 'expiry' in errors}", placeholder: 'YY', value: @state.expiryYear, onChange: @updateExpiryYear, onBlur: @blurExpiryYear, 'aria-label': 'Expiration Year'
                div className: 'InputAddonWrapper',
                  div className: 'InputAddon',
                    fa 'lock'
                  input type: 'text', ref: 'cvv', 'data-e2e': 'cvc-input', autoComplete: 'cc-csc', className: "Input Input--expand #{cx 'payment-input-error': 'cvc' in errors}", placeholder: 'CVC', 'data-stripe': 'cvc', value: @state.cvc, onChange: @updateCvc, onBlur: @blurCVC, 'aria-label': 'CVC Security Code'

              div className: "Error ErrorMessage #{cx 'AddPaymentCardError': errors.length > 0 || !!error} AddPaymentCardErrorHidden",
                span className: 'errorTitle', strong 'Validation Errors'
                errors.map (error) =>
                    span key: "#{errorInfo[error]['id']}", 'data-e2e': "#{errorInfo[error]['id']}", "- #{errorInfo[error]['message']}"
                if error then span error

              div className: 'AddPaymentPrimary',
                Checkbox className: 'AddPaymentPrimary--Checkbox', onChange: @handlePrimaryCheckbox, 'aria-labelledby': 'add-payment-make-primary'
                span id: 'add-payment-make-primary', style: addCardTitlesStyle,
                  'Make Primary'

              Spinner state: loading,
                div className: 'PaymentModalFooter',
                  React.createElement(Link, {
                    className: 'Button Button--cancel',
                    to: BACK_TO,
                    role: 'button',
                  }, 'Cancel')
                  tch tagName: 'button', id: "save-card-button", className: "Button Button--primary Button--pointer #{cx 'is-disabled': @chargeDisabled()}", handler: @saveCard, disabled: @chargeDisabled(), role: 'button', 'Save Card'
})

module.exports = observer(AddPaymentMethod)
