"use strict";
const authStore = require("stores/new-auth");
const PatientSearch = require("components/elements/patient-search");
const withMediaQueries = require("components/elements/with-media-queries");
const { observer } = require("mobx-react");
const { mdDown } = require("lib/media-queries");
const { proxyPatient } = require("lib/utils");

const PatientSearchHeader = ({ isMobile }) => {
  if (!isMobile) return null;

  let { me } = authStore;
  if (!me.isAdmin) return null;

  return (
    <div className="PatientHeader PatientHeader--search">
      <PatientSearch modifier="header" onSelect={proxyPatient} />
    </div>
  );
};

module.exports = withMediaQueries(observer(PatientSearchHeader), {
  isMobile: mdDown,
});
