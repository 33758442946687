import Card from "./card";
import { healthScreening } from "config";

class HealthScreening extends React.Component {
  healthScreeningContent(intro, message, imageURL, renderLink = false) {
    return (
      <div>
        <div className="HraScreening-intro">{intro}</div>
        <div className="HraScreening-content">
          <div className="HraScreening-message-annual">
            <img alt="" src="/combo/images/hra/icon-step-1-complete.png" />
          </div>
          <div className="HraScreening-message-biennial">
            <img alt="" src={imageURL} />
            <p className="HealthScreening-step2-message">
              {message}
              {renderLink && (
                <a
                  href="https://microsite.ehr.com/usa-amp-wellness/health-and-wellbeing/wellness/health-screenings"
                  target="_blank"
                >
                  AMP Wellness Website
                </a>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderHealthScreeningContent(screeningStatus) {
    switch (screeningStatus) {
      case "not_applicable":
        const { hraOnlyTab, stepTwoNotApplicable } = healthScreening;
        const imageURLNotApplicable =
          "/combo/images/hra/icon-step-2-notapplicable.png";
        return this.healthScreeningContent(
          hraOnlyTab,
          stepTwoNotApplicable,
          imageURLNotApplicable
        );

      case "complete":
        const { screeningMethodTab } = healthScreening;
        const imageURLComplete = "/combo/images/hra/icon-step-2-complete.png";
        return this.healthScreeningContent(
          screeningMethodTab,
          "",
          imageURLComplete
        );

      case "incomplete":
        const { nullScreeningMethodTab, stepTwoIncomplete } = healthScreening;
        const renderLink = true;
        const imageURLIncomplete =
          "/combo/images/hra/icon-step-2-incomplete.png";
        return this.healthScreeningContent(
          nullScreeningMethodTab,
          stepTwoIncomplete,
          imageURLIncomplete,
          renderLink
        );

      default:
        return null;
    }
  }

  render() {
    const { title, screeningStatus, className } = this.props;

    return (
      <React.Fragment>
        <Card title={title} iconClass="fa-check-square" className={className}>
          <div className="Hra-body">
            {this.renderHealthScreeningContent(screeningStatus)}
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default HealthScreening;
