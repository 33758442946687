ReactSelect = require('react-select').default
ReactDOM = require 'react-dom'
{clone} = require 'lib/utils'
{div, span, fa} = Exim.DOM
{cx} = Exim.helpers
{isEdge} = require 'config'

Select = Exim.createView module.id,
  propTypes:
    options: React.PropTypes.array
    value: React.PropTypes.any
    onChange: React.PropTypes.func

  componentDidMount: ->
    {select} = @refs

    if @props.savedHover
      select.getFocusableOption = (selectedOption) ->
        {props} = select
        {value} = props
        options = @_visibleOptions
        return if not options.length
        selected = options.find((opt) -> opt[props.valueKey] is value)
        {focusedOption} = @state
        if focusedOption isnt selected
          focused = focusedOption and options.find((opt) ->
            opt[props.valueKey] is focusedOption[props.valueKey]
          )
          focusedOption = focused or selectedOption
        else
          focusedOption = selected or @state.focusedOption or selectedOption
        result = focusedOption if (focusedOption and focusedOption in options)
        result

      select.forceUpdate()

  optgroupOption: (option) ->
    div className: "#{if option.optgroup then 'Select-optgroup' else 'Select-optchild'} #{cx 'is-disabled': option.optgroup and (!option.clickable or option.disabled)}", key: option.label,
      option.label

  avatarItem: (option) ->
    div className: 'Select-avatar', key: option.avatar,
      span className: 'Select-avatarImage', style: 'backgroundImage': "url(#{option.avatar})"
      div className: 'Select-avatarTitle', option.label

  iconItem: (option) ->
    div className: 'Select-avatar', key: option.avatar,
      span className: "Select-icon Select-icon--#{option.icon}", fa option.icon
      div className: 'Select-iconedTitle',
        span className: 'Select-label', option.label
        span className: 'Select-labelRight', option.labelRight if option.labelRight

  render: ->
    props = clone(@props)
    options = @props.options or []
    props.disabled = @props.disabled or options.length < 1
    props.ref = 'select'
    [first] = options
    if first
      if first.hasOwnProperty('icon')
        props.optionRenderer = @iconItem
        props.valueRenderer = @iconItem
      if first.hasOwnProperty('avatar')
        props.optionRenderer = @avatarItem
        props.valueRenderer = @avatarItem
      if first.hasOwnProperty('optgroup')
        props.optionRenderer = @optgroupOption
    React.createElement ReactSelect, props

module.exports = Select
