"use strict";
const { observer } = require("mobx-react");
const { SingleDatePicker } = require("react-dates");
const Appointment = require("models/appointment");
const slotsStore = require("stores/new-slots");
const {
  editSlotOrSeries,
  deleteSlotOrSeries,
} = require("components/calendar/Schedule/helpers");
const AppointmentsModal = require("components/calendar/modals/appointments-modal");
const { Modal, showModal } = require("components/elements/new-modal");
const PatientCard = require("components/elements/patient-card");
const PatientSearch = require("components/elements/patient-search");
const ApptTypeSelect = require("components/elements/appt-type-select");
const MultipleApptCheckbox = require("components/elements/multiple-appt-checkbox");
const { StartTimeSelect, EndTimeSelect } = require("./time-selects");
const { range } = require("lodash");
const { cx, getNumberValue, onlyDate } = require("lib/utils");

const DAYS_OF_WEEK = range(7).map((day) => onlyDate().day(day));

class SlotModal extends React.Component {
  constructor(props) {
    super(props);

    this._removedSeries = null;
    this.state = {
      showBooking: false,
      showDelete: false,
      repeatType: "date",
      appt: null,
    };
  }

  get showOverlay() {
    return this.state.showBooking || this.state.showDelete;
  }

  get slotFromStore() {
    let { slot } = this.props;
    return slotsStore.findSlot(slot.id);
  }

  closeModal() {
    this.props.abort();
  }

  async saveChanges() {
    let { slot } = this.props;
    await editSlotOrSeries(this.slotFromStore, slot);
    slotsStore.updateSlot(slot);
  }

  async saveChangesAndClose() {
    try {
      await this.saveChanges();
      this.closeModal();
    } catch (err) {
      if (err.name != "AbortError") {
        console.error(err);
        alert("Failed to edit slot or series.");
      }
    }
  }

  async saveChangesAndBook() {
    let { slot } = this.props;
    if (!slot.isBookable) return;

    let { appt } = this.state;
    let { name } = appt.patient;

    try {
      if (slot.isEditable) await this.saveChanges();
      await appt.post();
      this.closeModal();
      alert(`Successfully booked an appointment for ${name}.`);
    } catch (err) {
      if (err.name != "AbortError") {
        console.error(err);
        alert(`Failed to book an appointment for ${name}.`);
      }
    }
  }

  hideDelete() {
    this.setState({ showDelete: false });
  }

  async deleteSlotOrSeries() {
    try {
      await deleteSlotOrSeries(this.slotFromStore);
      this.closeModal();
    } catch (err) {
      this.hideDelete();

      if (err.name != "AbortError") {
        console.error(err);
        alert("Failed to delete slot or series.");
      }
    }
  }

  toggleGroup() {
    let { slot } = this.props;
    if (slot.isGroup) {
      slot.maxPatients = 1;
      slot.maxOverbook = 0;
    } else {
      slot.maxPatients = 2;
    }
  }

  toggleRepeat() {
    let { slot } = this.props;
    if (slot.isRepeated) {
      this._removedSeries = slot.series;
      slot.removeSeries();
    } else if (this._removedSeries) {
      slot.series = this._removedSeries;
    } else {
      slot.createSeries();
    }
  }

  showBooking() {
    let { slot } = this.props;

    this.setState({
      showBooking: true,
      appt: Appointment.forSlot(slot),
    });
  }

  hideBooking() {
    this.setState({
      showBooking: false,
      appt: null,
    });
  }

  toggleBooking() {
    if (this.state.showBooking) {
      this.hideBooking();
    } else {
      this.showBooking();
    }
  }

  toggleDelete() {
    this.setState({
      showDelete: !this.state.showDelete,
    });
  }

  toggleReferralsOnly() {
    let { slot } = this.props;
    if (slot.isReferralsOnly) {
      // box is unchecked
      slot.restrictedTo = "not_restricted";
    } else {
      // box is checked
      slot.restrictedTo = "virtual_visit_referral";
    }
  }

  changeAppointmentType(aT) {
    let { slot } = this.props;
    slot.appointmentType = aT;
    slot.resetMultipleAppointmentTypeIDs();
  }

  async showApptsModal() {
    let { slot } = this.props;
    try {
      await showModal(<AppointmentsModal slot={slot} />);
    } catch (err) {
      if (err.name != "AbortError") throw err;
    }

    if (this.slotFromStore) {
      // Slot could be deleted as a result of the change
      this.slotFromStore.restoreState(slot);
    }
  }

  renderContent() {
    let { slot } = this.props;

    if (this.state.showBooking) return this.renderBooking();
    if (this.state.showDelete || slot.isConflicted) return this.renderDelete();

    const selectedApptType = slot.selectedApptType.isOpen
      ? slot.appointmentType
      : slot.selectedApptType;

    return (
      <div className="SlotModal-content">
        <div className="SlotModal-section SlotModal-section-type">
          <ApptTypeSelect
            value={selectedApptType}
            types={slot.availableApptTypes}
            multipleVisitTypesSelected={slot.multipleVisitTypesSelected}
            onChange={(aT) => this.changeAppointmentType(aT)}
          />
        </div>
        {this.renderTimeSection()}
        {slot.isGroup && this.renderGroup()}
        {slot.isMultiple &&
          !slot.appointmentType.isOpen &&
          this.renderMultipleAppts()}
        {slot.isRepeated && this.renderRepeat()}
      </div>
    );
  }

  renderTimeSection() {
    let { slot } = this.props;

    return (
      <div className="SlotModal-section SlotModal-section-time">
        <StartTimeSelect slot={slot} />
        <span className="SlotModal-time-separator">to</span>
        <EndTimeSelect slot={slot} />
      </div>
    );
  }

  renderMultipleAppts() {
    let { slot } = this.props;

    const filteredApptTypes = slot.filteredByDurationApptTypes;

    return (
      <div className="SlotModal-section SlotModal-section-multiple-appts">
        {slot.allOtherFilteredByDurationApptTypes === 0 ? (
          <p className={"SlotModal-section-multiple-appts-is-empty"}>
            There are no visit types of the same duration to select from
          </p>
        ) : (
          <>
            <MultipleApptCheckbox
              selectedApptType={slot.appointmentType}
              multipleApptsTypes={slot.multipleAppointmentTypeIDs}
              types={filteredApptTypes}
              onChange={(aTID) => slot.setMultipleAppointments(aTID)}
            />
            <p className={"SlotModal-section-multiple-appts-note"}>
              Please ensure that selected visit types are enabled at your clinic
              or they will not be assigned to this slot.
            </p>
          </>
        )}
      </div>
    );
  }

  renderGroup() {
    let { slot } = this.props;

    return (
      <React.Fragment>
        <label className="SlotModal-section SlotModal-section-group">
          <span className="SlotModal-text">Number of patients allowed:</span>
          <input
            type="number"
            className="SlotModal-input"
            value={slot.maxPatients}
            min={2}
            max={30}
            onChange={(evt) => {
              slot.maxPatients = getNumberValue(evt.target);
            }}
          />
        </label>
        <label className="SlotModal-section SlotModal-section-group">
          <span className="SlotModal-text">Maximum overbook:</span>
          <input
            type="number"
            className="SlotModal-input"
            value={slot.maxOverbook}
            min={0}
            max={10}
            onChange={(evt) => {
              slot.maxOverbook = getNumberValue(evt.target);
            }}
          />
        </label>
      </React.Fragment>
    );
  }

  renderBooking() {
    let { appt } = this.state;

    return (
      <div className="SlotModal-content">
        <div className="SlotModal-section SlotModal-section-type">
          <ApptTypeSelect
            value={appt.type}
            types={appt.availableApptTypes}
            onChange={(aT) => (appt.type = aT)}
          />
        </div>
        <div className="SlotModal-section SlotModal-section-reason">
          <input
            className="SlotModal-input"
            placeholder="Reason for visit (optional)"
            value={appt.reason}
            onChange={(evt) => {
              appt.reason = evt.target.value;
            }}
          />
        </div>
        <div className="SlotModal-section SlotModal-section-patient">
          {this.renderPatientSearch()}
        </div>
      </div>
    );
  }

  renderPatientSearch() {
    let { appt } = this.state;
    if (appt.patient) {
      return (
        <React.Fragment>
          <PatientCard patient={appt.patient} modifier="booking" />
          <button
            className="SlotModal-button SlotModal-button-book"
            disabled={!appt.type.isBookable}
            autoFocus
            onClick={() => this.saveChangesAndBook()}
          >
            Book
          </button>
          <button
            className="SlotModal-button SlotModal-button-cancelBooking"
            onClick={() => this.hideBooking()}
          >
            Cancel
          </button>
        </React.Fragment>
      );
    }

    return (
      <PatientSearch
        modifier="calendar"
        onSelect={(patient) => {
          appt.patient = patient;
        }}
      />
    );
  }

  renderRepeat() {
    return (
      <React.Fragment>
        <div className="SlotModal-section SlotModal-section-repeat">
          <div className="SlotModal-repeatTypes">
            {this.renderRepeatType("date", "End date")}
            {this.renderRepeatType("num", "Number of slots")}
          </div>
          {this.renderRepeatEnd()}
        </div>
        <div className="SlotModal-section SlotModal-section-repeatDays">
          {DAYS_OF_WEEK.map((date) => this.renderRepeatDay(date))}
        </div>
      </React.Fragment>
    );
  }

  renderRepeatType(repeatType, text) {
    return (
      <label className="SlotModal-repeatType">
        <input
          className="SlotModal-repeatType-radio"
          type="radio"
          name="SLOT_MODAL_REPEAT_TYPE"
          checked={this.state.repeatType == repeatType}
          onChange={() => this.setState({ repeatType })}
        />
        {text}
      </label>
    );
  }

  renderRepeatEnd() {
    let { series } = this.props.slot;

    switch (this.state.repeatType) {
      case "date":
        return (
          <SingleDatePicker
            date={series.endDay}
            focused={this.state.focused}
            onDateChange={(date) => {
              if (date) {
                series.endDay = date;
              }
            }}
            onFocusChange={({ focused }) => {
              this.setState({ focused });
            }}
          />
        );
      case "num":
        return (
          <input
            className="SlotModal-input"
            type="number"
            value={series.slotsNumber}
            min={series.minSlotsNumber}
            max={series.maxSlotsNumber}
            onChange={(evt) => {
              series.slotsNumber = getNumberValue(evt.target);
            }}
          />
        );
    }
  }

  renderRepeatDay(date) {
    let { series } = this.props.slot;
    let day = date.day();

    return (
      <label
        key={day}
        className="SlotModal-repeatDay"
        title={date.format("dddd")}
      >
        {date.format("dd")}
        <input
          className="SlotModal-repeatDay-checkbox"
          type="checkbox"
          checked={series.isActiveDay(day)}
          disabled={series.isDisabledDay(day)}
          onChange={() => series.toggleRepeatDay(day)}
        />
      </label>
    );
  }

  renderDeleteMessage() {
    let { slot } = this.props;
    if (slot.isConflicted) {
      let target = slot.conflictedAppts.length == 1 ? "patient" : "group";
      return `Slot is canceled and can be deleted if ${target} is notified.`;
    }

    return "Would you like to delete this slot?";
  }

  renderDeleteButtons() {
    let { slot } = this.props;
    if (slot.isConflicted) return;

    return (
      <React.Fragment>
        <button
          className="SlotModal-button SlotModal-button-keep"
          onClick={() => this.hideDelete()}
        >
          Keep
        </button>
        <button
          className="SlotModal-button SlotModal-button-delete"
          onClick={() => this.deleteSlotOrSeries()}
        >
          Delete
        </button>
      </React.Fragment>
    );
  }

  renderDelete() {
    return (
      <div className="SlotModal-section SlotModal-section-delete">
        <span className="SlotModal-text">{this.renderDeleteMessage()}</span>
        {this.renderDeleteButtons()}
      </div>
    );
  }

  renderReferralsOnly() {
    let { slot } = this.props;
    if (slot.restrictedTo === null) {
      // set the restrictedTo prop value to avoid null
      slot.restrictedTo = "not_restricted";
    }

    return (
      <div className="SlotModal-referrals">
        <input
          className="SlotModal-checkbox"
          type="checkbox"
          checked={slot.isReferralsOnly}
          onChange={(e) => this.toggleReferralsOnly()}
        />
        <span>Restrict to XOP referrals only (hide for Self Schedule)</span>
      </div>
    );
  }

  renderActions() {
    return (
      <div className="SlotModal-actions">
        {this.renderMultipleApptsAction()}
        {this.renderGroupAction()}
        {this.renderVisibilityAction()}
        {this.renderRepeatAction()}
        {this.renderApptsAction()}
        {this.renderBookingAction()}
        {this.renderDeleteAction()}
      </div>
    );
  }

  getMultipleApptsActionTitle() {
    let { slot } = this.props;

    if (!slot.selectedApptType.isOpen && slot.appointmentType.isOpen) {
      return "Please ensure the selected visit type is enabled at your clinic to enable multiple appointments";
    }

    if (slot.appointmentType.isOpen) {
      return "Please select a visit type to use this function";
    }

    if (slot.isGroup && !slot.appointmentType.isOpen) {
      return "Selecting multiple visit types is disabled when group setting is enabled";
    }

    if (!slot.appointmentType.isOpen) {
      let tooltip = slot.isMultiple
        ? "Multiple visit types selected"
        : "Assign multiple visit types";
      return tooltip;
    }
  }

  renderMultipleApptsAction() {
    let { slot } = this.props;
    let apptSelected = slot.isMultiple ? "multiple" : "single";
    let apptClass = cx("SlotModal-action-appointment", apptSelected);

    return (
      <button
        className={`SlotModal-action ${apptClass}`}
        disabled={
          slot.appointmentType.isOpen ||
          slot.isGroup ||
          !slot.isEditable ||
          this.showOverlay
        }
        aria-pressed={slot.isMultiple}
        title={this.getMultipleApptsActionTitle()}
        onClick={() => slot.changeApptTypesBooked()}
      />
    );
  }

  renderGroupAction() {
    let { slot } = this.props;
    let modifier = slot.isGroup ? "group" : "single";
    let groupClass = cx("SlotModal-action-patients", modifier);

    return (
      <button
        className={`SlotModal-action ${groupClass}`}
        disabled={slot.isMultiple || !slot.isEditable || this.showOverlay}
        aria-pressed={slot.isGroup}
        title={
          slot.isMultiple
            ? "Group settings are disabled when multiple visit types are selected"
            : "Number of patients"
        }
        onClick={() => this.toggleGroup()}
      />
    );
  }

  renderVisibilityAction() {
    let { slot } = this.props;
    let visibilityClass = cx("SlotModal-action-visibility", slot.visibility);

    return (
      <button
        className={`SlotModal-action ${visibilityClass}`}
        disabled={!slot.isEditable || this.showOverlay}
        title={slot.displayVisibility}
        onClick={() => slot.changeVisibility()}
      />
    );
  }

  renderRepeatAction() {
    let { slot } = this.props;
    let repeatClass = cx("SlotModal-action-repeat", {
      enabled: slot.isRepeated,
    });

    return (
      <button
        className={`SlotModal-action ${repeatClass}`}
        disabled={!slot.isEditable || this.showOverlay}
        aria-pressed={slot.isRepeated}
        title="Repeat options"
        onClick={() => this.toggleRepeat()}
      />
    );
  }

  renderApptsAction() {
    let { slot } = this.props;
    let { length } = slot.appointments;
    if (!length) return;

    return (
      <button
        className="SlotModal-action SlotModal-action-appts"
        disabled={this.showOverlay}
        title={`${length} booked appointment(s)`}
        onClick={() => this.showApptsModal()}
      />
    );
  }

  renderBookingAction() {
    let { slot } = this.props;
    if (slot.appointments.length >= slot.maxCapacity) return;

    let actionClass = cx("SlotModal-action", { modal: this.state.showBooking });

    return (
      <button
        className={`${actionClass} SlotModal-action-booking`}
        disabled={!slot.isBookable || this.state.showDelete}
        aria-pressed={this.state.showBooking}
        title="Book appointment"
        onClick={() => this.toggleBooking()}
      />
    );
  }

  renderDeleteAction() {
    let { slot } = this.props;
    let actionClass = cx("SlotModal-action", { modal: this.state.showDelete });

    return (
      <button
        className={`${actionClass} SlotModal-action-delete`}
        disabled={!slot.isEditable || this.state.showBooking}
        aria-pressed={this.state.showDelete}
        title="Delete slot"
        onClick={() => this.toggleDelete()}
      />
    );
  }

  renderFooter() {
    let { slot } = this.props;

    return (
      <footer className="SlotModal-footer">
        <button
          className="SlotModal-button SlotModal-button-cancel"
          disabled={this.showOverlay}
          onClick={() => this.closeModal()}
        >
          Cancel
        </button>
        <button
          className="SlotModal-button SlotModal-button-save"
          disabled={!slot.isEditable || this.showOverlay}
          onClick={() => this.saveChangesAndClose()}
        >
          Save
        </button>
      </footer>
    );
  }

  render() {
    let { abort, slot } = this.props;
    let wrapperClass = cx("SlotModal-wrapper", { overlay: this.showOverlay });
    let modalScrollClass =
      slot.isMultiple && slot.allOtherFilteredByDurationApptTypes > 4
        ? "SlotModal-can-scroll"
        : "";

    return (
      <Modal
        className={`SlotModal ${modalScrollClass}`}
        wrapperClassName="NewModal-wrapper-can-scroll"
        title="Slot Preferences"
        close={abort}
      >
        {this.renderContent()}
        <div className={wrapperClass}>
          {this.renderActions()}
          {this.renderReferralsOnly()}
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

module.exports = observer(SlotModal);
