{appStore} = require 'stores'
{div, tch, fa, span} = Exim.DOM
{cx}           = Exim.helpers
{touchDevice}  = require 'config'

ActionDropdown = Exim.createView module.id,
  propTypes:
    values: React.PropTypes.array
    selected: React.PropTypes.string
    needsTwoTabs: React.PropTypes.bool
    isMessages: React.PropTypes.bool

  getInitialState: ->
    dropdown: false

  globalClick: ->
    if @state.dropdown
      @setState {dropdown: null}
      appStore.actions.removeGlobalClick @globalClick

  getSelected: ->
    if @props.selected
      @props.values.find((v) => v.id is @props.selected)
    else if !@props.placeholder
      @props.values[0]

  toggleDropdown: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()
    dropdown = !@state.dropdown
    @setState {dropdown}
    if dropdown
      setTimeout(=> @refs.menu?.querySelector('button')?.focus())
      appStore.actions.addGlobalClick @globalClick if @props.withGlobalClick
    else
      @refs.angle?.focus()
      appStore.actions.removeGlobalClick @globalClick if @props.withGlobalClick

  optionKeyDown: (evt) ->
    if evt.which is 27
      @toggleDropdown(evt)
    else if evt.which is 38 or (evt.shiftKey and evt.which is 9)
      evt.preventDefault()
      evt.stopPropagation()
      prevNode = evt.target.previousElementSibling or evt.target.parentElement.lastChild
      prevNode.focus()
    else if evt.which is 40 or evt.which is 9
      evt.preventDefault()
      evt.stopPropagation()
      nextNode = evt.target.nextElementSibling or evt.target.parentElement.firstChild
      nextNode.focus()

  dropdownKeyDown: (evt) ->
    if evt.which is 38
      evt.preventDefault()
      evt.stopPropagation()
      prevNode = evt.target.parentElement.lastChild.lastChild
      prevNode.focus()
    else if evt.which is 40
      evt.preventDefault()
      evt.stopPropagation()
      nextNode = evt.target.parentElement.lastChild.firstChild
      nextNode.focus()

  handleSelected: (handler) -> (evt) =>
    return if @props.disableSelected
    @_handle(handler)(evt)

  _handle: (handler) -> (evt) =>
    evt.preventDefault()
    evt.stopPropagation()
    @setState dropdown: false
    fn = -> handler(evt)
    if touchDevice then setTimeout(fn, 50) else fn()

  handle: (handler) -> (evt) =>
    @_handle(handler)(evt)
    @refs.angle?.focus()

  handleWrapperClick: (evt) ->
    evt.stopPropagation()

  handleBlur: (filteredValues, index) -> (evt) =>
    # a11y bug fix for keyboard users: toggle dropdown off
    # after the final option in the dropdown list loses focus (onBlur)
    if (index == filteredValues.length - 1)
      dropdown = !@state.dropdown
      @setState {dropdown}

  ariaLabelForDropdown: (value, isMessages, selected) ->
    if !isMessages then return "#{value.description or value.name}" else if value.id is selected.id then return "#{value.description or value.name} is already selected, press enter to deselect" else return "#{value.description or value.name}, press enter to select"

  render: ->
    selected = @getSelected()
    {className, fixed, placeholder, values, select} = @props
    {dropdown} = @state
    if !selected
      selected = {}
      if !fixed and placeholder
        fixed = placeholder
    single = values.length <= 1

    # if there are no dropdown options first button rendered should have a tab stop
    # if the action dropdown does have options, we want the activating dropdown element
    # (id: 'action-dropdown-show-additional-options') to have a tab stop ,
    # but we do not want an addtional tab stops.
    # added in a check to see if it is the check in button, since we do want an additional tab stop there
    hasDropdownOptions = !single
    needsTwoTabs = @props.needsTwoTabs
    dynamicTabIndex = if hasDropdownOptions and !needsTwoTabs then -1 else 0
    isMessages = @props.isMessages


    isNotSelected = (value) ->
      fixed or select or value isnt selected

    cls = "ActionDropdown #{cx full: fixed and typeof fixed is 'string', 'with-dropdown': dropdown, 'is-singleOption': single} #{className}"

    style = if selected.color then {color: selected.color} else {}

    div className: cls, onClick: @handleWrapperClick, onKeyUp: @handleWrapperClick, title: @props.tooltip,
      if fixed and typeof fixed is 'string'
        tch tagName: 'button', tabIndex: dynamicTabIndex, className: "ActionDropdown-title #{cx full: fixed}", overflow: ['top', 'bottom', 'left'], handler: @toggleDropdown, fixed
      else if fixed
        tch tagName: 'button', tabIndex: dynamicTabIndex, 'aria-label': fixed.name, className: 'ActionDropdown-title', overflow: ['top', 'bottom', 'left'], handler: @toggleDropdown,
          div className: 'ActionDropdown-title-name',
            fa fixed.icon if fixed.icon
            span fixed.name
      else
        tch tagName: 'button', tabIndex: dynamicTabIndex, 'aria-label': "#{if selected.disabled then 'Option disabled'} #{selected.description or selected.name}" , className: "ActionDropdown-title #{cx 'is-disabled': selected.disabled}", handler: @handleSelected(selected.handler), overflow: ['top', 'bottom', 'left'], onKeyDown: @dropdownKeyDown,
          div style: style, className: 'ActionDropdown-title-name',
            fa selected.icon if selected.icon
            span selected.name
      if hasDropdownOptions
        [
          tch tagName: 'button', ref: 'angle', 'aria-label': "#{if selected.name or @props.placeholder.name then "#{selected.name or @props.placeholder.name} selected, show additional options" else "Show additional options"}", key: 'angle', className: 'ActionDropdown-angle', id: "action-dropdown-show-additional-options-#{@props.elementId}", handler: @toggleDropdown, overflow: ['top', 'bottom', 'right'], 'aria-haspopup': true, 'aria-expanded': dropdown, 'aria-controls': "action-drop-down-menu-#{@props.elementId}",
            fa 'caret-down'

          div role: 'menu', ref: 'menu', id: "action-drop-down-menu-#{@props.elementId}", key: 'menu', className: 'ActionDropdown-menu', 'aria-hidden': !dropdown, onKeyDown: @optionKeyDown,
            values.filter(isNotSelected).map (value, index) =>
              tch role: 'menuitem', tagName: 'button', 'aria-label': @ariaLabelForDropdown(value, isMessages, selected), key: value.name, title: value.name, className: "ActionDropdown-menuItem #{cx 'ActionDropdown-menuItemIcon': value.icon} #{cx 'is-disabled': value.disabled} no-select", onBlur: @handleBlur(values.filter(isNotSelected), index), handler: @handle(value.handler),
                fa value.icon if value.icon
                span value.name
        ]


module.exports = ActionDropdown
