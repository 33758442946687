"use strict";
const withEscapeHandler = require("./with-escape-handler");

class Modal extends React.Component {
  constructor() {
    super();
    this.closeModalButtonRef = React.createRef();
  }

  componentDidMount() {
    if (this.closeModalButtonRef) {
      this.closeModalButtonRef.current.focus();
    }
  }

  componentWillUnmount() {
    if (!this.props.activatorId) {
      return;
    }
    const { activatorId } = this.props;
    const { elementId } = this.props;
    const id = `${activatorId}-${elementId}`;
    const activatorElement = document.getElementById(id);
    setTimeout(() => {
      if (activatorElement) {
        activatorElement.focus();
      }
    }, 0);
  }

  renderHeader() {
    return (
      <div className="NewModal-header">
        <button
          ref={this.closeModalButtonRef}
          className="NewModal-close"
          aria-label={`Close ${this.props.title} dialog`}
          onClick={this.props.close}
        />
        <h2 className={"NewModal-title"}>{this.props.title}</h2>
      </div>
    );
  }

  render() {
    const { className = "", wrapperClassName = "" } = this.props;

    return (
      <div className={`NewModal-wrapper ${wrapperClassName}`}>
        <div
          className={`NewModal-overlay ${wrapperClassName}`}
          onClick={this.props.close}
        />
        <div className={`NewModal ${className}`} role="dialog">
          {this.renderHeader()}
          {this.props.children}
        </div>
      </div>
    );
  }
}

module.exports = withEscapeHandler(Modal);
