{div} = Exim.DOM

IsEmpty = Exim.createView module.id,
  displayName: 'IsEmpty'

  propTypes:
    state: React.PropTypes.bool

  getInitialState: ->
    loaded: false

  getDefaultProps: ->
    ifEmpty: 'No items.'

  componentWillUpdate: (nextProps, nextState) ->
    if !nextProps.loading and (@props.loading or !@props.loading?)
      nextState.loaded = true

  render: ->
    {className, role, ifEmpty, children, onClick, loading} = @props
    {loaded} = @state

    div {className, role, onClick},
      if React.Children.count(children) is 0 and loaded and !loading
        div className: 'empty-outer',
          ifEmpty
      else
        children

module.exports = IsEmpty
