{authStore, appStore} = require 'stores'

config   = require 'config'
utils    = require 'lib/utils'
Validate = require 'components/mixins/Validate'
Tooltip  = require 'components/elements/Tooltip'
Disclaimer = require 'components/auth/Disclaimer'
AccountInfo = require './AccountInfo'
Portal   = require './account/Portal'
IdentityProvider = require './account/IdentityProvider'

{Link} = Exim.Router
{cx}   = Exim.helpers
{div, fa, spinner, input, span, button, form, tch, select, option, hr, h4, img, ul, li, h2} = Exim.DOM

Creds = Exim.createView module.id,
  mixins: [Validate]

  listen: [
    'auth/error', 'auth/lookingUp', 'auth/oAuthProviderLinks'
    'app/config'
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    tooltip: {}
    flipped: false

  componentDidMount: ->
    @forceUpdate()

    # need to wait 2 seconds before setting focus to allow screen reader to finish reading 'X step loaded' annoucement for a11y purposes
    setTimeout(() ->
      button = document.getElementById('activation-type-button')
      button?.focus()
    , 2000)

  showWhichActivationTypeModal: (evt) ->
    evt.preventDefault()
    body = require './WhichActivationTypeModal'
    modal =
      className: 'Modal--small Modal--scrollable'
      noClose: true
      title: 'Which activation type is for me?'
      body: body()
    appStore.actions.showModal(modal)

  goToSignin: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    @context.router.push pathname: '/signin'

  accountNext: (state) ->
    document.querySelector('.hidden').innerHTML = "Contact info step loading"
    @props.next(state)

  render: ->
    config = appStore.get('config')
    showOidc = @state.oAuthProviderLinks?.length > 0
    link = null

    if showOidc
        link = tch tagName: 'a', href: '#', role: 'button', className: 'Button', id: "activation-type-button", style: { width: '80%', marginTop: '8px' }, handler: @showWhichActivationTypeModal, 'Which Activation type is right for me?'

    div className: 'SignUpAccount',
      div className: 'SignUpAccount--intro',
        span 'Activate your account to access an all new healthcare experience!'
        link

      div className: 'SignUpAccount--panes',
        if showOidc
          div className: 'SignUpAccount--pane',
            IdentityProvider {}
        div className: 'SignUpAccount--pane',
          Portal { next: @accountNext, showOidc }

      div className: 'SignUpAccount--sign-in',
        span className: 'AuthCard--link-text', "Already have an account? "
        tch tagName: 'a', href: '#', className: 'Button--pointer Auth--informational-link underline', tabIndex: 0, handler: @goToSignin, 'Sign In'
#
#          if (security = config?.security) and Object.keys(security).length
#            Disclaimer()

module.exports = Creds
