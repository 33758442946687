{appStore} = require 'stores'

{div, p, strong, tch} = Exim.DOM

StandardNotification = Exim.createView module.id,
  staticView: true
  noHeader: true
  fadeIn: true

  contextTypes:
    router: React.PropTypes.object.isRequired

  goToPortal: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    @context.router.push pathname: '/'

  render: ->
    div null,
      p null,
        strong "Hi there!"
        
      p null,
        strong "You have access to our new virtual care service."

      p null,
        strong "Please login below where you can immediately access these new services from our Crossover providers:"

      p null,
        tch tagName: 'a', href: 'https://care.crossoverhealth.com', "https://care.crossoverhealth.com"

      p null,
        strong "Thank you!"

      p null,
        strong "Note: If you need access to prior information from the original Patient Portal, click "
        tch tagName: 'a', href: '#', handler: @goToPortal, "continue."

module.exports = StandardNotification
