{ observer } = require('mobx-react')
{ showModal, closeModal } = require('components/elements/new-modal')
VideoVisitPopUp = require('./video-visit-pop-up')

{ i, div, button } = Exim.DOM

VideoVisitButton = React.createClass({
  displayName: 'VideoVisitButton'

  getInitialState: ->
    keyBoardPress: false

  onKeyBoardPress: ->
    @setState keyBoardPress: true

  onMousePress: ->
    @setState keyBoardPress: false

  handleOpenPopUp: (evt) ->
    evt.stopPropagation()

    { video_conference } = @props
    showModal(React.createElement(VideoVisitPopUp, {
      rect: evt.target.getBoundingClientRect(),
      keyBoardPress: @state.keyBoardPress,
      video_conference,
      closeModal
    }))

  render: ->
    return div className: 'VideoVisitButton',
      button className: 'VideoVisitButton-icon', 'aria-label': 'video visit icon', onClick: this.handleOpenPopUp, onKeyPress: @onKeyBoardPress, onMouseDown: @onMousePress,
        i className: 'fa fa-video-camera'
})

module.exports = observer(VideoVisitButton)
