{messagesStore, authStore} = require 'stores'
{div, span, fa, input, button} = Exim.DOM
{cx} = Exim.helpers
{newApi} = require('config').features

maxPages = 4
module.exports =
  mixins: [messagesStore.connect('received', 'sent', 'archived', 'messagesFetching', 'receivedMessagesFetched', 'sentMessagesFetched', 'archivedMessagesFetched', 'messageUpdating', 'filter'), authStore.connect('user')]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    page: +(@props.location?.query?.page or 1)
    pageValue: +(@props.location?.query?.page or 1)

  componentWillMount: ->
    {page} = @state

    unless @state[@type]?
      state = {}
      state[@type] = response: []
      @setState state

    messagesStore.actions.fetchMessages(@type, page).then =>
      @props?.onLoad()

  componentWillUnmount: ->
    @props?.onUnload()

  isFetching: ->
    messagesStore.get('messagesFetching')

  componentWillUpdate: (nextProps, nextState) ->
    newPage = @pagesCount(nextState) if nextState.page > @pagesCount(nextState)
    newPage = 1 if nextState.page < 1

    if newPage
      nextState.page = newPage
      nextState.pageValue = newPage

    if @state.user.id isnt nextState.user.id
      messagesStore.actions.fetchMessages(@type, @state.page)
    if @props.sort isnt nextProps.sort
      messagesStore.actions.fetchMessages(@type, @state.page, false, nextProps.sort, @state.filter)
    if @state.filter.length isnt nextState.filter.length
      nextState.page = 1
      nextState.pageValue = 1
      messagesStore.actions.fetchMessages(@type, 1, false, @props.sort, nextState.filter)
    else if @state.page isnt nextState.page
      if newApi
        messagesStore.actions.searchMessages(@type, @props.matcher, @state.page)
      else
        messagesStore.actions.fetchMessages(@type, nextState.page, false, @props.sort, @state.filter)

  componentDidUpdate: (prevProps, prevState) ->
    if newApi and prevProps.matcher isnt @props.matcher
      messagesStore.actions.searchMessages(@type, @props.matcher, 1)

  onSetPage: (evt) ->
    value = evt.currentTarget?.value
    if /^\d*$/.test value
      @setState pageValue: value

  onKeyUp: (evt) ->
    if evt.which is 13
      @_setPage(@state.pageValue)

  _setPage: (n) ->
    @setState page: n, pageValue: n if 1 <= n <= @pagesCount()

  setPage: (n) -> (evt) =>

    @_setPage(n)

  applyFilter: (filter) ->
    prevFilter = @state.filter.slice()

    if filter is false
      messagesStore.actions.clearFilter()
      return

    if filter in prevFilter
      prevFilter.splice(prevFilter.indexOf(filter), 1)
    else
      prevFilter.push filter

    messagesStore.actions.setFilter(prevFilter)


  nextPage: (evt) ->
    val = @state.page + 1
    @setState page: val, pageValue: val unless @state.page >= @pagesCount()

  prevPage: (evt) ->
    val = @state.page - 1
    @setState page: val, pageValue: val unless @state.page <= 1

  pagesCount: (state=@state) ->
    messagesStore.get(@type).pages

  ifEnter: (f) -> (evt) =>
    return unless evt.which is 13

    f()

  pageControls: ->
    if @pagesCount() > 1
      {page, pageValue} = @state
      count = @pagesCount()

      prevDisabled = page is 1
      prevTabIndex = (if prevDisabled then -1 else 0)

      nextDisabled = page is count
      nextTabIndex = (if nextDisabled then -1 else 0)

      div className: 'PageControls',
        div className: 'PageControls-buttonsGroup',
          button className: "PageControls-button PageControls-button-first #{cx 'is-disabled': prevDisabled}", 'aria-disabled': "#{if prevDisabled then true else false}", 'aria-label': "first page #{cx 'disabled': prevDisabled}", onClick: @setPage(1), onKeyUp: @ifEnter(@setPage(1)), tabIndex: prevTabIndex, fa 'step-backward'
          button className: "PageControls-button PageControls-button-prev #{cx 'is-disabled': prevDisabled}", 'aria-disabled': "#{if prevDisabled then true else false}", 'aria-label': "previous page #{cx 'disabled': prevDisabled}", onClick: @prevPage, onKeyUp: @ifEnter(@prevPage), tabIndex: prevTabIndex, fa 'caret-left'

        div className: 'PageControls-group',
          div 'Page'
          input className: 'PageControls-currentPage', value: pageValue, onChange: @onSetPage, onKeyUp: @onKeyUp, 'aria-label': 'Change messages page'
          div "of #{count}"

        div className: 'PageControls-buttonsGroup',
          button className: "PageControls-button PageControls-button-next #{cx 'is-disabled': nextDisabled}", 'aria-disabled': "#{if nextDisabled then true else false}", 'aria-label': "next page #{cx 'disabled': nextDisabled}", onClick: @nextPage, onKeyUp: @ifEnter(@nextPage), tabIndex: nextTabIndex, fa 'caret-right'
          button className: "PageControls-button PageControls-button-last #{cx 'is-disabled': nextDisabled}", 'aria-disabled': "#{if nextDisabled then true else false}", 'aria-label': "last page #{cx 'disabled': nextDisabled}", onClick: @setPage(count), onKeyUp: @ifEnter(@setPage(count)), tabIndex: nextTabIndex, fa 'step-forward'

  getMessages: ->
    targ = messagesStore.get(@type)
    if newApi
      targ?.response
    else
      targ?.response.filter(@props.filterer)
