{div, h1, h2, a, p, ul, li, strong} = Exim.DOM

MissedApptModal = Exim.createView module.id,
  render: ->
    div className: 'u-scroll',
      p 'Wellness Center visits are pre-paid by Apple. If you miss your appointment Apple pays the full cost of that appointment when your time slot is not filled. Our goal is to reduce no show appointments which will increase appointment availability for employees and reduce the overall operating expense for Apple.'
      p 'Please cancel or reschedule appointments at least 24 hours in advance, so we can give your slot to one of your collegues.'
      p 'If you do not show up for your appointment or if you cancel within 2 hours of the appointment this is considered a no show.'
      p 'This star rating system is to inform you of the number of no shows that you\'ve had within a rolling 12 month period (each no show = 1 star removed).'

module.exports = MissedApptModal
