{medicationsStore} = require 'stores'
newAuthStore  = require 'stores/new-auth'
{capitalize} = require 'lib/utils'
{cx} = Exim.helpers
{client} = require 'config'
{tch, div, span, textarea, fa, strong, h3, tr, td} = Exim.DOM

Item = Exim.createView module.id,
  displayName: 'item'

  getInitialState: ->
    note: ''

  focus: ->
    setTimeout(=> @refs.textarea?.focus())

  onSelect: (evt) ->
    evt.preventDefault()
    @props.onSelect(@props.data.id)

  toggleExpanded: ->
    @props.onSelect()

  setComments: (evt) ->
    note = evt.currentTarget.value
    @setState {note}

  request: (evt) ->
    evt.preventDefault()
    {data} = @props
    data.note = @state.note
    medicationsStore.actions.refill(@props.data).then =>
      @toggleExpanded()

  getUnit: ->
    {dose, unit} = @props.data
    if unit
      capitalize if +dose is 1 then unit.slice(0,-1) else unit
    else ''


  render: ->
    { shouldSeeFullAccessPortal, isMicrosoftPatient } = newAuthStore
    clickable = @props.data.status is 'Refillable'
    { selected, data } = @props
    { medication, dose, dose_instructions, qty, unit, order_date, frequency } = data
    clickHandler = if clickable then @onSelect

    qty = 'Not specified' unless qty

    tableBodyColumnClass = if order_date then 'TableBody-detailed-col' else 'TableBody-column'

    groupClass = cx
      'AccordionGroup--expanded': selected
      'AccordionGroup--is-frozen': !clickable

    tr role: 'row', className: "TableBody-row MedsRow AccordionGroup AccordionGroup--noBorder #{groupClass}",
      div className: 'AccordionGroup-target',
        td role: 'cell', className: "#{tableBodyColumnClass} u-1of5 u-noPaddingLeft",
          span null, medication
        td role: 'cell', className: "#{tableBodyColumnClass} u-1of5 u-noPaddingLeft",
          span null, "#{dose_instructions}"
        td role: 'cell', className: "#{tableBodyColumnClass} u-1of5 u-textLeft",
          span null, qty or 'Not specified' #.toLowerCase()
        if order_date
          td role: 'cell', className: "#{tableBodyColumnClass} u-1of5 u-textLeft",
            span null, order_date or 'Not specified' #.toLowerCase()
        span className: "#{tableBodyColumnClass} u-1of4 u-noselect u-noPaddingRight c-dropdown",
        if shouldSeeFullAccessPortal
          if clickable
            if selected
              btnClass = 'Button--cancel'
              tch tagName: 'button', className: "Button Button--small Button--secondaryAction #{btnClass}", handler: clickHandler, 'aria-expanded': selected, 'aria-controls': data.id, 'aria-label': "Cancel",
                span 'Cancel'
            if !isMicrosoftPatient
              btnClass = 'Button--primary'
              tch tagName: 'button', className: "Button Button--small Button--secondaryAction #{btnClass}", handler: clickHandler, 'aria-expanded': selected, 'aria-controls': data.id, 'aria-label': "Renew #{medication}",
                span 'Renew'
          else
            div className: "Button Button--small Button--secondaryAction Button--disabled #{btnClass}", disabled: true, 'aria-disabled': true, role: 'button',
              span 'Request Refill'


      if clickable and shouldSeeFullAccessPortal
        div
          'aria-hidden': !selected,
          'aria-label': "#{medication} Renew Form",
          className: 'AccordionGroup-content Medication',
          id: data.id,
          role: 'form',
          h3 className: 'Medication-primaryText',
            'Message the Care Team'
          div className: 'Medication-wrapper', 'aria-label': 'Request Renewal form',
            div className: 'Medication-note',
              div className: 'Medication-noteLeft',
                fa 'exclamation-circle'
              div className: 'Medication-noteRight',
                strong 'NOTE: '
                'Check with your pharmacy to see if you have refills available. Use this form to request a prescription renewal from your physician'

            textarea 'aria-label': 'Insert message', className: 'Input', onChange: @setComments, value: @state.note, ref: 'textarea', tabIndex: (if selected then 0 else -1),
          tch tagName: 'button', className: 'Button Button--primary Button--pointer Button--expandOnSmall u-right', handler: @request, tabIndex: (if selected then 0 else -1),
            span 'Request Renewal'

module.exports = Item
