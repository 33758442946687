{appStore, documentsStore, authStore} = require 'stores'

Document   = require './Document'
DocumentPreview   = require './DocumentPreview'
DocumentVerification   = require './DocumentVerification'
IsEmpty    = require 'components/elements/IsEmpty'
GlobalClick = require 'components/mixins/GlobalClick'
Zoom        = require 'components/elements/Zoom'
ConfirmationModal= require 'components/elements/ConfirmationModal'
sort       = require 'components/mixins/sort'
utils      = require 'lib/utils'
{client} = require 'config'

{cx} = Exim.helpers
{div, h3, h1, span, input, img, label, fa, icn, spinner, i, a, video, source, strong, tch, iframe, object, table, th, tr} = Exim.DOM

NAV_WIDTH = 100
DARK_GREY = '#737373'

DocumentsList = Exim.createView module.id,
  mixins: [
    documentsStore.connect('documents', 'documentsLoading')
    authStore.connect('user')
    sort(type: 'created_at', asc: false, dates: ['created_at'])
    GlobalClick
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    editingId = @props.location.query?.editing
    fileName: ''
    selected: []
    checked: false
    editing: +editingId

  globalClick: ->
    @unSelect()

  componentWillUpdate: (nextProps, nextState) ->
    nextQuery = nextProps.location.query
    if nextQuery
      nextState.editing = +nextProps.location.query.editing
    else
      nextState.editing = null

    if (singleId = nextProps.params.id) && ((!@props.params.id) || (@props.params?.id != nextProps.params?.id))
      Promise.resolve().then =>
        targ = @state.documents.find (doc) -> +doc.id is +singleId
        @open(targ) if targ

  componentWillMount: ->
    promise = Promise.resolve()
    if !documentsStore.get('documents').length
      promise = promise.then => @props.fetch()
    if singleId = @props.params.id
      promise.then =>
        targ = @state.documents.find (doc) -> +doc.id is +singleId
        @open(targ) if targ
    else if appStore.get('modal')
      appStore.actions.showModal(null)

  componentWillUnmount: ->
    window.removeEventListener('keyup', @lastHandler) if @lastHandler

  viewInMessages: (doc) -> =>
    @props.router.push pathname: "/messages/#{doc.message_type}/#{doc.message_id}"

  rename: (doc, title) ->
    {id} = doc
    return if doc.title is title
    documentsStore.actions.saveTitle({id, title})
    setTimeout @unEdit, 0

  download: (doc) -> (evt) =>
    @prevent(evt)
    if utils.humanizeMime(doc.mime) is 'Image'
      utils.saveImage doc.url, doc.title
    else
      utils.openLink utils.getUrl(doc.url.slice(1)), doc.name or doc.title

  verify: (doc) -> (evt) =>
    @prevent(evt)
    {id} = doc
    doc.unverified = false
    documentsStore.actions.verify({id})
    @open(doc)

  edit: (doc) ->
    @setState editing: doc.id

  _edit: (doc) -> (evt) =>
    @prevent(evt)
    @context.router.push pathname: '/documents', query: editing: doc.id

  closeModal: ->
    appStore.actions.showModal(null)
    @toDocs()

  delete: (doc) -> (evt) =>
    @prevent(evt)

    title = 'Delete document'
    text = 'Are you sure you want to delete this document? This action cannot be undone.'
    confirmText = 'Delete'
    onConfirm = @_delete(doc)
    reOpen = => @open(doc)
    onCancel = if appStore.get('modal') then reOpen else @closeModal

    body = ConfirmationModal {title, text, confirmText, onCancel, onConfirm}

    modal =
      className: 'Modal--small'
      contentLabel: 'Delete Document'
      darker: true
      body: body

    appStore.actions.showModal(modal)

  _delete: (doc) -> =>
    documentsStore.actions.remove(doc).then =>
      @closeModal()

  erase: (doc) -> ->
    documentsStore.actions.erase(doc)

  startSwipe: (evt) ->
    @width = evt.currentTarget.clientWidth
    @startPos = evt.touches[0].pageX
    @startTime = new Date
    @setState swiping: true

  processSwipe: (evt) ->
    @moved = true
    pos = evt.touches[0].pageX
    return if Math.abs(pos - @startPos) < NAV_WIDTH
    @endPos = pos
    @diff = @endPos - @startPos
    @setState swipe: -@width + @endPos

  endSwipe: (doc) -> (evt) =>
    @setState swiping: false
    duration = new Date - @startTime
    if @moved and duration < 500 and (@diff > 150 or @diff < -150)
      if @diff < 0
        @open(doc.next)
      else
        @open(doc.prev)

    @diff = 0
    @moved = false
    @setState swipe: null

  onKeyUp: (doc) -> (evt) =>
    { which } = evt
    if which in [37, 38]
      @open(doc.prev) if doc.prev
    else if which in [39, 40]
      @open(doc.next) if doc.next

  toDocs: ->
    @context.router.push '/documents'

  navTo: (doc) -> =>
    return unless doc
    @context.router.push "/documents/#{doc.id}"
    document.getElementById("close-modal-pseudo-button").focus()

  handleKeyUp: (doc) ->
    window.removeEventListener('keyup', @lastHandler) if @lastHandler
    @lastHandler = @onKeyUp(doc)
    window.addEventListener('keyup', @lastHandler)

  disabledPrevAndNextStyle: (direction) ->
    style = null
    if (!direction and (client == 'microsoft' || client == 'docker'))
      style = {backgroundColor: DARK_GREY, borderColor: DARK_GREY, color: '#FFF', opacity: '1'}
    style

  open: (doc) ->
    @handleKeyUp(doc)
    url = utils.getUrl(doc.url.slice(1))
    mime = utils.humanizeMime(doc.mime).toLowerCase()
    {admin, proxying} = @state.user

    if mime in ['image', 'video', 'pdf', 'document']
      @toggleSelected(doc, true)
      className = 'Modal--noBodyPadding'
      body = DocumentPreview {doc, url, mime, @download, @verify, @openInWindow, deleteDoc: @delete, @open, @startSwipe, @processSwipe, @endSwipe}
      title = h1 id: 'document-heading', div null,
        strong utils.capitalize(mime),
        ": #{doc.title}"
      footer = div className: 'DocumentPreview-footer u-flex u-flexJustifyBetween',
        tch role: 'button', className: "Button Button--pointer Button--small prev u-flex u-flexAlignSelfStart #{cx 'is-disabled': !doc.prev}", 'aria-label': "Previous Document", 'aria-disabled': "#{!doc.prev}", style: @disabledPrevAndNextStyle(doc.prev), handler: @navTo(doc.prev), onEnter: @navTo(doc.prev), tabIndex: (if doc.prev then 0 else -1),
          'Previous'
        tch role: 'button', className: "Button Button--pointer Button--small next u-flex u-flexAlignSelfEnd #{cx 'is-disabled': !doc.next}", 'aria-label': "Next Document", 'aria-disabled': "#{!doc.next}", style: @disabledPrevAndNextStyle(doc.next), handler: @navTo(doc.next), onEnter: @navTo(doc.next), tabIndex: (if doc.next then 0 else -1),
          'Next'
      modal = {title, body, footer, className, onClose: @toDocs, contentLabel: doc.title}
      appStore.actions.showModal(modal)
    else
      @openInWindow(url)

  _open: (item) -> => @open(item)

  openInWindow: (url) ->
    window.open(url, '_blank').focus()

  toggleEdit: (doc) ->
    [pathname, query] = if @state.editing then ['/documents'] else ['/documents', editing: doc.id] #"/documents/editing/#{doc.id}"
    # @setState editing: if @state.editing then null else doc.id
    @context.router.push {pathname, query}

  toggleSelected: (doc, force) ->
    @setState selected: if doc in @state.selected and !force then [] else [doc]

  unSelect: (evt) ->
    @setState selected: []

  unEdit: ->
    @context.router.push '/documents'

  archive: (doc) -> =>
    documentsStore.actions.archive(doc.id, @context.router.isActive('/documents', true)).then =>
      @toDocs()

  prevent: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()

  toggleFilter: (sortKey) ->
    @sort(sortKey)

  getTableSortingInfo: () -> 
    [ {fieldName: "title", displayName: "Name"}, 
      {fieldName: "mime", displayName: "File Type"}, 
      {fieldName: "created_at", displayName: "Received"} ].map (col) =>
      sortDirection = @getAriaSortDirection(col.fieldName)
      if sortDirection then "column  #{col.displayName} sorted #{sortDirection}"

  render: ->
    titleSortDirection = @getAriaSortDirection('title')
    fileTypeSortDirection = @getAriaSortDirection('mime')
    receivedSortDirection = @getAriaSortDirection('created_at')

    thead =
      tr className: 'TableHeader TableHeader--flex TableHeader--documents TableHeader--zoom',
        div 'aria-live': 'polite', className: 'AriaLiveForScreenReaders', ref: 'hidden', ''
        tch tagName: 'th', role: 'button', tabIndex: 0, 'aria-label': "Column 1 Name #{if titleSortDirection then "sorted #{titleSortDirection}" else ''}", className: "TableHeader-column u-1of4 u-noselect #{@isSorted('title')}", onEnter: @toggleFilter('title', titleSortDirection, 'Name'), handler: @toggleFilter('title', titleSortDirection, 'Name'),
          span 'Name'
        tch tagName: 'th', role: 'button', tabIndex: 0, 'aria-label': "Column 2 File Type #{if fileTypeSortDirection then "sorted #{fileTypeSortDirection}" else ''}", className: "TableHeader-column u-1of4 u-noselect #{@isSorted('mime')}", onEnter: @toggleFilter('mime', fileTypeSortDirection, 'File Type'), handler: @toggleFilter('mime', fileTypeSortDirection, 'File Type'),
          span 'File Type'
        tch tagName: 'th', role: 'button', tabIndex: 0, 'aria-label': "Column 3 Received #{if receivedSortDirection then "sorted #{receivedSortDirection}" else ''}", className: "TableHeader-column u-1of4 u-noselect #{@isSorted('created_at')} u-textLeft", onEnter: @toggleFilter('created_at', receivedSortDirection, 'Received'), handler: @toggleFilter('created_at', receivedSortDirection, 'Received'),
          strong 'Received'
        div tagName: 'th', tabIndex: 0, 'aria-label': "Column 4 Actions", className: 'TableHeader-column u-1of4 u-noselect', style: { cursor: 'auto' },
          span 'Actions'
    return div {},
      div 'aria-live': 'assertive', className: 'sr-only', "#{ if @props.matcher then "#{@state.documents?.filter(@props.filterer)?.length} #{if @state.documents?.filter(@props.filterer)?.length == 1 then "result for #{@props.matcher}" else "results for #{@props.matcher}"}" else ''}" 
      table role: "table", 'aria-label': "Documents", 'aria-rowcount': @state.documents.filter(@props.filterer).sort(@getSortFn()).length, 'aria-colcount': "4", 'aria-describedby': "tableInfo", className: 'Table Table--fullWidth Table--bordered',
        thead
        IsEmpty className: 'TableBody TableBody--zoom', ifEmpty: 'No documents', loading: documentsStore.get('documentsLoading'),
          @state.documents.filter(@props.filterer).sort(@getSortFn()).map (doc, i, docs) =>
            doc.next = docs[i+1]
            doc.prev = docs[i-1]
            Document
              data: doc
              key: doc.id
              selected: doc in @state.selected
              editing: parseInt(doc.id) is @state.editing
              onSelect: @toggleSelected
              onEdit: @toggleEdit
              onOpen: @_open(doc)
              onRename: @rename
              onCancel: @toggleEdit
              onRemove: @delete(doc)
              onErase:  @erase(doc)
              onDownload: @download(doc)
              showCheckbox: @state.selected.length > 0
              style: zIndex: docs.length - i
      div id: "tableInfo", hidden: "true", 'aria-hidden': "true", @getTableSortingInfo()

module.exports = DocumentsList
