'use strict'
{div, span, input, fa, button, table, tbody, thead, tr, th, td} = Exim.DOM
{observer} = require 'mobx-react'
{Modal} = require 'components/elements/new-modal'
NotesModal = require 'components/calendar/modals/appt-notes'
EditApptModal = require 'components/calendar/modals/edit-appt'
CancelApptModal = require 'components/elements/cancel-appt-modal'
authStore = require 'stores/new-auth'
Note = require 'models/appointment-note'

getLatestNote = (appt) =>
  notes = appt.latestNotes
  return null if !notes.length

  [note] = notes
  return note.text

class AppointmentsModal extends React.Component
  constructor: (props) ->
    super(props)

    this.state = {
      note: null,
    }

  handleKeyUp: (evt) =>
    return if evt.defaultPrevented

    switch evt.key
      when 'Enter'
        evt.preventDefault()
        this.saveNote()
      when 'Escape'
        evt.preventDefault()
        this.cancelNote()

  createNote: (appointment) ->
    this.setState({
      note: new Note({appointment}),
    })

  saveNote: =>
    {note} = this.state
    return if !note.isSaveable

    note.post().then(() =>
      this.setState({note: null})
    )

  cancelNote: =>
    this.setState({note: null})

  showNotesModal: (appointment) ->
    modal = React.createElement(NotesModal, {appointment})
    this.props.showChildModal(modal)

  showEditModal: (appointment) ->
    modal = React.createElement(EditApptModal, {appointment})
    this.props.showChildModal(modal)

  showCancelModal: (appointment) ->
    modal = React.createElement(CancelApptModal, {appointment, type: 'cancel'})
    this.props.showChildModal(modal)

  isCreatingNote: ({id}) ->
    {note} = this.state

    return false if !note
    return note.appointment.id == id

  renderHead: ->
    return tr null,
      th className: 'AppointmentsModal-patient', 'Patient'
      th className: 'AppointmentsModal-status', 'Status'
      th className: 'AppointmentsModal-provider', 'Provider'
      th className: 'AppointmentsModal-notes', 'Notes'

  renderCreatingNote: ->
    {note} = this.state

    return React.createElement(React.Fragment, null,
      input {
        className: 'AppointmentsModal-text',
        value: note.text,
        ref: (el) =>
          el && el.focus()
        onChange: ({target}) =>
          note.text = target.value
        onKeyUp: this.handleKeyUp,
      }
      button {
        className: 'AppointmentsModal-action AppointmentsModal-action-saveNote',
        title: 'Save',
        disabled: !note.isSaveable,
        onClick: this.saveNote,
      }, fa 'check'
      button {
        className: 'AppointmentsModal-action AppointmentsModal-action-cancelNote',
        title: 'Cancel',
        onClick: this.cancelNote,
      }, fa 'times'
    )

  renderLatestNote: (appt) ->
    note = getLatestNote(appt)
    {user} = authStore

    return React.createElement(React.Fragment, null,
      note && span title: "Latest note:\n#{note}", note
      button {
        className: 'AppointmentsModal-action',
        title: 'Add note',
        disabled: !user.canReadCalendar,
        onClick: () => this.createNote(appt),
      }, fa 'plus'
      button {
        className: 'AppointmentsModal-action',
        title: 'See all notes',
        disabled: !appt.notes.length,
        onClick: () => this.showNotesModal(appt),
      }, fa 'sticky-note'
    )

  renderAppt: (appt) =>
    {patient} = appt

    return tr key: appt.id,
      td className: 'AppointmentsModal-patient',
        button {
          className: 'AppointmentsModal-proxy',
          title: patient.shortInfo,
          onClick: () =>
            this.props.abort()
            patient.proxy(appt)
        }, patient.name
      td className: 'AppointmentsModal-status',
        button {
          className: 'AppointmentsModal-action',
          title: 'Edit appointment',
          onClick: () => this.showEditModal(appt),
        }, fa 'pencil'
        button {
          className: 'AppointmentsModal-action AppointmentsModal-action-cancelAppt',
          title: 'Cancel appointment',
          onClick: () => this.showCancelModal(appt),
        }, fa 'calendar-times-o'
        appt.displayStatus
      td className: 'AppointmentsModal-provider',
        appt.provider.name
      td className: 'AppointmentsModal-notes',
        if this.isCreatingNote(appt)
          this.renderCreatingNote()
        else
          this.renderLatestNote(appt)

  render: ->
    {slot} = this.props
    appts = slot.appointments.filter((appt) => appt.isActive)

    return React.createElement(Modal, {
      title: 'Booked Appointments',
      close: this.props.abort,
      className: 'AppointmentsModal',
    },
      table className: 'NewTable',
        thead null, this.renderHead()
        tbody null, appts.map(this.renderAppt)
    )

module.exports = observer(AppointmentsModal)
