{visitsStore, metricsStore, checkinStore, authStore, patientDataStore, patientStore, appStore, synopsisStore} = require 'stores'

Metric         = require './Metric'
Widget         = require './Widget'
Diagnosis      = require './items/Diagnosis'
Allergens      = require './items/Allergens'
Medications    = require './items/Medications'
Conditions     = require './items/Conditions'
Immunizations  = require './items/immunizations'
NotesModal     = require 'components/elements/NotesModal'
Progress       = require 'components/elements/Progress'
InnerScroll    = require 'components/mixins/InnerScroll'
Donut          = require 'components/elements/Donut'
Spinner        = require 'components/elements/Spinner'
Switcher       = require 'components/elements/Switcher'
{client}         = require "config"
utils          = require 'lib/utils'

{isEqual} = require 'lodash'

config = require 'config'
{cx}   = Exim.helpers

HEALTH_PAGE_ITEMS = 4 * 4
FOCUS_PAGE_ITEMS  = 5
MAX_VISITS = 12
MAX_VISITS_UPCOMING = 3
MAX_VISITS_PREVIOUS = 7

matchesMoreVisits = (item) ->
  item.date is null or /more in past/.test(item.name)

{div, h2, a, i, tch, span, h4, h3}  = Exim.DOM

trendStores = [
  metricsStore
  visitsStore
  patientDataStore
  synopsisStore
]

widgetItems = [
  'diagnoses'
  'conditions'
  'medications'
  'allergies'
]
widgetVals = widgetItems.concat('dataDate').concat(widgetItems.map (item) -> "#{item}Loading")

Latest = Exim.createView module.id,
  mixins: [
    authStore.connect('user')
    patientStore.connect('patient')
    metricsStore.connect(
      'selectedHealth', 'selectedLifestyle', 'corkHealthItems',
      'healthItems', 'lifestyleItems', 'goals',
      'focusAreas', 'BMI', 'metricsLoading',
      'results', 'providersLoading'
    )
    visitsStore.connect 'visits', 'visitsLoading'
    patientDataStore.connect.apply(patientDataStore, widgetVals)
    InnerScroll
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    healthPage: 0
    focusPage: 0
    type: 'All'
    showRed: true
    animationDisabled: true
    expanded: null

  componentWillMount: ->
    @healthItemsPages = {}
    @fetch()
    metricsStore.actions.fetchResults()
    metricsStore.actions.fetchProviders() if config.features.newApi

  componentDidMount: ->
    if @state.healthItems?.length
      @setHealthItems()
    appStore.actions.setTitle('Trends')

  componentWillUnmount: ->
    appStore.actions.setTitle()
    trendStores.forEach (store) ->
      store.actions?.clear?()

  componentWillUpdate: (nextProps, nextState) ->
    if not isEqual(nextState.healthItems, @state.healthItems) or not isEqual(nextState.type, @state.type)
      @setHealthItems(nextState)
    if not isEqual(nextState.user.id, @state.user.id)
      @fetch()

  fetch: ->
    isPlainUser = not @state.user.proxying
    trendStores.forEach (store) ->
      args = if store is metricsStore
        {isPlainUser}
      else
        null
      store.actions.fetch(args).catch ->
        console.error 'Failed to fetch from', store

  setHealthItems: (state=@state) ->
    site = state.user?.default_site
    if site is 'crk'
      items = state.healthItems.concat(state.corkHealthItems)
    else
      items = state.healthItems
    @healthItems = items.filter(@filterByType)
    if state.BMI
      @healthItems.unshift state.BMI

    @healthPages = Math.ceil @healthItems.filter(@filterByType).length / HEALTH_PAGE_ITEMS

    @healthItemsPages = {}
    @healthItems.forEach (item, i) =>
      page = Math.floor(i / HEALTH_PAGE_ITEMS)
      @healthItemsPages[page] ?= []
      unless item.hasOwnProperty('patient_trending') && !item.patient_trending && !@state.user.proxying
        @healthItemsPages[page].push Metric
          key: item.id
          data: item
          chart: true
          size: 'small'
          tagName: 'button'
          onClick: @selectHealth
          site: state.user?.default_site

  onSetPage: (healthPage, totalPages) -> (evt) =>
    evt.stopPropagation()
    evt.preventDefault()
    return if healthPage < 0 or healthPage > totalPages - 1
    @setState {healthPage}

  selectLifestyle: (metric) ->
    @setState animationDisabled: false
    metricsStore.actions.selectLifestyle(metric)

  selectHealth: (metric) ->
    @setState animationDisabled: false
    metricsStore.actions.selectHealth(metric)

  filterByType: (metric) ->
    if @state.type is 'All'
      return metric
    else
      metric.id in config.metricTypes[@state.type]

  goToAppts: (type) -> (evt) =>
    evt.preventDefault()
    @context.router.push pathname: '/appointments', query: {type}

  toScreen: (step) -> =>
    checkinStore.actions.changeState('screen', patient_id: @state.patient.patient_id, appointment_id: 99999999, appointment_at: new Date).then =>
      @context.router.push "/checkin/screen/#{step}"

  flipDonut: (evt, ignoreSwitch) ->
    {toggleDonut} = @refs
    unless ignoreSwitch
      res = !@refs.toggleDonut.switcher.isChecked()
      toggleDonut?.ignore = true
      toggleDonut?.checked = res
      # @refs.toggleDonut.switcher.handleOnchange(res)
      toggleDonut?.jackEl.click()
    @setState showRed: !@state.showRed
    toggleDonut?.ignore = false
    @announceChanges()

  announceChanges: () -> 
    hiddenDiv = document.querySelector('.hidden')
    focusList = document.querySelectorAll('.FocusAreas-listItem')
    childEl = document.createElement("p")

    utils.screenReaderSpeak("#{if @refs.toggleDonut?.props?.checked then 'Opportunities' else 'Successes'} Showing Toggle", "polite")
    while hiddenDiv.firstChild
      hiddenDiv.removeChild(hiddenDiv.lastChild)
    if focusList and focusList.length
      msg = ''
      focusList.forEach((el) -> msg += "#{el.innerHTML} ")
      msgContent = "Focus Areas: " + msg
      childEl.textContent = msgContent
      hiddenDiv.appendChild(childEl)
      setTimeout(() ->
        utils.screenReaderSpeak(msgContent, "polite")
      , 1000)
    else 
      msgContent = "Focus Areas: No data"
      childEl.textContent = msgContent
      hiddenDiv.appendChild(childEl)
      setTimeout(() ->
        utils.screenReaderSpeak(msgContent, "polite")
      , 1000)

   
  showModal: (visit, focusOnCloseRef) -> (evt) =>
    focusOnClose = @refs[focusOnCloseRef]
    {lifestyleItems, BMI} = @state
    evt.preventDefault()
    modal =
      className: 'NotesModal'
      title: "#{visit.name} - #{visit.date}"
      body: NotesModal { appointment_id: visit.appointment_id, visit, metrics: [BMI].concat(@healthItems).concat(lifestyleItems) }
      print: true
      onClose: -> focusOnClose?.focus()
    appStore.actions.showModal(modal)

  toggleExpanded: (name) ->
    @setState expanded: if name is @state.expanded then null else name

  removeDiagnosis: (diagnosis) ->
    return if !diagnosis?.code or not @state.user.proxying
    patientDataStore.actions.removeDiagnosis diagnosis?.code

  getVisitsList: ->
    {visits} = @state
    sorted = {upcoming: [], previous: []}
    currentDate = new Date().setHours(0,0,0,0)

    visits = visits.filter (item) -> ['canceled', 'no_show', 'Canceled', 'No Show'].indexOf(item.status) is -1

    visits.forEach (item) =>
      if ((item.date and /\(.*\)/.test item.date) or (/future/.test item.name) or (new Date(item.date) >= currentDate)) and (!item.status or item.status.toLowerCase() isnt 'completed')
        sorted.upcoming.push item
      else
        sorted.previous.push item

    sorted.upcoming.sort (a, b) -> new Date(a.date) - new Date(b.date)
    sorted.previous.sort (a, b) -> new Date(b.date) - new Date(a.date)

    showMore = {name: 'View more', date: null}

    if sorted.upcoming.length > MAX_VISITS_UPCOMING
      sorted.upcoming = sorted.upcoming.slice(0, MAX_VISITS_UPCOMING).concat([showMore])

    if sorted.previous.length > MAX_VISITS_PREVIOUS
      sorted.previous = sorted.previous.slice(0, MAX_VISITS_PREVIOUS).concat([showMore])

    Object.keys(sorted).map (key) =>
      list = sorted[key]

      div className: 'ActionsSection', key: "#{key}-appointments",
        h3 className: 'ActionsSection-title', "#{key} appointments"
        if @state.visitsLoading
          div className: 'ActionsSection-empty', Progress()
        else if !list.length
          div className: 'ActionsSection-empty', "No #{key} appointments"
        else
          list.map (item, i) =>
            if matchesMoreVisits item
              a href: '#', 'aria-label': item.name, className: 'Action', onClick: @goToAppts(key), key: item.name,
                div className: 'Action-more', item.name
            else
              actionText = item.date.replace(/[()]/g, '') + ' ' + item.name
              actionRef = "appt-#{i}"
              a href: '#', 'aria-label': actionText, className: cx('Action': true, 'Action--attention': !item.date), key: "#{item.name} #{item.date} #{i}", onClick: @showModal(item, actionRef), ref: actionRef,
                div className: 'Action-date', item.date.replace(/[()]/g, '')
                div className: 'Action-name', item.name

  getLifeStyleItems: (from, to) ->
    @state.lifestyleItems.slice(from, to).map (metric) =>
      Metric
        key: metric.id
        size: 'small'
        tagName: 'button'
        className: 'Metric--lifestyle'
        chart: false
        data: metric
        icon: metric.icon
        onClick: @selectLifestyle
        site: @state.site?.default_site

  getLifeStyleSection: ->
    default_site = user?.default_site

    div null,
      div className: 'Content-subheader',
        h3 className: 'Content-subheaderText trends-header', 'Lifestyle'
      div className: 'ContentWrapper ContentWrapper--wrapOnTablet',
        div className: 'ContentBlock ContentBlock--1of2',
          Metric
            size: 'big'
            className: 'Metric--lifestyleSelected'
            chart: true
            data: @state.selectedLifestyle
            icon: @state.selectedLifestyle.icon
            xTicks: 3
            xTickFormat: '%b %y'
            animationDisabled: @state.animationDisabled
            state: @state.metricsLoading
            site: default_site

          div className: 'Lifestyle Lifestyle-left',
            @getLifeStyleItems(0, 4)

        div className: 'ContentBlock ContentBlock--1of2',
          div className: 'Lifestyle',
            @getLifeStyleItems(4)

  render: ->
    {healthPage, user} = @state
    default_site = user?.default_site
    isSingapore = default_site in ['amk', 'inn']
    focusItems = @state.focusAreas
    redItems = focusItems.filter((item) -> !item.good)
    greenItems = focusItems.filter((item) -> item.good)

    focusItemsList = if @state.showRed then redItems else greenItems

    focusPages = Math.ceil @state.focusAreas.length / FOCUS_PAGE_ITEMS

    noItems = (step) => h2 null,
      'No metrics.'
      a href: '#', onClick: @toScreen(step), 'Add new?'

    div null,
      div className: 'Content-subheader',
        h2 className: 'Content-subheaderText trends-header', 'Health Maintenance'

      div null,
        div className: 'ContentWrapper ContentWrapper--wrapOnTablet',
          div className: 'ContentBlock ContentBlock--1of2 ContentBlock--metric mobile-second',
            div className: 'metrics',
              Metric
                className: 'selected-metric'
                size: 'large'
                chart: true
                data: @state.selectedHealth
                animationDisabled: @state.animationDisabled
                state: @state.metricsLoading
                site: default_site

          unless client == "apple"
            div className: 'ContentBlock ContentBlock--1of2 ContentBlock--focus mobile-first',
              div className: 'ContentBlock-header', 'Focus areas'
              div className: "FocusAreas #{cx 'is-red': @state.showRed}",
                if focusItems.length
                  div className: 'Donut',
                    Donut(data: focusItems, size: 'big', showRed: @state.showRed, flip: @flipDonut, switcher: true, ariaLabel: 'Focus Area Graph')
                    div className: 'DonutSwitcher',
                      Switcher
                        checked: @state.showRed
                        ref: 'toggleDonut'
                        onChangeValue: @flipDonut
                        color: '#E02654'
                        secondaryColor: '#DDDDDD'
                        title: 'Switcher'
                div className: 'FocusAreas-list', onWheel: @preventParentWheeling,
                  focusItemsList.map (item) ->
                    div key: item.id, className: 'FocusAreas-listItem', item.name
                  if focusItemsList.length == 0
                    'No data'
                div className: 'hidden'

        div className: 'ContentWrapper ContentWrapper--wrapOnTablet',
          div className: 'ContentBlock ContentBlock--metrics',
            Spinner state: false, ifEmpty: noItems('biometrics'), className: 'Metrics',
              Object.keys(@healthItemsPages).map (page) =>
                div className: "MetricsGroup #{cx 'u-hidden': parseInt(page) isnt healthPage}", key: "group-#{page}",
                  @healthItemsPages[page]
            if @healthPages > 1
              div className: 'u-flex u-flexRow u-flexCenter',
                div className: 'Paginator',
                  tch tagName: 'i', className: "ion ion-chevron-up Button--pointer #{cx disabled: healthPage is 0}", handler: @onSetPage(healthPage - 1, @healthPages)
                  [0...@healthPages].map (index) =>
                    tch tagName: 'i', key: "hp-#{index}", className: "ion dot Button--pointer ion-ios-circle-#{if healthPage is index then 'filled' else 'outline'}", handler: @onSetPage(index, @healthPages)
                  tch tagName: 'i', className: "ion ion-chevron-down Button--pointer #{cx disabled: @healthPages is 0 or healthPage is @healthPages-1}", handler: @onSetPage(healthPage + 1, @healthPages)


          div className: 'ContentBlock ContentBlock--actions',
            div className: 'Actions',
              div className: 'ActionsGroup',
                @getVisitsList()

        if isSingapore then @getLifeStyleSection()

        div className: 'Content-subheader',
          h2 className: 'Content-subheaderText trends-header', 'Goals'
        div className: 'ContentWrapper',
          div className: 'ContentBlock',
            @state.goals?.map((goal) -> div key: goal, goal)

        div className: 'ContentBlock',
          Widget
            name: 'Diagnoses'
            Item: Diagnosis
            items: @state.diagnoses.items
            fields:
              name: 'Diagnoses'
              note: 'Status'
            sortKeys:
              note: 'date'
            defaultSort: {type: 'date', asc: true}
            state: @state.diagnosesLoading
            toggleExpanded: @toggleExpanded
            expanded: @state.expanded is 'Diagnoses'
            results: @state.results.diagnoses

        div className: 'ContentBlock',
          Widget
            name: 'Conditions'
            Item: Conditions
            items: @state.conditions.items
            fields:
              name: 'Conditions'
              note: 'Relationships'
            state: @state.conditionsLoading
            toggleExpanded: @toggleExpanded
            expanded: @state.expanded is 'Conditions'
            results: @state.results.conditions
            note: @state.conditions.note

        div className: 'ContentBlock',
          Widget
            name: 'Medications'
            Item: Medications
            items: @state.medications.items
            fields:
              name: 'Medications'
              dose_instructions: 'Instructions'
              qty: 'Quantity'
            state: @state.medicationsLoading
            toggleExpanded: @toggleExpanded
            expanded: @state.expanded is 'Medications'
            results: @state.results.medications
            fallbackLabel: 'Not specified'
            rowHeight: 70

        div className: 'ContentBlock',
          Widget
            name: 'Allergies'
            Item: Allergens
            items: @state.allergies.items
            fields:
              name: 'Allergies'
              note: 'Reaction'
            state: @state.allergiesLoading
            toggleExpanded: @toggleExpanded
            expanded: @state.expanded is 'Allergies'
            results: @state.results.allergies

        div className: 'ContentBlock',
          React.createElement(Immunizations)

module.exports = Latest
