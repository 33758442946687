{ observer } = require('mobx-react')
{ withEscapeHandler } = require('components/elements/new-modal')
{ div, p } = Exim.DOM

PopUp = withEscapeHandler(((props) =>
  return React.createElement(React.Fragment, null,
    div className: 'ProfileInfoPopUp-overlay', onClick: props.close
    props.children
  )), 'profile-view-info-icon')

PCPInfoPopUp = Exim.createView module.id,
  componentDidMount: ->
    @refs.popUp?.focus()

  render: ->
    { rect: { bottom, right }, abort } = this.props
    popUpClassName = if (bottom < 535) then 'ProfileInfoPopUp-no-transform' else 'ProfileInfoPopUp-transform'

    return React.createElement(PopUp, {close: abort},
      div ref: 'popUp', tabIndex: 0, className: popUpClassName, style: {top: "#{bottom}px", left: "#{right - 100}px"},
        p className: 'ProfileInfoPopUp-statement',
          'Selecting a Primary Crossover Provider enhances your care experience by allowing Crossover to anticipate your healthcare needs and address your concerns. Your future primary care visits will be defaulted to this dedicated Provider. You can update your Primary Crossover provider at any time.'
        p className: 'ProfileInfoPopUp-statement-note',
          'NOTE: You can still schedule visits with any other provider on the team at any time.'
    )


module.exports = observer(PCPInfoPopUp)
