CLIENT_ID = 'xo'

exports.ssInit = () ->
  new Promise (resolve, reject) ->
    ss = new cordova.plugins.SecureStorage(
      () -> resolve(ss)
      () -> reject()
      CLIENT_ID
    )

exports.ssGet = (ss, key) ->
  new Promise (resolve, reject) ->
    ss.get(
      (val) -> resolve(val)
      () -> reject()
      key
    )

exports.ssGetPair = (ss, key1, key2) ->
  exports.ssGet(ss, key1).then((val1) ->
    exports.ssGet(ss, key2).then((val2) ->
      obj = {}
      obj[key1] = val1
      obj[key2] = val2
      obj
    )
  )

exports.ssWritePair = (ss, key1, val1, key2, val2) ->
  noop = (() ->)
  ss.set(
    () -> ss.set(noop, noop, key2, val2)
    noop
    key1, val1
  )

exports.ssDeletePair = (ss, key1, key2) ->
  noop = (() ->)
  ss.remove(
    () -> ss.remove(noop, noop, key2)
    noop
    key1
  )

exports.touchIDSupported = () ->
  return unless window.touchid

  new Promise (resolve, reject) ->
    window.touchid.checkSupport(
      () -> resolve(true),
      () -> resolve(false)
    )

exports.touchIDAuth = (prompt) ->
  new Promise (resolve, reject) ->
    window.touchid.authenticate(
      () -> resolve()
      (e) ->
        if e is 'Canceled by user.'
          reject('cancel')
        else
          reject('mismatch')
      prompt
    )

exports.androidFingerprintSupported = () ->
  new Promise (resolve, reject) ->
    resolve(false) unless window.FingerprintAuth
    window.FingerprintAuth.isAvailable(
      () -> resolve(true),
      () -> resolve(false)
    )

exports.androidFingerprintAuth = () ->
  new Promise (resolve, reject) ->
    resolve(false) unless window.FingerprintAuth
    window.FingerprintAuth.encrypt(
      {clientId: CLIENT_ID},
      () -> resolve(true),
      () -> resolve(false)
    )

exports.fingerprintSupported = () ->
  platform = window.device?.platform?.toLowerCase()

  if platform is 'android'
    exports.androidFingerprintSupported()
  else if platform is 'ios'
    exports.touchIDSupported()
  else
    Promise.resolve(false)

exports.fingerprintAuth = (prompt) ->
  platform = window.device?.platform?.toLowerCase()

  if platform is 'android'
    exports.androidFingerprintAuth()
  else if platform is 'ios'
    exports.touchIDAuth(prompt)
  else
    Promise.resolve(false)
