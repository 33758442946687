request       = require 'lib/request'

module.exports = store = Exim.createStore
  actions: [
    'fetch'
    'fetchArchived'
    'archive'
    'saveTitle'
    'verify'
    'remove'
    'erase'
    'upload'
    'clear'
    'updateFilter'
    'clearFilter'
  ]

  initial:
    documents: []
    error: null
    documentsLoading: true
    filter: null
    uploadedDocument: {}

  fetch:
    while: (documentsLoading) ->
      @set {documentsLoading}
    on: ->
      request.get('documents')
    did: (documents) ->
      @set {documents}

  fetchArchived:
    while: (documentsLoading) ->
      @set {documentsLoading}
    on: ->
      request.get('documents/archived')
    did: (documents) ->
      @set {documents}

  archive:
    on: (id, archive) ->
      @archive = archive
      request.put("documents/#{id}/archive", {@archive})
    did: ->
      if @archive then @actions.fetch() else @actions.fetchArchived()

  updateFilter: (filter) ->
    @set { filter }

  clearFilter: ->
    @set filter: null

  saveTitle:
    while: (titleSaving) ->
      @set {titleSaving}
    will: (args) ->
      @ignoreRequest = false
      {id, title} = args
      @id = id
      @title = title
      [documents, @oldDocs] = [@get('documents'), @get('documents')]
      documents.forEach (doc) =>
        if doc.id is @id and doc.title is @title
          @ignoreRequest = true
      unless @ignoreRequest
        documents = documents.filter((doc) => doc.id isnt @id)
        @set {documents}
    on: ->
      request.put("documents/#{@id}", {@title}) unless @ignoreRequest
    did: (file) ->
      @set 'documents': @get('documents').concat(file)
    didNot: (errorResponse) ->
      @set 'documents', @oldDocs
      alert errorResponse.error


  verify:
    while: (verifyingDoc) ->
      @set {verifyingDoc}
    will: (args) ->
      {@id} = args
      [documents, @oldDocs] = [@get('documents'), @get('documents')]
      documents.forEach (doc) =>
        if doc.id is @id
          doc.unverified = false
      @set {documents}
    on: ->
      request.put("documents/#{@id}", {sign: true})
    didNot: ->
      @set 'documents', @oldDocs


  remove:
    will: (args, force) ->
      [documents, @oldDocs] = [@get('documents').slice(), @get('documents').slice()]
      @targ = documents.filter((doc, i) -> doc.id is args.id and ~(doc._index = i))[0]
      @id = @targ.id
      if force
        documents = documents.filter((doc) -> doc.id isnt args.id)
      else
        @targ.deleted = true
      @set {documents}
    on: (documents) ->
      request.del("documents/#{@id}")
    didNot: (err) ->
      @set 'documents', @oldDocs

  erase: (args) ->
    documents = @get('documents').slice()
    @targ = documents.filter((doc, i) -> doc.id is args.id and ~(doc._index = i))[0]
    documents.splice(@targ._index, 1) if @targ
    @set {documents: documents.slice()}

  upload:
    while: (state) ->
      @set documentUploading: state
    on: (file, opts) ->
      @set error: null
      form = new FormData

      if opts and opts.fileName
        form.append 'file', file, opts.fileName
      else
        form.append 'file', file

      console.log("Uploading form", form)
      request.form('documents', form, opts)
    did: (file) ->
      @set 'documents': @get('documents').concat(file), uploadedDocument: file
    didNot: (xhr) ->
      if error = xhr.error
        @set error
        alert error
      else
        console.error xhr
  clear: ->
    @set documents: [], uploadedDocument: {}
