request = require 'lib/request'
graph   = require 'lib/graph'
utils   = require 'lib/utils'
{kebabCase} = require('lodash')
{HKit, GFit}  = require 'lib/device'
config = require 'config'
metricDateFormat = 'MM/DD/YYYY hh:mm:ss'
hashDateFormat = 'YYYYMMDD'
goalsValues =
  '1': "I don't want to get healthier"
  '2': 'I want to get healthier, but not yet'
  '3': "I'm ready to start getting healthier"
  '4': "I'm already improving my health"
  '5': "I've reached my health goals"

getInitial = ->
  metrics: []
  types: []
  screenData: {}
  # maintenance: []
  # health: []
  selectedHealth:
    values: []
  selectedLifestyle:
    values: []
  healthItems: []
  lifestyleItems: []
  goals: []
  focusAreas: []
  results: {}
  providers: []
  BMI: null

module.exports = store = Exim.createStore
  actions: [
    'clear'
    'fetch'
    'fetchMetrics'
    'fetchTypes'
    'fetchBMI'
    'save'
    'selectLifestyle'
    'selectHealth'
    'getHealthKitData'
    'setHealthKitData'
    'getGoogleFitData'
    'setGoogleFitData'
    'fetchResults'
    'fetchProviders'
  ]

  initial: getInitial

  clear: ->
    @set getInitial()

  fetchMetrics:
    on: ->
      request.get('patient/metrics')
    did: (data) ->
      @set metrics: data

  fetchTypes:
    on: ->
      request.get('metric_types')
    did: (data) ->
      @set types: data

  fetchBMI:
    while: (metricsLoading) ->
      @set {metricsLoading}
    on: ->
      request.get('patient/metrics/74')
    did: (metrics) ->
      metric = metrics[0]
      if metric
        datasets = graph.datasets metric

        metric.values ?= []
        metric.value = graph.lastValueLabel metric, datasets
        metric.cls = graph.statusClass metric, datasets
        metric.values.forEach (value) ->
          value.date = value.created
        @set BMI: metric

  fetch:
    while: (metricsLoading) ->
      @set {metricsLoading}
    will: (params) ->
      @isPlainUser = params?.isPlainUser ? true
      request.get('metric_types')
    on: (types) ->
      @set {types}
      request.get('patient/metrics')
    did: (metrics) ->
      find = (id) -> metrics.filter((m)-> m.id is id)[0]
      comparator = (a, b) ->
        type = a['type']
        bType = b['type']
        {types} = config
        return 0 unless type and bType and type is bType
        currTypes = types[type]
        currTypes.indexOf(a['name']) - currTypes.indexOf(b['name'])

      @get('types').forEach (item) ->
        if found = find(item.id)
          found[key] = value for key, value of item
        else
          item.values = []
          metrics.push item

      metrics.forEach (item) ->
        name = item.name
        {types} = config

        Object.keys(types).forEach (type) ->
          item.type = type if name in types[type]

      rem = metrics.filter (item) ->
        {name, values, type} = item
        (type is 'lifestyle' and values.length is 0) or
        (name isnt 'BMI' and not type)
      metrics = metrics.filter((m) -> not(m in rem) )

      [healthItems, corkHealthItems, goals, lifestyleItems] = [[], [], [], []]

      metrics.forEach (item) ->
        if item.id is 140
          item.name = 'Depression (PHQ-9)'
        if item.id is 127
          item.name = 'Anxiety (GAD-7)'

        if item.id is 99
          sleepHash = item.values.reduce((sleepHash, i) ->
            date = moment(i.date).format(hashDateFormat)
            sleepHash[date] ?= 0
            sleepHash[date] += +i.value
            sleepHash
          , {})
          item.values = Object.keys(sleepHash).map((date) ->
            {
              date: moment(date, hashDateFormat).format(metricDateFormat)
              source: 'healthkit'
              value: sleepHash[date]
            }
          )

        if item.id in [76,77]
          id = item.id
          value = item.values?.sort((a,b) -> moment(b.date, metricDateFormat) - moment(a.date, metricDateFormat))[0].value
          value = if id is 76 then goalsValues[value] else value
          goals.push(value)
        else
          datasets = graph.datasets item
          item.value = graph.lastValueLabel item, datasets
          item.cls = graph.statusClass item, datasets
          item.good = graph.isMetricGood item, datasets

          switch item.type
            when 'health'
              healthItems.push item
            when 'corkHealth'
              corkHealthItems.push item
            when 'lifestyle'
              name = item.name
              item.icon = "icn-#{kebabCase(name.toLowerCase())}.svg"
              lifestyleItems.push item

      healthItems = healthItems.sort(comparator).sort (a, b) ->
        behavioralIds = [127, 140]
        if (a.id in behavioralIds) and not (b.id in behavioralIds)
          return 1
        else if not (a.id in behavioralIds) and (b.id in behavioralIds)
          return -1
        else
          return 0

      selectedHealth = healthItems.filter((item)->item.value)[0] or {values: []}
      selectedLifestyle = lifestyleItems.filter((item)->item.value)[0] or {values: []}

      lifestyleItems = lifestyleItems.sort(comparator)

      BMI = metrics.filter((item)->item.name is 'BMI')[0]

      isEmpty = (item) -> item.values.length > 0
      sortByValues = (a, b) -> b.values.length - a.values.length
      clone = (m) -> Object.create(m)

      focusAreas = healthItems.concat(BMI).concat(lifestyleItems).filter(isEmpty).sort(sortByValues).map(clone)

      @set {corkHealthItems, healthItems, lifestyleItems, goals, selectedHealth, selectedLifestyle, BMI, focusAreas}
      if @isPlainUser
        @actions.setHealthKitData()
        @actions.setGoogleFitData()

  save:
    will: (attrs) ->
      data = @get 'screenData'
      data[attrs.type] = attrs.metrics
      @set 'screenData', data
    on: (attrs) ->
      # request.post("metrics/#{attrs.type}", attrs.metrics)
      new Promise (r) -> r (attrs)

  selectLifestyle: (selectedLifestyle) ->
    @set {selectedLifestyle}

  selectHealth: (selectedHealth) ->
    @set {selectedHealth}

  getHealthKitData:
    on: ->
      HKit.getAllMetrics(true)
    did: (metrics) ->
      return HKit.updateFetchDate() unless metrics.length
      console.log 'getHealthKitData: Uploading HealthKit data to server', metrics
      request.put('metrics/health', metrics).then(HKit.updateFetchDate).then(@actions.fetch(false))

  setHealthKitData: ->
    HKit.syncMetricsFromServer @get('healthItems')

  getGoogleFitData:
    on: ->
      GFit.getAllMetrics(true)
    did: (metrics) ->
      return GFit.updateFetchDate() unless metrics.length
      console.log 'getGoogleFitData: Uploading Google Fit data to server', metrics
      request.put('metrics/health', metrics).then(GFit.updateFetchDate).then(@actions.fetch(false))

  setGoogleFitData: ->
    GFit.syncMetricsFromServer @get('healthItems')

  fetchResults:
    on: ->
      @set {results}

  fetchProviders:
    while: (providersLoading) ->
      @set {providersLoading}
    on: ->
      request.get('config/providers')
    did: (data) ->
      @set providers: data

results = {
  diagnoses: [
    {name: 'Hypertension', type: 'type'}
    {name: 'Lower Back Pain', type: 'type'}
    {name: 'Shoulder Strain', type: 'type'}
    {name: 'Hypertension', type: 'type'}
    {name: 'Lower Back Pain', type: 'type'}
    {name: 'Shoulder Strain', type: 'type'}
    {name: 'Hypertension', type: 'type'}
    {name: 'Lower Back Pain', type: 'type'}
    {name: 'Shoulder Strain', type: 'type'}
    {name: 'Hypertension', type: 'type'}
    {name: 'Lower Back Pain', type: 'type'}
    {name: 'Shoulder Strain', type: 'type'}
  ],
  conditions: [
    {name: 'Cancer', type: 'type'}
    {name: 'Diabetes', type: 'type'}
    {name: 'Cholesterol', type: 'type'}
    {name: 'Bronchitis', type: 'type'}
    {name: 'Heart Disease', type: 'type'}
    {name: 'Cancer', type: 'type'}
    {name: 'Diabetes', type: 'type'}
    {name: 'Cholesterol', type: 'type'}
    {name: 'Bronchitis', type: 'type'}
    {name: 'Heart Disease', type: 'type'}
  ],
  allergies: [
    {name: 'Codeine', type: 'type'}
    {name: 'Imtrex', type: 'type'}
    {name: 'Levaquin', type: 'type'}
    {name: 'Codeine', type: 'type'}
    {name: 'Imtrex', type: 'type'}
    {name: 'Levaquin', type: 'type'}
    {name: 'Codeine', type: 'type'}
    {name: 'Imtrex', type: 'type'}
    {name: 'Levaquin', type: 'type'}
    {name: 'Codeine', type: 'type'}
    {name: 'Imtrex', type: 'type'}
    {name: 'Levaquin', type: 'type'}
  ],
  medications: [
    {name: 'Albuterol', type: 'type'}
    {name: 'Ambien', type: 'type'}
    {name: 'Atenolol', type: 'type'}
    {name: 'Ceftriaxone', type: 'type'}
    {name: 'Enalapril', type: 'type'}
    {name: 'Glipizide', type: 'type'}
    {name: 'Hydrocone', type: 'type'}
    {name: 'Methadone', type: 'type'}
    {name: 'Tramadol', type: 'type'}
  ]
}
