getFocusableElements = require('lib/utils/getFocusableElements').default
{p, div, input, label, fa, span, tch} = Exim.DOM

INTERESTS = [
  [
    { label: 'I’d like to understand more about my health in general', metric_type_id: 250 }
    { label: 'I’d like to ease neck or back pain and/or improve my overall physical functioning', metric_type_id: 251 }
    { label: 'I need support with my diet and/or to learn how to live healthier', metric_type_id: 252 }
  ]
  [
    { label: 'I want to improve my mental well-being to enhance my personal and professional life', metric_type_id: 253 }
    { label: 'I’m due to check on the health of my eyes', metric_type_id: 254 }
    { label: 'I’m due for my annual skin cancer screening and/or have other issues with my skin', metric_type_id: 255 }
    { label: 'I’m content with my health overall', metric_type_id: 256 }
  ]
]

Interests = Exim.createView module.id,
  getInitialState: ->
    metrics: []

  componentDidMount: ->
    # need to wait 2 seconds before setting focus to allow screen reader to finish reading 'X step loaded' annoucement for a11y purposes
    setTimeout(() ->
      items = document.getElementById('sign-up-interest-items')
      focusable = getFocusableElements(items)
      focusable[0]?.focus()
    , 2000)

  changed: (metric) -> (evt) =>
    {metrics} = @state
    if evt.target.checked
      metrics.push(metric)
    else
      metrics = metrics.filter( (x) -> x isnt metric )
    @setState {metrics}

  next: ->
    document.querySelector('.hidden').innerHTML = "Verify step loading"

    @props.next(interests: @state.metrics)

  render: ->
    {metrics} = @state

    div className: 'SignUpInterests',
      div className: 'SignUpInterests--content',
        p 'Choose which most apply to you.'

        div className: 'SignUpInterests--items', id: 'sign-up-interest-items',
          INTERESTS.map (r, idx) =>
            div className: 'SignUpInterests--column', key: idx,
              r.map (i) =>
                div className: 'SignUpInterests--item', key: i.metric_type_id,
                  input type: 'checkbox', id: i.metric_type_id, onChange: @changed(i.metric_type_id), checked: metrics.indexOf(i.metric_type_id) > -1
                  label htmlFor: i.metric_type_id, i.label

      div className: 'SignUp--actions',
        tch tagName: 'a', href: '#', handler: @props.leave, className: 'Auth--informational-link underline',
          fa 'times-circle', 'Button-icon'
          span className: 'Button-text', 'Leave Activation'
        tch tagName: 'a', href: '#', className: 'Button Button--action', handler: @next, 'Finish'


module.exports = Interests
