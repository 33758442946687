'use strict'
Step = require 'components/mixins/Step'
{observer} = require('mobx-react')
{capitalize} = require('lodash')
checkInStore = require('stores/new-check-in').default
{cx} = Exim.helpers
{main, section, div, h3, label, h1, span, tch} = Exim.DOM

DentalStep = React.createClass({
  displayName: 'DentalStep',
  mixins: [Step]
  name: 'dental'

  required: ['visit', 'brush', 'floss', 'sensitivity']

  yesNoRadio: (name, opts = {}) ->
    data = @getFields()
    div className: "RadioGroup #{@err(name)} RadioGroup--horizontal",
      [ {value: true, title: 'yes'}, {value: false, title: 'no'} ].map (option) =>
        {value, title} = option
        tch key: "radio-#{value}", className: 'RadioGroupItem', handler: (=> @updateData(name, value)), tagName: 'li',
          div className: "Radio Radio--gray Radio--large #{cx selected: data[name] is value}"
          span capitalize title

  render: ->
    {user} = checkInStore

    return div className: 'CheckinForm',
      h1 'Dental Questionnaire'
      span className: 'CheckinForm-row CheckinForm-row--desc CheckinForm-row--no-margin',
        'Tell us about your current dental routine and habits.'
      div className: 'ContentBlock',
        h3 'General Questions'
        div className: 'CheckinForm-row is-lifestyle',
          label 'How long since your last dental visit?'
          @select 'visit', ['Less than 6 months', '6-12 months', '1-3 years', 'Greater than 3 years']

        div className: 'CheckinForm-row is-lifestyle',
          label 'How frequently do you brush your teeth?'
          @select 'brush', ['3x or more a day', '2x a day', '1x a day', 'Weekly', 'Seldom']

        div className: 'CheckinForm-row is-lifestyle',
          label 'How frequently do you floss?'
          @select 'floss', ['1x or more a day', '2-6x a week', '1-6x a month', 'Seldom', 'Never']

        div className: 'CheckinForm-row is-lifestyle',
          label 'Do you have any teeth sensitivity?'
          @select 'sensitivity', ['Hot', 'Cold', 'Sweets', 'Pressure', 'Multiple sensitivities', 'None']

        h3 'Yes/No Questions'

        if user.isFemale
          div className: 'CheckinForm-row is-dental-checkbox columned',
            label 'Are you pregnant?'
            @yesNoRadio('pregnant')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Are you nervous about dental treatment?'
          @yesNoRadio('nervous')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Have you ever had orthodontic treatment?'
          @yesNoRadio('orthodontic')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Are you happy with your smile?'
          @yesNoRadio('happy')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Do your gums bleed when you brush or floss?'
          @yesNoRadio('bleed')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Do you clench or grind your teeth?'
          @yesNoRadio('grind')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Have you ever been treated for gum disease?'
          @yesNoRadio('gums')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Are any of your teeth currently causing you pain?'
          @yesNoRadio('pain')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Have you ever had any periodontal treatment?'
          @yesNoRadio('periodontal')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Are you concerned with teeth loosening?'
          @yesNoRadio('loosening')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Do you have dental implants, dentures, or partials?'
          @yesNoRadio('implants')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Do you have any clicking or pain in your jaw?'
          @yesNoRadio('jaw')

        div className: 'CheckinForm-row is-dental-checkbox columned',
          label 'Have you ever taken medication for a bone disorder?'
          @yesNoRadio('bonemeds')

      this.renderCheckInFooter()
})

module.exports = observer(DentalStep)
