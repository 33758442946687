utils = require 'lib/utils'

re =
  digit:  /\d/
  dob:    /^(\d{1,2})([-/. ])(\d{1,2})\2(\d{4})$/
  email:  /[^@]+@[^@]+/
  gender: /^(m|f|i|o|u|male|female|intersex|other|unknown)$/i
  low:    /[a-z]/
  name:   /^[a-z][-a-z0-9 .']*$/i
  up:     /[A-Z]/

makeGuard = (item, typeName) ->
  if item and typeof item isnt typeName
    throw new TypeError "Invalid type, #{typeName} expected"

type = {}

['string', 'object', 'number'].forEach (name) ->
  type[name] = (a, b) -> makeGuard a, name

checkers =
  name: (value) ->
    type.string value
    re.name.test value

  email: (value) ->
    type.string value
    re.email.test value

  password: (password) ->
    type.string password
    password and
    password.length >= 8 and
    re.low.test(password) and
    re.up.test(password) and
    re.digit.test(password)

  gender: (value) ->
    type.string value
    value.length and re.gender.test value

  dob: (value) ->
    type.string value
    if rx = value.match(re.dob)
      mon = +rx[1]
      day = +rx[3]
      yrs = +rx[4]
      if 1915 < yrs <= 2020
        all = [mon, day, yrs].join('/')
        if all is moment(all, 'M/D/YYYY').format('M/D/YYYY')
          return true
    false

  truthy: (value) ->
    !!value

  consent: (value) ->
    true

  # Transformer.
  phone: (value) ->
    type.string value
    utils.toPhone value

aliases =
  first_name: 'name'
  middle_name: 'name'
  last_name: 'name'
  birthdate: 'dob'
  crossover: 'consent'
  wellness_one: 'consent'
  birthdate_day: 'truthy'
  birthdate_month: 'truthy'
  birthdate_year: 'truthy'
  cell_phone: 'phone'
  work_phone: 'phone'
  home_phone: 'phone'
  emergency_phone: 'phone'
  password_confirmation: 'password'
  create_pass: 'password'

for key, al of aliases
  checkers[key] = checkers[al]

checkers.re = re

checkers.complex =
  password: (value) ->
    characters: value.length >= 8
    lowerCase: re.low.test value
    upperCase: re.up.test value
    number:    re.digit.test value

module.exports = checkers
# validate.transform = transform
