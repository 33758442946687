{isEqual} = require 'lodash'
{div, spinner} = Exim.DOM

DELAY = 0
END_DELAY = 50

Spinner = Exim.createView module.id,
  displayName: 'spinner'

  propTypes:
    state: React.PropTypes.bool

  getInitialState: ->
    loading: false
    isMounted: false

  getDefaultProps: ->
    delay: DELAY
    endDelay: END_DELAY

  shouldComponentUpdate: (nextProps, nextState) ->
    propsDiff = not isEqual(@props, nextProps)
    stateDiff = not isEqual(@state, nextState)
    # console.log 'Props', @props, nextProps if propsDiff
    # console.log 'State', @state, nextState if stateDiff
    propsDiff or stateDiff

  componentDidMount: ->
    if @props.state
      @startTimeout(@props, @state)
    @setState isMounted: true

  componentWillUpdate: (nextProps, nextState) ->
    if not @state.loading and nextProps.state
      @startTimeout(nextProps, nextState)

    if not @state.ended and @props.state and not nextProps.state
      @endTimeout(nextProps)
    return

  startTimeout: (props, state) ->
    delay = @props.delay or DELAY
    @props.clear?()
    clearTimeout @tms if @tms?
    @tms = setTimeout =>
      hasResult = React.Children.count(props.children) isnt 0
      if ((state.ended or @state.ended)) and @state.isMounted
        @setState loading: false if state.loading
        return
      return if (hasResult and !@props.ignoreChildren) or !@state.isMounted
      @setState loading: true
    , delay

  endTimeout: (props) ->
    endDelay = 50
    clearTimeout @tme if @tme?
    @tme = setTimeout =>
      return if (@props.state or props.state) or !@state.isMounted
      @setState ended: true, loading: false
    , endDelay


  refresh: ->
    clearTimeout(@tme) if @tme?
    @setState(ended: false, loading: false)

  render: ->
    {children, loading} = @props

    load =
      if loading
        if typeof loading is 'function' then loading(el) else loading
      else
        spinner()

    isLoading = @props.state
    timeoutPassed = @state.loading

    args =
      if isLoading
        div className: 'app-spinner-outer',
          if timeoutPassed
            load
          else
            if @props.preserve
              div @props.ifEmpty
            else
              div ''
      else if React.Children.count(children) is 0 and @state.ended
        div className: 'app-spinner-outer',
          @props.ifEmpty
      else
        children

    if @props.outer
      @props.outer args
    else
      div className: "app-spinner-outer-content #{@props.className or ''}", args

module.exports = Spinner
