'use strict'
{section, div, ul, li, b, span, a, tch, fa} = Exim.DOM
GlobalClick = require 'components/mixins/GlobalClick'
ActionDropdown = require 'components/elements/ActionDropdown'
{observer} = require 'mobx-react'
{cx} = Exim.helpers
apptsStore = require 'stores/new-appts'
{flatMap, sumBy, uniq, uniqBy} = require 'lodash'

Item = (props) ->
  return tch {
    className: 'ActionDropdown-menuItem ActionDropdown-menuItem--noPadding ActionDropdown-menuItem--line ActionDropdown-menuItem--selectable',
    handler: props.onClick,
  },
    div className: 'ActionDropdown-menuItem-inner',
      span className: 'ActionDropdown-menuItemName', props.children
      fa 'check' if props.isSelected

ScheduleFilter = React.createClass({
  displayName: 'ScheduleFilter',
  mixins: [GlobalClick],

  globalClick: ->
    this.setState(this.getInitialState())

  getInitialState: ->
    return {
      isOpen: false,
      tab: 'filter',
    }

  toggleDropdown: ->
    this.setState({
      isOpen: !this.state.isOpen,
    })

  isRefined: ->
    {filter} = apptsStore
    return !!(filter.provider || filter.type)

  getCount: ->
    return sumBy(apptsStore.some, (prov) => prov.appointments.length)

  renderFilterTab: ->
    return div className: 'ActionDropdown-menu ActionDropdown-menu--large',
      this.renderSortStatus()
      this.renderProviderItems()
      this.renderSummaryItems()

  renderSortStatus: ->
    {sort} = apptsStore

    return tch {
      className: 'ActionDropdown-menuItem angle ActionDropdown-menuItem--tripleLine',
      handler: () => this.setState({tab: 'sort'}),
    },
      b 'Payment Status: '
      sort.label
      fa 'angle-right'

  renderProviderItems: ->
    {filter} = apptsStore
    totalCount = sumBy(apptsStore.all, (prov) => prov.appointments.length)

    return React.createElement(React.Fragment, null,
      React.createElement(Item, {
        isSelected: filter.provider == null,
        onClick: () => filter.provider = null,
      }, "All Providers (#{totalCount})")
      React.createElement(Item, {
        isSelected: filter.type == null,
        onClick: () => filter.type = null,
      }, "All Appointment Types (#{totalCount})")
    )

  renderSummaryItems: ->
    {all, filter} = apptsStore
    appts = flatMap(all, (prov) => prov.appointments)
    apptTypes = uniq(appts.map((appt) => appt.type_id))
    providers = uniqBy(all, 'name')

    return apptTypes.map((type) =>
      apptsCount = appts.filter((appt) => appt.type_id == type).length
      providerItems = providers.filter((provider) =>
        return provider.appointments.some((appt) => appt.type_id == type)
      ).map((provider) =>
        return React.createElement(Item, {
          key: provider.id,
          isSelected: filter.provider?.id == provider.id,
          onClick: () =>
            filter.provider = if filter.provider == provider then null else provider
        }, "#{provider.name} (#{provider.appointments.length})")
      )

      return div key: type,
        React.createElement(Item, {
          isSelected: filter.type == type,
          onClick: () =>
            filter.type = if filter.type == type then null else type
        }, "#{type} (#{apptsCount})")
        providerItems
    )

  renderSortTab: ->
    return div className: 'ActionDropdown-menu ActionDropdown-menu--large',
      tch {
        className: 'ScheduleFilter-back ActionDropdown-menuItemTop ActionDropdown-menuItem ActionDropdown-menuItem--tripleLine',
        handler: () => this.setState({tab: 'filter'}),
      },
        fa 'angle-left'
        ' Back'
      section className: 'ActionDropdown-list',
        b 'Sort by:'
        ul null, this.renderSorters()

  renderSorters: ->
    {sort, sorts} = apptsStore

    return sorts.map((s) =>
      isSelected = s.label == sort.label
      return tch {
        key: s.label,
        className: 'ActionDropdown-menuItem no-select',
        tagName: 'li',
        handler: () =>
          apptsStore.sort = s
          this.setState({
            isOpen: false,
            tab: 'filter',
          })
      },
        div className: "Radio Radio--gray #{cx selected: isSelected}"
        s.label
    )

  render: ->
    {isOpen, tab} = this.state

    return div className: "ActionDropdown ActionDropdown--refine #{cx 'with-dropdown': isOpen}",
      tch className: 'ScheduleFilter-refine ActionDropdown-title ActionDropdown-title--inlineAngle u-flex', handler: this.toggleDropdown,
        fa 'filter'
        span className: 'ScheduleFilter-refineLabel ActionDropdown-titleInner',
          if this.isRefined() then "Refined (#{this.getCount()})" else 'Refine'
        span className: 'ActionDropdown-inlineAngle',
          fa 'angle-down'
      tab == 'filter' && this.renderFilterTab()
      tab == 'sort' && this.renderSortTab()
})

module.exports = observer(ScheduleFilter)
