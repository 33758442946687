{cx}     = Exim.helpers
{div, select, option, optgroup} = Exim.DOM

PROPS_BLACKLIST = ['className', 'angle', 'idPath', 'nullPlaceholder']

Select = Exim.createView module.id,
  propTypes:
    values: React.PropTypes.array
    selected: React.PropTypes.oneOfType [React.PropTypes.object, React.PropTypes.string, React.PropTypes.number]
    idPath: React.PropTypes.string
    onChange: React.PropTypes.func

  getDefaultProps: ->
    values: []
    className: ''
    id: ''

  focus: ->
    @refs.outer.querySelector('select').focus()

  getInitialState: ->
    item = if typeof sel = @props.selected is 'object'
      @props.selected
    else if typeof sel = @props.selected is 'string'
      id = @_id
      @props.values.filter((v) -> id(v).toString() is sel)[0]
    selected: item

  _id: (i) ->
    p = @props.idPath
    if p then i[p] else i

  handleChange: (e) ->
    selection = e.currentTarget.value
    id = @_id
    selected = @props.values.filter((v) -> id(v).toString() is selection)[0]
    @select selected

  select: (selected) ->
    if selected
      @setState {selected}
      @props.onChange?(selected)

  toggleActive: (active=@state.active) -> (evt) =>
    @setState {active}

  render: ->
    id = @_id
    labelFn = @props.label
    props = Object.assign({}, @props, {label: null, values: null, ref: 'select', onChange: @handleChange, onFocus: @toggleActive(true), onBlur: @toggleActive(false)})
    props.value = id(@props.selected) if @props.selected
    {className} = @props
    PROPS_BLACKLIST.forEach (prop) ->
      delete props[prop]

    div ref: 'outer', className: "SimpleSelect #{className} #{cx 'SimpleSelect--angle': @props.angle, 'is-active': @state.active}",
      select props,
        if @props.nullPlaceholder
          option null
        if @props.placeholder
          option key: 'placeholder', value: '', disabled: true, selected: true, @props.placeholder
        if og = @props.optgroups
          og.items.map (group, i) =>
            unless group.hide
              optgroup label: group[og.label], key: group[og.label],
                group[og.list].map (_id) =>
                  @props.values.filter((value) -> id(value) is _id).map (val, i) ->
                    option key: id(val) + i, value: id(val), labelFn?(val) or val
        else
          @props.values.map (val, i) ->
            option key: id(val) + ' ' + i, value: id(val), labelFn?(val) or val

module.exports = Select
