import { features } from "config";
import { browserHistory } from "react-router";

const pathname = features.apptsOnly ? "appointments" : "profile";

export default async () => {
  const newAuthStore = require("stores/new-auth");
  const { authStore, messagesStore } = require("stores");

  newAuthStore.clearProxiedPatientFeatureFlags();
  await authStore.actions.finishImpersonation();
  messagesStore.actions.fetchMessages();

  let { id } = authStore.get("user");
  browserHistory.push({
    pathname,
    query: {
      patient_id: id,
    },
  });
};
