request = require 'lib/request'

module.exports = charges = Exim.createStore
  path: 'charges'
  actions: [
    'fetchPaymentData'
    'fetchFeatureFlags'
    'fetchCards'
    'addCard'
    'deleteCard'
    'updateCard'
    'charge'
    'deleteCharge'
    'addCharge'
    'setOwed'
    'clearError'
    'setChargeStatus'
  ]

  initial:
    paymentData: {}
    featureFlags: []
    cards: []
    amount: 0
    error: null
    updatingCard: false
    oneTimePaymentCardId: null
    addingCharge: false
    chargeAdded: false

  fetchPaymentData:
    on: (user_id='') ->
      request.get 'payment', {user_id}
    did: (paymentData) ->
      @set {paymentData}
    didNot: (response) -> Promise.resolve()#proceed on error

  fetchFeatureFlags:
    on: () ->
      request.get "v1/feature_flags"
    did: (response) ->
      @set {featureFlags: response.results}
    didNot: (response) -> Promise.resolve()

  fetchCards:
    on: (user_id='') ->
      request.get "users/#{user_id}/payment_methods"
    did: (cards) ->
      @set {cards}
    didNot: (response) -> Promise.resolve()

  addCard:
    while: (updatingCustomer) ->
      @set {updatingCustomer}
    on: (user_id='', card_label, card_brand, card_id, primary, last4, payment_token) ->
      request.post "users/#{user_id}/payment_methods", {card_label, card_brand, card_id, primary, last4, payment_token}
    did:    (response) -> @set oneTimePaymentCardId: response.id, error: null
    didNot: (response) -> @set error: response?.error

  deleteCard:
    on: (url='') ->
      request.del url
    did:    (response) -> @set error: null
    didNot: (response) -> @set error: response?.error

  updateCard:
    while: (updatingCard) ->
      @set {updatingCard}
    on: (url='', card_label, card_brand, payment_method_id, primary) ->
      request.put url, {card_label, card_brand, payment_method_id, primary}
    did:    (response) -> #@set error: null
    didNot: (response) -> @set error: response?.error

  charge:
    will: -> @set error: null
    on: (user_id, payment_method_id, amount, note, appointment_id, one_time_payment_token, one_time_payment, charge_ids,category) ->
      request.post 'payment_methods/charge', {user_id, payment_method_id, amount, note, appointment_id, one_time_payment_token, one_time_payment, charge_ids,item: category}
    did:    (response) -> @set error: null
    didNot: (response) -> @set error: response?.error

  deleteCharge:
    on: (charge_id) ->
      request.delete "charges/#{charge_id}", {id: charge_id}
    did:    (response) -> @set error: null
    didNot: (response) -> @set error: response?.error

  addCharge:
    while: (state) ->
      @set addingCharge: state
    on: (patient_id, amount, description, charge_date, category) ->
      request.post "patient/#{patient_id}/charges", {patient_id, amount, description, charge_date, item: category, status: 'unpaid'}
    did:    (response) ->
      @set error: null
      @set chargeAdded: true
    didNot: (response) -> @set error: response?.error

  setChargeStatus: ->
    @set chargeAdded: false

  setOwed:
    on: (patient_id, appointment_id, amount) ->
      request.post 'owed', {patient_id, appointment_id, amount}

  clearError: -> @set error: null
