"use strict";
const newAuthStore = require("stores/new-auth");
const { observer } = require("mobx-react");
const { withRouter } = require("react-router");
const { messagesStore, authStore } = require("stores");
const Badge = require("../badge");
const Link = require("../link");

const MESSAGES_PATH = "/messages";
const MessagesTab = React.createClass({
  displayName: "MessagesTab",
  mixins: [messagesStore.connect("unreadCount"), authStore.connect("user")],

  componentWillMount() {
    messagesStore.actions.fetchMessages("received", 1);
  },

  componentDidUpdate(prevProps, prevState) {
    if (prevState.user.id !== this.state.user.id) {
      messagesStore.actions.fetchMessages("received", 1);
    }
  },

  renderBadge() {
    return <Badge modifier="messages" count={this.state.unreadCount} />;
  },

  getTypeOfMessage() {
    const { pathname } = this.props.router.location;
    let typeOfMessage;

    switch (pathname) {
      case "/messages":
        typeOfMessage = "Inbox";
        break;
      case "/messages/sent":
        typeOfMessage = "Sent";
        break;
      case "/messages/archived":
        typeOfMessage = "Archived";
        break;
      default:
        typeOfMessage = null;
    }

    return { typeOfMessage };
  },

  keyBoardPress(searchInput) {
    return (evt) => {
      evt.preventDefault();

      const { typeOfMessage } = this.getTypeOfMessage();
      const keyCodes = [13, 17, 18, 32];
      const targetSideBarLink = evt.target
        .getElementsByTagName("span")
        .item(0).innerHTML;

      if (
        searchInput &&
        keyCodes.includes(evt.which) &&
        typeOfMessage === targetSideBarLink
      ) {
        searchInput.focus();
      }
    };
  },

  renderSubLinks() {
    const searchInput = document
      .getElementsByClassName("ContentSearch-input")
      .item(0);

    return (
      <React.Fragment>
        <Link
          to={MESSAGES_PATH}
          modifier="sub"
          onlyActiveOnIndex={true}
          onKeyUp={this.keyBoardPress(searchInput)}
        >
          <span className="SidebarNavLink-text">Inbox</span>
          {this.renderBadge()}
        </Link>
        <Link
          to={`${MESSAGES_PATH}/sent`}
          modifier="sub"
          onKeyUp={this.keyBoardPress(searchInput)}
        >
          <span className="SidebarNavLink-text">Sent</span>
        </Link>
        <Link
          to={`${MESSAGES_PATH}/archived`}
          modifier="sub"
          onKeyUp={this.keyBoardPress(searchInput)}
        >
          <span className="SidebarNavLink-text">Archived</span>
        </Link>
      </React.Fragment>
    );
  },

  render() {
    let { user } = newAuthStore;
    if (!user.canReadMessages) return null;

    let isOpen = this.props.router.isActive(MESSAGES_PATH);

    return (
      <li className="SidebarNav-item">
        <Link to={MESSAGES_PATH}>
          <span className="SidebarNavLink-icon">
            <span aria-hidden={true} className="icon fa fa-envelope" />
          </span>
          <span className="SidebarNavLink-text">Messages</span>
          {!isOpen && this.renderBadge()}
        </Link>
        {isOpen && this.renderSubLinks()}
      </li>
    );
  },
});

module.exports = withRouter(observer(MessagesTab));
