"use strict";
/* global Exim, React */
const { PropTypes, Fragment } = React;
import { configureSurvey } from "lib/utils";
import * as SurveyJS from "survey-react";

/*
READ ONLY SURVEY
Renders a read-only copy of a screening template, given prior response data
Is a standalone class, to enforce PropTypes and limit complexity of UI logic
*/
class ReadOnlySurvey extends React.Component {
  constructor(props) {
    super(props);

    configureSurvey(SurveyJS);
  }

  render() {
    const { template, data } = this.props;
    let model = new SurveyJS.Model(template);
    // Assign model properties (don't do this to template, namespace overlap within SurveyJS)
    Object.assign(model, {
      mode: "display",
      data: JSON.parse(data),
      // Disable rendundant UI
      showPageTitles: false,
      showQuestionNumbers: "off",
    });
    return (
      <section id="SurveyJS">
        <SurveyJS.Survey model={model} />
      </section>
    );
  }
}

ReadOnlySurvey.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    data: PropTypes.object,
  }),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
ReadOnlySurvey.defaultProps = {
  template: {
    id: null,
    name: null,
    status: null,
    data: {},
  },
  data: null,
};

module.exports = ReadOnlySurvey;
