{cx} = Exim.helpers
config = require 'config'

DBLCLICK_DELAY = 250

TouchClick = Exim.createView module.id,
  getInitialState: ->
    touched: false
    touchdown: false
    coords: { x:0, y:0 }
    evObj: {}

  getDefaultProps: ->
    classNames: ''
    node: 'div'
    overflow: []

  focus: ->
    @refs.elem?.focus()

  handler: ->
    typeof @props.handler == 'function' and @props.handler.apply(@, arguments)

  getCoords: (e) ->
    if e.touches && e.touches.length
      touch = e.touches[0]

      x: touch.pageX
      y: touch.pageY

  onTouchStart: (e) ->
    @setState
      touched: true
      touchdown: true
      coords: @getCoords(e)
      evObj: e
    @waitForDouble(e) if @props.double

  onTouchMove: (e) ->
    coords = @getCoords(e)
    distance = Math.max(
      Math.abs(@state.coords.x - coords.x)
      Math.abs(@state.coords.y - coords.y)
    )
    if distance > 6
      @setState touchdown: false

  onTouchEnd: (evt) ->
    if @props.alwaysHandle or @state.touchdown
      @handler.call @, @state.evObj
    @setState @getInitialState()

  onClick: (e) ->
    e.stopPropagation()
    e.preventDefault() if @props.href in ['', '#']
    return if config.isMobileBrowser
    # return if window.ontouchstart is null
    @setState(@getInitialState())
    @handler.apply(this, arguments)
    @waitForDouble(e) if @props.double

  waitForDouble: (e) ->
    if @state.waiting
      clearTimeout(@tm)
      @setState waiting: false
      @props.double(e)
    else if @props.double
      waitingForDouble = =>
        @setState waiting: false
      @tm = setTimeout waitingForDouble, DBLCLICK_DELAY
      @setState waiting: true

  onEnter: (e) ->
    if e.which is 13
      @props.onEnter?(e)

  render: ->
    classNames = ['touchclick']
    {overflow} = @props

    classNames.push(@props.className)
    classNames = classNames.concat overflow.map((side) -> "overflow-#{side}")
    classNames.push('touchdown') if @state.touchdown

    Exim.DOM[@props.tagName || 'div']
      id: @props.id
      className: classNames.join(' ')
      ref: 'elem'
      onTouchStart: @onTouchStart
      onTouchMove: @onTouchMove
      onTouchEnd: @onTouchEnd
      onClick: @onClick
      onKeyDown: @props.onKeyDown
      onKeyUp: @onEnter
      title: @props.title
      style: @props.style
      href: @props.href
      tabIndex: @props.tabIndex
      disabled: @props.disabled
      'aria-disabled': @props['aria-disabled']
      'aria-label': @props['aria-label']
      'aria-controls': @props['aria-controls']
      'aria-expanded': @props['aria-expanded']
      'aria-selected': @props['aria-selected']
      'aria-pressed': @props['aria-pressed']
      'aria-checked': @props['aria-checked']
      'aria-sort': @props['aria-sort']
      'aria-haspopup': @props['aria-haspopup']
      'aria-labelledby': @props['aria-labelledby']
      role: @props.role
    , @props.children

module.exports = TouchClick
