import React from "react";
import { Radio } from "components/elements";
import appStore from "stores/app";
import has from "lodash/has";
import config from "config";
import { observer } from "mobx-react";
import appointmentsStore from "stores/new-appointments";

const formatActiveEpisodesDate = (date) => {
  const zone = moment.tz.guess(true);
  const formattedDate = moment(date)
    .tz(zone)
    .format(config.formats.xopDateFormat);
  const zoneAbbr = moment.tz.zone(zone).abbr(moment(date).format("x"));

  return `${formattedDate} ${zoneAbbr}`;
};

export const PushToXOPModal = observer((props) => {
  const { patientName, visitType, activeEpisodes, appointmentId } = props;

  const handleOptionChange = (e) => {
    appointmentsStore.setEpisodeId(+e.target.value);
    appointmentsStore.setAppointmentId(appointmentId);
  };

  const handleRequestedByMemberChange = (e) => {
    appointmentsStore.setRequestedByMember(e.target.value);
  };

  return (
    <div className="push-to-xop-modal">
      <p>Patient Name: {patientName}</p>
      <p>Visit: {visitType}</p>
      <div className="active-episode-section">
        {activeEpisodes.map((episode) => (
          <Radio
            key={episode.id}
            name={episode.label}
            value={episode.id}
            checked={appointmentsStore.episodeId === episode.id}
            onChange={handleOptionChange}
          >
            {episode.label} ({formatActiveEpisodesDate(episode.created_at)})
          </Radio>
        ))}
        <Radio
          name="create_eoc"
          value="-1"
          onChange={handleOptionChange}
          checked={appointmentsStore.episodeId === -1}
        >
          Start a new Conversation
        </Radio>
      </div>
      <div className="requested-by-member">
        <p>Was this visit requested by a member?</p>
        <Radio
          name="requested_by_member"
          value="Yes"
          checked={appointmentsStore.requestedByMember === "Yes"}
          onChange={handleRequestedByMemberChange}
        >
          Yes
        </Radio>
        <Radio
          name="requested_by_member"
          value="No"
          checked={appointmentsStore.requestedByMember === "No"}
          onChange={handleRequestedByMemberChange}
        >
          No
        </Radio>
      </div>
    </div>
  );
});

export const PushToXOPModalFooter = observer(() => {
  const closePushToXOPModal = () => {
    closeModal();
    appointmentsStore.clearPushToXOPModalData();
  };

  const submitAction = () => {
    appointmentsStore
      .pushAppointmentToXOP()
      .then(() => {
        closePushToXOPModal();
      })
      .catch((e) => {
        closePushToXOPModal();

        if (has(e, "error") && e.status === 400) {
          showPushToXopErrorModal(e.error);
        }
      });
  };

  return (
    <div>
      <button
        className="Button Button--secondary"
        onClick={closePushToXOPModal}
      >
        Close
      </button>
      <button
        className="Button Button--primary"
        disabled={!appointmentsStore.canPushToXOP}
        onClick={submitAction}
      >
        {appointmentsStore.pushingToXOP ? "Pushing..." : "Confirm"}
      </button>
    </div>
  );
});

// helper methods
const closeModal = () => {
  appStore.actions.showModal(null);
};

const showPushToXopErrorModal = (errorMessage) => {
  appStore.actions.showModal({
    body: (
      <div style={{ maxWidth: 300 }}>
        <h2 style={{ marginTop: 0 }}>Error</h2>
        <p>{errorMessage}</p>
      </div>
    ),
    footer: (
      <div style={{ textAlign: "right" }}>
        <button className="Button Button--primary" onClick={closeModal}>
          Close
        </button>
      </div>
    ),
  });
};
