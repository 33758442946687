"use strict";
const authStore = require("stores/new-auth");
const oldAuthStore = require("stores/auth");
const { withRouter } = require("react-router");
const { closeModal } = require("components/elements/new-modal");
const IDLE_TIMEOUT = moment.duration(10, "minutes");

const withIdleTimer = (Component) => {
  class IdleTimer extends React.Component {
    constructor(props) {
      super(props);

      this.resetTimeout = () => {
        clearTimeout(this.id);
        this.id = setTimeout(this.redirectOrSignout, IDLE_TIMEOUT);
      };

      this.redirectOrSignout = () => {
        const { me } = authStore;
        if ((me && me.isAdmin) || authStore.isProxying) {
          this.props.router.push("/checkin/auth");
        } else {
          oldAuthStore.actions.signout();
        }

        closeModal(); // TODO: router should handle this
      };
    }

    componentDidMount() {
      this.id = setTimeout(this.redirectOrSignout, IDLE_TIMEOUT);
      document.addEventListener("pointermove", this.resetTimeout);
    }

    componentWillUnmount() {
      clearTimeout(this.id);
      document.removeEventListener("pointermove", this.resetTimeout);
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return withRouter(IdleTimer);
};

module.exports = withIdleTimer;
