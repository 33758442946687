"use strict";
const { observer } = require("mobx-react");
const calStore = require("stores/cal");

const { showModal } = require("components/elements/new-modal");
const ClearDateRangeModal = require("../helpers/delete/clear-date-range-modal");

const ClearRangeNav = () => {
  const filter = calStore.providerFilter.type;

  if (filter !== "BY_PROVIDER_ID") {
    return null;
  }

  return (
    <div
      className="ScheduleCalendarNavButton Button"
      onClick={() => showModal(<ClearDateRangeModal />)}
    >
      <i className="fa fa-eraser"></i>
      Clear
    </div>
  );
};

module.exports = observer(ClearRangeNav);
