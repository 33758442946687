{messagesStore, appStore, authStore, synopsisStore} = require 'stores'

RecipientsList = require './RecipientsList'
FileAttach     = require 'components/mixins/FileAttach'
Fields         = require 'components/mixins/Fields'
Spinner        = require 'components/elements/Spinner'
ActionDropdown = require 'components/elements/ActionDropdown'
DocumentPreview = require './DocumentPreview'
request        = require 'lib/request'
{compact, flatMap} = require('lodash')
oooStore = require('stores/ooo')
{Link} = require('react-router')
BackSwiper = require('components/elements/back-swiper')
{client} = require 'config'


fetchedProviderIds = []

{ isEdge, mimeTypes, maxFileSize } = require 'config'

{observer} = require('mobx-react')
utils = require 'lib/utils'
{cx} = Exim.helpers
MD_DOWN = 768
{div, input, h2, textarea, span, img, h3, fa, strong, tch, i, label, p, button} = Exim.DOM

NAV_WIDTH = 100

Create = React.createClass({
  displayName: 'CreateMessage',
  dataName: 'message'
  mixins: [
    appStore.connect('config')
    messagesStore.connect('recipients', 'recentProviderRecipients', 'allProviderRecipients', 'savedMessage', 'specialRecipients', 'messageSending', 'recipientsFetching')
    authStore.connect('user')
    synopsisStore.connect('sites', 'providers', 'allProviders')
    FileAttach
    Fields
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  required: ['subject', 'value', 'selected']

  getInitialState: ->
    selected: []
    value: ''
    showCC: false
    message: {} #utils.clone messagesStore.get('message')
    originalMessage: {}
    mode: null
    archiveOriginal: false
    uploadingAttachments: false

  getInitialData: ->
    @state.savedMessage or {
      from: {}
      to: []
      documents: []
    }

  componentWillMount: ->
    synopsisStore.actions.fetch()
    appStore.actions.setHeader(name: 'New Message')
    @id = @props.params.messageId

    @replying = !!@props.params.messageId
    @isResponse = @replying

    @page = @props.location.query.page
    @proxying = authStore.get('user').proxying

    if @replying
      if @props.location.pathname.includes("forward")
        console.log("forward")
        appStore.actions.setTitle("Forward Message")
      else
        if @props.location.pathname.includes("reply")
          console.log("reply")
          appStore.actions.setTitle("Reply to Message")
    else
      appStore.actions.setTitle("New Message")

    if !@proxying and !@replying
      if savedMessage = messagesStore.get('savedMessage')
        @setState {message: savedMessage, value: savedMessage.value}
      else
        messagesStore.actions.clearMessage().then =>
          message = messagesStore.get('message')
          @setState {message}

    if @initialRecipients = @props.location.query.recipients
      if @replying
        messagesStore.actions.fetchRecentProviderRecipients()
        messagesStore.actions.fetchAllProviderRecipients()
        if mid = @props.params.messageId
          messagesStore.actions.fetchMessage type: 'received', id: mid if +mid isnt messagesStore.get('message')?.id
      else if @proxying
        messagesStore.actions.fetchRecentProviderRecipients()
        messagesStore.actions.fetchAllProviderRecipients()
      messagesStore.actions.fetchRecipientsByIds(@initialRecipients)
    else
      messagesStore.actions.fetchRecentProviderRecipients()
      messagesStore.actions.fetchAllProviderRecipients()
      if mid = @props.params.messageId
        if +mid isnt messagesStore.get('message')?.id
          messagesStore.actions.fetchMessage type: 'received', id: mid

  componentWillUpdate: (newProps, newState) ->
    @isForward  = (newState.mode is 'forward')  or (!newState.mode and @context.router.isActive("messages/received/#{newProps.params.messageId}/forward"))
    @isReplyAll = (newState.mode is 'replyall') or (!newState.mode and @context.router.isActive("messages/received/#{newProps.params.messageId}/replyall"))
    @replying   =  (newState.mode in ['reply', 'replyall']) or (!newState.mode and !!newProps.params.messageId and not @isForward)

    @isResponse = @replying or @isForward

    modeChanged = newState.mode isnt @state.mode

    @refreshState(newState) if newProps.route.path isnt @props.route.path

    if (@isResponse) and !newState.message.id
      newState.message = messagesStore.get('message')
      original = utils.clone messagesStore.get('message'), ['attachments']
      original.attachments = newState.message.attachments
      newState.originalMessage = original

    if @initialRecipients and !@state.savedMessage
      recipients = newState.specialRecipients
      newState.message.selected = recipients

    targ = if @initialRecipients then 'cc' else 'selected'
    notFrom = (who) -> (item) -> item.id isnt who.id

    if modeChanged
      message = newState.originalMessage
      {from, to} = message
      to ?= []

      items = if @isReplyAll
        [from].concat(to.filter(notFrom(@state.user)).filter(notFrom(from)))
      else if @isForward
        []
      else
        [from]

      newState.message[targ] = items.map (item) ->
        label: item.name
        name: item.name
        value: "#{item.id}_#{item.name}"
        id: item.id
      newState.message.replyingSubject = @getReplyingSubject(newState.message.subject, if @isForward then 'FW' else 'RE')
      newState.message.subject = newState.message.replyingSubject unless newState.message.subject
      if @isForward
        newState.message.last_action = "forward"
      else if @isReplyAll
        newState.message.last_action = "replyall"
      else
        newState.message.last_action = "reply"

    if !modeChanged and @replying and ((!@state.message.id and newState.message.id) or (!@state.message[targ] and newState.message.id))
      {message} = newState
      {from, to} = message
      to ?= []
      items = if @isReplyAll then [from].concat(to.filter(notFrom(@state.user)).filter(notFrom(from))) else [from]
      message[targ] = items.map (item) ->
        label: item.name
        name: item.name
        value: "#{item.id}_#{item.name}"
        id: item.id
      message.replyingSubject = @getReplyingSubject(message.subject)
      message.subject = message.replyingSubject unless message.subject

      newState.message =
        subject: message.subject
        body: message.body
        id: message.id
        created_at: message.created_at
        received_at: message.received_at
        sent_at: message.sent_at
        from: message.from
        files: message.files or []
      newState.message[targ] = message[targ]
      newState.message.replyingSubject = message.replyingSubject

      @queueFocus()

    if !modeChanged and @isForward and newState.message.id and !newState.parsed
      newState.value = @getBody(newState.message)
      newState.message.replyingSubject = @getReplyingSubject(newState.message.subject, 'FW')
      newState.message.subject = newState.message.replyingSubject unless newState.message.subject
      newState.message.last_action = "forward"
      @addInitialFiles newState.message.attachments.map((attachment) -> {file: attachment}) unless newState.files?.length
      newState.parsed = true

    if !modeChanged and @replying and newState.message.id and !newState.parsed
      newState.value = @getBody(newState.message)
      newState.parsed = true
      if @isReplyAll
        newState.message.last_action = "replyall"
      else
        newState.message.last_action = "reply"

    newState.message.selected ?= @state.message.selected if @replying

  compareNameArrays: (msgName, provName) ->
    msgNameArr = msgName.toLowerCase().split(", ")
    provNameArr = provName.toLowerCase().split(", ")

    if msgNameArr.length == 1
      msgNameArr = msgName.toLowerCase().split(" (")

    if (msgNameArr == provNameArr) then return false
    if (msgNameArr == null || provNameArr == null) then return false
    if (msgNameArr.length == 0 || provNameArr.length == 0) then return false
    if (msgNameArr.length != provNameArr.length) then return false

    if (msgNameArr[0] == provNameArr[0])
      return true

    return false

  getProviderIds: () ->
    findByName = (s) => providers.find((prov) => this.compareNameArrays(s.name, prov.name))
    providers = flatMap(this.state.sites, (site) => site.providers)
    return compact((this.state.message.selected || []).map(findByName)).map((prov) => prov.user)

  componentDidUpdate: (props, oldState) ->
    provider_ids = this.getProviderIds()
    if provider_ids.length && !utils.isArrayEql(provider_ids, fetchedProviderIds)
      fetchedProviderIds = provider_ids
      oooStore.fetch({
        provider_ids,
      })

    if @replying and @state.message.id and !oldState.message.id
      body = @refs.body

  componentWillUnmount: ->
    clearTimeout(@tmFocus)
    window.removeEventListener('keyup', @lastHandler) if @lastHandler
    messagesStore.actions.clearMessage()
    unless @context.router.isActive("messages/received/#{@id}") or
           @context.router.isActive('messages/new')
      messagesStore.actions.clearSavedMessage()

  refreshState: (state, force) ->
    state.message = @getInitialData()
    state.files = []
    state.selected = []
    @setState state if force
    @refs.body.value = ''

  updateRecipients: (income, total) ->
    income = total if Array.isArray(total)
    type = if @initialRecipients and @proxying then 'cc' else 'selected'
    @updateData type, income

  toggleCC: ->
    @setState showCC: !@state.showCC

  composeGroupedRecipients: (recent=@state.recentProviderRecipients, all=@state.allProviderRecipients) ->
    recipientsList = []
    recipientsListIds = []

    if recent.length
      recipientsList.push {name: 'Recents', disabled: true}
      recents = recent.map (x) ->
        x.className = 'GroupedOption'
        return x
      recipientsList = recipientsList.concat recents

    recipientsList.forEach (user) -> recipientsListIds.push user.id if user.id

    all.forEach (site) ->
      allSiteRecipients = [].concat(site.admin).concat(site.users).concat(site.staff)
      recipientsTable = {}

      allSiteRecipients.map (recipient) ->
        while recipientsListIds.indexOf(recipient.id) >= 0
          recipient.id +=',null'
        recipientsListIds.push recipient.id

      allSiteRecipients = allSiteRecipients.filter (recipient) -> not recipient.hidden

      allSiteRecipients.forEach (user) ->
        if !recipientsTable.hasOwnProperty(user.type)
          recipientsTable[user.type] = [user]
        else
          recipientsTable[user.type].push user
      for key, list of recipientsTable
        locationLabel = site.location.toLowerCase().split(' ').map((x) -> x[0].toUpperCase() + x.slice(1)).join(' ')
        recipientsList.push {name: "#{locationLabel}#{if key is 'undefined' then '' else ' - ' + key}", disabled: true}
        list = list.map (x) ->
          x.className = 'GroupedOption'
          return x
        recipientsList = recipientsList.concat list

    return recipientsList

  checkMe: (user) ->
    if user.id is @state.user.id then 'Me' else user.name

  submit: (vrm) -> (evt) =>
    if @state.archiveOriginal
      @_submitAndArchive(evt, vrm)
    else
      @_submit(evt, vrm)

  _submit: (evt, vrm) ->
    evt.currentTarget.style.pointerEvents = 'none'

    if @isResponse
      @_send(vrm).then =>
        this.goBack()
      , => @setState sending: false
    else
      @_send(vrm).then =>
        messagesStore.actions.fetchMessages('received', 1) if @proxying
      this.goBack()

  handleArchiveCheckboxChange: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    @setState archiveOriginal: !@state.archiveOriginal

  _submitAndArchive: (evt, vrm) ->
    return unless @isResponse

    @_send(vrm).then =>
      @_archiveOriginal()
      this.goBack()
    , => @setState sending: false

  _send: (vrm) ->
    data = @getFields()

    return if @state.sending
    @setState sending: true

    {message, value, user} = @state

    msgParams = {}
    for key in Object.keys(message)
      msgParams[key] = message[key]

    msgParams.selected = message.selected.concat(message.cc) if message.cc
    to = []
    msgParams.selected.map((s) -> s.id).join(',').split(',').forEach (item) ->
      to.push(val) if (val = parseInt(item, 10)) and to.indexOf(val) < 0
    msgParams.to = to

    if @state.files.length
      msgParams.documents = @state.files.filter((item) -> !item.file?.id)

    msgParams.body = value
    msgParams.subject = msgParams.replyingSubject if @isResponse

    providerTypes = Object.keys(@state.config.provider_type_visit_replacement_code or {})

    if vrm
      prov = @findProvider(user.staff?.full_name || user.full_name)
      if prov.type and (prov.type in providerTypes) and (vrid = @state.config.provider_type_visit_replacement_code[prov.type])
        msgParams.vrid = vrid

    delete msgParams.selected

    messageToSave = utils.clone message, ['attachments']
    messageToSave.attachments = message.attachments
    messageToSave.value = value
    messagesStore.actions.saveMessage(messageToSave)

    onError = if @isResponse
      null
    else
      => @context.router.push pathname: '/messages/new'

    messagesStore.actions.send(msgParams, onError)

  _archiveOriginal: ->
    return unless @id

    message = @state.originalMessage
    data = {
      archived: true
      vrid: ''
    }
    messagesStore.actions.update('received', message, data)

  getReplyingSubject: (subject, prefix='RE') ->
    "#{prefix}: #{subject or ''}"

  getBody: (message) ->
    body = message.body.split(/\n/).map (line) ->
      "> #{line}"
    .join('\n')

    """



      On #{moment(message.received_at || message.sent_at).format('ddd, MMM DD, YYYY [at] hh:mm A')} - #{message.from.name} wrote:

      #{body}
    """

  setBody: (evt) ->
    {value} = evt.currentTarget
    @setState {value}

  onUploadClick: (e) ->
    e.stopPropagation()
    @refs.fileinput.click()

  onUploadKeyUp: (e) ->
    e.stopPropagation()
    @refs.fileinput.click()

  replace: (vrid) ->
    @setState {vrid}

  getCC: (message) ->
    if message.cc and message.selected.every((sel) -> sel.id isnt message.cc.id)
      message.cc
    else
      null

  componentDidMount: ->
    @queueFocus()

  queueFocus: ->
    @tmFocus = setTimeout(@focus)

  focus: ->
    {body, recipients, subject} = @refs
    if @isResponse && !@isForward
      if body
        body.focus()
        body.selectionStart = body.selectionEnd = 0
    else if recipients
      recipients.focus()
    else if subject
      subject.focus()

  prevent: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()

  startSwipe: (evt) ->
    @width = evt.currentTarget.clientWidth
    @startPos = evt.touches[0].pageX
    @startTime = new Date
    @setState swiping: true

  processSwipe: (evt) ->
    @moved = true
    pos = evt.touches[0].pageX
    return if Math.abs(pos - @startPos) < NAV_WIDTH
    @endPos = pos
    @diff = @endPos - @startPos
    @setState swipe: -@width + @endPos

  endSwipe: (doc) -> (evt) =>
    @setState swiping: false
    duration = new Date - @startTime
    if @moved and duration < 500 and (@diff > 150 or @diff < -150)
      if @diff < 0
        @open(doc.next)
      else
        @open(doc.prev)

    @diff = 0
    @moved = false
    @setState swipe: null

  deleteDoc: (doc) -> (evt) =>
    @prevent(evt)
    @onFileRemove(doc)(evt)
    appStore.actions.showModal(null)

  onKeyUp: (doc) -> (evt) =>
    switch evt.which
      when 37, 38 then @open(doc.prev)
      when 39, 40 then @open(doc.next)

  handleKeyUp: (doc) ->
    window.removeEventListener('keyup', @lastHandler) if @lastHandler
    @lastHandler = @onKeyUp(doc)
    window.addEventListener('keyup', @lastHandler)

  open: (doc) ->
    return unless doc

    @handleKeyUp(doc)
    url = doc.src or doc.file?.url
    mime = utils.humanizeMime(doc.file.type or doc.file.mime).toLowerCase()
    {admin, proxying} = @state.user
    if mime in ['image', 'video', 'pdf', 'document']
      className = 'Modal--noBodyPadding'
      body = DocumentPreview {doc, url, mime, @openInWindow, @deleteDoc, @open, @startSwipe, @processSwipe, @endSwipe}
      title = div null,
        strong utils.capitalize(mime)
        ": #{doc.file.name}"
      footer = div className: 'DocumentPreview-footer u-flex u-flexJustifyBetween',
        tch className: "Button Button--pointer Button--small prev u-flex u-flexAlignSelfStart #{cx 'is-disabled': !doc.prev}", handler: @previewDoc(doc.prev),
          'Previous'
        tch className: "Button Button--pointer Button--small next u-flex u-flexAlignSelfEnd #{cx 'is-disabled': !doc.next}", handler: @previewDoc(doc.next),
          'Next'
      modal = {title, body, footer, className, onClose: @toDocs}
      appStore.actions.showModal(modal)
    else
      @openInWindow(url)

  previewDoc: (doc) -> (evt) =>
    @prevent(evt)
    @open(doc)

  attachFiles: (evt) ->
    if isEdge then @onFileAttachInEdge(evt) else @onFileAttach(evt)

  removeFile: (item) -> (evt) =>
    if (evt.type == "click")
      @onFileRemove(item)(evt)
    else
      if (evt.type == "keypress" && (evt.key == "Enter" || evt.key == " "))
        @onFileRemove(item)(evt)
      else if (evt.keyCode == 32 || evt.charCode == 32 || evt.keyCode == 13 || evt.charCode == 13)
        @onFileRemove(item)(evt)


  getBackTo: ->
    {page} = this.props.location.query
    return {
      pathname: '/messages',
      query: {page},
    }

  goBack: ->
    this.props.router.push(this.getBackTo())

  onFileAttachInEdge: (evt) ->
    files = [].slice.call evt.currentTarget.files
    @setState uploadingAttachments: true
    Promise.all(files.map (file) =>
      form = new FormData()
      form.append 'file', file
      form.append 'show_preview', true
      request.form('attachments', form)).then (responseObjects) =>
        @setState uploadingAttachments: false
        @onFilesAttachPostUpload(responseObjects, files)

  openInWindow: (url) ->
    window.open(url, '_blank').focus()

  setMode: (mode) -> =>
    @setState {mode}

  getEmergencyNumber: ->
    {sites, user} = @state
    {default_site} = user
    if default_site
      site = sites?.find((s) -> s.code is default_site)
      return site?.emergency_number or '911'

    '911'

  findProvider: (key) ->
    providers = synopsisStore.get('allProviders')

    provider = null
    key = key.toLowerCase().replace(/\W+/g, "")
    providers.every (prov) ->
      if prov.name?.toLowerCase().replace(/\W+/g, "").indexOf(key) isnt -1
        provider = prov
        return false
      return true

    provider

  isProvider: (key) ->
    providerTypes = Object.keys(@state.config.provider_type_visit_replacement_code or {})
    provider = @findProvider(key)
    provider and provider.type and (provider.type in providerTypes)

  renderOOOWarning: ->
    defaultSite = @state.sites.find((site) => site.code == @state.user?.default_site)
    provider_ids = this.getProviderIds()
    OOOs = oooStore._OOOs.filter((ooo) =>
      return provider_ids.includes(ooo.provider.id) and ooo.range.start <= moment()
    )

    return if !OOOs.length

    div className: 'OOOWarning',
      div className: 'OOOWarning-icon',
        fa 'exclamation-triangle fa-lg'
      div style: {display: 'inline-block', maxWidth: '82%'},
        OOOs.map ({provider, range}) =>
          p key: provider.id, "#{provider.name} will be out of office through #{range.end.format('MM/DD/YYYY')}"
        p "If you need assistance before then, you can send your request to General Messages for #{defaultSite?.name} and someone from our staff will follow-up. You may also call your home wellness center at #{defaultSite?.dial}."

  renderProviderText: ->
    div className: 'CreateMessageCardFooter-note', style: {marginRight: '0px', fontSize: '11.7px'},
      if client == 'cork'
        "To ensure a timely response, your provider or another member of your care team will respond to this message. For urgent medical or psychiatric needs out of hours please contact SouthDoc on 0818 355 999 or dial 999 in an emergency."
      else
        "To ensure a timely response, your provider or another member of your care team will respond to this message."

  render: ->
    format = (date) -> moment(date).format('dddd, MMMM DD, YYYY [at] hh:mm A')
    {message, user, mode} = @state
    {admin, proxying} = user

    isProvider = @isProvider(user.staff?.full_name || user.full_name)

    isMobile = document.body.clientWidth < MD_DOWN
    showVrid = (admin or proxying)
    visits = @state.config.replaceable_visits

    visitsData = if !visits then [] else Object.keys(visits).map((key) =>
      id: key, name: visits[key], handler: => @replace(key)
    ).concat([{id: 'none', name: 'None', handler: => @replace('')}])

    actions = [
      {id: 'reply', name: 'Reply', icon: 'reply', handler: @setMode('reply')}
      {id: 'replyall', name: 'Reply All', icon: 'reply-all', handler: @setMode('replyall')}
      {id: 'forward', name: 'Forward', icon: 'share', handler: @setMode('forward')}
    ]
    selected = mode or if @isReplyAll
      'replyall'
    else if @isForward
      'forward'
    else if @replying
      'reply'
    else
      null

    disabled = if @required
      !@required.every (key) =>
        target = message[key] or @state[key]
        if Array.isArray(target)
          target.length
        else
          target

    sendMsgBtnStyle = if disabled and (client == 'microsoft' || client == 'docker') then {color: '#FFFFFF', backgroundColor: '#595959', opacity: '1', borderColor: 'unset'} else null

    div className: "CreateMessageCardWrapper #{cx replying: @replying, forward: @isForward}",
      React.createElement(BackSwiper, {to: this.getBackTo()})
      div className: 'MessageActions',
        React.createElement(Link, {
          className: 'Button Button--back',
          'aria-label': 'Cancel new message',
          to: this.getBackTo(),
        },
          span 'Cancel'
          span className: 'is-hidden-on-mobile', ' New Message' unless @isResponse
        )

      div className: "CreateMessageCard #{cx 'CreateMessageCard--replying': @isResponse}", onDragOver: @onDragOver, onDragLeave: @onDragLeave, onDrop: @onDrop,
        if @replying and message.id or !@replying
          div className: 'CreateMessageCardHeader-note', "NOTE: If this is a medical or psychiatric emergency, please call #{@getEmergencyNumber()}."
        if @replying
          [
            div className: 'CreateMessageCardTitle', key: 'card-title',
              h2 message.replyingSubject
              div className: 'CreateMessageCardTitle-actions',
                ActionDropdown
                  className: 'ActionDropdown--actions'
                  values: actions
                  selected: selected
                  disableSelected: true
            div className: 'MessageCardHeader', key: 'card-avatar',
              if user.proxying
                type = if @initialRecipients and @proxying then 'cc' else 'selected'

                [
                  div className: 'MessageCardHeader-line', key: 'specialRecipients',
                    div className: 'MessageCardHeader-name MessageCardHeader-name--Flex',
                      div className: 'MessageCardHeader-label', 'To:'
                      @state.specialRecipients?.map (user, i, arr) ->
                        sep = if i is arr.length-1 then '' else ', '
                        span key: "#{user.name}-label", className: 'MessageCardHeader-label', "#{user.name}#{sep}"
                  div className: 'MessageCardHeader-line', key: 'header-name',
                    div className: "MessageCardHeader-name #{cx 'u-flex': @state.showCC}",
                      div className: 'MessageCardHeader-label', 'CC:'
                      message[type]?.map((user) => @checkMe(user)).join(', ') unless @state.showCC

                      tch className: 'MessageCardHeader-add-cc', tagName: 'a', handler: @toggleCC,
                        unless @state.showCC
                          [
                            strong key: 'pl', '+'
                            span key:'add', ' Add CC'
                          ]
                        else
                          'Hide'

                      if @state.showCC
                        RecipientsList
                          recipients: @composeGroupedRecipients()
                          onChange: @updateRecipients
                          label: (rec) -> rec.name
                          idPath: (rec, i) -> rec.id + '_' + rec.name + i
                          value: @getCC(message)
                          description: 'CC'
                ]
              else
                [
                  div className: 'MessageCardHeader-soloField MessageCardHeader-name', key: 'card-to',
                    div className: 'CreateMessageCardSection-label', 'To:'
                    div className: 'CreateMessageCardSection-field',
                      RecipientsList
                        recipients: if @initialRecipients then @state.specialRecipients else @composeGroupedRecipients()
                        onChange: @updateRecipients
                        label: (rec) -> rec.name
                        idPath: (rec, i) -> rec.id + '_' + rec.name + i
                        value: message.selected
                        disabled: !!@initialRecipients
                      @renderProviderText()
                      @renderOOOWarning()
                  if !!@initialRecipients
                    div className: 'MessageCardHeader-soloField MessageCardHeader-name', key: 'card-cc',
                      div className: 'CreateMessageCardSection-label', 'CC:'
                        RecipientsList
                          recipients: @composeGroupedRecipients()
                          onChange: @updateRecipients
                          label: (rec) -> rec.name
                          idPath: (rec, i) -> rec.id + '_' + rec.name + i
                          value: @getCC(message)
                          description: 'CC'
                ]
          ]
        else if @isForward
          [
            div className: 'CreateMessageCardTitle', key: 'card-title',
              h2 message.replyingSubject
              div className: 'CreateMessageCardTitle-actions',
                ActionDropdown
                  className: 'ActionDropdown--actions'
                  values: actions
                  selected: selected
                  disableSelected: true
            div className: 'MessageCardHeader', key: 'card-avatar',
              div className: 'MessageCardHeader-content'
              div className: 'MessageCardHeader-soloField MessageCardHeader-name',
                div className: 'CreateMessageCardSection-label', 'To:'
                div className: 'CreateMessageCardSection-field',
                  RecipientsList
                    ref: 'recipients'
                    recipients: if @initialRecipients then @state.specialRecipients else @composeGroupedRecipients()
                    onChange: @updateRecipients
                    label: (rec) -> rec.name
                    idPath: (rec, i) -> rec.id + '_' + rec.name + i
                    value: message.selected
                    disabled: !!@initialRecipients
                    description: 'To'
                  @renderProviderText()
                  @renderOOOWarning()
              if !!@initialRecipients
                div className: 'MessageCardHeader-soloField MessageCardHeader-name',
                  div className: 'CreateMessageCardSection-label', 'CC:'
                    RecipientsList
                      recipients: @composeGroupedRecipients()
                      onChange: @updateRecipients#if @replying and !!@initialRecipients then null else @updateRecipients
                      label: (rec) -> rec.name
                      idPath: (rec, i) -> rec.id + '_' + rec.name + i
                      value: @getCC(message)
                      description: 'CC'
          ]
        else
          [
            div className: 'CreateMessageCardSection', key: 'to-section',
              div className: 'CreateMessageCardSection-label', 'To:'
              div className: 'CreateMessageCardSection-field',
                if @proxying and @state.recipientsFetching
                  input className: 'Input Input--expand CreateMessageCardSection-fieldInput', defaultValue: authStore.get('user').legal_and_preferred_name
                else
                  RecipientsList
                    ref: 'recipients'
                    recipients: if @initialRecipients then @state.specialRecipients else @composeGroupedRecipients()
                    onChange: @updateRecipients
                    label: (rec) -> rec.name
                    idPath: (rec, i) -> rec.id + '_' + rec.name + i
                    value: message.selected
                    disabled: !!@initialRecipients
                    description: 'To'
                @renderProviderText()
                @renderOOOWarning()

            if @proxying and !!@initialRecipients
              div className: 'CreateMessageCardSection', key: 'cc-section',
                div className: 'CreateMessageCardSection-label', 'CC:'
                div className: 'CreateMessageCardSection-field',
                  RecipientsList
                    recipients: @composeGroupedRecipients()
                    onChange: if @replying and !!@initialRecipients then null else @updateRecipients
                    label: (rec) -> rec.name
                    idPath: (rec, i) -> rec.id + '_' + rec.name + i
                    value: message.cc
                    description: 'CC'

            div className: 'CreateMessageCardSection', key: 'subject-section',
              div className: 'CreateMessageCardSection-label',
                'Subject:'
              div className: 'CreateMessageCardSection-field',
                input 'aria-label': 'Subject', type: 'text', className: 'Input u-fullWidth', value: message.subject, ref: 'subject', onChange: @onDataChange('subject')
          ]
        if @replying and message.id or !@replying
          {value} = @state
          div className: 'CreateMessageCardSection',
            if !@replying and !@isForward
              div className: 'CreateMessageCardSection-label',
                'Message:'
            div className: 'CreateMessageCardSection-field',
              textarea 'aria-label': 'Message body', className: 'Input Input--expand', value: value, onChange: @setBody, ref: 'body'#, autoFocus: @isAutoFocus() #, onFocus: @onFocus
        if @state.dragging
          div className: 'textarea-input-hover-file',
            h3 'Drop Files To Attach'
        div className: "Attachments #{cx 'Attachments--fullWidth': @isResponse}",
          @state.attachmentErrors.map (error, i) =>
            div key: "err#{i}", className: 'error', error
          @state.files.map (item, i, docs) =>
            item.prev = docs[i-1]
            item.next = docs[i+1]
            key = item?.file?.name or item.src
            type = item?.file?.mime?.toLowerCase() or item?.file?.type?.toLowerCase()
            tch className: 'Attachment', key: "#{key} #{i}", handler: @previewDoc(item),
              div className: 'Attachment-image',
                if type?.startsWith 'image'
                  img alt: "attachment for #{item.file?.name}", src: item.file.url or item.src
                else
                  fa utils.getIconName(type)
              div className: 'AttachmentText',
                div item.file?.name
                div utils.humanizeSize item.file.size if item.file?.size
              unless item.file?.id
                div className: 'AttachmentHeader',
                  button className: 'AttachmentHeader-close', 'aria-label': "Delete #{item.file?.name}", onClick: @removeFile(item), onKeyPress: @removeFile(item), fa 'times'

        div className: "CreateMessageCardFooter #{cx 'CreateMessageCardFooter--new': !@isResponse}",
          Spinner state: @state.uploadingAttachments, ignoreChildren: true,
            tch tabIndex: 0, className: 'AttachmentButton', handler: @onUploadClick, onEnter: @onUploadKeyUp, 'aria-label': 'Attach file', role: 'button',
              fa 'paperclip'
              span id: 'attach-file',
                'Attach file...'
            input className: 'invisible', ref: 'fileinput', type: 'file', onChange: @attachFiles, multiple: true, tabIndex: -1, 'aria-labelledby': 'attach-file'
          Spinner state: @state.messageSending or @state.sending, className: 'CreateMessageCardFooterButtons', ignoreChildren: true,
            if isProvider
              tch tagName: 'a', href: '#', className: "Button #{cx {'is-disabled': disabled}}", handler: @submit(true),
                'Send and Replace Visit'
            tch tagName: 'a', href: '#', 'aria-label': "Send Message #{cx {', disabled': disabled}}", role: 'button', tabIndex: (if disabled then -1 else 0), className: "Button Button--action #{cx {'is-disabled': disabled}}", style: sendMsgBtnStyle, handler: @submit(false), onEnter: @submit(false),
              fa('envelope')
              'Send Message'
          if @isResponse
            div className: "CreateMessageCardFooter-archiveOriginal",
              tch tagName: 'a', href: '#', 'aria-label': 'Archive original message when sending', role:"checkbox", 'aria-checked': @state.archiveOriginal, className: 'Checkbox', handler: @handleArchiveCheckboxChange,
                input id: 'checkboxArchive', type: 'checkbox', tabIndex: -1, className: 'Checkbox-input', checked: @state.archiveOriginal
                div className: 'Checkbox-rect'
              label htmlFor: 'checkboxArchive', className: "CreateMessageCardFooter-archiveOriginalLabel", 'Archive original message when sending'
})

module.exports = observer(Create)
