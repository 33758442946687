"use strict";
const Model = require("./base");
const User = require("models/provider");
const synopsisStore = require("stores/new-synopsis");
const authStore = require("stores/new-auth");
const { computed } = require("lib/utils");

class Note extends Model {
  static fromAPI(raw) {
    // TODO: replace with `synopsisStore.findSite(raw.clinic_id)`
    let { site } = synopsisStore;

    return super.fromAPI({
      id: raw.id,
      text: raw.note,
      type: raw.note_type || "text",
      author: User.fromAPI({
        id: raw.created_by,
        name: raw.author_name,
      }),
      createdAt: moment.tz(raw.created_at, site.zone),
    });
  }

  constructor(params) {
    let { site } = synopsisStore;

    super({
      text: "",
      type: "text",
      author: authStore.me,
      createdAt: site.now,
      appointment: null,
      ...params,
    });
  }

  toJSON() {
    return {
      ...super.toJSON(),
      note: this.text,
      note_type: this.type == "text" ? null : this.type,
      created_at: this.createdAt,
      created_by: this.author.id,
      author_name: this.author.name,
      appointment_id: this.appointment.id,
    };
  }

  updateFromAPI(raw) {
    this.id = raw.appointment_notes.pop().id; // TODO
  }

  async post() {
    await super.post({
      path: `/v1/appointments/${this.appointment.id}/notes`,
      data: {
        note: this.text,
      },
    });

    this.appointment.notes.push(this);
  }

  destroy() {
    let { notes } = this.appointment;
    let index = notes.findIndex((note) => note.is(this));
    if (index != -1) notes.splice(index, 1);
  }
}

Note.endPoint = "/v1/appointment-notes";

computed(Note.prototype, {
  get isText() {
    return this.type == "text";
  },

  get isDeletable() {
    let { me } = authStore;

    return this.isText && me.canReadCalendar && me.id == this.author.id;
  },

  get isSaveable() {
    return this.isText && !!this.text.trim();
  },
});

module.exports = Note;
