{appStore, authStore, checkinStore} = require 'stores'

attachHeader = require 'components/mixins/attachHeader'
{cx} = require 'lib/utils'
defaultRouteRe = /\/checkin\/steps\/.+|\/schedule/

{div, h2, h3, a, p, button, fa, img, footer} = Exim.DOM

Pin = Exim.createView module.id,
  mixins: [
    appStore.connect('lastLocation', 'config')
    authStore.connect('user', 'error', 'checkingPin', 'pinCorrect')
    attachHeader(null)
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    pin: []

  componentWillMount: ->
    authStore.actions.clearPin()

  componentDidMount: ->
    document.body.addEventListener('keydown', @onKeyDown)

  componentWillUnmount: ->
    document.body.removeEventListener('keydown', @onKeyDown)

  componentDidUpdate: (_, newState) ->
    return if @state.pinCorrect is newState.pinCorrect

    {router} = @context
    checkinState = checkinStore.get('checkinState')
    if checkinState in ['lock', 'list', 'thanks']
      checkinStore.actions.changeState('list').then =>
        authStore.actions.clearPin()
        redirectTarg = @props.location.query?.redirect_to or ''
        router.push pathname: if redirectTarg then "/#{redirectTarg}" else '/schedule'
    else if checkinState is 'welcome'
      checkinStore.actions.changeState(checkinState, {}, true).then =>
        authStore.actions.clearPin()
        router.goBack()
    else if checkinState is 'screen'
      checkinStore.actions.changeState('list', {}, true).then =>
        authStore.actions.clearPin()
        router.goBack()
    else
      checkinStore.actions.changeState('welcome', {}, true).then =>
        checkinStore.actions.changeState(checkinState).then =>
          authStore.actions.clearPin()
          router.goBack()

  cancel: (evt) ->
    evt.preventDefault()
    {router} = @context
    checkinStore.actions.changeState('lock').then =>
      {user} = @state
      if !@state.lastLocation or defaultRouteRe.test @state.lastLocation.pathname
        @setState error: 'pin'
      else
        router.goBack()

  signOut: (evt) ->
    evt.preventDefault()
    authStore.actions.signout().then =>
      @context.router.push {pathname: '/signin'}

  onKeyDown: (evt) ->
    evt.preventDefault()
    {which} = evt
    index = [48..57].indexOf(which)
    if index isnt -1
      @add(index)
    else if which is 8
      @del()

  add: (num) ->
    pin = @state.pin.concat(num)
    @setState {pin}
    return if pin.length < 4

    pinString = pin.join ''
    authStore.actions.checkPin(pinString).catch =>
      @clear()
      @setState {error: 'pin'}

  clear: ->
    @setState {
      pin: []
    }

  del: ->
    @setState {
      pin: @state.pin.slice(0, -1)
    }

  renderLogo: ->
    {assets = {}} = this.state.config

    return img Object.assign({className: 'PinCard-logo', alt: ''}, assets.logo_on_white)

  renderDots: ->
    div className: 'PinCard-pin-dots',
      [0..3].map (key) =>
        className = cx 'PinCard-pin-dot',
          hasValue: key of @state.pin

        div {className, key}

  renderKeyboard: ->
    pinButton = (num) =>
      button {
        className: 'PinKeyboard-button',
        onClick: () => @add(num),
      }, num

    return div className: 'PinKeyboard',
      div className: 'PinKeyboard-row',
        pinButton(1)
        pinButton(2)
        pinButton(3)
      div className: 'PinKeyboard-row',
        pinButton(4)
        pinButton(5)
        pinButton(6)
      div className: 'PinKeyboard-row',
        pinButton(7)
        pinButton(8)
        pinButton(9)
      div className: 'PinKeyboard-row',
        button className: 'PinKeyboard-button', disabled: true
        pinButton(0)
        button className: 'PinKeyboard-button PinKeyboard-clear', onClick: @clear

  render: ->
    className = cx('PinCard', {
      error: this.state.error && !this.state.pin.length,
    })

    return div className: 'Splash Splash--auth view flipped-section pin u-noselect',
      div {className},
        this.renderLogo()
        h3 className: 'PinCard-title', 'Enter PIN'
        this.renderDots()
        this.renderKeyboard()
        footer className: 'PinCard-footer',
          button className: 'PinCard-signOut', onClick: @signOut,
            fa 'sign-out'
            'Sign Out'

module.exports = Pin
