toBe = React.PropTypes
{div, img, spinner} = Exim.DOM
{cx} = Exim.helpers
utils = require 'lib/utils'

Img = Exim.createView module.id,
  propTypes:
    className: toBe.string
    type: toBe.string
    onLoad: toBe.func
    onError: toBe.func
    onClick: toBe.func
    alt: toBe.string

  getInitialState: ->
    loaded: false
    error: false

  normSrc: ({type, src}) ->
    if type is 'avatar' then utils.getAvatarUrl(src) else src

  componentWillUpdate: (nextProps) ->
    if @normSrc(nextProps) isnt @normSrc(@props)
      @setState loaded: false, error: false

  onLoad: ->
    @setState loaded: true, error: false
    @props.onLoad?()

  onError: ->
    @setState loaded: true, error: true
    @props.onError?()

  render: ->
    hidden = !@state.loaded
    imgSrc = @normSrc(@props)
    srcArray = imgSrc.split('/')
    imgName = srcArray[srcArray.length - 1]

    imgProps = Object.assign({}, @props, {
      src: imgSrc
      alt: @props.alt || imgName || 'uploaded image',
      hidden
      @onLoad
      @onError
    })

    div className: "#{@props.className or ''} img-comp #{cx(loaded: @state.loaded, error: @state.error)}", onClick: @props.onClick,
      spinner('loader') if hidden
      img(imgProps)

module.exports = Img
