authStore = require 'stores/auth'
request   = require 'lib/request'
utils     = require 'lib/utils'
synopsisStore = require('stores/new-synopsis')

messagesCache  = require('lib/cache').messages
module.exports = messages = Exim.createStore
  path: 'messages'

  actions: [
    'fetchMessages'
    'fetchMessage'
    'fetchRecipients'
    'fetchRecentProviderRecipients'
    'fetchAllProviderRecipients'
    'searchMessages'
    'send'
    'update'
    'fetchRecipientsByIds'
    'clearMessage'
    'clearSavedMessage'
    'saveMessage'
    'clear'
    'setFilter'
    'clearFilter'
    'displayTZ'
  ]

  initial:
    received:
      response: []
    sent:
      response: []
    archived:
      response: []
    flagged:
      response: []
    unread:
      response: []

    filter: []

    message:
      from: {}
      to: []
      documents: []

    recipients: []
    recentProviderRecipients: []
    allProviderRecipients: []
    specialRecipients: []

    messageFetching: true
    messagesFetching: false

    receivedMessagesFetched: false
    sentMessagesFetched: false
    archivedMessagesFetched: false

    unreadCount: 0

    zoneAbbr: ''

  fetchRecentProviderRecipients:
    while: (recipientsFetching) ->
      @set {recipientsFetching}
    on: ->
      request.get('messages/my-recent-providers')
    did: (recipients) ->
      @set {recentProviderRecipients: recipients.recent.users}

  fetchAllProviderRecipients:
    while: (recipientsFetching) ->
      @set {recipientsFetching}
    on: ->
      request.get('messages/my-providers')
    did: (recipients) ->
      {admin, proxying, default_site} = authStore.get('user')
      filteredRecipients = []

      for site, location of recipients
        if admin or proxying
          location.admin = []
        else
          location.staff = []

        if site is default_site
          filteredRecipients.unshift location
        else
          filteredRecipients.push location

      @set {allProviderRecipients: filteredRecipients}

  fetchRecipients:
    while: (recipientsFetching) ->
      @set {recipientsFetching}
    on: ->
      request.get('messages/recipients')
    did: (recipients) ->
      {admin, proxying} = authStore.get('user')
      recipients = recipients.map (location) ->
        if admin or proxying
          location.admin = []
        else
          location.staff = []
        location
      @set {recipients}

  fetchRecipientsByIds:
    while: (recipientsFetching) ->
      @set {recipientsFetching}
    on: (ids) ->
      ids = ids.join(",") if (ids and typeof ids is 'object' and ids instanceof Array)
      request.get("patients/#{ids}")
    did: (specialRecipients) ->
      @set {specialRecipients}

  fetchMessages:
    while: (messagesFetching) ->
      @set {messagesFetching}
    on: (@type='received', page=1, @allowCaching, sort, @filterBy) ->
      path = if @type in ['sent', 'received'] then @type else 'received'

      filter = if @type in ['flagged', 'unread']
        @type
      else if @filterBy
        @filterBy
      else
        false

      @archived = @type is 'archived'

      if @allowCaching and items = messagesCache[@type]?.all
        return items
      opts = {page, reverse: !sort?.asc, filter, @archived}
      if sort?.type
        opts.sort = sort?.type

      request.get("messages/#{path}", opts)
    did: (messages) ->
      result = {}
      result.unreadCount = messages.unread if @type is 'received' and not @filterBy
      result[@type] = messages
      result["#{@type}MessagesFetched"] = true if @allowCaching
      @set result
      messagesCache[@type] ?= {}
      messagesCache[@type].all = messages

  searchMessages:
    while: (messagesFetching) ->
      @set {messagesFetching}
    on: (type, query, page) ->
      @type = type
      query ?= ''
      page ?= 1
      path = if type in ['sent', 'received'] then type else "all/#{type}"
      request.get("messages/#{path}/search", {page, query})
    did: (messages) ->
      @set @type, messages

  fetchMessage:
    while: (messageFetching) ->
      @set {messageFetching}
    on: (args) ->
      @args = args
      cached = messagesCache[args.type]?[args.id]
      if cached and not args.skipCache
        Promise.resolve(cached)
      else
        request.get("messages/#{args.type}/#{args.id}")
    did: (message) ->
      message.documents ?= []
      messagesCache[@args.type] ?= {}
      messagesCache[@args.type][message.id] ?= message
      @set {message}

  send:
    while: (messageSending) ->
      @set {messageSending}
    on: (message, @onError, @silent) ->
      promise = Promise.resolve()
      if message.documents?.length > 0
        uploads = []
        message.documents.forEach (item) ->
          promise = promise.then (res) ->
            uploads.push res if res
            form = new FormData
            form.append 'file', item.file
            request.form('attachments', form)

        promise = promise.then (res) ->
          uploads.concat [ res ]

      promise.then (uploadRes=[]) ->
        message.attachments = uploadRes.map (doc) -> doc.id
        delete message.documents
        request.post('messages', message)

    did: (message) ->
      @actions.clearMessage()
      @actions.clearSavedMessage() if message
      return message
    didNot: (err) ->
      @onError()
      alert('Failed to send the message') unless @silent

  update:
    while: (messageUpdating) ->
      @set {messageUpdating}
    on: (type, message, data, cacheDataOnly) ->
      if data
        Object.keys(data).forEach ( key ) ->
          message[key] = data[key]
      else
        data = message

      @message = message
      @data = data
      @type = type
      @cacheDataOnly = cacheDataOnly

      attachments = @message.attachments
      delete @message.attachments
      @updatedMessage = utils.clone(@message)
      @updatedMessage.attachments = attachments

      @set {message: @updatedMessage} unless @cacheDataOnly
      request.put("messages/#{type}/#{message.id}", data)
    did: ->
      data = @data
      messagesCache[@type] ?= {}
      if @cacheDataOnly
        if cached = messagesCache[@type][@updatedMessage.id]
          Object.keys(data).forEach (key) ->
            cached[key] = data[key]
          messagesCache[@type][@updatedMessage.id] = cached
          @set {message: cached}
      else
        messagesCache[@type][@updatedMessage.id] = @updatedMessage
        @set {message: @updatedMessage}

  saveMessage: (savedMessage) ->
    @set {savedMessage}

  clearMessage: ->
    current = @get('message')
    current.selected = null
    @set({message: {from: {}, to: [], documents: []}, recipients: [], specialRecipients: []})

  clearSavedMessage: ->
    @set savedMessage: null

  clear: ->
    initial =
      received:
        response: []
      sent:
        response: []
      archived:
        response: []
      filter: []

    @set initial

  setFilter: (filter) ->
    @set {filter}

  clearFilter: ->
    @set filter: []

  displayTZ: ->
    user = authStore.get('user')
    site = synopsisStore.findSite(user.clinic_id)
    zoneAbbr = site && utils.timezone(site.zone)
    @set zoneAbbr: zoneAbbr
