"use strict";
const { observer } = require("mobx-react");
const { cx } = require("lib/utils");
const { ISO_DATE } = require("lib/formats");
const calStore = require("stores/cal");

const dayFormat = "D";
const dateFormat = `MMM ${dayFormat}`;

const renderDate = () => {
  switch (calStore.mode) {
    case "day":
      return renderDay(calStore.date);
    case "week":
      return renderRange(calStore.dateRange);
  }
};

const renderDay = (date) => {
  return (
    <time dateTime={date.format(ISO_DATE)}>{date.format(dateFormat)}</time>
  );
};

const renderRange = ({ start, end }) => {
  let endFormat = start.isSame(end, "month") ? dayFormat : dateFormat;

  return (
    <React.Fragment>
      <time dateTime={start.format(ISO_DATE)}>{start.format(dateFormat)}</time>{" "}
      &ndash;{" "}
      <time dateTime={end.format(ISO_DATE)}>{end.format(endFormat)}</time>
    </React.Fragment>
  );
};

const renderAngles = () => {
  let { mode } = calStore;

  return (
    <div className="ScheduleCalendarDateNav-angles">
      <button
        className={cx("ScheduleCalendarDateNav-angle", "left")}
        aria-label={`View previous ${mode}`}
        onClick={() => calStore.prev()}
      >
        <span className="fa fa-angle-left" />
      </button>
      <button
        className={cx("ScheduleCalendarDateNav-angle", "right")}
        aria-label={`View next ${mode}`}
        onClick={() => calStore.next()}
      >
        <span className="fa fa-angle-right" />
      </button>
    </div>
  );
};

const DateNav = () => {
  let { mode } = calStore;

  return (
    <div className="ScheduleCalendarDateNav">
      <span className={cx("ScheduleCalendarDateNav-date", mode)}>
        {renderDate()}
      </span>
      {renderAngles()}
      <button
        className="ScheduleCalendarDateNav-now"
        disabled={calStore.isNow()}
        onClick={() => calStore.now()}
      >
        {mode == "day" ? "Today" : "Current week"}
      </button>
    </div>
  );
};

module.exports = observer(DateNav);
