ReactDOM = require 'react-dom'
{authStore, appStore, patientStore, clinicsStore} = require 'stores'

config   = require 'config'
utils    = require 'lib/utils'
Validate = require 'components/mixins/Validate'
Tooltip  = require 'components/elements/Tooltip'
Disclaimer = require 'components/auth/Disclaimer'
ConfirmationModal = require 'components/elements/ConfirmationModal'
{proxyPatient} = require 'lib/utils'

{Link} = Exim.Router
{cx}   = Exim.helpers
{div, p, spinner, input, label, span, button, form, tch, select, option, hr, h3, h4, ul, li} = Exim.DOM
detailsKeys = ['first_name', 'last_name', 'dob', 'gender', 'employee_id', 'email','dob_m', 'dob_d', 'dob_y', 'employer', 'clinic_id', 'xop_eligible']
amazonAlertEfIds = ['161', '172','173', '174', '176', '177', '178', '179', '180', '181']

ForcePatientModal = Exim.createView module.id,
  mixins: [
    Validate
    clinicsStore.connect('clinics')
  ]

  listen: [
    'auth/error', 'auth/lookingUp',
    'app/config',
    'synopsis/site', 'synopsis/sites',
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    state = {}
    if data = @props.location?.query
      detailsKeys.forEach (key) ->
        state[key] = data[key] if data[key]
    if data = @props.data
      detailsKeys.forEach (key) ->
        state[key] = data[key] if data[key]
    state.tooltip = {}
    state.displayEmailWarning = false
    state.emailWarning = ''
    state.warningForClient = ''
    state.xop_eligible = false
    state

  componentDidMount: ->
    @domain = appStore.get('config')?.signup?.domain or 'work'
    @forceUpdate()

  componentWillUpdate: (newProps, newState) ->
    if @state.employer isnt newState.employer
      clinicsStore.actions.fetchClinics(newState.employer)

  forcePatient: (e) ->
    e.preventDefault()
    data =
      first_name:   @state.first_name
      last_name:    @state.last_name
      dob:          moment(@state.dob, 'MM/DD/YYYY').format('YYYY-MM-DD')
      email:        @state.email
      employee_id:  @state.employee_id
      employer:     @state.employer
      gender:       @state.gender?.toUpperCase()[0]
      clinic_id:    @state.clinic_id
      relationship: @state.relationship
      xop_eligible: @state.xop_eligible

    for key in [].concat ['dob_d', 'dob_m', 'dob_y'], Object.keys data
      @validate key, (key is 'employee_id')

    if @state.errors?.length
      @forceUpdate()
    else
      patientStore.actions.forcePatient(data).then((response) =>
        proxyPatient(response[0], {isForced: true})
        @props.handleModalClose()
      )
    return

  goToSignin: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    @context.router.push pathname: '/signin'

  dobFields: ->
    [
      div key: 'dob-label', className: @errClass('dob_m', 'dob_d', 'dob_y'),
        label className: 'dob-label', htmlFor: 'dob_m', 'Date of Birth'
      div key: 'dob-fields', className: 'dob-wrap',
        @unlabeledSelect 'dob_m', 'Month', @months, {html: {classes: 'dob_m dob-wrap-dob_m SimpleSelect SimpleSelect--angle SimpleSelect--auth'}, defaultValue: @state.dob_m}
        @unlabeledSelect 'dob_d', 'Day',   @days,   {html: {classes: 'dob_d dob-wrap-dob_d SimpleSelect SimpleSelect--angle SimpleSelect--auth'}, defaultValue: @state.dob_d}
        @unlabeledSelect 'dob_y', 'Year',  @years,  {html: {classes: 'dob_y dob-wrap-dob_y SimpleSelect SimpleSelect--angle SimpleSelect--auth'}, defaultValue: @state.dob_y}
    ]

  closeModal: ->
    @props.handleModalClose()

  getEmployers: ->
    {clients} = appStore.get('config')

    Object.keys(clients).map((key) -> {value: key, label: clients[key]}).sort (a,b) =>
      if a.label < b.label
        return -1
      else if a.label > b.label
        return 1
      else
        return -1

  getSitesList: ->
    if @state.employer
      {clinics, employer} = @state
      clinics.map((clinic) ->
        {label: clinic.name, value: clinic.id}
      )
    else
      []

  validateEmail: ->
    {email} = @state
    {validations} = @state.config?.signup

    if !!email and validations?.email
      validationMatch = null
      validations.email.forEach (validation_check) =>
        validationCheck = email.match(validation_check?.check)
        if (!!validationCheck)
          validationMatch = {message: validation_check?.message, client: validation_check?.client, match: [validationCheck...]}

      if validationMatch and @state.displayEmailWarning is false
        @setState {displayEmailWarning: true, emailWarning: validationMatch.message, warningForClient: validationMatch.client}
        return
      else if !validationMatch and @state.displayEmailWarning is true
        @setState {displayEmailWarning: false, emailWarning: '', warningForClient: ''}
    else if @state.displayEmailWarning is true
      @setState {displayEmailWarning: false, emailWarning: '', warningForClient: ''}

  showDependentsModal: ->
    body = ConfirmationModal
      title: 'Account Activation for Dependents'
      text: "Please enter the primary policy holder's work email address in the Company Email Address field. This will allow us to confirm eligibility.\n\nPlease use your own email address to create your account"
      onConfirm: @props.reopen(@state)
      confirmText: 'Close'

    modal =
      className: 'Modal--small'
      body: body
      onClose: @props.reopen(@state)

    appStore.actions.showModal(modal)

  handleEmployerChange: (evt) ->
    employer = evt.target.value
    if (employer in amazonAlertEfIds)
      @props.handleAmazonAlertOpen()

  render: ->
    {validations} = @state.config?.signup
    config = appStore.get('config')
    style = {display: 'inherit', padding: 0}
    amazonEmployerSelected = @state.employer in ["161", "172", "173", "174", "176", "177", "178", "179", "180", "181"]

    div {className: 'SignupSection ForcePatientSignupSection--details', style},
      form className: 'card-logo', onSubmit: @forcePatient, ref: 'container', autoComplete: 'off',
        h3 'Add a new Patient'
        if title = @state.config?.signup?.force_patient_subhead
          h4 title

#         if err = @state.error
#           p className: 'error', err

        div className: 'input-wrapper',
          div className: 'ForcePatientModal--row',
            @labeledInput 'first_name', 'Patient First Name'
            @labeledInput 'last_name', 'Patient Last Name'

          div key: 'info-email', className: 'ForcePatientModal--row',
            div {},
              @labeledInput 'email','Email of Eligible Employee', placeholder: "Your #{@domain} email", type: 'email', onChange: @validateEmail()
              div className: 'SignupSection-preferredEmailInfo', 'What is the email address of the primary insurance holder (not the dependent)?'
              if @state.displayEmailWarning and @state.emailWarning and @state.warningForClient != 'amazon'
                div className: 'SignupSection-validationWarning', dangerouslySetInnerHTML: {__html: @state.emailWarning}
          div key: 'dob-wrap', className: 'ForcePatientModal--row',
            div {},
              @dobFields()
            div {},
              label className: 'gender-label', htmlFor: 'gender', 'Gender'
              @unlabeledSelect 'gender', 'Select one',
                [
                  {value: 'male', label: 'Male'},
                  {value: 'female', label: 'Female'},
                  {value: 'other',  label: 'Other/Intersex'}
                ],
                html: classes: 'relationship eid-wrap-relationship SimpleSelect SimpleSelect--angle SimpleSelect--auth'

          div key: 'eid-wrap', className: 'ForcePatientModal--row',
            div {},
              div className: "#{@errClass('employer')}",
                label htmlFor: 'employer', 'Employer'
              @unlabeledSelect 'employer', 'Employer', @getEmployers(), onChange: @handleEmployerChange, html: classes: "relationship eid-wrap-relationship SimpleSelect SimpleSelect--angle SimpleSelect--auth"

            if amazonEmployerSelected
              @labeledInput 'employee_id', 'Alias', html: {classes: "ForcePatientModal--employee-id"}, {isOptional: true}
            else
              @labeledInput 'employee_id', 'Employee ID', html: {classes: "ForcePatientModal--employee-id"}, {type: 'number', isOptional: true}

            div {},
              div className: @errClass('relationship'),
                label htmlFor: 'relationship', 'Patient\'s Eligibility'
              @unlabeledSelect 'relationship', 'Patient\'s Eligibility',
                [
                  { value: 'SE', label: 'Employee' },
                  { value: 'SP', label: 'Dependent' }
                ],
                html: classes: 'relationship eid-wrap-relationship SimpleSelect SimpleSelect--angle SimpleSelect--auth'

          div className: 'force-XOPEligible ForcePatientModal--row',
            div className: 'force-XOPEligible-clinic',
              div className: @errClass('clinic_id'),
                label htmlFor: 'default-clinic', 'Default Clinic'
              @unlabeledSelect 'clinic_id', 'Default Clinic', @getSitesList(), { disabled: !@state.employer, html: classes: 'SimpleSelect SimpleSelect--angle SimpleSelect--auth SimpleSelect--no-margin' }
              div 'Please select an Employer before assigning a default clinic'

            div className: 'force-XOPEligible-options',
              div className: @errClass('xop_eligible'),
                label htmlFor: 'xop_eligible', 'XOP Eligible?'
              @unlabeledSelect 'xop_eligible', '',
                [
                  { value: 'false', label: 'No' },
                  { value: 'true', label: 'Yes' }
                ],
                { html: { classes: 'SimpleSelect SimpleSelect--angle SimpleSelect--auth' }, defaultValue: 'No' }

          div className: 'force-makeSureWarn ForcePatientModal--row',
            span 'Make certain to:'
            ul
              li "Verify the patient's identity (employee badge, identification, etc.)"
              li 'Confirm the above email address belongs to the Eligible Employee'

          div className: 'ForcePatientModal--row ForcePatientModal--actions',
            button className: 'Button Button--large Button--auth', onClick: @closeModal,
              'Cancel'
            button id: 'add-patient-button', className: 'Button Button--primary Button--large Button--auth', type: 'submit',
              'Add New Patient'

module.exports = ForcePatientModal
