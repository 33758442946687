request = require 'lib/request'
mdUtils = require 'lib/md-utils'

module.exports = store = Exim.createStore
  path: 'changelog'
  actions: [
    'fetch'
  ]

  initial:
    preview: {}
    items: []

  fetch:
    while: (changelogLoading) ->
      @set {changelogLoading}
    on: ->
      request.get('changes')
    did: ({text}) ->
      this.set({
        items: mdUtils.splitItems(text),
        preview: mdUtils.getPreview(text),
      })

