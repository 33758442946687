"use strict";
const authStore = require("stores/new-auth");
const { observer } = require("mobx-react");
const Link = require("../link");

const DocumentsLink = () => {
  let { user } = authStore;
  if (!user.canReadDocuments) return null;

  return (
    <li className="SidebarNav-item">
      <Link to="/documents">
        <span className="SidebarNavLink-icon">
          <span aria-hidden={true} className="icon fa fa-file-text-o" />
        </span>
        <span className="SidebarNavLink-text">Documents</span>
      </Link>
    </li>
  );
};

module.exports = observer(DocumentsLink);
