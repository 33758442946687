{chargesStore, appointmentsStore} = require 'stores'
appStore = require 'stores/new-app'
Spinner  = require 'components/elements/Spinner'
Checkbox = require 'components/elements/Checkbox'
Select   = require 'components/elements/Select'
media    = require 'components/mixins/MediaQuery'
chargesStore = require 'stores/charges'
{observer} = require('mobx-react')
{tch, div, span, input, form, label, fa, i, textarea, table, thead, th, tbody, tr, td} = Exim.DOM
{cx} = Exim.helpers

cardNumRegex = /^(\d{0,4}\s?){0,4}$/

StripeModal = React.createClass({
  displayName: 'StripeModal',
  mixins: [
    media(hideLabel: 'paymentModalDesktopSm'),
    chargesStore.connect('error', 'cards', 'oneTimePaymentCardId'),
  ]

  getInitialState: ->
    amount: (@props.amount/100).toFixed(2)
    category: 'Medicine'
    note: ''
    loading: false
    cardNumber: ''
    expiryYear: ''
    expiryMonth: ''
    cvc: ''
    errors: []
    expiry: ''
    showCardDialog: false
    paymentToken: null
    last4: @props.digits
    saveCard: false
    withBody: false
    cardType: 'personal'
    cardSelected: null
    cardSelectedInfo: null
    cardOptions: []
    hideLabel:  767 < document.body.clientWidth < 880
    unpaidCharges: @props.unpaidCharges or []
    copayAmount: @props.amount
    editingCopay: false
    owedChargeId: null

  componentWillMount: ->
    chargesStore.actions.clearError()
    if @props.unpaidCharges?.length
      {id, unpaidCharges, amount} = @props
      {copayAmount} = @state

      if apptCharge = unpaidCharges.find((charge) -> charge.appointment_id is id)
        copayAmount = apptCharge.amount

      unpaidCharges = unpaidCharges.filter((charge) -> charge.appointment_id isnt id)
      totalOwed = @props.amount
      totalOwed += charge.amount for charge in unpaidCharges
      @setState {amount: (totalOwed/100).toFixed(2), unpaidCharges, copayAmount}

  componentDidMount: ->
    chargesStore.actions.fetchFeatureFlags()
    chargesStore.actions.fetchCards(@props.user_id).then =>
      @updateCardDropdown()

  componentDidUpdate: (prevProps, prevState) ->
    if prevState.editingAmount isnt @state.editingAmount and @state.editingAmount
      @refs.amountInput.focus()
    if @state.withBody and not prevState.withBody
      fn = => @refs.category?.focus()
      setTimeout fn, 10

  handleKeyDown: (evt) ->
    if evt.which is 13
      @toggleEditAmount(false)(evt)

  updateAmount: (evt) ->
    {value} = evt.currentTarget
    @setState amount: value

  updateNote: (evt) ->
    {value} = evt.currentTarget
    @setState note: value

  updateCategory: (option) ->
    @setState category: option.value

  updateCardDropdown: () ->
    cardOptions = @composePaymentOptions @state.cards
    primaryCardId = @state.cards.filter((card) -> card.primary)[0]?.id
    cardInfo = @state.cards.filter((card) -> card.id is primaryCardId)[0] if primaryCardId
    @setState { cardOptions, cardInfo, cardSelected: primaryCardId }

  charge: (oneUsePaymentToken) ->
    {id, user_id, once, patient_id, providerId, oneTimePayment} = @props
    {amount, category, note, paymentToken, saveCard, cardSelected, oneTimePaymentCardId} = @state
    amountUpdated = if oneTimePayment then amount isnt @props.amount / 100 else @props.amount isnt @state.copayAmount

    @setState loading: true

    amount = Math.floor(amount * 100)
    chargeIds = @state.unpaidCharges?.map (charge) -> charge.id
    chargeIds.push @state.owedChargeId if @state.owedChargeId
    if amountUpdated
      if oneTimePayment
        chargesStore.actions.charge(user_id, cardSelected or oneTimePaymentCardId, amount, note, id, oneUsePaymentToken, oneTimePayment, null, category).then =>
          @setState success: true
          @props.update(null, null, null, !once) if @props.update
        , =>
          @setState loading: false
      else
        chargesStore.actions.charge(user_id, cardSelected or oneTimePaymentCardId, amount, note, id, oneUsePaymentToken, oneTimePayment, chargeIds).then =>
          @setState success: true
          @props.update(null, null, null, !once) if @props.update
        , =>
          @setState loading: false
    else
      appointmentsStore.actions.generateApptCharge(id, patient_id, @state.copayAmount).then (data) =>
        chargeIds.push(data[0].id)
        chargesStore.actions.charge(user_id, cardSelected or oneTimePaymentCardId, amount, note, id, oneUsePaymentToken, oneTimePayment, chargeIds).then =>
          @setState success: true
          @props.update(null, null, null, !once) if @props.update
        , =>
          @setState loading: false

  updateCardNumber: (evt) ->
    { value } = evt.currentTarget
    return unless cardNumRegex.test value
    return if value.length > 19
    if value.length > @state.cardNumber.length
      value =
        switch value.length
          when 4, 9, 14 then "#{value} "
          else value
    @updateData('cardNumber', value)

  blurCardNumber: (evt) ->
    { cardNumber } = @state
    if cardNumber
      @validateInput('cardNumber')(evt)

  updateCvc: (evt) ->
    {value} = evt.currentTarget
    return unless /^\d{0,4}$/.test value
    return if value.length > 4
    @updateData('cvc', value)

  updateExpiryMonth: (evt) ->
    {value} = evt.currentTarget
    wasNumber = !isNaN(+value.slice(0, -1))
    return if wasNumber and (not /^\d{0,2}\s?$/.test(value) or value.length > 2)
    @updateData('expiryMonth', value)
    if value.length is 2
      @refs['exp-year'].focus()

  blurExpiryMonth: (evt) ->
    { expiryMonth, expiryYear } = @state
    currValue = evt.currentTarget.value
    validMonth = parseInt(currValue) > 0 and parseInt(currValue) <= 12
    if currValue? and +currValue isnt 0 and currValue.toString().length is 1
      updated = "0#{currValue}"
      @updateData('expiryMonth', updated)
    if expiryMonth and expiryYear or !validMonth and expiryMonth
      @validateInput('expiryMonth')(evt)

  updateExpiryYear: (evt) ->
    {value} = evt.currentTarget
    nan = isNaN(value)
    return if value.length > 4 or nan
    @updateData('expiryYear', value)
    if value.length is 4
      @refs.cvv.focus()

  blurExpiryYear: (evt) ->
    { expiryYear } = @state
    currValue = evt.currentTarget.value
    if currValue?
      updated = switch currValue.toString().length
        when 2 then "20#{currValue}"
        when 1 then "201#{currValue}"
        else false
      @updateData('expiryYear', updated) if updated
    if expiryYear
      @validateInput('expiryMonth')(evt)
      @validateInput('expiryYear')(evt)

  blurCVC: (evt) ->
    fields = [
      'cardNumber'
      'expiryMonth'
      'expiryYear'
      'cvc'
    ]
    i = 0
    while i < fields.length
      @validateInput(fields[i])(evt)
      i++

  updateData: (name, value) ->
    {errors} = @state
    errName = if name in ['expiryMonth', 'expiryYear'] then 'expiry' else name
    if (index = errors.indexOf(errName)) >= 0
      errors.splice(index, 1) if @check(errName, value)
    state = {}
    state[name] = value
    state['errors'] = errors
    @setState state

  check: (name, value) ->
    {card} = appStore.stripe
    {cardNumber, expiryMonth, expiryYear, cvc} = @state
    switch name
      when 'cardNumber'
        card.validateCardNumber(cardNumber)
      when 'expiry'
        card.validateExpiry(expiryMonth, expiryYear)
      when 'cvc'
        card.validateCVC(cvc)

  validateInput: (name, value) -> (evt) =>
    {errors, error} = @state
    chargesStore.actions.clearError() if error
    errName = if name in ['expiryMonth', 'expiryYear'] then 'expiry' else name
    if @check(errName, @state[name])
      if (index = errors.indexOf(errName)) >= 0
        errors.splice(index, 1)
    else
      errors.push errName unless errName in errors

    @setState {errors}


  stripeResponseHandler: (oneTimePayment) -> (status, response) =>
    {saveCard} = @state
    if response.error
      @setState errors: ['card']
    else
      if oneTimePayment and saveCard
        @saveCard(response).then =>
          @charge()
      else if oneTimePayment and !saveCard
        @setState paymentToken: response, loading: false, last4: response.card.last4
        @charge(response.id)
      else if !oneTimePayment and !saveCard
        @setState paymentToken: response, loading: false, last4: response.card.last4
        @charge(response.id)
      else if !oneTimePayment and saveCard
        @saveCard(response).then =>
          @charge()

  saveCard: (response) ->
    {user_id} = @props
    {card} = response
    chargesStore.actions.addCard(user_id, null, card?.brand, card?.id, true, card?.last4, response.id).then =>
      chargesStore.actions.fetchCards(@props.user_id).then =>
        @updateCardDropdown()

  updateAndClose: (evt) ->
    {id, patient_id, providerId} = @props
    {amount} = @state
    amount = if amount is '' then false else amount * 100
    chargesStore.actions.setOwed(patient_id, id, amount).then =>
      if @props.update
        @props.update(providerId, id, amount).then =>
          @props.close?()
      else
        @props.close?()

  cancel: (evt) ->
    @props.close?()

  back: (evt) ->
    @setState addCard: false

  submitCard: (evt) ->
    {oneTimePayment} = @props
    {cardNumber, expiryMonth, expiryYear, cvc, saveCard, cardInfo, oneTimePaymentCardId} = @state
    if @state.cardSelected and !@state.addCard
      @setState loading: true
      @charge()
    else
      errors = @validate()

      if errors.length
        @setState {errors}
        return false

      @setState loading: true

      appStore.stripe.card.createToken({
        number: cardNumber
        cvc: cvc
        exp_month: expiryMonth
        exp_year: expiryYear
      }, @stripeResponseHandler(oneTimePayment))


  chargeDisabled: ->
    {cardNumber, cvc, last4, amount, category, note} = @state
    return false if @state.cardSelected and amount > 0 and amount < 600
    errors = @validate()
    !!errors.length or amount is "0.00" or (category == 'other' and !note) or (!category)

  validate: ->
    {card} = appStore.stripe
    {cardNumber, expiryMonth, expiryYear, cvc} = @state
    errors = []
    errors.push 'cardNumber' unless card.validateCardNumber(cardNumber)
    errors.push 'cvc' unless card.validateCVC(cvc)
    errors.push 'expiry' unless card.validateExpiry(expiryMonth, expiryYear)
    errors

  toggleSaveCard: ->
    @setState saveCard: !@state.saveCard

  toggleEditAmount: (value=!@state.editingAmount) -> (evt) =>
    return unless @props.oneTimePayment
    evt.stopPropagation()
    @setState editingAmount: value

  stopPropagation: (evt) ->
    evt.stopPropagation()

  toggleBody: ->
    withBody = !@state.withBody
    @setState {withBody}

  onTypeSelect: (type) -> =>
    @setState cardType: type

  updatePaymentOption: (option) ->
    if option.value is 'add'
      @setState addCard: true
    else if option.value is 'remove'
      @removeCard()
    else
      cardInfo = @state.cards.find((card) -> card.id is option.value)
      @setState {cardInfo, cardSelected: option.value}

  composePaymentOptions: (cards) ->
    {hideLabel} = @state
    options = []
    cards.forEach (card) ->
      cardName = card.card_label or card.card_brand
      option =
        value: card.id
        label: if hideLabel then "****#{card.last4}" else cardName
        labelRight: if hideLabel then '' else "****#{card.last4}"
        icon: if card.card_label is 'HSA/FSA' then 'credit-card-alt' else "cc-#{card.card_brand?.toLowerCase()}"
      options.push option
    options = options.concat({value: 'add', label: "Add New Card", icon: 'plus'})
    return options

  removeCharge: (chargeId) -> =>
    charges = @state.unpaidCharges.filter (charge) -> charge.id isnt chargeId
    totalOwed = @state.copayAmount
    totalOwed += charge.amount for charge in charges
    @setState amount: (totalOwed/100).toFixed(2), unpaidCharges: charges

  editCopay: ->
    @setState editingCopay: true

  onCopayEdit: (e) ->
    value = e.target.value
    return if isNaN value

    if value[value.length - 1] is '.'
      @setState copayAmount: (value * 100) + '.'
    else
      @setState copayAmount: value * 100

  confirmCopayAmount: ->
    return if isNaN @state.copayAmount
    {id, patient_id, providerId, oneTimePayment} = @props
    totalOwed = @state.copayAmount
    totalOwed += charge.amount for charge in @state.unpaidCharges

    chargesStore.actions.setOwed(patient_id, id, @state.copayAmount).then (data) =>
      @props.update(providerId, id, @state.copayAmount) if @props.update
      @setState amount: (totalOwed/100).toFixed(2), editingCopay: false, owedChargeId: data[0].charge_id

  render: ->
    {amount, category, note, loading, showCardDialog, errors, last4, paymentToken, saveCard, editingAmount, error, success, cardType, addCard} = @state
    {paymentDisabled, oneTimePayment, once, currency, digits, name, dob, sexage} = @props
    error = 'Amount must be under $600' if @state.amount >= 600

    div className: 'PaymentModal',
      unless success
        [
          div className: 'PaymentModalAmountSection', key: 'amount-section', onClick: @toggleEditAmount(false),
            div className: 'PaymentModalAmountSection-title', 'Total Due'
            div className: 'PaymentModalAmountSection-amount',
              if editingAmount
                div className: 'InputAddonWrapper InputAddonWrapper--large',
                  div className: 'InputAddon', currency
                  input ref: 'amountInput', className: 'Input Input--expand amount', onClick: @stopPropagation, type: 'text', placeholder: '10', 'data-e2e': 'total-due-input', value: amount, onChange: @updateAmount, onKeyDown: @handleKeyDown
              else
                div className: 'PaymentModalAmountSection-value', onClick: @toggleEditAmount(true), "#{currency}#{amount}"
          unless paymentDisabled
            div className: "PaymentModalCardSection #{cx withBody: @state.withBody}", key: 'card-section',
              tch className: 'PaymentModalHeader', handler: @toggleBody,
                span 'Process Payment'
                span style: {fontSize: '14px', display: 'block'}, "#{name} • #{dob} • #{sexage}"
              div className: 'PaymentModalBody',
                unless !oneTimePayment
                  div className: 'PaymentModalBody-title', 'Type'
                  div className: 'PaymentModalBody-notes',
                    Select
                      options: [
                        {value: 'Medicine', label: 'Medicine'},
                        {value: 'Late Cancel/Missed Appointment Fee', label: 'Late Cancel/Missed Appointment Fee'},
                        {value: 'Health Item', label: 'Health Item'},
                        {value: 'Travel Consult', label: 'Travel Consult'},
                        {value: 'Visit Fee', label: 'Visit Fee'}]
                      value: @state.category
                      onChange: @updateCategory
                      matchProp: 'label'
                      clearable: false
                      backspaceRemoves: true
                      placeholder: 'Select Category'
                      searchable: false
                      savedHover: true
                      key: 'option-category'
                div className: 'PaymentModalBody-title', "Notes #{if category == 'other' then '(required)' else ''}"
                div className: 'PaymentModalBody-notes',
                  textarea className: 'Input Input--expand', ref: 'notes', type: 'text', value: note, onChange: @updateNote
                if @state.cardOptions.length == 1 or addCard
                  [
                    div className: 'PaymentModalBody-title', key: 'new-card', 'Add New Card'
                    div className: 'InputAddonWrapper', key: 'card-name',
                      div className: 'InputAddon',
                        fa 'credit-card'
                      input ref: 'card-n', type: 'text', id:'ccnumber', name:'cardNumber', autoComplete: 'cc-number', className: "Input Input--expand #{cx error: 'cardNumber' in errors}", placeholder: 'Card number', 'data-stripe': 'number', value: @state.cardNumber, onChange: @updateCardNumber, onBlur: @blurCardNumber, ref: 'ccnumber'
                    div className: 'PaymentModal-row', key: 'control-row',
                      input id:'cc-exp-month', name: 'cc-exp-month', type: 'number', min: '0', max: '12', ref: 'exp-month', autoComplete: 'cc-exp-month', className: "Input Input--expand #{cx error: 'expiry' in errors}", placeholder: 'MM', value: @state.expiryMonth, onChange: @updateExpiryMonth, onBlur: @blurExpiryMonth
                      input id:'cc-exp-year', type: 'number', min: '0', ref: 'exp-year', autoComplete: 'cc-exp-year', className: "Input Input--expand #{cx error: 'expiry' in errors}", placeholder: 'YY', value: @state.expiryYear, onChange: @updateExpiryYear, onBlur: @blurExpiryYear
                      div className: 'InputAddonWrapper',
                        div className: 'InputAddon',
                          fa 'lock'
                        input type: 'text', ref: 'cvv', 'data-e2e': 'cvc-input', autoComplete: 'cc-csc', className: "Input Input--expand #{cx error: 'cvc' in errors}", placeholder: 'CVC', 'data-stripe': 'cvc', value: @state.cvc, onChange: @updateCvc, onBlur: @blurCVC, 'aria-label': 'CVC Security Code'
                    div className: 'PaymentModalBody-save', key: 'body-save',
                      Checkbox size: 'small', onChange: @toggleSaveCard
                      div className: 'PaymentModalBody-save-label', 'Save card on file.'

                  ]
                else
                  [
                    div className: 'PaymentModalBody-title', key: 'card-title', 'Payments on File'
                    Select
                      className: 'Select--iconed Select--large'
                      options: @state.cardOptions
                      value: @state.cardSelected
                      onChange: @updatePaymentOption
                      matchProp: 'label'
                      clearable: false
                      backspaceRemoves: true
                      placeholder: 'Select Payment'
                      searchable: false
                      savedHover: true
                      key: 'option-select'
                  ]
                div className: 'error', error if error
                div className: 'PaymentModalFooter',
                  if !addCard
                    div className: 'Button Button--cancel Button--pointer', 'data-e2e': 'Exit-Modal', onClick: @cancel, 'Cancel'
                  else
                    div className: 'Button Button--cancel back Button--pointer', 'data-e2e': 'Exit-Add-New-Card', onClick: @back, 'Back'

                  if loading
                    div className: 'Button Button--primary Button--pointer is-disabled',
                      fa 'spinner fa-circle-o-notch fa-spin'
                  else
                    div className: "Button Button--primary Button--pointer #{cx 'is-disabled': @chargeDisabled()}", id: 'charge-card', onClick: @submitCard, 'Charge Card'

          if !@props.once
            [
              div className: 'ItemizedCharges-header', 'Itemized Charges'
              div className: 'ItemizedCharges',
                div style: {margin: '24px'},
                  table className: 'NewTable', style: {width: '100%'},
                    tbody {},
                        tr className: 'ChargeRow',
                          td style: {width: '70%'}, 'Appointment Fee'
                          if @state.editingCopay
                            [
                              td {},
                                div className: 'InputAddonWrapper CopayEditInput',
                                  div className: 'InputAddon', currency
                                  input className: 'Input Input--expand', type: 'text', placeholder: '10.00', onChange: @onCopayEdit
                              td className: 'SaveFeeIcon', onClick: @confirmCopayAmount,
                                fa 'check'
                            ]
                          else
                            [
                              td style: {textAlign: 'center'}, '$' + (@state.copayAmount/100).toFixed(2)
                              td className: 'EditFeeIcon', onClick: @editCopay,
                                fa 'pencil'
                            ]
                        @state.unpaidCharges.map (charge) =>
                          tr key: charge.id, className: 'ChargeRow',
                            td charge.description
                            td style: {textAlign: 'center'}, '$' + (charge.amount/100).toFixed(2)
                            td className: 'RemoveChargeIcon', onClick: @removeCharge(charge.id),
                              fa 'times'
            ]
        ]
      else
        div className: 'PaymentModalSuccessSection', key: 'success-section',
          div className: 'PaymentModalSuccessSection-icon', fa 'check-circle'
          div className: 'PaymentModalSuccessSection-title', 'The payment was processed successfully.'
          tch className: 'PaymentModalSuccessSection-close', handler: @cancel, 'Close'
})


module.exports = observer(StripeModal)
