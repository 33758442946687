{appStore, authStore} = require 'stores'

Fields  = require 'components/mixins/Fields'
Tooltip = require 'components/elements/Tooltip'
Success = require 'components/auth/Success'

{cx} = Exim.helpers
{div, h1, p, input, spinner, tch, form, button, label, span} = Exim.DOM

Activation = Exim.createView module.id,
  mixins: [Fields]
  listen: ['auth/sendingReset', 'auth/resetSuccess']

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    tooltip: {}
    sent: false
    agreement: false
    activation:
      password: ''
      password_confirmation: ''

  getInitialData: ->
    password: @state.activation.password
    password_confirmation: @state.activation.password_confirmation

  dataName: 'activation'
  required: ['password', 'password_confirmation']

  componentDidMount: ->
    appStore.actions.setTitle('Reset')
    authStore.actions.clearPasswordReset()
    href = location.href.split('/')
    @code = href[href.length - 1]
    document.body.addEventListener('keydown', @onKeyDown)

  componentWillUnmount: ->
    appStore.actions.setTitle()
    document.body.removeEventListener('keydown', @onKeyDown)

  tooltipFor: (type) -> (evt) =>
    target = evt.currentTarget
    value = target.value
    position = top: target.offsetTop , left: target.offsetLeft + 290
    matches = @getComplexChecker('password')(value)
    @setState tooltip: {type, matches, position, animate: true}

  hideTooltip: ->
    @setState tooltip: {}

  sendReset: (e) ->
    e.stopPropagation()
    e.preventDefault()
    {password, password_cofirmation} = @getFields()

    errors = []
    if !password or password.length <= 8
      errors.push 'password'
    if !password_cofirmation or password isnt password_cofirmation
      errors.push 'password_confirmation'
    if !@state.agreement
      errors.push 'agreement'

    if errors.length > 0
      @setState {errors}
      return false
    else
      authStore.actions.resetPassword(@code, password).then ->
      @setState sent: true

  onKeyDown: (evt) ->
    key = evt.which
    if evt.which is 13
      @sendReset()

  leave: ->
    @context.router.push '/signin'

  toggleAgreement: ->
    @setState agreement: !@state.agreement, errors: @state.errors.filter((err) -> err isnt 'agreement')

  showAgreement: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()
    body = require('components/modals/Agreement')
    modal = {body}

    appStore.actions.showModal(modal)

  toggleCheckbox: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    checkbox = evt.currentTarget.querySelector('input')
    checkbox.checked = !checkbox.checked
    @toggleAgreement()

  render: ->
    div className: 'Splash Splash--auth',
      div className: 'FlippedSectionInner',
        div className: cx('FlippedContainer': true), ref: 'container',
          div className: 'FlippedCard',
            div className: 'FlippedCard-front',
              form onSubmit: @sendReset, autoComplete: 'off',
                unless @state.sent
                  [
                    h1 className: 'FlippedCard-title', 'Complete your Account Activation'
                    div className: 'input-wrapper password',
                      @field 'password', className: 'Input Input--auth Input--expand', required: true, placeholder: 'Enter Password', onFocus: @tooltipFor('password'), onBlur: @hideTooltip, onKeyUp: @tooltipFor('password')
                      Tooltip(@state.tooltip)
                    div className: 'input-wrapper password-cofirmation',
                      @field 'password_cofirmation', className: 'Input Input--auth Input--expand', required: true, placeholder: 'Confirm Password'

                    label className: 'SignupSection-checkboxGroup',
                      tch tagName: 'label', className: 'checkbox-show no-margin-top', handler: @toggleCheckbox,
                        input type: 'checkbox', className: 'checkbox-show-input'
                        div className: 'checkbox-rect'
                        span className: "#{if 'agreement' in @state.errors then 'error' else ''}",
                          'I agree to the'
                          tch tagName: 'a', href: '#', handler: @showAgreement, 'User agreement'

                    button className: 'Button Button--primary Button--large Button--expand Button--auth', type: 'submit', handler: @sendReset,
                      'Activate Account'
                    div className: 'input-bottom inv-input-bottom',
                      tch tagName: 'a', href: '#', handler: @leave, 'Back to Sign In'
                  ]
                else
                  [
                    if @state.sendingReset
                      spinner()
                    else
                      if @state.resetSuccess
                        Success()
                      else
                        [
                          h1 'Whoops!'
                          p className: 'check', 'This activation link is expired or invalid. Please try the process again.'
                          div className: 'spacer'
                          if help = appStore.get('config')?.app?.helpHTML
                            p dangerouslySetInnerHTML: {__html: help}
                          div className: 'input-bottom inv-input-bottom',
                            tch tagName: 'a', href: '#', handler: @leave, 'Return to Sign In'
                        ]
                  ]

module.exports = Activation
