request     = require 'lib/request'
authStore = require 'stores/auth'
newAuthStore = require 'stores/new-auth'
synopsisStore = require 'stores/synopsis'
messagesStore = require 'stores/messages'
ReactDOM    = require 'react-dom'
URI         = require 'urijs'
appConfig   = require 'config'
parseLinks  = require 'parse-link-header'
{isCordova, buildStatusUrl, buildDate, iOS, guestRoutes} = appConfig

module.exports = appStore = Exim.createStore
  path: 'app'
  actions: [
    'start'
    'completeOAuth'
    'showModal'
    'addGlobalClick'
    'removeGlobalClick'
    'setGlobalFileChangeHandler'
    'removeGlobalFileChangeHandler'
    'setHeader'
    'updateHeader'
    'navTo'
    'updateLastLocation'
    'setAfterRoute'
    'setTitle'
  ]

  initial:
    config:
      links: {}

    modal: null
    globalClickHandlers: []
    globalFileChangeHandler: null
    cache: {}
    header: null
    title: null
    router: null
    route: null
    lastLocation: null
    isConfigFetched: false

  start:
    will: ->
      authStore.actions.fetchOAuthProviderLinks()
      authStore.actions.fetchUser().then(->
        synopsisStore.actions.fetch()
        if authStore.get 'loggedIn'
          messagesStore.actions.fetchMessages()
          newAuthStore.fetchFeatureFlags()
        if newAuthStore.isProxying and newAuthStore?.user?.id
          newAuthStore.fetchProxiedPatientFeatureFlags(newAuthStore?.user?.id)
      ).catch(->) unless appConfig.guestRoutes.some (route) -> document.location.pathname.match ///^\/#{route}///
    on: ->
      request.get('config')
    did: (config) ->
      document.title = title4 if title4 = config?.app?.title4

      routes = require('routes')

      window.handleOpenURL = (url) -> appStore.actions.completeOAuth(url)

      @set {config, router: routes, isConfigFetched: true}
      parent = document.querySelector('#app-outer') or document.body
      ReactDOM.render routes, parent

      if appVersion = cordova?.getAppVersion
        appVersion.getVersionNumber().then (version) ->
          appConfig.build.version = version
        appVersion.getVersionCode().then (code) ->
          appConfig.build.code = code

      universalLinks?.subscribe null, (data) ->
        console.log 'Launched from universal links'
        authStore.actions.signout().then ->
          window.location.href = window.location.href.split('#')[0] + '#' + data.path

  completeOAuth: (url) ->
    cordova.plugins.browsertab?.close()

    {history} = @get('router').props
    history.push pathname: '/oidc-loading'
    u = new URI(url)
    params = u.query()

    headers = new Headers({
      'Accept': 'application/json'
      'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Length': params.length.toString()
    })

    request.send(u.path(), { method: 'POST', body: params, headers: headers }, { return_headers: true }).then (resp) ->
      links = parseLinks resp.headers.get('Link')
      next = new URI(links.next.url)
      path = next.path()
      query = next.search(true)

      if path[1..] in guestRoutes
        history.push pathname: path, query: query
      else
        authStore.actions.fetchUser().then (user) ->
          history.push pathname: path, query: query

  setTitle: (title) ->
    title4 = @get('config')?.app?.title4
    title = if title then "#{title4} - #{title}" else title4
    document.title = title
    @set {title}

  showModal: (modal) ->
    body = document.querySelector('body')
    if modal
      body?.setAttribute('class', (body.getAttribute('class') or '') + ' has-modal')
    else
      body?.setAttribute('class', (body.getAttribute('class') or '').replace(/\s+has-modal/ig, ''))
    @set {modal}

  addGlobalClick: (handler) ->
    globalClickHandlers = @get('globalClickHandlers')
    globalClickHandlers.push handler unless handler in globalClickHandlers
    @set {globalClickHandlers}

  removeGlobalClick: (handler) ->
    globalClickHandlers = @get('globalClickHandlers')
    filteredHandlers = globalClickHandlers.filter((x) -> x isnt handler)
    @set {globalClickHandlers: filteredHandlers}

  setGlobalFileChangeHandler: (globalFileChangeHandler) ->
    @set {globalFileChangeHandler}

  removeGlobalFileChangeHandler: ->
    @set globalFileChangeHandler: null

  setHeader: (header) ->
    @set {header}

  updateHeader: (next) ->
    header = @get('header')
    Object.keys(next).forEach (key) ->
      header[key] = next[key]
    @set {header}

  navTo: (route) ->
    @set {route}

  updateLastLocation: (lastLocation) ->
    @set {lastLocation}

  setAfterRoute: (afterRoute) ->
    @set {afterRoute}
