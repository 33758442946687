{ chargesStore } = require 'stores'
{ tch, div, h3 } = Exim.DOM
{ cx } = Exim.helpers

DeleteChargeModal = Exim.createView module.id,
  onConfirm: ->
    chargesStore.actions.deleteCharge(@props.chargeId).then =>
      @props.close()

  render: ->
    div className: 'DeleteChargeModal-body',
      h3 'Are you sure you want to delete this charge?'
      div className: 'DeleteChargeModal-footer',
        tch className: 'Button', handler: @props.close, style: {marginRight: '8px'},
          'Cancel'
        tch className: 'Button Button--primary', handler: @onConfirm,
          'Delete'

module.exports = DeleteChargeModal
