"use strict";
const { observer } = require("mobx-react");
const wellnessStore = require("stores/wellness").default;

class Overview extends React.Component {
  getOverviewText(overviewText) {
    return { __html: overviewText };
  }

  render() {
    const programs = wellnessStore.programs;
    const { overviewText, ongoing } = wellnessStore.getSurvey(programs[0]);

    return (
      <div className="Wellness-Overview">
        {ongoing ? (
          <div
            className="Wellness-Overview--message"
            dangerouslySetInnerHTML={this.getOverviewText(overviewText)}
          />
        ) : (
          <div className="Wellness-Overview--program-ended-message">
            <p>The 2020 Wellness Incentive Program is now closed.</p>
            <p>Information about the 2021 Program will be available in 2021.</p>
          </div>
        )}
      </div>
    );
  }
}

module.exports = observer(Overview);
