"use strict";
const { showModal } = require("components/elements/new-modal");
const DeleteSlotSeriesModal = require("./delete-slot-series-modal");
const DeleteBookedSlotModal = require("./delete-booked-slot-modal");

const conflictResolution = "cancel";
const getDeleteTarget = () => showModal(<DeleteSlotSeriesModal />);

const showDeleteBookedModal = async (appts) => {
  if (appts.length) {
    await showModal(<DeleteBookedSlotModal appts={appts} />);
  }
};

const deleteSlotOrSeries = async (slot) => {
  let target = slot.isRepeated ? await getDeleteTarget() : "one";
  if (target == "one") {
    await showDeleteBookedModal(slot.appointments);
    await slot.delete();
    return;
  }

  let { series, date } = slot;

  switch (target) {
    case "future": {
      let { summary } = await series.deleteFuture({ date, dryRun: true });
      await showDeleteBookedModal(summary.conflictedAppointments);
      await series.deleteFuture({ date, conflictResolution });
      break;
    }

    case "all": {
      let { summary } = await series.delete({ dryRun: true });
      await showDeleteBookedModal(summary.conflictedAppointments);
      await series.delete({ conflictResolution });
      break;
    }
  }
};

module.exports = deleteSlotOrSeries;
