"use strict";
const Spinner = require("components/elements/new-spinner");
const Main = require("components/elements/main");
const { appStore } = require("stores");
const { ISO_DATE } = require("lib/formats");
const { autorun } = require("mobx");
const { observer } = require("mobx-react");
const apptsStore = require("stores/new-appts");
const withIdleTimer = require("components/elements/with-idle-timer");
const PatientSearchHeader = require("components/elements/patient-search-header");
const Provider = require("./provider");
const { withRouter } = require("react-router");
const ScheduleHeader = require("./schedule-header");
const ScheduleTags = require("./schedule-tags");
const Footer = require("components/app/Footer");

const renderProvider = (prov) => {
  return <Provider key={prov.id} provider={prov} />;
};

class Schedule extends React.Component {
  componentWillMount() {
    appStore.actions.setHeader(null);

    let {
      router,
      location: {
        pathname,
        query: { date },
      },
    } = this.props;

    if (date) {
      apptsStore.date = moment(date);
    }

    this.disposeFetchAutorun = autorun(() => {
      apptsStore.fetchSchedule();
    });

    this.disposeRouterAutorun = autorun(() => {
      router.replace({
        pathname,
        query: {
          date: apptsStore.date.format(ISO_DATE),
        },
      });
    });
  }

  componentWillUnmount() {
    this.disposeFetchAutorun();
    this.disposeRouterAutorun();
  }

  renderProviders() {
    if (apptsStore.isScheduleLoading) {
      return <Spinner />;
    }

    let { some } = apptsStore;
    if (!some.length) {
      return "There are no appointments for this date.";
    }

    return <div className="ProviderAppts">{some.map(renderProvider)}</div>;
  }

  render() {
    return (
      <div className="NewContent">
        <ScheduleHeader />
        <Main className="DailySchedule">
          <PatientSearchHeader />
          <ScheduleTags />
          {this.renderProviders()}
        </Main>
        {Footer()}
      </div>
    );
  }
}

module.exports = withIdleTimer(withRouter(observer(Schedule)));
