import { formats } from "config";
import { timezone } from "lib/utils";

const CalendarCard = ({ date, zone }) => {
  return (
    <div className="CalendarCard">
      <div className="CalendarCard-heading">We’ll see you on</div>
      <div className="CalendarCard-date">
        <div className="CalendarCard-month">{date.format("MMMM")}</div>
        <div className="CalendarCard-day">{date.format("DD")}</div>
      </div>
      <div className="CalendarCard-time">
        at {date.format(formats.scheduleTimeFormat)} {timezone(zone)}
      </div>
    </div>
  );
};

export default CalendarCard;
