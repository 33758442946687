import { observer } from "mobx-react";
import moment from "moment";
import { SelectOptions } from "./selectOptions";

const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

class DOBfields extends React.Component {
  state = {
    day: "",
    month: "",
    year: "",
  };

  getYears() {
    const result = {};
    let key = 0;
    for (let i = moment().year(); i >= 1900; i--) {
      result[key] = i;
      key++;
    }
    return result;
  }

  getDays() {
    const result = {};
    let key = 0;
    for (let i = 1; i <= 31; i++) {
      result[key] = i;
      key++;
    }
    return result;
  }

  handleOnChange = (e, field) => {
    this.setState({
      [field]: e.target.value,
    });
    this.props.form.$(field).onChange(e);
  };

  render() {
    const { form } = this.props;
    const { day, year, month } = this.state;

    return (
      <React.Fragment>
        <label className="Hra-dob-label" htmlFor={form.$("day").id}>
          Date of Birth
        </label>
        <div className="Hra-dob-fields">
          <SelectOptions
            options={months}
            onChange={this.handleOnChange}
            className={
              form.$("month").error
                ? "Hra-month-input-error"
                : "Hra-month-input"
            }
            field={form.$("month")}
            value={month}
          />
          <SelectOptions
            options={this.getDays()}
            onChange={this.handleOnChange}
            className={
              form.$("day").error ? "Hra-day-input-error" : "Hra-day-input"
            }
            field={form.$("day")}
            value={day}
          />
          <SelectOptions
            options={this.getYears()}
            onChange={this.handleOnChange}
            className={
              form.$("year").error ? "Hra-year-input-error" : "Hra-year-input"
            }
            field={form.$("year")}
            value={year}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default DOBfields;
