
Spinner = require 'components/elements/Spinner'

{ div, h2 } = Exim.DOM

Loading = Exim.createView module.id,
  render: ->
    div className: 'Splash Splash--auth',
      div className: 'FlippedSectionInner',
        div className: 'FlippedContainer',
          div className: 'FlippedCard',
            div className: 'FlippedCard-front card-logo',
              h2 {},
                "Loading Identity"
              Spinner state: true

module.exports = Loading
