import { observable } from "mobx";
import request from "lib/new-request";

const careTeamsStore = observable({
  careTeams: {},
  selectedProviderName: null,
  selectedProviderSrc: null,

  async fetchCareTeams(user_id) {
    try {
      this.careTeams = {};
      const { results } = await request.get(`/v1/users/${user_id}/care-teams`);
      this.careTeams = results;
    } catch (e) {
      console.log(e);
    }
  },

  get providerCareTeams() {
    return this.careTeams;
  },

  setSelectedProvider(prov) {
    this.selectedProviderName = prov.name;
    this.selectedProviderSrc = prov.shot || "/images/team/photo-default.jpg";
  },

  get providerName() {
    return this.selectedProviderName;
  },

  get providerSrc() {
    return this.selectedProviderSrc;
  },

  clear() {
    this.selectedProviderName = null;
    this.selectedProviderSrc = null;
  },
});

export default careTeamsStore;
