'use strict'
Step = require 'components/mixins/Step'

{cx} = Exim.helpers
{main, section, div, h2, h3, label, h1, span} = Exim.DOM

Lifestyle = Exim.createView module.id,
  mixins: [Step]
  name: 'lifestyle'

  required: ['exercise', 'quality_of_sleep', 'problems_with_sleep', 'stress_level', 'typical_mood', 'intake_of_whole_grains', 'intake_of_saturated_fats', 'fruits_veg_servings', 'caffeine_drinks', 'alcohol_drinks', 'tobacco_use', 'managing_health', 'description', 'goals']

  componentDidMount: ->
    setTimeout(() => @refs.exercise?.focus())

  componentDidUpdate: ->
    if (@state.errors.length)
      firstErrorField = document.querySelectorAll('.error')[0]
      firstErrorField.firstChild.focus()

  render: ->
    return div className: 'CheckinForm',
      if @state.errors.length
        div className: 'sr-only', 'aria-live': 'assertive', "Please complete all required fields: #{@state.errors.map((error) => error.replace(/_/g, ' ')).join(', ')}"
      h2 className: 'SectionHeading',  'Your lifestyle'
      span className: 'CheckinForm-row CheckinForm-row--desc CheckinForm-row--no-margin',
        'Tell us about your current routine and habits.'
      div className: 'ContentBlock',
        h3 className: 'FieldHeading', 'Fitness'
        div className: 'CheckinForm-row is-lifestyle',
          label "Exercise per Week #{if 'exercise' in @required then '*'}"
          @select 'exercise', ['0-30 minutes', '30-60 minutes', '60-90 minutes', '90-120 minutes', '120-180 minutes', '180-240 minutes', '240+ minutes'], className: '', description: 'Exercise per Week'

        h3 className: 'FieldHeading', 'Sleep'
        div className: 'CheckinForm-row is-lifestyle',
          label "Quality of Sleep #{if 'quality_of_sleep' in @required then '*'}"
          @select 'quality_of_sleep', ['Very Good', 'Good', 'Fair', 'Poor', 'Very Poor'], description: 'Quality of Sleep'

        div className: 'CheckinForm-row is-lifestyle',
          label "Problems with Sleep #{if 'problems_with_sleep' in @required then '*'}"
          @select 'problems_with_sleep', ['Not at all', 'A little bit', 'Somewhat', 'Quite a bit', 'Very much'], description: 'Problems with Sleep'

        h3 className: 'FieldHeading', 'Stress'
        div className: 'CheckinForm-row is-lifestyle',
          label "Stress Level #{if 'stress_level' in @required then '*'}"
          @select 'stress_level', ['1 (Very low)', '2', '3', '4', '5', '6', '7', '8', '9', '10 (Very high)'], description: 'Stress Level'

        div className: 'CheckinForm-row is-lifestyle',
          label "Happiness #{if 'typical_mood' in @required then '*'}"
          @select 'typical_mood', ['1 (Very unhappy)', '2', '3', '4', '5', '6', '7', '8', '9', '10 (Very happy)'], description: 'Happiness'

        h3 className: 'FieldHeading', 'Nutrition'
        div className: 'CheckinForm-row is-lifestyle',
          label "Daily Intake of Whole Grains #{if 'intake_of_whole_grains' in @required then '*'}"
          @select 'intake_of_whole_grains', ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'], description: 'Daily Intake of Whole Grains'

        div className: 'CheckinForm-row is-lifestyle',
          label "Daily Intake of Saturated Fats #{if 'intake_of_saturated_fats' in @required then '*'}"
          @select 'intake_of_saturated_fats', ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'], description: 'Daily Intake of Saturated Fats'

        div className: 'CheckinForm-row is-lifestyle',
          label "Fruit and Vegetable Servings per Day #{if 'fruits_veg_servings' in @required then '*'}"
          @select 'fruits_veg_servings', ['0', '1-2', '3', '4', '5+'], description: 'Fruit and Vegetable Servings per Day'

        div className: 'CheckinForm-row is-lifestyle',
          label "Caffeine Drinks per Week #{if 'caffeine_drinks' in @required then '*'}"
          @select 'caffeine_drinks', ['0', '1-4', '5-7', '8-10', '11-14', '15+'], description: 'Caffeine Drinks per Week'

        h3 className: 'FieldHeading', 'Habits'
        div className: 'CheckinForm-row is-lifestyle',
          label "Alcohol Drinks per Week #{if 'alcohol_drinks' in @required then '*'}"
          @select 'alcohol_drinks', ['0', '1-4', '5-7', '8-14', '15-21', '22+'], description: 'Alcohol Drinks per Week'

        div className: 'CheckinForm-row is-lifestyle',
          label "Tobacco Use #{if 'tobacco_use' in @required then '*'}"
          @select 'tobacco_use', ['None', 'Sometimes', 'Yes'], description: 'Tobacco Use'

        h3 className: 'FieldHeading', 'Wellness'
        div className: 'CheckinForm-row is-lifestyle',
          label "Rate your ability to manage your health #{if 'managing_health' in @required then '*'}"
          @select 'managing_health', ['Poor', 'Fair', 'Good', 'Very Good', 'Excellent'], description: 'Rate your ability to manage your health'

        div className: 'CheckinForm-row is-lifestyle',
          label "How do you feel about your health? #{if 'description' in @required then '*'}"
          @select 'description', ["I don't want to get healthier", 'I want to get healthier, but not yet', "I'm ready to start getting healthier", "I'm already improving my health", "I've reached my health goals"], description: 'How do you feel about your health?'

        div className: 'CheckinForm-row is-lifestyle columned',
          label "What are your health goals? #{if 'goals' in @required then '*'}" 
          @textarea 'goals', useValue: true, description: 'What are your health goals?'

      this.renderCheckInFooter()

module.exports = Lifestyle
