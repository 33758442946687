const EN_DASH = "\u2013";

class MomentRange {
  constructor(start, end) {
    this.start = start.clone();
    this.end = end.clone();
  }

  get duration() {
    return moment.duration(this.end - this.start);
  }

  set(...args) {
    this.start.set(...args);
    this.end.set(...args);

    return this;
  }

  includes(date) {
    return date >= this.start && date <= this.end;
  }

  clone() {
    let start = this.start.clone();
    let end = this.end.clone();

    return new this.constructor(start, end);
  }

  by(...args) {
    let dates = [];
    let date = this.start.clone();

    for (;;) {
      dates.push(date.clone());
      date.add(...args);
      if (date > this.end) break;
    }

    return dates;
  }

  format(pattern) {
    let { start, end } = this;

    return start.isSame(end)
      ? start.format(pattern)
      : `${start.format(pattern)} ${EN_DASH} ${end.format(pattern)}`;
  }
}

export default MomentRange;
