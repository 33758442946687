screenStore  = require 'stores/screen'
screeningStore = require 'stores/screening'
metricsStore = require 'stores/metrics'
checkinStore = require 'stores/checkin'
patientStore = require 'stores/patient'

screenConfig = require './config'
adminRoute   = require 'components/mixins/adminRoute'
{capitalize, shortFormatDateNoMidnight, formatDateSimple, parsePattern, EximShim} = require 'lib/utils'
{toJS} = require 'mobx'
AdHocScreening = EximShim require 'components/screen/AdHocScreening'
SimpleSelect = require 'components/elements/SimpleSelect'

{cx} = Exim.helpers
{div, select, option, span, b, input, label, h3, h5, ul, li, tch, spinner, fa} = Exim.DOM
confirm = require 'lib/confirm'

Step = Exim.createView
  mixins: [
    metricsStore.connect('types')
    screenStore.connect('screen', 'screenSaving', 'patient_id', 'appointment_id', 'appointment_at', 'additional_data')
    patientStore.connect('patient')
    adminRoute(isAdmin: true, allowProxying: true)
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  routerWillLeave: (transition) ->
    hasData = Object.keys(@state.stepData).length > 0
    ignorePrevention = /\/screen/.test(transition.path)
    if hasData and not @skipCheck
      confirm('Discard unsaved values?').then (confirmed) =>
        if confirmed and not ignorePrevention
          screenStore.actions.clear()
          @skipCheck = true
          @context.router.push transition.pathname
      return false
    else
      return true

  getInitialState: ->
    stepData: {}
    data: []
    errors: []

  componentWillMount: ->
    # Can't select ad-hoc screens here, that depends on async
    @name = @props.params?.step or screenConfig.firstStep
    @step = screenConfig.steps?[@name]
    @props.setName @name

    @fetchStepData()

  componentWillUpdate: (nextProps, nextState)->
    # Steps will be undefined if loading survey templates - define here
    if @name and !@step and screeningStore.onDemandScreenings?[@name]
      @step = screenConfig.steps?[@name] or screeningStore.onDemandScreenings?[@name]
      @fetchStepData()
    else if (nextStep = nextProps.params?.step) and nextStep isnt @name
      @name = nextStep
      @step = screenConfig.steps?[@name] or screeningStore.onDemandScreenings?[@name]
      @props.setName @name
      # Prevent stale survey state from carrying over
      if (@state.data)
        nextState.data = []
        nextState.screen.data = []
      @fetchStepData()

  fetchStepData: ->
    patientStore.actions.fetch().then =>
      # We always need patient data, but AdHocScreenings will their own data
      if !@step?.screeningTemplate
        screenStore.actions.fetch(@state.patient.patient_id, @name)
          .then(=> @setState data: screenStore.get('screen').data)
          .catch => @context.router.push '/schedule'

  reloadStep: ->
    screenStore.actions.fetch(@state.patient.patient_id, @name)
      .then =>
        @setState data: screenStore.get('screen').data, success: true
        @clearData()
      .catch -> console.log('error saving screen')

  filterByStep: (type) ->
    if @step
      type?.id in @step.ids

  sortByStep: (a,b) ->
    @step.ids.indexOf(a.id) - @step.ids.indexOf(b.id)

  getRanges: (type) ->
    ranges = type.ranges
    type.displayedAsRange = ranges and 'value' of ranges[0]
    type

  setPreviousValues: (type) ->
    type.previous = this.state.data.find((d) => d.metric == type.id)
    type

  clearData: ->
    @setState stepData: {}
    elements = document.querySelectorAll('.ScreensRow select,.ScreensRow input')
    elements.forEach (el) ->
      el.value = ''

  save: ->
    data = {list: Object.keys(s = @state.stepData).map((id) -> metric: id, value: s[id])}
    data.appointment_id =  @state.appointment_id || 999999
    data.patient_id = @state.patient_id || @state.patient.patient_id
    data.appointment_at = @state.appointment_at || new Date
    if data.list.length and !@state.errors.length
      screenStore.actions.save(@name, data).then =>
        @reloadStep()

  getDisplayValue: (metric, section, items) ->
    if not metric.previous?.value
      return ''

    if metric.displayedAsRange
      name = metric.ranges.filter((range)-> +range.value is +metric.previous?.value)?[0]?.name
      name or metric.previous?.value
    else
      metric.previous?.value

  onFocus: (evt) ->
    evt.currentTarget.parentNode.classList.add('prev')

  onBlur: (metric) -> (evt) =>
    value = evt.currentTarget.value
    evt.currentTarget.parentNode.classList.remove('prev') unless value
    @check metric, value

  onChange: (id) -> (evt) =>
    value = evt.currentTarget?.value or evt.value?.toString()
    @setState success: false if @state.success
    {stepData} = @state
    stepData[id] = value
    @setState {stepData}
    if @step.sections and section = @step.sections.filter((section) -> id in section.ids)[0]
      @calculateValues(section) if section

  calculateValues: (section) ->
    {stepData} = @state
    {total, subtotal} = section
    totalValue = 0

    return unless total || subtotal

    enteredMetricIds = Object.keys(stepData).map((id) -> +id).filter((id) -> (id in section.ids) and !(id in [total, subtotal?.id]))
    if enteredMetricIds.length <= subtotal?.included?.length
      subtotalIncluded = enteredMetricIds.filter((id) -> !(id in subtotal?.included)).length == 0
    else
      subtotalIncluded = false

    section?.ids?.forEach (id) ->
      totalValue += +(stepData[id] or 0) unless +id is +total or +id is +subtotal?.id

    if subtotalIncluded
      stepData[subtotal.id] = totalValue.toString()
      stepData[total] = null
    else
      stepData[total] = totalValue.toString()
      stepData[subtotal.id] = null if subtotal

    @setState {stepData}

  removePrevious: (metric) -> (evt) ->
    confirmed = window.confirm 'Do you want to delete this value?'
    return unless confirmed
    screenStore.actions.removePrevious metric.previous

  check: (metric, value) ->
    if value and raw = (metric.valid or metric.mask)
      pattern = parsePattern raw
      if pattern value
        @setState errors: @state.errors.filter((id) -> id isnt metric.id)
      else
        @setState errors: @state.errors.concat [metric.id] if @state.errors.indexOf(metric.id) < 0
    else if !value
      @setState errors: @state.errors.filter((id) -> id isnt metric.id)

  err: (metric) ->
    if metric.id in @state.errors then 'error' else ''

  render: ->
    items = @state.types
      .filter(@filterByStep)
      .sort(@sortByStep)
      .map(@getRanges)
      .map(@setPreviousValues)

    {patient, additional_data, appointment_at} = @state

    row = (metric, section, metrics) =>
      display_value = @getDisplayValue(metric, section, metrics)
      div className: 'CheckinForm-row ScreensRow', key: metric.id,
        label className: 'ScreensRow-label', dangerouslySetInnerHTML: {__html: metric.name}
        if metric.id is section?.total or metric.id is section?.subtotal?.id
          span className: 'CheckinForm-rowTotal', @state.stepData[metric.id]
        else if metric.displayedAsRange
          SimpleSelect
            angle: true
            values: metric.ranges
            onChange: @onChange(metric.id)
            selected: @state.stepData[metric.id]?.toString()
            idPath: 'value'
            nullPlaceholder: true
            label: (v) -> v.name
            placeholder: ''
            className: "SimpleSelect--largeFont #{cx prev: !!@state.stepData[metric.id]}"
        else
          div className: 'ScreensRow-input InputWrapper--withUnits', 'data-unit': metric.units,
            input className: "#{@err(metric)} Input--largeFont Input Input--expand", type: 'text', onFocus: @onFocus, onBlur: @onBlur(metric), onChange: @onChange(metric.id), value: @state.stepData[metric.id]

        if metric.previous or metric.id is section?.total
          span {className: 'ScreensRow-previous', onDoubleClick: @removePrevious(metric)},
            div className: 'value', "#{display_value} #{if display_value then (metric.units or '') else ''}"
            div className: 'timestamp', shortFormatDateNoMidnight(metric.previous.date) if metric.previous?.date
        else
          span className: 'ScreensRow-previous'

    physician = additional_data?.providerData?.name
    visit_type = additional_data?.summary
    appointment_at = appointment_at?.replace(' ', 'T').replace(' ', '')
    appt_date = if appointment_at then formatDateSimple(appointment_at) else false

    div className: 'CheckinForm ContentInner',
      div className: 'ScreensInfo',
        div className: 'ScreensInfo-row',
          label 'Patient:'
          span  "#{patient.first_name} #{patient.last_name}"
        div className: 'ScreensInfo-row',
          label 'DOB:'
          span  formatDateSimple(patient.birthdate, 'mm/dd/yyyy')

        if physician
          div className: 'ScreensInfo-row',
            label 'Physician:'
            span  physician
        if appt_date
          div className: 'ScreensInfo-row',
            label 'Date:'
            span  appt_date
        if visit_type
          div className: 'ScreensInfo-row',
            label 'Visit Type:'
            span  visit_type
        if !@state.appointment_id
          b 'No appointment selected'
      # If the router points to a Survey Template, render that template
      if @step?.screeningTemplate
        screeningProps =
          title: @step.title
          template: @step.template
          patient_id: @state.patient_id || @state.patient?.patient_id
          appointment_id: @state.appointment_id
        AdHocScreening screeningProps
      # Render legacy form properties (sections for screenings found in config.coffee, etc)
      if @step?.sections?
        @step.sections.map (section, i, arr) ->
          cls = if i is 0 then 'ContentBlock--noTopMargin' else if i is arr.length - 1 then 'ContentBlock--bottomMargin' else ''
          div className: "ContentBlock #{cls}", key: section.name,
            h3 section.name
            items.filter((type) -> type.id in section.ids).slice().sort((a,b) -> section.ids.indexOf(a.id) - section.ids.indexOf(b.id)).map((metric, i, arr) -> row(metric, section, arr))
            if section.legend
              div className: 'ScreensLegend',
                div className: "#{cx 'LegendColumn': section.score}",
                  h5 'Legend'
                  ul null,
                    section.legend.map (item, i) ->
                      li key: i, item
                if section.score
                  div className: 'LegendColumn',
                    h5 'Score'
                    ul null,
                      section.score.map (item, i) ->
                        li key: i, item
      else
        div className: 'ContentBlock ContentBlock--bottomMargin',
          items.map (metric) -> row(metric)

      if items?.length
        div className: 'ScreensFooter',
          if @state.success
            div className: 'ScreensFooter-success',
              fa 'check-circle'
              span "#{capitalize @name} Saved"
          tch className: 'Button Button--primary Button--pointer', handler: @save,
            if @state.screenSaving
              div className: 'app-spinner-outer-content',
                div className: 'app-spinner-outer',
                  spinner()
            else
              "Save #{capitalize @name}"
          div className: 'ScreensFooter-spacer'

module.exports = Step
