'use strict'
authStore = require('stores/new-auth')
synopsisStore = require('stores/new-synopsis')
oldAuthStore = require('stores/auth')
{appStore, checkinStore, patientStore, screenStore, metricsStore} = require 'stores'
appointmentsStore = require('stores/new-appointments').default

screenConfig = require 'components/screen/config'
CancelApptModal = require 'components/elements/cancel-appt-modal'
ActionDropdown = require 'components/elements/ActionDropdown'
NotesModal = require 'components/elements/NotesModal'

{formats, client} = require('config')
{showModal, closeModal} = require('components/elements/new-modal')
{observer} = require('mobx-react')
{cx} = require('lib/utils')
{withRouter} = require('react-router')
{startCase, kebabCase} = require('lodash')
ApptNotesButton = require 'components/elements/appt-notes-button'
{PushToXOPModalFooter, PushToXOPModal} = require 'components/elements/push-to-xop-modal'
Appointment = require 'models/appointment'

DATE_FORMAT = formats.date
TIME_FORMAT = 'LT'
DATETIME_FORMAT = "#{DATE_FORMAT}, #{TIME_FORMAT}"

{button, div, span, tr, td, strong, p} = Exim.DOM

EARLY_CHECK_IN_NOTE = 'Early Check-in is available 24 hours before your appointment time. You may update your address and other personal information at any time by editing your profile page'
CHECK_IN_VIDEO_NOTE = 'Please be sure to complete your check-in. Once complete, a JOIN button will be available 15 mins prior to your appointment time.'
CHECK_IN_PHONE_NOTE = 'Please be sure to complete your check-in. Once complete, a STANDBY button will be available 15 mins prior to your appointment time.'
STAND_BY_NOTE = 'Please ensure that your phone is on and charged. Your provider will contact you at your scheduled appointment time.'
READ_ONLY_CHECK_IN_NOTE = 'Check-in must be completed via the Daily Schedule for this member.'


AppointmentRow = React.createClass({
  displayName: 'AppointmentRow'

  componentDidMount: ->
    if authStore.me.isAdmin
      authStore.getActiveEpisodes()

  showCancelModal: (type) ->
    modal = React.createElement(CancelApptModal, {
      appointment: this.props.appointment,
      type,
      close: (cancelMessage) =>
        alert = document.querySelector('.hidden')
        alert.innerHTML = cancelMessage
        closeModal()
        document.getElementById('returnFocusAfterModal').focus()
    })

    showModal(modal).then(() =>
      appointmentsStore.fetch()
    )

  preflight: (type) ->
    checkinStore.actions.changeState('welcome', {
      preflight: type,
      appointment_id: this.props.appointment.id,
    }).then(() =>
      oldAuthStore.actions.startPreflight()

      {me} = authStore
      {router} = this.props

      if me.isAdmin
        router.push('/checkin/steps/welcome')
      else
        return checkinStore.actions.changeState('checkin').then(() =>
          router.push('/checkin/steps/about')
        )
    , () =>
      alert 'Unable to checkin'
    )

  _getRawAppt: ->
    {id} = this.props.appointment
    return appointmentsStore.getRawAppt(id)

  _getLabel: ->
    {startAt} = this.props.appointment
    return "appointment at #{startAt.format(DATETIME_FORMAT)}"

  screen: ->
    patient = patientStore.get('patient')
    rawAppt = this._getRawAppt()

    screenStore.actions.setup(patient.patient_id, rawAppt.id, rawAppt.starts_at_utc, {
      providerData: {
        name: rawAppt.provider,
      },
      summary: rawAppt.type,
    }).then =>
      this.props.router.push "/checkin/screen/#{screenConfig.firstStep}"

  showNotesModal: ->
    {appointment} = this.props
    metrics = [metricsStore.get('healthItems')...]
    metrics.push(metricsStore.get('corkHealthItems')...) if appointment.site.isCork
    metrics.push(metricsStore.get('lifestyleItems')...)

    appStore.actions.showModal({
      className: 'NotesModal'
      title: "#{appointment.typeName} - #{appointment.startAt.format(DATE_FORMAT)}"
      body: NotesModal({
        appointment_id: appointment.id,
        visit: this._getRawAppt(),
        metrics,
        title: 'Appointment Metrics'
      }),
      print: true
    })

  showEarlyModal: ->
    appStore.actions.showModal({
      body: EARLY_CHECK_IN_NOTE,
    })

  openVideoCallWindow: (url) ->
    videoCallTab = window.open()
    videoCallTab.opener = null
    videoCallTab.location = url

  compareCurrentWithApptTime: (appointment) ->
    currentTime = moment().tz(appointment.site.zone).format('DD MM YYYY HH:mm')
    apptStartTime = moment(appointment.startAt).subtract(15, 'minutes').format('DD MM YYYY HH:mm')
    apptEndTime = moment(appointment.endAt).format('DD MM YYYY HH:mm')

    return { currentTime, apptStartTime, apptEndTime }

  renderCheckinTooltip: (appointment, showPhoneStatement, isPortalReadOnly) ->
    if isPortalReadOnly
      READ_ONLY_CHECK_IN_NOTE
    else if (!appointment.canCheckIn && appointment.status != 'completed')
      EARLY_CHECK_IN_NOTE
    else
      if (appointment.checkInStatus != 'review' && appointment.checkInStatus != 'preflighted_current' && appointment.status != 'completed')
        if appointment.method == 'video'
          return CHECK_IN_VIDEO_NOTE
        if appointment.method == 'phone'
          return CHECK_IN_PHONE_NOTE
      else
        if showPhoneStatement
          STAND_BY_NOTE

  onPushToXOP: ->
    {appointment} = this.props
    appStore.actions.showModal({
      className: 'push-to-xop-modal'
      contentLabel: "Push to XOP"
      title: p 'Please confirm if you would like to push this visit to the Crossover platform and link to the following conversation.'
      body: React.createElement(
        PushToXOPModal, {
          patientName: appointment.patient.full_name,
          visitType: appointment.typeName,
          activeEpisodes: authStore.activeEpisodes,
          appointmentId: appointment.id
        }
      )
      footer: React.createElement(PushToXOPModalFooter)
    })

  renderUpcomingActions: ->
    {me, isPortalReadOnly, isPortalReadOnlyForMember} = authStore

    if isPortalReadOnlyForMember
      return null

    {appointment} = this.props
    showJoinVideoLink = false
    showPhoneStatement = false
    videoConferenceLink = if appointment.video_conference?.url? then appointment.video_conference.url else appointment.provider.video_conference_link
    hasXOPAccess = appointment.patient.xop_eligible
    
    noShowAction = {
      name: 'No Show',
      handler: () => this.showCancelModal('no_show'),
      description: "Mark as a no show #{this._getLabel()}",
    }

    cancelAction = {
      name: 'Cancel',
      handler: () => this.showCancelModal(),
      description: "Cancel #{this._getLabel()}",
    }

    lateCancelAction = {
      name: 'Late Cancel',
      handler: () => this.showCancelModal('late_cancellation'),
      description: "Late cancel #{this._getLabel()}",
    }

    joinVideoCallAction = {
      name: 'Join',
      handler: () => this.openVideoCallWindow(videoConferenceLink),
      description: "Join video #{this._getLabel()}",
    }

    phoneAppointmentsMethod = {
      name: 'Standby',
      disabled: true,
      handler: (->),
      description: "Standby for phone #{this._getLabel()}",
    }

    pushToXopAction = {
      name: 'Push to XOP',
      handler: this.onPushToXOP
    }

    review = {
      name: 'Review',
      handler: () => this.preflight('review'),
      description: "Review #{this._getLabel()}",
    }

    enabledCheckIn = {
      name: 'Check-In',
      handler: () => this.preflight('checkin'),
      description: "Check-In #{this._getLabel()}",
    }

    disabledCheckIn = {
      name: 'Check-In',
      disabled: true,
      handler: () => this.showEarlyModal(),
      description: EARLY_CHECK_IN_NOTE,
    }

    readOnlyCheckIn = {
      name: 'Check-In',
      disabled: true,
      description: READ_ONLY_CHECK_IN_NOTE,
    }

    if (appointment.method == 'video')
      { currentTime, apptStartTime, apptEndTime } = this.compareCurrentWithApptTime(appointment)
      showJoinVideoLink = currentTime >= apptStartTime && currentTime <= apptEndTime && !!videoConferenceLink

    if (appointment.method == 'phone')
      { currentTime, apptStartTime, apptEndTime } = this.compareCurrentWithApptTime(appointment)
      showPhoneStatement = currentTime >= apptStartTime && currentTime <= apptEndTime

    actions = []
    if appointment.isPassed
      actions.push(noShowAction)

    if me.canPreflight
      switch appointment.checkInStatus
        when 'current', 'checkin'
          if appointment.status == 'completed'
            if showJoinVideoLink
              actions.push(joinVideoCallAction)
            else if showPhoneStatement
              actions.push(phoneAppointmentsMethod)
            else
              actions.push(review)
          else
            if isPortalReadOnly then actions.push(readOnlyCheckIn) else actions.push(enabledCheckIn)
        when 'review'
          if showJoinVideoLink
            actions.push(joinVideoCallAction)
          else if showPhoneStatement
            actions.push(phoneAppointmentsMethod)
          else
            actions.push(review)
        when 'non_preflighted_current'
          if (appointment.method == 'video' || appointment.method == 'phone')
            actions.push(enabledCheckIn)
          else
            actions.push(disabledCheckIn)
        when 'preflighted_current'
          if (appointment.method == 'video' || appointment.method == 'phone')
            if showJoinVideoLink
              actions.push(joinVideoCallAction)
            else if showPhoneStatement
              actions.push(phoneAppointmentsMethod)
            else
              actions.push(review)
        else
          if appointment.status == 'completed'
            if showJoinVideoLink
              actions.push(joinVideoCallAction)
            else if showPhoneStatement
              actions.push(phoneAppointmentsMethod)
            else
              actions.push(review)
          else
            actions.push(disabledCheckIn)

      if me.isAdmin && appointment.canScreen
        actions.push({
          name: 'Screen',
          handler: () => this.screen(),
          description: "Screen #{this._getLabel()}",
        })

    if !isPortalReadOnlyForMember
      actions.push(cancelAction)
    if me.isAdmin && !appointment.isPassed
      if !isPortalReadOnlyForMember
        actions.push(lateCancelAction, noShowAction)

      if hasXOPAccess && !appointment.hasBeenPushedToXOP
        actions.push(pushToXopAction)

    return ActionDropdown
      className: 'ActionDropdown--gray'
      values: actions
      tooltip: this.renderCheckinTooltip(appointment, showPhoneStatement, isPortalReadOnly)
      withGlobalClick: true
      needsTwoTabs: true
      elementId: this.props.appointment.id

  renderPastActions: ->
    {appointment} = this.props
    {me} = authStore

    actions = [{
      name: 'View',
      handler: () => this.showNotesModal(),
      description: "View #{this._getLabel()}",
    }]

    if me.isAdmin && me.canPreflight
      actions.push({
        name: 'Screen',
        handler: () => this.screen(),
        description: "Screen #{this._getLabel()}",
      })

    return ActionDropdown
      className: 'ActionDropdown--gray'
      values: actions
      withGlobalClick: true
      needsTwoTabs: true

  renderActions: ->
    {appointment} = this.props
    {me} = authStore

    if appointment.isScheduled || (appointment.isPassed && me.isAdmin)
      return this.renderUpcomingActions()

    if appointment.isCompleted
      return this.renderPastActions()
    
    else
      span className: "invisible", "blank"
      
  renderExpansion: ->
    {toggleExpand, appointment} = this.props

    return tr className: 'AppointmentRow-expansion', onClick: toggleExpand,
      td null,
        "Chief Complaint: #{appointment.reason || 'None'}"
      td colSpan: 5,
        appointment.isCanceled && "Cancel Reason: #{appointment.cancelReason || 'None'}"

  render: ->
    {appointment, toggleExpand, isExpanded} = this.props
    cssStatus = kebabCase(appointment.status)
    isExpandable = authStore.isProxying && synopsisStore.hasScheduling
    virtualVisit = appointment.method == 'video' || appointment.method == 'phone'
    handleClick = if isExpandable then toggleExpand else null
    tag = if appointment.isScheduled then strong else span

    rowClass = cx('AppointmentRow', cssStatus, {
      expandable: isExpandable,
      expanded: isExpanded,
    })

    return React.createElement(React.Fragment, null,
      div className: 'hidden', role: 'alert'
      tr className: rowClass, 'data-e2e': appointment.id, onClick: handleClick,
        td className: 'AppointmentRow-type',
          appointment.typeName
          if appointment.hasBeenPushedToXOP  
            span className: 'xop-badge',
              'XOP'

        td className: 'AppointmentRow-provider',
          tag appointment.provider.name
        td className: 'AppointmentRow-site',
          if !virtualVisit
            appointment.site.name
          else if client == 'nearsite' || client == 'docker'
            span null,
              tag 'Virtual'
              span " - #{appointment.site.name}"
          else
            'Virtual'
        td className: 'AppointmentRow-status',
          tag className: cx('AppointmentStatus', cssStatus),
            startCase(appointment.status)
        td className: 'AppointmentRow-startAt',
          div className: 'AppointmentRow-startDate',
            appointment.startAt.format(DATE_FORMAT)
          div className: 'AppointmentRow-startTime',
            tag appointment.startAt.format(TIME_FORMAT)
          div className: 'AppointmentRow-timeZone',
            appointment.zoneAbbr
        td className: 'AppointmentRow-actions',
          this.renderActions()
          React.createElement(ApptNotesButton, {appointment})
      this.renderExpansion() if isExpanded
    )
})

module.exports = withRouter(observer(AppointmentRow))
