"use strict";
const authStore = require("stores/new-auth");
const { observer } = require("mobx-react");
const Link = require("../link");
import { withFeatureFlag } from "components/util/FeatureFlag";

const BeWellLink = () => {
  let { user } = authStore;
  if (user.isAdmin) return null;
  return (
    <li className="SidebarNav-item">
      <Link
        to={`https://be-well.crossoverhealth.com?utm_source=portal&utm_campaign=be_well&utm_medium=sidebar&utm_term=${user.employer}+${user.efId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="SidebarNavLink-icon">
          <span aria-hidden={true} className="icon fa fa-smile-o" />
        </span>
        <span className="SidebarNavLink-text">Be Well</span>

        <span className="SidebarNavLink-icon">
          <span aria-hidden={true} className="icon fa fa-external-link" />
        </span>
      </Link>
    </li>
  );
};

module.exports = withFeatureFlag(observer(BeWellLink), "be_well");
