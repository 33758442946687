request       = require 'lib/request'
API_MAX_VISITS = 21
module.exports = store = Exim.createStore
  actions: ['fetch', 'clear']

  initial:
    visits: []

  fetch:
    while: (state) ->
      @set visitsLoading: state
    on: (params) ->
      request.get 'patient/visits', params
    did: (data) ->
      @set visits: data
    didNot: ->
      console.error 'Error: visits are not available'

  clear: ->
    @set visits: []
