
actionToIconMap = (action) ->
  switch action
    when 'replyall' then 'reply-all'
    else action

actionToPastTense = (action) ->
  switch action
    when 'replyall' then 'Replied All'
    when 'reply' then 'Replied'
    when 'forward' then 'Forwarded'

module.exports = { actionToIconMap, actionToPastTense }
