import React, { createRef, Component } from "react";
import { Link } from "react-router";

class BackToAppointmentsLink extends Component {
  constructor(props) {
    super(props);
    this.link = createRef();
  }
  componentDidMount() {
    // we have an old verions of react-router complicating the usage of refs in the Link
    // so we drop a ref on the containing div, then find the first link, and focus that on mount
    this.link.current && this.link.current.querySelector("a").focus();
  }

  render() {
    const { to } = this.props;
    return (
      <div ref={this.link}>
        <Link to={to} className="Button Button--back Appointments-back">
          Back to Appointments
        </Link>
      </div>
    );
  }
}

module.exports = BackToAppointmentsLink;
