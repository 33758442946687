"use strict";
const { mapValues } = require("lodash");

const withMediaQueries = (Component, queries) => {
  class MediaQueries extends React.Component {
    constructor(props) {
      super(props);

      this.state = {};
      this.queries = mapValues(queries, matchMedia);
    }

    componentWillMount() {
      for (let [key, media] of Object.entries(this.queries)) {
        const handleChange = () => {
          this.setState({
            [key]: media.matches,
          });
        };

        media.onchange = handleChange;
        handleChange();
      }
    }

    componentWillUnmount() {
      for (let media of Object.values(this.queries)) {
        media.onchange = null;
      }
    }

    render() {
      return <Component {...this.state} {...this.props} />;
    }
  }

  return MediaQueries;
};

module.exports = withMediaQueries;
