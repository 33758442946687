'use strict'
{p, div, span, button, footer} = Exim.DOM
{Modal} = require 'components/elements/new-modal'

EditSlotSeriesModal = ({abort, resolve}) =>
  return React.createElement(Modal, {
    close: abort,
    title: 'Edit Recurring Slot',
    className: 'EditSlotSeriesModal',
  },
    p className: 'EditSlotSeriesModal-text',
      'Apply changes to:'
    footer className: 'EditSlotSeriesModal-footer',
      button {
        className: 'Button',
        title: 'Only this slot will be edited',
        onClick: () => resolve('one'),
      }, 'Only this'
      button {
        className: 'Button Button--primary',
        title: 'This and all future slots will be edited',
        onClick: () => resolve('future'),
      }, 'All future'
      button {
        className: 'Button Button--primary',
        title: 'All slots in this series will be edited',
        onClick: () => resolve('all'),
      }, 'All series'
  )

module.exports = EditSlotSeriesModal
