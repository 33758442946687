{appStore} = require 'stores'

FileAttach = require 'components/mixins/FileAttach'
FileItem   = require './FileItem'
{div, input, span, label, p, h3} = Exim.DOM

Upload = Exim.createView module.id,
  mixins: [FileAttach]

  close: ->
    @props.close()

  uploadAll: ->
    Promise.all(Object.keys(@refs).map (ref) => @refs[ref].upload()).then =>
      @setState allUploaded: true

  attachMore: (evt) ->
    @setState allUploaded: false
    @onFileAttach(evt)

  render: ->
    div className: 'DocumentUpload', onDragOver: @onDragOver, onDragLeave: @onDragLeave, onDrop: @onDrop,
      if @state.files.length
        div className: 'content',
          div className: 'content-block',
            @state.files.map (item) =>
              FileItem(onRemove: @onFileRemove(item), key: "#{item.file.name} #{item.attachedAt}", data: item, ref: item.file.name)
          if @state.allUploaded
            div className: 'DocumentUpload-buttons',
              label className: 'Button Button--small Button--primary',
                span 'Choose more files'
                input type: 'file', className: 'Input--fileHidden', onChange: @attachMore, multiple: true
              div className: 'Button Button--small Button--cancel', onClick: @close, 'Done'
          else
            div className: 'DocumentUpload-buttons',
              label className: 'Button Button--small Button-primary', onClick: @uploadAll,
                span "Upload file#{if @state.files.length > 1 then 's' else ''}"
              div className: 'Button Button--small Button--cancel', onClick: @close, 'Cancel'
      else
        div className: 'content',
          div className: 'content-block',
            p 'Choose files to upload. You can select more than one file at a time. You can also drag and drop files anywhere on this page to start uploading.'
          div className: 'DocumentUpload-buttons',
            label className: 'Button Button--small Button--primary',
              span 'Choose files'
              input type: 'file', className: 'Input--fileHidden', onChange: @onFileAttach, multiple: true
            div className: 'Button Button--small Button--cancel', onClick: @close, 'Cancel'
      div className: 'drag-file', style: { height: '24px' },
        h3 if @state.dragging then 'Drop Files To Attach' else ''

module.exports = Upload
