"use strict";
const { isPrimaryButton } = require("lib/utils");
const { observer } = require("mobx-react");
const { editSlotOrSeries } = require("../../helpers");
const { MIN_IN_PX } = require("stores/cal");

class SlotResizer extends React.Component {
  constructor(props) {
    super(props);

    this.resizingSlotStart = this.resizingSlotStart.bind(this);
    this.resizingSlotContinue = this.resizingSlotContinue.bind(this);
    this.handlePointerUp = this.handlePointerUp.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  resizingSlotStart(evt) {
    evt = evt.nativeEvent;
    if (!evt.isPrimary || !isPrimaryButton(evt)) return;
    evt.preventDefault();

    let { slot, onResizeStart } = this.props;
    this._memento = slot.saveState();
    this._startY = evt.y;

    onResizeStart();
    document.addEventListener("pointermove", this.resizingSlotContinue);
    document.addEventListener("pointerup", this.handlePointerUp);
    document.addEventListener("keyup", this.handleKeyUp);
  }

  resizingSlotContinue(evt) {
    if (!evt.isPrimary || !isPrimaryButton(evt)) return;
    evt.preventDefault();

    let startMin = this._memento.duration.asMinutes();
    let deltaMin = (evt.y - this._startY) / MIN_IN_PX;

    let { slot } = this.props;
    slot.duration = moment.duration(startMin + deltaMin, "minutes");
  }

  handlePointerUp(evt) {
    if (evt.isPrimary) {
      evt.preventDefault();
      this.resizingSlotEnd(false);
    }
  }

  handleKeyUp(evt) {
    if (evt.key == "Escape") {
      evt.preventDefault();
      this.resizingSlotEnd(true);
    }
  }

  async resizingSlotEnd(isAborted) {
    let { slot, onResizeEnd } = this.props;

    document.removeEventListener("pointermove", this.resizingSlotContinue);
    document.removeEventListener("pointerup", this.handlePointerUp);
    document.removeEventListener("keyup", this.handleKeyUp);

    try {
      if (isAborted) throw { name: "AbortError" };

      await editSlotOrSeries(this._memento, slot);
    } catch (err) {
      slot.restoreState(this._memento);

      if (err.name != "AbortError") {
        console.error(err);
        alert("Failed to edit the slot.");
      }
    }

    onResizeEnd();
  }

  render() {
    let { slot } = this.props;
    if (!slot.isEditable || slot.isDurationFixed) return null;

    return (
      <div
        className="ScheduleCalendarSlot-resizer"
        aria-hidden
        touch-action="none"
        onPointerDown={this.resizingSlotStart}
      >
        =
      </div>
    );
  }
}

SlotResizer.defaultProps = {
  onResizeStart: () => {},
  onResizeEnd: () => {},
};

module.exports = observer(SlotResizer);
