"use strict";
const ReactDOM = require("react-dom");
const { first, last, omit } = require("lodash");
const { cx, sortedFindClosest } = require("lib/utils");

const getX = (evt) => first(evt.touches).clientX;

class SnapContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onSwipeStart = ({ nativeEvent: evt }) => {
      this.startTime = evt.timeStamp;
      this.startX = getX(evt) - this.props.snapTo;
    };

    this.onSwipeContinue = ({ nativeEvent: evt }) => {
      if (!this.isValidEvent(evt)) return;

      let x = getX(evt) - this.startX;
      x = Math.max(this.xMin, x);
      x = Math.min(this.xMax, x);

      this.updateTransform(x);
    };

    this.onSwipeEnd = ({ nativeEvent: evt }) => {
      if (!this.isValidEvent(evt)) return;

      let x = sortedFindClosest(this.props.snapPoints, this.lastX);
      this.props.onSnap(x);
      this.updateTransform(x);
    };
  }

  get xMin() {
    return first(this.props.snapPoints);
  }

  get xMax() {
    return last(this.props.snapPoints);
  }

  updateTransform(x) {
    this.lastX = x;

    let el = ReactDOM.findDOMNode(this);
    if (el) {
      el.style.transform = `translateX(${x}px)`;
    }
  }

  isValidEvent(evt) {
    let time = evt.timeStamp - this.startTime;
    return time > this.props.minTime;
  }

  render() {
    let swipeableProps = {
      onTouchStart: this.onSwipeStart,
      onTouchMove: this.onSwipeContinue,
      onTouchEnd: this.onSwipeEnd,
      onTouchCancel: this.onSwipeEnd,
    };

    return (
      <div
        style={{
          transform: `translateX(${this.props.snapTo}px)`,
        }}
        className={this.props.className}
        {...(this.props.isSwipeable && swipeableProps)}
      >
        {this.props.children}
      </div>
    );
  }
}

SnapContainer.defaultProps = {
  isSwipeable: false,
  minTime: 100,
  snapPoints: [],
  snapTo: 0,
  onSnap: () => {},
};

module.exports = SnapContainer;
