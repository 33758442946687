const { PropTypes } = React;

class Expandable extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isExpanded: false };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleToggle() {
    this.props.handleToggle();
  }

  handleKeyPress(e) {
    const code = e.keyCode;
    if (code === 13 || (code === 17 && code === 18)) {
      this.handleToggle();
    }
    e.stopPropagation();
  }

  render() {
    let { title, isExpanded, isCompleted, children } = this.props;

    return (
      <button className="Expandables" onKeyUp={this.handleKeyPress}>
        <header
          className={`Expandables-header ${isExpanded ? "open" : "closed"} ${
            isCompleted && "completed"
          }`}
          onClick={this.handleToggle}
        >
          <h3 className="Expandables-title">{title}</h3>
        </header>
        {isExpanded && <div className="Expandables-table">{children}</div>}
      </button>
    );
  }
}

Expandable.propTypes = {
  title: PropTypes.string,
  isExpanded: PropTypes.bool,
  handleToggle: PropTypes.func,
};
Expandable.defaultProps = {
  title: "",
  isExpanded: false,
  handleToggle: () => undefined,
};

export default Expandable;
