{appStore}   = require 'stores'
attachHeader = require 'components/mixins/attachHeader'

{div, tch, fa}  = Exim.DOM

AuthDisclaimer = Exim.createView module.id,
  staticView: true
  noHeader: true
  fadeIn: true
  mixins: [attachHeader(null)]

  contextTypes:
    router: React.PropTypes.object.isRequired

  goToSecurity: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    query = if (@props.from)
      {data} = @props
      data.from = @props.from
      data
    else
      null
    @context.router.push {pathname: '/security', query}

  render: ->
    {security} = appStore.get('config')

    div className: 'AuthDisclaimer u-flex',
      div className: 'AuthDisclaimer-icon' # appears to be broken?
      div className: 'AuthDisclaimer-body',
        div className: 'AuthDisclaimer-text', dangerouslySetInnerHTML: { __html: security.short } if security.short
        tch className: 'AuthDisclaimer-link', handler: @goToSecurity, security.link if security.link

module.exports = AuthDisclaimer
