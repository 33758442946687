{ authStore } = require 'stores'

Validate = require 'components/mixins/Validate'
utils = require 'lib/utils'

{div, h3, form, ul, li, span, tch} = Exim.DOM

IdentityProvider = Exim.createView module.id,
  mixins: [Validate]

  listen: [
    'auth/error', 'auth/lookingUp'
    'app/config'
  ]

  signUp: (e) ->
    @signingUp = true
    e.preventDefault()

    email = @state.email
    password = @state.password

    for key in ['email', 'password']
      @removeErrors key
      @validate key

    if @state.errors?.length
      document.getElementById("labeled-input-#{@state.errors[0]}")?.focus()
      @forceUpdate()
      @signingUp = false
    else
      fn = =>
        @signingUp = false
        @props.next(email: email, password: password)

      authStore.actions.lookup({email, password}).then(fn() unless authStore.get('error'))

  render: ->
    email_change = (event) =>
      utils.screenReaderSpeak(document.getElementById('email').validationMessage, 'assertive')
      setTimeout =>
        unless @state.focused is 'email' or ~(@state.errors or []).indexOf 'email'
          email = @state.email
          authStore.actions.lookup({email}).then =>
            if authStore.get 'error'
              (@alreadyTakenEmails ?= {})[email] = true
            @validate 'email'
            @forceUpdate()
            return
        return
      , 100
      return

    div className: 'SignUpAccount--portal',
      h3 className: 'SignUpAccount--PaneTitle', (if @props.showOidc then 'Or create your own Portal Credentials' else 'Create your Crossover credentials')

      form className: 'SignUpAccount--portal-form', onSubmit: @signUp, ref: 'container', autoComplete: 'off',
        @labeledInput 'email', 'Account Email *' + (if @state.lookingUp and !@signingUp then ' ...' else ''), type: 'email', id: 'email', onChange: email_change, onBlur: email_change, requiredField: true, error: 'Email address is a required field'
        ul className: 'SignUpAccount--email-hints',
          li {}, 'You may choose any email address'
          li {}, 'Health data won\'t be sent via email'

        @labeledInput 'password', 'Create Password *', type: 'password', requiredField: true, error: 'Password is a required field'

        div className: 'pw-approval' + @errClass('password'),
          @buildPwAlerts('password', @state.password)
          span className: @pwApproval('chars'), '12+ long'
          ' \u00a0'
          span className: @pwApproval('lower'), 'lowercase'
          ' \u00a0'
          span className: @pwApproval('upper'), 'uppercase'
          ' \u00a0'
          span className: @pwApproval('number'), 'number'

        tch tagName: 'button', className: 'Button Button--action', style: { width: '100%' }, 'Continue Activation'

module.exports = IdentityProvider
