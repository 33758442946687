{div, h2, p, button, ul, li, tch} = Exim.DOM

AccountInfo = Exim.createView module.id,
  render: ->
    {flip} = @props
    div className: 'FlippedCard-back card-logo',
      div className: 'SignInInfo',
        h2 className: 'SignInInfo-header', 'Which activation step is for me?'
        p null,
          'Activate your Crossover Health account with your employee credentials'
        ul null,
          li null, 'Your work email, name and security credentials will be passed to Crossover Health'
          li null, 'You can still choose a personal email for notifications from the portal'
          li null, 'Your personal contact information can be edited after activation'
        p null,
          'Or activate using account credentials that you specify'
        ul null,
          li null, 'You will still need to supply your work email to help verify your eligibility'
          li null, 'Your personal information can be edited after activation'

      tch style: {marginTop: '20px'}, tagName: 'button', className: "SignInInfo-button Button Button--primary Button--large Button-extend Button--auth", handler: flip, 'Close'

module.exports = AccountInfo
