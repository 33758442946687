ReactDOM = require 'react-dom'
{div} = Exim.DOM

moving = false

Range = Exim.createView module.id,
  getDefaultProps: ->
    min: 0
    max: 100
    step: 1
    multiple: false
    className: ''

  getInitialState: ->
    first: 0
    last: 100
    width: @props.max - @props.min

  getData: ->
    diff = @props.max - @props.min
    firstPerc = @state.first
    lastPerc = @state.last
    firstVal = firstPerc / 100 * diff
    lastVal = lastPerc / 100 * diff
    [firstVal, lastVal]

  onStart: (evt) ->
    @moving = true
    @start = evt.clientX

  onMove: (type) -> (evt) =>
    if @moving
      pos = evt.clientX
      {left, width} = ReactDOM.findDOMNode(@).getBoundingClientRect()
      innerLeft = pos - left - 10
      leftPerc = innerLeft / width * 100
      return if leftPerc > 100 or leftPerc < 0
      state = {}
      state[type] = leftPerc
      @setState state
      @props.onMove?(@getData())

  onMouseLeave: ->
    @moving = false
    @start = null
    @props.onStop?(@getData())

  render: ->
    indicatorStyle = left: @state.first + '%', right: (100 - @state.last) + '%'

    div className: "Range #{@props.className}", onMouseLeave: @onMouseLeave,
      div className: 'Range-container',
        div className: 'Range-drag', onMouseDown: @onStart, onMouseMove: @onMove('first'), onMouseUp: @onMouseLeave,  style: left: @state.first + '%'
        if @props.multiple
          [
            div className: 'Range-indicator', style: indicatorStyle
            div className: 'Range-drag', onMouseDown: @onStart, onMouseMove: @onMove('last'), onMouseUp: @onMouseLeave,  style: left: @state.last + '%'
          ]

module.exports = Range
