import NativeSelect from "components/elements/native-select";
import { groupBy, map } from "lodash";

const renderType = (apptType) => {
  const { nameAndDuration, modalityName, id } = apptType;
  return (
    <option key={id} value={id} label={`${modalityName}: ${nameAndDuration}`}>
      {`${modalityName}: ${nameAndDuration}`}
    </option>
  );
};

const renderModality = (apptTypes, name) => {
  const apptTypesWithModalityName = apptTypes.map((apptType) => {
    apptType.modalityName = name;
    return apptType;
  });
  return (
    <optgroup key={name} label={name}>
      {apptTypesWithModalityName.map(renderType)}
    </optgroup>
  );
};

const groupByModality = (apptTypes) => {
  apptTypes = apptTypes.filter((aT) => !!aT);
  let openTypes = apptTypes.filter((aT) => aT.isOpen);
  let withMods = apptTypes.filter((aT) => aT.modality);
  let byMods = groupBy(withMods, (aT) => aT.modality.name);
  let noMods = apptTypes.filter((aT) => !aT.isOpen && !aT.modality);

  return [
    ...openTypes.map(renderType),
    ...map(byMods, renderModality),
    ...noMods.map(renderType),
  ];
};

const ApptTypeSelect = (props) => {
  let { value, types, onChange, multipleVisitTypesSelected, ...rest } = props;

  const handleChange = (evt) => {
    let id = evt.target.value;
    let apptType = types.find((aT) => !!aT && aT.id == id);
    if (apptType) {
      onChange(apptType);
    }
  };

  let id = value && value.id;
  if (id && !types.some((aT) => !!aT && aT.id == id)) {
    id = "";
  }

  return (
    <NativeSelect
      value={multipleVisitTypesSelected ? "" : id}
      placeholder={
        multipleVisitTypesSelected
          ? "Multiple Visit Types Selected"
          : "Select appointment type"
      }
      onChange={handleChange}
      {...rest}
    >
      {groupByModality(types)}
    </NativeSelect>
  );
};

ApptTypeSelect.defaultProps = {
  value: "",
  types: [],
  onChange: () => {},
};

module.exports = ApptTypeSelect;
