import computeChanges from "./compute-changes";
import slotsStore from "stores/new-slots";
const EditSlotSeriesModal = require("./edit-slot-series-modal");
const EditBookedSlotModal = require("./edit-booked-slot-modal");
const SlotSeriesModal = require("./slot-series-modal");
const { showModal } = require("components/elements/new-modal");

const getEditTarget = () => showModal(<EditSlotSeriesModal />);
const getConflictResolution = (appts) => {
  if (appts.length) {
    return showModal(<EditBookedSlotModal appts={appts} />);
  }
};

const updateSlot = async ({ isConflicted, slot }) => {
  if (isConflicted) {
    let conflictResolution = await getConflictResolution(slot.appointments);
    if (conflictResolution == "cancel") {
      await slot.delete();
    }

    let clone = slot.clone();
    clone.dropId();
    await clone.post();
    slotsStore.addSlot(clone);
    return clone;
  } else {
    await slot.update();
    return slot;
  }
};

const editSlotOrSeries = async (...args) => {
  let changes = computeChanges(...args);
  let { isConflicted, slot, series } = changes;

  switch (changes.type) {
    case "SLOT_UPDATED": {
      await updateSlot(changes);
      break;
    }

    case "SERIES_CREATED": {
      let { summary } = await series.createFromSlot({ dryRun: true, slot });
      await showModal(
        <SlotSeriesModal title="Create Series" metrics={summary} />
      );

      let conflictResolution = await getConflictResolution(slot.appointments);
      const newSlot = await updateSlot(changes);
      await series.createFromSlot({ slot: newSlot, conflictResolution });
      break;
    }

    case "SERIES_UPDATED": {
      switch (await getEditTarget()) {
        case "one": {
          slot.removeSeries();
          await updateSlot(changes);
          break;
        }

        case "future": {
          series.slot = slot;

          let { date } = slot;
          let { summary } = await series.updateFuture({ dryRun: true, date });
          await showModal(
            <SlotSeriesModal title="Edit Series" metrics={summary} />
          );

          let conflictResolution = await getConflictResolution(
            summary.conflictedAppointments
          );
          await series.updateFuture({ date, conflictResolution });

          // TODO: avoid updating the whole calendar
          let calStore = require("stores/cal");
          calStore.fetchSlots();
          break;
        }

        case "all": {
          series.slot = slot;

          let { summary } = await series.update({ dryRun: true });
          await showModal(
            <SlotSeriesModal title="Edit Series" metrics={summary} />
          );

          let conflictResolution = await getConflictResolution(
            summary.conflictedAppointments
          );
          await series.update({ conflictResolution });
          break;
        }
      }
      break;
    }

    case "SERIES_DELETED": {
      switch (await getEditTarget()) {
        case "one": {
          await updateSlot(changes);
          break;
        }

        case "future": {
          let { date } = slot;
          let { summary } = await series.deleteFuture({ dryRun: true, date });
          await showModal(
            <SlotSeriesModal title="Delete Series" metrics={summary} />
          );

          let conflictResolution = await getConflictResolution(
            summary.conflictedAppointments
          );
          await series.deleteFuture({ date, conflictResolution });
          break;
        }

        case "all": {
          let { summary } = await series.delete({ dryRun: true });
          await showModal(
            <SlotSeriesModal title="Delete Series" metrics={summary} />
          );

          let conflictResolution = await getConflictResolution(
            summary.conflictedAppointments
          );
          await series.delete({ conflictResolution });
          break;
        }
      }
      break;
    }
  }
};

module.exports = editSlotOrSeries;
