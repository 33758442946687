"use strict";
import { client } from "config";
const { observer } = require("mobx-react");
const { cx } = require("lib/utils");
const calStore = require("stores/cal");
const { MIN_IN_PX } = calStore;
const SlotDraggable = require("./slot-draggable");
const SlotResizer = require("./slot-resizer");
const { getSlotTitle, getNotesTitle } = require("./slot-title");
const { showModal } = require("components/elements/new-modal");
const SlotModal = require("../slot-modal");
const { flatMap } = require("lodash");

class Slot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isResizing: false,
    };
  }

  get isMoving() {
    let { moving } = calStore;
    if (!moving) return false;

    return moving.slot.is(this.props.slot);
  }

  get isActive() {
    let { slot } = this.props;

    return !slot.isCreated || this.state.isResizing || this.isMoving;
  }

  calcStyle() {
    let { slot, lane, lanesCount } = this.props;
    let { start } = calStore.displayRange;

    let top = slot.startTime.diff(start, "minutes") * MIN_IN_PX;
    let height = calStore.getHeight(slot.duration);
    let apptType = slot.bookedAppointmentType || slot.appointmentType;

    return {
      top: `${top + 1}px`,
      left: `${(lane / lanesCount) * 100}%`,
      width: `${(1 / lanesCount) * 100}%`,
      height: `${height - 2}px`,
      "--color": apptType.color,
    };
  }

  showModal() {
    let { slot } = this.props;
    showModal(<SlotModal slot={slot.saveState()} />);
  }

  renderLabel() {
    let { slot } = this.props;

    let renderedApptType = slot.bookedAppointmentType || slot.appointmentType;
    let { label } = renderedApptType;

    if (slot.multipleVisitTypesSelected) {
      return slot.bookedAppointmentType && slot.bookedAppointmentType.label ? (
        <span className="ScheduleCalendarSlot-label">
          {slot.bookedAppointmentType.label}
        </span>
      ) : (
        <div className="ScheduleCalendarSlot-label ScheduleCalendarSlot-label-multiple-appts">
          <i className="fa fa-check-square-o" />
          <span>{slot.appointmentTypesList.length}</span>
        </div>
      );
    } else if (label) {
      return <span className="ScheduleCalendarSlot-label">{label}</span>;
    } else {
      return null;
    }
  }

  renderIcons() {
    let { slot } = this.props;
    let notes = flatMap(slot.appointments, (appt) => appt.latestNotes).filter(
      (note) => note.isText
    );

    return (
      <div className="ScheduleCalendarSlot-icons">
        {slot.isReferralsOnly && (
          <span
            className="ScheduleCalendarSlot-icon ScheduleCalendarSlot-icon-referrals"
            title="XOP referrals only"
          />
        )}
        {slot.isConflicted && (
          <span
            className="ScheduleCalendarSlot-icon ScheduleCalendarSlot-icon-conflicted"
            title="Previously booked appointment in conflict queue"
          />
        )}
        {notes.length > 0 && (
          <span
            className="ScheduleCalendarSlot-icon ScheduleCalendarSlot-icon-notes"
            title={getNotesTitle(notes)}
          />
        )}
        {slot.isRepeated && (
          <span
            className="ScheduleCalendarSlot-icon ScheduleCalendarSlot-icon-repeated"
            title="Repeated slot"
          />
        )}
        {slot.isPrivate && (
          <span
            className="ScheduleCalendarSlot-icon ScheduleCalendarSlot-icon-private"
            title="Private slot"
          />
        )}
        {slot.isPublic && (
          <span
            className="ScheduleCalendarSlot-icon ScheduleCalendarSlot-icon-public"
            title="Public slot"
          />
        )}
      </div>
    );
  }

  renderCount() {
    let { slot } = this.props;
    if (!slot.isGroup) return;

    let conflictedCount = slot.conflictedAppts.length;
    let notConflictedCount = slot.appointments.length - conflictedCount;

    return (
      <span className="ScheduleCalendarSlot-count" title="Booked group slots">
        {notConflictedCount}/{slot.maxCapacity}
        {conflictedCount > 0 && (
          <span className="ScheduleCalendarSlot-conflictedCount">
            {conflictedCount}/{slot.maxCapacity}
          </span>
        )}
      </span>
    );
  }

  render() {
    let { slot } = this.props;
    let apptType = slot.bookedAppointmentType || slot.appointmentType;
    let slotClass = cx("ScheduleCalendarSlot", {
      active: this.isActive,
      group: slot.isGroup,
      hold: slot.isHold,
      public: slot.isPublic,
      booked: slot.isBooked,
      filled: slot.isFilled,
      conflicted: slot.isConflicted,
      partiallyConflicted: slot.isPartiallyConflicted,
      initial: apptType.isInitial,
      checkInPending: slot.isCheckInPending,
      checkInCompleted: slot.isCheckInCompleted,
    });

    return (
      <article
        className={slotClass}
        title={getSlotTitle(slot, { hideReason: client === "cork" })}
        style={this.calcStyle()}
        onClick={() => this.showModal()}
        onContextMenu={(evt) => evt.preventDefault()}
      >
        <header className="ScheduleCalendarSlot-header">
          <time
            className="ScheduleCalendarSlot-duration"
            dateTime={slot.duration.toISOString()}
          >
            {slot.duration.asMinutes()} min
          </time>
          {this.renderLabel()}
          <SlotDraggable slot={slot} />
          {this.renderIcons()}
        </header>
        <div className="ScheduleCalendarSlot-text">
          {slot.patientName}
          {slot.isBooked && <br />}
          {apptType.isInternal && apptType.name}
        </div>
        {this.renderCount()}
        <SlotResizer
          slot={slot}
          onResizeStart={() => this.setState({ isResizing: true })}
          onResizeEnd={() => this.setState({ isResizing: false })}
        />
      </article>
    );
  }
}

module.exports = observer(Slot);
