import { observer } from "mobx-react";
import { ISO_TIME } from "lib/formats";
import { onlyTime } from "lib/utils";
import NativeSelect from "components/elements/native-select";

const TIME_FORMAT = "h:mm A";
const renderStartOption = (startTime) => {
  let iso = startTime.format(ISO_TIME);

  return (
    <option key={iso} value={iso}>
      {startTime.format(TIME_FORMAT)}
    </option>
  );
};

export const StartTimeSelect = observer(({ slot }) => {
  const changeStartTime = (evt) => {
    let { value } = evt.target;
    let { zone } = slot.site;

    slot.startTime = onlyTime(moment.tz(value, ISO_TIME, zone));
  };

  return (
    <NativeSelect
      placeholder="Start time"
      value={slot.startTime.format(ISO_TIME)}
      disabled={!slot.isEditable || slot.isBooked}
      onChange={changeStartTime}
    >
      {slot.availableStartTimes.map(renderStartOption)}
    </NativeSelect>
  );
});

export const EndTimeSelect = observer(({ slot }) => {
  const renderEndOption = (duration) => {
    let iso = duration.toISOString();
    let endTime = slot.startTime.clone().add(duration);

    return (
      <option key={iso} value={iso}>
        {endTime.format(TIME_FORMAT)} ({duration.asMinutes()} min)
      </option>
    );
  };

  const changeDuration = (evt) => {
    let { value } = evt.target;

    slot.duration = moment.duration(value);
  };

  return (
    <NativeSelect
      placeholder="End time"
      value={slot.duration.toISOString()}
      disabled={!slot.isEditable || slot.isBooked || slot.isDurationFixed}
      onChange={changeDuration}
    >
      {slot.availableDurations.map(renderEndOption)}
    </NativeSelect>
  );
});
