timelineStore = require 'stores/timeline'
authStore     = require 'stores/auth'
screenStore   = require 'stores/screen'
appStore      = require 'stores/app'
utils         = require 'lib/utils'
ReactDOM      = require 'react-dom'
screenConfig  = require 'components/screen/config'
Fields        = require 'components/mixins/Fields'
Spinner       = require 'components/elements/Spinner'
Img           = require 'components/elements/Img'
FileAttach    = require 'components/mixins/FileAttach'
Orders        = require './Orders'

{defaultAvatar} = require 'config'
{cx} = Exim.helpers
{div, h3, h4, span, label, button, img, input, i, em, fa, p}  = Exim.DOM

subjectDivider = ': '

Post = Exim.createView module.id,
  mixins: [
    authStore.connect('user', 'impersonationTarget')
    timelineStore.connect('entries', 'postCreating', 'signinOff', 'attaching', 'removingEntry')
    Fields
    FileAttach
  ]

  dataName: 'reply'
  required: ['body']

  getInitialState: ->
    reply:
      body: ''
    modalOpened: false
    responseInput: false
    note: @props.post.body

  getInitialData: ->
    reply: @state.reply
    note: @state.note

  submit: (type) -> =>
    {reply} = @state
    reply.type = type
    {id} = @props.post

    timelineStore.actions.reply(id, reply).then =>
      @setState reply: {body: ''}
      ReactDOM.findDOMNode(@refs.body).value = ''

  attach: ->
    {id} = @props.post
    data =
      documents: @state.files

    timelineStore.actions.attach(id, data).then =>
      @setState files: []

  previewDoc: (doc) -> ->
    if utils.humanizeMime doc.mime is 'Image'
      title = doc.title
      body = div className: 'image-modal', Img src: doc.fullUrl
      logo = false
      modal = {title, body, logo}
      appStore.actions.showModal(modal)
    else
      window.open(doc.fullUrl, '_blank').focus()

  signOff: ->
    {id, status} = @props.post
    return unless status is 'draft'
    appStore.actions.showModal(null)
    timelineStore.actions.signOff(id)

  addData: ->
    {origin, user_id} = @props.post
    return unless origin
    data =
      user_id: user_id
      patient_id: origin?.patient_id or patient_id
      appointment_id: origin?.appointment_id or 999999
      appointment_at: new Date()

    screenStore.actions.setup(data.patient_id, data.appointment_id, data.appointment_at).then =>
      authStore.actions.startImpersonation(data.user_id).then =>
        @transitionTo 'screen-step', step: screenConfig.firstStep

  handleReplyButton: (type) -> (evt) =>
    @submit(type).call() if evt.which is 13

  toggleExpanded: (id) -> =>
    @props.toggleExpanded(id)

  toggleResponseModal: (evt) ->
    opened = !@state.modalOpened
    state = {modalOpened: opened}
    state.responseInput = false if !opened
    @setState state

  toggleResponseInput: (evt) ->
    resp = !@state.responseInput
    @setState responseInput: resp

  closeResponseModal: (evt) ->
    @setState modalOpened: false

  fillNote: (text) -> =>
    @closeResponseModal()
    if text
      @setState note: text
      @saveNote(text)
    else
      @setState note: ''
      ReactDOM.findDOMNode(@refs.note).focus()

  confirm: ->
    body = div className: 'content-wrapper',
      div className: 'content',
        div className: 'content-block',
          h4 'Post Message Draft'
          span 'This makes all future updates patient facing.'

    footer = (params) =>
      div className: 'u-flex u-flexJustifyBetween',
        div className: 'Button Button--cancel Button--small Button--pointer', onClick: params.close,
          'Cancel'
        div className: 'spacer'
        div className: 'Button Button--primary Button--small Button--pointer', onClick: @signOff,
          'Confirm'
    modal =
      title: 'Confirmation'
      className: 'timeline-post-modal'
      body: body
      footer: footer
    appStore.actions.showModal(modal)

  saveNote: (note = @state.note) ->
    {id} = @props.post
    return unless note.length > 0
    timelineStore.actions.update(id, note).then =>
      ReactDOM.findDOMNode(@refs.note).blur()

  handleNoteSave: (evt) ->
    @saveNote() if evt.which is 13

  submitResponse: (evt) ->
    if evt.which is 13
      {value} = evt.currentTarget
      timelineStore.actions.addEntry(value).then =>
        @toggleResponseInput()

  onNoteChange: (evt) ->
    {value} = evt.currentTarget
    @setState note: value

  removeEntry: (id) -> (evt) ->
    evt.preventDefault()
    evt.stopPropagation()
    timelineStore.actions.removeEntry(id)

  render: ->
    {id, subject, body, type, sender, signed_at, created_at, replies, documents, status, origin, patient_id, orders, expanded} = @props.post
    {expanded, entries} = @props

    user = if usr = sender
      full_name: usr.full_name or "#{usr.first_name} #{usr.last_name}"
      avatar_url: usr.avatar_url or defaultAvatar
    else
      @state.user

    isAdmin = @state.user.admin or @state.user.proxying
    replies = replies.filter((r) -> isAdmin or r.reply_type isnt 'draft_comment')
    replyType = if status is 'draft' then 'draft_comment' else 'comment'

    subject = if subject.length > 140 then subject.substring(0,140) + '...' else subject

    div className: "timeline-post #{cx expanded: expanded is id}",
      div className: 'timeline-post-author',
        div className: 'timeline-post-author-avatar',
          img src: utils.getAvatarUrl(user.avatar_url)
        div className: 'timeline-post-author-name',
          user.full_name
          div className: 'timeline-post-author-timestamp',
            utils.formatDateNicely(created_at)
      div className: 'timeline-post-content',
        div className: "timeline-post-content-header #{cx draft: status is 'draft'}",
          unless type is 'checkin'
            subject
          else
            subj = subject.split(subjectDivider)
            if subj.length > 1
              visitType = subj.splice(0,1)[0] + subjectDivider
              reason = if subj.length > 0 then subj.join(subjectDivider) else ''
              [
                span className: 'visit-type', visitType
                span className: 'reason', reason
              ]
            else
              subject

        div className: 'timeline-post-content-body', onDragOver: @onDragOver, onDragLeave: @onDragLeave, onDrop: @onDrop,
          div className: 'timeline-post-content-note',
            div className: 'timeline-post-content-note-input',
              input type: 'text', ref: 'note', value: @state.note, onChange: @onNoteChange, className: 'timeline-post-content-note-text', placeholder: 'Enter Your Message...', onKeyUp: @handleNoteSave
              unless @state.note.length
                div className: "button responses #{cx clicked: @state.modalOpened}", onClick: @toggleResponseModal, 'Responses'
            if @state.modalOpened
              div className: 'timeline-post-content-note-modal',
                div className: 'section',
                  div className: 'section-title',
                    'Saved'
                  div className: 'section-list',
                    entries.map (item) =>
                      div className: 'section-list-item', onClick: @fillNote(item.text),
                        item.text
                        div className: 'section-list-item-delete', onClick: @removeEntry(item.id),
                          fa 'times'
                div className: 'section',
                  div className: 'section-title',
                    'Add'
                  div className: 'section-list',
                    unless @state.responseInput
                      div className: 'section-list-item', onClick: @toggleResponseInput,
                        'Add new response'
                    else
                      div className: 'section-list-item',
                        input type: 'text', ref: 'resp', onKeyUp: @submitResponse
                div className: 'section',
                  div className: 'section-title',
                    'New'
                  div className: 'section-list',
                    div className: 'section-list-item', onClick: @fillNote(),
                      'Insert new message'

          if @state.dragging
            div className: 'timeline-post-content-body-hover-file',
              h3 'Drop Files To Attach'
        div className: 'timeline-post-content-additions',
          if ordersLength = orders?.length
            div className: 'timeline-post-content-additions-item',
              fa 'flask'
              "#{ordersLength} Order#{if ordersLength > 1 then 's' else ''}"
          if screenings = replies?.filter((reply) -> reply.reply_type is 'screening')?.length
            div className: 'timeline-post-content-additions-item',
              fa 'flask'
              "#{screenings} Screening#{if screenings > 1 then 's' else ''}"
          if attachments = documents?.length
            div className: 'timeline-post-content-additions-item',
              fa 'files-o'
              "#{attachments} Attachment#{if attachments > 1 then 's' else ''}"
        if @state.files.length
          div className: 'timeline-post-content-create-attachments',
            @state.files.map (item, i) =>
              div className: 'image-wrapper', key: "#{item.src} #{i}",
                img src: item.src, onClick: @preview(item),
                div className: 'image-wrapper-top',
                  div className: 'image-wrapper-top-close', onClick: @onFileRemove(item), '✕'
        div className: 'timeline-post-content-actions',
          div className: 'timeline-post-content-actions-wrapper',
            div className: 'timeline-post-content-actions-action',
              'Attach Files'
              input className: 'attach-file', type: 'file', onChange: @onFileAttach, multiple: true
            div className: "timeline-post-content-actions-action #{cx selected: expanded is id}", onClick: @toggleExpanded(id),
              'Orders'
            div className: 'timeline-post-content-actions-action', onClick: @addData,
              'Add Data'
          if type is 'checkin' and (@state.user.id is sender.id or @state.user.admin_id is sender.id)
            signed = status is 'signed'
            [
              Spinner state: @state.signinOff,
                label className: 'sign-off',
                  label className: "checkbox-signoff #{cx checked: signed}",
                    input type: 'checkbox', className: 'checkbox-signoff-input', checked: signed, onChange: @confirm,
                    div className: 'checkbox-rect'
                    span className: 'label',
                      "Sign#{if signed then 'ed' else ' Off'}"
                    if signed and signed_at
                      span className: 'sublabel',
                        utils.formatDateNicely(signed_at, true)
            ]
          if @state.files.length
            [
              div className: 'spacer'
              Spinner state: @state.attaching,
                div className: 'button send', onClick: @attach, 'Attach'
            ]
        div className: 'timeline-post-expand-wrapper',
          if documents?.length
            div className: 'timeline-post-content-attachments',
              div className: 'timeline-post-content-attachments-label', 'Attachments'
              documents.map (doc) =>
                doc.fullUrl = utils.getUrl(doc.url.slice(1))
                div className: 'timeline-post-content-attachments-file',
                  Img className: 'timeline-post-content-attachments-file-preview', src: doc.fullUrl, onClick: @previewDoc(doc)

          if replies.length
            div className: 'timeline-post-content-thread',
              replies.map (reply, replyKey) =>
                isScreening = reply.reply_type is 'screening'
                div className: "timeline-post-content-thread-message #{cx disabled: (status isnt 'draft' and reply.reply_type is 'draft_comment'), 'is-screening': isScreening}", key: replyKey,
                  div className: 'timeline-post-content-thread-message-author',
                    div className: 'timeline-post-content-thread-message-author-avatar',
                      img src: utils.getAvatarUrl(reply.sender?.avatar_url)
                    div className: 'timeline-post-content-thread-message-author-dash'
                  div className: 'timeline-post-content-thread-message-body',
                    div className: 'timeline-post-content-thread-message-body-text',
                      if reply.sender
                        [
                          span className: 'timeline-post-content-thread-message-body-sender',
                            "#{reply.sender.first_name} #{reply.sender.last_name} "
                          switch reply.reply_type
                            when 'screening'
                              [
                                if len = reply.body.length
                                  s = if len > 1 then 's' else ''
                                  [
                                    "updated #{len} metric#{s}:"
                                    div null,
                                      reply.body.map (i) ->
                                        div "#{i.name} = #{i.value} #{i.units}"
                                  ]
                              ]
                            when 'attachment'
                              div className: 'timeline-post-content-thread-message-attachments',
                                reply.documents.map (doc) =>
                                  doc.fullUrl = utils.getUrl(doc.url.slice(1))
                                  div className: 'timeline-post-content-thread-message-attachments-file',
                                    Img className: 'timeline-post-thread-message-attachments-file-preview', src: doc.fullUrl, onClick: @previewDoc(doc)
                            else
                              [
                                span(reply.body)
                              ]
                        ]

                    div className: 'timeline-post-content-thread-message-body-timestamp',
                      utils.formatDateNicely(reply.created_at)

          div className: 'timeline-post-content-reply',
            @field 'body', className: 'timeline-post-content-reply-text', useValue: true, placeholder: 'Type Reply...', onKeyDown: @handleReplyButton
            if status is 'draft'
              @button className: 'update', state: @state.replying, onSubmit: @submit(replyType), 'Update'
            else
              @button className: 'send', state: @state.replying, onSubmit: @submit(replyType), 'Post'
        div className: 'timeline-post-content-orders',
          Orders({post: @props.post, toggleExpanded: @props.toggleExpanded})

module.exports = Post
