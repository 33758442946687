TIMEOUT = 1000 # 1 second

Bouncer =
  resetInterval: (fn) ->
    clearTimeout window.bouncerTimeout
    window.bouncerTimeout = setTimeout fn, TIMEOUT

  startBouncer: (inp, fn) ->
    window.bouncerTimeout ?= setTimeout fn, timTIMEOUTeout
    inp.addEventListener 'keyup', @resetInterval(fn)

module.exports = Bouncer
