appStore     = require 'stores/app'
patientStore = require 'stores/patient'
authStore    = require 'stores/auth'

Img          = require 'components/elements/Img'
Spinner      = require 'components/elements/Spinner'
Footer       = require 'components/app/Footer'
ReactCropper      = require 'components/elements/Cropper'
attachHeader = require 'components/mixins/attachHeader'
{cx} = Exim.helpers

utils = require 'lib/utils'
config = require 'config'
{main, div, spinner, h2, h3, label, select, option, span, p, a, input, textarea, tch, strong, form, fa} = Exim.DOM

Edit = Exim.createView module.id,
  mixins: [
    appStore.connect('config')
    patientStore.connect('patient', 'patientUpdating')
    authStore.connect('user', 'avatarUploading')
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  componentWillMount: ->
    appStore.actions.setHeader(name: 'Your Profile Photo')
    @setState uploadedFile: authStore.get('user').avatar_url
  
  componentWillUnmount: ->
    { cropArea, neDragHandle, nwDragHandle, seDragHandle, swDragHandle } = @state
    cropArea.removeEventListener("keydown", @handleKeyDownOnCropArea)
    neDragHandle.removeEventListener("keydown", @handleKeyDownOnDragHandles);
    nwDragHandle.removeEventListener("keydown", @handleKeyDownOnDragHandles);
    seDragHandle.removeEventListener("keydown", @handleKeyDownOnDragHandles);
    swDragHandle.removeEventListener("keydown", @handleKeyDownOnDragHandles);


  toProfileEdit: ->
    @context.router.push '/profile/edit'

  stopPropagation: (e) ->
    e.stopPropagation()


  onCrop: ->
    Promise.resolve(@state.cropper.getCroppedCanvas(config.avatarSize).toDataURL(@state.file?.type)).then (dataUrl) =>
      @setState cropped: dataUrl

  rotate: ->
    utils.screenReaderSpeak("Image rotated ninety degrees counter clockwise.", "assertive")
    @state.cropper.rotate(-90)

  save: ->
    @state.cropper.getCroppedCanvas(config.avatarSize).toBlob (blob) =>
      authStore.actions.uploadAvatar(file: blob).then =>
        @toProfileEdit()
  
  onCropperInitialized: (cropper) ->
    @setState({ cropper: cropper})

  handleKeyDownOnCropArea: (e) ->
    cropper = @state.cropper
    currentData = cropper.getData()
    newData = Object.assign(currentData, {})
    switch (e.key)
      # move to the left
      when 'ArrowLeft'
        e.preventDefault()
        e.stopPropagation()
        newX = currentData.x - 1
        newData.x = newX 
        cropper.setData(newData)
        @reFocusTarget(e.target)
      # Move to the top
      when 'ArrowUp'
        e.preventDefault()
        e.stopPropagation()
        newY = currentData.y - 1
        newData.y = newY
        cropper.setData(newData)
        @reFocusTarget(e.target)
      # Move to the right
      when 'ArrowRight'
        e.preventDefault()
        e.stopPropagation()
        newX = currentData.x + 1
        newData.x = newX 
        cropper.setData(newData)
        @reFocusTarget(e.target)
      # Move to the bottom
      when 'ArrowDown'
        e.preventDefault()
        e.stopPropagation()
        newY = currentData.y + 1
        newData.y = newY
        cropper.setData(newData)
        @reFocusTarget(e.target)
      else
        return

  handleKeyDownOnDragHandles: (e) ->
    cropper = @state.cropper
    action = e.target.getAttribute('data-cropper-action')
    switch (action)
      when 'ne'
        @handleKeyDownOnNeDragHandle(e, cropper)
      when 'nw'
        @handleKeyDownOnNwDragHandle(e, cropper)
      when 'se'
        @handleKeyDownOnSeDragHandle(e, cropper)
      when 'sw'
        @handleKeyDownOnSwDragHandle(e, cropper)

  handleKeyDownOnNeDragHandle: (e, cropper) ->
    switch (e.key)
      when 'ArrowRight'
        @expandCropperTowardNeCorner(e, cropper)
      when 'ArrowUp'
        @expandCropperTowardNeCorner(e, cropper)
      when 'ArrowDown'
        @contractCropperTowardSwCorner(e, cropper)
      when 'ArrowLeft'
        @contractCropperTowardSwCorner(e, cropper)
  
  handleKeyDownOnSeDragHandle: (e, cropper) ->
    switch (e.key)
      when 'ArrowRight'
        @expandCropperTowardSeCorner(e, cropper)
      when 'ArrowDown'
        @expandCropperTowardSeCorner(e, cropper)
      when 'ArrowUp'
        @contractCropperTowardNwCorner(e, cropper)
      when 'ArrowLeft'
        @contractCropperTowardNwCorner(e, cropper)
  
  handleKeyDownOnSwDragHandle: (e, cropper) ->
    switch (e.key)
      when 'ArrowLeft'
        @expandCropperTowardSwCorner(e, cropper)
      when 'ArrowDown'
        @expandCropperTowardSwCorner(e, cropper)
      when 'ArrowUp'
        @contractCropperTowardNeCorner(e, cropper)
      when 'ArrowRight'
        @contractCropperTowardNeCorner(e, cropper)
  
  handleKeyDownOnNwDragHandle: (e, cropper) ->
    switch (e.key)
      when 'ArrowLeft'
        @expandCropperTowardNwCorner(e, cropper)
      when 'ArrowUp'
        @expandCropperTowardNwCorner(e, cropper)
      when 'ArrowDown'
        @contractCropperTowardSeCorner(e, cropper)
      when 'ArrowRight'
        @contractCropperTowardSeCorner(e, cropper)
  
  mutateCropper: (evt, cropper, changeFunction) ->
    evt.preventDefault()
    evt.stopPropagation()
    previousData = cropper.getData()
    mutableDataCopy = Object.assign(previousData, {})
    newData = changeFunction(previousData, mutableDataCopy)
    cropper.setData(newData)
    @reFocusTarget(evt.target)

  expandCropperTowardNeCorner: (e, cropper) ->
    changeFunction = (previous, updated) =>
      updated.height = previous.height + 1
      updated.width = previous.width + 1
      updated.y = previous.y - 1
      return updated
    @mutateCropper(e, cropper, changeFunction)
  
  expandCropperTowardNwCorner: (e, cropper) ->
    changeFunction = (currentData, newData) =>
      newData.height = currentData.height + 1
      newData.width = currentData.width + 1
      newData.y = currentData.y - 1
      newData.x = currentData.x - 1
      return newData
    @mutateCropper(e, cropper, changeFunction)
  
  expandCropperTowardSeCorner: (e, cropper) ->
    changeFunction = (currentData, newData) =>
      newData.height = currentData.height + 1
      newData.width = currentData.width + 1
      return newData
    @mutateCropper(e, cropper, changeFunction)
  
  expandCropperTowardSwCorner: (e, cropper) ->
    changeFunction = (currentData, newData) =>
      newData.height = currentData.height + 1
      newData.width = currentData.width + 1
      newData.x = currentData.x - 1
      return newData
    @mutateCropper(e, cropper, changeFunction)
  
  contractCropperTowardNeCorner: (e, cropper) ->
    changeFunction = (currentData, newData) =>
      newData.height = currentData.height - 1
      newData.width = currentData.width - 1
      newData.x = currentData.x + 1
      return newData
    @mutateCropper(e, cropper, changeFunction)

  contractCropperTowardNwCorner: (e, cropper) ->
    changeFunction = (currentData, newData) =>
      newData.height = currentData.height - 1
      newData.width = currentData.width - 1
      return newData
    @mutateCropper(e, cropper, changeFunction)

  contractCropperTowardSeCorner: (e, cropper) ->
    changeFunction = (currentData, newData) =>
      newData.height = currentData.height - 1
      newData.width = currentData.width - 1
      newData.y = currentData.y + 1
      newData.x = currentData.x + 1
      return newData
    @mutateCropper(e, cropper, changeFunction)
  

  contractCropperTowardSwCorner: (e, cropper) ->
    changeFunction = (currentData, newData) =>
      newData.height = currentData.height - 1
      newData.width = currentData.width - 1
      newData.y = currentData.y + 1
      return newData
    @mutateCropper(e, cropper, changeFunction)
  

  # for some reason even with the e.preventDefaults focus is moving off the cropper
  # after an arrow key is pressed, so this hack returns focus to the target
  reFocusTarget: (target) ->
    focusTarget = => target.focus()
    setTimeout focusTarget, 0

  onCropperReady: (_evt) ->
    ariaLabels = {
      cropArea: 'Use the arrow keys to move the crop selection area',
      neDragHandle: 'Use the arrow keys to move the north east drag handle to re-size the crop selection area',
      nwDragHandle: 'Use the arrow keys to move the north west drag handle to re-size the crop selection area',
      seDragHandle: 'Use the arrow keys to move the south east drag handle to re-size the crop selection area',
      swDragHandle: 'Use the arrow keys to move the south west drag handle to re-size the crop selection area',
    }
    # Add necessary dom attr's and event listners
    cropArea = document.getElementsByClassName("cropper-view-box")[0];
    @setState({ cropArea: cropArea})
    cropArea.setAttribute('tabindex', "0") 
    cropArea.setAttribute('role', "button") 
    cropArea.setAttribute('aria-label', ariaLabels.cropArea)
    cropArea.addEventListener("keydown", @handleKeyDownOnCropArea)
    cropArea.focus()

    neDragHandle = document.getElementsByClassName("cropper-point point-ne")[0];
    @setState({ neDragHandle: neDragHandle})
    neDragHandle.setAttribute('id', "ReactCropperCropPoint") 
    neDragHandle.setAttribute('tabindex', "0")
    neDragHandle.setAttribute('role', "button") 
    neDragHandle.setAttribute('aria-label', ariaLabels.neDragHandle)
    neDragHandle.addEventListener("keydown", @handleKeyDownOnDragHandles);

    nwDragHandle = document.getElementsByClassName("cropper-point point-nw")[0];
    @setState({ nwDragHandle: nwDragHandle})
    nwDragHandle.setAttribute('id', "ReactCropperCropPoint") 
    nwDragHandle.setAttribute('aria-label', ariaLabels.nwDragHandle)
    nwDragHandle.setAttribute('role', "button") 
    nwDragHandle.setAttribute('tabindex', "0")
    nwDragHandle.addEventListener("keydown", @handleKeyDownOnDragHandles);

    seDragHandle = document.getElementsByClassName("cropper-point point-se")[0];
    @setState({ seDragHandle: seDragHandle})
    seDragHandle.setAttribute('id', "ReactCropperCropPoint") 
    seDragHandle.setAttribute('aria-label', ariaLabels.seDragHandle)
    seDragHandle.setAttribute('role', "button") 
    seDragHandle.setAttribute('tabindex', "0")
    seDragHandle.addEventListener("keydown", @handleKeyDownOnDragHandles);

    swDragHandle = document.getElementsByClassName("cropper-point point-sw")[0];
    @setState({ swDragHandle: swDragHandle})
    swDragHandle.setAttribute('id', "ReactCropperCropPoint") 
    swDragHandle.setAttribute('aria-label', ariaLabels.swDragHandle)
    swDragHandle.setAttribute('role', "button") 
    swDragHandle.setAttribute('tabindex', "0")
    swDragHandle.addEventListener("keydown", @handleKeyDownOnDragHandles);

    # Remove redundant drag handles - our crop is set to always be square so 
    # we only need resizing handles on the corners
    nDragHandle = document.getElementsByClassName("cropper-point point-n")[0];
    sDragHandle = document.getElementsByClassName("cropper-point point-s")[0];
    eDragHandle = document.getElementsByClassName("cropper-point point-e")[0];
    wDragHandle = document.getElementsByClassName("cropper-point point-w")[0];
    nDragHandle.remove()
    sDragHandle.remove()
    eDragHandle.remove()
    wDragHandle.remove()

  render: ->
    {patient, user, error, uploadedFile, avatarUploading} = @state

    main id: 'mainContent', className: 'Content ProfileEdit ProfilePhoto',
      div className: 'Content-body Content-body--article',
        Spinner className: 'u-flexChild', state: false,#!patient.id,
          div className: "ContentInner",
            div className: 'MessageActions',
              a href: '/profile/edit', className: 'Button Button--back Button--pointer MessageActions-backButton', style: {marginTop: '12px'},
                'Back to Edit Profile'

            div className: 'ContentBlock',
              h3 ' CROP & ROTATE YOUR PROFILE PHOTO '
              Spinner state: !uploadedFile and avatarUploading,
                div className: 'ProfilePhoto-preview',
                  Img src: @state.cropped or utils.getAvatarUrl(uploadedFile), onError: @uploadError, alt: "User Profile Avatar"
                  span 'To the left is what your profile image will look like. You can rotate or drag around and resize the square below to get it just how you like it. When you are happy with your icon, click save to finish.'

                div className: 'ProfilePhoto-crop',
                  ReactCropper
                    ref: 'cropper'
                    src: utils.getAvatarUrl(uploadedFile)
                    aspectRatio: 1
                    style: {maxHeight: '65vh', width: '100%'}
                    crop: @onCrop
                    onInitialized: @onCropperInitialized
                    ready: @onCropperReady

                div className: 'ProfileEdit-row',
                  div className: 'ProfilePhoto-buttons',
                    tch tagName: "button", className: "Button Button--withMargin Button--plainText Button--pointer", handler: @rotate, onEnter: @rotate,
                      fa 'undo'
                      span 'Rotate'
                    tch tagName: "button", className: "Button Button--withMargin Button--pointer", handler: @toProfileEdit, onEnter: @toProfileEdit,
                      "Cancel"
                    tch tagName: "button", className: "Button Button--withMargin Button--pointer Button--primary Button--#{cx disabled: avatarUploading}", handler: @save, onEnter: @save, ref: 'savePhoto', tabIndex: 0,
                      "Save Photo"

        Footer()

module.exports = Edit
