import withDirty from "./with-dirty";
import { Simulate } from "react-dom/test-utils";
import { has, get, set } from "lodash";

const getKey = (el) => {
  if (el instanceof HTMLInputElement) {
    switch (el.type) {
      case "checkbox":
        return "checked";
      // case 'date': return 'valueAsDate'
      // case 'number': return 'valueAsNumber'
    }
  }

  return "value";
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  *formElements() {
    let { elements } = this.formRef.current;
    let { dataSource } = this.props;

    for (let el of elements) {
      if (el.matches("button")) continue;

      let { name } = el;
      if (!name) continue;

      yield [name, elements.namedItem(name)];
    }
  }

  loadFromSource() {
    let { dataSource } = this.props;
    for (let [name, el] of this.formElements()) {
      el[getKey(el)] = get(dataSource, name) || "";

      if (el instanceof HTMLSelectElement) {
        Simulate.change(el);
      }
    }
  }

  saveToSource() {
    let { dataSource } = this.props;
    for (let [name, el] of this.formElements()) {
      let value = el[getKey(el)];
      set(dataSource, name, value);
    }
  }

  componentDidMount() {
    this.loadFromSource();
  }

  render() {
    let { dataSource: _, afterSubmit, ...formProps } = this.props;

    return (
      <form
        {...formProps}
        ref={this.formRef}
        onSubmit={(evt) => {
          evt.preventDefault();
          this.saveToSource();
          afterSubmit();
        }}
      />
    );
  }
}

Form.defaultProps = {
  dataSource: {},
  afterSubmit: () => {},
};

export default withDirty(Form, {
  type: "onInvalidCapture",
});
