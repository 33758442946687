{appStore, authStore, changelogStore} = require 'stores'

Footer       = require 'components/app/Footer'
attachHeader = require 'components/mixins/attachHeader'

utils    = require 'lib/utils'
{formats} = require 'config'

{main, section, div, tch, span, fa, h2, h3, button} = Exim.DOM

ITEMS_PER_PAGE = 3

WhatsNewPage = Exim.createView module.id,
  listen: ['changelog/items']
  mixins: [attachHeader(name: "What's New")]

  getInitialState: ->
    page: 1

  componentWillMount: ->
    changelogStore.actions.fetch()

  componentDidMount: ->
    appStore.actions.setTitle("What's New")

  componentWillUnmount: ->
    appStore.actions.setTitle()

  loadMore: ->
    user = authStore.get('user')
    {page, items} = @state

    newPage = page + 1
    
    itemsCount = page * ITEMS_PER_PAGE

    if items.length > page * ITEMS_PER_PAGE
      @setState {page: newPage}, -> 
        document.querySelector('#item-'+itemsCount).focus()

  render: ->
    {page, items} = @state
    user = authStore.get('user')

    itemsCount = page * ITEMS_PER_PAGE
    showLoadMore = items.length > itemsCount

    main id: 'mainContent', className: 'Content',
      section className: 'Content-body',
        div className: 'ContentInner WhatsNew',
          items.slice(0, itemsCount).map (item, i) ->
            div className: 'WhatsNewItem', key: 'item-'+i,
              if date = item.date
                div className: 'WhatsNewItem-date', date
              h2 className: 'WhatsNewItem-title', id: 'item-'+i, tabIndex: 0,
                item.title
              div className: 'WhatsNewItem-body', dangerouslySetInnerHTML: { __html: item.content }
          if showLoadMore
            div className: 'u-center u-fullWidth', id: 'loadmore-container',
              button className: 'Button Button--primary', onClick: utils.a11yClick(@loadMore), onKeyPress: utils.a11yClick(@loadMore),
                'Load More Updates '
                fa 'angle-down'
        Footer()


module.exports = WhatsNewPage
