{authStore, appStore} = require 'stores'

config   = require 'config'
utils    = require 'lib/utils'
Validate = require 'components/mixins/Validate'
Tooltip  = require 'components/elements/Tooltip'
Disclaimer = require 'components/auth/Disclaimer'
ConfirmationModal = require 'components/elements/ConfirmationModal'

{Link} = Exim.Router
{cx}   = Exim.helpers
{div, p, spinner, input, label, span, button, form, tch, select, option, hr, h4} = Exim.DOM
detailsKeys = ['first_name', 'last_name', 'dob', 'gender', 'employee_id', 'work_email', 'ssn_last4', 'dob_m', 'dob_d', 'dob_y', 'relationship']

Details = Exim.createView module.id,
  mixins: [Validate]

  listen: [
    'auth/error', 'auth/lookingUp',
    'auth/email', 'auth/password',
    'auth/signingUp'
    'app/config'
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    state = {}
    state.oauth = !!@props.location?.query?.oauth

    if data = @props.location?.query
      detailsKeys.forEach (key) ->
        state[key] = data[key] if data[key]
    state.tooltip = {}

    if data.dob
      dob = moment(data.dob)
      state['dob_y'] = dob.year() unless dob.year() is 0
      state['dob_m'] = dob.month() + 1
      state['dob_d'] = dob.date()

    state.email = state.work_email if state.oauth
    state.displayEmailWarning = false
    state.emailWarning = ''
    state

  componentDidMount: ->
    @signupType = appStore.get('config')?.signup?.type or 'employee'
    @domain = appStore.get('config')?.signup?.domain or 'work'
    @forceUpdate()

  componentWillUpdate: (nextProps, nextState) ->
    if nextState.config isnt @state.config
      @signupType = nextState.config?.signup?.type or 'employee'

  signUp: (e) ->
    e.preventDefault()

    return if @state.signingUp

    data =
      email:      @state.email
      first_name: @state.first_name
      last_name:  @state.last_name
      dob:        @state.dob
      agreement:  @state.agreement

    if @state.oauth
      data.employee_id = @state.employee_id if @state.employee_id
    else
      data.password = @state.password

    switch @signupType
      when 'employee'
        data.employee_id = @state.employee_id
        data.relationship = @state.relationship
      when 'ssn'
        data.ssn_last4 = @state.ssn_last4
        data.gender = @state.gender?.toLowerCase()[0]
      when 'email', 'mail'
        data.work_email = @state.work_email
        data.gender = @state.gender?.toLowerCase()[0]
      when 'eid'
        data.employee_id = @state.employee_id
        data.relationship = @state.relationship

    for key in [].concat ['dob_d', 'dob_m', 'dob_y'], Object.keys data
      @validate key

    if @state.errors?.length
      @forceUpdate()
    else
      authStore.actions.signup(data).then(=> @props.forward()).catch(=> @props.forward())

    return

  goToSignin: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    @context.router.push pathname: '/signin'

  showAgreement: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()
    body = require('components/modals/Agreement')
    modal = {body}

    appStore.actions.showModal(modal)

  dobFields: ->
    [
      div key: 'dob-label', className: @errClass('dob_m', 'dob_d', 'dob_y'),
        label className: 'dob-label', htmlFor: 'dob_m', 'Date of Birth'
      div key: 'dob-fields', className: 'dob-wrap',
        @unlabeledSelect 'dob_m', 'Month', @months, {html: {classes: cx('is-disabled': @state.signingUp) + ' dob_m dob-wrap-dob_m SimpleSelect SimpleSelect--angle SimpleSelect--auth', props: disabled: @state.signingUp}, defaultValue: @state.dob_m}
        @unlabeledSelect 'dob_d', 'Day',   @days,   {html: {classes: cx('is-disabled': @state.signingUp) + ' dob_d dob-wrap-dob_d SimpleSelect SimpleSelect--angle SimpleSelect--auth', props: disabled: @state.signingUp}, defaultValue: @state.dob_d}
        @unlabeledSelect 'dob_y', 'Year',  @years,  {html: {classes: cx('is-disabled': @state.signingUp) + ' dob_y dob-wrap-dob_y SimpleSelect SimpleSelect--angle SimpleSelect--auth', props: disabled: @state.signingUp}, defaultValue: @state.dob_y}
    ]

  closeModal: ->
    appStore.actions.showModal(null)

  showDependentsModal: ->
    body = ConfirmationModal
      title: 'Account Activation for Dependents'
      text: "Please enter the primary policy holder's work email address in the Company Email Address field. This will allow us to confirm eligibility.\n\nPlease use your own email address to create your account"
      onConfirm: @closeModal
      confirmText: 'Close'

    modal =
      className: 'Modal--small'
      body: body()

    appStore.actions.showModal(modal)

  validateEmail: ->
    {work_email} = @state
    {validations} = @state.config?.signup
    if !!work_email and validations?.email
      validations.email.forEach (validation_check) =>
        re = new RegExp(validations.email[0].check)
        validationMatch = work_email.match(validation_check.check)
        if validationMatch and @state.displayEmailWarning is false
          @setState {displayEmailWarning: true, emailWarning: validation_check.message}
          return
        else if !validationMatch and @state.displayEmailWarning is true
          @setState {displayEmailWarning: false, emailWarning: ''}
    else if @state.displayEmailWarning is true
      @setState {displayEmailWarning: false, emailWarning: ''}

  render: ->
    {signingUp, displayEmailWarning, emailWarning} = @state
    config = appStore.get('config')
    style = display: if @props.isActive then 'inherit' else 'none'
    div {className: 'SignupSection SignupSection--details', style},
      form className: 'SignupSection--form', onSubmit: @signUp, ref: 'container', autoComplete: 'off', disabled: signingUp,
        div className: 'SignupSection--logo'
        h4 'Activate your account to access an all new health care experience'

#         if err = @state.error
#           p className: 'error', err

        div className: 'input-wrapper',
          div className: 'signup-field-res odd',
            @labeledInput 'first_name', 'First Name', disabled: signingUp

          div className: 'signup-field-res even',
            @labeledInput 'last_name', 'Last Name', disabled: signingUp

          switch @signupType
            when 'employee'
              [
                div key: 'dob-wrap', className: 'signup-field-res odd',
                  @dobFields()

                div key: 'eid-wrap', className: 'signup-field-res even eid-wrap',
                  div className: 'split2 odd',
                    @labeledInput 'employee_id', 'Employee ID', type: 'number', disabled: signingUp

                  div className: 'split2 even ext-wrap-relationship',
                    div className: @errClass('relationship'),
                      label htmlFor: 'relationship', 'Relationship'
                    @unlabeledSelect 'relationship', 'Select One', ['Employee', 'Spouse', 'Domestic Partner', 'Child'], html: { classes: cx('is-disabled': signingUp) + 'relationship eid-wrap-relationship SimpleSelect SimpleSelect--angle SimpleSelect--auth', props: disabled: signingUp }
              ]
            when 'ssn'
              [
                div key: 'dob-wrap', className: 'signup-field-res odd',
                  @dobFields()

                div key: 'eid-wrap', className: 'signup-field-res even eid-wrap',
                  div className: 'split2 odd',
                    @labeledInput 'ssn_last4', 'SSN (Last 4)', type: 'number', disabled: signingUp

                  div className: 'split2 even',
                    div key: 'dob-label', className: @errClass('gender'),
                      label className: 'dob-label', htmlFor: 'gender', 'Gender'
                    if isCork or isSingapore
                      @unlabeledSelect 'gender', 'Select One', ['Female', 'Male', 'Other'], html: { classes: cx('is-disabled': signingUp) + 'SimpleSelect SimpleSelect--angle SimpleSelect--auth', props: disabled: signingUp }, id: 'gender'
                    else
                      @unlabeledSelect 'gender', 'Select One', ['Female', 'Male', 'Intersex'], html: { classes: cx('is-disabled': signingUp) + 'SimpleSelect SimpleSelect--angle SimpleSelect--auth', props: disabled: signingUp }, id: 'gender'
              ]
            when 'email', 'mail'
              [
                div key: 'info-email', className: 'signup-field-res full',
                  @labeledInput 'work_email','Company Email Address of Eligible Employee', placeholder: "Your #{@domain} email", type: 'email', disabled: signingUp or @state.oauth, onChange: @validateEmail()
                  tch className: 'SignupSection-modalLink', handler: @showDependentsModal, 'Signing up as a dependent?'

                if displayEmailWarning and emailWarning
                  div className: 'SignupSection-validationWarning', dangerouslySetInnerHTML: {__html: @state.emailWarning}

                if @state.oauth
                  div key: 'account-email', className: 'signup-field-res full',
                    @labeledInput 'email', 'Preferred Email for Contact & Notifications', placeholder: 'Your contact email address', type: 'email', disabled: signingUp
                    div className: 'SignupSection-preferredEmailInfo', 'Where do you want emails from the clinic to be sent?'

                div key: 'dob-wrap', className: 'signup-field-res full',
                  div className: 'split2 odd',
                    @dobFields()
                  div className: 'split2 even',
                    div key: 'dob-label', className: @errClass('gender'),
                      label className: 'dob-label', htmlFor: 'gender', 'Gender'
                    @unlabeledSelect 'gender', 'Select One', ['Female', 'Male', 'Other'], html: { classes: cx('is-disabled': signingUp) + ' SimpleSelect SimpleSelect--angle SimpleSelect--auth', props: disabled: signingUp }, id: 'gender'
              ]
            when 'eid'
              [
                div key: 'eid-wrap', className: 'signup-field-res odd eid-wrap',
                  div className: 'split2 odd',
                    @labeledInput 'employee_id', 'Employee ID', type: 'number', disabled: signingUp

                  div className: 'split2 even',
                    div key: 'dob-label', className: @errClass('gender'),
                      label className: 'dob-label', htmlFor: 'gender', 'Gender'
                    @unlabeledSelect 'gender', 'Select One', ['Female', 'Male', 'Other'], html: { classes: cx('is-disabled': signingUp) + 'SimpleSelect SimpleSelect--angle SimpleSelect--auth', props: disabled: signingUp }, id: 'gender'

                div key: 'dob-wrap', className: 'signup-field-res even',
                  @dobFields()

              ]
            else
              []

          hr()

          div className: 'signup-field-res full action-box',
            @checkboxPanel 'agreement',
              span 'I agree to the',
                tch tagName: 'a', href: '#', handler: @showAgreement, 'User agreement'
              disabled: signingUp

            button className: cx('is-disabled': signingUp) + ' Button Button--primary Button--large Button--auth Button--signUp', type: 'submit', disabled: signingUp,
              (if signingUp then 'Activating your account' else 'Activate your account')

          div className: 'FlippedSectionLinks Button--pointer footer-link', ref: 'links',
            tch tagName: 'span', handler: @goToSignin, 'Already have an account?'
            tch tagName: 'a', className: 'flipper-login-button', handler: @goToSignin, 'Sign In'

      if (security = config?.security) and Object.keys(security).length
        data = {}
        detailsKeys.forEach (key) =>
          data[key] = @state[key]
        Disclaimer from: 'details', data: data


module.exports = Details
