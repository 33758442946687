"use strict";
const { appStore } = require("stores");
const wellnessStore = require("stores/wellness").default;
const { Link } = require("react-router");
const { observer } = require("mobx-react");
const { mdDown } = require("lib/media-queries");
const { getIdFromUrl } = require("lib/utils");
const {
  ContentHeader,
  Main,
  withMediaQueries,
} = require("components/elements");
const Footer = require("components/app/Footer");
const { isEmpty } = require("lodash");

class Wellness extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    appStore.actions.setHeader(null);
  }

  render() {
    if (isEmpty(wellnessStore.programs)) return null;

    const { pathname, query } = this.props.location;
    const programs = wellnessStore.programs;
    const survey = wellnessStore.getSurvey(programs[0]);

    const width = 100 / (1 + programs.length);
    const buttonClass = "WellnessView-view-toggle-opt";
    const isSelected = `is-selected`;
    const defaultPath = "/wellness";
    const overviewPath = `${defaultPath}/overview`;
    const resultPath = `${defaultPath}/result`;
    const surveyPath = `survey/hra?id=${getIdFromUrl(survey.url)}`;

    return (
      <div className="NewContent">
        <ContentHeader
          title="Wellness Incentive Program"
          className="Wellness-wrapper"
        >
          {!survey.ineligible && survey.ongoing && (
            <Link to={surveyPath} className="Button WellnessView-button-survey">
              {this.props.isPhone ? "" : survey.label} SURVEY
            </Link>
          )}
        </ContentHeader>
        <Main className="Main--wellness">
          <div className="WellnessView-view-toggle">
            <Link
              to={overviewPath}
              className={`${buttonClass} ${
                (pathname === overviewPath || pathname === defaultPath) &&
                isSelected
              }`}
              style={{ width: `${width}%` }}
            >
              PROGRAM OVERVIEW
            </Link>
            {programs.map((item, i) => {
              const id = getIdFromUrl(item.url);
              const fullResultPath = `${resultPath}?id=${id}`;
              const label =
                item.wellness_program && item.wellness_program.button_label;
              return (
                <Link
                  key={i}
                  to={fullResultPath}
                  className={`${buttonClass} ${id === query.id && isSelected}`}
                  style={{ width: `${width}%` }}
                >
                  {label}
                </Link>
              );
            })}
          </div>
        </Main>
        {this.props.children}
        {Footer()}
      </div>
    );
  }
}

module.exports = withMediaQueries(observer(Wellness), { isPhone: mdDown });
