{clone} = require 'lib/utils'
{div, input, label} = Exim.DOM

defaultPlaceholder = 'Select'

DateField = Exim.createView module.id,
  propTypes:
    onChange: React.PropTypes.func
    isDisabled: React.PropTypes.func

  getInitialState: -> {}

  getDefaultProps: ->
    format: 'YYYY-MM-DD'
    placeholder: defaultPlaceholder

  handleChange: (evt) ->
    if evt.currentTarget.value
      formatted = moment(evt.currentTarget.value, 'YYYY-MM-DD').toDate()
      @setState selected: formatted
      @props.onChange(formatted) if @props.onChange

  render: ->
    props = clone @props
    props.value = moment(@state.selected).format(@props.format) if @state.selected
    props.type = 'date'
    props.onChange = @handleChange
    props.style = unless @state.selected then width: '1px', height: '1px', opacity: 0, zIndex: '-50' else {}
    label null,
      unless @state.selected
        div className: 'placeholder', props.placeholder
      input props

module.exports = DateField
