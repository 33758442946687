"use strict";
const { appStore } = require("stores");
const { toPhone } = require("lib/utils");

const renderLocation = ({ location, phone, email }) => {
  return (
    <section key={location} className="SignupError-location">
      <h3 className="SignupError-location-name">{location}</h3>
      {phone && (
        <div className="SignupError-location-phone">
          Phone: <a href={`tel:${phone}`}>{toPhone(phone)}</a>
        </div>
      )}
      {email && (
        <div className="SignupError-location-email">
          Email: <a href={`mailto:${email}`}>{email}</a>
        </div>
      )}
    </section>
  );
};

const SignupError = (props) => {
  let locations = (appStore.get("config").app || {}).help || [];

  return (
    <div className="SignupError" id='sign-up-error' tabIndex='0'>
      <header className="SignupError-header">
        <h2 className="SignupError-title">Uh, oh!</h2>
        <div className="SignupError-subTitle">
          <p className="SignupError-message">
            Sorry, we could not confirm your identity.
          </p>
          <p className="SignupError-contact">
            That’s okay! Usually this is because something didn’t match in our
            records, like your email address. You can{" "}
            <a
              aria-label="try registering again"
              tabIndex={0}
              className="underline"
              onClick={props.goToSignUpAccount}
            >
              try again
            </a>
            , or simply call the Member Support number for your care team:
          </p>
        </div>
      </header>
      <div className="SignupError-locations">
        {locations.map(renderLocation)}
      </div>
      <footer className="SignupError-footer">
        You can also always visit us at your local Crossover Health Center!
      </footer>
    </div>
  );
};

module.exports = SignupError;
