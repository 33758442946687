{ timelineStore } = require 'stores'
utils = require 'lib/utils'

Search = require 'components/mixins/Search'

{div, h2, h3, input, fa} = Exim.DOM

Orders = Exim.createView module.id,
  mixins: [Search, timelineStore.connect('posts', 'order', 'orderTypes', 'date')]

  searchKeys: -> ['name', 'type']

  search: (evt) ->
    {value} = evt.currentTarget
    @onSearch(value)

  addOrder: (order) -> (evt) ->
    timelineStore.actions.addOrder(order)

  removeOrder: (key, order) -> ->
    timelineStore.actions.removeOrder(key, order)

  placeOrder: ->
    timelineStore.actions.placeOrder(@props.post.id).then =>
      @props.toggleExpanded(@props.post.id)

  render: ->
    {id} = @props.post
    {order, orderTypes} = @state

    used = (item) ->
      !(item.name in (order[item.type] or []))

    div className: 'orders',
      div className: 'orders-divider'
      div className: 'orders-wrapper',
        div className: 'orders-main',
          div className: 'orders-header',
            'In-House Orders'
          div className: 'orders-body',
            Object.keys(order).map (key) =>
              div className: 'orders-section',
                div className: 'orders-section-header', utils.capitalize("#{key}s")
                  order[key].map (item) =>
                    div className: 'orders-section-item',
                      div className: 'title',
                        item
                      div className: 'spacer'
                      div className: 'close', onClick: @removeOrder(key, item),
                        fa 'times-circle'
          div className: 'orders-main-footer',
            div className: 'Button', onClick: @placeOrder, 'Place Order'
        div className: 'orders-sidebar',
          div className: 'orders-sidebar-search',
            div className: 'search-input',
              input className: 'Input', type: 'search', placeholder: 'Search', onChange: @search
          div className: 'orders-sidebar-types-header', 'Order Type'
          div className: 'orders-sidebar-types',
            orderTypes.filter(used).filter(@filterer).map (orderType) =>
              div className: 'orders-sidebar-types-item', onClick: @addOrder(orderType),
                div className: 'title',
                  orderType.name
                div className: 'type',
                  orderType.type

module.exports = Orders
