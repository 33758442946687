import checkInStore from "stores/new-check-in";
import synopsisStore from "stores/new-synopsis";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { fromAbout } from "./helpers";
import Modal from "components/elements/Modal";
import withIdleTimer from "components/elements/with-idle-timer";
import adminRoute from "components/mixins/adminRoute";
import ContentHeader from "components/elements/content-header";
import CheckInNav from "./check-in-nav";
import Main from "components/elements/main";
import { first, fromPairs, every } from "lodash";

import oldAppStore from "stores/app";
import oldCheckInStore from "stores/checkin";
import oldSynopsisStore from "stores/synopsis";

const pageTitles = {
  "about": "About",
  "reason": "Reason for Visit",
  "meds": "Medications",
  "dental": "Dental",
  "conditions": "Medical Conditions",
  "lifestyle": "Lifestyle",
  "consents": "Consents",
  "patient_screenings": "Screenings",
}

const toPair = (consent) => [first(consent), false];
const CheckIn = React.createClass({
  displayName: "CheckIn",
  mixins: [
    oldAppStore.connect("modal"), // TODO: transit to new modals
    oldAppStore.connect("title"),
    adminRoute({ isAdmin: true, allowProxying: true, pin: true }),
  ],

  async componentWillMount() {
    if (checkInStore.getStep() && checkInStore.getStep().id == "thanks") return;

    let { router, location, route } = this.props;
    try {
      checkInStore.isFetched = false;
      await oldCheckInStore.actions.fetch();

      let checkin = oldCheckInStore.get("checkin");
      if (!checkin.appointment_id) {
        await oldCheckInStore.actions.changeState("list");
        router.push("/schedule");
        return;
      }

      await oldSynopsisStore.actions.fetch();

      let user = fromAbout(checkin.about);
      let { required = [] } = checkin;
      let consents = {
        ...fromPairs(required.map(toPair)),
        ...checkin.consents,
      };
      if(pageTitles[checkInStore.getStep().id]) {
        oldAppStore.actions.setTitle(pageTitles[checkInStore.getStep().id])
      }

      checkInStore.site = synopsisStore.sites.find(
        (s) => s.code == checkin.site
      );
      checkInStore.isTiny = !!checkin.tiny;
      checkInStore.hasDental = "dental" in checkin;
      checkInStore.hasLifeStyle = "lifestyle" in checkin;
      checkInStore.hasScreening = "patient_screenings" in checkin;
      checkInStore.hasSMSReminders = checkin.smss;
      checkInStore.hasOAuth = (checkin.oauth_links || []).length > 0;
      checkInStore.lastStepId = checkin.lastStep;
      checkInStore.consents = consents;
      checkInStore.otherConsents = required;
      checkInStore.user = user;
      checkInStore.portal = {};
      checkInStore.isConsented = user.isConsented && every(consents);
      checkInStore.xop_member = checkin.xop_member;

      user.fetchInsurance();

      if ("last" in location.query) {
        checkInStore.lastStep.activate();
      }

      this.unsetLeaveHook = router.setRouteLeaveHook(route, ({ action }) => {
        if (action == "POP") {
          router.replace(location.pathname);
          return false;
        }

        return true;
      });
    } catch (err) {
      console.error(err);
      router.push("/schedule");
    } finally {
      checkInStore.isFetched = true;
    }
  },

  componentDidUpdate() {
    if (this.state.title && this.state.title.split(" - ")[1] !== pageTitles[checkInStore.getStep().id]) {
      oldAppStore.actions.setTitle(pageTitles[checkInStore.getStep().id])
    }
  },

  componentWillUmount() {
    oldCheckInStore.actions.clearCheckin();
    let fn = this.unsetLeaveHook;
    if (fn) fn();

    checkInStore.clear();
  },

  renderForm() {
    if (!checkInStore.isFetched) return;
    let step = checkInStore.getStep();
    let stepNavs = checkInStore.getNavs();

    return (
      <React.Fragment>
        <ContentHeader title={step.title} withSidebar={false} />
        <CheckInNav stepNavs={stepNavs} />
        <Main className="Main--checkIn">{this.props.children}</Main>
      </React.Fragment>
    );
  },

  render() {
    let step = checkInStore.getStep();
    let { modal } = this.state;
    if (!step) return null;

    return (
      <React.Fragment>
        {step.isForm ? this.renderForm() : this.props.children}
        {modal && Modal(modal)}
      </React.Fragment>
    );
  },
});

export default withIdleTimer(withRouter(observer(CheckIn)));
