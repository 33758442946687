{authStore, patientStore} = require 'stores'

Spinner    = require 'components/elements/Spinner'
IsEmpty    = require 'components/elements/IsEmpty'
MessagesFilters = require './MessagesFilters'
media      = require 'components/mixins/MediaQuery'
sort       = require 'components/mixins/sort'
utils      = require 'lib/utils'
{cx}       = Exim.helpers
{div, tch, fa, span, table, tr, th, td}      = Exim.DOM

MessagesList = Exim.createView module.id,
  mixins: [
    media(mobile: 'md')
    authStore.connect('user')
    patientStore.connect('patient')
    sort(type: 'date', asc: false, arrays: ['to'], objects: ['from'])
  ]

  componentWillUpdate: (nextProps, nextState) ->
    if nextState.sort isnt @state.sort
      @props.onSortChange(nextState.sort)

  toggleFilter: (sortKey) ->
    @sort(sortKey)

  getTableSortingInfo: (fields) ->
    fields.map (title, i) =>
      type = if title is 'received' or title is 'sent' then 'date' else title
      sortDirection = @getAriaSortDirection(type)
      if sortDirection then  "column #{i+1} #{title} sorted #{sortDirection}"

  getColumnsLength: (type, fields) ->
    if type in ['received', 'flagged', 'unread', 'sent'] then return fields?.length + 1 else return fields?.length

  render: ->
    {messages, fields, loading, View, pageControls, page, pages, className, filter, onUnload, applyFilter, onSearch, matcher, clearSearch, filtersList} = @props
    {mobile} = @state

    div {},
      if applyFilter
        MessagesFilters { ref: 'filter', onUnload, applyFilter, filter, onSearch, clearSearch, filtersList}
      div role: 'grid', 'aria-label': @props.type, 'aria-rowcount': messages?.length, 'aria-colcount': @getColumnsLength(@props.type, fields), 'aria-describedby': "tableInfo", className: 'Table Table--fullWidth Table--bordered Table--bordered-md-down Table--padded-md-down',
        div className: "TableHeader TableHeader--flex TableHeaderMessages TableHeaderMessages-#{@props.type}", role: 'row',
          div className: 'TableHeader-column MessagesList-item-flag u-noselect', key: 'flag', tabIndex: 0, role: 'columnheader', 'aria-label': "column 1 actions", fa 'flag' if @props.type in ['received', 'flagged', 'unread', 'sent']
          fields.map (title, i) =>
            type = if title is 'received' or title is 'sent' then 'date' else title
            sortDirection = @getAriaSortDirection(type)
            tch tagName: 'div', tabIndex: 0, role: 'columnheader', 'aria-label': "column #{i+2} #{title} #{if sortDirection then "sorted #{sortDirection}" else ''}", className: "TableHeader-column u-1of#{fields.length} u-noselect #{@isSorted(type)}", onEnter: @toggleFilter(type, sortDirection, title), handler: @toggleFilter(type, sortDirection, title), key: i, title
        div className: 'TableBody TableBody--zoom',
          IsEmpty ifEmpty: 'No messages', loading: loading,
            messages.map (data, i) ->
              key = (if data.from then 'from' else 'to') + data.id
              View({data, key, page, pages, matcher, messageNumber: i+1})
          pageControls() if pageControls
      div id: "tableInfo", hidden: "true", 'aria-hidden': "true", @getTableSortingInfo(fields)
      div 'aria-live': 'assertive', className: 'sr-only', "#{ if @props.matcher then "#{@props.messages?.length} #{if @props.messages?.length == 1 then "result for #{@props.matcher}" else "results for #{@props.matcher}"}" else ''}"
      div 'aria-live': 'polite', className: 'AriaLiveForScreenReaders', ref: 'hidden', ''
      div className: 'invisible', 'aria-live': 'assertive', " #{if messages.length > 0 then "#{messages.length} results" else "No messages" }"

module.exports = MessagesList
