import hraStore from "stores/hra-survey";
import { observer } from "mobx-react";
import CardioVascularResults from "./cardio-results";
import PromisResults from "./promis-results";
import FitnessResults from "./fitness-results";
import { toJS } from "mobx";
import HealthScreening from "./health-screening";

class ResultsContent extends React.Component {
  printResults() {
    window.print();
  }

  renderResults(results) {
    return results.map(
      ({ type, name, value, scale, status, skipped_reason }) => {
        switch (type) {
          case "fitness_assessment":
            return <FitnessResults title={name} value={value} key={type} />;
          case "cv_risk_score":
            return (
              <CardioVascularResults
                title={name}
                value={value}
                key={type}
                scale={scale}
                status={status}
                skipped_reason={skipped_reason}
              />
            );
          case "promis":
            return (
              <PromisResults
                title={name}
                value={value}
                key={type}
                value={value}
              />
            );
          default:
            return null;
        }
      }
    );
  }

  render() {
    const { results, hraScreeningStatus } = hraStore;
    return (
      <div className="LandingWidgets">
        <HealthScreening
          title={"Your Personal Wellness Incentive Program Status"}
          screeningStatus={hraScreeningStatus}
        />
        <div className="HraResults-intro">
          <span className="HraResults-statement">
            {" "}
            This is only one step towards a healthier you!{" "}
          </span>{" "}
          <br />
          <span className="HraResults-intro--body">
            <br />
            We encourage you to take advantage of your preventative health
            coverage by scheduling your annual health maintenance visit with
            your primary care doctor. This way you can make sure you are staying
            current on preventative recommendations such as screenings for high
            blood pressure and cancer, along with immunizations while discussing
            a plan that makes sense to you.
            <p className="HraResults-intro--print">
              <button
                title="Print"
                onClick={this.printResults}
                className="HraResults-intro--print-btn"
              >
                <i className="fa fa-print"></i>
              </button>{" "}
              Click this icon to print and/or download a copy of these results
              for your records.
            </p>
          </span>
        </div>
        {this.renderResults(toJS(results))}
      </div>
    );
  }
}

export default observer(ResultsContent);
