documentsStore = require 'stores/documents'
authStore = require 'stores/auth'
appStore = require 'stores/app'
Zoom = require 'components/elements/Zoom'
ConfirmationModal = require 'components/elements/ConfirmationModal'
DocumentPreview   = require './DocumentPreview'
{div, video, source, tch, span, a, fa} = Exim.DOM
utils = require 'lib/utils'
{cx} = Exim.helpers
BackSwiper = require('components/elements/back-swiper')
{withRouter} = require('react-router')
NAV_WIDTH = 100
BACK_TO = '/documents'

DocumentView = React.createClass({
  displayName: 'DocumentView',
  mixins: [
    authStore.connect('user'),
    documentsStore.connect('documents'),
  ],

  getInitialState: ->
    docs = documentsStore.get('documents').filter(@props.filterer)
    index = null
    doc = docs.find (d, i) =>
      result = d.id is +@props.params?.id
      index = i if result
      result
    if doc
      doc.prev = docs[index-1]
      doc.next = docs[index+1]
    item: doc or {}

  componentWillMount: ->
    if !@state.documents.length
      @props.fetch()

  componentWillUpdate: (nextProps, nextState) ->
    if !nextState.item.id
      docs = nextState.documents.filter(@props.filterer)
      index = null
      doc = docs.find (d, i) =>
        result = d.id is +@props.params.id
        index = i if result
        result
      if doc
        doc.prev = docs[index-1]
        doc.next = docs[index+1]
        nextState.item = doc

  startSwipe: (evt) ->
    @width = evt.currentTarget.clientWidth
    @startPos = evt.touches[0].pageX
    @startTime = new Date
    @setState swiping: true

  processSwipe: (evt) ->
    @moved = true
    pos = evt.touches[0].pageX
    return if Math.abs(pos - @startPos) < NAV_WIDTH
    @endPos = pos
    @diff = @endPos - @startPos
    @setState swipe: -@width + @endPos

  endSwipe: (doc) -> (evt) =>
    @setState swiping: false
    duration = new Date - @startTime
    if @moved and duration < 500 and (@diff > 150 or @diff < -150)
      if @diff < 0
        @navTo(doc.next, 'after')
      else
        @navTo(doc.prev, 'before')

  _edit: (doc) -> =>
    @props.router.push pathname: '/documents', query: editing: "#{doc.id}"

  download: (doc) -> (evt) =>
    @prevent(evt)
    if utils.humanizeMime(doc.mime) is 'Image'
      utils.saveImage doc.url, doc.title
    else
      utils.openFileOnDevice utils.getUrl(doc.url.slice(1)), doc.name, doc.mime

  verify: (doc) -> (evt) =>
    @prevent(evt)
    {id} = doc
    documentsStore.actions.verify({id})

  archive: (doc) -> =>
    documentsStore.actions.archive(doc.id, @props.router.isActive('/documents', true)).then =>
      this.goBack()

  viewInMessages: (doc) -> =>
    @props.router.push pathname: "/messages/#{doc.message_type}/#{doc.message_id}"

  closeModal: ->
    appStore.actions.showModal(null)

  delete: (doc) -> (evt) =>
    @prevent(evt)

    title = 'Delete document'
    text = 'Are you sure you want to delete this document? This action cannot be undone.'
    confirmText = 'Delete'
    onConfirm = @_delete(doc)

    body = ConfirmationModal {title, text, confirmText, onCancel: @closeModal, onConfirm}

    modal =
      className: 'Modal--small'
      darker: true
      body: body

    appStore.actions.showModal(modal)

  _delete: (doc) -> =>
    documentsStore.actions.remove(doc).then =>
      this.closeModal()
      this.goBack()

  goBack: ->
    this.props.router.push(BACK_TO)

  openInWindow: (url) ->
    window.open(url, '_blank').focus()

  handleNav: (doc={}, dir) -> =>
    @navTo(doc, dir)

  navTo: (doc, dir) ->
    docId = doc.id
    @props.router.push "/documents/#{docId}"

  prevent: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()

  render: ->
    doc = @state.item
    return div() unless doc.id
    {admin, proxying} = @state.user
    url = utils.getUrl(doc.url.slice(1))
    mime = utils.humanizeMime(doc.mime).toLowerCase()

    div className: 'DocumentView',
      React.createElement(BackSwiper, {to: '/documents'})
      div className: 'DocumentView-content',
        div className: 'DocumentView-body', onClick: @prevent, onTouchStart: @prevent,
          DocumentPreview {doc, url, mime, @download, @verify, @openInWindow, deleteDoc: @delete, @open, @startSwipe, @processSwipe, @endSwipe, back: this.goBack, mobile: true}

        div className: 'DocumentView-footer u-flex u-flexJustifyBetween',
          tch className: "Button Button--pointer Button--small u-flex u-flexAlignSelfStart prev #{cx 'is-disabled': !doc.prev}", handler: @handleNav(doc.prev, 'before'), onEnter: @handleNav(doc.prev, 'before'), tabIndex: (if doc.prev then 0 else -1),
            'Previous'
          tch className: "Button Button--pointer Button--small u-flex u-flexAlignSelfEnd next #{cx 'is-disabled': !doc.next}", handler: @handleNav(doc.next, 'after'), onEnter: @handleNav(doc.next, 'after'), tabIndex: (if doc.next then 0 else -1),
            'Next'
})

module.exports = withRouter(DocumentView)
