import InteractiveSurvey from "components/screen/InteractiveSurvey";
import { observer } from "mobx-react";
import hraStore from "stores/hra-survey";
import { toJS } from "mobx";

class HraSurvey extends React.Component {
  constructor(props) {
    super(props);

    this.onComplete = this.onComplete.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentWillMount() {
    const {
      router,
      params: { magicToken },
    } = this.props;

    if (!hraStore.isVerified) {
      router.push(`/surveys/hra/${magicToken}/verify`);
    }

    hraStore.lastStepId = "survey";
  }

  onComplete(surveyData) {
    const {
      params: { magicToken },
      router,
    } = this.props;
    const { dob, email } = hraStore.userCreds;

    hraStore
      .postAnswers({
        magicToken,
        dob,
        email,
        hra_content: surveyData,
      })
      .then(() => {
        hraStore.isVerified &&
          router.replace(`/surveys/hra/${magicToken}/results`);
      });
  }

  onPageChange() {
    window.scrollTo(0, 0);
  }

  onValueChange(survey, numVisibleElements, options) {
    if (options.question && options.question.errors) {
      options.question.errors.pop();
    }

    if (options.question && options.question.inputType === "number") {
      const re = /^[0-9\b]+$/;
      options.question.hasErrors(() => {
        if (re.test(options.value)) {
          return true;
        }
        return false;
      });
    }
  }

  render() {
    const options = {
      showPageTitle: true,
      showQuestionNumbers: "off",
      showProgressBar: "bottom",
    };

    return (
      <div className="Wellness Wellness-survey">
        <div className={"SurveyCard"}>
          <InteractiveSurvey
            template={toJS(hraStore.questions)}
            surveyOpts={options}
            onComplete={this.onComplete}
            onPageChange={this.onPageChange}
            onChange={this.onValueChange}
          />
        </div>
      </div>
    );
  }
}

export default observer(HraSurvey);
