{div, h1, h2, a, p, ul, li, strong} = Exim.DOM

Agreement = Exim.createView module.id,
  componentDidMount: ->
    document.querySelector('.u-scroll').focus()
    
  render: ->
    div className: 'AuthAgreement u-scroll', tabIndex: "0",
      h1 'User Agreement'
      div className: 'content',
        p null,
          strong 'Crossover Health Medical Group Authorization to Provide Medical Information to the Crossover System'
        p 'I understand that my health and medical information that will be provided to the Patient Portal (my or the “PHI”) is of the type that may be protected by the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”). I understand that I am voluntarily choosing to create this account and to provide the PHI described below to the Patient Portal as an interaction with my healthcare provider for the provision of care.'
        p 'I understand that the PHI described below will be stored by Crossover Health Medical Group in the Patient Portal and associated electronic record system and will be subject to the protections of HIPAA. I further agree that the email I have provided in order to create this account is my personal email account, which I alone control.'
        p 'Notwithstanding the above and to the extent this interaction would be considered a disclosure of PHI under HIPAA that requires an authorization, you must agree to all sections of this authorization before the PHI is provided to the Patient Portal.'
        p null,
          strong 'EXPLANATION: '
          'This Authorization authorizes Crossover Health Medical Group to provide the PHI described below to the Patient Portal. Please be aware that once the PHI is uploaded to or transferred by the Patient Portal, all protection of that information will be subject to the technological safeguards that exist for the Patient Portal. Please make yourself aware of the technological safeguards that exist by reviewing the Terms and Conditions provided on the Patient Portal.'
        p null,
          strong 'RESTRICTIONS: '
          'I understand that Crossover Health Medical Group may not further use or disclose the PHI described on this Authorization unless another authorization is obtained from me or unless such use or disclosure is specifically required or permitted by law. I hereby release Crossover Medical Group from any/all liability that may arise from the release of my PHI to the Patient Portal to the extent such release is covered by this authorization.'
        p null,
          strong 'ADDITIONAL COPY: '
          'I further understand that I or my authorized representative has a right to receive a copy of this Authorization upon my request.'
        p null,
          strong 'CONDITIONING TREATMENT ON AUTHORIZATION: '
          'Crossover Health Medical Group may not condition treatment, except as provided by law, on your decision whether to sign this Authorization.'
        p null,
          strong 'DURATION REVOCATION: '
          'I understand that I or my authorized representative may revoke this authorization by deleting my Patient Portal account, and this authorization will expire upon the deletion of my Patient Portal account.'
        ul null,
          li null,
            '1. '
            strong 'Authorization: '
            'I authorize Crossover Health Medical Group to disclose my medical information as described below: '
          li null,
            '2. '
            strong 'PHI Holder’s Name: '
            'Crossover Health Medical Group'
          li null,
            '3. '
            strong 'Address: '
            '101 W. Avenida Vista Hermosa Ste. 120, San Clemente, CA 92672 '
          li null,
            '4. '
            strong 'PHI Released To: '
            'I authorize Crossover Health Medical Group to disclose my PHI to the Patient Portal. I can revoke this authorization at any time as described above.'
          li null,
            '5. '
            strong 'Dates Information To Be Released: '
            'Any and all times related to my use of the Patient Portal.'
          li null,
            '6. '
            strong 'Description of Information to be Used or Disclosed: '
            'Given the interactive purpose of the Patient Portal to provide care, any PHI related to the provision of care may be used or disclosed to the Patient Portal.'
          li null,
            '7. '
            strong 'Use of Information: '
            'The individual or entity identified above is permitted to use my PHI with regard to any of the Patient Portal functions.'
        p 'I am eighteen (18) years of age or older, I have the right to enter into this agreement and the permission of no other party is necessary for me to enter into this agreement. My entering into this agreement does not violate the rights of any third parties, and this document shall be binding upon my heirs, successors and assigns.'

module.exports = Agreement
