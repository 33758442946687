"use strict";
const authStore = require("stores/new-auth");
const { observer } = require("mobx-react");
const Link = require("../link");

const AppointmentsLink = () => {
  let { user } = authStore;
  if (!user.canReadAppointments) return null;

  return (
    <li className="SidebarNav-item">
      <Link to="/appointments">
        <span className="SidebarNavLink-icon">
          <span aria-hidden={true} className="icon fa fa-calendar-o" />
        </span>
        <span className="SidebarNavLink-text">Appointments</span>
      </Link>
    </li>
  );
};

module.exports = observer(AppointmentsLink);
