authStore = require 'stores/auth'

Profile = Exim.createView module.id,
  mixins: [authStore.connect('user')]

  contextTypes:
    router: React.PropTypes.object.isRequired

  onEdit: ->
    @context.router.push pathname: '/profile/edit'

  offEdit: ->
    @context.router.push pathname: '/profile'

  render: ->
    @props.children

module.exports = Profile
