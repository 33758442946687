const handleEscapeKeydown = (evt, callback) => {
  const isEscapeKey =
    evt.key == "Escape" || evt.keyCode == "27" || evt.code == "Escape";
  if (!isEscapeKey) {
    return;
  } else {
    callback();
  }
};

export const addEscapeKeyListener = (document, callback) => {
  document.addEventListener("keydown", (evt) =>
    handleEscapeKeydown(evt, callback)
  );
};

export const removeEscapeKeyListener = (document, callback) => {
  document.removeEventListener("keydown", (evt) =>
    handleEscapeKeydown(evt, callback)
  );
};
