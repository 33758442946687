{appStore, labsStore} = require 'stores'
{api, formats, touchDevice} = require 'config'

ActionDropdown = require 'components/elements/ActionDropdown'

{cx} = Exim.helpers
{div, tch, span, fa, object, a, strong} = Exim.DOM

utils = require 'lib/utils'

Lab = Exim.createView module.id,
  propTypes: data: React.PropTypes.object

  mixins: [
    labsStore.connect('zoneAbbr')
  ]

  openInWindow: (url) ->
    url = utils.getUrl(url.slice(1))
    window.open(url, '_blank').focus()

  download: ->
    {url, title} = @props.data
    if url
      utils.openLink utils.getUrl(url.slice(1))

  prevent: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()

  viewPDF: (evt) ->
    @prevent(evt)
    {url, title} = @props.data
    if url
      body = div className: 'DocumentPreview',
        div className: 'ConfirmationModalTitle', strong "Lab #{@props.data.title}"
        div className: 'DocumentPreview-mime DocumentPreview-mime-pdf',
          if touchDevice
            tch tagName: 'a', href: '#', className: 'pdf-download', handler: @openInWindow.bind(this, url),
              fa 'file-pdf-o'
              'Open File'
          else
            div className: 'DocumentPreview-mime-pdf-object scroll-wrapper',
              object data: url, type: 'application/pdf', width: '100%', height: '100%',  'aria-label': "this is the #{@props.data.title}",
                a href: url, target: '_blank', url

    appStore.actions.showModal({body})

  render: ->
    {data} = @props

    mDate = moment(data.collected_at, formats.fullWithTimezone)

    tch className: 'TableBody-row LabsBody-row Button--pointer TableBody-row--padded', handler: @viewPDF,
      div className: 'TableBody-column LabsBody-column u-3of5 title',
        data.title
      div className: 'TableBody-column LabsBody-column TableBody-column--pullRight u-1of5 resulted',
        span mDate.format('MM/DD/YY')
        span className: 'u-hidden-md-down', ', '
        span mDate.format('HH:MM A'),
          ' '
          @state.zoneAbbr
      div className: 'TableBody-column LabsBody-column u-1of5 actions',
        ActionDropdown
          className: 'ActionDropdown--gray'
          values: [
            {name: 'View PDF', handler: @viewPDF}
            {name: 'Download', handler: @download}
          ]

module.exports = Lab
