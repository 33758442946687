import { observable } from "mobx";
import request from "lib/new-request";
import { ISO_DATE } from "lib/formats";
import { MomentRange } from "lib/utils";
import synopsisStore from "stores/new-synopsis";
import slotsStore from "stores/new-slots";
import { remove, uniqBy } from "lodash";

const ENDPOINT = "/v1/providers/out-of-office-notifications";
const fromAPI = (raw) => {
  let site = synopsisStore.findSite(raw.clinic_id);
  let provider = site.getProvider(raw.provider_id);

  let start = moment.tz(raw.start_date, site.zone);
  let end = moment.tz(raw.end_date, site.zone);

  return {
    site,
    provider,
    id: raw.id,
    range: new MomentRange(start, end),
  };
};

const oooStore = observable({
  _OOOs: [],

  findOOOs(provider, date) {
    let OOOs = this._OOOs.filter((ooo) => {
      const providerCheck = ooo && ooo.provider && ooo.provider.is(provider);
      const dateCheck = ooo && ooo.range && ooo.range.includes(date);
      return providerCheck && dateCheck;
    });

    return uniqBy(OOOs, "id");
  },

  async fetch(params) {
    let rawOOOs = await request.get(ENDPOINT, params, {
      serializeObject: true,
    });

    this._OOOs = rawOOOs.map((raw) => fromAPI(raw));
  },

  async book({ provider, startDay, endDay }) {
    let { site } = synopsisStore;
    let rawOOO = await request.post(ENDPOINT, {
      clinic_id: site.id,
      provider_id: provider.id,
      start_date: startDay.format(ISO_DATE),
      end_date: endDay.format(ISO_DATE),
    });

    this._OOOs.push(fromAPI(rawOOO));
    slotsStore.fetchConflicted();
  },

  async delete({ id }) {
    await request.delete(`${ENDPOINT}/${id}`);
    remove(this._OOOs, (ooo) => ooo.id == id);
  },
});

module.exports = oooStore;
