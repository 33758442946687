module.exports = device = {}

{unzip} = require 'lodash'
plugins = device.plugins = window.plugins # TODO: remove & refactor

device.storage = storage =
  isSupported: ->
    !!window.plugins?.appPreferences?

  getItem: (key) ->
    new Promise (resolve, reject) ->
      window.plugins.appPreferences.fetch(resolve, reject, key)

  setItem: (key, value) ->
    new Promise (resolve, reject) ->
      window.plugins.appPreferences.store(resolve, reject, key, value)

device.HKit = HKit =
  isSupported: -> !!window.plugins?.healthkit?
  lastFetchDate: new Date(0)
  lastSaveDate: new Date(0)
  hasAccess: false

  requestAccess: ->
    new Promise (resolve, reject) ->
      return resolve() if HKit.hasAccess

      {HKMetrics} = require('config')
      types = HKMetrics.filter((m) -> !m.disabled and m.hk_name).reduce (result, metric) ->
        name = metric.hk_name
        name = [name] unless Array.isArray(name)
        result.concat(name)
      , []
      all = {readTypes: types, writeTypes: types}
      window.plugins.healthkit.requestAuthorization all, (error) ->
        if error
          console.error 'HealthKit request access:'
          console.error  error
          return reject error
        HKit.hasAccess = true
        resolve()

HKit.getMetric = (params) ->
  new Promise (resolve, reject) ->
    window.plugins.healthkit.querySampleType(params, resolve, reject)

HKit.saveSample = (params) ->
  new Promise (resolve, reject) ->
    window.plugins.healthkit.saveQuantitySample(params, resolve, reject)

HKit.saveCorellation = (params) ->
  new Promise (resolve, reject) ->
    window.plugins.healthkit.saveCorrelation(params, resolve, reject)

HKit.getAppMetric = (metric, startDate) ->
  startDate ?= HKit.lastFetchDate
  sampleType = metric.hk_name
  {unit} = metric
  return null unless sampleType
  endDate = new Date
  HKit.getMetric({startDate, endDate, sampleType, unit}).then (data) ->
    return null unless data.length
    metric.data = data
    metric
  .catch (error) ->
    console.log 'HKit.getAppMetric error: ', error
    null

HKit.saveMetric = (name, unit, datapoint, sinceWhen, isBloodPressure) ->
  pointDate = moment(datapoint.date).toDate()
  val = datapoint.value
  sinceWhen ?= HKit.lastSaveDate
  return Promise.resolve(false) if not name or sinceWhen > pointDate

  if isBloodPressure
    [sys, dias] = val.split('/')
    bpName = 'HKCorrelationTypeIdentifierBloodPressure'
    bpSamples = [
      {
        sampleType: 'HKQuantityTypeIdentifierBloodPressureSystolic'
        startDate: pointDate, endDate: pointDate, unit: unit
        amount: sys
      },
      {
        sampleType: 'HKQuantityTypeIdentifierBloodPressureDiastolic'
        startDate: pointDate, endDate: pointDate, unit: unit
        amount: dias
      }
    ]
    HKit.saveCorellation
      correlationType: bpName,
      startDate: pointDate, endDate: pointDate, unit: unit,
      samples: bpSamples
  else
    HKit.saveSample
      sampleType: name,
      startDate: pointDate, endDate: pointDate, unit: unit,
      amount: val

HKit.saveAppMetric = (appMetric, sinceWhen) ->
  metricName = appMetric.name
  allValues = appMetric.values

  {HKMetrics} = require('config')
  hkMetrics = HKMetrics.filter((hk) -> hk.xo_name is metricName)
  [metric] = hkMetrics
  if not metric
    return console.error 'Invalid metric', metricName

  name = metric.hk_name
  return unless name

  if metric.disabled
    return console.log 'Metric disabled', metricName

  window.plugins.healthkit.checkAuthStatus {type: name}
  , (res) ->
    status = res == 'authorized'
    return console.log 'Metric is not authorized', name if !status

    {unit} = metric
    values = allValues.filter (value) -> value.source isnt 'healthkit'
    isBloodPressure = metricName is 'Blood Pressure'

    filtered = values.filter (value) -> sinceWhen < moment(value.date).toDate()
    return Promise.resolve([]) if not filtered.length
    console.log 'HKit.saveAppMetric %s (%s)', name, unit, filtered

    return Promise.all(values.map (datapoint) ->
      HKit.saveMetric(name, unit, datapoint, sinceWhen, isBloodPressure).catch (error) ->
        console.error('HealthKit: Failed to save metric', name, unit, datapoint, error)
    )
  , (err) ->
    return console.log 'Error: ', err

HKit.getAllMetrics = (sinceLastFetch) ->
  return Promise.resolve([]) unless HKit.isSupported()
  {HKMetrics} = require('config')

  HKit.requestAccess()
  .then (access) ->
    if sinceLastFetch
      HKit.getFetchDate()
    else
      Promise.resolve(new Date(0))
  .then (sinceWhen) ->
    console.log 'HKit.getAllMetrics: Fetching ones starting from', sinceWhen
    Promise.all(
      HKMetrics
        .filter((metric) -> !metric.disabled)
        .map((metric) -> HKit.getAppMetric(metric, sinceWhen))
    )
  .then (unfiltered) ->
    data = unfiltered.filter((item) -> item)
    deepCopy = (item) -> JSON.parse JSON.stringify item
    field = 'xo_name'
    groupped = data.reduce (object, value) ->
      key = value[field] or Math.random()
      object[key] ?= []
      object[key].push value
      object
    , {}
    prepared = Object.keys(groupped).map (key) ->
      similar = groupped[key]
      return similar[0] if similar.length <= 1

      objectPair = similar
      listPair = objectPair.map (item) -> item.data
      pairs = unzip(listPair)
      objectList = pairs.map (pair) ->
        [obj1, obj2] = pair
        result = deepCopy obj1
        result.quantity = obj1.quantity + '/' + obj2.quantity
        result

      result = deepCopy objectPair[0]
      result.hk_name = objectPair[0].hk_name + '/' + objectPair[1].hk_name
      result.data = objectList
      result
    console.log 'HKit.getAllMetrics: Fetched', prepared

    prepared = prepared.map (metric) ->
      return metric unless metric.id is 99
      metric.data = metric.data.map (item) ->
        item.quantity = moment(item.endDate).diff(moment(item.startDate), 'minutes')
        item
      metric

    prepared
  .catch (error) ->
    console.log 'HKit.prepareMetricsForServer error', error

HKit.getSyncDate = (name) ->
  key = if name is 'Fetch'
    'HKFetchDate'
  else
    'HKSaveDate'
  cacheKey = if name is 'Fetch'
    'lastFetchDate'
  else
    'lastSaveDate'
  if storage.isSupported()
    storage.getItem(key).then (date) ->
      if isNaN(date)
        console.log 'HKit: Date is NaN'
        date = 0
      date = null if date is 'null'
      date ?= 0
      str = new Date(+date)
      console.log 'HKit.get%sDate: %s', name, str
      HKit[cacheKey] = str
      str
  else
    date = new Date(0)
    GFit[cacheKey] = date
    date

HKit.setSyncDate = (name, date) ->
  date = new Date date if date is 0
  date ?= new Date
  console.log 'HKit.set%sDate: %s', name, date
  key = if name is 'Fetch'
    'HKFetchDate'
  else
    'HKSaveDate'
  cacheKey = if name is 'Fetch'
    'lastFetchDate'
  else
    'lastSaveDate'
  HKit[cacheKey] = date
  storage.setItem(key, (+date).toString()) if storage.isSupported()

HKit.getFetchDate = ->
  HKit.getSyncDate('Fetch')

HKit.updateFetchDate = ->
  HKit.setSyncDate('Fetch')

HKit.getSaveDate = ->
  HKit.getSyncDate('Save')

HKit.updateSaveDate = ->
  HKit.setSyncDate('Save')

HKit.resetDates = ->
  HKit.setDate('Fetch', new Date(0))
  HKit.setDate('Save', new Date(0))

HKit.syncMetricsFromServer = (metrics) ->
  return unless HKit.isSupported()
  console.log 'HKit.syncMetricsFromServer'
  HKit.requestAccess()
  .then(HKit.getSaveDate)
  .then (sinceWhen) ->
    Promise.all(metrics.map (metric) -> HKit.saveAppMetric metric, sinceWhen)
  .then(HKit.updateSaveDate)
  .catch (e) ->
    console.log 'HKit.syncMetricsFromServer: Error', e

device.GFit = GFit =
  lastFetchDate: new Date(1)
  lastSaveDate: new Date(1)
  hasAccess: false

  isSupported: ->
    !!navigator?.googlefit?

  connect: ->
    new Promise (resolve, reject) ->
      return resolve() if GFit.hasAccess

      navigator.googlefit?.connect (success) ->
        GFit.hasAccess = true
        resolve()
      , (error) ->
        console.error 'Google Fit connection error: ', error
        return reject error

GFit.getSyncDate = (name) ->
  key = if name is 'Fetch'
    'GFFetchDate'
  else
    'GFSaveDate'
  cacheKey = if name is 'Fetch'
    'lastFetchDate'
  else
    'lastSaveDate'
  if storage.isSupported()
    storage.getItem(key).then (date) ->
      if isNaN(date)
        console.log 'GFit: Date is NaN'
        date = 1
      date = null if date is 'null'
      date ?= 1
      str = new Date(+date)
      console.log 'GFit.get%sDate: %s', name, str
      GFit[cacheKey] = str
      str
  else
    date = new Date(1)
    GFit[cacheKey] = date
    date

GFit.setSyncDate = (name, date) ->
  date = new Date date if date is 0 or date is 1
  date ?= new Date
  console.log 'GFit.set%sDate: %s', name, date
  key = if name is 'Fetch'
    'GFFetchDate'
  else
    'GFSaveDate'
  cacheKey = if name is 'Fetch'
    'lastFetchDate'
  else
    'lastSaveDate'
  GFit[cacheKey] = date
  storage.setItem(key, (+date).toString()) if storage.isSupported()

GFit.getFetchDate = ->
  GFit.getSyncDate('Fetch')

GFit.updateFetchDate = ->
  GFit.setSyncDate('Fetch')

GFit.getSaveDate = ->
  GFit.getSyncDate('Save')

GFit.updateSaveDate = ->
  GFit.setSyncDate('Save')

GFit.resetDates = ->
  GFit.setDate('Fetch', new Date(1))
  GFit.setDate('Save', new Date(1))

GFit.getMetric = (name, params) ->
  new Promise (resolve, reject) ->
    navigator.googlefit.readMetric(name, params, resolve, reject)

GFit.saveMetric = (name, values) ->
  return Promise.resolve(false) if not name

  new Promise (resolve, reject) ->
    navigator.googlefit.saveMetric(name, values, resolve, reject)

GFit.getSaveValue = (id, value) ->
  switch id
    when 8 then parseFloat(value) * 0.453592 #Weight (lb to kg)
    when 9 then parseFloat(value) * 0.0254 #Height (in to m)
    else value

GFit.getReadValue = (id, value) ->
  switch id
    when 8 then parseFloat(value) * 2.20462 #Weight (kg to lb)
    when 9 then parseFloat(value) * 39.3701 #Height (m to in)
    else value

GFit.getAppMetric = (metric, startDate) ->
  startDate ?= GFit.lastFetchDate
  sampleType = metric.gf_name
  return null unless sampleType

  GFit.getMetric(sampleType, {startDate}).then (data) ->
    return null unless data.values?.length
    metric.data = data
    metric
  .catch (error) ->
    console.log 'GFit.getAppMetric error: ', error
    null

GFit.saveAppMetric = (appMetric, sinceWhen) ->
  metricId = appMetric.id
  metricName = appMetric.name
  allValues = appMetric.values

  console.log metricName
  {GFMetrics} = require('config')
  gfMetrics = GFMetrics.filter((gf) -> gf.xo_name is metricName)
  metric = gfMetrics[0]
  if not metric
    return console.error 'Invalid metric', metricName

  name = metric.gf_name
  return unless name

  if !!metric.disabled
    return console.log 'Metric disabled', metricName

  filtered = []
  allValues.forEach (value) ->
    value.value = GFit.getSaveValue(metricId, value.value)
    value.date = moment(value.date).toDate()
    if value.source isnt 'healthkit'# and sinceWhen < value.date
      filtered.push value

  return Promise.resolve([]) if not filtered.length
  console.log 'GFit.saveAppMetric %s (%s)', name, filtered

  GFit.saveMetric(name, filtered).catch (error) ->
    console.error 'Google Fit: Failed to save metric', name, filtered, error
    console.error error

GFit.getAllMetrics = (sinceLastFetch) ->
  console.log 'get all'
  return Promise.resolve([]) unless GFit.isSupported()
  {GFMetrics} = require('config')

  console.log 'GFit supported'
  GFit.connect()
  .then (access) ->
    if sinceLastFetch
      GFit.getFetchDate()
    else
      Promise.resolve(new Date(1))
  .then (sinceWhen) ->
    console.log 'GFit.getAllMetrics: Fetching ones starting from', sinceWhen
    Promise.all(
      GFMetrics
        .filter((metric) -> !metric.disabled)
        .map((metric) -> GFit.getAppMetric(metric, sinceWhen))
    )
  .then (unfiltered) ->
    data = unfiltered.filter((item) -> item)

    field = 'data_type'
    groupped = data.reduce (object, value) ->
      key = value[field] or Math.random()
      object[key] ?= []
      object[key].push value
      object
    , {}

    prepared = Object.keys(groupped).map (key) ->
      similar = groupped[key]

      console.log key

      result = []
      {id} = similar[0]
      similar.forEach (item) ->
        item.data?.values.forEach (value) ->
          result.push {quantity: GFit.getReadValue(id, value.value), endDate: new Date(value.endTime)}
      {id, data: result}

    console.log 'GFit.getAllMetrics: Fetched', prepared
    prepared
  .catch (error) ->
    console.log 'GFit.prepareMetricsForServer error'
    console.log error


GFit.syncMetricsFromServer = (metrics) ->
  return unless GFit.isSupported()
  console.log 'GFit.syncMetricsFromServer'
  GFit.connect()
  .then(GFit.getSaveDate)
  .then (sinceWhen) ->
    Promise.all(metrics.map (metric) ->
      GFit.saveAppMetric(metric, sinceWhen)
    )
  .then(GFit.updateSaveDate)
  .catch (e) ->
    console.log 'GFit.syncMetricsFromServer: Error'
    console.log e
