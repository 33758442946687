'use strict'
{div, time, button, table, thead, tbody, tr, td, th} = Exim.DOM
{observer} = require 'mobx-react'
{formats} = require 'config'
{Modal} = require 'components/elements/new-modal'

class NotesModal extends React.Component
  renderHead: ->
    return tr null,
      th null, 'Note'
      th null, 'Author'
      th null, 'Date'
      th null, 'Action'

  renderNote: (note) =>
    return tr key: note.id,
      td null, note.text
      td null, note.author.name
      td null,
        time dateTime: note.createdAt.toISOString(),
          note.createdAt.format(formats.notesDateFormat)
      td null,
        button {
          className: 'NotesModal-remove',
          disabled: !note.isDeletable,
          onClick: () => note.delete(),
        }, 'Remove'

  render: ->
    notes = this.props.appointment.latestNotes

    return React.createElement(Modal, {
      className: 'NotesModal',
      title: 'Appointment Notes',
      close: this.props.abort,
    },
      table className: 'NewTable',
        thead null,
          this.renderHead()
        tbody null,
          if notes.length
            notes.map(this.renderNote)
          else
            tr className: 'NewTable-noItems',
              td colSpan: 4, 'No appointment notes.'
    )

module.exports = observer(NotesModal)
