import { cx } from "lib/utils";
import { Input } from "components/elements";

const MIN_LENGTH = 8;
const reqClass = (ok) => cx("CredentialsForm-req", { ok });

class CredentialsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLong: false,
      hasLower: false,
      hasUpper: false,
      hasDigit: false,
    };
  }

  handleChange(target) {
    let { value } = target;
    let hasLower = /[a-z]/.test(value);
    let hasUpper = /[A-Z]/.test(value);
    let hasDigit = /\d/.test(value);

    this.setState({
      isLong: value.length >= MIN_LENGTH,
      hasLower,
      hasUpper,
      hasDigit,
    });

    let isStrong = hasLower && hasUpper && hasDigit;
    target.setCustomValidity(isStrong ? "" : "Password is weak");
  }

  render() {
    let { isLong, hasLower, hasUpper, hasDigit } = this.state;

    return (
      <React.Fragment>
        <div className="CheckinForm-row">
          <label>Email address</label>
          <Input
            type="email"
            name="email"
            className="CredentialsForm-email Input Input--expand"
            placeholder="john.smith@company.com"
            required
            ref={this.props.emailRef}
            onChange={(evt) => {
              evt.target.setCustomValidity("");
            }}
          />
        </div>

        <div className="CheckinForm-row">
          <label>Password</label>
          <div className="Input--expand">
            <Input
              name="password"
              type="password"
              className="CredentialsForm-password Input Input--expand"
              placeholder={"*".repeat(MIN_LENGTH)}
              minLength={MIN_LENGTH}
              required
              ref={this.props.passwordRef}
              onChange={(evt) => {
                this.handleChange(evt.target);
              }}
            />
            <div className="CredentialsForm-reqs">
              <span className={reqClass(isLong)}>8+ long</span>{" "}
              <span className={reqClass(hasLower)}>lowercase</span>{" "}
              <span className={reqClass(hasUpper)}>uppercase</span>{" "}
              <span className={reqClass(hasDigit)}>digit</span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CredentialsForm;
