{authStore, changelogStore} = require 'stores'
{div, tch, span, ul, li, fa} = Exim.DOM
Widget = require './Widget'
utils = require 'lib/utils'
{formats} = require 'config'

WhatsNewWidget = Exim.createView module.id,
  listen: ['changelog/preview']

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    innerHtml: null
    latest: null

  componentWillMount: ->
    changelogStore.actions.fetch()

  more: ->
    @context.router.push '/whatsNew'

  render: ->
    {preview} = @state

    if preview and preview.title
      div className: 'WhatsNewLandingWidget',
        Widget
          icon: 'comments-o'
          title: "What's new"
          ariaLabelledById: 'whats-new-close'
          dismiss: @props.dismiss
          body: ->
            div null,
              div className: 'LandingWidgetBody-date', preview.date if preview?.date
              div className: 'LandingWidgetBody-title', preview.title if preview?.title
          innerHtml: preview?.content
          more: @more
          onClick: @more
    else
      null


module.exports = WhatsNewWidget
