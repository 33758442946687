import { observer } from "mobx-react";
import { Modal } from "components/elements/new-modal";
import {
  DayPickerSingleDateController,
  DayPickerRangeController,
} from "react-dates";
import synopsisStore from "stores/new-synopsis";
import slotsStore from "stores/new-slots";
import calStore from "stores/cal";

const getStartOfWeek = (day) => {
  let date = day.startOf("week");
  let today = moment(new Date());

  return date < today ? today : date;
};

class ClearRangeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      toggle: "day",
      startDate: null,
      endDate: null,
      focused: false,
      focusedInput: "startDate",
    };
  }

  onToggleSelect(key) {
    this.setState({
      toggle: key,
      startDate: null,
      endDate: null,
      focused: false,
      focusedInput: "startDate",
    });
  }

  renderCalendar() {
    switch (this.state.toggle) {
      case "day":
        return (
          <DayPickerSingleDateController
            date={this.state.startDate}
            onDateChange={(date) =>
              this.setState({ startDate: date, endDate: date })
            }
            focused={this.state.focused}
            onFocusChange={() =>
              this.setState({ focused: !this.state.focused })
            }
            isOutsideRange={(date) => date < moment()}
          />
        );
      case "week":
        return (
          <DayPickerRangeController
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={({ startDate, endDate }) =>
              this.setState({ startDate, endDate })
            }
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) => this.setState({ focusedInput })}
            startDateOffset={getStartOfWeek}
            endDateOffset={(day) => day.endOf("week")}
            isOutsideRange={(date) => date < moment()}
          />
        );
      case "range":
        return (
          <DayPickerRangeController
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={({ startDate, endDate }) =>
              this.setState({ startDate, endDate })
            }
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) =>
              this.setState({ focusedInput: focusedInput || "startDate" })
            }
            isOutsideRange={(date) => date < moment()}
          />
        );
    }
  }

  renderDatesText() {
    const { startDate, endDate } = this.state;
    let text = "Select dates";

    if (startDate && endDate) {
      const startDateText = startDate.format("l");
      const endDateText = endDate.format("l");

      text =
        startDateText === endDateText
          ? startDateText
          : `${startDateText} to ${endDateText}`;
    }

    return text;
  }

  async clearSlots() {
    await slotsStore.clearSlotsByRange({
      provider_id: calStore.providerFilter.value,
      clinic_id: synopsisStore.site.id,
      start_date: this.state.startDate.format("YYYY/MM/DD"),
      end_date: this.state.endDate.format("YYYY/MM/DD"),
    });

    await calStore.fetchSlots();
    this.props.abort();
  }

  render() {
    let title = "Clear Provider's Schedule";
    const providerId = calStore.providerFilter.value;
    const provider = synopsisStore.site.getProvider(providerId);

    if (this.state.confirm) {
      let title = `Delete appointments for ${provider.name}?`;

      return (
        <Modal
          close={this.props.abort}
          title={title}
          className="ClearRangeModal-confirm"
        >
          <div>
            <p>
              Are you sure you want to delete appointments and slots for{" "}
              <b>{provider.name}</b>?
            </p>
            <p>
              All booked appointments will go to the Conflicts Queue and all
              slots from <b>{this.renderDatesText()}</b> will be deleted.
            </p>
          </div>
          <div className="ConfirmFooter">
            <div className="ConfirmFooter-buttons">
              <button className="Button" onClick={this.props.abort}>
                Cancel
              </button>
              <button
                className="Button ConfirmButton"
                onClick={() => this.clearSlots()}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      );
    }

    return (
      <Modal close={this.props.abort} title={title} className="ClearRangeModal">
        <div className="ClearRangeText">
          <p>
            Choose the day(s) to delete slots for <b>{provider.name}</b>:
          </p>
        </div>

        <div className="ClearRangeToggle">
          <button
            className={`ClearRangeToggle-button ${
              this.state.toggle === "day" ? "is-active" : null
            }`}
            onClick={() => this.onToggleSelect("day")}
          >
            One Day
          </button>
          <button
            className={`ClearRangeToggle-button ${
              this.state.toggle === "week" ? "is-active" : null
            }`}
            onClick={() => this.onToggleSelect("week")}
          >
            One Week
          </button>
          <button
            className={`ClearRangeToggle-button ${
              this.state.toggle === "range" ? "is-active" : null
            }`}
            onClick={() => this.onToggleSelect("range")}
          >
            Range of Days
          </button>
        </div>

        <div className="ClearRangeCalendar">{this.renderCalendar()}</div>

        <div className="ClearRangeDatesPreview">{this.renderDatesText()}</div>

        <div className="ClearRangeFooter">
          <div className="ClearRangeFooter-buttons">
            <button className="Button" onClick={this.props.abort}>
              Cancel
            </button>
            <button
              className="Button ConfirmButton"
              onClick={() => this.setState({ confirm: true })}
              disabled={!(this.state.startDate && this.state.endDate)}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

module.exports = observer(ClearRangeModal);
