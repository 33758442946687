import { observable, toJS } from "mobx";
// Can't use mobx computed because of Exim conflicts
import { computed, capitalize, destructureDateTime } from "lib/utils";
import { timezone } from "lib/utils/timezone";
import request from "lib/new-request";
import { remove, uniqBy, isEmpty, sortBy, get } from "lodash";
import { appStore } from "stores";
import synopsisStore from "stores/new-synopsis";
import scheduleStore from "./schedule";
import * as SurveyJS from "survey-react";
import marked from "marked";
import {
  configureDefaultOptions,
  logToRollbar,
  setTableHeaderAttributes,
} from "lib/utils";

configureDefaultOptions(SurveyJS);

const ENDPOINT = "/v1/screening-templates";

const MARKED_RENDERER = new marked.Renderer();
MARKED_RENDERER.link = (href, title, text) =>
  `<a target="_blank" href="${href}" rel="noopener noreferrer" title="${title}">${text}</a>`;

const screeningStore = observable({
  screeningTemplates: [],
  screeningData: [],
  screeningModalities: [],
  screeningInteractionsData: [],
  slotClinicId: "",
  healthScreenings: [],
  outboundScreeningTemplate: {},

  async fetchTemplates(params) {
    let screeningTemplates = [];
    screeningTemplates = await request
      .get(ENDPOINT, params, { serializeObject: true })
      .then((templates) => {
        return templates.map((template) => {
          template.screeningTemplate = true;
          template.data = JSON.parse(template.data);
          return template;
        });
      });
    this.screeningTemplates = screeningTemplates;
  },

  async fetchForwadableTemplates() {
    const forwardableTemplates = await request.get(
      `/v1/screening-templates?forwardable=true`
    );
    return forwardableTemplates;
  },

  async fetchPatientScreeningById(patient_id, screening_template_id, params) {
    let screeningData = [];
    screeningData = await request.get(
      `/v1/patient/${patient_id}/screenings/${screening_template_id}`,
      params
    );
    this.screeningData = screeningData;
  },

  async fetchModalities() {
    let modalities = [];
    modalities = await request.get("/v1/modalities");
    this.screeningModalities = modalities.results;
  },

  async createTemplate({ name, status, data }, templateId) {
    if (templateId) {
      return await request.put(ENDPOINT + `/${templateId}`, {
        name,
        status,
        data,
      });
    } else {
      return await request.post(ENDPOINT, { name, status, data });
    }
  },

  async delete({ id, status }) {
    await request.delete(`${ENDPOINT}/${id}`);
    if (status === "draft") {
      remove(this.screeningTemplates, (template) => template.id === id);
    } else if (status === "live") {
      this.screeningTemplates.forEach((template) => {
        if (template.id === id) {
          template.status = "deleted";
        }
      });
    }
  },

  async createTemplateInteraction(params) {
    await request
      .post("/v1/template-interactions", params)
      .then(() => this.fetchTemplates());
  },

  async requestTemplateInteraction(params) {
    await request.post("/v1/template-interactions/request", params);
  },

  async deleteTemplateInteraction(id) {
    await request.delete(`/v1/template-interactions/${id}`);
  },

  // Save screening framework data
  // app.post    "/v1/patient/:id/screening-request"
  async createScreeningData(patient_id, params) {
    return await request.post(
      `/v1/patient/${patient_id}/screening-request`,
      params
    );
  },

  async fetchHealthScreenings(patient_id) {
    if (!patient_id) return;

    try {
      const response = await request.get(
        `/v1/patients/${patient_id}/outbound_screenings`
      );
      this.healthScreenings = response.results;
    } catch (e) {
      logToRollbar(e);
    }
  },

  async fetchOutboundScreeningTemplate(patient_id, template_id) {
    if (!template_id || !patient_id) return;

    try {
      this.outboundScreeningTemplate = await request.get(
        `/v1/patients/${patient_id}/outbound_screenings/${template_id}`
      );
      this.updateHealthScreenings(this.outboundScreeningTemplate);
    } catch (e) {
      logToRollbar(e);
    }
  },

  async saveOutboundScreeningData(patient_id, data) {
    if (isEmpty(this.outboundScreeningTemplate)) return;

    try {
      const { id } = this.outboundScreeningTemplate;
      const url = `/v1/patients/${patient_id}/outbound_screenings/${id}/screen`;
      const updatedScreener = await request.put(url, {
        data: JSON.stringify(data),
      });

      this.updateHealthScreenings(updatedScreener);
    } catch (e) {
      logToRollbar(e);
    }
  },

  async completeOutboundScreener(patient_id, data) {
    const { id } = this.outboundScreeningTemplate;

    try {
      const url = `/v1/patients/${patient_id}/outbound_screenings/${id}/complete`;
      const completedScreener = await request.post(url, {
        data: JSON.stringify(data),
      });

      this.updateHealthScreenings(completedScreener);
    } catch (e) {
      logToRollbar(e);
    }
  },

  async addNewNote(patient_id, screening_id, note) {
    try {
      const url = `/v1/patients/${patient_id}/outbound_screenings/${screening_id}/notes`;
      const newNote = await request.post(url, { note });

      this.healthScreenings = this.healthScreenings.map((screening) => {
        if (screening.id === screening_id) {
          screening.outbound_screening_notes =
            screening.outbound_screening_notes
              ? screening.outbound_screening_notes.concat(newNote)
              : [newNote];
        }

        return screening;
      });
    } catch (e) {
      logToRollbar(e);
    }
  },

  async deleteNote(patient_id, screening_id, note_id) {
    const url = `/v1/patients/${patient_id}/outbound_screenings/${screening_id}/notes/${note_id}`;

    try {
      await request.delete(url);

      this.healthScreenings = this.healthScreenings.map((screening) => {
        if (screening.id === screening_id) {
          screening.outbound_screening_notes =
            screening.outbound_screening_notes
              ? screening.outbound_screening_notes.filter(
                  (note) => note.id !== note_id
                )
              : [];
        }

        return screening;
      });
    } catch (e) {
      logToRollbar(e);
    }
  },

  setOutboundScreener(screener) {
    this.outboundScreeningTemplate = screener;
  },

  updateHealthScreenings(screening) {
    this.healthScreenings = this.healthScreenings
      .filter((e) => e.id !== screening.id)
      .concat(screening);
  },

  clearOutboundScreeningData() {
    this.outboundScreeningTemplate = {};
  },

  filterScreeningInteractions(templateId) {
    return this.screeningInteractionsData.filter(
      (option) => option.templateId === templateId
    );
  },

  setTemplateId(templateId) {
    let screeningInteractionsData = [];
    let screeningInteraction = this.filterScreeningInteractions(templateId);

    if (isEmpty(screeningInteraction)) {
      screeningInteractionsData = [
        {
          templateId: templateId,
          otherInteractionSelection: [],
          otherInteractionSelectedTiming: "",
          otherInteractionSelectedAdministration: "",
          modalities: [],
          otherInteraction: false,
          onDemand: false,
        },
        ...this.screeningInteractionsData,
      ];

      this.screeningInteractionsData = screeningInteractionsData;
    }
  },

  setOtherInteractionSelection(value, templateId) {
    let screeningInteraction = this.filterScreeningInteractions(templateId);
    screeningInteraction[0].otherInteractionSelection = [value];
  },

  setOtherInteractionTiming(value, templateId) {
    let screeningInteraction = this.filterScreeningInteractions(templateId);
    screeningInteraction[0].otherInteractionSelectedTiming = value;
    if (
      screeningInteraction[0].otherInteractionSelectedTiming === "Pre - Flight"
    ) {
      this.setOtherInteractionAdministration("", templateId);
    }
  },

  setOtherInteractionAdministration(value, templateId) {
    let screeningInteraction = this.filterScreeningInteractions(templateId);
    screeningInteraction[0].otherInteractionSelectedAdministration = value;
  },

  setInfoIconStyle(options) {
    let element = options.htmlElement.querySelector("#sq_100_ariaTitle");
    let ariaLabel = !!element ? element.getAttribute("aria-label") : "";
    let infoIcon = null;

    if (
      ariaLabel === "Which body region are you primarily seeking treatment for?"
    ) {
      let infoIconArray = element.getElementsByClassName(
        "fa fa-info-circle fa-lg"
      );
      infoIcon = get(infoIconArray, "[0]", null);
    }

    if (!!infoIcon) {
      infoIcon.style.position = "initial";
      infoIcon.style.marginRight = "8px";
    }
  },

  toggleOtherInteractionsSelection(templateId) {
    let screeningInteraction = this.filterScreeningInteractions(templateId);
    screeningInteraction[0].otherInteraction =
      !screeningInteraction[0].otherInteraction;
  },

  toggleOnDemandSelection(templateId) {
    let screeningInteraction = this.filterScreeningInteractions(templateId);
    screeningInteraction[0].onDemand = !screeningInteraction[0].onDemand;
  },

  saveSelectedModality(
    selectedModality,
    selectedFrequencyType,
    selectedFrequencyValue,
    templateId
  ) {
    let screeningInteraction = this.filterScreeningInteractions(templateId);
    let modalities = [];
    modalities = [
      {
        modality: selectedModality,
        frequencyType: selectedFrequencyType,
        frequencyValue: selectedFrequencyValue,
        selectedTiming: screeningInteraction[0].otherInteractionSelectedTiming,
        selectedAdministrationType:
          screeningInteraction[0].otherInteractionSelectedAdministration,
      },
      ...screeningInteraction[0].modalities,
    ];

    screeningInteraction[0].modalities = uniqBy(modalities, "modality");
  },

  deleteSelectedModality(value, templateId) {
    let screeningInteraction = this.filterScreeningInteractions(templateId);
    remove(
      screeningInteraction[0].modalities,
      (option) => option.modality === value
    );
  },
});

computed(screeningStore, {
  /*
  ON DEMAND SCREENINGS: Live screenings that have an on-demand interaction type
  - Selects live ad-hoc screenings
  - Coerces Survey Template data to a "step" based data structure like in screen/config.coffee
  */
  get onDemandScreenings() {
    return screeningStore.screeningTemplates
      .filter((screen) => {
        if (screen.status === "live") {
          // Find out if this has an on demand interaction. If so, drill the ID into template:{}
          const onDemandInteraction = screen.template_interactions.find(
            (int) => int.interaction_type === "on_demand"
          );
          if (onDemandInteraction) {
            screen.template_interaction_id = onDemandInteraction.id;
            return true;
          }
        }
        return false;
      })
      .reduce((mapIdsToScreenings, template) => {
        const { id, name, screeningTemplate } = template;
        mapIdsToScreenings[id] = {
          // Including these fields prevents null selection bugs in
          title: name,
          ids: [],
          sections: [],
          // Survey data and bool for type checking elsewhere
          screeningTemplate,
          template,
        };
        return mapIdsToScreenings;
      }, {});
  },

  get schedulingScreeningModel() {
    if (!this.schedulingScreening) {
      return null;
    }

    const model = new SurveyJS.Model(toJS(this.schedulingScreening.data));
    model.onTextMarkdown.add((survey, options) => {
      let str = marked(options.text, { renderer: MARKED_RENDERER });

      // Trim wrapping <p> tag(s)
      str = str.substring(3);
      str = str.substring(0, str.length - 5);

      options.html = str;
    });
    model.onAfterRenderQuestion.add(function (survey, options) {
      let miniScreeningDoc = document.getElementById("MiniScreening");
      if (miniScreeningDoc) {
        setTableHeaderAttributes(options);
      }

      if (!/body\s+part/i.test(options.question.rfvLabel)) return;

      var btn = document.createElement("i");
      btn.type = "button";
      btn.className = "fa fa-info-circle fa-lg";
      btn.style.position = "absolute";
      btn.style.padding = "5px 0 0 5px";
      btn.style.color = "#337bb7";
      btn.onclick = function () {
        appStore.actions.showModal({
          className: "Modal--transparent",
          body: (
            <img
              alt={"Body Parts"}
              src={"/combo/images/body-parts.png"}
              height="600"
            />
          ),
          noClose: true,
        });
      };
      var header = options.htmlElement.querySelector("h5");
      var span = document.createElement("span");
      span.innerHTML = "  ";
      header.appendChild(span);
      header.appendChild(btn);
    });
    model.onAfterRenderSurvey.add((survey, options) => {
      let miniScreeningDoc = document.getElementById("MiniScreening");
      if (miniScreeningDoc) {
        this.setInfoIconStyle(options);
      }
    });

    Object.assign(model, {
      checkErrorsMode: "onComplete",
      showNavigationButtons: false,
      showPageTitles: false,
      showQuestionNumbers: "off",
      showCompletedPage: false,
      clearInvisibleValues: "none",
    });

    return model;
  },

  get schedulingScreening() {
    const apptType = scheduleStore.apptType.name;
    const apptTypeId = scheduleStore.apptType.id;
    const siteId = this.slotClinicId;
    let schedulingTemplate = null;

    screeningStore.screeningTemplates
      .filter((template) => template.status === "live")
      .forEach((template) => {
        const activeInteraction = template.template_interactions.find((x) => {
          return (
            x.interaction_type === "schedule" &&
            x.status === "live" &&
            x.clinic_id == siteId &&
            x.appointment_type == apptTypeId
          );
        });

        if (activeInteraction) {
          schedulingTemplate = Object.assign(template, {
            activeInteraction: activeInteraction,
          });
        }
      });

    return schedulingTemplate;
  },

  get priorScreeningData() {
    return screeningStore.screeningData.slice(-1)[0]; //  Returns the last element without mutating the source
  },

  get healthScreeners() {
    const zoneAbbr = timezone(synopsisStore.site.zone);

    const sanitizeStatus = (rawStatus) => {
      if (rawStatus === "active") return "In Progress";
      return capitalize(rawStatus) || "Incomplete";
    };

    return screeningStore.healthScreenings.map((screening) => {
      const status = sanitizeStatus(screening.status);
      const assigned_at = destructureDateTime(screening.assigned_at, zoneAbbr);
      const completed_at = destructureDateTime(
        screening.patient_screening_completed_at,
        zoneAbbr
      );

      return { ...screening, status, assigned_at, completed_at };
    });
  },

  get completedScreeners() {
    return sortBy(
      screeningStore.healthScreeners.filter(
        (screening) => screening.status === "Complete"
      ),
      ["id"]
    );
  },

  get outstandingScreeners() {
    return sortBy(
      screeningStore.healthScreeners.filter(
        (screening) => screening.status !== "Complete"
      ),
      ["id"]
    );
  },

  get outboundScreenerTemplate() {
    const { screening_template } = screeningStore.outboundScreeningTemplate;
    if (!screening_template) return {};

    return { ...screening_template };
  },

  get patientScreenerData() {
    return (
      screeningStore.outboundScreeningTemplate.patient_screening_data || "[]"
    );
  },

  get noOfOutstandingScreeners() {
    return screeningStore.outstandingScreeners.length;
  },
});

module.exports = screeningStore; // (imported elsewhere as commonjs)
