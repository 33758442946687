"use strict";
const Model = require("./base");
const Provider = require("./provider");
const AppointmentType = require("./appointment-type");
const { shortWeekdays, nonUS } = require("config");
const { MomentRange, onlyTime, onlyDate, computed } = require("lib/utils");
const { ISO_TIME } = require("lib/formats");
const { flatMap, compact, uniqBy } = require("lodash");

const rejectAnyProvider = (p) => p.user != 0;

class Site extends Model {
  static fromAPI(raw) {
    const synopsisStore = require("stores/new-synopsis");

    let operationHours = raw.hours_of_operation || {};
    let workingDays = Object.keys(operationHours).map((day) =>
      shortWeekdays.indexOf(day)
    );
    let workingRanges = new Map(
      Object.entries(operationHours).map(([apiDay, hours]) => {
        let [start, end] = hours.map((time) => {
          return onlyTime(moment.tz(time, ISO_TIME, raw.zone));
        });

        let day = shortWeekdays.indexOf(apiDay);
        let range = new MomentRange(start, end);
        return [day, range];
      })
    );

    return super.fromAPI({
      workingDays,
      workingRanges,
      _providers: uniqBy(raw.providers.filter(rejectAnyProvider), "user"),
      modalities: raw.modalities.map((mod) => {
        let apptTypes = mod.list.map((id) => synopsisStore.findApptType(id));

        return {
          name: mod.type,
          color: mod.color,
          apptTypes: compact(apptTypes),
        };
      }),
      id: raw.id,
      code: raw.code,
      zone: raw.zone,
      name: raw.name,
      waitListMessage: raw.wait,
      contactTel: raw.dial,
      apptMessages: Object.entries(raw.anys).map((any) => {
        let [html, apptTypeIds] = any;
        return { html, apptTypeIds };
      }),
      insurances: raw.insurances,
    });
  }

  constructor(params) {
    super({
      workingDays: [],
      workingRanges: new Map(),
      _providers: [],
      modalities: [],
      code: "",
      zone: null,
      name: "",
      waitListMessage: "",
      contactTel: "",
      apptMessages: [],
      insurances: {},
      ...params,
    });

    this.modalities.forEach((mod) => {
      mod.apptTypes = mod.apptTypes.map((aT) => (aT ? aT.setSite(this) : aT));
    });
  }

  isWorkingDay(date) {
    return this.workingDays.includes(date.day());
  }

  getWorkingRange(date) {
    return this.workingRanges.get(date.day());
  }

  getProvider(id) {
    return this.providers.find((prov) => prov.id == id);
  }

  hasProvider(id) {
    return this.providers.some((prov) => prov.id == id);
  }

  getProvidersForType(id) {
    return this.providers.filter((prov) => prov.type == id);
  }

  hasProvidersForType(id) {
    return this.providers.some((prov) => prov.type == id);
  }

  findApptType(id) {
    return this.apptTypes.find((aT) => aT.id == id);
  }

  get now() {
    return moment.tz(this.zone);
  }

  get today() {
    return onlyDate(this.now);
  }
}

computed(Site.prototype, {
  get isAny() {
    return this.id == "__any__";
  },

  get providers() {
    return this._providers.map((raw) => Provider.fromAPI(raw));
  },

  get apptTypes() {
    return [
      ...this.bookableApptTypes,
      ...AppointmentType.INTERNAL_TYPES,
      AppointmentType.OPEN,
    ];
  },

  get bookableApptTypes() {
    return uniqBy(
      flatMap(this.modalities, (mod) => mod.apptTypes),
      "id"
    );
  },

  get hasWaitList() {
    return !!this.waitListMessage;
  },

  get contactTelURL() {
    return this.contactTel.replace(/\D/g, "");
  },

  get isUS() {
    return !nonUS.includes(this.code);
  },

  get isCork() {
    return this.code == "crk";
  },

  get isSingapore() {
    return ["amk", "inn"].includes(this.code);
  },
});

module.exports = Site;
