import { mapValues } from "lodash";
import { computed, decorate } from "mobx";

const { isMoment, isDuration } = moment;
const customComputed = computed({
  equals: (a, b) => {
    if (isMoment(a) && isMoment(b)) return +a == +b;
    if (isDuration(a) && isDuration(b)) return +a == +b;

    return Object.is(a, b);
  },
});

export default (target, params) => {
  let descs = Object.getOwnPropertyDescriptors(params);
  let decorators = mapValues(descs, () => customComputed);

  Object.defineProperties(target, descs);
  decorate(target, decorators);
};
