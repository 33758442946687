ReactDOM = require 'react-dom'
{div, strong, span} = Exim.DOM

ttpHeight = 40

ChartTooltip = Exim.createView module.id,
  getInitialState: ->
    style: {}

  componentDidUpdate: (props, state) ->
    node = ReactDOM.findDOMNode(this)
    fn = -> node.classList.add('is-tooltip-active')
    setTimeout fn, 100
    height = node.clientHeight
    if (res = height - ttpHeight) > 0 and state.style isnt @state.style
      style = @state.style
      style.top -= res
      @setState style: style

  componentWillUpdate: (props, state) ->
    if @state.display isnt state.display
      ReactDOM.findDOMNode(this).classList.remove('is-tooltip-active')

  render: ->
    currentStyle = @state.style or {}
    style =
      left: currentStyle.left
      top: currentStyle.top - 15
      width: currentStyle.width
      display: if @state.display then 'block' else 'none'

    div role: 'tooltip', style: style, className: "profile-tooltip angle-#{@state.angle} #{if @state.className then @state.className else ''}",
      strong @state.value
      span @state.date

module.exports = ChartTooltip
