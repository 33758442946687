class Card extends React.Component {
  render() {
    const { iconClass, children, className, title } = this.props;
    return (
      <div className={`HraResult-card ${className}`}>
        <div className="HraResult-column--icon">
          <i className={`fa icon ${iconClass}`} />{" "}
        </div>
        <div className="HraResult-column--body">
          <span className="HraResult-column--title">{title}</span>
          {children}
        </div>
      </div>
    );
  }
}

export default Card;
