{appStore, authStore, documentsStore} = require 'stores'

DocumentVerification   = require './DocumentVerification'
Search        = require 'components/mixins/Search'
attachHeader  = require 'components/mixins/attachHeader'
PullToRefresh = require 'components/elements/PullToRefresh'
Footer        = require 'components/app/Footer'
PatientHeader = require 'components/elements/PatientHeader'
ReactDOM      = require 'react-dom'
{features, touchDevice, iOS} = require 'config'
cache         = require 'lib/cache'
utils         = require 'lib/utils'
{cx}          = Exim.helpers
newAuthStore = require 'stores/new-auth'

# Fix for iOS
loadImage = require 'blueimp-load-image'

MD_DOWN = 767

{main, section, div, span, strong} = Exim.DOM

Documents = Exim.createView module.id,
  mixins: [
    Search,
    documentsStore.connect('documentsLoading', 'documentUploading', 'filter'),
    authStore.connect('user')
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  searchKeys: -> ['title', 'mime']

  saveSearch: ( val ) ->
    documentsStore.actions.updateFilter(val)

  getSavedSearch: ->
    documentsStore.get('filter')

  getActions: ->
    actions = [
      title: "New #{@getTitle()}"
      to: '/messages/new'
      icon: 'plus'
      params: null
      upload: @upload
      uploading: 'documentUploading'
      query: {}
      key: 'messages-create'
    ]

    if newAuthStore.isPortalReadOnly
      actions = []

    return actions

  getTitle: ->
    return 'Document'

  componentWillMount: ->
    @single = !@context.router.isActive('/documents', true)
    if features.newApi
      subLinks = [
        {title: 'Active', to: '/documents', onlyActiveOnIndex: true}
        {title: 'Archived', to: '/documents/archived'}
      ]
    else subLinks = null

    appStore.actions.setHeader(name: "#{@getTitle()}s", links: subLinks, actions: @getActions(), search: not @single and @onSearch, clearSearch: @clearSearch, matcher: @state.matcher)

  componentDidMount: ->
    appStore.actions.setTitle('Documents')
    if scrollTop = cache.documents.scrollTop
      element = ReactDOM.findDOMNode(@refs.docs)
      element.scrollTop = scrollTop
    appStore.actions.updateHeader(actions: @getActions())
    document.querySelector('#contentSearchInput').focus()

  componentWillUpdate: (nextProps, nextState) ->
    appStore.actions.updateHeader(actions: @getActions())

    if (nextState.user.id != @state.user.id)
      documentsStore.actions.fetch()

  componentWillUnmount: ->
    appStore.actions.setTitle()
    element = ReactDOM.findDOMNode(@refs.docs)
    cache.documents.scrollTop = element.scrollTop
    onDocs = @context.router.isActive('/documents')
    unless onDocs
      documentsStore.actions.clear()
      documentsStore.actions.clearFilter()
      appStore.actions.showModal(null) if appStore.get('modal')

  showUploadModal: ->
    body = require './Upload'
    appStore.actions.showModal
      title: 'Upload Documents'
      body: body()

  fetch: ->
    {router} = @context
    if router.isActive('/documents')
      documentsStore.actions.fetch()
    else if router.isActive('/documents/archived')
      documentsStore.actions.fetchArchived()

  toDocs: ->
    appStore.actions.showModal(null)
    @context.router.push '/documents'

  upload: (evt) ->
    {files} = evt.currentTarget

    if iOS
      filesReady = []
      for i in [0...files.length]
        file = files[i]
        filesReady.push new Promise (resolve) ->
          loadImage.parseMetaData file, (data) ->
            orientation = if data.exif
              orientation = data.exif.get 'Orientation'
            else
              0

            loadImage file, (canvas) =>
                base64 = canvas.toDataURL(file.type)
                blob = utils.dataURItoBlob(base64)
                blob.name = file.name
                resolve(blob)
              ,
                canvas: true,
                orientation: orientation

      Promise.all(filesReady).then (filesArray) =>
        @_upload(filesArray)
    else
      filesArray = []
      for i in [0...files.length]
        filesArray.push files[i]
      @_upload(filesArray)

  _upload: (filesArray) ->
    console.log("Uploading #{filesArray.length} files", filesArray)
    Promise.all(filesArray.map (file) -> documentsStore.actions.upload(file, fileName: file.name)).then =>
      console.log("Completed #{filesArray.length} files", filesArray)
      if authStore.get('user')?.proxying
        doc = documentsStore.get('uploadedDocument')
        url = utils.getUrl(doc.url.slice(1))
        mime = utils.humanizeMime(doc.mime).toLowerCase()

        className = 'DocumentVerificationModal'
        title = div null,
          'Document Upload Verification'
        body = DocumentVerification {doc, url, mime, onClose: @toDocs}

        modal = {title, body, className, disableClose: true}
        appStore.actions.showModal(modal)

  onDragOver: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    evt.dataTransfer.dropEffect = 'copy'
    @setState dragging: true

  onDragLeave: (evt) ->
    @setState dragging: false

  onDrop: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    files = evt.dataTransfer.files
    @setState dragging: false
    filesArray = []
    for i in [0...files.length]
      filesArray.push files[i]
    @_upload(filesArray)

  render: ->
    withPull = touchDevice and not @single
    matcher = @state.matcher
    common = ref: 'docs', className: 'Content-body'
    {dragging} = @state

    args =
      ref: 'docs', tag: section
      className: 'Content-body'
      onRefresh: @fetch
      isLoading: @state.documentsLoading
      static: withPull

    main id: 'mainContent', className: "Content #{cx 'Content--withPatientHeader': @state.user.proxying}",
      PullToRefresh args,
        div className: 'ContentInnerWrapper',
          PatientHeader()# className: 'PatientHeader--fullWidth'
          div className: "ContentInner Documents #{cx full: touchDevice and @single, 'is-dragging': dragging}", onDragOver: @onDragOver,
            div className: 'DocumentsOverlayWrapper', onDragLeave: @onDragLeave, onDrop: @onDrop,
              div className: 'DocumentsOverlay',
                div className: 'DocumentsOverlay-message',
                  span 'Drop a file to add to Documents.'
            React.cloneElement @props.children, {@filterer, @fetch, matcher}
          Footer()

module.exports = Documents
