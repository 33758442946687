'use strict'
scheduleStore = require('stores/schedule').default
{Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody} = require('react-accessible-accordion')
AppointmentTypesAccordion = require('./AppointmentTypesAccordion')
getFocusableElements = require('lib/utils/getFocusableElements').default
{b, div, span, h4, h5, textarea, p, ul, li, button, a, tch, fa, strong, i, input, label, img, header, option, optgroup, fieldset, legend} = Exim.DOM

AppointmentTypesAccordionContainer = React.createClass({
  displayName: 'AppointmentTypesAccordionContainer'
    
  componentDidMount: ->
    context = this
    setTimeout(() ->
      modal = document.getElementById('appointment-details-accordion')
      context.setAndTrapFocus(modal)
    , 0)

  componentWillUnmount: ->
    modal = document.getElementById('appointment-details-accordion')
    modal.removeEventListener('keydown', (e) -> context.handleKeydowns(e, focusableEls));

  handleKeydowns: (evt, focusableEls) ->
    # because the last focusable element changes depending on the state
    # of the accordion we have to do a lot of custom keyhandling
    isTabPressed = (evt.key =='Tab' || evt.keyCode == 9 || evt.code == 'Tab')
    isEscapeKey = (evt.key == 'Escape' || evt.keyCode == '27' || evt.code == 'Escape')
    if (!isTabPressed && !isEscapeKey)
      return # do nothing
    if (isEscapeKey)
      @handleCloseAccordionClick()
      return

    firstFocusableEl = focusableEls[0];
    lastExpandableDiv = focusableEls[focusableEls.length - 2];
    lastButton = focusableEls[focusableEls.length - 1];
    allActivators = document.querySelectorAll('*[id^="accordion__heading"]');
    lastAccordionActivator = allActivators[allActivators.length - 1];

    isLastDivExpanded = lastAccordionActivator.getAttribute("aria-expanded") == 'true'
    isLastDivClosed = lastAccordionActivator.getAttribute("aria-expanded") == 'false'

    if (evt.shiftKey) # then key press is shift + tab -- we've filtered out non-tab keypresses above
      if (document.activeElement == firstFocusableEl)
        if (isLastDivExpanded)
          lastButton.focus()
        else
          lastExpandableDiv.focus()
        # default behavior is to move focus to next element in tab flow, since
        # we've set programmatically we need to stop the default
        # behavior of the keypress in question
        evt.preventDefault()

    if (isTabPressed && !evt.shiftKey)
      if (document.activeElement == lastExpandableDiv)
        if (isLastDivClosed)
          firstFocusableEl.focus()
        else
          lastButton.focus()  
        evt.preventDefault()
      else if (document.activeElement == lastButton)
        firstFocusableEl.focus()
        evt.preventDefault()

  setAndTrapFocus: (modal) -> 
    context = this
    focusableEls = getFocusableElements(modal)
    focusableEls[0]?.focus()
    modal.addEventListener('keydown', (e) -> context.handleKeydowns(e, focusableEls));

  handleScheduleAppointmentTypeClick: (type) -> 
    scheduleStore.apptType = type
    # this set timeout is a bit hacky, but it works, there are other components trying
    # to grab focus at this point and by throwing this .focus invocation in the event
    # loop we make sure focus ends up on the element we want it on. Not best practice
    setTimeout(() -> 
      isVisitLocationRadioSelected = scheduleStore.searchBy == 'location'
      if isVisitLocationRadioSelected 
        providerDropdownElement = document.getElementById('focus-ref-select-provider')
        providerDropdownElement?.focus()
      else
        visitLocationSelectElement = document.getElementById('schedule-site-select')
        visitLocationSelectElement?.focus()
    , 0)
    this.props.closeAccordion()
  
  handleCloseAccordionClick: () ->
    # return focus to button activating the accordion if no selection has been made
    activationButton = document.getElementById(this.props.activatorId)
    this.props.closeAccordion()
    activationButton?.focus()
  
  getAccordionPanelText: (type) ->
    return if scheduleStore.site?.appointment_description_overrides?[type.id] then scheduleStore.site.appointment_description_overrides[type.id] else if type.description then type.description else 'Please message the Center for additional information'

  render: ->
      return React.createElement(AppointmentTypesAccordion, {
        appointmentTypes: scheduleStore.apptTypes,
        handleCloseAccordionClick: this.handleCloseAccordionClick,
        handleScheduleAppointmentTypeClick: this.handleScheduleAppointmentTypeClick
        getAccordionPanelText: this.getAccordionPanelText,
      })
})

module.exports = AppointmentTypesAccordionContainer
