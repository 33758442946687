config = {}

config.ids = ids =
  lipids:    [14,15,16,17,30,31,25]
  chemistry: [21,22,23,24,25,26,27,28,29,30,31,32,33,34]
  misc:      [35,38,39,78,70,37,92,36,93,94]
  urin:      [42,44,47,48,41,43,40,45,46,49,50]
  fitness:   [51,52,53,54,57]
  lifestyle:
    fitness: [58]
    sleep: [71, 72]
    stress: [61, 62]
    nutrition: [63, 64, 65, 67]
    habits: [68, 69]
    wellness: [65, 76, 77]
  mag7:      [83..90]
  gad7:      [120..128]
  phq9:      [131..141]
  insomnia: [190..197]

config.steps = steps =
  biometrics:
    title: 'Biometrics'
    ids: [1,2,3,4,5,7,51,8,9,10,11,12,13]

  laboratory:
    title: 'Lab'
    ids: ids.lipids.concat ids.chemistry, ids.misc, ids.urin
    sections: [
      {name: 'Lipids'       , ids: ids.lipids}
      {name: 'Chemistry'    , ids: ids.chemistry}
      {name: 'Miscellaneous', ids: ids.misc}
      {name: 'Urinalysis'   , ids: ids.urin}
    ]

  sleep:
    title: 'Sleep'
    ids: ids.insomnia
    sections: [
      {
        name: 'Insomnia Severity Index © Morin, C.M. 1993, 2006'
        ids: ids.insomnia
        total: ids.insomnia[ids.insomnia.length - 1]
        legend: ['0-7: No clinically significant insomnia', '8-14: Subthreshold insomnia', '15-21: Clinical insomnia (moderate severity)', '22-28: Clinical insomnia (severe)']
      }
    ]

  physmed:
    title: 'Physical Medicine'
    ids: ids.mag7
    sections: [
      {
        name: 'Mag-7'
        ids: ids.mag7
        total: ids.mag7[ids.mag7.length - 1]
        legend: ['0: Painful', '1: Cannot perform', '2: Performs w/compensation', '3: Performs w/out compensation']
      }
    ]

  fitness:
    title: 'Fitness'
    ids: ids.fitness

  behavioral:
    title: 'Behavioral'
    ids: ids.gad7.concat ids.phq9
    sections: [
      {
        name: 'PHQ-9 - Over the last 2 weeks, how often have you been bothered by any of the following problems?'
        ids: ids.phq9
        total: ids.phq9[ids.phq9.length - 2]
        subtotal: {
          id: 141,
          included: ids.phq9[0..1]
        }
        legend: ['0: Never', '1: Several days', '2: Over half the days', '3: Nearly every day']
        score:  ['0-4: minimal or no depression', '5-9: mild depression', '10-14: moderate depression', '15-19: moderately severe depression', '20+: severe depression']
      }
      {
        name: 'GAD-7 - Over the last 2 weeks, have you felt bothered by any of these things?'
        ids: ids.gad7
        total: ids.gad7[ids.gad7.length - 2]
        subtotal: {
          id: 128,
          included: ids.gad7[0..1],
        }
        legend: ['0: Never', '1: Several days', '2: Over half the days', '3: Nearly every day']
        score:  ['0-4: no anxiety', '5–9: mild anxiety', '10–14: moderate anxiety', '15–21: severe anxiety'],
      }
    ]

  lifestyle:
    title: 'Lifestyle'
    ids: Object.keys(ids.lifestyle).reduce(((o,n)->o.concat ids.lifestyle[n]),[])
    sections: [
      {name: 'Fitness', ids: ids.lifestyle.fitness}
      {name: 'Sleep', ids: ids.lifestyle.sleep}
      {name: 'Stress', ids: ids.lifestyle.stress}
      {name: 'Nutrition', ids: ids.lifestyle.nutrition}
      {name: 'Habits', ids: ids.lifestyle.habits}
      # {name: 'Wellness', ids: ids.lifestyle.wellness}
    ]

config.firstStep = 'biometrics'

module.exports = config
