"use strict";
const { times } = require("lodash");

const renderBar = (at) => {
  return <span key={at} className="Spinner-bar" />;
};

const Spinner = ({ className = "" }) => {
  return <div className={`Spinner ${className}`}>{times(12, renderBar)}</div>;
};

module.exports = Spinner;
