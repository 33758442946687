store = require 'stores/metrics'
{cx} = Exim.helpers
{div, icn, span, input, fa} = Exim.DOM

DateRange = require 'components/elements/DateRange'
Select = require 'components/elements/Select'
today = moment()

Allergens = Exim.createView module.id,
  componentWillMount: ->
    @providers = store.get('providers')

  getInitialState: ->
    provider: store.get('providers')[0]

  updateProvider: (provider) ->
    @setState {provider}

  onChangeRange: (dates) ->
    console.log dates

  render: ->
    div className: 'widget-item allergens',
      div className: 'body',
        div className: 'widget-item-range',
          div className: 'axis',
            '2008'
            '2010'
            '2012'
            '2014'
            'TODAY'
          DateRange
            start: today.subtract(7, 'years')
            max: today
            onChange: @onChangeRange
            multiple: true
            className: 'xo-range'
        div className: 'widget-item-select',
          Select
            values: @providers
            onChange: @updateProvider
            selected: @state.provider
            label: (prov) -> prov[2]
            angle: true

module.exports = Allergens
