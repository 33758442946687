import { appStore } from "stores";
import authStore from "stores/new-auth";
import { Spinner } from "components/elements";
import { observer } from "mobx-react";

class Appointments extends React.Component {
  componentWillMount() {
    appStore.actions.setHeader(null);
    appStore.actions.setTitle("Appointments");
  }

  render() {
    let { user } = authStore;

    return (
      <div className="NewContent">
        {user.isCreated ? (
          user.isAdmin ? (
            <p className="Appointments-choosePatient">
              Please choose a patient to schedule an appointment.
            </p>
          ) : (
            this.props.children
          )
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

module.exports = observer(Appointments);
