authStore    = require 'stores/auth'
{capitalize} = require 'lib/utils'
{mount, mountEl, match, from,
onAdminEnter,
onTravelerEnter,
onSignInEnter,
onSignUpEnter,
onCalendarEnter} = require 'lib/router-helpers'
checkInRoutes = require 'components/checkin/routes'
hraRoutes = require 'components/surveys/hra/routes'

{features,
defaultAdminRoute,
defaultPatientRoute,
touchDevice} = require 'config'

{newApi, apptsOnly}  = features

{Router, Route, IndexRoute} = Exim.Router
{browserHistory} = require('react-router')
user                 = authStore.get('user')
defaultRouteName     = if user.admin and !user.proxying then defaultAdminRoute else defaultPatientRoute

portalRoutes = [
  match '/appointments', mountEl('appointments/appointments'), [
    match index: true, mountEl('appointments/all'), onEnter: onTravelerEnter
    match 'new*', mountEl('appointments/Schedule'), onEnter: onTravelerEnter
  ]
  match '/health-screenings', mountEl('outbound-screenings/SectionWrapper'), [
    match index: true, mountEl('outbound-screenings/AllOutboundScreenings')
    match ':id', mountEl('outbound-screenings/OutboundScreening')
  ]
]

unless apptsOnly
  portalRoutes = portalRoutes.concat [
    match '/landing', mount('landing/Landing')
    match '/whatsNew', mount('landing/WhatsNewPage')
    match '/checkin/screen', mountEl('screen/Screen'),
      match ':step', mount('screen/Step')
    match '/profile', [
      match index: true, mountEl('profile/Show')
      match 'edit',   mountEl('profile/Edit')
      match 'edit/photo',   mount('profile/ProfilePhoto')
      match 'edit/photo/crop',   mount('profile/ProfilePhotoCrop')
      match 'payment',   mountEl('profile/AddPaymentMethod')
    ]
    match '/schedule', mountEl('schedule'), onEnter: onAdminEnter(isAdmin: true, allowProxying: false, pin: true)
    match '/calendar', mountEl('calendar/calendar'), onEnter: onAdminEnter(isAdmin: true, allowProxying: false, pin: true), [
      match index: true, mountEl('calendar/Schedule'), onEnter: onCalendarEnter
      match 'conflicts', mountEl('calendar/conflicts'), onEnter: onCalendarEnter
    ]
    match '/trends', mountEl('trends/Trends'), onEnter: onAdminEnter(isAdmin: false), [
      match index: true, mount('trends/Latest')
      match 'archived', mount('trends/Archived')
    ]
    match '/medications', mount('medications/Medications')
    match '/messages',                   mount('messages/Messages'), [
      match index: true,                 mount('messages/Inbox')
      match ':type/:messageId/reply',    mountEl('messages/Create')
      match ':type/:messageId/replyall', mountEl('messages/Create')
      match ':type/:messageId/forward',  mountEl('messages/Create')
      match ':type/:id',                 mountEl('messages/Show')
      match 'sent',                      mount('messages/Sent')
      match 'new',                       mountEl('messages/Create')
      match 'archived',                  mount('messages/Archived')
      match 'flagged',                   mount('messages/Flagged')
      match 'unread',                    mount('messages/Unread')
    ]
    match '/documents', mount('documents/Documents'), [
      match 'archived',  mount('documents/DocumentsList')
      match ':id', if touchDevice then mountEl('documents/DocumentView') else mount('documents/DocumentsList')
      match index: true, mount('documents/DocumentsList')
    ]
    match '/screenings', mount('screenings/Screenings'), [
      match 'new', mountEl('screenings/ScreeningView')
      match ':id', mountEl('screenings/ScreeningView')
      match index: true, mountEl('screenings/ScreeningsList')
    ]
    match '/survey/hra', mountEl('wellness/HRASurvey'), []
    match '/wellness', mountEl('wellness/index'), [
      match index: true, mountEl('wellness/Overview')
      match 'overview', mountEl('wellness/Overview')
      match 'result', mountEl('wellness/Result')
    ]
    match '/laboratories',
      match index: true, mount('laboratories/All')
    if newApi
      match '/timeline',
        match index: true, mount('timeline/Recent')
  ]

module.exports =
  Router history: browserHistory,
    checkInRoutes,
    hraRoutes

    Route component: mount('app/App'),
      match '/home*',           mountEl('auth/home'),
      match '/oidc-loading',   mount('auth/oidc/Loading')
      match '/forgot',         mount('auth/signin/ResetPassword')
      match '/security',       mount('auth/SecurityDisclaimer')
      match '/reset/:code',    mount('auth/reset/Reset')
      match '/activate/:code', mount('auth/activation/Activation')
      match '/verify/:code',   mountEl('auth/verify/verify')
      match '/signin',         mount('auth/signin/SignIn'), onEnter: onSignInEnter
      match '/signin/notification', mount('auth/signin/SignInNotification')

      from  '/signup',         to: '/signup/account'
      match '/signup/:step',   mount('auth/signup/Signup'), onEnter: onSignUpEnter

      match '/checkin/auth',   mount('auth/checkin/Pin')
      match '/browsers',       mount('app/Browsers')

      from '/', to: defaultRouteName
      from '/signout', to: '/signin'
      from '/logout', to: '/signin'

      Route component: mountEl('app/portal-wrapper'), portalRoutes

      from '*', to: defaultRouteName
