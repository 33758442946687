"use strict";
const { Modal } = require("components/elements/new-modal");

const SlotSeriesModal = ({ abort, title, resolve, metrics: m }) => {
  return (
    <Modal className="SlotSeriesModal" title={title} close={abort}>
      <p>These changes will have the following effects:</p>
      <ul>
        <li>
          <b>Creating:</b> {m.createdCount}{" "}
          {m.createdCount == 1 ? "slot" : "slots"} created
        </li>
        <li>
          <b>Deleting:</b> {m.deletedCount}{" "}
          {m.deletedCount == 1 ? "slot" : "slots"} deleted
        </li>
        <li>
          <b>Overlapping:</b> {m.overlappedCount}{" "}
          {m.overlappedCount == 1 ? "slot overlaps" : "slots overlap"} with
          existing slots
        </li>
      </ul>
      <footer className="ModalFooterButtons">
        <button className="Button Button--cancel" onClick={() => abort()}>
          Cancel
        </button>
        <button className="Button Button--primary" onClick={() => resolve()}>
          Proceed
        </button>
      </footer>
    </Modal>
  );
};

module.exports = SlotSeriesModal;
