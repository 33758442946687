export const SelectOptions = ({
  options,
  onChange,
  className,
  value,
  field: { name, label },
}) => {
  return (
    <div className="Hra-select">
      <select
        onChange={(e) => onChange(e, name)}
        className={`${className} ${value !== "" && "Hra-select-values"}`}
        defaultValue=""
      >
        <option value="" disabled={true}>
          {label}
        </option>
        {Object.keys(options).map((key) => {
          return (
            <option key={key} value={options[key]}>
              {options[key]}
            </option>
          );
        })}
      </select>
    </div>
  );
};
