MessagesList   = require './MessagesList'
Messages       = require 'components/mixins/Messages'
ActionDropdown = require 'components/elements/ActionDropdown'
Tooltip        = require 'components/elements/Tooltip'
media          = require 'components/mixins/MediaQuery'

{actionToIconMap} = require './messageUtils'
{appStore, messagesStore} = require 'stores'
{messageFlags, messageFlagsPlaceholder}  = require 'config'
{keyboardScrollTable}  = require('lib/utils/keyboardScrollTable')

utils = require 'lib/utils'
{cx}  = Exim.helpers
{div, tch, fa, span, tr, td} = Exim.DOM

type = 'sent'

SentMessage = Exim.createView module.id,
  mixins: [messagesStore.connect(type, 'zoneAbbr'), media(showFlags: 'phoneOrTablet')]
  type: type

  contextTypes:
    router: React.PropTypes.object.isRequired

  show: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    @context.router.push pathname: "/messages/#{@type}/#{@props.data.id}", query: page: @props.page

  getInitialState: ->
    lastActionHover: false

  getDefaultProps: ->
    data: subject: ''

  mouseEnter: (evt) ->
    @setState lastActionHover: true

  mouseLeave: (evt) ->
    @setState lastActionHover: false

  flag: (message, flag) -> =>
    data = {}
    message.flags = if message.flags is flag then '' else flag
    {flags} = message

    messagesStore.actions.update(type, message, {flags}, true)

  getFlags: ->
    {data} = @props
    utils.clone messageFlags?.map (f) => f.handler = @flag(data, f.id); f

  render: ->
    {data, matcher} = @props

    rawName = data.to.map((user) -> user.name).join('; ')

    name = utils.highlightMatched(rawName, matcher)
    subject = utils.highlightMatched(utils.getSubject(data.subject), matcher)

    opts = cx
      attachments: data.document_ids?.length > 0

    flag = messageFlags.find((f) => f.id == data.flags)
    flags = @getFlags()

    div className: "TableBody-row MessagesList-item #{opts}", role: 'row',
      div className: 'TableBody-column MessagesList-item-flag', role: 'gridcell',
        ActionDropdown
          className: 'MessageCardTitle-actionsItem ActionDropdown--flags'
          values: flags
          select: true
          selected: data.flags
          placeholder: messageFlagsPlaceholder
          isMessages: true
          elementId: @props.messageNumber
      tch tagName: "span", role: 'link', className: "MessagesList-item MessagesList-item-info", id: @props.messageNumber, handler: @show, onEnter: @show, tabIndex: 0, 'aria-label': "Row #{@props.messageNumber}, from #{data.to?[0]?.name}, subject #{data.subject}, received #{utils.formatDateShort(data.date)} #{if @state.zoneAbbr then (@state.zoneAbbr) else ''}, click to read full message",
        div className: 'TableBody-column MessagesList-item-title u-1of3', role: 'gridcell',
          if data.lurker
            fa 'user'
          name
          if data.last_action
            span className: 'MessageList-item-last-action', onMouseEnter: @mouseEnter, onMouseLeave: @mouseLeave,
              fa "mail-#{actionToIconMap(data.last_action)}"
              Tooltip({ type: 'text', text: utils.formatDateShort(data.last_action_at) }) if @state.lastActionHover
        div className: 'TableBody-column MessagesList-item-subject u-1of3', role: 'gridcell',
          if data.attachments
            span null,
              fa 'paperclip'
          subject
        div className: 'TableBody-column MessagesList-item-dateTime u-1of3', role: 'gridcell',
          utils.formatDateShort(data.date)
          " "
          @state.zoneAbbr

module.exports = Sent = Exim.createView module.id,
  mixins: [Messages]
  type: type

  componentWillMount: ->
    appStore.actions.updateHeader({name: 'Sent'})
    messagesStore.actions.displayTZ()

  componentDidMount: ->
    appStore.actions.setTitle('Sent Messages')
    document.querySelector('#contentSearchInput')?.focus()
    document.addEventListener('keyup', keyboardScrollTable)

  componentWillUnmount: ->
    appStore.actions.setTitle()
    document.removeEventListener('keyup', keyboardScrollTable)

  render: ->
    MessagesList
      className: 'sent'
      messages: @getMessages()
      loading: @isFetching() and not @state.sentMessagesFetched
      View: SentMessage
      fields: ['to', 'subject', 'sent']
      pageControls: @pageControls
      page: @state[@type].page
      pages: @state[@type].pages
      type: @type
      sort: @props.sort
      onSortChange: @props.onSortChange
      onLoad: @props.onLoad
      onUnload: @props.onUnload
      onSearch: @props.onSearch
      clearSearch: @props.clearSearch
      matcher: @props.matcher
      applyFilter: @applyFilter
      filtersList: ['flagged', 'attached']
      filter: @state.filter
