"use strict";
const Model = require("./base");
const { ISO_DATE } = require("lib/formats");

class Provider extends Model {
  static fromAPI(raw) {
    let synopsisStore = require("stores/new-synopsis");
    let { site } = synopsisStore; // TODO: synopsisStore.findSite(raw.clinic_id)

    return super.fromAPI({
      id: raw.id || raw.user,
      site,
      name: raw.name,
      shot: raw.shot,
      type: raw.type,
      apptTypes: (raw.uses || []).map(
        (id) => site.findApptType(id) || synopsisStore.findApptType(id)
      ),
      elationId: raw.elation_id,
      gone: raw.gone,
      days: raw.days || [],
      show: raw.show,
      globalId: raw.global_id,
      video_conference_link: raw.video_conference_link,
      pcp_eligible: raw.pcp_eligible,
    });
  }

  constructor(params) {
    super({
      apptTypes: [],
      ...params,
    });
  }

  toJSON() {
    return {
      user: this.id,
      name: this.name,
      shot: this.shot,
      type: this.type,
      uses: this.apptTypes.map((t) => !!t && t.id),
      elation_id: this.elationId,
      gone: this.gone,
      days: this.days,
      show: this.show,
      global_id: this.globalId,
      video_conference_link: this.video_conference_link,
      pcp_eligible: this.pcp_eligible,
    };
  }

  get isAny() {
    return this.id == "__any__";
  }
}

module.exports = Provider;
