Chart  = require 'components/elements/Chart'
{Link} = Exim.Router
{cx}   = Exim.helpers
utils = require 'lib/utils'
{override_metrics, nonUS} = require 'config'
{authStore} = require 'stores'
newAuthStore = require 'stores/new-auth'

{div, h4, h3, img, span, spinner, tch} = Exim.DOM
Metric = Exim.createView module.id,
  listen: ['auth/user']

  getDefaultProps: ->
    className: ''
    chart: false
    size: 'small'

  onClick: ->
    @props.onClick? @props.data
    focus = () ->
      document.querySelector('.chart--large')?.focus()
      document.querySelector('.Content-subheaderText')?.scrollIntoView(true)

    setTimeout(focus, 500)

  getImageURL: (id, url) ->
    if id in [51,52,53,54,57] then 'combo/images/icons/trends/icn-exercise-per-week.svg' else "combo/images/icons/trends/#{url}"

  render: ->
    {jail, proxying, admin, traveler} = @state.user
    {isPortalReadOnlyForMember} = newAuthStore

    schedulingAllowed = if admin or proxying then !(jail & 4) else !traveler

    {data, size, chartSize, xTicks, xTickFormat, animationDisabled, site, chart, chartOnly} = @props
    useMetricValues = site in nonUS

    if data.id is 9 and (not useMetricValues) and (not data.override)
      data.value = utils.feetAndInches(data.value)

    if data.id is 99
      h = parseInt(data.value) / 60
      data.value = (Math.floor(h) + Math.round(60 * (h - Math.floor(h)) / 30) / 2)

    if override_metrics[data.id] and not data.override
      unless (data.id is 9 and useMetricValues)
        for key, value of override_metrics[data.id]
          data[key] = value unless typeof value is 'undefined'
        data.override = true

    tch tagName: @props.tagName, className: "Metric #{@props.className} Metric--#{@props.size}", handler: utils.a11yClick(@onClick),
      div className: 'Metric-label',
        unless chartOnly
          [
            if @props.icon
              div key: 'metric-icon', className: 'Metric-icon',
                img alt: '', role: 'presentation', src: @getImageURL(data.id, @props.icon)
            h3 key: 'metric-name', className: 'Metric-name', dangerouslySetInnerHTML: {__html: data.name}
            div role: "presentation", key: 'metric-value', className: "Metric-value #{data.cls or ''}",
              if /^\d+\.\d{2,}$/.test data.value
                Math.round(data.value * 100) / 100
              else
                data.value
              if data.value isnt undefined
                span className: 'Metric-unit', ' ' + data.units.replace /\//g, ' / ' if data.units
          ]

      if chart or chartOnly
        div className: 'Metric-graph',
          if @props.state
            div className: 'app-spinner-outer',
              spinner()
          else if data.values?.length > 1 or (size isnt 'small' and data.values.length)
            Chart
              label: data.name
              data: data
              size: chartSize or size
              xTicks: xTicks
              xTickFormat: xTickFormat
              animationDisabled: animationDisabled
          else if schedulingAllowed and !isPortalReadOnlyForMember
            div className: 'Metric-empty',
              if size is 'large'
                Link to:'appointments/new', className: 'Button Button--primary',
                  'Schedule an Appointment'

module.exports = Metric
