dataURItoBlob = (dataURI) ->
  byteString = if (dataURI.split(',')[0].indexOf('base64') >= 0)
    atob(dataURI.split(',')[1])
  else
    unescape(dataURI.split(',')[1])

  mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  ia = new Uint8Array(byteString.length)
  ia[i] = byteString.charCodeAt(i) for i in [0...byteString.length]

  new Blob [ia], {type:mimeString}

module.exports = (imageFile) ->
  compressionRate = if arguments.length > 1 and arguments[1] isnt undefined
    arguments[1]
  else
    0.5

  new Promise((resolve) ->
    reader = new FileReader()

    reader.onload = ->
      fullImageBase64 = reader.result # image loaded from file system
      img = new Image()
      img.onload = ->
        # shrink image
        canvas = document.createElement('canvas')
        ctx = canvas.getContext('2d')
        ctx.canvas.width = img.width
        ctx.canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        shrunkBase64 = canvas.toDataURL(imageFile.type, compressionRate) # Actual compression step
        blob = dataURItoBlob(shrunkBase64)
        compressedFile = new Blob([blob], {type: imageFile.type})
        resolve compressedFile
      img.src = fullImageBase64

    reader.readAsDataURL(imageFile)
  )
