ReactDOM = require 'react-dom'
{div, fa} = Exim.DOM

Rating = Exim.createView module.id,
  getDefaultProps: ->
    className: ''
    total: 5

  render: ->
    {value, total} = @props
    filled = Math.floor(value)
    half = value - filled
    empty = total - value

    filledStar =
      div className: 'Rating-star',
        fa 'star'
    halfStar =
      div className: 'Rating-star',
        fa 'star-half-o'
    emptyStar =
      div className: 'Rating-star',
        fa 'star-o'

    div className: "Rating #{@props.className}",
      filledStar for [1..filled] if filled
      halfStar if half
      emptyStar for [1..empty] if empty

module.exports = Rating
