"use strict";
const { observable } = require("mobx");
const User = require("models/user");
const { computed } = require("lib/utils");
const oldStore = require("stores/auth");
const _ = require("lodash");
const patientStore = require("stores/patient");
const request = require("lib/new-request");

const LOADING = "loading";
const COMPLETE = "complete";
const FAILED = "failed";

const authStore = observable({
  user: new User(),
  me: new User(),
  userActiveEpisodes: null,
  getEpisodesStatus: "",
  featureFlags: [],
  getFeatureFlagsStatus: "",
  getProxiedPatientFeatureFlagsStatus: "",
  proxiedPatientFeatureFlags: [],

  getActiveEpisodes: async () => {
    if (authStore.fetchingEpisodes) {
      return;
    }

    authStore.getEpisodesStatus = LOADING;

    try {
      const response = await request.get(
        `/v1/members/${authStore.user.id}/active-episodes-for-portal`
      );
      authStore.userActiveEpisodes = response.results;
      authStore.getEpisodesStatus = COMPLETE;
    } catch {
      authStore.getEpisodesStatus = FAILED;
    }
  },

  fetchFeatureFlags: async () => {
    if (authStore.fetchingFeatureFlags) {
      return;
    }

    authStore.getFeatureFlagsStatus = LOADING;

    try {
      const response = await request.get(`v1/feature_flags`);
      authStore.featureFlags = response.results;
      authStore.getFeatureFlagsStatus = COMPLETE;
    } catch {
      authStore.getFeatureFlagsStatus = FAILED;
    }
  },

  fetchProxiedPatientFeatureFlags: async (userId) => {
    if (authStore.fetchingProxiedPatientFeatureFlags) {
      return;
    }

    authStore.getProxiedPatientFeatureFlagsStatus = LOADING;

    try {
      const response = await request.get(`v1/feature_flags/user/${userId}`);
      const results =
        typeof response === "string"
          ? JSON.parse(response).results
          : response.results;
      authStore.proxiedPatientFeatureFlags = results;
      authStore.getProxiedPatientFeatureFlagsStatus = COMPLETE;
    } catch {
      authStore.getProxiedPatientFeatureFlagsStatus = FAILED;
    }
  },

  clearProxiedPatientFeatureFlags: () => {
    authStore.proxiedPatientFeatureFlags = [];
  },
});

computed(authStore, {
  get isProxying() {
    return !this.user.is(this.me);
  },

  get activeEpisodes() {
    return this.userActiveEpisodes || [];
  },

  get fetchingEpisodes() {
    return this.getEpisodesStatus === LOADING;
  },

  get fetchingFeatureFlags() {
    return this.getFeatureFlagsStatus === LOADING;
  },

  get fetchingProxiedPatientFeatureFlags() {
    return this.getProxiedPatientFeatureFlagsStatus === LOADING;
  },

  get proxiedPatientFeatureFlagsLoaded() {
    return (
      this.getProxiedPatientFeatureFlagsStatus &&
      this.getProxiedPatientFeatureFlagsStatus !== LOADING
    );
  },

  get featureFlagsLoaded() {
    return this.getFeatureFlagsStatus && this.getFeatureFlagsStatus !== LOADING;
  },

  get hasSOGI() {
    if (this.isProxying) {
      return this.proxiedPatientFeatureFlags.includes("sogi");
    } else {
      return this.featureFlags.includes("sogi");
    }
  },
  
  get hasRaceAndEthnicity() {
    if (this.isProxying) {
      return this.proxiedPatientFeatureFlags.includes("race_and_ethnicity");
    } else {
      return this.featureFlags.includes("race_and_ethnicity");
    }
  },

  get hasPortalWellnessTile() {
    return this.featureFlags.includes("portal_wellness_tile");
  },

  get isPortalReadOnlyForMember() {
    return !this.isProxying && !this.user.admin && this.isPortalReadOnly;
  },

  get isPortalReadOnly() {
    if (this.isProxying) {
      return this.proxiedPatientFeatureFlags.includes("migrated_to_xop");
    } else {
      return this.featureFlags.includes("migrated_to_xop");
    }
  },

  get hasRestrictToVirtualOption() {
    return this.featureFlags.includes("self_schedule_virtual");
  },

  get isViewingAsMigratedUser() {
    if (this.isProxying) {
      // checks results `v1/feature_flags/user/${userId}`endpoint
      return this.proxiedPatientFeatureFlags.includes("migrated_to_xop");
    } else {
      // checks results from `v1/feature_flags` endpoint
      return this.featureFlags.includes("migrated_to_xop");
    }
  },

  get hasUserRefresh() {
    if (this.isProxying) {
      return this.proxiedPatientFeatureFlags.includes("user_refresh");
    } else {
      return this.featureFlags.includes("user_refresh");
    }
  },

  get shouldSeeFullAccessPortal() {
    return !this.isViewingAsMigratedUser;
  },

  get isMicrosoftPatient() {
    return this.user.isMicrosoftPatient;
  },
});

oldStore.onChange(async () => {
  const rawUser = oldStore.get("user");
  if (!rawUser.id) {
    if (authStore.user.isCreated) authStore.user = new User();
    if (authStore.me.isCreated) authStore.me = new User();
    return;
  }

  const patient = patientStore.get("patient");
  const user = User.fromAPI({
    ...rawUser,
    migrated_to_xop: _.get(patient, "migrated_to_xop"),
  });
  authStore.user = user;

  const me = rawUser.staff ? User.fromAPI(rawUser.staff) : authStore.user;
  if (me.id == authStore.me.id) return;

  authStore.me = me;
  await me.fetch();
});

module.exports = authStore;
