appStore     = require 'stores/app'
patientStore = require 'stores/patient'
authStore    = require 'stores/auth'

Img          = require 'components/elements/Img'
Spinner      = require 'components/elements/Spinner'
Footer       = require 'components/app/Footer'
attachHeader = require 'components/mixins/attachHeader'
{cx} = Exim.helpers

utils = require 'lib/utils'
config = require 'config'
{main, div, spinner, h2, h3, label, select, option, span, p, a, input, textarea, tch, strong, form, fa} = Exim.DOM
{iOS} = config

# Fix for IE 11+ and Safari
require 'blueimp-canvas-to-blob'
# Fix for iOS
loadImage = require 'blueimp-load-image'

Edit = Exim.createView module.id,
  mixins: [
    appStore.connect('config')
    patientStore.connect('patient', 'patientUpdating')
    authStore.connect('user', 'avatarUploading')
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    filename: ''

  componentWillMount: ->
    appStore.actions.setHeader(name: 'Your Profile Photo')

  stopPropagation: (e) ->
    e.stopPropagation()

  selectFile: (evt) ->
    file = evt.currentTarget.files[0]
    if iOS
      loadImage.parseMetaData file, (data) =>
        orientation = if data.exif
          orientation = data.exif.get 'Orientation'
        else
          0

        loadImage file, (canvas) =>
            base64 = canvas.toDataURL(file.type)
            blob = utils.dataURItoBlob(base64)
            @setState filename: file.name, file: blob
          ,
            canvas: true,
            orientation: orientation
    else
      @setState filename: file.name, file: file
    utils.screenReaderSpeak("File #{file.name} has been selected for upload.")


  uploadFile: (evt) ->
    {file, filename} = @state
    if file
      authStore.actions.uploadAvatar({file, filename}).then (res) =>
        @setState uploadedFile: authStore.get('user').avatar_url
        @context.router.push '/profile/edit/photo/crop'
      , (err) =>
        @setState error: true

  clickInput: (e) ->
    e.stopPropagation()
    @refs.fileinput?.click()

  uploadError: ->
    @setState error: true, file: null, uploadedFile: null, filename: null

  tryAgain: ->
    @setState error: false, file: null, uploadedFile: null, filename: null

  render: ->
    {patient, user, error, uploadedFile, avatarUploading} = @state

    main id: 'mainContent', className: 'Content ProfileEdit ProfilePhoto',
      div className: 'Content-body Content-body--article',
        Spinner className: 'u-flexChild', state: false,#!patient.id,
          div className: "ContentInner",
            div className: 'MessageActions',
              a href: '/profile/edit', className: 'Button Button--back Button--pointer MessageActions-backButton', style: {marginTop: '12px'},
                'Back to Edit Profile'

            div className: 'ContentBlock',
              h3 ' Upload a photo '

              unless error
                Spinner state: avatarUploading,
                  div className: 'ProfileEdit-row',
                    input id: 'avatar-file', type: 'file', accept: 'image/*', className: 'Input--fileHidden', 'aria-hidden': true, onClick: @stopPropagation, onChange: @selectFile, ref: 'fileinput', tabIndex: -1
                    div className: 'u-flex u-flexRow u-4of5',
                      tch role: 'button', className: 'Button Button--withMargin Button--pointer Button--secondary', id: 'choose-file-button', handler: @clickInput, onEnter: @clickInput, ref: ((e) -> e?.focus()), tabIndex: 0,
                        "Choose file"
                      span className: 'u-flex u-flexAlignCenter u-pad-left',
                        @state.filename
                  div className: 'ProfileEdit-row',
                    div className: 'u-flex u-flexRow u-4of5',
                      tch className: "Button Button--withMargin Button--pointer Button--#{cx disabled: !@state.file}", handler: @uploadFile, onEnter: @uploadFile, tabIndex: "#{if @state.file then 0 else -1}", role: 'button', 'aria-label': "#{if !@state.file then 'Upload button disabled - please choose file' else 'Upload file'}",
                        "Upload"
              else
                div null,
                  div className: 'ProfileEdit-row',
                    div className: 'u-flex u-flexRow u-4of5',
                      div className: 'ProfilePhoto-warning',
                        fa 'exclamation-triangle'
                        "Upload failed"
                  div className: 'ProfileEdit-row',
                    div className: 'u-flex u-flexRow u-4of5',
                      tch className: "Button Button--withMargin Button--plainText", handler: @tryAgain, onEnter: @tryAgain, tabIndex: 0,
                        "Try again"

        Footer()

module.exports = Edit
