import React from "react";
import moment from "moment";
import { client } from "config";
import { observer } from "mobx-react";
import authStore from "stores/new-auth";
import synopsisStore from "stores/new-synopsis";
import AWCHolidayBanner from "./awc-holiday-banner";
import CookiesNotice from "./cookies-notice";
import AMPPlatformBanner from "./amp-banner";
import MemberTransitionBanner from "./MemberTransitionBanner";

const AMP_BANNER_HEIGHT = 50;
const COOKIES_NOTICE_BANNER_HEIGHT = 50;
const AWC_HOLIDAY_BANNER_HEIGHT = 65;
const TRANSITION_BANNER_HEIGHT = 50;

class AppBanners extends React.Component {
  componentDidMount() {
    if (!this.props.onBannerHeightChange) return;
    this.props.onBannerHeightChange(this.calculateBannerHeight());
    this.saveCurrentBannerInfo();
  }

  componentDidUpdate() {
    if (!this.props.onBannerHeightChange || !this.shouldUpdateBannerHeight())
      return;
    this.props.onBannerHeightChange(this.calculateBannerHeight());
    this.saveCurrentBannerInfo();
  }

  shouldUpdateBannerHeight() {
    const { me } = authStore;
    const { hasAMATHRA, cookiesAcknowledged, showMemberTransitionBanner } =
      this.initialData;
    return (
      hasAMATHRA !== synopsisStore.amat_hra ||
      cookiesAcknowledged !== me.hasAcknowledgedCookies ||
      showMemberTransitionBanner !== !!synopsisStore.transitionBannerInfo
    );
  }

  saveCurrentBannerInfo() {
    const { me } = authStore;
    this.initialData = {
      hasAMATHRA: synopsisStore.amat_hra,
      cookiesAcknowledged: me.hasAcknowledgedCookies,
      showMemberTransitionBanner: !!synopsisStore.transitionBannerInfo,
    };
  }

  calculateBannerHeight() {
    let bannerHeight = 0;

    if (this.shouldShowCookiesNoticeBanner()) {
      bannerHeight += COOKIES_NOTICE_BANNER_HEIGHT;
    }

    if (this.shouldShowAWCHolidayBanner()) {
      bannerHeight += AWC_HOLIDAY_BANNER_HEIGHT;
    }

    if (this.shouldShowAMPBanner()) {
      bannerHeight += AMP_BANNER_HEIGHT;
    }

    if (this.shouldShowTransitionBanner()) {
      bannerHeight += TRANSITION_BANNER_HEIGHT;
    }

    return bannerHeight;
  }

  shouldShowCookiesNoticeBanner() {
    const { me } = authStore;
    return !me.isAdmin && !me.hasAcknowledgedCookies;
  }

  shouldShowAWCHolidayBanner() {
    const endDate = moment("11/26/18", "MM-DD-YYYY");
    const { me } = authStore;
    return (
      moment() < endDate &&
      !me.isAdmin &&
      (client === "apple" || client === "docker")
    );
  }

  shouldShowAMPBanner() {
    const { me } = authStore;
    return !me.isAdmin && synopsisStore.amat_hra;
  }

  shouldShowTransitionBanner() {
    return !!synopsisStore.transitionBannerInfo;
  }

  render() {
    return (
      <div className="banner-container">
        {this.shouldShowTransitionBanner() && <MemberTransitionBanner />}
        {this.shouldShowCookiesNoticeBanner() && <CookiesNotice />}
        {this.shouldShowAWCHolidayBanner() && <AWCHolidayBanner />}
        {this.shouldShowAMPBanner() && <AMPPlatformBanner />}
      </div>
    );
  }
}

export default observer(AppBanners);
