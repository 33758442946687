export { default as WelcomeStep } from "./welcome";
export { default as AboutStep } from "./about";
export { default as ReasonStep } from "./reason";
export { default as MedsStep } from "./Meds";
export { default as DentalStep } from "./Dental";
export { default as ConditionsStep } from "./Conditions";
export { default as LifestyleStep } from "./Lifestyle";
export { default as ScreeningsStep } from "./screenings";
export { default as ConsentsStep } from "./consents";
export { default as CrossoverStep } from "./crossover";
export { default as ThanksStep } from "./thanks";
