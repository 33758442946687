const boolAttr = (bool) => (bool ? "" : null);

const withDirty = (Component, { type }) => {
  class DirtyAttr extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isDirty: false };
    }

    render() {
      let props = {
        ...this.props,
        "data-dirty": boolAttr(this.state.isDirty),
        [type]: (evt) => {
          this.setState({ isDirty: true });
          this.props[type](evt);
        },
      };

      return <Component {...props} />;
    }
  }

  DirtyAttr.defaultProps = {
    [type]: () => {},
  };

  return DirtyAttr;
};

export default withDirty;
