"use strict";
const Slot = require("models/slot");
const calStore = require("stores/cal");
const authStore = require("stores/new-auth");
const slotsStore = require("stores/new-slots");
const { removeDragImage } = require("lib/utils");

class SlotCreator extends React.Component {
  creatingSlotStart(evt) {
    evt = evt.nativeEvent;

    let { user } = authStore;
    if (!user.canEditCalendar) {
      evt.preventDefault();
      alert("Write access is required to create a slot.");
      return;
    }

    let { provider, date } = this.props;
    if (provider.site.today > date) {
      evt.preventDefault();
      alert("Unable to create a slot in the past.");
      return;
    }

    removeDragImage(evt.dataTransfer);
    evt.dataTransfer.effectAllowed = "move";

    requestAnimationFrame(() => {
      let slot = new Slot({ provider, date });

      slotsStore.addSlot(slot);
      calStore.creating = {
        startY: evt.offsetY,
        slot,
      };
    });
  }

  creatingSlotEnd(evt) {
    let { slot } = calStore.creating;
    calStore.creating = null;

    let isCanceled = evt.dataTransfer.dropEffect == "none";
    if (isCanceled) {
      slotsStore.deleteSlot(slot);
      return;
    }

    slot.post().catch((err) => {
      slotsStore.deleteSlot(slot);
      console.error(err);
      alert("Failed to create a slot.");
    });
  }

  render() {
    return (
      <div
        className="ScheduleCalendarDay-SlotCreator"
        draggable
        onDragStart={(evt) => this.creatingSlotStart(evt)}
        onDragEnd={(evt) => this.creatingSlotEnd(evt)}
      />
    );
  }
}

module.exports = SlotCreator;
