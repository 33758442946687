import authStore from "stores/new-auth";
import { observer } from "mobx-react";
import AppointmentRow from "./appointment-row";
import { without, isEqualWith } from "lodash";
import { withSort } from "components/elements";

class AppointmentsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };

    this.collapseAll = () => {
      this.setState({
        expanded: [],
      });
    };

    this.expandAll = () => {
      this.setState({
        expanded: this.props.appointments.map((appt) => appt.id),
      });
    };
  }

  toggleExpand(id) {
    let { expanded } = this.state;
    if (expanded.includes(id)) {
      this.setState({
        expanded: without(expanded, id),
      });
    } else {
      this.setState({
        expanded: [...expanded, id],
      });
    }
  }

  renderActions() {
    let { me } = authStore;
    if (!me.isAdmin) return;

    let { expanded } = this.state;
    let isAllCollapsed = !expanded.length;
    let isAllExpanded = isEqualWith(
      expanded,
      this.props.appointments,
      (appt) => appt.id
    );

    return (
      <div className="AppointmentsTable-actions">
        {!isAllCollapsed && (
          <button
            className="AppointmentsTable-button"
            onClick={this.collapseAll}
          >
            Collapse All
          </button>
        )}
        {!isAllExpanded && (
          <button className="AppointmentsTable-button" onClick={this.expandAll}>
            Expand All
          </button>
        )}
      </div>
    );
  }

  renderHeader() {
    const { Sorter } = this.props;

    return (
      <tr>
        <Sorter className="AppointmentRow-type" by="typeName">
          Appointment
        </Sorter>
        <Sorter className="AppointmentRow-provider" by="provider.name">
          Provider
        </Sorter>
        <Sorter className="AppointmentRow-site" by="site.name">
          Location
        </Sorter>
        <Sorter className="AppointmentRow-status" by="status">
          Status
        </Sorter>
        <Sorter className="AppointmentRow-startAt" by="startAt">
          <strong>Visit Date</strong>
        </Sorter>
        <th className="AppointmentRow-actions">Actions</th>
      </tr>
    );
  }

  renderRows() {
    let { appointments } = this.props;
    if (!appointments.length) {
      return (
        <tr className="NewTable-noItems">
          <td colSpan={6}>No appointments.</td>
        </tr>
      );
    }

    let { expanded } = this.state;
    return appointments.map((appt) => {
      return (
        <AppointmentRow
          key={appt.id}
          appointment={appt}
          toggleExpand={() => this.toggleExpand(appt.id)}
          isExpanded={expanded.includes(appt.id)}
        />
      );
    });
  }

  render() {
    let { title } = this.props;

    return (
      <section className="AppointmentsTable-wrapper">
        <header className="AppointmentsTable-header">
          <h3 className="AppointmentsTable-title">{title}</h3>
          {this.renderActions()}
        </header>
        <table className="AppointmentsTable-table NewTable" aria-label={title}>
          <thead>{this.renderHeader()}</thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </section>
    );
  }
}

AppointmentsTable.defaultProps = {
  appointments: [],
};

export default withSort(observer(AppointmentsTable), {
  data: "appointments",
  by: "startAt",
});
