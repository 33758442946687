"use strict";
const synopsisStore = require("stores/new-synopsis");
const Model = require("./base");
const Appointment = require("./appointment");
const { min, max } = require("lodash");
const { onlyDate, computed, MomentRange, dateTime } = require("lib/utils");
const { ISO_DATE } = require("lib/formats");
const { shortWeekdays } = require("config");

class SlotSeries extends Model {
  static fromAPI(raw) {
    if (!raw) return null;

    let site = synopsisStore.findSite(raw.clinic_id);
    let { zone } = site;

    return new this({
      id: raw.id,
      site,
      startDay: onlyDate(moment.tz(raw.start_at, zone)),
      endDay: onlyDate(moment.tz(raw.end_at, zone)),
      daysActive: raw.days_active.map((day) => shortWeekdays.indexOf(day)),
    });
  }

  static fromSlot(slot) {
    let { site } = slot;
    let series = new this({
      site,
      slot,
      startDay: slot.date,
      daysActive: site.workingDays.filter((day) => {
        let { start, end } = site.workingRanges.get(day);
        return slot.startTime >= start && slot.endTime <= end;
      }),
    });

    series.slotsNumber = 2;
    return series;
  }

  constructor(params) {
    super({
      site: null,
      startDay: null,
      endDay: null,
      daysActive: [],
      ...params,
    });
  }

  updateFromAPI(raw) {
    // TODO
    if (raw.summary) return;

    this.destroy();
    if (raw.id) {
      super.updateFromAPI(raw);
    }

    let Slot = require("./slot");
    let slots = raw.slots.map((rawSlot) => {
      if (raw.id) {
        rawSlot.slot_series = this.toJSON();
      }

      return Slot.fromAPI(rawSlot);
    });

    let removedSlots = (raw.removed_slots || []).map((rawSlot) => {
      return Slot.fromAPI(rawSlot);
    });

    let slotsStore = require("stores/new-slots");
    slotsStore.addSlots([...slots, ...removedSlots]);
  }

  // TODO: rename this
  async createFromSlot({ dryRun, slot }) {
    return super
      .post({
        path: `/v1/slots/${slot.id}/series`,
        query: {
          dry_run: !!dryRun,
        },
        data: this.toJSON(),
      })
      .then(normEnvelope);
  }

  async update({ dryRun, conflictResolution }) {
    return super
      .put({
        query: {
          dry_run: !!dryRun,
        },
        data: {
          ...this.toJSON(),
          conflict_resolution: conflictResolution,
        },
      })
      .then(normEnvelope);
  }

  async updateFuture({ date, ...params }) {
    this.startDay = date.clone();
    return this.update(params);
  }

  async delete({ dryRun, conflictResolution }) {
    return super
      .delete({
        query: {
          dry_run: !!dryRun,
        },
        data: {
          conflict_resolution: conflictResolution,
        },
      })
      .then(normEnvelope);
  }

  async deleteFuture({ date, ...params }) {
    let isFirst = this.startDay.isSame(date);
    if (isFirst) return this.delete(params);

    this.endDay = date.clone().subtract(1, "day");
    return this.update(params);
  }

  toJSON() {
    return {
      ...super.toJSON(),
      clinic_id: this.site.id,
      start_at: dateTime(this.startDay, this.slot.startTime), //
      end_at: dateTime(this.endDay, this.slot.endTime), //
      series_start: this.startDay.format(ISO_DATE),
      series_end: this.endDay.format(ISO_DATE),
      days_active: this.daysActive.map((day) => shortWeekdays[day]),
      ...this.slot.sharedJSON,
    };
  }

  destroy() {
    let slotsStore = require("stores/new-slots");
    slotsStore.deleteSeries(this);
  }

  isActiveDay(day) {
    return this.daysActive.includes(day);
  }

  isDisabledDay(day) {
    let isLastActive = this.isActiveDay(day) && this.daysActive.length == 1;
    if (isLastActive) return true;

    let range = this.site.workingRanges.get(day);
    if (!range) return true;

    let { startTime, endTime } = this.slot;
    return startTime < range.start || endTime > range.end;
  }

  toggleRepeatDay(day) {
    // TODO: replace `daysActive` with `Set`
    let index = this.daysActive.indexOf(day);
    if (index == -1) {
      this.daysActive.push(day);
    } else {
      this.daysActive.splice(index, 1);
    }
  }

  _calcEndDay(days) {
    let endDay = this.startDay.clone();

    while (days != 1) {
      endDay.add(1, "day");
      if (this.isActiveDay(endDay.day())) {
        days--;
      }
    }

    return endDay;
  }
}

SlotSeries.endPoint = "/v1/slot-series";
SlotSeries.prototype.minSlotsNumber = 2;
SlotSeries.prototype.maxSlotsNumber = 255;

computed(SlotSeries.prototype, {
  get slotsNumber() {
    let range = new MomentRange(this.startDay, this.endDay);
    let days = range.by(1, "day").map((date) => date.day());

    return days.filter((day) => this.isActiveDay(day)).length;
  },

  set slotsNumber(days) {
    this.endDay = this._calcEndDay(days);
  },

  get minEndDay() {
    return this._calcEndDay(this.minSlotsNumber);
  },

  get maxEndDay() {
    return this._calcEndDay(this.maxSlotsNumber);
  },

  get minStartTime() {
    return max(
      this.daysActive.map((day) => {
        return this.site.workingRanges.get(day).start;
      })
    );
  },

  get maxEndTime() {
    return min(
      this.daysActive.map((day) => {
        return this.site.workingRanges.get(day).end;
      })
    );
  },
});

const normEnvelope = (body) => {
  if (!body) return body;

  return {
    ...body,
    summary: body.summary ? normSummary(body.summary) : null,
  };
};

const normSummary = (raw) => ({
  createdCount: raw.total_new_slots,
  deletedCount: raw.total_removed_slots,
  overlappedCount: raw.total_overlapping_slots,
  conflictedAppointments: raw.conflicting_appointments.map((appt) =>
    Appointment.fromAPI(appt)
  ),
});

module.exports = SlotSeries;
