ReactDOM = require 'react-dom'
utils = require 'lib/utils'
cache = require 'lib/cache'
config = require 'config'
{div, spinner, fa} = Exim.DOM
{cx} = Exim.helpers

WAITING_DELAY = 300
DIFF_TO_ACTION = 15
DEFAULT_RESISTANCE = 1

BLUR_DELAY = 300
SPINNER_DELAY = 1000

BASE_MARGIN_MOBILE = 60
BASE_MARGIN_DESKTOP = 73
REFRESH_DELAY = 300

PullToRefresh = Exim.createView module.id,
  propTypes:
    disabled: React.PropTypes.bool
    onRefresh: React.PropTypes.func
    className: React.PropTypes.string
    id: React.PropTypes.string
    style: React.PropTypes.object
    tag: React.PropTypes.func
    rangeToRefresh: React.PropTypes.number
    resistance: React.PropTypes.number
    isLoading: React.PropTypes.bool

  getInitialState: ->
    mobile = document.body.clientWidth < 767
    marg = if mobile then BASE_MARGIN_MOBILE else BASE_MARGIN_DESKTOP
    marginTop: marg
    preventTouch: false
    waiting: true
    component: null
    isMounted: false

  getDefaultProps: ->
    disabled: false
    isLoading: false
    className: ''
    style: {}
    tag: Exim.DOM.div
    rangeToRefresh: 50
    resistance: 2.5
    delay: BLUR_DELAY
    endDelay: SPINNER_DELAY

  componentDidMount: ->
    @startSpinnerTimer()
    @startWaitingTimer()
    @handleResize()
    window.addEventListener('resize', @handleResize)
    @setState isMounted: true

  handleResize: (evt) ->
    clearTimeout(@rtm)
    @rtm = =>
      return unless @state.isMounted
      loading = @state.waiting and @props.isLoading
      marg = if loading and config.touchDevice then 27 else 0
      @setState marginTop: @getMarginTop(marg)
    setTimeout @rtm, REFRESH_DELAY
    if evt && width = evt.target?.window?.document?.width
      @setState mobile: width < 767
    else
      @setState mobile: document.body.clientWidth < 767

  componentWillUpdate: (nextProps, nextState) ->
    baseMargin = @getMarginTop()

    if !@props.isLoading and nextProps.isLoading
      @startSpinnerTimer()

    unless nextProps.ignorePreventing
      if nextState.marginTop and not @state.marginTop and not cache.preventY
        cache.preventX = true
      else if not nextState.marginTop and @state.marginTop
        cache.preventX = false
    if nextState.marginTop > baseMargin and ((@props.isLoading and !nextProps.isLoading))
      @clearScroll()
    if @props.isLoading and !nextProps.isLoading
      nextState.marginTop = @getMarginTop(0)
      @clearTimeouts()

  componentWillUnmount: ->
    cache.preventX = false
    window.removeEventListener('resize', @handleResize)

  onTouchStart: (evt) ->
    return if cache.preventY or @props.static
    {scrollTop} = ReactDOM.findDOMNode(@)
    if scrollTop is 0 and !@props.isLoading
      [touch] = evt.touches
      @start = touch.clientY

  onTouchMove: (evt) ->
    return if cache.preventY or @props.static
    {resistance} = @props
    [touch] = evt.touches
    @diff = (touch.clientY - @start) / (resistance || DEFAULT_RESISTANCE)
    @clearVars() if @diff < 0
    return unless @start
    if @diff > DIFF_TO_ACTION or @diff < -DIFF_TO_ACTION
      {scrollTop} = ReactDOM.findDOMNode(@)
      max = Math.abs(scrollTop) - @diff
      if scrollTop <= 0
        @setState marginTop: @getMarginTop(@diff + 27)

  onTouchEnd: (evt) ->
    if @state.preventScroll
      @setState preventScroll: false
    cache.preventX = false
    return @clear() unless @start
    @first = false
    {rangeToRefresh} = @props
    if @diff > rangeToRefresh
      @refresh(evt)
    else
      @clear()

  onScroll: (evt) ->
    return if @props.static
    if cache.preventY and !@state.preventScroll
      @setState preventScroll: true

  clear: ->
    @clearScroll()
    @clearVars()

  onTouchCancel: ->
    cache.preventX = false

  clearVars: ->
    [@start, @diff, @first] = []

  clearScroll: ->
    loading = @state.waiting and @props.isLoading
    marg = if loading then 27 else 0
    baseMargin = @getMarginTop(marg)

    state = {}
    state.marginTop = baseMargin if @state.marginTop > baseMargin
    state.preventTouch = false if @state.preventTouch
    @setState state if Object.keys(state).length > 0

  refresh: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()
    @props.onRefresh?()
    @setState marginTop: @getMarginTop(27), preventTouch: true, waiting: true
    @startSpinnerTimer()
    @startWaitingTimer()

  startSpinnerTimer: ->
    blurFn = =>
      if @state.isMounted
        @setState isBlur: true
    spinnerFn = =>
      if @state.isMounted
        @setState isSpinner: true

    @tmBlur = setTimeout blurFn, BLUR_DELAY
    @tmSpinner = setTimeout spinnerFn, SPINNER_DELAY

  clearTimeouts: ->
    clearTimeout(@tmBlur)
    clearTimeout(@tmSpinner)
    @setState isBlur: false, isSpinner: false

  startWaitingTimer: ->
    @tm = setTimeout @clearWaitingTimer, WAITING_DELAY

  clearWaitingTimer: ->
    return unless @state.isMounted
    clearTimeout(@tm)
    @setState waiting: false
    @clearVars()
    @clearScroll() if !@state.waiting and !@props.isLoading

  getMarginTop: (add=0) ->
    baseMargin = if @state.mobile then BASE_MARGIN_MOBILE else BASE_MARGIN_DESKTOP
    baseMargin + add

  render: ->
    {mobile, marginTop, preventTouch, preventScroll, waiting, isBlur, isSpinner} = @state
    {onRefresh, className, id, tag, rangeToRefresh, isLoading} = @props
    style = if @props.static then {} else utils.clone(@props.style)
    if !@props.static
      baseMargin = @getMarginTop(0)
      loading = isLoading # @init and !waiting and isLoading
      if !config.touchDevice
        style.marginTop = baseMargin
      else if loading and style.marginTop is baseMargin
        style.paddingTop = baseMargin #+ 55
        style.marginTop = 0
      else
        style.paddingTop = marginTop
        style.marginTop = 0
      if cache.preventY
        style.paddingTop = baseMargin
        style.marginTop = 0
      # loading = false if style.marginTop is 0
      upAngle = marginTop > rangeToRefresh
      opacity = marginTop / rangeToRefresh
      noScroll = preventScroll or marginTop > baseMargin

    tag {ref: 'refresh-view', className: "PullToRefresh #{className} #{cx 'static': @props.static, 'is-blur': loading and isBlur, 'no-touch': preventTouch, 'no-scroll': noScroll}", id, style, @onTouchStart, @onTouchMove, @onTouchEnd, @onTouchCancel, @onScroll},
      unless @props.static
        [
          if marginTop > baseMargin and !loading
            div key: 'pull-angle', className: 'angle', style: {opacity},
              fa 'arrow-down', cx 'up': upAngle
          div key: 'pull-spinner', className: 'app-spinner-outer',
          if loading and isSpinner
            spinner()
        ]
      @props.children

module.exports = PullToRefresh
