import { browserHistory } from "react-router";

const TITLES = {
  verify: "Verify your identity",
  survey: "Take the survey",
  results: "Your results",
};

class Step {
  constructor(id) {
    this.id = id;
  }

  get url() {
    return this.id;
  }

  get title() {
    return TITLES[this.id];
  }

  get isActive() {
    const { pathname } = location;
    const path = pathname.substr(pathname.lastIndexOf("/") + 1);
    return path === this.url;
  }

  activate() {
    browserHistory.push(this.url);
  }
}

export default Step;
