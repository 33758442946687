import { features } from "config";
import { browserHistory } from "react-router";
import confirm from "lib/confirm";
import request from "lib/new-request";

const pathname = features.apptsOnly ? "appointments" : "profile";
const proxy = async ({ id }) => {
  const newAuthStore = require("stores/new-auth");
  const { messagesStore, authStore } = require("stores");

  messagesStore.actions.clear();
  await newAuthStore.fetchProxiedPatientFeatureFlags(id);
  await authStore.actions.startImpersonation(id);

  messagesStore.actions.fetchMessages();
  browserHistory.push({
    pathname: "/profile",
    query: {
      patient_id: id,
    },
  });
};

export default async (patient, options = {}) => {
  let { me } = require("stores/new-auth");

  if (options.isForced && me.canAccess(patient)) {
    proxy(patient);
    return;
  }

  if (!me.canAccess(patient)) {
    alert(
      "Access denied. Please consult your medical director if access is clinically required."
    );
    return;
  }

  if (!patient.isActive) {
    let isConfirmed = await confirm("Access this inactive patient?");
    if (!isConfirmed) return;
  }

  if (patient.isAsleep) {
    let isConfirmed = await confirm(
      "Would you like to create a record for this patient?"
    );
    if (!isConfirmed) return;

    try {
      let newPatient = await request.post("/v1/patient", {
        base_id: patient.id,
      });

      proxy(newPatient);
      return;
    } catch (err) {
      console.error(err);
      alert(`Failed to create record for #${patient.id}.`);
    }
  }

  proxy(patient.toJSON());
};
