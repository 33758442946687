"use strict";
const Swiper = require("components/elements/swiper");
const withMediaQueries = require("components/elements/with-media-queries");
const { withRouter } = require("react-router");
const { smDown } = require("lib/media-queries");
const { isCordova } = require("config");

const BackSwiper = ({ isPhone, router, to }) => {
  if (!isPhone) return null;
  if (!isCordova) return null;

  return (
    <Swiper
      modifier="back"
      onSwipeRight={() => {
        router.push(to);
      }}
    />
  );
};

module.exports = withMediaQueries(withRouter(BackSwiper), {
  isPhone: smDown,
});
