{appStore, authStore, metricsStore, screenStore} = require 'stores'
screeningStore = require 'stores/screening'

withIdleTimer = require('components/elements/with-idle-timer')
PatientHeader = require 'components/elements/PatientHeader'
ConfirmationModal = require 'components/elements/ConfirmationModal'
Footer = require 'components/app/Footer'

{steps, firstStep} = require './config'
{capitalize, EximShim} = require('lib/utils')
{observer} = require 'mobx-react'
{cx} = Exim.helpers
{main, section, div, icn, p, h1} = Exim.DOM
FeatureFlag =        EximShim require('components/util/FeatureFlag').FeatureFlag
AsyncPortal =        EximShim require 'components/util/AsyncPortal'
ContentHeaderTitle = EximShim require 'components/elements/ContentHeaderTitle'

links = Object.keys(steps).map (step) -> to: "/checkin/screen/#{step}", title: capitalize(steps[step].title)

Screen = React.createClass({
  displayName: 'Screen',
  mixins: [
    screenStore.connect('screen')
    authStore.connect('user')
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    name: 'Screen'

  componentWillMount: ->
    {router} = @context
    router.push(pathname: "/checkin/screen/#{firstStep}") unless router.isActive('/checkin/screen')
    metricsStore.actions.fetchTypes()
    appStore.actions.setHeader(name: 'Screen', links: links, arrows: @arrows, user: @state.user, noHover: true)

  componentDidMount: ->
    screeningStore.fetchTemplates()

  setName: (name) ->
    @setState {name}

  toStep: (step) -> (evt) =>
    items = Object.keys(steps)
    if step < 0 or step >= items.length
      return
    else
      @context.router.push(pathname: "/checkin/screen/#{items[step]}")

  arrows: ->
    items = Object.keys(steps)
    index = items.indexOf(@state.name)
    div className: 'sub-header-arrows',
      div className: "sub-header-arrows-left #{cx disabled: index is 0}", onClick: @toStep(index-1),
        icn 'chevron-left'
      div className: "sub-header-arrows-right #{cx disabled: index is items.length - 1}", onClick: @toStep(index+1),
        icn 'chevron-right'

  render: ->
    {meta} = @state.screen
    user =
      full_name: "#{meta.about.first_name} #{meta.about.last_name}"
      avatar_url: meta.avatar_url
    # Links to ad-hoc screening templates
    screeningLinks = Object.keys screeningStore.onDemandScreenings
      .map((key) -> name: screeningStore.onDemandScreenings[key].title, path: key )
    main id: 'mainContent', className: "Content #{cx 'Content--withPatientHeader': @state.user.proxying}",
      FeatureFlag flag: 'hasScreeningFramework',
        AsyncPortal selector: '.ContentHeader-group',
          ContentHeaderTitle name: 'Health Screening', links: screeningLinks
      section className: 'Content-body',
        PatientHeader()
        React.cloneElement @props.children, {@setName}
        Footer()
})

module.exports = withIdleTimer(observer(Screen))
