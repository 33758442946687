import { observer } from "mobx-react";
import Step from "components/mixins/Step";

const ReasonStep = React.createClass({
  mixins: [Step],

  render() {
    return (
      <div className="CheckinForm CheckinForm--fullWidth">
        <h2 className="SectionHeading">Reason for your visit</h2>
        <p className="CheckinForm-row CheckinForm-row--desc CheckinForm-row--no-margin">
          Briefly tell us why you are here today.
        </p>
        <div className="ContentBlock CheckinForm-row CheckinForm-row--no-margin">
          {this.textarea("Briefly tell us why you are here today.", {
            className:
              "Input Input--expand TextArea--small Input--resizeVertical",
            placeholder: "Briefly tell us why you're here today.",
            useValue: true,
          })}
        </div>
        {this.renderCheckInFooter()}
      </div>
    );
  },
});

export default observer(ReasonStep);
