{div, tch, p} = Exim.DOM
Widget = require './Widget'
AMATHealthScreeningStatus = require './AMATHealthScreeningStatus'

WellnessProgramWidget = Exim.createView module.id,
  contextTypes:
    router: React.PropTypes.object.isRequired

  readMoreOnClick: ->
    @context.router.push '/wellness/overview'

  renderContent: (wellnessProgramResults) ->
    { wellness_program, status, patient_screening_status_msg, date_msg, url } = wellnessProgramResults

    div className: 'WellnessWidgetContent',
      div className: 'WellnessWidgetContent-title',
        date_msg
      div className: 'WellnessWidgetContent-body',
        div className: 'WellnessWidgetContent-body-content',
          AMATHealthScreeningStatus {
            wellnessScreeningStatus: status,
            wellnessProgramInfo: wellness_program,
            wellnessProgramUrl: url,
            patient_screening_status_msg
          }
      div className: 'WellnessWidgetContent-link',
        tch tagName: 'a', href: '#', className: '', handler: @readMoreOnClick, 'Read more'

  render: ->
    { programsWithWidgets } = @props

    programsWithWidgets.map (result) =>
      div className: 'WellnessLandingWidget', key: Math.random(),
        Widget
          icon: 'wellnessLandingWidgetIcon'
          title: result['wellness_program'].title
          titleClassName: 'WellnessWidgetTitle'
          body: @renderContent(result)

module.exports = WellnessProgramWidget
