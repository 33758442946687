config    = require 'config'
marked    = require 'marked'
authStore = require 'stores/auth'
humanize  = require 'humanize'
URITemp   = require 'urijs/src/URITemplate'
parseLink = require 'parse-link-header'
ReactTestUtils = require('react-dom/test-utils')
newUtils = require('./new-utils')
moment = require('moment')

blankImg = null

MAX_PHOTO_SIZE = 5 * 1024 * 1024
MAX_DOC_SIZE = 30 * 1024 * 1024
savingWithPlugin = config.iOS or config.android
{span} = Exim.DOM

epoch = moment(0)

ymd = (date) =>
  return {
    year: date.year(),
    month: date.month(),
    date: date.date(),
  }

utils = {
  expandUriTemplate: (template, values) ->
    URITemp(template).expand(values)

  ready: ->
    new Promise (res) ->
      document.addEventListener('DOMContentLoaded', res)

  # execute the passed function upon click, Enter keypress, or Spacebar keypress
  a11yClick: (f, arg) -> (evt) =>
    if (evt.type == "click")
      # invoke the function with argument if one exists, else just invoke the function
      `arg ? f(arg)(evt) : f(evt)`
    else
      if (evt.type == "keypress")
        if (evt.key == "Enter" || evt.key == " ")
          `arg ? f(arg)(evt) : f(evt)`
        else if (evt.keyCode == 32 || evt.keyCode == 13)
          `arg ? f(arg)(evt) : f(evt)`

  # try to convert a string to a phone number
  toPhone: (str='') ->
    rub = (str) -> str.replace(/\s+/g, ' ').trim()
    num = rub(str)

    return num if authStore.get('user')?.default_site in ['crk', 'amk', 'inn']

    # try to parse extension (check in a specific order!)
    if num.match(/[x#:,]/i)
      [num, ext] = num.split(/\s*x\s*/i, 2)
      [num, ext] = num.split(/\s*#\s*/i, 2) unless ext
      [num, ext] = num.split(/\s*:\s*/i, 2) unless ext
      [num, ext] = num.split(/\s*,\s*/i, 2) unless ext

    # remove superfluous characters
    num = num.replace(/^[^\+0-9]*\+?1/, '')
    ext = ext.replace(/\D+/g, '') if ext

    # preserve international and adjust domestic formatting
    if all = num.match(/^[^+0-9]*\+(.*)/)
      num = '+' + rub(all[1].replace(/\D/g, ' '))
    else if all = num.replace(/\D/g, '').match(/^([2-9][0-8][0-9])([2-9]\d\d)(\d{4})$/)
      num = "(#{all[1]}) #{all[2]}-#{all[3]}"
    else
      num = ''

    # our best guess!
    num += ", ext. #{ext}" if num and ext
    num

  formatUSPhone: (str='') ->
    rub = (str) -> str.replace(/\s+/g, ' ').trim()
    num = rub(str)

    # try to parse extension (check in a specific order!)
    if num.match(/[x#:,]/i)
      [num, ext] = num.split(/\s*x\s*/i, 2)
      [num, ext] = num.split(/\s*#\s*/i, 2) unless ext
      [num, ext] = num.split(/\s*:\s*/i, 2) unless ext
      [num, ext] = num.split(/\s*,\s*/i, 2) unless ext

    # remove superfluous characters
    num = num.replace(/^[^\+0-9]*\+?1/, '')
    ext = ext.replace(/\D+/g, '') if ext

    # preserve international and adjust domestic formatting
    if all = num.match(/^[^+0-9]*\+(.*)/)
      num = '+' + rub(all[1].replace(/\D/g, ' '))
    else if all = num.replace(/\D/g, '').match(/^([2-9][0-8][0-9])([2-9]\d\d)(\d{4})$/)
      num = "(#{all[1]}) #{all[2]}-#{all[3]}"
    else
      num = ''

    # our best guess!
    num += ", ext. #{ext}" if num and ext
    num

  validatePhoto: (file, opts={}) ->
    maxSize = opts.maxSize or 3
    unless file.type.match('image.*')
      return false
    if (file.size / (1024 * 1000)) > maxSize # MB
      return false
    return true

  feetAndInches: (value) ->
    feet = +value / 12
    inches = Math.round(Math.round(12 * (feet - Math.floor(feet)) * 100) / 100)

    feet = Math.floor(feet)

    if inches is 12
      feet++
      inches = 0

    "#{feet}′#{inches}″"

  getAvatarUrl: (link, allowNull) ->
    if link
      src = if link.indexOf('http') isnt -1 or link.indexOf('/') isnt 0
        link
      else
        link.slice(1)
    src ?= 'images/image-placeholder.jpg' unless allowNull
    src = "combo/#{src}" if src is 'images/image-placeholder.jpg'
    return utils.getUrl(src)

  getCordovaUrl: (link) ->
    return link unless config.isCordova

    src = if link.indexOf('http') isnt -1 or link.indexOf('/') isnt 0
      link
    else
      link.slice(1)

  getUrl: (src) -> "#{config.api.hostname}#{src}"

  getRedirectUrl: (location, withRedirect) ->
    redirectPath = location.pathname.split('redirect=')[1] || location.search.split('redirect=')[1]
    if redirectPath
      if withRedirect
        "?redirect=#{encodeURIComponent(redirectPath)}"
      else
        decodedRedirectPath = decodeURIComponent(redirectPath)
        pathname = decodedRedirectPath.split('?')[0]
        queryParam = decodedRedirectPath.split('?')[1]
        search = if queryParam then "?#{queryParam}" else ""
        {pathname, search}
    else ''

  setRedirectUrl: (location, hasRedirect) ->
    if hasRedirect
    then "/home?redirect=#{location.pathname + location.search}"
    else location.pathname + location.search

  getIcsUrl: (starts_at, type) ->
    "#{config.api.hostname}#{config.api.root}/appointments/generate_ics/#{encodeURIComponent(starts_at)}/#{encodeURIComponent(type)}"

  capitalize: (string) ->
    return string unless string
    words = string.split(' ').map((str) -> "#{str.charAt(0).toUpperCase()}#{str.slice(1)}")
    return words.join(' ')

  humanizeSize: (size) ->
    humanize.filesize(size, 1000, 1)

  humanizeOrdinal: (i) ->
    humanize.ordinal(i)

  parseString: (string) ->
    string.split('_').map(utils.capitalize).join(' ')

  formatDateSimple: (date, format) ->
    moment(date).format(config.formats.date)

  shortFormatDateNoMidnight: (date) ->
    moment(date).format(config.formats.checkinShortDateFormat).split(' 12:00 am')[0]

  formatDateShort: (date) ->
    moment(date).format(config.formats.shortDateFormat)

  formatDateNicely: (raw, withPrefix, withTime) ->
    momentDate = moment(raw)
    isToday = momentDate.isSame(moment(), 'day')

    if isToday
      prefix = '[at] '
      date = ''
      time = 'h:mm a'
    else
      prefix = '[on] '
      date = 'MM/DD/YY'
      time = if withTime then ', hh:mm A' else ''

    prefix = '' unless withPrefix
    return momentDate.format(prefix + date + time)

  humanizeMime: (mime) ->
    if /pdf/.test(mime)
      'PDF'
    else if /image/.test(mime)
      'Image'
    else if /video/.test(mime)
      'Video'
    else if /zip/.test(mime)
      'Zip'
    else if /document/.test(mime)
      'Document'
    else if /audio/.test(mime)
      'Audio'
    else
      'Other'

  getIconName: (mime) ->
    return 'file-o' unless mime
    mime = mime.toLowerCase()
    if /pdf/.test(mime)
      'file-pdf-o'
    else if /doc|docx|pages/.test(mime)
      'file-text-o'
    else if /image|jpg|jpeg|png|gif/.test(mime)
      'file-photo-o'
    else if /video/.test(mime)
      'file-video-o'
    else if /zip|rar/.test(mime)
      'file-archive-o'
    else if /document/.test(mime)
      'file-text-o'
    else if /audio/.test(mime)
      'file-audio-o'
    else
      'file-o'

  clone: (obj, exclude=[]) ->
    str = Object.prototype.toString.call(obj)
    if obj instanceof Array
      out = []
      for i of obj
        out[i] = arguments.callee(obj[i]) unless i in exclude
      return out

    else if str is '[object Object]'
      out = {}
      for key, val of obj
        out[key] = arguments.callee(val) unless key in exclude
      return out

    return obj

  readImage: (file) ->
    new Promise (res, rej) ->
      reader = new FileReader
      try
        onLoad = (e) -> res(e.target.result)
        onError = (e) -> rej(e)
        reader.onload = onLoad
        reader.onerror = onError
        reader.readAsDataURL(file)
      catch err
        console.log err

  saveToGallery: (fullUrl) ->
    new Promise (res, rej) ->
      window.canvas2ImagePlugin.saveUrlToLibrary(res, rej, fullUrl)

  download: (fileEntry, uri, readBinaryData, cb) ->
    fileTransfer = new FileTransfer()
    fileURL = fileEntry.toURL()

    fileTransfer.download(
      uri,
      fileURL,
      (entry) =>
        @readBinaryFile(entry) if readBinaryData
        cb(entry) if cb
      , (error) ->
        console.log('upload error code' + error.code)
      , null, {
        headers: { Connection: 'close' }
      }

    )

  readBinaryFile: (fileEntry) ->
    fileEntry.file (file) ->
      reader = new FileReader()
      reader.onloadend = ->
        console.log('Successful file read: ' + this.result)
        blob = new Blob([new Uint8Array(this.result)], { type: 'image/png' })
        # displayImage(blob)
      reader.readAsArrayBuffer(file)
    , (error) -> console.log "Error reading file: #{error}"

  saveImage: (url, name) ->
    if config.isCordova
      return if @isSaving
      console.log 'trying to save an image'
      if savingWithPlugin
        @isSaving = true
        @saveToGallery(@getUrl(url.slice(1))).then =>
          @isSaving = false
          navigator.notification.alert('', null, 'Image was saved successfully', 'Done')
        .catch (err) =>
          @isSaving = false
          navigator.notification.alert('An error happened while saving the image', null, 'Error', 'Cancel')
      else
        window.requestFileSystem LocalFileSystem.PERSISTENT, MAX_PHOTO_SIZE, (fs) =>
          console.log('file system open: ' + fs.name)
          uri = encodeURI(@getUrl(url.slice(1)))
          console.log uri
          downloadLink = encodeURI(url)
          ext = downloadLink.substr(downloadLink.lastIndexOf('.') + 1)
          fs.root.getFile 'downloaded-image.png', { create: true, exclusive: false }, (fileEntry) =>
            @download(fileEntry, uri, true)
          , (error) -> console.log "Error creating file: #{error}"
        , (error) -> console.log "Error reading FS: #{error}"
    else
      link = document.createElement('a')
      link.target = '_blank'
      link.href = @getUrl(url.slice(1))
      link.classList.add('links')
      link.download = name
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)

  openLink: (url, name='') ->
    link = document.createElement('a')
    link.target = '_blank'
    link.href = url
    link.classList.add('links')
    link.download = name
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)

  openFileOnDevice: (url, name, mime) ->
    unless config.isCordova
      @openLink url, name
    else
      return unless cordova?.plugins?.fileOpener2

      error = (error) -> console.log 'error', error
      success = (d) -> console.log 'success', d

      successCallback = (fileEntry) ->
        url = fileEntry.nativeURL
        cordova.plugins.fileOpener2.open(url, mime, {error, success})

      window.requestFileSystem LocalFileSystem.PERSISTENT, MAX_DOC_SIZE, (fs) =>
        uri = encodeURI(url)
        fs.root.getFile name, { create: true, exclusive: false }, (fileEntry) =>
          @download(fileEntry, uri, false, successCallback)
        , (error) -> console.log "Error creating file: #{error}"
      , (error) -> console.log "Error reading FS: #{error}"

  openOAuthProvider: (url) ->
    fetch(url).then (resp) ->
      linkHeader = resp.headers.get('Link')
      links = parseLink(linkHeader)
      next = links.next.url
      cordova.plugins.browsertab.isAvailable (res) ->
        unless res
          cordova.InAppBrowser.open(next, '_system')
        else
          cordova.plugins.browsertab.openUrl(
            next,
            (success) -> console.log("Success:", success),
            (err) -> console.log("Error:", err)
          )

  age: (dob, format) ->
    dob = moment(dob, format) if format
    moment().diff(dob, 'years')

  gender: (val) ->
    switch (val or 'U')
      when 'M', 'm' then 'Male'
      when 'F', 'f' then 'Female'
      when 'I', 'i' then 'Intersex'
      when 'O', 'o' then 'Other'
      when 'U', 'u' then 'Unknown'
      else val

  getSubject: (subject) ->
    if subject then subject.replace(/(RE:\s?)+/g, 'RE: ').replace(/(FW:\s?)+/g, 'FW: ') else ''

  getBody: (body) ->
    if body
      match = body.match /(On \w{3}, \w{3} \d{1,2}, \d{4} at \d{2}:\d{2} \w{2} - ?.* wrote:)/ig
      match?.forEach (date) ->
        body = body.replace(date, '> ' + date)

      marked(body, gfm: true, breaks: true, sanitize: true)
    else
     ''

  sortBooleans: (a, b, asc) ->
    if asc
      if (a is b) then 0 else (if a then -1 else 1)
    else
      if (a is b) then 0 else (if a then 1 else -1)

  parsePattern: do ->
    regexMatcher = /^\/(.*)\//
    rangeMatcher = /^range(\(|\[)(\d+(?:\.\d+)?)\s*,\s*(\d+(?:\.\d+)?)(\]|\))$/
    customMatcher = /^custom\((\w+)\)$/
    bpMatcher = /^(\d+)[\s\/\-:,]*(\d+)$/
    maskMatcher = /^(9*)(\[9*\])?\.?(9*)?$/

    patterns =
      # Blood pressure. 70-250 / 40-150
      bp: (value) ->
        return false unless match = value.match bpMatcher
        low = parseInt match[1]
        high = parseInt match[2]
        if (70 <= low <= 250) and (40 <= high <= 150)
          "#{low}/#{high}"
        else
          false

    makeMinComparator = (min, bracket) ->
      if bracket is '['
        (value) -> value >= min
      else
        (value) -> value > min

    makeMaxComparator = (max, bracket) ->
      if bracket is ']'
        (value) -> value <= max
      else
        (value) -> value < max

    makeMaskMatcher = (match) ->
      int = match[1]?.replace(/\D/g, '').length
      opt = match[2]?.replace(/\D/g, '').length
      fract = match[3]?.replace(/\D/g, '').length
      rxString = '^'
      rxString += "\\d{#{int}}" if int
      rxString += "\\d{0,#{opt}}" if opt
      rxString += "\\.\\d{#{fract}}" if fract
      rxString += '$'
      matcher = new RegExp(rxString)

    (pattern) ->
      return true unless pattern
      if Array.isArray pattern
        (value) -> value in pattern
      else if match = pattern.match regexMatcher
        re = match[1]
        (value) -> re.test value
      else if match = pattern.match rangeMatcher
        [_, bracket1, min, max, bracket2] = match
        minComp = makeMinComparator parseFloat(min, 10), bracket1
        maxComp = makeMaxComparator parseFloat(max, 10), bracket2
        (value) ->
          (minComp value) and (maxComp value)
      else if match = pattern.match customMatcher
        name = match[1]
        patterns[name] or throw new Error "Unknown pattern: #{name}"
      else if match = pattern.match maskMatcher
        matcher = makeMaskMatcher(match)
        # (value) -> matcher.test value
        -> true
      else
        -> true

  getFirstChars: (str, ignoreEnd) ->
    if str
      words = str.split(' ')
      words = words.slice(0,2) if ignoreEnd and words.length > 2
      words.map((c) -> c.slice(0,1).toUpperCase()).join('')
    else
      ''

  getRandomColor: (str='') ->
    i = 0
    hash = 0
    while i < str.length
      hash = str.charCodeAt(i++) + (hash << 5) - hash
    i = 0
    colour = '#'
    while i < 3
      colour += ('00' + (hash >> i++ * 8 & 0xFF).toString(16)).slice(-2)
    colour

  getGenderLabel: (client='', hasSOGI=false, isCork=false, isSingapore=false) ->
    shouldHideGenderField = config.hideGenderFieldForClients.includes(client.toLowerCase())
    return '' if shouldHideGenderField
    return 'Gender' if isCork or isSingapore or !hasSOGI
    return 'Sex Assigned at Birth'

  getFormattedSexAtBirth: (sexAtBirth) ->
    switch (sexAtBirth)
      when 'M' then 'Male'
      when 'F' then 'Female'
      when 'I' then 'Intersex'
      when 'O' then 'Other'
      else 'U'

  getFormattedPronouns: (pronouns, isCtm) ->
    notListed = if isCtm then 'Option not listed (check member chart)' else 'Option not listed (please inform provider)'
    switch pronouns
      when 'she_her_hers' then 'She / Her / Hers'
      when 'he_him_his' then 'He / Him / His'
      when 'they_them_theirs' then 'They / Them / Theirs'
      when 'option_not_listed' then notListed
      else ''

  getFormattedGenderIdentity: (genderIdentity, isCtm) ->
    notListed = if isCtm then 'Option not listed (check member chart)' else 'Option not listed (please inform provider)'
    switch genderIdentity
      when 'woman' then 'Woman'
      when 'man' then 'Man'
      when 'transgender_woman' then 'Transgender woman / transfeminine'
      when 'transgender_man' then 'Transgender man / transmasculine'
      when 'nonbinary' then 'Non-binary / genderqueer / gender fluid'
      when 'two_spirit' then 'Two-Spirit'
      when 'option_not_listed' then notListed
      when 'prefer_not_to_say' then 'Prefer not to say'
      when 'do_not_know' then "Don't know"
      else 'Not answered'

  dataURItoBlob: (dataURI) ->
    byteString = if (dataURI.split(',')[0].indexOf('base64') >= 0)
      atob(dataURI.split(',')[1])
    else
      unescape(dataURI.split(',')[1])

    mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    ia = new Uint8Array(byteString.length)
    ia[i] = byteString.charCodeAt(i) for i in [0...byteString.length]

    new Blob [ia], {type:mimeString}

  highlightMatched: (raw, matcher) ->
    result = if matcher and (matched = raw?.match(new RegExp(matcher, 'i')))
      matchedAt = raw.indexOf(matched[0])
      if matchedAt is 0
        [
          span key: 'highlight-1', className: 'u-highlight', raw.substring(matchedAt, matchedAt + matcher.length)
          span key: 'highlight-2', raw.substring(matchedAt + matcher.length)
        ]
      else
        [
          span key: 'highlight-1', raw.substring(0, matchedAt)
          span key: 'highlight-2', className: 'u-highlight', raw.substring(matchedAt, matchedAt + matcher.length)
          span key: 'highlight-3', raw.substring(matchedAt + matcher.length)
        ]
    else
      raw

  isEmail: (value) ->
    return false unless value

    input = document.createElement('input')
    input.type = 'email'
    input.value = value
    return input.checkValidity()

  cx: (base, mods...) ->
    res = base

    mods.forEach((mod) ->
      if mod is Object(mod)
        Object.entries(mod).forEach(([mod, isEnabled]) ->
          res += " #{base}--#{mod}" if isEnabled
        )
      else if typeof mod == 'string'
        res += " #{base}--#{mod}"
    )

    return res

  asc: (a, b) => a - b
  desc: (a, b) => b - a

  onlyTime: (withDate = moment()) => withDate.clone().set(ymd(epoch)),
  onlyDate: (withTime = moment()) => withTime.clone().startOf('day'),
  dateTime: (date, time) => time.clone().set(ymd(date)),

  mem: (fn) =>
    cache = new WeakMap

    return (key) ->
      return cache.get(key) if cache.has(key)

      res = fn(key)
      cache.set(key, res)

      return res

  getInputValue: (el, fn) =>
    num = fn(el.value)

    if el.hasAttribute('min')
      min = fn(el.min)
      return min if num < min || isNaN(num)

    if el.hasAttribute('max')
      max = fn(el.max)
      return max if num > max

    return num

  getNumberValue: (el) =>
    return utils.getInputValue(el, Number)

  getRandomValue: (length=6) =>
    return "#{Math.floor(Math.random() * (10 ** length))}"

  stepNumber: (el, dir) =>
    num = +el.value
    step = if el.hasAttribute('step') then +el.step else 1

    switch dir
      when 'up'
        num += step
      when 'down'
        num -= step

    if el.hasAttribute('min')
      return if num < el.min

    if el.hasAttribute('max')
      return if num > el.max

    el.value = num
    ReactTestUtils.Simulate.change(el)

  removeDragImage: (dt) ->
    if !dt.setDragImage
      return

    if !blankImg
      blankImg = document.createElement('canvas')
      blankImg.style = 'position: absolute; top: -1px; left: -1px;'
      blankImg.width = 1
      blankImg.height = 1
      document.body.append(blankImg)

    dt.setDragImage(blankImg, 0, 0)

  destructureDateTime: (datetime, zoneAbbr='PDT') ->
    if not datetime
      return

    [date, time] = datetime.replace('Z', '').split('T')
    [year, month, day] = date.split('-')
    [hour, minute, second] = time.split(':')
    m = moment()
    m.set('hour', hour)
    m.set('minute', minute)
    m.set('second', second)

    destructured = [
      [month, day, year].join('/'),
      m.format('h:mm A'),
      zoneAbbr
    ]
}

Object.assign(utils, newUtils)
module.exports = utils
