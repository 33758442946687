const { withEscapeHandler } = require("components/elements/new-modal");

function formatYesNo(val) {
  return val ? "YES" : "No";
}

const CovidToolTip = withEscapeHandler((props) => {
  if (!props.show) return null;

  const { value, from_claims, recent_visit } = props.score || {};
  return (
    <div className="SidebarNav-hover" style={{ top: "58px", left: "660px" }}>
      <div style={{ margin: "10px 0" }}>
        <div
          style={{ width: "30%", display: "inline-block" }}
        >{`Numeric Index:  ${value || "NA"}`}</div>
        <div
          style={{ width: "30%", display: "inline-block" }}
        >{`Claims Data:  ${formatYesNo(from_claims)}`}</div>
        <div
          style={{ width: "30%", display: "inline-block" }}
        >{`Recent Visit:  ${formatYesNo(recent_visit)}`}</div>
      </div>
      <div>
        <div>
          COVID 19 Vulnerability Index is calculated by reviewing the following
          elements over the past 12 months:
        </div>
        <ul
          style={{ padding: "0px 12px", margin: "0px", listStyleType: "disc" }}
        >
          <li>Age</li>
          <li>Recent XO visits</li>
          <li>Claims Data- when available</li>
          <li># of ER Visits</li>
          <li>
            Dx of any of the following:
            <ul>
              <li>Respiratory signs and symptoms</li>
              <li>Diseases of the circulatory system</li>
              <li>Diseases of the respiratory system</li>
              <li>Chronic obstructive pulmonary disease and bronchiectasis</li>
              <li>Pneumonia except that caused by tuberculosis</li>
              <li>Diseases of the blood and blood-forming organs</li>
              <li>Certain disorders involving the immune mechanism</li>
              <li>Heart failure</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
});

module.exports = CovidToolTip;
