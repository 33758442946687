"use strict";
const { observer } = require("mobx-react");
const immunizationsStore = require("stores/immunizations");
const Sortable = require("components/elements/sort/sortable");
const Sorter = require("components/elements/sort/sorter");
const { IMMUNIZATION } = require("lib/formats");

const renderRow = ({ name, date }) => {
  return (
    <tr key={`${name}@${date}`}>
      <td>{name}</td>
      <td className="TrendsTable-date">{date.format(IMMUNIZATION)}</td>
    </tr>
  );
};

const renderEmptyRow = () => {
  return (
    <tr>
      <td>There are currently no items listed.</td>
    </tr>
  );
};

class Immunizations extends React.Component {
  componentWillMount() {
    immunizationsStore.fetchAll();
  }

  renderHead() {
    let { sort } = this.props;

    return (
      <tr>
        <Sorter sort={sort} by="name">
          <h2 className="trends-header">Immunizations</h2>
        </Sorter>
        <Sorter sort={sort} by="date" className="TrendsTable-date">
          Date
        </Sorter>
      </tr>
    );
  }

  renderBody() {
    return immunizationsStore.all.length ? [...immunizationsStore.all].sort(this.props.sort.fn).map(renderRow) : renderEmptyRow();
  }

  renderSortInfo() {
    return `column ${this.props.sort.by} sorted ${this.props.sort.order}`;
  }

  render() {
    return (
      <>
        <table
          className="NewTable NewTable--striped TrendsTable"
          aria-colcount="2"
          aria-rowcount={immunizationsStore.all.length}
          aria-label="Immunizations"
          aria-describedby="tableInfo"
        >
          <thead>{this.renderHead()}</thead>
          <tbody>{this.renderBody()}</tbody>
        </table>
        <p hidden aria-hidden="true" id="tableInfo">
          {this.renderSortInfo()}
        </p>
      </>
    );
  }
}

const ImmunizationsObserver = observer(Immunizations);

module.exports = () => {
  return (
    <Sortable by="name" order="descending">
      <ImmunizationsObserver />
    </Sortable>
  );
};
