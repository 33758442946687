{ chargesStore } = require 'stores'
{ tch, div, h3, span, input, textarea } = Exim.DOM
{ cx } = Exim.helpers
Select = require 'components/elements/Select'


AddChargeModal = Exim.createView module.id,
  mixins: [
    chargesStore.connect('addingCharge', 'chargeAdded')
  ]

  getInitialState: ->
    amount: null
    category: ''
    description: ''
    date: null
    confirmDisabled: true

  onConfirm: ->
    return if @state.confirmDisabled
    chargesStore.actions.addCharge(@props.userId, @state.amount*100, @state.description, moment(@state.date).toISOString(), @state.category).then =>
      @props.close()

  onAmountChange: (e) ->
    amount = e.target.value
    if (amount > 0 and !!Number(amount)) or amount is ''
      @setState {amount}, @validate

  onDescriptionChange: (e) ->
    @setState description: e.target.value, @validate

  onDateChange: (e) ->
    @setState date: e.target.value, @validate

  updateCategory: (option) ->
    @setState category: option.value, @validate

  validate: ->
    { amount, description, date, category } = @state
    if amount and description and date and category and amount <= 600
      @setState confirmDisabled: false
    else
      @setState confirmDisabled: true

  render: ->
    div className: 'AddChargeModal-body',
      div className: 'AmountOwedRow',
        span 'Amount Owed'
        input className: 'AmountOwedInput', value: @state.amount, placeholder: '$000.00', onChange: @onAmountChange, onBlur: @validate
      div,
        div className: 'PaymentModalBody-notes',
          Select
            options: [
              {value: 'Medicine', label: 'Medicine'},
              {value: 'Late Cancel/Missed Appointment Fee', label: 'Late Cancel/Missed Appointment Fee'},
              {value: 'Health Item', label: 'Health Item'},
              {value: 'Travel Consult', label: 'Travel Consult'},
              {value: 'Visit Fee',label: 'Visit Fee'},
              ]
            value: @state.category
            onChange: @updateCategory
            matchProp: 'label'
            clearable: false
            backspaceRemoves: true
            placeholder: 'Select Category'
            searchable: false
            savedHover: true
            key: 'option-category'
      textarea className: 'ChargeDescription', placeholder: 'Description (required)', onChange: @onDescriptionChange, onBlur: @validate
      div className: 'ServiceDateInputRow',
        span 'Service Date'
        input className: 'ServiceDateInput', placeholder: moment().format('L'), onChange: @onDateChange, onBlur: @validate
      div className: 'AddChargeModal-footer',
        tch className: 'Button', handler: @props.close, style: {marginRight: '8px'},
          'Cancel'
        tch className: "Button Button--primary #{cx 'is-disabled': @state.confirmDisabled || @state.addingCharge || @state.chargeAdded}", handler: @onConfirm,
          'Confirm'
      if @state.amount > 600
        div className: 'error', style: {paddingTop: '10px'}, 'Amount must be between $0 - $600'

module.exports = AddChargeModal
