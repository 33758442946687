authStore = require 'stores/auth'
request = require 'lib/request'
{uniqBy, mapValues} = require('lodash')

module.exports = Exim.createStore
  path: 'synopsis'
  actions: [
    'fetch'
    'updateSite'
    'updateInsurance'
  ]

  initial: ->
    sites: []
    site:
      modalities: []
    types: []
    allProviders: []
    providers: []
    synopsis: {}
    clinics: {}
    xo_scheduling: false
    globalIdAsPCP: false
    client: null
    amat_hra: false
    be_well: false
    synopsisLoading: false

  updateSite: (site) ->
    allTypes = @get('synopsis').appointments or []
    mdl = site.modalities or []
    siteTypes = mdl.map((m) -> m.list).reduce(((o, u) -> o.concat(u)), [])
    types = allTypes.filter((type) -> type.id in siteTypes)
    localStorage.setItem('site', site.code)
    @set {site, types, allProviders: site.providers}

  fetch:
    while: (synopsisLoading) ->
      @set {synopsisLoading}
    on: ->
      request.get('synopsis')
    did: (synopsis) ->
      defaultSite = localStorage.getItem('site') or authStore.get('user').default_site
      site = synopsis.sites.find((s) -> s.code is defaultSite) or synopsis.sites[0] or {}
      synopsis.appointments = uniqBy(synopsis.appointments, 'id')
      @set
        synopsis: synopsis
        sites: synopsis.sites
        site: site
        allTypes: synopsis.appointments
        types: synopsis.appointments
        allProviders: site.providers
        providers: synopsis.sites[0]?.providers
        stars: synopsis.stars # TODO: move to User model
        noshows: synopsis.noshows # TODO: move to User model
        limit: synopsis.limit # TODO: move to User model
        window: synopsis.window # TODO: move to User model
        clinics: synopsis.clinics
        enable_outbound_pros: synopsis.enable_outbound_pros
        xo_scheduling: synopsis.xo_scheduling
        xo_screening_framework: synopsis.xo_screening_framework or false
        enable_pcp_enhancement: synopsis.enable_pcp_enhancement
        hide_landing_page_office_booking: site.hide_landing_page_office_booking
        client: synopsis.client
        amat_hra: synopsis.amat_hra
        be_well: synopsis.be_well
        transition_banner_tile: synopsis.transition_banner_tile

  updateInsurance:
    while: (state) ->
      @set updatingInsrance: state
    on: (user_id, params) ->
      request.put("users/#{user_id}/insurance",
        mapValues(params, (val) => if val == '' then null else val)
      )
    did: (insuranceData) ->
      @set {insuranceData}
    didNot: (error) ->
      alert(error.error)
