'use strict'
{div, button, time, fa, table, tbody, thead, tr, td, th} = Exim.DOM
{authStore, messagesStore, appStore} = require 'stores'
{observer} = require 'mobx-react'
{showModal} = require 'components/elements/new-modal'
CancelApptModal = require 'components/elements/cancel-appt-modal'
AddNoteModal = require 'components/calendar/modals/add-note'
NotesModal = require 'components/calendar/modals/appt-notes'
Sortable = require 'components/elements/sort/sortable'
Sorter = require 'components/elements/sort/sorter'
{formats} = require 'config'
slotsStore = require 'stores/new-slots'
newAuthStore = require 'stores/new-auth'
{proxyPatient} = require('lib/utils')

class Conflicts extends React.Component
  showCancelApptModal: (appointment) ->
    modal = React.createElement(CancelApptModal, {appointment})
    return showModal(modal)

  showAddNoteModal: (appointment) ->
    modal = React.createElement(AddNoteModal, {appointment})
    showModal(modal)

  showNotesModal: (appointment) ->
    modal = React.createElement(NotesModal, {appointment})
    showModal(modal)

  renderHead: ->
    {sort} = this.props

    return tr null,
      React.createElement(Sorter, {
        sort,
        by: 'startAt',
      }, 'Appt Date')
      React.createElement(Sorter, {
        sort,
        by: 'type.name',
      }, 'Appt Type')
      React.createElement(Sorter, {
        sort,
        by: 'duration',
      }, 'Duration')
      React.createElement(Sorter, {
        sort,
        by: 'provider.name',
      }, 'Provider')
      React.createElement(Sorter, {
        sort,
        by: 'patient.name',
        className: 'Conflicts-patient',
      }, 'Patient')
      th null, 'Actions'

  renderBody: ->
    return [slotsStore.conflictedAppts...]
      .sort(this.props.sort.fn)
      .map(this.renderAppt)

  renderAppt: (appt) =>
    {startAt, patient} = appt

    return tr key: appt.id,
      td null,
        time dateTime: startAt.toISOString(),
          startAt.format(formats.shortDateFormat)
      td className: 'Conflicts-type',
        appt.slot.isGroup && fa 'users'
        appt.type?.name
      td null,
        time dateTime: appt.duration.toISOString(),
          "#{appt.duration.asMinutes()} min"
      td null,
        appt.provider.name
      td title: patient.shortInfo,
        button {
          className: 'Conflicts-patient',
          onClick: () => proxyPatient(patient),
        }, patient.name
      td className: 'Conflicts-actions',
        this.renderActions(appt)

  renderActions: (appt) ->
    {user} = newAuthStore

    return React.createElement(React.Fragment, null,
      button {
        className: 'Conflicts-button Conflicts-button-keep'
        disabled: !user.canEditCalendar
        onClick: () => appt.keep()
      }, 'Keep'
      button {
        className: 'Conflicts-button Conflicts-button-cancel'
        disabled: !user.canEditCalendar
        onClick: () => this.showCancelApptModal(appt)
      }, 'Cancel'
      button {
        className: 'Conflicts-button Conflicts-button-cancel'
        disabled: !user.canEditCalendar
        onClick: () =>
          this.showCancelApptModal(appt).then(() =>
            appt.patient.proxy(appt)
          )
      }, 'Cancel and Reschedule'
      button {
        className: 'Conflicts-action'
        disabled: !user.canReadCalendar
        title: 'Add note'
        onClick: () => this.showAddNoteModal(appt)
      }, fa 'plus'
      button {
        className: 'Conflicts-action'
        disabled: !appt.notes.length
        title: 'See notes'
        onClick: () => this.showNotesModal(appt)
      }, fa 'sticky-note'
    )

  render: ->
    if !slotsStore.conflictedAppts.length
      return div className: 'Conflicts',
        'There are no unresolved conflicts.'

    return div className: 'Conflicts',
      table className: 'Conflicts-table NewTable',
        thead null, this.renderHead()
        tbody null, this.renderBody()

ConflictsObserver = observer(Conflicts)

module.exports = (props) =>
  return React.createElement(Sortable, {
    by: 'startAt',
    order: 'descending',
  },
    React.createElement(ConflictsObserver, props)
  )
