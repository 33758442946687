request = require 'lib/request'

module.exports = store = Exim.createStore
  path: 'appointments'
  actions: [
    'changeStatus'
    'setAmount'
    'create'
    'generateApptCharge'
  ]

  initial: ->
    appointments: []
    types: []
    schedule: []
    error: null

  changeStatus:
    while: (apptStatusChanging) ->
      @set {apptStatusChanging}
    on: (id, status) ->
      schedule = @get('schedule').slice()
      schedule.forEach (provider) ->
        provider.appointments.forEach (appt) ->
          appt.status = appt.action_status = 'review' if id is appt.id
      @set {schedule}

  setAmount:
    on: (providerId, apptId, amount) ->
      amount = amount / 100
      schedule = @get('schedule').slice()
      changed = null
      schedule.forEach (provider) ->
        if (provider.id is providerId)
          provider.appointments.forEach (appt) ->
            if appt.id is apptId
              appt.amount = if typeof amount is 'number' then "$#{amount}" else 'TBD (no plan)'
              appt.owed = appt.amount
              changed = {appointment_id: appt.id, provider_id: provider.id}
          provider.appointments = provider.appointments.slice()
      @set {schedule}

  create:
    while: (isScheduling) ->
      @set {isScheduling}
    on: (fields) ->
      request.post('appointments', fields)
    did: (resp) ->
      @set error: null
    didNot: (resp) ->
      @set error: resp.error

  generateApptCharge:
    on: (appointment_id, patient_id, amount) ->
      params =
        appointment_id: appointment_id
        patient_id: patient_id
        amount: amount
        status: 'unpaid'
      request.post 'appointments/generate-charge', params
