{appStore, authStore} = require 'stores'
{main, section, div} = Exim.DOM
{cx} = Exim.helpers

Search        = require 'components/mixins/Search'
# attachHeader  = require 'components/mixins/attachHeader'
PullToRefresh = require 'components/elements/PullToRefresh'
PatientHeader = require 'components/elements/PatientHeader'
Footer        = require 'components/app/Footer'


Screenings = Exim.createView module.id,
  mixins: [
    Search,
    authStore.connect('user'),
    # attachHeader(name: 'Screening Tools'),
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  # searchKeys: -> ['name', 'owner_name']

  saveSearch: (val) ->
    console.log 'search val:', val

  componentWillUpdate: (prevProps) ->
    return if prevProps.children is @props.children
    @setHeader()

  componentWillMount: ->
    @setHeader()

  setHeader: ->
    actions = [
        title: 'New Screening',
        to: '/screenings/new',
        icon: 'plus',
        params: null,
        query: {},
        key: 'screenings-create'
    ]

    appStore.actions.setHeader(name: 'Screening Tools', actions: actions, search: @onSearch, clearSearch: @clearSearch, matcher: @state.matcher, noSearch: 'screenings/new')

  render: ->
    main id: 'mainContent', className: "Content #{cx 'Content--withPatientHeader': @state.user.proxying}",
      PullToRefresh tag: section, className: 'Content-body', onRefresh: null, isLoading: false,
        div className: 'ContentInnerWrapper',
          PatientHeader()
          div id: 'SurveyJS', className: 'ContentInner Screenings',
            @props.children
          Footer()

module.exports = Screenings
