appointmentsStore = require 'stores/appointments'
authStore = require 'stores/auth'
request = require 'lib/request'
{ hasPendingScreenings } = require('lib/utils/screenings-framework')

module.exports = Exim.createStore
  path: 'checkin',
  actions: [
    'clearCheckin'
    'changeState'
    'create'
    'fetch'
    'update'
    'setStep'
  ]

  initial: ->
    error: false
    isUpdating: false,
    checkin: {
      about: {},
      reason: {},
      meds: {},
      dental: {},
      conditions: {},
      lifestyle: {},
      screenings: {},
    },
    checkinState: 'list'
    stepData: {}

  changeState:
    on: (state, data={}, useCached) ->
      cached = @get('checkinData')
      @data = if useCached and cached and state isnt 'lock' then cached else data
      @state = @data.state = state
      if state isnt 'lock'
        request.post('checkin/state', @data)
    did: ->
      authStore.actions.changeState @state
      @set checkinState: @state
      if @state is 'lock'
        @set checkinData: {}
      else if @state is 'welcome'
        @set checkinData: @data

  create:
    on: (data) ->
      request.post 'checkin', @data = data
    did: ->
      appointmentsStore.actions.changeStatus(@data.appointment_id, 'review')

  update:
    while: (isUpdating) ->
      @set {isUpdating}
    on: (data) ->
      newCheckInStore = require('stores/new-check-in').default
      {next} = newCheckInStore.getStep()
      pending = hasPendingScreenings(data.patient_screenings)
      data.finished = next.id == 'thanks' && !pending

      request.put "checkin/#{data.id}", data
    did: (checkin) ->
      @set {checkin}
    didNot: ({error, field}) ->
      @set {error, errorField: field}

  fetch:
    on: ->
      request.get 'checkin'
    did: (checkin) ->
      data = {checkin}
      if stepName = @get 'step'
        data.stepData = checkin[stepName] or {}
      @set data

  setStep: (name) ->
    stepData = @get('checkin')?[name] or {}
    @set {step: name, stepData}

  clearCheckin: ->
    @reset 'checkin'
