class ResultsHeader extends React.Component {
  render() {
    return (
      <div className="HraResults">
        <i className="fa fa-check-circle-o HraResults-icon"></i>
        <br />
        <span className="HraResults-header"> Congratulations </span>
        <br />
        <span className="HraResults-statement">
          on completing your Health Risk Assessment and taking charge of your
          health!
        </span>
      </div>
    );
  }
}

export default ResultsHeader;
