/* global Exim, React, moment */
import authStore from "stores/new-auth";
import appStore from "stores/app";
import PatientHeader from "components/elements/PatientHeader";
import PullToRefresh from "components/elements/PullToRefresh";
import { touchDevice } from "config";
import { observer } from "mobx-react";
import screeningStore from "stores/screening";

const { cx } = Exim.helpers;
const { section } = Exim.DOM;

class HealthScreenings extends React.Component {
  static contextTypes = {
    router: React.PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.single = !this.context.router.isActive("/health-screenings", true);
  }

  componentDidMount() {
    const { patientId } = authStore.user;

    appStore.actions.setTitle("My Health Checklist");
    appStore.actions.setHeader({ name: "My Health Checklist" });
    screeningStore.fetchHealthScreenings(patientId);
  }

  renderContent() {
    return (
      <div className="ContentInnerWrapper">
        <PatientHeader />
        {this.props.children}
      </div>
    );
  }

  render() {
    let containerClass = `${cx} Content`;
    if (authStore.user.proxying)
      containerClass += " Content--withPatientHeader";

    return (
      <main id="mainContent" className={containerClass}>
        {PullToRefresh({
          ref: "healthscrns",
          tag: section,
          className: "Content-body",
          onRefresh: () => null,
          isLoading: false,
          static: touchDevice && !this.single,
          children: this.renderContent(),
        })}
      </main>
    );
  }
}

module.exports = observer(HealthScreenings);
