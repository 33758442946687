import React from "react";
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import ForcePatientModal from "components/elements/ForcePatientModal"

class AddNewPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAmazonAlertOpen: false,
    }
    this.handleAmazonAlertOpen = () => {
      this.setState({ isAmazonAlertOpen: true });
    }
    this.handleAmazonAlertClose = () => {
      this.setState({ isAmazonAlertOpen: false });
    }
  }

  render() {
    const { onClose, isModalOpen } = this.props;

    return (
      <>
        <Dialog onClose={onClose} open={isModalOpen} className="AddNewPatientModal">
          <DialogTitle className="AddNewPatientModal-title">
            <button
              className="fa fa-times"
              aria-label={`Close add new patient pop up`}
              onClick={onClose}
            />
          </DialogTitle>

          <ForcePatientModal
            handleAmazonAlertOpen={this.handleAmazonAlertOpen}
            handleModalClose={onClose}
          />
        </Dialog>

        <Dialog open={this.state.isAmazonAlertOpen} className="AmazonAlertModal">
          <DialogTitle className="AmazonAlertModal-title">
            <button
              className="fa fa-times"
              aria-label={`Close amazon alert pop up`}
              onClick={this.handleAmazonAlertClose}
            />
          </DialogTitle>
          <DialogContent className="AmazonAlertModal-content">
            <i className="fa fa-exclamation-triangle AmazonAlertModal-danger" aria-hidden="true" />
            <p className="AmazonAlertModal-message">New accounts for this Amazon region should be created in Platform.</p>
            <button
              className="AmazonAlertModal-button"
              aria-label={`Ok, I understand, close pop up`}
              onClick={this.handleAmazonAlertClose}
            >OK, I understand</button>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default AddNewPatient;
