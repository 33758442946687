"use strict";
const authStore = require("stores/new-auth");
const { observer } = require("mobx-react");

const ElationLink = () => {
  if (!authStore.isProxying) return null;

  let url = authStore.user.ehrChartURL;
  if (!url) return null;

  return (
    <li className="SidebarNav-item">
      <a
        className="SidebarNavLink"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="SidebarNavLink-icon">
          <span aria-hidden={true} className="icon fa fa-folder-open" />
        </span>
        <span className="SidebarNavLink-text">Elation</span>
        <span className="SidebarNavLink-external icon fa fa-external-link" />
      </a>
    </li>
  );
};

module.exports = observer(ElationLink);
