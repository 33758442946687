Search =
  getInitialState: ->
    matcher: @getSavedSearch?() or null

  onSearch: (part) ->
    @setState matcher: part
    @saveSearch?(part)

  clearSearch: (part) ->
    @setState matcher: null

  filterer: (item) ->
    return item unless @state.matcher
    searchRe = ///\b#{@state.matcher}///i
    for attr in @searchKeys()
      targ =
        if typeof attr is 'function'
          attr(item)
        else if typeof attr is 'string'
          item[attr]
      return true if searchRe.test targ

module.exports = Search
