{appStore} = require 'stores'
screeningStore = require('stores/screening')
newAuthStore = require('stores/new-auth')
media = require 'components/mixins/MediaQuery'

Modal = require 'components/elements/Modal'
utils = require 'lib/utils'

{cx} = Exim.helpers
{div, input, a, p, button} = Exim.DOM
{iOS, isCordova, isIEunder11, guestRoutes, touchDevice} = require 'config'

App = Exim.createView module.id,

  listen: [
    'auth/loggedIn', 'auth/user', 'app/modal',
    'app/header',
    'app/globalClickHandlers', 'app/globalFileChangeHandler', 'app/afterRoute',
    'app/route'
  ]

  mixins: [
    media(showFlags: 'phoneOrTablet')
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  componentDidMount: ->
    @redirectToBrowsersDownload()
    @redirectToSigninWhenGuest(@state, true)
    @redirectToPinWhenLocked(@state)
    @checkRedirect(@state, true)

    screeningStore.fetchHealthScreenings(newAuthStore?.user?.patientId)

    iframes = Array.from(document.querySelectorAll('iframe'))
    iframes.forEach((iframe) => iframe.setAttribute('tabindex', -1))

  componentWillUpdate: (newProps, newState) ->
    @redirectToBrowsersDownload()
    @checkAfterRouteActions(newProps, newState)
    @redirectToSigninWhenGuest(newState)
    @redirectToPinWhenLocked(newState)
    @checkRedirect(newState)
    @updateLoggingContext(newState)

  componentWillReceiveProps: (nextProps) ->
    if !@props.location or nextProps.location isnt @props.location
      {pathname, query} = @props.location
      appStore.actions.updateLastLocation({pathname, query}) if pathname isnt nextProps.location?.pathname

  redirectToBrowsersDownload:  ->
    if isIEunder11 and !@isActive('browsers')
      @context.router.push {pathname: '/browsers'}

  redirectToSigninWhenGuest: (state, ignoreSignIn) ->
    notLoggedIn = !state.loggedIn and !@guestRouteIsActive()

    if notLoggedIn
      pathname = utils.setRedirectUrl(@props.location, !@guestRouteIsActive())
      @context.router.push { pathname }
    else if !ignoreSignIn and state.loggedIn and !@state.loggedIn
      {pathname, search} = utils.getRedirectUrl(@props.location)
      {user} = state

      if user.xop_eligible || user.retrofit_signin_message
        @context.router.push
          pathname: '/signin/notification'
          state: { user }
      else if pathname
        @context.router.push {pathname, search}
      else
        @context.router.push {pathname: '/'}

  redirectToPinWhenLocked: (state) ->
    {user} = state
    if user.checkin_state is 'lock' and !@isActive('checkin/auth')
      @context.router.push {pathname: '/checkin/auth'}

  checkRedirect: (newState, ignoreCurrent) ->
    if newState.route and (ignoreCurrent or !@state.route)
      appStore.actions.navTo(null)
      @context.router.push newState.route

  updateLoggingContext: (newState) ->
    Rollbar?.configure({ payload: { context: newState.location, server: { host: (window?.location.hostname || window?.location.host) } } })

  checkAfterRouteActions: (nextProps, nextState) ->
    if nextProps.location.pathname isnt @props.location.pathname
      if nextState.afterRoute
        nextState.afterRoute()
        appStore.actions.setAfterRoute(null)

  isActive: (route) ->
    ///^\/#{route}///.test(this.props.location.pathname)

  guestRouteIsActive: ->
    guestRoutes.some(@isActive)

  stopPropagation: (e) ->
    e.stopPropagation()

  globalClick: (evt) ->
    @state.globalClickHandlers.forEach (handler) ->
      handler(evt)

  globalFileChange: (evt) ->
    @state.globalFileChangeHandler?(evt)

  getKeyboardFocusableElements: (element = document) ->
    Array.from(element.querySelectorAll('a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])')).filter (el) -> !el.hasAttribute('disabled') && el.offsetParent

  focusElementById: (id) ->
    document.getElementById(id).focus()

  focusContent: () ->
    # assign focus to first focusable element on main content of page
    if @state.showFlags
      @focusElementById('menu')
    else if document.getElementById('contentSearchInput')
      @focusElementById('contentSearchInput')
    else if document.getElementById('returnFocusAfterModal')
      @focusElementById('returnFocusAfterModal')
    else if document.getElementsByClassName('NewContentHeader').length > 0 && document.getElementsByClassName('NewContentHeader')[0].offsetParent != null
      newContentHeader = document.getElementsByClassName('NewContentHeader')[0]
      @getKeyboardFocusableElements(newContentHeader)[0].focus()
    else if document.querySelectorAll('.NewContent:not(.NewContentHeader)').length > 0 && document.querySelectorAll('.NewContent:not(.NewContentHeader)')[0].offsetParent != null
      newContent = document.querySelectorAll('.NewContent:not(.NewContentHeader)')[0]
      @getKeyboardFocusableElements(newContent)[0].focus()
    else
      mainContent = document.getElementById('mainContent')
      @getKeyboardFocusableElements(mainContent)[0].focus()

  render: ->
    isEditingProfile = this.context.router.isActive('profile/edit')

    return div {
      className: "App #{cx ios: iOS, native: isCordova, touch: touchDevice}",
      onClick: this.globalClick,
    },
      if not @guestRouteIsActive()
        button className: 'SkipLink', "data-e2e": 'skip-link', onClick: @focusContent, 'Skip to Content'
      p className: 'invisible', 'aria-live': 'assertive', 'tabindex': -1, 'aria-hidden': 'true', "Loading #{appStore.initial?.title} page"
      if isEditingProfile && isCordova
        input id: 'avatar-file', type: 'file', className: 'Input--fileHidden', ref: 'fileinput', onClick: @stopPropagation, onChange: @globalFileChange

      this.props.children

      if modal = @state.modal
        Modal modal

module.exports = App
