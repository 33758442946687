"use strict";
const { observer } = require("mobx-react");
const { withRouter } = require("react-router");
const Calendar = require("components/elements/Calendar");
const apptsStore = require("stores/new-appts");
const synopsisStore = require("stores/new-synopsis");
const authStore = require("stores/new-auth");
const Link = require("../link");

const DAILY_PATH = "/schedule";
const renderCalendar = () => {
  return (
    <Calendar
      modifier="sidebar"
      date={apptsStore.date}
      onChange={(date) => {
        apptsStore.date = date;
      }}
      isActive={(date) => {
        let { site } = synopsisStore;
        return site.isWorkingDay(date);
      }}
    />
  );
};

const ScheduleTab = ({ router }) => {
  let { user } = authStore;
  if (!user.canReadSchedule) return null;

  let isActive = router.isActive(DAILY_PATH);

  return (
    <li className="SidebarNav-item">
      {isActive && renderCalendar()}
      <Link to={DAILY_PATH}>
        <span className="SidebarNavLink-icon">
          <span className="icon fa fa-calendar-check-o" />
        </span>
        <span className="SidebarNavLink-text">Daily Schedule</span>
      </Link>
    </li>
  );
};

module.exports = withRouter(observer(ScheduleTab));
