{appStore, authStore, metricsStore, visitsStore, patientDataStore} = require 'stores'

{observer} = require('mobx-react')
attachHeader  = require 'components/mixins/attachHeader'
PullToRefresh = require 'components/elements/PullToRefresh'
immunizationsStore = require('stores/immunizations')
Footer        = require 'components/app/Footer'
PatientHeader = require 'components/elements/PatientHeader'
PADDING_TOP = 150
config = require 'config'

{main, section, div}  = Exim.DOM
{cx} = Exim.helpers

trendStores = [
  metricsStore
  visitsStore
  patientDataStore
]

Trends = React.createClass({
  displayName: 'Trends'
  mixins: [
    authStore.connect('user')
    attachHeader(name: 'Trends')
    metricsStore.connect('metricsLoading')
  ]

  componentWillMount: ->
    appStore.actions.setTitle('Trends')

  componentWillUnmount: ->
    appStore.actions.setTitle()

  componentDidMount: ->
    if not @state.user.proxying
      metricsStore.actions.getHealthKitData()
      metricsStore.actions.getGoogleFitData()

  fetch: ->
    isPlainUser = not @state.user.proxying
    trendStores.forEach (store) ->
      args = if store is metricsStore
        {isPlainUser}
      else
        null
      store.actions.fetch(args).catch ->
        console.error 'Failed to fetch from', store
    metricsStore.actions.fetchResults()
    metricsStore.actions.fetchProviders() if config.features.newApi

  render: ->
    isLoading = @state.metricsLoading || @state.visitsLoading || immunizationsStore.isLoading

    main id: 'mainContent', className: "Content #{cx 'Content--withPatientHeader': @state.user.proxying}",
      PullToRefresh tag: section, className: 'Content-body', onRefresh: @fetch, isLoading: isLoading,
        PatientHeader()
        div className: 'ContentInner trends',
          @props.children
        Footer()
})

module.exports = observer(Trends)
