import confirm from "lib/confirm";
import authStore from "stores/new-auth";

export const unlockAccount = async () => {
  let isConfirmed = await confirm("Unlock patient's account?");
  if (isConfirmed) {
    let { user } = authStore;
    await user.unlockAccount();
  }
};
