import { isEqual, difference } from "lodash";

function* compareSlot(a, b) {
  if (+a.date != +b.date) yield "date";
  if (+a.duration != +b.duration) yield "duration";
}

function* compareSeries(a, b) {
  if (!a || !b) return;

  if (+a.startDay != +b.startDay) yield "startDay";
  if (+a.endDay != +b.endDay) yield "endDay";
  if (`${a.daysActive}` != `${b.daysActive}`) yield "daysActive"; // TODO: use `isEqual` after we update MobX
}

function* compareShared(a, b) {
  if (a.appointmentType.id != b.appointmentType.id) yield "appointmentType";
  if (a.maxPatients != b.maxPatients) yield "maxPatients";
  if (a.maxOverbook != b.maxOverbook) yield "maxOverbook";
  if (a.visibility != b.visibility) yield "visibility";
  if (a.restrictedTo != b.restrictedTo) yield "restrictedTo";
  if (+a.startTime != +b.startTime) yield "startTime";
  if (+a.duration != +b.duration) yield "duration";
}

function* compareMultipleAppts(a, b) {
  let aLength = (a && a.length) || 0;
  let bLength = (b && b.length) || 0;

  let statement = "";

  if (aLength !== bLength || a.find((a1) => !b.includes(a1))) {
    statement = "multipleAppointments";
  } else {
    statement = "";
  }

  if (statement === "multipleAppointments") yield "multipleAppointments";
}

const computeChanges = (a, b) => {
  let slot = [...compareSlot(a, b)];
  let series = [...compareSeries(a.series, b.series)];
  let shared = [...compareShared(a, b)];
  let multipleAppts = [
    ...compareMultipleAppts(
      a.multipleAppointmentTypeIDs,
      b.multipleAppointmentTypeIDs
    ),
  ];

  let type = (() => {
    if (a.isRepeated) {
      if (b.isRepeated) {
        if (series.length || shared.length || multipleAppts.length)
          return "SERIES_UPDATED";
      } else {
        return "SERIES_DELETED";
      }
    } else {
      if (b.isRepeated) return "SERIES_CREATED";
      if (slot.length || shared.length || multipleAppts.length)
        return "SLOT_UPDATED";
    }
  })();

  let isConflicted = (() => {
    if (!b.appointments.length) return false;

    let isVisibilityOnly = isEqual(shared, ["visibility"]);
    if (isVisibilityOnly) return false;

    let isCapacityOnly = !difference(shared, ["maxPatients", "maxOverbook"])
      .length;
    return isCapacityOnly ? b.isOverfilled : true;
  })();

  return {
    type,
    isConflicted,
    slot: b,
    series: type == "SERIES_DELETED" ? a.series : b.series,
  };
};

export default computeChanges;
