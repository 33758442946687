import moment from "moment";

const KEYS = ["round", "ceil", "floor"];

const dateMethods = KEYS.reduce((methods, key) => {
  return {
    ...methods,
    [key](to, unit) {
      let num = this.get(unit);
      let res = Math[key](num / to) * to;

      return this.clone().set(unit, res).startOf(unit);
    },
  };
}, {});

const durationMethods = KEYS.reduce((methods, key) => {
  return {
    ...methods,
    [key](to, unit) {
      let num = this.as(unit);
      let res = Math[key](num / to) * to;

      return moment.duration(res, unit);
    },
  };
}, {});

Object.assign(moment.fn, dateMethods);
Object.assign(moment.duration.fn, durationMethods);
