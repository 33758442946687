utils = require 'lib/utils'
{ authStore } = require 'stores'
synopsisStore = require('stores/new-synopsis')
ActionDropdown = require 'components/elements/ActionDropdown'

{cx} = Exim.helpers
{div, form, input, label, span, tch, fa, strong, tr, td} = Exim.DOM

TIMEOUT    = 300
ESCAPE_KEY = 27
LG_DOWN    = 992

Document = Exim.createView module.id,
  propTypes:
    data:     React.PropTypes.object
    onSelect: React.PropTypes.func
    onEdit:   React.PropTypes.func
    onRename: React.PropTypes.func
    onCancel: React.PropTypes.func
    selected: React.PropTypes.bool
    editing:  React.PropTypes.bool

  contextTypes:
    router: React.PropTypes.object.isRequired

  componentDidMount: ->
    if @props.editing
      @refs.input.focus()

  componentDidUpdate: ->
    if @props.editing
      @refs.input.focus()

  saveTitle: (evt) ->
    evt.preventDefault()
    text = @refs.input.value
    @props.onRename(@props.data, text)

  endOfText: (evt) ->
    evt.currentTarget.value = evt.currentTarget.value

  onTitleClick: ->
    {data} = @props
    return if data?.deleted
    @props.onSelect(data, true)
    @context.router.push "/documents/#{data?.id}"

  prevent: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()

  stopPropagation: (evt) ->
    evt.stopPropagation()

  onSelect: ->
    @props.onSelect(@props.data)

  onDoubleClick: ->
    @props.onSelect(@props.data, true)
    @props.onEdit(@props.data)

  handleClicks: (evt) ->
    @prevent(evt)
    @clicks = 0 unless @clicks

    @clicks += 1
    if @clicks is 1
      setTimeout =>
        if @clicks is 1
          @onTitleClick()
        else
          @onDoubleClick()
        @clicks = 0
      ,
        TIMEOUT

  edit: ->
    @props.onEdit(@props.data)

  handleKeyUp: (evt) ->
    if evt.keyCode is ESCAPE_KEY
      @props.onCancel(@props.data)

  displayTZ: (user) ->
    return if user.clinic_id == null
    site = synopsisStore.findSite(user.clinic_id)
    
    return if site then utils.timezone(site.zone) else ''

  render: ->
    {selected, editing, onEdit, onCancel, data, showCheckbox} = @props
    {id, title, mime, size, created_at, deleted, unverified} = data
    user = authStore.get('user')
    opacity = if showCheckbox then 1 else 0

    viewOption = if document.body.clientWidth < LG_DOWN then 'View' else 'View Document'
    size = if size isnt null then " (#{utils.humanizeSize size})"
    args = {className: "TableBody-row DocumentsListItem #{cx {selected, editing, deleted}}", handler: @onTitleClick, tagName: 'tr'}

    dropdownActions = [
      {name: viewOption, handler: @onTitleClick, description: "View #{title} document"}
      {name: 'Download', handler: @props.onDownload, description: "Download #{title} document"}
      {name: 'Rename', handler: @edit, description: "Rename #{title} document"}
    ]

    dropdownActions.push({name: 'Delete', handler: @props.onRemove}) if user.admin or user.proxying

    tch args,
      if deleted
        div className: 'TableBody-column DocumentsListItem-title u-1of4',
          strong 'Deleted: '
          span title
      else if editing
        div className: 'TableBody-column u-1of3',
          form className: 'DocumentsListItem-renameForm', onSubmit: @saveTitle,
            input 'aria-label': "Rename #{title}", className: 'Input u-flexChild Input--noMargin DocumentsListItem-renameFormInput', type: 'text', defaultValue: title, ref: 'input', autoFocus: true, onBlur: @saveTitle, onFocus: @endOfText, onClick: @prevent, onTouchEnd: @stopPropagation, onKeyUp: @handleKeyUp
      else
        td className: 'TableBody-column DocumentsListItem-title u-1of4',
          span onClick: @handleClicks, title
      td className: 'TableBody-column DocumentsListItem-type u-1of4',
        div className: 'type-name',
          utils.humanizeMime mime
          size
      td className: 'TableBody-column DocumentsListItem-dateTime u-1of4 u-textLeft',
        utils.formatDateNicely(created_at, false, true)
        " "
        @displayTZ(user)
      td className: 'TableBody-column DocumentsListItem-actions u-1of4 u-noselect c-dropdown',
        if deleted
          tch className: 'DocumentsListItem-clear', handler: @props.onErase,
            fa 'times'
        else
          ActionDropdown
            className: 'ActionDropdown--gray ActionDropdown--zoom'
            values: dropdownActions
            withGlobalClick: true
            needsTwoTabs: true
            elementId: data.id

module.exports = Document
