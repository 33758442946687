import withDirty from "./with-dirty";

const Input = withDirty(
  (props) => {
    let { className = "", inputRef, ...inputProps } = props;

    return (
      <input
        ref={inputRef}
        className={`NewInput ${className}`}
        {...inputProps}
      />
    );
  },
  { type: "onFocus" }
);

export default React.forwardRef((props, ref) => {
  return <Input {...props} inputRef={ref} />;
});
