request       = require 'lib/request'
synopsisStore = require('stores/new-synopsis')
authStore = require 'stores/auth'
{timezone} = require 'lib/utils'

module.exports = store = Exim.createStore
  actions: [
    'fetch'
    'clear',
    'displayTZ'
  ]

  initial: ->
    labs: []
    labsLoading: true,
    zoneAbbr: ''

  fetch:
    while: (labsLoading) ->
      @set {labsLoading}
    on: ->
      request.get('labs')
    did: (labs) ->
      @set {labs}

  clear: ->
    @set labs: []

  displayTZ: ->
    zoneAbbr = ''
    user = authStore.get('user')

    if user
      site = synopsisStore.findSite(user.clinic_id)
      zoneAbbr = if site then timezone(site.zone) else ''

    @set zoneAbbr: zoneAbbr
