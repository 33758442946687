"use strict";
const { observer } = require("mobx-react");
const Select = require("components/elements/Select");
const synopsisStore = require("stores/new-synopsis");
const calStore = require("stores/cal");
const { isCordova } = require("config");

const optgroup = (params) => ({
  optgroup: true,
  clickable: true,
  ...params,
});

function* getFilterOptions() {
  let { providerTypes, site } = synopsisStore;

  yield optgroup({
    label: "Active Providers",
    value: JSON.stringify({ type: "ACTIVE_PROVIDERS" }),
  });

  yield optgroup({
    label: "All Providers",
    value: JSON.stringify({ type: "ALL_PROVIDERS" }),
  });

  for (let provType of providerTypes) {
    let providers = site.getProvidersForType(provType.id);
    if (!providers.length) continue;

    yield optgroup({
      label: provType.name,
      value: JSON.stringify({
        type: "BY_PROVIDER_TYPE",
        value: provType.id,
      }),
    });

    for (let prov of providers) {
      yield {
        label: prov.name,
        value: JSON.stringify({
          type: "BY_PROVIDER_ID",
          value: prov.id,
        }),
      };
    }
  }
}

class ProviderNav extends React.Component {
  componentWillMount() {
    synopsisStore.fetchProviderTypes();
  }

  render() {
    let select = Select({
      options: [...getFilterOptions()],
      value: JSON.stringify(calStore.providerFilter),
      matchProp: "label",
      clearable: false,
      backspaceRemoves: true,
      placeholder: "Select provider",
      searchable: !isCordova,
      savedHover: true,
      onChange: ({ value }) => {
        calStore.changeProviderFilter(JSON.parse(value));
      },
    });

    return <div className="ScheduleCalendarProviderNav">{select}</div>;
  }
}

module.exports = observer(ProviderNav);
