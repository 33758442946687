import { toJS } from "mobx";
import * as SurveyJS from "survey-react";

class MiniScreening extends React.Component {
  constructor(props) {
    super(props);
  }

  onValueChange(data, totalQuestions) {
    this.forceUpdate();
  }

  render() {
    const { model, expanded } = this.props;

    if (!expanded) return null;

    return (
      <div id="MiniScreening">
        <section id="SurveyJS">
          <SurveyJS.Survey
            model={toJS(model)}
            onValueChanged={this.onValueChange.bind(this)}
          />
        </section>
      </div>
    );
  }
}

module.exports = MiniScreening;
