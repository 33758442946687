import Site from "models/site";
import Provider from "models/provider";
import authStore from "stores/new-auth";
import synopsisStore from "stores/new-synopsis";
import careTeamsStore from "stores/care-teams";
import { computed } from "lib/utils";
import request from "lib/new-request";
import { ISO_DATE } from "lib/formats";
import { observable, autorun } from "mobx";
import { isEmpty, first, groupBy, map } from "lodash";
import { toJS } from "mobx";

const joinIds = (providers) => {
  let byName = groupBy(providers, "name");

  return map(byName, (providers) => {
    let provider = first(providers).clone();
    provider.id = providers.map((prov) => prov.id).join();
    return provider;
  });
};

const ANY_SITE = new Site({
  id: "__any__",
  name: "Any Location",
});

computed(ANY_SITE, {
  get bookableApptTypes() {
    return synopsisStore.bookableApptTypes;
  },

  get providers() {
    return synopsisStore.providers;
  },
});

const ANY_PROVIDER = new Provider({
  id: "__any__",
  name: "Any Provider",
});

const scheduleStore = observable({
  searchBy: "location",
  siteId: null,
  apptType: null,
  providerId: null,
  isAnyCtProvider: false,
  isProfilePage: false,
  changeApptSelect: false,
  pcpsProviderProfiles: [],

  _month: null,
  days: [],
  isFetchingDays: false,

  day: null,
  slots: [],
  isFetchingSlots: false,

  _providersProfiles: new Map(),

  async logEvent() {
    let response = await request.post("/v1/appointments/log_start", {});
    return response;
  },

  async fetchProvidersProfiles() {
    let profiles = await request.get("/v1/provider-profiles");
    this._providersProfiles = new Map(Object.entries(profiles));
  },

  async fetchDays() {
    this.isFetchingDays = true;
    try {
      let days = await request.get("/v1/capacity", {
        month: this.month.format(ISO_DATE),
        ...this._fetchParams,
      });

      this.days = days.map((day) => {
        return moment.tz(day, "YYYYMMDD", this.site.zone);
      });
    } catch {
      this.days = [];
    } finally {
      this.isFetchingDays = false;
    }
  },

  async fetchSlots() {
    if (!this.day) return;

    this.isFetchingSlots = true;
    try {
      let slots = await request.get("/v1/timeslots", {
        date: this.day.format(ISO_DATE),
        ...this._fetchParams,
      });

      // TODO: Slot.fromAPI
      slots.forEach((slot) => {
        slot.site = synopsisStore.findSite(slot.clinic_id);
        slot.startAt = moment.tz(slot.start_at, slot.site.zone);
        slot.isInternal = !!slot.type || slot.visibility == "internal";
      });

      this.slots = slots;
    } catch {
      this.slots = [];
    } finally {
      this.isFetchingSlots = false;
    }
  },

  setIsCtOnly(value) {
    this.isAnyCtProvider = value;
  },

  setIsProfilePage(value) {
    this.isProfilePage = value;
  },

  setPcpProviderProfiles(value) {
    this.pcpsProviderProfiles = value;
  },

  setSearchBy(value) {
    this.clear();
    this.searchBy = value;
  },

  setDeepLinkApptType(apptTypeId) {
    const appointmentType = this.apptTypes.find((aT) => aT.id === apptTypeId);
    if (appointmentType) this.apptType = appointmentType;
  },

  clear() {
    this.siteId = null;
    this.apptType = null;
    this.providerId = null;
    this._month = null;
    this._day = null;
  },
});

computed(scheduleStore, {
  get sites() {
    let byApptType = (site) => {
      return site.apptTypes.some((aT) => !!aT && aT.id == this.apptType.id);
    };

    let sites = [ANY_SITE, ...synopsisStore.sites];
    if (
      (this.searchBy == "appointment_type" ||
        this.searchBy == "virtual_method") &&
      this.apptType
    ) {
      sites = sites.filter(byApptType);
    }

    return sites;
  },

  get site() {
    return (
      this.sites.find((site) => site.id == this.siteId) ||
      (this.searchBy == "location" ? authStore.user.defaultSite : ANY_SITE) ||
      synopsisStore.site
    );
  },

  get careTeamProviders() {
    if (!this.apptType) return [];

    const careTeams = toJS(careTeamsStore.providerCareTeams);
    const careTeamProviders = Object.keys(careTeams).map((i) => careTeams[i]);

    const filteredCTs = careTeamProviders.filter((CT) => {
      return CT.uses.some((aTId) => {
        return aTId == this.apptType.id;
      });
    });

    return filteredCTs;
  },

  get apptTypes() {
    let { user } = authStore;
    let byGender = (aT) => (user.isFemale ? true : aT.id != 11688);
    /*
    NOTE: this.site is not guarenteed to exist, especially when searching All Locations
    So we sort through all sites to get the order of modalities, then sort appt types appropriately
    This is a fast, not the right, solution. The right solution is filtering by a map of modalities and their order
    */
    // Sort through each site and record their modalities *in order*
    let modalities = [];

    careTeamsStore.fetchCareTeams(authStore.user.id);

    this.sites.forEach((site) => {
      const mods = site.modalities
        .filter((mod) => !!mod.name)
        .map((mod) => mod.name);
      mods.forEach((name, i) => {
        // If the merged array doesn't have this modality...
        if (modalities.indexOf(name) < 0) {
          // Check for the preceding name for this particular site, add this to the merged set *after* its predecessor
          if (i >= 1) {
            const prevModName = mods[i - 1];
            const prevModIndex = modalities.indexOf(prevModName);
            modalities.splice(prevModIndex + 1, 0, name);
          } else {
            modalities.unshift(name);
          }
        }
      });
    });
    // Ensure "internal" groups are always at the end - https://stackoverflow.com/a/41507374
    modalities.push(
      ...modalities.splice(
        modalities.findIndex((mod) => mod.toLowerCase().includes("internal")),
        1
      )
    );
    let appointmentTypes = this.site.bookableApptTypes
      .filter(byGender)
      .filter((aT) => !!aT.modality) // Appointments without a modality are for sites not visible to the user)
      .sort((a, b) => {
        const diff =
          modalities.indexOf(a.modality.name) -
          modalities.indexOf(b.modality.name);
        if (diff === 0) return a.name.localeCompare(b.name);
        return diff;
      });

    if (this.searchBy == "virtual_method") {
      appointmentTypes = appointmentTypes.filter((appt) => {
        return (
          appt.method === "phone" ||
          appt.method === "virtual" ||
          appt.method === "video"
        );
      });
    }

    return appointmentTypes;
  },

  get providers() {
    if (!this.apptType) return [];

    let byApptType = (prov) => {
      return prov.apptTypes.some((aT) => !!aT && aT.id == this.apptType.id);
    };

    const pcpID = authStore.user.xo_physician_global_id;
    let providers = this.site.providers.filter(byApptType);

    if (
      this.searchBy == "appointment_type" ||
      this.searchBy == "virtual_method"
    )
      providers = joinIds(providers);
    if (providers.length > 1) {
      if (synopsisStore.globalIdAsPCP) {
        const PCP_provider = providers.find((provider) => {
          if (pcpID === provider.globalId) {
            const PCP = new Provider({
              id: provider.id,
              shot: provider.shot,
              name: provider.name,
            });

            return PCP;
          }
        });

        providers = [PCP_provider || ANY_PROVIDER, ANY_PROVIDER, ...providers];
        const providerSet = Array.from(new Set(providers));
        providers = [...providerSet];
      } else {
        providers = [ANY_PROVIDER, ...providers];
      }
      if (pcpID === "dr-community-provider" || pcpID === "dr-declined") {
        providers = [
          ANY_PROVIDER,
          ...providers.filter(({ id }) => id !== ANY_PROVIDER.id),
        ];
      }
    }

    return providers;
  },

  get anyProvider() {
    return ANY_PROVIDER;
  },

  get provider() {
    let itemProviderId;
    if (!isEmpty(this.providers)) {
      this.changeApptSelect
        ? (itemProviderId = first(this.providers).id)
        : (itemProviderId = this.providerId);

      return (
        this.providers.find((prov) => prov && prov.id === itemProviderId) ||
        first(this.providers)
      );
    }

    return null;
  },

  get month() {
    return this._month || this.site.today.startOf("month");
  },

  set month(date) {
    this._month = date;
  },

  get pcpProviderProfiles() {
    return toJS(this.pcpsProviderProfiles);
  },

  get profilePageStatus() {
    return this.isProfilePage;
  },

  get providersProfiles() {
    let providers;
    !this.isProfilePage
      ? (providers = this.providers)
      : (providers = synopsisStore.providers);

    return providers
      .filter((prov) => {
        return this._providersProfiles.has(prov.globalId);
      })
      .map((prov) => {
        let id = prov.globalId;

        return {
          providerName: prov.name,
          providerBio: this._providersProfiles.get(id).content,
          avatarUrl: prov.shot,
          globalId: id,
        };
      });
  },

  get _fetchParams() {
    let paramProviderId;
    this.isAnyCtProvider
      ? (paramProviderId = this.apptType.id)
      : (paramProviderId = this.provider.isAny
          ? this.apptType.id
          : this.provider.id);

    let params = {
      appointment_type: this.apptType.id,
      provider_id: paramProviderId,
      primary_search_field:
        this.searchBy === "virtual_method" ? "appointment_type" : this.searchBy,
      care_team_only: this.isAnyCtProvider,
    };

    if (!this.site.isAny) {
      params.site_code = this.site.code;
    }

    return params;
  },
});

autorun(() => {
  scheduleStore.fetchDays();
});

autorun(() => {
  scheduleStore.fetchSlots();
});

export default scheduleStore;
