marked = require 'marked'
config = require 'config'

module.exports = {
  splitItems: (text) ->
    allItems = []
    items = []
    currentToken = null
    lastItem = []

    tokens = marked.lexer(text)

    tokens.forEach (token, i) ->
      if token.type is 'heading' and token.depth is 2
        currentToken = token
        if lastItem.length
          allItems.push lastItem
          lastItem = []

      if currentToken
        lastItem.push token

      if i is tokens.length-1 and lastItem.length
        allItems.push lastItem

    allItems.forEach (item) ->
      heading = item[0].text

      matches = heading.match(/\(.*\)$/g)
      [ title, date ] = if matches?.length
        dateMatch = matches[matches.length - 1]
        [heading.replace(dateMatch, '').replace(/^Patient/, '').trim(), dateMatch.replace(/[()]/g, '')]
      else
        [heading, '']

      item.shift()
      item.links = true #workaround for parser
      content = marked.parser(item)
      content = content.replace(/\/combo/g, 'combo') if config.isCordova

      data = { date, title: title.replace(/_/g, ''), content }
      items.push data

    items

  trimStringToWord: (str, maxLength=117) ->
    return str if str.length < maxLength
    trimmed = str.substr(0, maxLength)
    trimmed = trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(' '))) + '...'

  getPreview: (text) ->
    currentToken = null
    item = []
    texts = []
    previewText = null

    tokens = marked.lexer(text)

    tokens.every (token, i) ->
      if token.type is 'heading' and token.depth is 2
        currentToken = token
        if item.length
          return false

      if token.type is 'text' or token.type is 'paragraph' and not previewText and token.text[0] isnt '!'
        texts.push token.text

      if currentToken
        item.push token

      if i is tokens.length-1 and item.length
        return false

      return true

    heading = item[0]?.text
    matches = heading?.match(/\(.*\)$/g)
    [ title, date ] = if matches?.length
      dateMatch = matches[matches.length - 1]
      [heading.replace(dateMatch, '').replace(/^Patient/, '').trim(), dateMatch.replace(/[()]/g, '')]
    else
      [heading, '']

    item.shift()
    previewText = @trimStringToWord(texts.join('\n').replace(/\*/g,'')).split('\n').map((i) -> "<p>#{i}</p>").join('')

    {date, title: title.replace(/_/g,'', -1), content: previewText}
}
