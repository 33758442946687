{appStore, patientStore} = require 'stores'
{div, b, button, a, ul, li, p} = Exim.DOM

module.exports = (email, user_id = null) ->
  new Promise (resolve, reject) ->
    closeModal = ->
      appStore.actions.showModal(null)
      reject()

    clearAccount = ->
      patientStore.actions.clearAccount(user_id).then ->
        appStore.actions.showModal(null)
        resolve('clear')

    unrestrictAccount = ->
      patientStore.actions.unrestrictAccount(user_id).then ->
        appStore.actions.showModal(null)
        resolve('unrestrict')

    body = div null,
      p 'Make certain to:'
      ul null,
        li 'Verify the patient’s identity (employee badge, identification, etc.)'
        li 'Confirm this is their email address:'
      p className: 'VerifyPatientModal-email', email
      p null,
        'If the email address is correct, select'
        b ' Verify.'
        ' Otherwise select'
        b ' Clear Account.'
      div className: 'VerifyPatientModal-buttons',
        button className: 'VerifyPatientModal-button VerifyPatientModal-button--cancel', onClick: closeModal,
          'Cancel'
        button className: 'VerifyPatientModal-button VerifyPatientModal-button--clear', onClick: clearAccount,
          'Clear Account'
        button className: 'VerifyPatientModal-button VerifyPatientModal-button--unrestrict', onClick: unrestrictAccount,
          'Verify'

    appStore.actions.showModal {
      className: 'VerifyPatientModal'
      title: 'This patient has not yet had their identity verified'
      body,
      hideBackground: true
    }
