import Model from "./base";
import synopsisStore from "stores/new-synopsis";
import wellnessStore from "stores/wellness";
import oldAuthStore from "stores/auth";
import { compact, mapValues, isEmpty } from "lodash";
import { onlyDate, getAvatarUrl, computed } from "lib/utils";
import { ISO_DATE } from "lib/formats";
import { formats, nonUS } from "config";

const getAgeUnit = (dur) => {
  if (dur.asYears() >= 2) return "years";
  if (dur.asMonths() >= 3) return "months";
  if (dur.asWeeks() >= 4) return "weeks";
  if (dur.asDays() >= 2) return "days";

  return "day";
};

class User extends Model {
  static fromAPI(raw, opts = {}) {
    // TODO: synopsisStore.findSite(raw.clinic_id)
    let { site, client } = synopsisStore;

    return super.fromAPI(
      {
        id: raw.id,
        employeeId: raw.employee_id,
        patientId: raw.patient_id,
        xoId: raw.xoid,
        firstName: raw.first_name,
        middleName: raw.middle_name,
        lastName: raw.last_name,
        preferredName:
          raw.preferred_name == raw.first_name ? "" : raw.preferred_name,
        name: raw.name || raw.full_name,
        legalAndPreferredName: raw.legal_and_preferred_name,
        full_name: raw.full_name,
        bornAt: onlyDate(moment.tz(raw.born_at, site.zone)),
        shortGender: (raw.gender || "").charAt(),
        gender_identity: raw.gender_identity,
        pronouns: raw.pronouns,
        _defaultSiteCode: raw.default_site,
        client: client == "nearsite" || client == "microsoft" ? raw.client : "",
        locked: raw.locked,
        avatarURL: getAvatarUrl(raw.avatar_url),
        email: raw.email,
        status: raw.status,
        jail: raw.jail,
        pcp: raw.pcp,
        xo_physician_global_id: raw.xo_physician_global_id,
        ehrChartURL: raw.ehr_chart_url,
        covidRiskScore: raw.covid_risk_score,
        educationMaterialURL: raw.education_material,
        xop_eligible: raw.xop_eligible,
        efId: raw.ef_id,
        employer: raw.employer,
        is_kaiser_restricted: !!raw.is_kaiser_restricted,

        isAdmin: !!raw.admin,
        isConsented: !!raw.consented,
        isVerified: !!raw.verified,
        isProxying: !!raw.proxying, // TODO: remove
        isTraveler: !!raw.traveler,
        isAsleep: !!raw.asleep,
        isActive: !raw.inactive,
        isAccountLocked: !!raw.account_locked,
        isAccountUnlockable: !!raw.account_unlockable,
        hasAcknowledgedCookies: !!raw.cookies_acknowledged,
        canAccessVIPs: !!raw.vips,
        migratedToXOP: raw.migrated_to_xop,
        isMinor: raw.minor,

        medicalPlan: raw.pair,
        preferredLanguage: raw.preferred_language,
        genderIdentity: raw.gender_identity,
        sexualOrientation: raw.sexual_orientation,
        pronouns: raw.pronouns,
        address: raw.address,
        city: raw.city,
        state: raw.state,
        zip: raw.zip,
        homePhone: raw.home_phone,
        cellPhone: raw.cell_phone,
        workPhone: raw.work_phone,
        hasSMSReminders: raw.cell_texts,
        primaryContact: raw.primary_contact,
        can_see_insurance: raw.can_see_insurance,
        can_see_sogi: raw.can_see_sogi,
        can_see_real: raw.can_see_real,
        race: raw.race,
        ethnicity: raw.ethnicity,
        emergencyContact: {
          name: raw.emergency_name,
          relationship: raw.emergency_relationship,
          phone: raw.emergency_phone,
        },
        insurance: {
          plan: raw.insurance_plan,
          holder: raw.insurance_holder,
          id: raw.insurance_id,
        },
        isMicrosoftPatient: raw.is_microsoft_user,
      },
      opts
    );
  }

  constructor(params) {
    super({
      searchHistory: [],
      bornAt: moment(0),
      client: "",
      emergencyContact: {},
      insurance: {},
      insurances: {},
      isFetchingInsurance: false,
      ...params,
    });
  }

  get _age() {
    let dur = moment.duration(this.defaultSite.today - this.bornAt);
    let unit = getAgeUnit(dur);
    let int = Math.floor(dur.as(unit)) || 1;
    return { int, unit };
  }

  get age() {
    let { int, unit } = this._age;
    return `${int} ${unit}`;
  }

  get shortAge() {
    let { int, unit } = this._age;
    let [char] = unit;
    return `${int}${char}`;
  }

  async acknowledgeCookies() {
    await this.patch({
      data: {
        cookies_acknowledged: true,
      },
    });
  }

  async unlockAccount() {
    if (!this.isAccountUnlockable) return;

    await this.post({
      path: "/v1/patient/admin",
      data: {
        type: "unlock",
      },
      update: false, //
    });

    this.isAccountLocked = false;
    this.isAccountUnlockable = false;
  }

  async fetchSearchHistory() {
    let Patient = require("models/patient");
    let { results } = await this.get({
      path: "&/patient-quick-list",
    });

    this.searchHistory = results.map((raw) => Patient.fromAPI(raw));
  }

  async update(params) {
    let { defaultSite, medicalPlan, ...rest } = params;

    await this.put({
      path: "/v1/user",
      data: {
        clinic_id: (defaultSite || {}).id,
        pair: medicalPlan,
        ...rest,
      },
      update: false,
    });

    Object.assign(this, params);
    oldAuthStore.actions.fetchUser();
  }

  async fetchInsurance() {
    try {
      this.isFetchingInsurance = true;
      this.insurances = await this.get({
        path: "&/insurance",
      });
    } finally {
      this.isFetchingInsurance = false;
    }
  }

  async saveInsurance() {
    await this.put({
      path: "&/insurance",
      data: mapValues(this.insurances, (val) => val || null),
      update: false,
    });
  }

  canAccess(patient) {
    return patient.locked ? this.canAccessVIPs : this.isAdmin;
  }

  toJSON() {
    return {
      id: this.id,
      employee_id: this.employeeId,
      patient_id: this.patientId,
      xoid: this.xoId,
      name: this.name,
      first_name: this.firstName,
      middle_name: this.middleName,
      last_name: this.lastName,
      preferred_name: this.preferredName,
      full_name: this.name,
      legal_and_preferred_name: this.legalAndPreferredName,
      born_at: this.bornAt.format(ISO_DATE),
      gender: this.gender,
      gender_identity: this.gender_identity,
      pronouns: this.pronouns,
      default_site: this._defaultSiteCode,
      client: this.client,
      locked: this.locked,
      avatar_url: this.avatarURL,
      email: this.email,
      status: this.status,
      jail: this.jail,
      xo_physician_global_id: this.xo_physician_global_id,
      pcp: this.pcp,
      ehr_chart_url: this.ehrChartURL,
      covid_risk_score: this.covidRiskScore,
      education_material: this.educationMaterialURL,

      admin: this.isAdmin,
      consented: this.isConsented,
      verified: this.isVerified,
      proxying: this.isProxying,
      traveler: this.isTraveler,
      asleep: this.isAsleep,
      inactive: !this.isActive,
      account_locked: this.isAccountLocked,
      account_unlockable: this.isAccountUnlockable,
      cookies_acknowledged: this.hasAcknowledgedCookies,
      vips: this.canAccessVIPs,
      migrated_to_xop: this.migratedToXOP,

      pair: this.medicalPlan,
      preferred_language: this.preferredLanguage || undefined,
      gender_identity: this.genderIdentity,
      sexual_orientation: this.sexualOrientation,
      pronouns: this.pronouns,
      address: this.address,
      city: this.city,
      state: this.state,
      zip: this.zip,
      home_phone: this.homePhone,
      cell_phone: this.cellPhone,
      work_phone: this.workPhone,
      cell_texts: this.hasSMSReminders,
      primary_contact: this.primaryContact,
      race: this.race,
      ethnicity: this.ethnicity,

      emergency_name: this.emergencyContact.name,
      emergency_relationship: this.emergencyContact.relationship,
      emergency_phone: this.emergencyContact.phone,

      insurance_plan: this.insurance.plan,
      insurance_holder: this.insurance.holder,
      insurance_id: this.insurance.id,
      can_see_insurance: this.can_see_insurance,
      can_see_sogi: this.can_see_sogi,
      can_see_real: this.can_see_real,
      xop_eligible: this.xop_eligible,
    };
  }
}

User.endPoint = "/v1/users";

computed(User.prototype, {
  get canUnlockAccount() {
    return this.isAccountLocked && this.isAccountUnlockable;
  },

  get isVIP() {
    return this.locked == "vip";
  },

  get isXO() {
    return this.locked == "xo";
  },

  get dob() {
    return this.bornAt.format(formats.dob);
  },

  get shortDob() {
    return this.bornAt.format(formats.shortDob);
  },

  get gender() {
    const isCork = this._defaultSiteCode == 'crk';
    const isSingapore = this._defaultSiteCode in ['amk', 'inn'];
    const defaultResponse = (isCork || isSingapore) ? "Unknown" : '';

    switch (this.shortGender) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      case "I":
        return "Intersex";
      case "O":
        return "Other";
    }
      return defaultResponse;
  },

  get isFemale() {
    return this.shortGender == "F";
  },

  get shortClient() {
    return this.client.replace(/\s*\(.+\)\s*/, "");
  },

  get shortInfo() {
    const BULLET = "\u2022";

    return compact([
      this.name,
      `${this.dob} ${BULLET} ${this.age}`,
      this.patientId && `#${this.patientId}`,
    ]).join("\n");
  },

  get hasSearchHistory() {
    return this.searchHistory.length > 0;
  },

  get defaultSite() {
    return (
      synopsisStore.sites.find((site) => site.code == this._defaultSiteCode) ||
      synopsisStore.site
    );
  },

  set defaultSite(site) {
    this._defaultSiteCode = site.code;
  },

  get longName() {
    return this.preferredName
      ? `${this.preferredName} ${this.lastName} (${this.firstName})`
      : `${this.firstName} ${this.lastName}`;
  },
});

const NO_TRENDS = 1;
const NO_CHECKIN_OR_DAILY_SCHEDULE = 2;
const NO_APPTS = 4;
const NO_MSGS = 8;
const NO_MEDS = 16;
const NO_LABS = 32;
const NO_DOCUMENTS = 64;
const NO_APPT_HISTORY = 128;
const HUSHED_MSGS = 256;
const NO_INTERNAL_APPTS = 512;
const MULTISITE_ADMIN = 1024;
const DISABLE_CHECKIN_WITH_DAILY_SCHEDULE = 2048;
const CAPACITY_ACCESS = 4096;
const CAPACITY_RESTRICT_WRITE_ACCESS = 8192;
const NO_PREFLIGHT = 16384;
const READ_ONLY_MSGS = 32768;
const REACTIVATE_INELIGIBLES = 65536;
const SCREENINGS_ACCESS = 131072;

const LABS_SITES = [
  "scv",
  "prk",
  "wfe",
  "sac",
  "mtv",
  "stm",
  "mld",
  "fst",
  "red",
  "eye",
  "vfc",
  "gop",
  "mil",
  "cos",
  "nyc",
  "rgs",
  "mfh",
  "fbc",
  "sma",
  "bee",
  "hpo",
  "amp",
  "glo",
  "nbp",
  "atx",
  "spr",
  "csp",
  "ast",
  "cpt",
  "lcs",
  "mtc",
  "mpk",
  "kds",
  "ahc",
  "asg",
  "asd",
  "asv",
  "apm",
  "ats",
  "apt",
  "alr",
  "alj",
  "als",
  "awl",
  "aro",
  "abt",
  "asb",
  "afd",
  "aev",
  "xct",
  "xow",
  "xoe",
  "xne",
];

computed(User.prototype, {
  get canReadSchedule() {
    return this.isAdmin && !(this.jail & NO_CHECKIN_OR_DAILY_SCHEDULE);
  },

  get canReadCalendar() {
    return (
      synopsisStore.hasScheduling &&
      this.isAdmin &&
      !!(this.jail & CAPACITY_ACCESS)
    );
  },

  get canReadScreenings() {
    return this.isAdmin && !!(this.jail & SCREENINGS_ACCESS);
  },

  get canViewWellnessWidget() {
    return !this.isAdmin && !isEmpty(wellnessStore.activePrograms);
  },

  get canEditCalendar() {
    return (
      this.canReadCalendar && !(this.jail & CAPACITY_RESTRICT_WRITE_ACCESS)
    );
  },

  get canReadTrends() {
    return !this.isAdmin && !(this.jail & NO_TRENDS);
  },

  get canReadAppointments() {
    return (
      !this.isAdmin &&
      !(this.jail & NO_APPTS) &&
      (this.isProxying || !this.isTraveler)
    );
  },

  get canReadMessages() {
    return !(this.jail & NO_MSGS);
  },

  get canReadDocuments() {
    return !(this.jail & NO_DOCUMENTS);
  },

  get canReadMedications() {
    return (
      !this.isAdmin &&
      !(this.jail & NO_MEDS) &&
      !nonUS.includes(this._defaultSiteCode)
    );
  },

  get canReadLaboratories() {
    return (
      !this.isAdmin &&
      !(this.jail & NO_LABS) &&
      LABS_SITES.includes(this._defaultSiteCode)
    );
  },

  get canPreflight() {
    return (
      !(this.jail & DISABLE_CHECKIN_WITH_DAILY_SCHEDULE) &&
      !(this.jail & NO_PREFLIGHT)
    );
  },

  get canReactivateIneligibles() {
    return (
      (this.isAdmin || this.isProxying) &&
      !!(this.jail & REACTIVATE_INELIGIBLES)
    );
  },

  get isEligible() {
    return this.jail != 32790;
  },
});

module.exports = User;
