'use strict'
{div, p, b, input, footer, button} = Exim.DOM
{Modal} = require 'components/elements/new-modal'
Note = require 'models/appointment-note'

class AddNoteModal extends React.Component
  constructor: (props) ->
    super(props)

    this.state = {
      text: '',
    }

  addNote: =>
    {text} = this.state
    {appointment, abort} = this.props

    note = new Note({text, appointment})
    return if !note.isSaveable

    note.post().catch((err) =>
      console.error(err)
      alert('Failed to create a note.')
    ).finally(abort)

  render: ->
    {abort, appointment} = this.props
    {text} = this.state

    return React.createElement(Modal, {
      title: 'Add Note',
      close: abort,
      className: 'AddNoteModal',
    },
      p className: 'AddNoteModal-name',
        'Patient name: '
        b null, appointment.patient.name
      input {
        className: 'AddNoteModal-text',
        placeholder: 'Note text',
        value: text,
        ref: (el) =>
          el && el.focus()
        onChange: ({target}) =>
          this.setState({text: target.value})
        onKeyUp: (evt) =>
          if evt.key == 'Enter'
            evt.preventDefault()
            this.addNote()
      }
      footer className: 'NewModal-footer',
        button {
          className: 'Button Button--cancel',
          onClick: abort,
        }, 'Close'
        button {
          className: 'Button Button--primary',
          disabled: !text.trim(),
          onClick: this.addNote,
        }, 'Save'
    )

module.exports = AddNoteModal
