import { formats } from "config";
import authStore from "stores/new-auth";
import { observer } from "mobx-react";
import { ISO_DATE } from "lib/formats";
import isEmpty from "lodash/isEmpty";
import { formatDateShort } from "lib/utils";
import { withEscapeHandler } from "components/elements/new-modal";

const PopUp = observer(
  withEscapeHandler((props) => {
    return (
      <React.Fragment>
        <div className="AppointmentsNotesModal-overlay" onClick={props.close} />
        {props.children}
      </React.Fragment>
    );
  })
);

class NotesModal extends React.Component {
  state = { newNote: "" };

  get style() {
    const { position } = this.props || {};

    return {
      top: `${position.bottom}px`,
      left: `${position.right}px`,
    };
  }

  handleChange = (evt) => {
    this.setState({ newNote: evt.target.value });
  };

  handleKeyDown = (evt) => {
    if (evt.keyCode === 13 || evt.key === "Enter") this.saveNote();
  };

  saveNote = () => {
    const { newNote } = this.state;
    const { addNote } = this.props;

    if (!newNote) return;

    addNote(newNote);
    this.setState({ newNote: "" });
  };

  renderForm() {
    const { newNote } = this.state;

    return (
      <div className="AppointmentsNotesModal-form">
        <input
          className="Input AppointmentsNotesModal-noteText"
          placeholder="New note..."
          aria-label="Note text"
          value={newNote}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        />
        <button
          className="Button AppointmentsNotesModal-addNote"
          onClick={this.saveNote}
          disabled={false}
        >
          Add Note
        </button>
      </div>
    );
  }

  renderNote = (noteObject) => {
    const { me } = authStore;
    const { deleteNote } = this.props;
    const { created_by, created_at, author_name, note, id } = noteObject;

    return (
      <tr key={id}>
        <td>
          <div className="AppointmentsNotesModal-type AppointmentsNotesModal-type--text" />
        </td>
        <td className="AppointmentsNotesModal-text" title={note}>
          {note}
        </td>
        <td>{author_name}</td>
        <td>
          <time dateTime={created_at.format(ISO_DATE)}>
            {formatDateShort(created_at, formats.notesDateFormat)}
          </time>
        </td>
        <td>
          {me.id === created_by && (
            <button
              className="AppointmentsNotesModal-deleteNote"
              aria-label="Delete note"
              onClick={() => deleteNote(noteObject)}
            />
          )}
        </td>
      </tr>
    );
  };

  render() {
    const { notes, abort } = this.props;

    return (
      <PopUp close={abort}>
        <div className="AppointmentsNotesModal" style={this.style}>
          {this.renderForm()}
          <table className="AppointmentsNotesModal-table">
            <tbody>{notes.map(this.renderNote)}</tbody>
          </table>
        </div>
      </PopUp>
    );
  }
}

export default observer(NotesModal);
