{div, form, p, a, img} = Exim.DOM
attachHeader = require 'components/mixins/attachHeader'

Browsers = Exim.createView module.id,
  mixins: [attachHeader(null)]
  render: ->
    div className: 'Splash Splash--auth',
      div className: 'BrowsersCard',
        form className: 'card-logo', ref: 'container', autoComplete: 'off',
          p 'Unfortunately your current web browser will not provide the best experience possible.'
          div className: 'BrowsersCard-text', 'If possible, please upgrade to one of the following web browsers.'
          div className: 'BrowsersCard-text', 'If you are using an employer owned computer, the Information Technology policies or workstation management may restrict your ability to upgrade or install web browsers. Please consult with your IT support team before proceeding.'
          div className: 'BrowsersCardImages',
            a href: 'https://www.google.com/chrome/browser/desktop/', className: 'BrowsersCardImages--chrome',
              img src: 'combo/images/chromelogo.png'
            a href: 'https://www.mozilla.org/firefox/new/', className: 'BrowsersCardImages--firefox',
              img src: 'combo/images/firefoxlogo.png'
            a href: 'https://www.microsoft.com/en-us/windows/microsoft-edge', className: 'BrowsersCardImages--edge',
              img src: 'combo/images/edgelogo.png'
            a href: 'https://support.microsoft.com/en-us/help/18520/download-internet-explorer-11-offline-installer', className: 'BrowsersCardImages--ie',
              img src: 'combo/images/IE11logo.png'



module.exports = Browsers
