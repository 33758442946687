import React from "react";
import { BarStackHorizontal, Circle } from "@vx/shape";
import { Group } from "@vx/group";
import { AxisBottom } from "@vx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@vx/scale";
import { withTooltip, Tooltip } from "@vx/tooltip";
import { LegendOrdinal } from "@vx/legend";

let tooltipTimeout;

export default withTooltip(
  ({
    // graph specific props
    width = 400,
    height = 280,
    margin = {
      top: 180,
      left: 50,
      right: 40,
      bottom: 50,
    },
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
    tickFormat = (tick) => (tick ? tick : "0"),
    // other props
    colors = ["#4d7c1d", "#FAAE43", "#E5001E", "#000000", "#EAEDFF", "#FF00FF"],
    data,
    keys,
    legendLabels,
    rangeThreshhold,
    xAxisLabel,
    labelClassName,
  }) => {
    // accessors
    const y = (d) => d.index;

    // scales
    const xScale = scaleLinear({
      domain: [rangeThreshhold.low, rangeThreshhold.high],
      nice: true,
    });

    const yScale = scaleBand({
      domain: data.map(y),
      padding: 0.2,
    });

    const color = scaleOrdinal({
      domain: keys,
      range: colors,
    });

    const legendColors = colors.filter((value) => value !== "#EAEDFF");
    const legend = scaleOrdinal({
      domain: legendLabels,
      range: legendColors,
    });

    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    xScale.rangeRound([0, xMax]);
    yScale.rangeRound([yMax, 0]);

    const renderTooltip = (key) => {
      if (key === "low") {
        return "Low Risk";
      } else if (key === "medium") {
        return "Medium Risk";
      } else if (key === "high") {
        return "High Risk";
      }
    };

    return (
      <div>
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <rect width={width} height={height} fill={"#EAEDFF"} rx={14} />
          <Group top={margin.top} left={margin.left}>
            <BarStackHorizontal
              data={data}
              keys={keys}
              height={yMax}
              y={y}
              xScale={xScale}
              yScale={yScale}
              color={color}
            >
              {(barStacks) => {
                return barStacks.map((barStack) => {
                  return barStack.bars.map((bar) => {
                    const {
                      bar: {
                        data: { type, average_score, your_score },
                      },
                      key,
                    } = bar;

                    if (type === "circle" && key === "your_score") {
                      return (
                        <Circle
                          key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                          cx={xScale(your_score)}
                          cy={yScale(1)}
                          r={12}
                          fill={"#000000"}
                        />
                      );
                    } else if (
                      average_score &&
                      type === "circle" &&
                      key === "average_score"
                    ) {
                      return (
                        <Circle
                          key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                          cx={xScale(average_score)}
                          cy={yScale(1)}
                          r={average_score === your_score ? 8 : 12}
                          stroke={"#FF00FF"}
                          fill={"#FF00FF"}
                        />
                      );
                    }
                    return (
                      <rect
                        key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                        x={bar.x}
                        y={bar.y}
                        width={bar.width || 0}
                        height={bar.height}
                        fill={bar.key === "threshold" ? "none" : bar.color}
                        onMouseLeave={(event) => {
                          tooltipTimeout = setTimeout(() => {
                            hideTooltip();
                          }, 300);
                        }}
                        onMouseMove={(event) => {
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);
                          const top = bar.y + margin.top;
                          const left = bar.x + bar.width + margin.left;
                          showTooltip({
                            tooltipData: bar,
                            tooltipTop: top,
                            tooltipLeft: left,
                          });
                        }}
                      />
                    );
                  });
                });
              }}
            </BarStackHorizontal>
            <AxisBottom
              top={yMax}
              scale={xScale}
              stroke={"#FD4900"}
              tickStroke={"#FD4900"}
              tickLabelProps={(value, index) => ({
                fill: "#FD4900",
                fontSize: 11,
                textAnchor: "middle",
              })}
              tickFormat={tickFormat}
              label={xAxisLabel}
              labelClassName={labelClassName}
              numTicks={5}
            />
          </Group>
        </svg>
        <div
          style={{
            position: "absolute",
            top: margin.top / 2 - 50,
            left: 50,
            justifyContent: "center",
            fontSize: "14px",
          }}
        >
          <LegendOrdinal
            shape={"circle"}
            scale={legend}
            direction="column"
            labelMargin="0 200px 0 0"
          />
        </div>
        {tooltipOpen && (
          <Tooltip
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              minWidth: 60,
              backgroundColor: "rgba(0,0,0,0.9)",
              color: "white",
            }}
          >
            <div style={{ color: color(tooltipData.key) }}>
              <strong>{tooltipData.key}</strong>
            </div>
            <div> {renderTooltip(tooltipData.key)}</div>
          </Tooltip>
        )}
      </div>
    );
  }
);
