"use strict";
const { Link } = require("react-router");
const { cx } = require("lib/utils");
const LINK_CLASS = "SidebarNavLink";

const SidebarLink = ({ modifier, ...props }) => {
  return (
    <Link
      className={cx(LINK_CLASS, modifier)}
      activeClassName={`${LINK_CLASS}--active`}
      {...props}
    />
  );
};

module.exports = SidebarLink;
