"use strict";
const { observer } = require("mobx-react");
const oldAppStore = require("stores/app");
const screeningStore = require("stores/screening");
const metricStore = require("stores/metric");
const synopsisStore = require("stores/new-synopsis");
const { Modal } = require("components/elements/new-modal");
const { isEmpty } = require("lodash");

import * as SurveyKnockout from "survey-knockout";
import * as SurveyEditor from "surveyjs-editor";
import {
  configureEditor,
  configureSurvey,
  configureDefaultOptions,
} from "lib/utils";
// Do not run the above util immediately - it relies on an fetch request

const mainColor = "#007aa3";
const textColor = "black";

class ScreeningView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      interactionView: false,
      otherInteractionChecked: false,
      otherInteractionMessage: "",
      loadedTemplate: null,
      showModal: false,
      selectedModality: "",
      selectedFrequencyValue: null,
      selectedFrequencyType: "",
    };

    this.otherInteractionsDropdownOptions = [
      {
        key: "pro",
        label: "PRO",
        value: "PRO",
      },
      {
        key: "scheduling",
        label: "Scheduling",
        value: "Scheduling",
      },
      {
        key: "activation",
        label: "Activation",
        value: "Activation",
      },
    ];

    this.timingOptions = [
      {
        key: "preflight",
        label: "Pre - Flight",
        value: "Pre - Flight",
      },
      {
        key: "inflight",
        label: "In - Flight",
        value: "In - Flight",
      },
      {
        key: "postflight",
        label: "Post - Flight",
        value: "Post - Flight",
      },
      {
        key: "adhoc",
        label: "ADHOC",
        value: "ADHOC",
      },
    ];
    configureDefaultOptions(
      SurveyKnockout,
      screeningStore.fetchForwadableTemplates
    );
  }

  setHeader() {
    const templateId = this.props.router.params.id;
    let onDemandInteraction = false;

    if (this.state.loadedTemplate) {
      onDemandInteraction =
        this.state.loadedTemplate.template_interactions.find(
          (interaction) =>
            interaction.interaction_type === "on_demand" &&
            interaction.status !== "deleted"
        );
    }

    let actions = [
      {
        title: "Save as Draft",
        disabled: !this.state.title,
        action: () => {
          screeningStore
            .createTemplate(
              {
                name: this.state.title,
                status: "draft",
                data: this.editor.text,
              },
              this.state.loadedTemplate ? this.state.loadedTemplate.id : false
            )
            .then((data) => {
              let screeningInteraction =
                screeningStore.screeningInteractionsData.filter(
                  (option) => option.templateId === templateId
                );

              if (
                !isEmpty(screeningInteraction) &&
                screeningInteraction[0].onDemand
              ) {
                screeningStore.createTemplateInteraction({
                  template_id: data.id,
                  clinic_id: synopsisStore.site.id,
                  interaction_type: "on_demand",
                });
              }

              if (
                !isEmpty(screeningInteraction) &&
                screeningInteraction[0].otherInteraction
              ) {
                if (
                  screeningInteraction[0].otherInteractionSelection[0] === "PRO"
                ) {
                  if (!isEmpty(screeningInteraction[0].modalities)) {
                    screeningStore.requestTemplateInteraction({
                      template_id: data.id,
                      message: `${
                        this.state.otherInteractionMessage
                      }. Modality and Frequency: ${JSON.stringify(
                        screeningInteraction[0].modalities
                      )}`,
                      interaction_types:
                        screeningInteraction[0].otherInteractionSelection,
                    });
                  } else {
                    screeningStore.requestTemplateInteraction({
                      template_id: data.id,
                      message: `${
                        this.state.otherInteractionMessage
                      }. Screening Interaction Timing: ${
                        screeningInteraction[0]
                          .otherInteractionSelectedTiming || "not set"
                      }. Screening Interaction Administration: ${
                        screeningInteraction[0]
                          .otherInteractionSelectedAdministration || "not set"
                      }.`,
                      interaction_types:
                        screeningInteraction[0].otherInteractionSelection,
                    });
                  }
                } else {
                  screeningStore.requestTemplateInteraction({
                    template_id: data.id,
                    message: this.state.otherInteractionMessage,
                    interaction_types:
                      screeningInteraction[0].otherInteractionSelection,
                  });
                }
              }

              if (onDemandInteraction && !screeningInteraction[0].onDemand) {
                screeningStore.deleteTemplateInteraction(
                  onDemandInteraction.id
                );
              }
              this.navigateToScreenings();
            });
        },
      },
      {
        title: "Publish for Use",
        disabled: !this.state.title || this.state.otherInteractionChecked,
        action: () => {
          screeningStore
            .createTemplate({
              name: this.state.title,
              status: "live",
              data: this.editor.text,
            })
            .then((data) => {
              let screeningInteraction =
                screeningStore.screeningInteractionsData.filter(
                  (option) => option.templateId === templateId
                );
              if (
                !isEmpty(screeningInteraction) &&
                screeningInteraction[0].onDemand
              ) {
                screeningStore.createTemplateInteraction({
                  template_id: data.id,
                  clinic_id: synopsisStore.site.id,
                  interaction_type: "on_demand",
                });
              }
              if (onDemandInteraction && !screeningInteraction[0].onDemand) {
                screeningStore.deleteTemplateInteraction(
                  onDemandInteraction.id
                );
              }
              this.navigateToScreenings();
            });
        },
      },
    ];

    if (
      this.state.loadedTemplate &&
      this.state.loadedTemplate.status === "live"
    ) {
      actions = [
        {
          title: "Back to Screenings",
          action: () => this.navigateToScreenings(),
        },
      ];
    }

    oldAppStore.actions.updateHeader({ actions: actions });
  }

  componentDidUpdate(prevProps, prevState) {
    const { title, otherInteractionChecked } = this.state;

    if (
      prevState.title !== title ||
      prevState.otherInteractionChecked !== otherInteractionChecked
    ) {
      this.setHeader();
    }
  }

  componentDidMount() {
    screeningStore.fetchModalities();

    this.setHeader();
    const editorOptions = {
      showJSONEditorTab: false,
    };
    this.editor = new SurveyEditor.SurveyEditor("surveyEditor", editorOptions);
    this.editor.haveCommercialLicense = true;

    const defaultThemeColorsEditor =
      SurveyEditor.StylesManager.ThemeColors["default"];
    defaultThemeColorsEditor["$primary-color"] = mainColor;
    defaultThemeColorsEditor["$secondary-color"] = mainColor;
    defaultThemeColorsEditor["$primary-hover-color"] = mainColor;
    defaultThemeColorsEditor["$primary-text-color"] = textColor;
    defaultThemeColorsEditor["$selection-border-color"] = mainColor;
    SurveyEditor.StylesManager.applyTheme();

    const templateId = this.props.router.params.id;

    // Inject the metricTypeId configs *IF* they aren't loaded yet (CWM can be called many times onNavigate)
    const surveyHasMetricConfigs =
      SurveyEditor.SurveyQuestionEditorDefinition.definition[
        "question"
      ].properties.some((prop) => prop.name === "metricTypeId");
    if (!surveyHasMetricConfigs) {
      metricStore.fetchMetricTypes().then((res) => {
        configureEditor(
          SurveyKnockout,
          SurveyEditor,
          metricStore.typesAsOptions
        );
        configureSurvey(SurveyKnockout);

        if (templateId) {
          this.loadTemplate(templateId);
        }
      });
    } else {
      if (templateId) {
        this.loadTemplate(templateId);
      }
    }
  }

  navigateToScreenings() {
    this.props.router.push({ pathname: "/screenings" });
  }

  loadTemplate(templateId) {
    const screeningTemplate = screeningStore.screeningTemplates.find(
      (template) => template.id == templateId
    );
    if (!screeningTemplate) {
      return this.navigateToScreenings();
    }

    this.editor.text = JSON.stringify(screeningTemplate.data);
    this.setState(
      {
        title: screeningTemplate.name,
        loadedTemplate: screeningTemplate,
      },
      this.setHeader
    );
  }

  otherInteractionsOnClick(value, templateId) {
    screeningStore.setOtherInteractionSelection(value, templateId);
    this.otherInteractionsFocus();
  }

  timingRadioOnChange = (value, templateId) => {
    screeningStore.setOtherInteractionTiming(value, templateId);
    document
      .getElementById("Timing-content")
      .classList.remove("show-timing-radio");
  };

  administrationRadioOnChange = (value, templateId) => {
    screeningStore.setOtherInteractionAdministration(value, templateId);
    document
      .getElementById("Administration-content")
      .classList.remove("show-administration-radio");
  };

  otherInteractionsFocus = () => {
    document
      .getElementById("Interactions-content")
      .classList.remove("show-interactions-dropdown-content");
    document
      .getElementById("Modalities-content")
      .classList.remove("show-modalities-dropdown-content");
    document
      .getElementById("Timing-content")
      .classList.remove("show-timing-radio");
    document
      .getElementById("Administration-content")
      .classList.remove("show-administration-radio");
  };

  openModalityModal(selectedModality) {
    this.setState({
      showModal: true,
      selectedModality: selectedModality,
    });
    document
      .getElementById("Modalities-content")
      .classList.remove("show-modalities-dropdown-content");
  }

  closeModalityModal() {
    this.setState({
      showModal: false,
    });
  }

  saveSelectedModality(templateId) {
    const { selectedModality, selectedFrequencyType, selectedFrequencyValue } =
      this.state;
    screeningStore.saveSelectedModality(
      selectedModality,
      selectedFrequencyType,
      selectedFrequencyValue,
      templateId
    );
    this.closeModalityModal();
  }

  renderModal() {
    const templateId = this.props.router.params.id;
    const result = [];
    for (let i = 1; i <= 12; i++) {
      result.push(i);
    }

    return (
      <Modal
        close={() => this.closeModalityModal()}
        className="Modal-description Modalities-modal-description"
      >
        <div>
          <p>
            How frequently should a screening be taken for{" "}
            {this.state.selectedModality}?
          </p>
          <div className="Modalities-modal-frequency">
            <select
              onChange={(e) =>
                this.setState({ selectedFrequencyValue: e.target.value })
              }
            >
              <option value="">Select frequency value</option>
              {result.map((opt) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </select>

            <select
              onChange={(e) =>
                this.setState({ selectedFrequencyType: e.target.value })
              }
            >
              <option value="">Select frequency type</option>
              <option value="visit (s)">Visit (s)</option>
              <option value="month (s)">Month (s)</option>
            </select>
          </div>

          <div className="Modalities-modal-btn-wrapper">
            <button
              className="Modalities-modal-btn"
              disabled={
                !(
                  this.state.selectedFrequencyValue &&
                  this.state.selectedFrequencyType
                )
              }
              onClick={() => this.saveSelectedModality(templateId)}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  onChangeOtherInteractionsCheckbox(templateId) {
    screeningStore.toggleOtherInteractionsSelection(templateId);
    let screeningInteraction = screeningStore.screeningInteractionsData.filter(
      (option) => option.templateId === templateId
    );
    this.setState({
      otherInteractionChecked: screeningInteraction[0].otherInteraction,
    });
    this.otherInteractionsFocus();
  }

  renderInteractions() {
    const templateId = this.props.router.params.id;
    let screeningInteraction = screeningStore.screeningInteractionsData.filter(
      (option) => option.templateId === templateId
    );
    const {
      otherInteractionSelection,
      otherInteractionSelectedTiming,
      otherInteractionSelectedAdministration,
      modalities,
      otherInteraction,
      onDemand,
    } = screeningInteraction[0];

    return (
      <div className="ScreeningView-interactions">
        <div style={{ fontSize: "20px" }}>Assigned Interactions:</div>
        <div style={{ padding: "12px" }}>
          <div className="OnDemand-checkbox-wrap">
            <input
              type="checkbox"
              name="on-demand"
              id="on-demand-checkbox"
              checked={onDemand}
              onChange={() =>
                screeningStore.toggleOnDemandSelection(templateId)
              }
              aria-labelledby="on-demand"
            />
            <label htmlFor="on-demand-checkbox">
              On-Demand Screening by Provider (i.e. available under BioMetrics
              for ad hoc access)
            </label>
          </div>
        </div>

        <div className="OtherInteraction">
          <div className="OtherInteraction-checkbox">
            <input
              type="checkbox"
              name="other-interaction"
              id="other-interaction"
              checked={otherInteraction}
              onChange={() =>
                this.onChangeOtherInteractionsCheckbox(templateId)
              }
              aria-labelledby="other-interaction"
            />
            <label htmlFor="other-interaction">Other Interaction:</label>
          </div>

          <div className="OtherInteraction-dropdown">
            <span>What workflow should this questionnaire integrate with:</span>
            <div
              className={
                otherInteraction
                  ? "Interactions-dropdown"
                  : "Interactions-dropdown-disabled"
              }
            >
              <div>
                <button
                  className="Interactions-dropdown-btn"
                  disabled={!otherInteraction}
                  onClick={() =>
                    document
                      .getElementById("Interactions-content")
                      .classList.toggle("show-interactions-dropdown-content")
                  }
                >
                  {(otherInteractionSelection &&
                    otherInteractionSelection[0]) ||
                    "Select Interaction"}
                  <i className="fa fa-caret-down Interactions-dropdown-icon" />
                </button>
                <div
                  id="Interactions-content"
                  className="Interactions-dropdown-content"
                >
                  {this.otherInteractionsDropdownOptions.map((option) => (
                    <a
                      key={option.key}
                      id={option.key}
                      className={
                        otherInteractionSelection &&
                        otherInteractionSelection[0] === option.value
                          ? "checked-dropdown-value"
                          : ""
                      }
                      onClick={() =>
                        this.otherInteractionsOnClick(option.value, templateId)
                      }
                    >
                      {option.label}
                    </a>
                  ))}
                </div>
              </div>

              <div>
                <button
                  className={
                    otherInteractionSelection &&
                    otherInteractionSelection[0] === "PRO"
                      ? "Interactions-dropdown-btn"
                      : "Interactions-dropdown-btn-hidden"
                  }
                  disabled={!otherInteraction}
                  onClick={() =>
                    document
                      .getElementById("Timing-content")
                      .classList.toggle("show-timing-radio")
                  }
                >
                  {otherInteractionSelectedTiming || "Select Timing"}
                  <i className="fa fa-caret-down Interactions-dropdown-icon" />
                </button>

                <div
                  id="Timing-content"
                  className="Interactions-dropdown-checkbox"
                >
                  {this.timingOptions.map((option) => (
                    <div key={option.key}>
                      <label>
                        <input
                          type="radio"
                          name="interactions-timing-radio"
                          value={option.value}
                          onChange={() =>
                            this.timingRadioOnChange(option.value, templateId)
                          }
                          checked={
                            otherInteractionSelectedTiming === option.value
                          }
                        />
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <button
                  className={
                    otherInteractionSelection &&
                    otherInteractionSelection[0] === "PRO"
                      ? "Interactions-dropdown-btn"
                      : "Interactions-dropdown-btn-hidden"
                  }
                  disabled={!otherInteraction}
                  onClick={() =>
                    document
                      .getElementById("Administration-content")
                      .classList.toggle("show-administration-radio")
                  }
                >
                  {otherInteractionSelectedAdministration ||
                    "Select Administration"}
                  <i className="fa fa-caret-down Interactions-dropdown-icon" />
                </button>

                <div
                  id="Administration-content"
                  className="Interactions-dropdown-checkbox administration-dropdown"
                >
                  <label>
                    <input
                      type="radio"
                      name="interactions-administration-radio"
                      value={"Self Administered"}
                      onChange={() =>
                        this.administrationRadioOnChange(
                          "Self Administered",
                          templateId
                        )
                      }
                      checked={
                        otherInteractionSelectedAdministration ===
                        "Self Administered"
                      }
                    />
                    Self Administered
                  </label>
                  <br />
                  {otherInteractionSelectedTiming !== "Pre - Flight" && (
                    <label>
                      <input
                        type="radio"
                        name="interactions-administration-radio"
                        value={"Co Administered"}
                        onChange={() =>
                          this.administrationRadioOnChange(
                            "Co Administered",
                            templateId
                          )
                        }
                        checked={
                          otherInteractionSelectedAdministration ===
                          "Co Administered"
                        }
                      />
                      Co Administered
                    </label>
                  )}
                </div>
              </div>

              <div className="Modalities">
                <div
                  className={
                    otherInteractionSelection &&
                    otherInteractionSelection[0] === "PRO"
                      ? "Modalities-dropdown"
                      : "hidden-modalities-dropdown"
                  }
                >
                  {!isEmpty(screeningStore.screeningModalities) && (
                    <button
                      className="Interactions-dropdown-btn"
                      disabled={!otherInteraction}
                      onClick={() =>
                        document
                          .getElementById("Modalities-content")
                          .classList.toggle("show-modalities-dropdown-content")
                      }
                    >
                      {isEmpty(modalities)
                        ? "Select Modality"
                        : modalities[0].modality}
                      <i className="fa fa-caret-down Interactions-dropdown-icon" />
                    </button>
                  )}
                  <div
                    id="Modalities-content"
                    className="Interactions-dropdown-content Modalities-dropdown-content"
                  >
                    {screeningStore.screeningModalities.map((modality) => (
                      <a
                        key={modality.type}
                        onClick={() => this.openModalityModal(modality.type)}
                      >
                        {modality.type}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              otherInteractionSelection &&
              otherInteractionSelection[0] === "PRO" &&
              !isEmpty(modalities)
                ? "Modalities-selected-table"
                : "Modalities-selected-table-hidden"
            }
          >
            <table>
              <tbody>
                <tr>
                  <th>Selected Modality</th>
                  <th>Frequency Type</th>
                  <th>Frequency Value</th>
                  <th>Timing</th>
                  <th>Administration Type</th>
                </tr>
                {modalities.map((option) => (
                  <tr key={Math.random()}>
                    <td>{option.modality}</td>
                    <td>{option.frequencyType}</td>
                    <td>{option.frequencyValue}</td>
                    <td>{option.selectedTiming || "Not Set"}</td>
                    <td className="Modalities-selected-table-admin">
                      <div>
                        {option.selectedAdministrationType || "Not Set"}
                      </div>
                      <button
                        className="Modalities-selected-table-btn"
                        disabled={!otherInteraction}
                        onClick={() =>
                          screeningStore.deleteSelectedModality(
                            option.modality,
                            templateId
                          )
                        }
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div style={{ padding: "12px 12px 12px 60px", marginTop: "12px" }}>
            <span>
              Please describe the desired behavior in your own words: How should
              the patient or staff member access or complete the questionnaire?
              Are there any exceptions to is availability / use?
              <br />
              <br />
              The Tech Dev team will follow up with you for additional details.
            </span>
            <textarea
              className="Input OtherInteractionMessage"
              onChange={(e) =>
                this.setState({ otherInteractionMessage: e.target.value })
              }
              onFocus={() => this.otherInteractionsFocus()}
              disabled={!otherInteraction}
            />
          </div>
        </div>
      </div>
    );
  }

  onClickInteractionBtn() {
    const templateId = this.props.router.params.id;
    let ifChecked = false;
    let screeningInteraction = screeningStore.screeningInteractionsData.filter(
      (option) => option.templateId === templateId
    );
    if (!isEmpty(screeningInteraction)) {
      ifChecked = screeningInteraction[0].otherInteraction;
    }
    this.setState({
      interactionView: !this.state.interactionView,
      otherInteractionChecked: ifChecked,
    });
    screeningStore.setTemplateId(templateId);
  }

  render() {
    return (
      <div className="ScreeningView">
        <div className="ScreeningView-header">
          <input
            className="Input u-flexChild Input--noMargin ScreeningView-name"
            placeholder="New Name of Tool"
            value={this.state.title}
            onChange={(e) => this.setState({ title: e.target.value })}
          />
          <div
            className={`Button Interaction ${
              this.state.interactionView ? "Interaction-selected" : ""
            }`}
            onClick={() => this.onClickInteractionBtn()}
          >
            Interaction
          </div>
        </div>
        <div className="ScreeningView-border" />
        {this.state.interactionView && this.renderInteractions()}
        {this.state.showModal && this.renderModal()}
        <div
          id="surveyEditor"
          className="SurveyEditor"
          style={this.state.interactionView ? { display: "none" } : null}
        />
      </div>
    );
  }
}

module.exports = observer(ScreeningView);
