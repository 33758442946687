"use strict";
const LS_KEY = "Home-sign-in-navigated";
const AuthWrapper = require("./auth-wrapper");
const { Link, withRouter } = require("react-router");
const { getRedirectUrl } = require("lib/utils");

class Home extends React.Component {
  componentWillMount() {
    const redirectPath = getRedirectUrl(this.props.location, true);
    try {
      if (localStorage.getItem(LS_KEY)) {
        this.props.router.replace(`/signin${redirectPath}`);
      }
    } catch {
      //
    }
  }

  onSignIn() {
    try {
      localStorage.setItem(LS_KEY, Date.now());
    } catch {
      //
    }
  }

  render() {
    const redirectPath = getRedirectUrl(this.props.location, true);

    return (
      <AuthWrapper>
        <div className="AuthHome">
          <Link
            className="Button Button--large Button--brand"
            to={`/signin${redirectPath}`}
            onClick={() => this.onSignIn()}
          >
            Sign In
          </Link>
          <Link className="Button Button--clear" to="/signup">
            Activate Account
          </Link>
        </div>
      </AuthWrapper>
    );
  }
}

module.exports = withRouter(Home);
