import { createElement, Fragment } from "react";
/*
JSX Shim
Transforms JSX syntax into Exim, which requires (component, props, children)
NOTE: Exim views don't accept className, id, keys and displaynames. To account for that,
styles are applied to a wrapper component. This will show as a duplicate parent
in the React debugger (the shim function), but React will the components render as intended.
*/
export const JSXShim = (EximComponent, Tag = "div") => {
  // key and children always exist, assign props using spread ()...)
  return ({ id, className, style, children, ...props }) => {
    return createElement(
      // WRAPPER TAG: Receives an HTML tag (defaults to div) or Fragment (if a functional component)
      props ? Tag : Fragment,
      // WRAPPER PROPS: Contains everything style related not accepted by Exim's API
      { id, className, style },
      // EXIM CONSTRUCTOR: Creates an Exim view with remaining props drilled in
      EximComponent(props, children)
      // NOTE: Be sure to set the "displayName" prop manually in Exim views
    );
  };
};

/*
Example A: Wrapping an import for an Exim View

// Shim an import and (optionally) declare its name and the tag for the wrapper element
const FontSizeToFit = JSXShim(require('components/app/FontSizeToFit'), 'header')

...(
  <div className='ContentHeader-text'>
    <FontSizeToFit jsx='true'>
      <span>{name}</span>
    </FontSizeToFit>
  </div>
)
*/
