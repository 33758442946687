import checkInStore from "stores/new-check-in";
import { Spinner, Button } from "components/elements";
import { withRouter } from "react-router"; // required for mixin. TODO: remove
import oldCheckInStore from "stores/checkin";
import Step from "components/mixins/Step";
import { a11yClick } from 'lib/utils'

const CheckInFooter = React.createClass({
  displayName: "CheckInFooter",
  mixins: [Step, oldCheckInStore.connect("isUpdating")],

  renderActions() {
    let { prev, next } = checkInStore.getStep();

    return (
      <React.Fragment>
        <div className="CheckInFooter-leaveWrapper">
          <Button
            className="CheckInFooter-leave"
            role="link"
            onClick={this.leave}
            onKeyPress={a11yClick((e) => this.leave(e, true))}
          >
            Leave check-in
          </Button>
        </div>
        <div className="CheckInFooter-navWrapper">
          {prev.id != "welcome" && (
            <Button
              id="check-in-prev"
              className="Button"
              role="link"
              onClick={() => prev.activate()}
            >
              Go back
            </Button>
          )}
          {this.props.children}
          {!this.props.disableContinue && (
            <Button
              id="check-in-next"
              className="Button Button--primary"
              type="submit"
              onClick={this.props.onNext}
            >
              {next.id == "thanks" ? "Finish" : "Continue"}
            </Button>
          )}
          {this.props.disableContinue && (
            <Button
              id="check-in-next"
              className="Button Button--disabled"
              type="submit"
              aria-label="Please complete all required fields to continue"
              aria-disabled
            >
              {next.id == "thanks" ? "Finish" : "Continue"}
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  },

  render() {
    return (
      <footer className="CheckInFooter">
        {this.state.isUpdating ? <Spinner /> : this.renderActions()}
      </footer>
    );
  },
});

export default withRouter(CheckInFooter);
