appStore = require 'stores/app' # TODO: figure out why destructuring doesn't work here
{div} = Exim.DOM

AlertModal = Exim.createView module.id,
  render: ->
    div className: 'ConfirmationModal',
      div className: 'ConfirmationModalTitle',
        @props.title

module.exports = (title) ->
  appStore.actions.showModal({
    body: AlertModal {title}
  })
