"use strict";
const screeningStore = require("stores/screening");
const authStore = require("stores/new-auth");
const { observer } = require("mobx-react");
const Link = require("../link");
const Badge = require("components/app/sidebar/badge");

const HealthScreeningLink = ({ enableOutboundPros }) => {
  const { user } = authStore;

  // temporarily hide this feature behind feature flag
  if (user.isAdmin || !enableOutboundPros) return null;

  return (
    <li className="SidebarNav-item">
      <Link to="/health-screenings">
        <span className="SidebarNavLink-icon">
          <span aria-hidden={true} className="icon fa fa-check-square-o" />
        </span>
        <span className="SidebarNavLink-text no-wrap">My Checklist</span>
        <Badge
          modifier="conflicts"
          count={screeningStore.noOfOutstandingScreeners}
        />
      </Link>
    </li>
  );
};

module.exports = observer(HealthScreeningLink);
