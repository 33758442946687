"use strict";
const { observer } = require("mobx-react");
const wellnessStore = require("stores/wellness").default;
import CVRiskResults from "./elements/CVRiskResults";
import PromisResults from "./elements/PromisResults";
const { Spinner } = require("components/elements");

class Result extends React.Component {
  componentWillMount() {
    const { id } = this.props.location.query;
    wellnessStore.fetchProgram(id);
  }

  componentWillUpdate(newProps) {
    const { id } = newProps.location.query;
    if (id !== this.props.location.query.id) {
      wellnessStore.fetchProgram(id);
    }
  }

  renderResults(results, graphData) {
    return Object.entries(results).map(([key, values]) => {
      if (values) {
        switch (key) {
          case "cv_risk_score":
            return (
              <CVRiskResults key={key} values={values} graphData={graphData} />
            );
          case "promis":
            return (
              <PromisResults key={key} values={values} graphData={graphData} />
            );
          default:
            return null;
        }
      }
    });
  }

  renderPreventiveHealthResourcesLinks() {
    return (
      <div className="health-resources-links">
        <h3 className="MainWellnessCard-title">PREVENTIVE HEALTH RESOURCES</h3>
        <ul>
          <li>
            <p>
              Additional information from CDC on{" "}
              <a
                href="https://www.cdc.gov/cancer/colorectal/basic_info/screening/index.htm"
                target="_blank"
              >
                colorectal cancer screening
              </a>
            </p>
          </li>
          <li>
            <p>
              Additional information from CDC on{" "}
              <a
                href="https://www.cdc.gov/cancer/cervical/basic_info/screening.htm"
                target="_blank"
              >
                cervical cancer screening
              </a>
            </p>
          </li>
          <li>
            <p>
              Additional information from CDC on{" "}
              <a
                href="https://www.cdc.gov/cancer/breast/basic_info/screening.htm"
                target="_blank"
              >
                breast cancer screening
              </a>
            </p>
          </li>
        </ul>
      </div>
    );
  }

  renderMessages() {
    const { program } = wellnessStore;
    const { patient_screening_status, ongoing } = program || {};

    if (ongoing) {
      if (patient_screening_status === "active") {
        return "- You are almost there! Please complete the survey to view your results -";
      }
      if (patient_screening_status === "incomplete") {
        return "- Please click on the Start Survey button to begin your survey -";
      }
    }

    return "- There are no results to display for this year -";
  }

  render() {
    const { program, isFetching, graphData } = wellnessStore;
    const { results, result_title } = program || {};
    const surveyResults = results && JSON.parse(program.results);

    return (
      <React.Fragment>
        {isFetching ? (
          <div className="WellnessResults-spinner">
            <Spinner />
          </div>
        ) : surveyResults ? (
          <div className="WellnessResults">
            <div className="WellnessResults-wrap">
              <div className="WellnessResults-Header">
                <p className="WellnessResults-Header--title">{result_title}</p>
                <p className="WellnessResults-Header--subtitle">
                  This is only one step to a healthier you! We encourage you to
                  take advantage of your health coverage by scheduling a visit
                  with your primary care doctor. This way you can make sure you
                  are staying current on preventative recommendations while
                  discussing a plan that makes sense for you.
                </p>
                <p className="WellnessResults-Header--print">
                  <button
                    title="Print"
                    onClick={window.print}
                    className="WellnessResults-Header--print-btn"
                  >
                    <i className="fa fa-print"></i>
                  </button>
                  <span>
                    Click this icon to print and/or download a copy of these
                    results for your records
                  </span>
                </p>
              </div>
              <div className="WellnessResults-Content">
                {this.renderResults(surveyResults, graphData)}
                {this.renderPreventiveHealthResourcesLinks()}
              </div>
            </div>
          </div>
        ) : (
          <div className="WellnessResults-no--results">
            {this.renderMessages()}
          </div>
        )}
      </React.Fragment>
    );
  }
}

module.exports = observer(Result);
