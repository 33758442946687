import React from "react";
import PropTypes from "prop-types";

class AmazonPopUp extends React.Component {
  render() {
    const { handleCloseButtonClick } = this.props;

    return (
      <>
        <div
          className="touchclick Button"
          style={{
            textAlign: "left",
            padding: "8px 8px 8px 16px",
            margin: "12px 0px",
            maxWidth: "360px",
          }}
        >
          {/* although this isn't a true modal we are using some classes from our modal component
          for styling purposes */}
          <div className="NewModal-header">
            <button
              // TODO:  focus this el on mount
              ref={this.closeButtonRef}
              className="fa fa-times"
              aria-label={`Close message pop up`}
              onClick={handleCloseButtonClick}
            />
          </div>
          <p style={{ marginTop: "6px" }}>
            You cannot continue with activation until you have replaced the
            Amazon work email with your personal email address.
          </p>

          <p>
            You may also call the <b>Amazon Member Support Center</b> to help
            you activate your account at 1-888-854-1397.
          </p>
        </div>
      </>
    );
  }
}

AmazonPopUp.propTypes = {
  handleCloseButtonClick: PropTypes.func.isRequired,
};

module.exports = AmazonPopUp;
