{appStore, authStore, messagesStore, patientStore} = require 'stores'
newAuthStore = require 'stores/new-auth'

Img            = require 'components/elements/Img'
Spinner        = require 'components/elements/Spinner'
Zoom           = require 'components/elements/Zoom'
ActionDropdown = require 'components/elements/ActionDropdown'
DocumentPreview   = require './DocumentPreview'
BackSwiper = require('components/elements/back-swiper')

config          = require 'config'
utils           = require 'lib/utils'
messageUtils    = require './messageUtils'
cache           = require 'lib/cache'
confirm = require('lib/confirm')
request = require('lib/new-request')

{Link} = require('react-router')
welcome = config.welcomeMessage

NAV_WIDTH = 100
MD_DOWN = 768

dateFormat = 'dddd, MMMM DD, YYYY [at] hh:mm A'

{cx} = Exim.helpers
{div, span, fa, a, img, h2, label, tch, strong, button} = Exim.DOM

Show = React.createClass({
  displayName: 'ShowMessage',
  mixins: [
    appStore.connect('config')
    messagesStore.connect('message', 'received', 'sent',  'messageFetching', 'zoneAbbr')
    authStore.connect('user')
    patientStore.connect('patient')
  ],

  contextTypes:
    router: React.PropTypes.object.isRequired

  stopPropagation: (evt) ->
    evt.stopPropagation()

  proxyPatient: (id) -> (evt) =>
    evt.preventDefault()
    authStore.actions.startImpersonation(id).then =>
      @context.router.push pathname: '/profile'
    .catch ->

  componentWillMount: ->
    user = authStore.get('user')
    messagesStore.actions.displayTZ()
    {@type, @id} = @props.params
    {@page, @name, @avatar_url, @subject, type, @filter} = @props.location.query
    @subtype = type or @type # sent or received

    appStore.actions.updateHeader(name: utils.capitalize(if @type is 'received' then 'Inbox' else @type))

    @isWelcome = @id is '0'
    unless @isWelcome
      messagesStore.actions.fetchMessage({type: @subtype, @id, skipCache: true})

    if !user.proxying and resp = cache.messages[@subtype]?.all?.response
      cache.messages[@subtype].all.response = resp.map (msg) =>
        if +msg.id is +@id
          msg.unread = false
        msg

  componentWillUnmount: ->
    window.removeEventListener('keyup', @lastHandler) if @lastHandler
    unless @context.router.isActive("messages/received/#{@id}/reply") or
           @context.router.isActive("messages/received/#{@id}/replyall") or
           @context.router.isActive("messages/received/#{@id}/forward") or
           @context.router.isActive("messages/new")
      messagesStore.actions.clearMessage()

  componentDidMount: ->
    @refs.backButton?.focus()
    appStore.actions.setTitle("View Message")

  prev: ->
    return unless @state.prev
    @context.router.push pathname: "/messages/#{@type}/#{@state.prev.id}"

  next: ->
    return unless @state.next
    @context.router.push pathname: "/messages/#{@type}/#{@state.next.id}"

  reply: ->
    messageId = @state.message.id
    type = 'received'
    query = {}
    query.recipients = @state.patient.patient_id if @state.user.proxying
    query.page = @page if @page
    @context.router.push pathname: "/messages/#{type}/#{messageId}/reply", query: query

  replyAll: ->
    messageId = @state.message.id
    query = {}
    query.recipients = @state.patient.patient_id if @state.user.proxying
    query.page = @page if @page
    @context.router.push pathname: "/messages/received/#{messageId}/replyall", query: query

  forward: ->
    messageId = @state.message.id
    query = {}
    query.recipients = @state.patient.patient_id if @state.user.proxying
    query.page = @page if @page
    @context.router.push pathname: "/messages/received/#{messageId}/forward", query: query

  replace: (vrid) ->
    {message} = @state
    data = {vrid}
    messagesStore.actions.update(@subtype, message, data)

  archive: ->
    state = !(@type is 'archived')
    {message} = @state
    data = {}
    data['archived'] = state
    data['vrid'] = ''
    messagesStore.actions.update(@subtype, message, data).then =>
      this.goBack()

  flag: (flag) -> =>
    {message} = @state
    data = {}
    data.flags = if message.flags is flag then 0 else flag
    messagesStore.actions.update(@subtype, message, data)

  prevent: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()

  startSwipe: (evt) ->
    @width = evt.currentTarget.clientWidth
    @startPos = evt.touches[0].pageX
    @startTime = new Date
    @setState swiping: true

  processSwipe: (evt) ->
    @moved = true
    pos = evt.touches[0].pageX
    return if Math.abs(pos - @startPos) < NAV_WIDTH
    @endPos = pos
    @diff = @endPos - @startPos
    @setState swipe: -@width + @endPos

  endSwipe: (doc) -> (evt) =>
    @setState swiping: false
    duration = new Date - @startTime
    if @moved and duration < 500 and (@diff > 150 or @diff < -150)
      if @diff < 0
        @open(doc.next)
      else
        @open(doc.prev)

    @diff = 0
    @moved = false
    @setState swipe: null

  download: (doc) -> (evt) =>
    @prevent(evt)
    if utils.humanizeMime(doc.mime) is 'Image'
      utils.saveImage doc.url, doc.title
    else
      utils.openLink utils.getUrl(doc.url.slice(1), doc.name or doc.title)

  onKeyUp: (doc) -> (evt) =>
    { which } = evt
    if which in [37, 38]
      @open(doc.prev)
    else if which in [39, 40]
      @open(doc.next)

  handleKeyUp: (doc) ->
    window.removeEventListener('keyup', @lastHandler) if @lastHandler
    @lastHandler = @onKeyUp(doc)
    window.addEventListener('keyup', @lastHandler)

  open: (doc) ->
    return unless doc

    @handleKeyUp(doc)
    url = utils.getUrl(doc.url.slice(1))
    mime = utils.humanizeMime(doc.mime).toLowerCase()
    {admin, proxying} = @state.user
    if mime in ['image', 'video', 'pdf', 'document']
      className = 'Modal--noBodyPadding'
      body = DocumentPreview {doc, url, mime, @download, @openInWindow, @open, @startSwipe, @processSwipe, @endSwipe}
      title = div null,
        strong utils.capitalize(mime)
        ": #{doc.title}"
      footer = div className: 'DocumentPreview-footer u-flex u-flexJustifyBetween',
        tch className: "Button Button--pointer Button--small prev u-flex u-flexAlignSelfStart #{cx 'is-disabled': !doc.prev}", handler: @preview(doc.prev),
          'Previous'
        tch className: "Button Button--pointer Button--small next u-flex u-flexAlignSelfEnd #{cx 'is-disabled': !doc.next}", handler: @preview(doc.next),
          'Next'
      modal = {title, body, footer, className, onClose: @toDocs, contentLabel: doc?.title}
      appStore.actions.showModal(modal)
    else
      @openInWindow(url)

  preview: (doc) -> (evt) =>
    @prevent(evt)
    @open(doc)

  openInWindow: (url) ->
    window.open(url, '_blank').focus()

  checkName: (user) ->
    {admin, proxying} = @state.user
    if user.id is @state.user.id and not proxying
      'Me'
    else if (admin or proxying) and user.info
      tch className: 'MessageCardHeader-link', handler: @proxyPatient(user.id), key: user.id,
        "#{user.name} (#{user.info})"
    else user.name

  getBackLink: ->
    if @filter
      utils.capitalize @filter
    else if @type is 'received'
      'Inbox'
    else 'Sent'

  getBackTo: ->
    {location, params} = this.props
    {filter, page} = location.query
    type = filter || params.type
    pathname = '/messages'
    pathname += "/#{type}" if type != 'received'

    return {
      pathname,
      query: {page},
    }

  goBack: ->
    @context.router.push(this.getBackTo())

  getMessage: ->
    if @isWelcome
      if @isWelcome
        {user} = @state
        Object.assign({}, welcome, {
          to: [ { id: user.id, name: user.full_name } ],
          attachments: []
        })
    else
      cache.messages[@subtype]?[@id] or @state.message

  deleteMessage: ->
    confirm('Are you sure you want to recall this message?').then((isConfirmed) =>
      return if !isConfirmed

      {id} = this.getMessage()
      request.delete("/v1/messages/#{id}").then(() =>
        this.goBack()
      )
    )

  renderDeleteButton: ->
    return if !this.state.user.proxying

    return button {
      className: 'MessageCardTitle-actionsItem Button Button--archive',
      title: 'Recall this message',
      onClick: this.deleteMessage,
    }, fa 'trash'

  render: ->
    {user, messageFetching} = @state
    messageFetching = false if @isWelcome
    message = @getMessage()

    {admin, proxying} = user
    showVrid = (admin or proxying)
    isMobile = document.body.clientWidth < MD_DOWN

    format = (date) -> moment(date).format(dateFormat)

    archive = if @type in ['received', 'archived']
      tch tagName: 'button', className: "MessageCardTitle-actionsItem Button Button--archive #{cx 'is-active': @type is 'archived'}", title: (if @type is 'archived' then 'Unarchive message' else 'Archive message'), handler: @archive, onEnter: @archive, tabIndex: 0,
        fa 'archive'
    else null

    flags = config.messageFlags.map (f) => f.handler = @flag(f.id); f

    flag = if @type in ['received', 'sent']
      ActionDropdown
        className: 'MessageCardTitle-actionsItem ActionDropdown--flags'
        values: flags
        select: true
        selected: message.flags
        placeholder: config.messageFlagsPlaceholder
    else null

    visits = @state.config.replaceable_visits

    name = @name || message.from.name
    avatar_url = @avatar_url || message.from.avatar_url

    messageBody = utils.getBody(message.body)

    actions = [
      {name: 'Reply', icon: 'reply', handler: @reply}
      {name: 'Reply All', icon: 'reply-all', handler: @replyAll}
      {name: 'Forward', icon: 'share', handler: @forward}
    ]

    if newAuthStore.isPortalReadOnly
      actions = []

    div className: 'MessageCardWrapper',
      React.createElement(BackSwiper, {to: this.getBackTo()})
      div className: 'MessageActions',
        React.createElement(Link, {
          className: 'Button Button--back MessageActions-backButton',
          to: this.getBackTo(),
          'aria-label': "Back to #{@getBackLink()}",
        },
          span 'Back'
          span className: 'is-hidden-on-mobile', " to #{@getBackLink()}"
        )

      div className: 'MessageCard',
        Spinner state: !message.id and messageFetching,
          div null,
            div className: 'MessageCardTitle',
              h2 null,
                utils.getSubject(message.subject) or @subject or ''
              div className: 'MessageCardTitle-actions',
                flag
                archive
                this.renderDeleteButton()
            div className: 'MessageCardHeader',
              div className: 'MessageCardHeader-content',
                if (url = avatar_url) and url isnt '/images/image-placeholder.jpg'
                  div className: 'MessageCardHeader-avatar',
                    img alt: '', role: 'presentation', src: utils.getAvatarUrl(url)
                div className: 'MessageCardHeader-info',
                  div className: 'MessageCardHeader-line MessageCardHeader-line--tall',
                    div className: 'MessageCardHeader-user',
                      if @subtype is 'received' and user.admin and !user.proxying
                        tch handler: @proxyPatient(message.from.id),
                          @checkName(message.from)
                      else
                        name
                  div className: 'MessageCardHeader-line',
                    div className: "#{if message.last_action then ('MessageCardHeader-recipient-zoom') else ('MessageCardHeader-recipient')}",
                      'To: '
                      message.to.map (user, i, arr) =>
                        span key: user.id,
                          @checkName(user)
                          ', ' unless i is arr.length-1
                  div className: 'MessageCardHeader-line',
                    div null,
                      format(message.sent_at or message.received_at)
                      " "
                      @state.zoneAbbr
                div className: 'MessageCardHeader-actions',
                  ActionDropdown
                    className: 'ActionDropdown--actions'
                    values: actions
                  if message.last_action
                    div className: 'MessageCardLastAction MessageCardLastAction-zoom', "#{messageUtils.actionToPastTense(message.last_action)} on #{utils.formatDateShort(message.last_action_at)}"
            div className: 'MessageCardBody', onClick: @stopPropagation, dangerouslySetInnerHTML: {__html: messageBody}
            div className: 'Attachments',
              message.attachments?.map (doc, i, docs) =>
                doc.prev = docs[i-1]
                doc.next = docs[i+1]
                mime = utils.humanizeMime doc.mime
                doc.fullUrl = utils.getUrl(doc.url.slice(1))
                tch className: 'Attachment', key: doc.name, handler: @preview(doc), onEnter: @preview(doc), tabIndex: 0, role: 'button', 'aria-label': "Open Attachment #{doc.name}",
                  div className: 'Attachment-image', title: doc.name,
                    if mime is 'Image'
                      Img className: 'item-preview', src: doc.fullUrl
                    else
                      div key: doc.name, className: 'item-preview doc',
                        fa utils.getIconName(mime)
                  div className: 'AttachmentText',
                    div doc.name
                    div utils.humanizeSize doc.size if doc?.size

            unless @isWelcome or newAuthStore.isPortalReadOnly
              messageActions = [
                tch key: 'reply', tagName: 'button', className: 'Button Button--darkish Button--pointer MessageReplyButton', handler: @reply, onEnter: @reply, tabIndex: 0,
                  span className: 'Button-icon', fa 'reply'
                  span className: 'Button-text', 'Reply'
                tch key: 'share', tagName: 'button', className: 'Button Button--darkish Button--pointer MessageReplyButton', handler: @forward, onEnter: @forward, tabIndex: 0,
                  span className: 'Button-icon', fa 'share'
                  span className: 'Button-text', 'Forward'
                tch key: 'reply-all', tagName: 'button', className: 'Button Button--darkish Button--pointer MessageReplyButton', handler: @replyAll, onEnter: @replyAll, tabIndex: 0,
                  span className: 'Button-icon', fa 'reply-all'
                  span className: 'Button-text', 'Reply All'
                ]
              div null,
                div className: 'MessageActionsGroup',
                  # if @subtype is 'received'
                  messageActions
                if message.last_action
                  span className: 'MessageCardLastAction MessageCardLastAction--group', "#{messageUtils.actionToPastTense(message.last_action)} on #{utils.formatDateShort(message.last_action_at)}"
})

module.exports = Show
