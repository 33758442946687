Chart = require 'components/elements/Chart'

{div} = Exim.DOM

Archived = Exim.createView module.id,
  getDefaultProps: ->
    className: ''
    chart: false

  render: ->
    div className: 'content'

module.exports = Archived
