{appStore} = require 'stores'
attachHeader    = require 'components/mixins/attachHeader'

{Link} = Exim.Router
{cx}   = Exim.helpers
{div, tch, form}  = Exim.DOM

SignIn = Exim.createView module.id,
  staticView: true
  noHeader: true
  fadeIn: true
  mixins: [attachHeader(null)]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    flipped: false

  flip: (evt) ->
    evt.preventDefault()
    @setState flipped: !@state.flipped

  goToSignin: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    data = @props.location.query
    if data?.from is 'details'
      data.step = 1
      console.log data
      @context.router.push pathname: '/signup', query: data
    else
      @context.router.goBack()

  render: ->
    {security} = appStore.get('config')

    div className: 'Splash Splash--auth',
      div className: 'FlippedSectionInner',
        div className: 'AuthDisclaimer AuthDisclaimer--logo',
          div className: 'AuthDisclaimer-full', dangerouslySetInnerHTML: {__html: security.full} if security?.full
          tch tagName: 'button', className: "Button Button--primary Button--large Button--expand Button--auth", handler: @goToSignin, 'Close'

module.exports = SignIn
