RadioButton = require('./button')

{div}  = Exim.DOM

RadioButtonGroup = Exim.createView module.id,

  render: ->
    {name, checked, onChange, options, ariaLabeledBy} = @props

    div className: 'RadioButton-Group', role: "radiogroup", 'aria-labelledby': @props.ariaLabeledBy,
        options.map (option, index) ->
            RadioButton
                key: index
                name: name
                checked: checked
                value: option.value
                labelText:option.labelText
                id: "#{name}-#{option.value}"
                onChange: onChange

module.exports = RadioButtonGroup
