{ appStore } = require 'stores'
{ div, button, tch, span, fa } = Exim.DOM
{getAvatarUrl} = require('lib/utils')
utils    = require 'lib/utils'

ProviderListItem = Exim.createView module.id,
  getInitialState: ->
    expanded: false

  expandHandler: (e) ->
    @setState expanded: !@state.expanded

  onScheduleClick: ->
    appStore.actions.showModal(null)
    @props.scheduleCallback()
    @props.closeModal()
    document.getElementById('focus-ref-select-provider')?.focus()

  render: ->
    {providerName, providerBio, avatarUrl, renderScheduler} = @props
    avatarUrl = "url('#{getAvatarUrl(avatarUrl)}')"
    avatarStyle = if @state.expanded then null else {backgroundImage: avatarUrl}

    div className: 'ProviderListItem',
      div className: 'ProviderListItem-name', onClick: @expandHandler,
        div className: "ProviderListItem-avatar #{'is-expanded' if @state.expanded}", style: avatarStyle
        span className: 'ProviderListItem-label', providerName
        div className: 'ProviderListItem-caret',
          button className: "ProviderListItem-caret--#{if @state.expanded then 'angle-up' else 'angle-down'}", 'aria-label': "provider-profile #{providerName}", 'aria-expanded': @state.expanded
      div className: "ProviderListItem-details #{'is-expanded' if @state.expanded}", 'aria-hidden': !@state.expanded,
        div className: 'ProviderListItem-bio-avatar', style: {backgroundImage: avatarUrl}
        providerBio
        if renderScheduler
          div className: 'ProviderListItem',
            button className: 'ProviderListItem-schedule', onClick: utils.a11yClick(@onScheduleClick), onKeyPress: utils.a11yClick(@onScheduleClick), "Select #{providerName} as your provider"

module.exports = ProviderListItem
