import User from "models/user";
import Site from "models/site";
import Step from "models/check-in-step";
import apptsStore from "stores/new-appts";
import checkinStore from "./checkin";
import { compact } from "lodash";
import { observable } from "mobx";
import { computed, linkItems } from "lib/utils";

const checkInStore = observable({
  isFetched: null,
  isTiny: null,
  isConsented: null,
  hasDental: null,
  hasLifeStyle: null,
  hasScreening: null,
  hasSMSReminders: null,
  hasOAuth: null,
  lastStepId: null,
  site: null,
  user: null,
  consents: null,
  otherConsents: null,
  portal: null,
  xop_member: null,

  clear() {
    this.isFetched = false;
    this.isTiny = false;
    this.isConsented = false;
    this.hasDental = false;
    this.hasLifeStyle = false;
    this.hasScreening = false;
    this.hasSMSReminders = false;
    this.hasOAuth = false;
    this.lastStepId = null;
    this.site = new Site();
    this.user = new User();
    this.consents = {};
    this.otherConsents = [];
    this.xop_member = null;
    this.portal = {};
  },

  getStep() {
    return this.steps.find((step) => step.isActive);
  },

  getNavs() {
    const steps = this.steps.filter((step) => step.isForm);

    const stepIsDisabled = (index) => {
      const prevStep = steps[index - 1] || {};
      if (prevStep.title === "Screenings" && !this.screeningCompleted()) {
        return true;
      }
      return index > this.lastStepIndex + 1;
    };

    return steps.map((step, index) => ({
      content: step.title,
      to: step.url,
      isCompleted: index <= this.lastStepIndex,
      isDisabled: stepIsDisabled(index),
    }));
  },

  screeningCompleted() {
    const { patient_screenings } = checkinStore.get("checkin");

    const isCompleted =
      patient_screenings &&
      patient_screenings.findIndex((t) => t.completed !== "display") === -1;
    return isCompleted;
  },
});

checkInStore.clear();
computed(checkInStore, {
  get lastStep() {
    if (!this.lastStepId) return first(this.steps);

    return this.steps.find((step) => step.id == this.lastStepId);
  },

  get lastStepIndex() {
    if (!this.lastStepId) return 0;

    return this.steps.findIndex((step) => step.id == this.lastStepId);
  },

  get stepsIds() {
    if (apptsStore.forcedCheckinAppt) return ["welcome", "consents", "thanks"];

    return compact([
      "welcome",
      "about",
      this.site.isUS && "reason",
      this.hasScreening && "patient_screenings",
      this.hasDental && "dental",
      ...(this.isTiny
        ? []
        : [
            this.site.isUS && "meds",
            "conditions",
            this.hasLifeStyle && "lifestyle",
          ]),
      !this.isConsented && "consents",
      !this.user.isVerified && "crossover",
      "thanks",
    ]);
  },

  get steps() {
    let steps = this.stepsIds.map((id) => new Step(id));
    return linkItems(steps);
  },
});

export default checkInStore;
