import { withRouter } from "react-router";
import { observer } from "mobx-react";
import ContentHeader from "components/elements/content-header";
import Spinner from "components/elements/new-spinner";
import HraNav from "./survey/hraNav";
import hraStore from "stores/hra-survey";
import { toJS } from "mobx";

class Wrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };

    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    hraStore.getAssetsConfig();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth });
  }

  detectIE() {
    const ua = navigator.userAgent;
    const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
  }

  render() {
    const step = hraStore.getStep();
    const {
      isLoading,
      assets: { logo_on_hra },
    } = toJS(hraStore);
    const {
      params: { magicToken },
      location: { pathname },
    } = this.props;
    const { width } = this.state;

    const isMobile = width <= 768;
    return (
      <React.Fragment>
        <ContentHeader
          title={step && step.title}
          withSidebar={false}
          className={"hraWrapper"}
          titleClass={"hraNavBar-title"}
          logoClass={"hraNavBar-logo"}
          withLogo={true}
          logo={logo_on_hra}
        />
        {this.detectIE() && pathname.indexOf("verify") > 0 && (
          <p className="IEFrame">
            The Health Risk Assessment survey experience is optimized when it is
            taken on one of the recommended browsers instead of Internet
            Explorer. We recommend you click on the <strong>Browsers</strong>{" "}
            link to download the browser you prefer and launch the survey link
            via that browser to prevent issues related to Internet Explorer.{" "}
            <br />
            <a href="http://browsehappy.com/" target="_blank">
              {" "}
              Browsers{" "}
            </a>
          </p>
        )}
        <HraNav magicToken={magicToken} isMobile={isMobile} />
        {this.props.children}
        {isLoading && <Spinner className="Spinner--hra" />}
      </React.Fragment>
    );
  }
}

export default withRouter(observer(Wrapper));
