{appStore, documentsStore, authStore} = require 'stores'
Img        = require 'components/elements/Img'
Zoom        = require 'components/elements/Zoom'
{cx} = Exim.helpers
{div, img, span, fa, a, video, source, tch, object} = Exim.DOM

DocumentPreview = Exim.createView module.id,
  mixins: [
    authStore.connect('user')
  ]

  render: ->
    {admin, proxying} = @state.user
    {doc, mime, url, download, verify, deleteDoc, openInWindow, back, mobile, startSwipe, processSwipe, endSwipe} = @props

    div className: 'DocumentPreview',
      div className: 'DocumentPreview-actions',
        if back
          tch tagName: 'a', href: '#', className: 'Button Button--back Button--pointer MessageActions-backButton', handler: back, onEnter: back,
            span 'Back'
        if download
          tch tagName: 'a', href: '#', className: 'DocumentPreview-action', handler: download(doc), onEnter: download(doc), 'aria-label': "Download #{doc.title}",
            fa 'download'
            span className: 'DocumentPreview-actionTitle', 'Download'
        if (admin or proxying) and deleteDoc
          tch tagName: 'a', href: '#', className: 'DocumentPreview-action', handler: deleteDoc?(doc), onEnter: deleteDoc?(doc),
            fa 'trash-o'
            span className: 'DocumentPreview-actionTitle', 'Delete'
      div className: "DocumentPreview-mime DocumentPreview-mime-#{mime}", onTouchStart: startSwipe, onTouchMove: processSwipe, onTouchEnd: endSwipe(doc),
        if mime is 'video'
          video className: 'video', controls: true, autoPlay: false,
            source src: url
            a href:url, 'Download Video'
        else if mime is 'pdf'
          if mobile
            tch tagName: 'a', href: '#', className: 'pdf-download', handler: openInWindow.bind(this, url),
              fa 'file-pdf-o'
              'Open File'
          else
            div className: 'DocumentPreview-mime-pdf-object scroll-wrapper',
              object data: url, type: 'application/pdf', width: '100%', height: '100%', 'aria-label': "this is the #{mime} #{doc.title}",
                a href: url, target: '_blank', url
        else if mime is 'document'
          tch tagName: 'a', href: '#', className: 'document-download', handler: openInWindow.bind(this, url),
            fa 'file-text-o'
            'Open File'
        else
          Zoom null,#onClick: @open.bind(@, doc.next),
            Img className: "#{cx disabled: !doc.next}", force: true, src: url

module.exports = DocumentPreview
