const { observable, computed } = require("mobx");
import Step from "models/hra-step";
import request from "lib/new-request";
import { toJS } from "mobx";

const hraStore = observable({
  dob: null,
  error: null,
  isLoading: false,
  isVerified: false,
  email: null,
  isMobile: false,
  windowWidth: 768,
  surveyQuestions: {},
  results: [],
  lastStepId: null,
  graphItems: [0, 1],
  assets: { logo_on_hra: "" },
  screeningStatus: null,
  screeningStatusOnVerify: null,

  async verify({ magicToken, email, dob }) {
    const payload = {
      email,
      dob,
    };

    this.isLoading = true;
    try {
      this.clearError();
      const { survey } = await request.post(
        `/v1/surveys/${magicToken}/start`,
        payload
      );
      this.surveyQuestions = survey;
      this.email = email;
      this.dob = dob;
      this.isVerified = true;
    } catch ({ error_body }) {
      this.error = error_body.error;
      this.screeningStatusOnVerify = error_body.screening_status;
    } finally {
      this.isLoading = false;
    }
  },

  async postAnswers({ magicToken, ...payload }) {
    this.isLoading = true;
    try {
      const {
        results: { cvrisk, fitnessassessment, promis },
        screening_status,
      } = await request.post(`/v1/surveys/${magicToken}/complete`, payload);
      const cvRiskValue = cvrisk && cvrisk.value * 100;

      const cvRisk =
        cvrisk && cvrisk.status === "done"
          ? {
              type: "cv_risk_score",
              value: cvRiskValue.toFixed(1),
              name: "Cardiovascular Risk Assessment",
              scale: cvrisk && cvrisk.scale[0],
              status: cvrisk.status,
            }
          : {
              type: "cv_risk_score",
              name: "Cardiovascular Risk Assessment",
              status: cvrisk && cvrisk.status,
              skipped_reason: cvrisk.skipped_reason,
            };

      const fitnessAssessment = fitnessassessment
        ? {
            type: "fitness_assessment",
            name: "Fitness Assessment",
            value: {
              current_age: fitnessassessment.value.current_age,
              non_exercise_crf: Math.round(
                fitnessassessment.value.non_exercise_crf
              ),
              crf_ratio: fitnessassessment.value.crf_ratio,
              crf: Math.round(fitnessassessment.value.crf),
              fitness_age: Math.round(fitnessassessment.value.fitness_age),
            },
          }
        : {};

      const promisResults = promis
        ? {
            type: "promis",
            name: "Patient-Reported Outcomes Measurement Information System",
            value: {
              physical: {
                value: Math.round(promis.value.physical_health.mid),
                average: promis.value.physical_health.average,
                scale: promis.scale.physical[0],
              },
              mental: {
                value: Math.round(promis.value.mental_health.mid),
                average: promis.value.physical_health.average,
                scale: promis.scale.mental[0],
              },
            },
          }
        : {};

      this.results = [cvRisk, fitnessAssessment, promisResults];
      this.screeningStatus = screening_status;
    } catch (error) {
      console.log(error);
      this.results = [];
    } finally {
      this.isLoading = false;
    }
  },

  get lastStepIndex() {
    if (!this.lastStepId) return 0;

    return this.steps.findIndex((step) => step.id == this.lastStepId);
  },

  getStep() {
    return this.steps.find((step) => step.isActive);
  },

  get questions() {
    return this.surveyQuestions;
  },

  get userCreds() {
    return {
      dob: this.dob,
      email: this.email,
    };
  },

  get stepsIds() {
    return ["verify", "survey", "results"];
  },

  get steps() {
    let steps = this.stepsIds.map((id) => new Step(id));
    return steps;
  },

  get allResults() {
    return this.results;
  },

  get hraScreeningStatus() {
    return this.screeningStatus;
  },

  get onVerifyScreeningStatus() {
    return this.screeningStatusOnVerify;
  },

  graphData(graphType, resultType) {
    const { value, ...props } = toJS(this.results).find(
      (result) => result.type === graphType
    );

    const data = [];
    let dotGraph;
    let horizontalBarGraph;
    let scale;
    if (graphType === "promis") {
      if (resultType === "physical") {
        scale = value.physical.scale;
      } else {
        scale = value.mental.scale;
      }
    } else {
      scale = props.scale;
    }

    switch (graphType) {
      case "cv_risk_score":
        if (props.status === "done") {
          // represents values used in the horizontal graph
          horizontalBarGraph = {
            low: scale.green.high * 100,
            medium: scale.red.low * 100 - scale.green.high * 100,
            high: scale.red.high * 100 - scale.red.low * 100,
            your_score: null,
            type: "bar",
          };

          // Represents values used in the circle 'dot' above the horizontal graph
          dotGraph = {
            low: 0,
            medium: 0,
            high: 0,
            your_score:
              value > scale.red.high * 100 ? scale.red.high * 100 : value,
            type: "circle",
          };

          this.graphItems.map((index) => {
            index === 0
              ? data.push({
                  index,
                  ...horizontalBarGraph,
                })
              : data.push({
                  index,
                  ...dotGraph,
                });
          });
        }
        break;
      case "promis":
        horizontalBarGraph = {
          low: scale.green.high - scale.green.low,
          medium: scale.green.low - scale.yellow.low,
          high: scale.red.high - scale.red.low,
          threshold: scale.red.low,
          your_score: null,
          average_score: null,
          type: "bar",
        };

        dotGraph = {
          low: 0,
          medium: 0,
          high: 0,
          your_score: value[resultType].value,
          average_score: value[resultType].average,
          type: "circle",
        };

        this.graphItems.map((index) => {
          index === 0
            ? data.push({
                index,
                ...horizontalBarGraph,
              })
            : data.push({
                index,
                ...dotGraph,
              });
        });
        break;
      default:
        break;
    }
    return data;
  },

  async getAssetsConfig() {
    try {
      const { assets } = await request.get(`/v1/config`);
      this.assets = assets;
    } catch (e) {
      console.log(e);
    }
  },

  clearError() {
    this.error = null;
  },
});

export default hraStore;
