{authStore} = require 'stores'

{cx} = Exim.helpers
{div, h1, p, input, spinner, span, tch, form, button, label} = Exim.DOM

defaultState = sent: false, email: ''

SignInResetForm = Exim.createView module.id,
  listen: ['auth/sendingForgot', 'auth/error']

  getInitialState: ->
    defaultState

  sendForgot: (e) ->
    e.preventDefault()
    {email} = @state
    return unless email
    authStore.actions.forgot(email).then =>
      @setState sent: true

  flip: (evt) ->
    @setState sent: false
    @props.flip(evt)
    setTimeout @refreshSent, @props.animDuration

  refreshSent: ->
    @setState defaultState

  setEmail: (evt) ->
    @setState email: evt.currentTarget.value

  render: ->
    {flipped} = @props
    tabIndex = if flipped then 0 else -1
    div className: 'FlippedCard-back card-logo',
      form className: "#{cx 'is-sent': @state.sent}", onSubmit: @sendForgot, "aria-live": "polite", autoComplete: 'off',
        if @state.sent
          [
            h1 key: 'success-title', className: 'FlippedCard-title', 'Password reset request sent'
            p key: 'success-check', 'Please check your email and click the link to finish resetting your password.'
            p key: 'success-check-2', className: 'check', "If you don't receive it in a few minutes, please check your spam folder."
            div key: 'success-spacer', className: 'spacer'
          ]
        else
          [
            h1 key: 'fail-title', className: 'FlippedCard-title', 'Reset your password'
            div key: 'fail-email', className: 'input-wrapper email',
              label htmlFor: 'login-email', 'Email Address'
              input
                className: "Input Input--expand Input--auth  #{cx error: @state.error}"
                id: 'login-email'
                name: 'email'
                onChange: @setEmail
                ref: 'email'
                type: 'email'
                value: @state.email
                disabled: (not flipped)
                tabIndex: tabIndex
            button key: 'fail-submit', className: "Button Button--primary Button--large Button--expand Button--auth reset-password-button #{cx 'reset-password-button--error': @state.error}", type: 'submit', disabled: (not flipped), onClick: @sendForgot, tabIndex: tabIndex,
              if @state.sendingForgot then spinner() else 'Reset Password'
            if @state.error
              span key: 'fail-error', className: 'error', 'aria-live': 'assertive', @state.error
          ]
        div className: 'input-bottom inv-input-bottom',
          tch tagName: 'a', href: '#', tabIndex: tabIndex, className: 'Button--pointer', 'aria-disabled': (not flipped), handler: @flip, 'Go to Sign In'

module.exports = SignInResetForm
