{span} = Exim.DOM
ReactDOM = require 'react-dom'
DELAY = 500

FontSizeToFit = Exim.createView
  componentDidMount: ->
    @update()
    @setTm()

  componentWillUnmount: ->
    clearTimeout(@tm)

  update: ->
    node = ReactDOM.findDOMNode @
    parNode = node.parentNode.parentNode
    parNode.style.opacity = 0
    baseWidth = parseInt(window.getComputedStyle(parNode, null).width)
    base = if baseWidth < 60 then 60 else baseWidth
    if node.offsetWidth >= base
      text = @props.children
      while (@currentFontSize() >= 12 and node.offsetWidth + 2 >= base)
        currFontSize = if node.style.fontSize then parseInt(node.style.fontSize) else @currentFontSize()
        node.style.fontSize = currFontSize - 1 + 'px'
    parNode.style.opacity = 1
    @updated = true

  setTm: ->
    fn = =>
      @update() unless @updated
    @tm = setTimeout fn, DELAY

  currentFontSize: ->
    node = ReactDOM.findDOMNode @
    size = window.getComputedStyle(node, null).fontSize
    parseInt(size)

  render: ->
    text = @state?.limText || @props.children
    span null, @props.children

module.exports = FontSizeToFit
