request = require 'lib/request'

site = Exim.createStore
  path: 'site'
  actions: [
    'fetch'
  ]

  initial:
    site:
      name: 'Cupertino'

  fetch:
    while: (siteFetching) ->
      @set {siteFetching}
    on: (args) ->
      request.get('site')
    did: (site) ->
      @set {site}
    didNot: (site) ->
      console.error 'Cannot fetch the current site'
      @set site: 'ast'

module.exports = site
