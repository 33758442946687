{appStore} = require 'stores'

{div, p, strong, tch, br} = Exim.DOM

FacebookRetrofitNotification = Exim.createView module.id,
  staticView: true
  noHeader: true
  fadeIn: true

  render: ->
    div "data-e2e": "fbny-retrofit", null,

      p null,
        strong "Please visit " 
        tch tagName: 'a', href: 'https://care.crossoverhealth.com', "care.crossoverhealth.com"
        strong " to schedule an appointment or to start a Conversation with the Crossover Care Team."

      p null,
        strong "Your login and password will remain the same."
      
      p null,
        strong "Need help or have tech issues?"
        div null,
          strong "Connect with a member of the Crossover Care team at 866-271-3589"

      p null,
        "Note: You can still access your medical history and documents created prior to #{@props.retrofitDate} on "
        tch tagName: 'a', href: 'https://go.crossoverhealth.com', "go.crossoverhealth.com"

module.exports = FacebookRetrofitNotification