"use strict";
const Avatar = require("./avatar");
const Appointment = require("./appointment");

class Provider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      updateExpanded: false,
    };
  }

  componentWillUpdate() {
    if (!this.state.updateExpanded) {
      this.setState({ updateExpanded: true })
    }
  }

  componentDidUpdate() {
    if (this.state.updateExpanded) {
      this.setState({ updateExpanded: false })
    }
  }

  renderProvider() {
    let { provider } = this.props;

    return (
      <div className="ProviderSchedule-whois">
        <div className="ProviderSchedule-whois-avatar">
          <Avatar provider={provider} />
        </div>
        <div className="name">{provider.name}</div>
        <div className="ExpandControls">
          <button
            className="Button"
            onClick={() => this.setState({ isExpanded: false })}
          >
            Collapse All
          </button>
          <button
            className="Button ExpandButton"
            onClick={() => this.setState({ isExpanded: true })}
          >
            Expand All
          </button>
        </div>
      </div>
    );
  }

  renderAppts() {
    let { provider } = this.props;
    let renderAppt = (appt) => {
      return (
        <Appointment
          key={appt.id}
          expanded={this.state.isExpanded}
          updateExpanded={this.state.updateExpanded}
          provider={provider}
          appointment={appt}
        />
      );
    };

    return (
      <div className="ProviderSchedule-appointments-table Table Table--bordered">
        <div className="TableBody">{provider.appointments.map(renderAppt)}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="ProviderSchedule">
        {this.renderProvider()}
        {this.renderAppts()}
      </div>
    );
  }
}

module.exports = Provider;
