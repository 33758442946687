{div, h1, h2, a, p, ul, li, strong, tch, button} = Exim.DOM

TextUpdatesModal = Exim.createView module.id,
  render: ->
    div id: 'sms-reminders-modal', className: 'u-scroll',
      h2 style: {fontSize: '14px'}, 'Your number will be kept private.'
      p 'Text messages will be sent within 5 hours of your appointment, excluding night-time hours.'
      p 'You can disable texting in your profile.'
      h2 style: {fontSize: '14px'},'Terms of Service: '
      p 'By entering your phone number, you acknowledge that you agree to the terms of service and are subscribed to receive text message appointment reminders until you send STOP to this subscription service. I confirm that I hold the account corresponding to the mobile phone number I have entered, or that I have the account holder\'s permission to use this service.'
      p 'Please note text messages may not be compatible with all handsets. Subscription enables you to receive appointment reminders. Standard Message & Data Rates may apply. To cancel, go to your profile and unsubscribe in the contact section.'
      if @props.footer
        div style: {display: 'flex', justifyContent: 'space-evenly'},
          button className: 'Button', style: {flex: 0}, onClick: @props.close,
            'Decline'
          button className: 'Button Button--primary', style: {flex: 0}, onClick: @props.confirm,
            'Confirm'

module.exports = TextUpdatesModal
