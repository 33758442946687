MessagesList   = require './MessagesList'
Messages       = require 'components/mixins/Messages'
Tooltip        = require 'components/elements/Tooltip'
ActionDropdown = require 'components/elements/ActionDropdown'
media          = require 'components/mixins/MediaQuery'
{actionToIconMap} = require './messageUtils'
{appStore, messagesStore} = require 'stores'
{client, features, welcomeMessage, messageFlags, messageFlagsPlaceholder} = require 'config'

utils = require 'lib/utils'
{cx}  = Exim.helpers
{div, tch, fa, span, tr, td} = Exim.DOM
{keyboardScrollTable}  = require('lib/utils/keyboardScrollTable')

type = 'received'

InboxMessage = Exim.createView module.id,
  type: type

  mixins: [
    messagesStore.connect('zoneAbbr')
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  show: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    name = @props.data?.from?.name
    avatar_url = @props.data?.from?.avatar_url
    subject = @props.data?.subject
    page = @props.page
    @context.router.push pathname: "/messages/#{@type}/#{@props.data.id}", query: {name, avatar_url, subject, page}

  getInitialState: ->
    lastActionHover: false

  getDefaultProps: ->
    data: subject: ''

  mouseEnter: (evt) ->
    @setState lastActionHover: true

  mouseLeave: (evt) ->
    @setState lastActionHover: false

  flag: (message, flag) -> =>
    data = {}
    message.flags = if message.flags is flag then '' else flag
    {flags} = message

    messagesStore.actions.update(type, message, {flags}, true)

  getFlags: ->
    {data} = @props
    utils.clone messageFlags?.map (f) => f.handler = @flag(data, f.id); f

  render: ->
    {data, matcher} = @props

    opts = cx
      'is-unread': data.unread
      attachments: data.document_ids?.length > 0

    flag = messageFlags.find((f) => f.id == data.flags)
    flags = @getFlags()

    name = utils.highlightMatched(data.from?.name, matcher)
    subject = utils.highlightMatched(utils.getSubject(data.subject), matcher)

    div className: "TableBody-row MessagesList-item tr #{opts}",
      tch role: 'row', key: data.id, className: "MessagesList-item MessagesList-item-info #{opts}", id: @props.messageNumber, handler: @show, onEnter: @show, tabIndex: 0, 'aria-label': "Row #{@props.messageNumber}, from #{data.from?.name}, subject #{data.subject}, received #{utils.formatDateShort(data.date)} #{if @state.zoneAbbr then (@state.zoneAbbr) else ''}, click to read full message",
        div className: 'TableBody-column MessagesList-item-flag', role: 'gridcell',
          ActionDropdown
            className: 'MessageCardTitle-actionsItem ActionDropdown--flags'
            values: flags
            select: true
            selected: data.flags
            placeholder: messageFlagsPlaceholder
            isMessages: true
            elementId: @props.messageNumber
        div className: 'TableBody-column MessagesList-item-title u-1of3', role: 'gridcell',
          if data.lurker
            fa 'user'
          name
          if data.last_action
            span className: 'MessageList-item-last-action', onMouseEnter: @mouseEnter, onMouseLeave: @mouseLeave,
              fa "mail-#{actionToIconMap(data.last_action)}"
              Tooltip({ type: 'text', text: utils.formatDateShort(data.last_action_at) }) if @state.lastActionHover
        div className: 'TableBody-column MessagesList-item-subject u-1of3', role: 'gridcell',
          if data.attachments
            span null,
              fa 'paperclip'
          subject
        div className: "TableBody-column MessagesList-item-dateTime u-1of3 #{cx 'no-flag': !flag}", role: 'gridcell',
          utils.formatDateShort(data.date)
          " "
          @state.zoneAbbr

module.exports = Inbox = Exim.createView module.id,
  mixins: [Messages]
  type: type

  componentWillMount: ->
    appStore.actions.updateHeader({name: 'Inbox'})
    messagesStore.actions.displayTZ()

  componentDidMount: ->
    appStore.actions.setTitle('Inbox Messages')
    document.querySelector('#contentSearchInput')?.focus()
    document.addEventListener('keyup', keyboardScrollTable)

  componentWillUnmount: ->
    appStore.actions.setTitle()
    document.removeEventListener('keyup', keyboardScrollTable)

  getInboxMessages: ->
    messages = @getMessages()

    if client isnt 'apple' and features.welcomeMessage and welcomeMessage and @props.loaded and messages?.length is 0 and not @state.filter
      messages = [welcomeMessage]

    messages

  render: ->
    MessagesList
      messages: @getInboxMessages()
      loading: @isFetching() and not @state.receivedMessagesFetched
      View: InboxMessage
      fields: ['from', 'subject', 'received']
      pageControls: @pageControls
      page: @state[@type].page
      pages: @state[@type].pages
      type: @type
      sort: @props.sort
      onSortChange: @props.onSortChange
      onLoad: @props.onLoad
      onUnload: @props.onUnload
      onSearch: @props.onSearch
      clearSearch: @props.clearSearch
      matcher: @props.matcher
      applyFilter: @applyFilter
      filter: @state.filter
