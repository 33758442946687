/* global Exim, React, moment */
const { PropTypes } = React;
import { configureSurvey, setStartPage, setQuestionError } from "lib/utils";
import * as SurveyJS from "survey-react";

class ScreeningInteraction extends React.Component {
  constructor(props) {
    super(props);
    configureSurvey(SurveyJS);

    const model = this.handleCreateModel(props.template);
    this.state = { model };

    this.handleCompleted = this.handleCompleted.bind(this);
    this.handleCreateModel = this.handleCreateModel.bind(this);
    this.handleValueChanged = this.handleValueChanged.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.template.completed !== this.props.template.completed) {
      const model = this.handleCreateModel(nextProps.template);
      this.setState = { model };
    }
  }

  handleCreateModel({ tmpl, values, completed = "edit" }) {
    values = typeof values === "string" ? {} : values;
    let model = new SurveyJS.Model(tmpl.data);
    Object.assign(model, {
      title: tmpl.title,
      data: values,
      mode: completed,
      clearInvisibleValues: "none",
    });

    if (completed !== "display") {
      model = setStartPage(model);
    }
    model = setQuestionError(model);
    return model;
  }

  handleCompleted(survey, options) {
    const { onValueChanged, onCompleted } = this.props;
    survey.mode = "display";

    onValueChanged(survey);
    setTimeout(onCompleted(survey), 100);
  }

  handleValueChanged(survey, options) {
    const { onValueChanged } = this.props;

    onValueChanged(survey);
  }

  render() {
    const { model } = this.state;

    return (
      <section id="SurveyJS">
        <SurveyJS.Survey
          model={model}
          onComplete={this.handleCompleted}
          onValueChanged={this.handleValueChanged}
        />
      </section>
    );
  }
}

ScreeningInteraction.propTypes = {
  onValueChanged: PropTypes.func,
  onCompleted: PropTypes.func,
  template: PropTypes.shape({
    values: PropTypes.any,
    tmpl: PropTypes.shape({
      title: PropTypes.string,
      status: PropTypes.string,
      id: PropTypes.number.isRequired,
      data: PropTypes.string.isRequired,
    }),
  }),
};
ScreeningInteraction.defaultProps = {
  onValueChanged: () => undefined,
  onCompleted: () => undefined,
  template: null,
};

export default ScreeningInteraction;
