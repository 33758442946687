'use strict'
{div, span, tch, fa, label} = Exim.DOM
{observer} = require 'mobx-react'
apptsStore = require 'stores/new-appts'

class ScheduleTags extends React.Component
  removeProvider: ->
    apptsStore.filter.provider = null

  removeType: ->
    apptsStore.filter.type = null

  removeFilter: ->
    apptsStore.filter = {
      provider: null,
      type: null,
    }

  render: ->
    {provider, type} = apptsStore.filter
    return null if !(provider || type)

    return div className: 'ScheduleTags',
      span className: 'ScheduleTags-label', 'Refining by:'
      if provider
        span className: 'ScheduleTag',
          label provider.name
          tch handler: this.removeProvider, fa 'times-circle'
      if type
        span className: 'ScheduleTag',
          label type
          tch handler: this.removeType, fa 'times-circle'
      tch className: 'ScheduleTags-clearAll', handler: this.removeFilter,
        'Clear All'

module.exports = observer(ScheduleTags)
