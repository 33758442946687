import { Input } from ".";

const CheckBox = (props) => {
  let { children, ...inputProps } = props;

  return (
    <label className="NewCheckBox-wrapper">
      <span className="NewCheckBox">
        <Input type="checkbox" {...inputProps} />
        <span className="NewCheckBox-box" aria-hidden="true" />
      </span>
      {children}
    </label>
  );
};

export default CheckBox;
