"use strict";
const { observer } = require("mobx-react");
const authStore = require("stores/new-auth");
import { withFeatureFlag } from "components/util/FeatureFlag";

const AMPPlatformBanner = () => {
  let { me } = authStore;
  if (me.isAdmin) return null;

  return (
    <div className="AMPBanner">
      <span className="AMPBanner-message">
        Hi there! You now have access to Crossover Health's virtual care
        services. Please setup your account{" "}
        <a
          className="AMPBanner-link"
          target="_blank"
          href="https://care.crossoverhealth.com"
        >
          here
        </a>{" "}
        to be able to start online care with Crossover providers.
      </span>
    </div>
  );
};

module.exports = withFeatureFlag(observer(AMPPlatformBanner), "amat_hra");
