{appStore, authStore} = require 'stores'

Validate = require 'components/mixins/Validate'
Tooltip = require 'components/elements/Tooltip'
Success = require 'components/auth/Success'
AuthWrapper = require 'components/auth/auth-wrapper'
utils = require 'lib/utils'

{cx} = Exim.helpers
{div, h2, p, input, spinner, tch, form, button, span} = Exim.DOM

Reset = Exim.createView module.id,
  mixins: [Validate]
  listen: ['auth/sendingReset', 'auth/resetSuccess', 'auth/resetError']

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    tooltip: {}
    sent: false

  required: ['password', 'password_confirmation']

  componentDidMount: ->
    appStore.actions.setTitle('Reset Password')
    authStore.actions.clearPasswordReset()
    href = location.href.split('/')
    @code = href[href.length - 1]
    document.body.addEventListener('keydown', @onKeyDown)

  componentWillUnmount: ->
    appStore.actions.setTitle()
    document.body.removeEventListener('keydown', @onKeyDown)

  tooltipFor: (type) -> (evt) =>
    target = evt.currentTarget
    value = target.value
    position = top: target.offsetTop , left: target.offsetLeft + 290
    matches = @getComplexChecker('password')(value)
    @setState tooltip: {type, matches, position, animate: true}

  hideTooltip: ->
    @setState tooltip: {}

  sendReset: (e) ->
    e.preventDefault()
    {password, password_confirmation} = @state

    for key in ['password', 'password_confirmation']
      @removeErrors key
      @validate key

    if @state.errors.length > 0
      @forceUpdate()
      if (@state.errors[0] is 'password')
        document.getElementById('labeled-input-password')?.focus()
      else if (@state.errors[0] is 'password_confirmation')
        document.getElementById('labeled-input-password_confirmation')?.focus()
      return false
    else
      authStore.actions.resetPassword(@code, password).then ->
      @setState sent: true

  onKeyDown: (evt) ->
    if (evt.which is 13) and (evt.srcElement.id != "backToSignIn") and (evt.srcElement.id != "phoneLink")
      @sendReset(evt)

  leave: ->
    @context.router.push '/signin'

  render: ->
    return React.createElement(AuthWrapper, {title: 'Reset Password'},
      div className: "AuthCard #{cx error: @state.sent and !@state.resetSuccess}",
          form className: "AuthResetForm", onSubmit: @sendReset, autoComplete: 'off',
            unless @state.sent
              [
                @labeledInput 'password', 'New Account Password', type: 'password', error: 'New account password is a required field'
                div className: 'pw-approval' + @errClass('password'),
                  span className: 'invisible', 'aria-live': 'assertive', if ('password' in (@state.errors or [])) then 'Weak password' else 'Password is fine'
                  span className: @pwApproval('chars'), '12+ long'
                  ' \u00a0'
                  span className: @pwApproval('lower'), 'lowercase'
                  ' \u00a0'
                  span className: @pwApproval('upper'), 'uppercase'
                  ' \u00a0'
                  span className: @pwApproval('number'), 'number'

                @labeledInput 'password_confirmation', 'Password Confirmation', type: 'password', error: 'Password confirmation is a required field'

                button className: 'Button Button--primary Button--large Button--expand Button--auth', type: 'submit',
                  'Reset Password'

                div id: "backToSignIn", tabIndex: "0", className: 'Button--pointer Auth--informational-link Auth--informational-link-footer', onKeyPress: utils.a11yClick(@leave), onClick: @leave, 'Back to Sign In'
              ]
            else
              [
                div 'aria-live': 'assertive',
                  if @state.sendingReset
                    spinner()
                  else
                    if @state.resetSuccess
                      Success()
                    else
                      [
                        h2 'Whoops!'
                        if err = @state.resetError
                          p className: 'check', err
                        else
                          p className: 'check', 'This password reset link is expired or invalid. Please try the process again.'
                        if help = appStore.get('config').app?.helpHTML
                          p dangerouslySetInnerHTML: {__html: help.replace('<a ', '<a id= "phoneLink"')}
                        div className: 'input-bottom inv-input-bottom',
                          div id: "backToSignIn", tabIndex: "0", onKeyPress: utils.a11yClick(@leave), onClick: @leave, 'Return to Sign In'
                      ]
              ]
    )

module.exports = Reset
