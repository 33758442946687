import hraStore from "stores/hra-survey";
import { observer } from "mobx-react";
import StepsNav from "components/elements/steps-nav";

class HraNav extends React.Component {
  render() {
    const { magicToken, isMobile } = this.props;
    const { lastStepIndex } = hraStore;
    const steps = hraStore.steps;

    let toNav = (step, index) => ({
      content: step.title,
      to: `/surveys/hra/${magicToken}/${step.url}`,
      isCompleted: index <= lastStepIndex,
      isDisabled: true,
      isActive: step.isActive,
    });

    return (
      <StepsNav
        modifier={{ hra: true, checkIn: true }}
        steps={steps.map(toNav)}
        isMobile={isMobile}
        mobileResponsive={true}
        showProgress={false}
        newStyle={true}
        className={"StepsNav-steps--hra"}
      />
    );
  }
}

export default observer(HraNav);
