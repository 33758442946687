request = require 'lib/request'
types = [
  'diagnoses'
  'conditions'
  'medications'
  'allergies'
]

initial = ->
  obj = {}
  for key in types
    obj[key] = items: []
    obj["#{key}Loading"] = true
  obj

patientData = Exim.createStore
  actions: [
    'fetch'
    'fetchSingle'
    'clear'
    'removeDiagnosis'
  ]

  initial: initial

  fetch: ->
    Promise.all types.map (type) =>
      @actions.fetchSingle(type)

  fetchSingle:
    while: (state) ->
      @set "#{@type}Loading", state
    will: (type) ->
      @type = type
    on: ->
      request.get("patient/#{@type}")
    did: (data) ->
      data.items ?= []
      @set @type, data
    didNot: (xhr) ->
      if typeof xhr.json is 'function'
        xhr.json().then((json) -> console.error json.error)
      else
        console.error xhr

  clear: ->
    @set initial()

  removeDiagnosis:
    on: (@code) ->
      request.del('patient/diagnoses', {@code})
    did: ->
      diagnoses = @get('diagnoses')
      diagnoses.items = diagnoses.items.filter (diag) => diag.code isnt @code
      @set {diagnoses, dataDate: new Date}


module.exports = patientData
