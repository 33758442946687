"use strict";
const {
  withEscapeHandler,
  closeModal,
} = require("components/elements/new-modal");

class ConfirmSurveyExit extends React.Component {
  render() {
    const { onExit, onContinue = closeModal } = this.props;

    const onClose = () => {
      closeModal();
      onExit();
    };

    return (
      <div className={`NewModal-wrapper`}>
        <div className={`NewModal-overlay`} style={{ background: "#2227" }} />
        <div className={`NewModal`} style={styles.wrapper} role="dialog">
          <div style={styles.text}>
            <div>
              <span>You are about to exit an incomplete survey. </span>
            </div>
            <div>
              <span>
                Your responses will be saved and you can pick back up where you
                have left off.{" "}
              </span>
            </div>
          </div>
          <div>
            <button style={styles.button} onClick={onClose}>
              EXIT
            </button>
            <button style={styles.button} onClick={onContinue}>
              CONTINUE SURVEY
            </button>
          </div>
        </div>
      </div>
    );
  }
}

module.exports = withEscapeHandler(ConfirmSurveyExit);

const styles = {
  wrapper: {
    width: "508px",
    height: "207px",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "rgba(244, 113, 38, 1)",
    borderRadius: "5px",
    padding: "20px",
  },

  text: {
    width: "100%",
    color: "#F47126",
    margin: "0 0 70px 0",
    textAlign: "center",
    wordWrap: "break-word",
    lineHeight: "normal",
    letterSpacing: "1px",
    fontFamily: "ArialMT, Arial, sans-serif",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "18px",
  },

  button: {
    width: "181px",
    height: "40px",
    display: "inline-block",
    backgroundColor: "rgba(121, 166, 54, 1)",
    boxSizing: "border-box",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(170, 170, 170, 1)",
    borderRadius: "5px",
    color: "#FDF9F3",
    margin: "0 25px",
    fontSize: "15px",
    textAlign: "center",
  },
};
