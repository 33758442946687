import hraStore from "stores/hra-survey";
import Card from "./card";
import { cardioResults } from "config";
import Graph from "./graph";

class CardioVascularResults extends React.Component {
  renderResultsText(value, { green, yellow, red }) {
    let score = null;
    if (value < green.high * 100) {
      score = "low";
    } else if (value >= yellow.low * 100 && value < yellow.high * 100) {
      score = "medium";
    } else if (value >= red.low * 100) {
      score = "high";
    }
    return cardioResults[score];
  }

  renderSkippedText() {
    const { skipped_reason } = this.props;
    return cardioResults[skipped_reason];
  }

  render() {
    const { title, value, scale, status } = this.props;
    const keys = ["low", "medium", "high", "your_score"];
    const legendLabels = ["Low Risk", "Medium Risk", "High Risk", "Your Score"];
    const data = hraStore.graphData("cv_risk_score");
    const tickFormat = (tick) => (tick == 60 ? `>${tick}%` : `${tick}%`);

    return (
      <Card title={title} iconClass="fa-heartbeat">
        <div className="Hra-body">
          {cardioResults.intro} <br />
          {status === "done" && (
            <React.Fragment>
              <br />
              <span>
                <b>10-year risk of heart disease or stroke: </b>
                {value}%
              </span>{" "}
              <br />
              <span className="Hra-result--cvr">
                {this.renderResultsText(value, scale)}
              </span>
              <div className="Results--graph">
                <Graph
                  data={data}
                  keys={keys}
                  legendLabels={legendLabels}
                  rangeThreshhold={{
                    low: scale.green.low,
                    high: scale.red.high * 100,
                  }}
                  xAxisLabel={"Risk"}
                  labelClassName={"Hra-axisLabel"}
                  tickFormat={tickFormat}
                />
              </div>
            </React.Fragment>
          )}
          {status === "skipped" && (
            <React.Fragment>
              <span className="Hra-result--cvr">
                {" "}
                <br />
                {this.renderSkippedText()}
              </span>
              <br />
            </React.Fragment>
          )}{" "}
          <br />
          {cardioResults.disclaimer}
          <hr className="Hra-divider" />
        </div>
      </Card>
    );
  }
}

export default CardioVascularResults;
