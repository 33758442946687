'use strict'
{p, div, span, button, footer} = Exim.DOM
{Modal} = require 'components/elements/new-modal'

DeleteSlotSeriesModal = ({abort, resolve}) =>
  return React.createElement(Modal, {
    className: 'DeleteSeriesSlotModal',
    title: 'Delete slot(s) in a recurring series',
    close: abort,
  },
    p className: 'DeleteSeriesSlotModal-text',
      'Would you like to delete only this slot, delete this and all future slots, or delete the entire slot series?'
    div className: 'DeleteSeriesSlotModal-actions',
      div className: 'DeleteSeriesSlotModal-action',
        button {
          className: 'Button Button--primary',
          onClick: () => resolve('one'),
        }, 'Only this slot'
        span className: 'DeleteSeriesSlotModal-actionTitle',
          'Only this slot will be deleted.'
      div className: 'DeleteSeriesSlotModal-action',
        button {
          className: 'Button Button--primary',
          onClick: () => resolve('future'),
        }, 'All future slots'
        span className: 'DeleteSeriesSlotModal-actionTitle',
          'This and all future slots will be deleted.'
      div className: 'DeleteSeriesSlotModal-action',
        button {
          className: 'Button Button--primary',
          onClick: () => resolve('all'),
        }, 'All slots in the series'
        span className: 'DeleteSeriesSlotModal-actionTitle',
          'All slots in this series will be deleted.'
    footer className: 'DeleteSeriesSlotModal-footer',
      button {
        className: 'Button Button--cancel',
        onClick: abort,
      }, 'Discard this change'
  )

module.exports = DeleteSlotSeriesModal
