{appStore} = require 'stores'

{cx} = Exim.helpers
{div, h2, p, tch} = Exim.DOM

Success = Exim.createView module.id,
  contextTypes:
    router: React.PropTypes.object.isRequired

  onSuccess: ->
    @context.router.push '/signin'

  render: ->
    title = appStore.get('config')?.app?.title1

    return div null,
      h2 'Congratulations!'
      if title
        p className: 'check', @props.welcomeMessage
      div className: 'spacer'
      div className: 'input-bottom inv-input-bottom success-input-bottom',
        tch tagName: 'a', href: '#', handler: @onSuccess, 'Return to Sign In'

module.exports = Success
