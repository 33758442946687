import { observer } from "mobx-react";
import { Input } from "components/elements";

class ReadOnly extends React.Component {
  render() {
    const { label, name, text } = this.props;

    return (
      <label className="AboutStep-field AboutStep-field--read-only">
      {label}
      <Input
        className="Display" name={name} data-e2e={`read-only-${name}`}
        value={text}
        readOnly
      />
    </label>
    );
  }
}

export default observer(ReadOnly);
