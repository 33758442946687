"use strict";
const patientStore = require("stores/patient");
const oldAppStore = require("stores/app");
const appStore = require("stores/new-app");
const authStore = require("stores/new-auth");
const ContentHeader = require("./ContentHeader");
const ErrorBoundary = require("./ErrorBoundary").default;
const Sidebar = require("./sidebar");
const PatientHeader = require("../elements/PatientHeader");
const { cx } = require("lib/utils");
const { observer } = require("mobx-react");
const { mdDown, mdToLg } = require("lib/media-queries");
const withMediaQueries = require("components/elements/with-media-queries");
const SnapContainer = require("components/elements/snap-container");
const { default: AppBanners } = require("./AppBanners");

const closeSidebar = () => {
  appStore.isSidebarOpen = false;
};

const SNAP_CLOSED = 0;

const PortalWrapper = React.createClass({
  displayName: "PortalWrapper",
  mixins: [oldAppStore.connect("header")],

  getInitialState() {
    return {
      snapTo: SNAP_CLOSED,
      sidebarWidth: 0,
      bannerHeight: 0,
    };
  },

  componentWillMount() {
    patientStore.actions.fetch();
  },

  handleResize(sidebarWidth) {
    this.setState({ sidebarWidth });
  },

  handleSnap(snapTo) {
    switch (snapTo) {
      case SNAP_CLOSED:
        appStore.isSidebarOpen = false;
        break;

      case this.state.sidebarWidth:
        appStore.isSidebarOpen = true;
        break;
    }
  },

  getPageHeight() {
    return `calc(100% - ${this.state.bannerHeight}px)`;
  },

  renderHeader() {
    let { header } = this.state;
    if (header) {
      header.location = this.props.location;
      return <ContentHeader {...header} />;
    }
  },

  renderOverlay() {
    if (this.props.isTablet && appStore.isSidebarOpen) {
      return (
        <button
          className="Master-overlay"
          aria-label="Close sidebar"
          onClick={closeSidebar}
        />
      );
    }
  },

  render() {
    let { isSidebarOpen } = appStore;
    let { router, isPhone } = this.props;
    let masterClass = cx("Master", {
      admin: authStore.me.isAdmin,
      revealSidebar: isSidebarOpen,
      hiddenOnPrint:
        router.isActive("appointments") ||
        router.isActive("profile") ||
        router.isActive("trends") ||
        router.isActive("schedule"),
    });

    let Wrapper = isPhone ? SnapContainer : "div";
    let wrapperProps = isPhone
      ? {
          isSwipeable: true,
          snapPoints: [SNAP_CLOSED, this.state.sidebarWidth],
          snapTo: appStore.isSidebarOpen
            ? this.state.sidebarWidth
            : SNAP_CLOSED,
          onSnap: this.handleSnap,
        }
      : {};

    return (
      <React.Fragment>
        <ErrorBoundary router={router}>
          <AppBanners
            onBannerHeightChange={(bannerHeight) =>
              this.setState({ bannerHeight })
            }
          />
          <div className={masterClass} style={{ height: this.getPageHeight() }}>
            <Sidebar onResize={this.handleResize} />
            <Wrapper className="Master-wrapper" {...wrapperProps}>
              {this.renderHeader()}
              {authStore.isProxying &&
                PatientHeader({ className: "PatientHeader--tablet" })}
              {this.props.children}
            </Wrapper>
            {this.renderOverlay()}
          </div>
        </ErrorBoundary>
      </React.Fragment>
    );
  },
});

module.exports = withMediaQueries(observer(PortalWrapper), {
  isPhone: mdDown,
  isTablet: mdToLg,
});
