import withDirty from "./with-dirty";

export default withDirty(
  (props) => {
    let { className = "", ...inputProps } = props;

    return <textarea className={`NewInput ${className}`} {...inputProps} />;
  },
  { type: "onFocus" }
);
