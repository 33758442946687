"use strict";
const { observer } = require("mobx-react");
const { ISO_DATE } = require("lib/formats");
const { formats } = require("config");
const apptsStore = require("stores/new-appts");
const ContentHeader = require("components/elements/content-header");
const ScheduleFilter = require("./schedule-filter");
const Search = require("./search");
const withMediaQueries = require("components/elements/with-media-queries");
const { mdDown, mdToXl } = require("lib/media-queries");

const changeSearch = (event) => {
  apptsStore.search = event.target.value;
};

class ScheduleHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchFocused: false,
    };
  }

  renderTitle() {
    let { isPhone, isTablet } = this.props;
    if (isPhone && this.state.isSearchFocused) return "";
    if (isPhone || isTablet) return "Schedule";

    return "Daily Schedule";
  }

  renderDate() {
    let { isPhone, isTablet } = this.props;
    if (isPhone) return;

    let { date } = apptsStore;
    return (
      <time className="ScheduleHeader-date" dateTime={date.format(ISO_DATE)}>
        {date.format(isTablet ? formats.date : formats.fullDate)}
      </time>
    );
  }

  renderToday() {
    return (
      <button
        className="ScheduleHeader-today"
        disabled={apptsStore.isNow()}
        onClick={() => apptsStore.now()}
        aria-label="Today"
      />
    );
  }

  renderSearch() {
    return (
      <Search
        placeholder="Search Daily Schedule..."
        modifier={this.props.isPhone && this.state.isSearchFocused && "grow"}
        value={apptsStore.search}
        onChange={changeSearch}
        onFocus={() => this.setState({ isSearchFocused: true })}
        onBlur={() => this.setState({ isSearchFocused: false })}
      />
    );
  }

  render() {
    return (
      <ContentHeader title={this.renderTitle()}>
        {this.renderDate()}
        <div className="ScheduleHeader-actions">
          {this.renderToday()}
          {this.renderSearch()}
          <ScheduleFilter />
        </div>
      </ContentHeader>
    );
  }
}

module.exports = withMediaQueries(observer(ScheduleHeader), {
  isPhone: mdDown,
  isTablet: mdToXl,
});
