"use strict";
const { extendObservable } = require("mobx");
const request = require("lib/new-request");
const NO_CONTENT = 204;

let nextId = 0;

class Model {
  static fromAPI(data, opts = {}) {
    if (!data.id && !opts.allowMissingId) {
      console.error(data);
      throw new Error(`${this.name}.fromAPI should pass "id".`);
    }

    return new this(data);
  }

  static get(params = {}) {
    let { path = this.endPoint, data, opts = {} } = params;

    if (!opts.mock && this.mock) {
      opts.mock = this.mock;
    }

    return request.get(path, data, opts);
  }

  constructor(params) {
    return extendObservable(this, {
      id: --nextId,
      ...params,
    });
  }

  get _defaultPath() {
    let path = this.constructor.endPoint;
    if (this.isCreated) {
      path += `/${this.id}`;
    }

    return path;
  }

  get isCreated() {
    return this.id > 0;
  }

  async get(params) {
    let { path, opts } = this._withDefaults(params);
    return request.get(path, opts);
  }

  async fetch(params) {
    let { path, opts } = this._withDefaults(params);
    let res = await request.get(path, opts);
    this.updateFromAPI(res);
  }

  async post(params) {
    let { path, data, opts, update } = this._withDefaults(params);
    let res = await request.post(path, data, opts);
    if (update) this.updateFromAPI(res);
    return res;
  }

  async patch(params) {
    let { path, data, opts } = this._withDefaults(params);
    let res = await request.patch(path, data, opts);
    this.updateFromAPI(res);
    return res;
  }

  async update(params) {
    let { path, data, opts } = this._withDefaults(params);
    let res = await request.put(path, data, opts);
    this.updateFromAPI(res);
    return res;
  }

  async put(params) {
    let { path, data, opts, update } = this._withDefaults(params);
    let res = await request.put(path, data, opts);
    if (update) this.updateFromAPI(res);
    return res;
  }

  async delete(params) {
    let { path, data, opts } = this._withDefaults(params);
    let res = await request.delete(path, data, {
      ...opts,
      json: true,
      return_response: true,
    });

    if (res.status == NO_CONTENT) {
      this.destroy();
      return;
    }

    let body = await res.json();
    this.updateFromAPI(body);
    return body;
  }

  _withDefaults(params = {}) {
    let {
      path = "&",
      query = {},
      opts = {},
      data = this.toJSON(),
      update = true,
    } = params;

    if (!opts.mock && this.constructor.mock) {
      opts.mock = this.constructor.mock;
    }

    path = path.replace("&", this._defaultPath);
    path += path.includes("?") ? "&" : "?";
    path += request.serialize(query);

    return {
      path,
      query,
      data,
      opts,
      update,
    };
  }

  destroy() {}

  updateFromAPI(raw) {
    Object.assign(this, this.constructor.fromAPI(raw));
  }

  clone() {
    return this.constructor.fromAPI(this.toJSON());
  }

  toJSON() {
    if (this.isCreated) {
      return { id: this.id };
    }
  }

  saveState() {
    return this.clone();
  }

  restoreState(model) {
    Object.assign(this, model);
  }

  is(model) {
    return this.constructor == model.constructor && this.id == model.id;
    // return this instanceof model.constructor && this.id == model.id
  }

  dropId() {
    this.id = --nextId;
  }
}

module.exports = Model;
