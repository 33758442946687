"use strict";
const { observer } = require("mobx-react");
const { transaction } = require("mobx");
const calStore = require("stores/cal");
const { MIN_IN_PX } = calStore;

const onDragMove = (fn) => {
  let prevY;

  return (evt) => {
    evt.preventDefault();
    let y = evt.clientY;
    if (y == prevY) return;

    prevY = y;
    fn(evt);
  };
};

const handleDragOver = onDragMove((evt) => {
  evt = evt.nativeEvent;
  if (calStore.creating) {
    creatingSlotContinue(evt);
  } else if (calStore.moving) {
    movingSlotContinue(evt);
  }
});

const creatingSlotContinue = (evt) => {
  let { startY, slot } = calStore.creating;
  let { start } = calStore.displayRange;

  transaction(() => {
    let startTime = Math.min(startY, evt.offsetY) / MIN_IN_PX;
    slot.startTime = start.clone().add(startTime, "minutes");

    let endTime = Math.max(startY, evt.offsetY) / MIN_IN_PX;
    slot.endTime = start.clone().add(endTime, "minutes");
  });
};

const movingSlotContinue = (evt) => {
  let { startY, slot } = calStore.moving;
  let { start } = calStore.displayRange;

  let startTime = (evt.offsetY - startY) / MIN_IN_PX;
  slot.startTime = start.clone().add(startTime, "minutes");
};

class SlotDroppable extends React.Component {
  isValidFor(slot) {
    let { provider, date } = this.props;

    if (!slot.provider.is(provider)) return false;
    if (!slot.date.isSame(date) && slot.isRepeated) return false;
    if (slot.site.today > date) return false;

    return true;
  }

  handleDragEnter(evt) {
    if (calStore.creating) {
      evt.preventDefault();
    } else if (calStore.moving) {
      let { slot } = calStore.moving;
      if (this.isValidFor(slot)) {
        evt.preventDefault();
        slot.date = this.props.date;
      }
    }
  }

  render() {
    if (!calStore.creating && !calStore.moving) return null;

    return (
      <div
        className="ScheduleCalendarDay-droppable"
        onDragEnter={(evt) => this.handleDragEnter(evt)}
        onDragOver={(evt) => handleDragOver(evt)}
      />
    );
  }
}

module.exports = observer(SlotDroppable);
