'use strict'
{div, button, table, tbody, thead, tr, td, th, footer} = Exim.DOM
{observer} = require 'mobx-react'
{Modal} = require 'components/elements/new-modal'

DeleteBookedSlotModal = ({abort, appts, resolve}) =>
  return React.createElement(Modal, {
    close: abort,
    title: 'This slot is already booked',
    className: 'DeleteBookedSlotModal',
  },
    table className: 'NewTable',
      thead null,
        tr null,
          th null, 'Patient'
          th null, 'Status'
      tbody null,
        appts.map((appt) =>
          return tr key: appt.id,
            td null, appt.patient.name
            td null, appt.displayStatus
        )
    footer className: 'NewModal-footer',
      button {
        className: 'Button Button--cancel',
        onClick: abort,
      }, 'Cancel'
      button {
        className: 'Button Button--primary',
        onClick: () => resolve(true),
      }, 'Delete'
  )

module.exports = observer(DeleteBookedSlotModal)
