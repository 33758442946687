Hammer = require 'hammerjs'
{div} = Exim.DOM
{cx} = Exim.helpers
ReactDOM = require 'react-dom'

DBLCLICK_DELAY = 250
ANIM_DURATION = 300

posX=0
posY=0
scale=1
last_posX=0
last_posY=0


Zoom = Exim.createView module.id,
  getInitialState: ->
    touched: false
    touchdown: false
    coords: { x:0, y:0 }
    evObj: {}

  getDefaultProps: ->
    className: ''

  refresh: ->
    @setState transform: null

  componentDidUpdate: (nextProps, nextState) ->
    if @state.withAnim
      refreshAnim = => @setState withAnim: false
      setTimeout(refreshAnim, ANIM_DURATION)

  componentDidMount: ->
    mc = new Hammer.Manager(ReactDOM.findDOMNode(@))
    pinch = new Hammer.Pinch()
    rotate = new Hammer.Rotate()
    tap = new Hammer.Tap()
    pinch.recognizeWith(rotate)
    mc.add([pinch, rotate, tap])
    width = parseInt(getComputedStyle(ReactDOM.findDOMNode(@refs.wrapper)).width)
    max = width * 2
    min = width
    lastScale = 1
    endScale = 1
    prevent = false

    mc.on 'pinchstart', -> prevent = false

    mc.on 'pinch rotate', (ev) =>
      return if prevent
      {scale} = ev
      tempScale = scale

      posX = ev.deltaX
      posY = ev.deltaY
      scale = Math.max(.999, Math.min(endScale * (ev.scale), 4))
      if scale > 1
        transform = "translate3d(#{posX}px,#{posY}px, 0) scale3d(#{scale},#{scale}, 0) "
      else
        transform = null
      @setState {transform}
      lastScale = scale

    mc.on 'pinchend', (ev) ->
      prevent = true
      endScale = lastScale

    mc.on 'tap', (ev) =>
      if ev.tapCount is 2
        if endScale is 2
          endScale = 1
          scale = 1
        else
          endScale = 2
          scale = 2
        transform = "translate3d(0px, 0px, 0) scale3d(#{scale},#{scale}, 1) "
        @setState {transform, withAnim: true}
        lastScale = scale

  render: ->
    style = {transform: @state.transform, WebkitTransform: @state.transform, msTransform: @state.transform}
    className = "#{@props.className} zoom"
    Exim.DOM[@props.tagName || 'div'] className: className,
      div style: style, ref: 'wrapper', className: "wrapper #{cx anim: @state.withAnim}",
        @props.children

module.exports = Zoom
