class WellnessCard extends React.Component {
  render() {
    const { iconClass, children, title, className } = this.props;
    return (
      <div className={`MainWellnessCard ${className}`}>
        <div className="MainWellnessCard-icon">
          <i className={`fa icon ${iconClass}`} />
        </div>
        <div className="MainWellnessCard-body">
          <span className="MainWellnessCard-title">{title}</span>
          {children}
        </div>
      </div>
    );
  }
}

export default WellnessCard;
