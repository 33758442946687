'use strict'
{li, div, fa, span} = Exim.DOM
{observer} = require 'mobx-react'
{withRouter} = require 'react-router'
Calendar = require 'components/elements/Calendar'
authStore = require 'stores/new-auth'
synopsisStore = require 'stores/new-synopsis'
slotsStore = require 'stores/new-slots'
calStore = require 'stores/cal'
Link = require '../link'
Badge = require '../badge'

CAL_PATH = '/calendar'

class CalendarTab extends React.Component
  renderCalendar: ->
    return React.createElement(Calendar, {
      modifier: 'sidebar',
      date: calStore.date,
      mode: calStore.mode,
      onChange: (date) =>
        calStore.date = date
      isActive: (date) =>
        {site} = synopsisStore
        return site.isWorkingDay(date)
    })

  renderBadge: ->
    return React.createElement(Badge, {
      modifier: 'conflicts',
      count: slotsStore.conflictedAppts.length,
    })

  renderSubLinks: ->
    return React.createElement(React.Fragment, null,
      React.createElement(Link, {
        to: CAL_PATH,
        modifier: 'sub',
        onlyActiveOnIndex: true,
      },
        span className: 'SidebarNavLink-text', 'Schedule'
      ),
      React.createElement(Link, {
        to: "#{CAL_PATH}/conflicts",
        modifier: 'sub',
      },
        span className: 'SidebarNavLink-text', 'Conflicts'
        this.renderBadge()
      ),
    )

  render: ->
    {user} = authStore
    return null if !user.canReadCalendar

    isOpen = this.props.router.isActive(CAL_PATH)

    return li className: 'SidebarNav-item',
      isOpen && this.renderCalendar()
      React.createElement(Link, {to: CAL_PATH},
        span className: 'SidebarNavLink-icon', fa 'calendar'
        span className: 'SidebarNavLink-text', 'Calendar'
        !isOpen && this.renderBadge()
      )
      isOpen && this.renderSubLinks()

module.exports = withRouter(observer(CalendarTab))
