{appStore} = require 'stores'

{div, p, strong, tch} = Exim.DOM

RetrofitNotification = Exim.createView module.id,
  staticView: true
  noHeader: true
  fadeIn: true

  render: ->
    div null,
      p null,
        strong "Hi there!"

      p null,
        strong "Your account has moved to a better experience!"

      p null,
        strong "Visit "
        tch tagName: 'a', href: 'https://care.crossoverhealth.com', "care.crossoverhealth.com"
        strong " to schedule a new appointment or conversation with your Care Team."

      p null,
        strong "Your login and current appointments will remain the same."

      p null,
        "Note: You can still access your medical history and documents created prior to #{@props.retrofitDate} on "
        tch tagName: 'a', href: 'https://go.crossoverhealth.com', "go.crossoverhealth.com"

module.exports = RetrofitNotification
