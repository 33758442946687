import "./moment-round";
import { sortedIndex } from "lodash";

export { default as proxyPatient } from "./proxy-patient";
export { default as closePatient } from "./close-patient";

export { default as MomentRange } from "./moment-range";
export { default as computed } from "./computed";

export {
  configureEditor,
  configureSurvey,
  configureDefaultOptions,
  setStartPage,
  setQuestionError,
  disableLinkedField,
  setTableHeaderAttributes,
} from "./screenings-framework";
export { EximShim } from "./exim-shim";
export { JSXShim } from "./jsx-shim";
export { timezone } from "./timezone";

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const roundTo = (raw, to) => Math.round(raw / to) * to;
export const isPrimaryButton = (evt) => !!(evt.buttons & 1);

export const isArrayEql = (a, b, fn = (item) => item) => {
  if (a.length != b.length) return false;

  return a.every((item, index) => {
    return fn(item) == fn(b[index]);
  });
};

export const sortedFindClosest = (arr, item) => {
  let index = sortedIndex(arr, item);
  if (index == 0) return arr[0];
  if (index == arr.length) return arr[index - 1];

  let nextItem = arr[index];
  let nextDiff = Math.abs(nextItem - item);

  let prevItem = arr[index - 1];
  let prevDiff = Math.abs(prevItem - item);

  return nextDiff < prevDiff ? nextItem : prevItem;
};

export const linkItems = (items) => {
  items.reduce((a, b) => {
    a.next = b;
    b.prev = a;
    return b;
  });

  return items;
};

/*
FORMAT CURRENCIES
Calculate the locale and pass it in to a helper function via closure
Ideally should work for the USA and european locations (e.g. Cork)
*/

const locale = "en-US";
const currencyType = "USD";

// NOTE: You must explicitly cast strings args, otherwise this uses the string method, not the numeric variant
export const currency = (num = 0, digits = 0) => {
  return Number.parseFloat(num).toLocaleString(locale, {
    style: "currency",
    currency: currencyType,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

export const timeUnits = (date) => ({
  hour: date.hour(),
  minute: date.minute(),
  second: date.second(),
});

/*
ROLLBAR to log errors on portal
*/
export const logToRollbar = (error, info = "") => {
  if (!window.Rollbar) {
    return;
  }
  window.Rollbar.error("Portal Error", { error: error, info: info });
};

/*
Get Id from wellness program url
*/
export const getIdFromUrl = (url) => {
  return url.split("/").pop();
};

export const splitTransitionTileInfo = (transitionTileInfo) => {
  const header = transitionTileInfo.match(/<h3>(.*)<\/h3>/)[1];
  const body = transitionTileInfo.replace(/<h3>(.*)<\/h3>/, "");
  const faIcon = header.match(/<i class="fas fa-(.*)"><\/i>/)[1];
  const headerText = header.replace(/<i class="fas fa-(.*)"><\/i>/, "");

  return { faIcon, body, headerText };
};

// screenReaderSpeak(text, priority)
// text: the message to be vocalised
// priority (not required): "polite" (by default) or "assertive"
// the "priority" argument will become the aria-live value of the
// screen reader announcement. The default--"polite"--inserts the
// announcement in the regular queue of SR announcements.
// Override this with caution-- "assertive" will interrupt the screen
// reader. Because an interruption may disorient users or cause them
// to not complete their current task, don't use the assertive value
// unless the interruption is imperative

export const screenReaderSpeak = (text, priority) => {
  const el = document.createElement("div");
  const id = "speak-" + Date.now();
  el.setAttribute("id", id);
  el.setAttribute("aria-live", priority || "polite");
  el.classList.add("sr-only");
  document.body.appendChild(el);

  window.setTimeout(() => {
    document.getElementById(id).innerHTML = text;
  }, 100);

  window.setTimeout(() => {
    document.body.removeChild(document.getElementById(id));
  }, 1000);
};

// Will display text in an existing element and display it for five seconds
// id: id of parent element in which to insert text
// for text to be announced by screen reader the element must have an aria-live attr
export const showAndSpeakMessage = (text, id) => {
  const el = document.getElementById(id);
  window.setTimeout(() => {
    el.innerHTML = text;
  }, 100);

  window.setTimeout(() => {
    el.innerHTML = "";
  }, 5000);
};
