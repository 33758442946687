"use strict";
const { cx } = require("lib/utils");
const { ISO_TIME } = require("lib/formats");
const { observer } = require("mobx-react");
const calStore = require("stores/cal");

const SHORT_12H = "h:mm";
const LONG_12H = `${SHORT_12H}[\n]A`;

const renderLabel = (date) => {
  let iso = date.format(ISO_TIME);
  let modifier = date.minutes() ? "long" : "short";
  let labelClass = cx("ScheduleCalendar-timeLabel", modifier);
  let format = date.minutes() ? LONG_12H : SHORT_12H;

  return (
    <time key={iso} dateTime={iso} className={labelClass}>
      {date.format(format)}
    </time>
  );
};

const TimeLabels = () => {
  let labels = calStore.displayRange.by(30, "minutes");
  labels.pop();

  return (
    <div className="ScheduleCalendar-timeLabels">{labels.map(renderLabel)}</div>
  );
};

module.exports = observer(TimeLabels);
