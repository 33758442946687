import WellnessCard from "components/elements/WellnessCard";
import Graph from "components/elements/Graph";
import { promisResults } from "config";

class PromisResults extends React.Component {
  renderResultsText(value, { green, yellow, red }, type) {
    let score = null;
    if (value < red.high) {
      score = "poor";
    } else if (value >= yellow.low && value <= yellow.high) {
      score = "fair";
    } else if (value > green.low) {
      score = "good";
    }
    const results = promisResults.result[type];
    return results[score];
  }

  render() {
    const { values, graphData } = this.props;
    const { name, value, scale } = values;
    const keys = [
      "threshold",
      "high",
      "medium",
      "low",
      "your_score",
      "average_score",
    ];
    const legendLabels = [
      "High Risk",
      "Medium Risk",
      "Low Risk",
      "Your Score",
      "Average Score",
    ];
    const colors = [
      "#EAEDFF",
      "#E5001E",
      "#FAAE43",
      "#4d7c1d",
      "#000000",
      "#EAEDFF",
      "#FF00FF",
    ];
    const physicalData = graphData(values, "promis", "physical_health");
    const mentalData = graphData(values, "promis", "mental_health");

    const physicalScale = scale.physical[0];
    const mentalScale = scale.mental[0];

    return (
      <React.Fragment>
        <WellnessCard title={name}>
          <div className="WellnessResults-Content-Promis">
            <div className="WellnessResults-Content-Promis--intro">
              {promisResults.intro}
            </div>{" "}
            <br />
            <div className="WellnessResults-Content-Promis--statement">
              <span>What this tells us: </span>
              {promisResults.message}
            </div>{" "}
            <br />
            <div className="">
              <span className="WellnessResults-Content-Promis--subtitle">
                Physical Health Score: {value["physical_health"].value}
              </span>
              <div className="WellnessResults-Content-Promis--physical">
                {this.renderResultsText(
                  value["physical_health"].value,
                  physicalScale,
                  "physical"
                )}
              </div>
              <div className="Wellness-graph--promis">
                <Graph
                  data={physicalData}
                  keys={keys}
                  legendLabels={legendLabels}
                  colors={colors}
                  rangeThreshhold={{
                    low: physicalScale.red.low,
                    high: physicalScale.green.high,
                  }}
                  xAxisLabel={"Score"}
                  labelClassName={"Hra-axisLabel"}
                />
              </div>{" "}
              <br />
              <span className="WellnessResults-Content-Promis--subtitle">
                Mental Health Score: {value["mental_health"].value}
              </span>
              <div className="WellnessResults-Content-Promis--mental">
                {this.renderResultsText(
                  value["mental_health"].value,
                  mentalScale,
                  "mental"
                )}
              </div>
              <div className="Wellness-graph--promis">
                <Graph
                  data={mentalData}
                  keys={keys}
                  legendLabels={legendLabels}
                  colors={colors}
                  rangeThreshhold={{
                    low: mentalScale.red.low,
                    high: mentalScale.green.high,
                  }}
                  xAxisLabel="Score"
                  labelClassName={"Hra-axisLabel"}
                />
              </div>
            </div>
          </div>
        </WellnessCard>
      </React.Fragment>
    );
  }
}

export default PromisResults;
