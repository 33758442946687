"use strict";
const ReactDOM = require("react-dom");
const { cx } = require("lib/utils");

class Swiper extends React.Component {
  constructor(props) {
    super(props);

    this.onSwipeStart = ({ nativeEvent: evt }) => {
      if (this.isSwiping) return;
      if (evt.pointerType != "touch") return;

      this.isSwiping = true;
      this.down = evt;
    };

    this.onSwipeContinue = (evt) => {
      if (this.props.emitProgress && this.isValidMove(evt)) {
        this.emit(evt);
      }
    };

    this.onSwipeEnd = (evt) => {
      if (this.isValidUp(evt)) {
        this.emit(evt);
      }

      this.endSwipe();
    };

    this.endSwipe();
  }

  componentWillMount() {
    document.addEventListener("pointermove", this.onSwipeContinue);
    document.addEventListener("pointerup", this.onSwipeEnd);
  }

  componentWillUnmount() {
    document.removeEventListener("pointermove", this.onSwipeContinue);
    document.removeEventListener("pointerup", this.onSwipeEnd);
  }

  emit(evt) {
    evt.preventDefault();

    if (evt.x < this.down.x) {
      this.props.onSwipeLeft(evt);
    } else {
      this.props.onSwipeRight(evt);
    }
  }

  endSwipe() {
    this.isSwiping = false;
    this.down = null;
  }

  isValidMove(evt) {
    if (!this.isSwiping) return false;
    if (evt.defaultPrevented) return false;
    if (evt.pointerId != this.down.pointerId) return false;

    let time = evt.timeStamp - this.down.timeStamp;
    if (time < this.props.minTime) return false;

    let width = Math.abs(evt.x - this.down.x);
    if (width < this.props.minWidth) return false;

    return true;
  }

  isValidUp(evt) {
    if (!this.isValidMove(evt)) return false;

    let time = evt.timeStamp - this.down.timeStamp;
    if (time > this.props.maxTime) return false;

    let height = Math.abs(evt.y - this.down.y);
    if (height > this.props.maxHeight) return false;

    return true;
  }

  render() {
    return (
      <div
        className={cx("Swiper", this.props.modifier)}
        touch-action="none"
        onPointerDown={this.onSwipeStart}
      />
    );
  }
}

Swiper.defaultProps = {
  onSwipeLeft: () => {},
  onSwipeRight: () => {},

  emitProgress: false,

  minTime: 100,
  minWidth: 150,
  maxTime: 2500,
  maxHeight: 400,
};

module.exports = Swiper;
