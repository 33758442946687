"use strict";
const authStore = require("stores/new-auth");
const { observer } = require("mobx-react");
const Link = require("../link");

const ScreeningsLink = () => {
  let { user } = authStore;

  if (!user.canReadScreenings) return null;

  return (
    <li className="SidebarNav-item">
      <Link to="/screenings">
        <span className="SidebarNavLink-icon">
          <span aria-hidden={true} className="icon fa fa-check" />
        </span>
        <span className="SidebarNavLink-text">Screenings</span>
      </Link>
    </li>
  );
};

module.exports = observer(ScreeningsLink);
