"use strict";
const { observable } = require("mobx");
const request = require("lib/new-request");
const { IMMUNIZATION } = require("lib/formats");

const fromAPI = (im) => ({
  name: im.name,
  date: moment(im.date, IMMUNIZATION),
});

module.exports = observable({
  all: [],
  isLoading: false,

  async fetchAll() {
    this.isLoading = true;
    try {
      this.all = (await request.get("/v1/patient/immunizations")).map(fromAPI);
    } finally {
      this.isLoading = false;
    }
  },
});
