import PropTypes from "prop-types";
import { withSort } from "components/elements";

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };

    this.collapseAll = () => {
      this.setState({
        expanded: [],
      });
    };

    this.expandAll = () => {
      this.setState({
        expanded: this.props.appointments.map((appt) => appt.id),
      });
    };
  }

  toggleExpand(id) {
    let { expanded } = this.state;
    if (expanded.includes(id)) {
      this.setState({
        expanded: without(expanded, id),
      });
    } else {
      this.setState({
        expanded: [...expanded, id],
      });
    }
  }

  renderHeader() {
    const { Sorter, headers } = this.props;

    return (
      <tr>
        {headers.map(({ title, sort, className, key }, i) =>
          sort ? (
            <Sorter className={className} by={key} key={i}>
              {title}
            </Sorter>
          ) : (
            <th className={className} key={i}>
              {title}
            </th>
          )
        )}
      </tr>
    );
  }

  renderRow = (rowData, i) => {
    const { columnClasses, headers } = this.props;
    const renderCellValue = (cellData) => {
      if (!Array.isArray(cellData)) return cellData;
      return cellData.map((cellValue, index) => <p key={index}>{cellValue}</p>);
    };

    return (
      <tr key={i}>
        {headers.map(({ key }, index) => (
          <td key={index} className={columnClasses[key]}>
            {renderCellValue(rowData[key])}
          </td>
        ))}
      </tr>
    );
  };

  renderRows() {
    let { data, noDataMessage } = this.props;

    if (!data.length) {
      return (
        <tr className="NewTable-noItems">
          <td colSpan={6}>{noDataMessage}</td>
        </tr>
      );
    }

    return data.map(this.renderRow);
  }

  render() {
    const { title } = this.props;

    return (
      <section className="Table-wrapper">
        <header className="Table-header">
          <h3 className="Table-title">{title}</h3>
        </header>
        <table className="Table-table NewTable">
          <thead>{this.renderHeader()}</thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </section>
    );
  }
}

Table.defaultProps = {
  data: [],
  headers: [],
  title: "",
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  noDataMessage: PropTypes.string,
  columnClasses: PropTypes.shape({}),
  headers: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withSort(Table, {
  data: "data",
  by: "title",
});
