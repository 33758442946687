{appStore} = require 'stores'
Search     = require 'components/mixins/Search'
{main, section, div} = Exim.DOM

Timeline = Exim.createView module.id,
  mixins: [Search]

  componentWillMount: ->
    subLinks = [{title: 'Recent Activity', to: '/timeline'}]
    appStore.actions.setHeader name: 'Timeline', links: subLinks, search: @onSearch
  searchKeys: -> ['subject', 'body']

  render: ->
    main id: 'mainContent', className: 'Content timeline',
      section className: 'Content-body',
        div className: 'ContentInner',
          React.cloneElement @props.children, {filterer: @filterer}

module.exports = Timeline
