eximStores = [
  'app'
  'appointments'
  'auth'
  'cal'
  'changelog'
  'charges'
  'checkin'
  'clinics'
  'documents'
  'labs'
  'medications'
  'messages'
  'metrics'
  'patient'
  'patientData'
  'screen'
  'site'
  'synopsis'
  'timeline'
  'urgent'
  'visits'
]

eximStores.forEach (store) ->
  exports["#{store}Store"] = exports[store] = require "stores/#{store}"
