"use strict";
const ReactDOM = require("react-dom");
const MODAL_ROOT = document.querySelector("#modal-root");

const showModal = (modal) => {
  return new Promise((resolve, reject) => {
    let withProps = React.cloneElement(modal, {
      abort: () => {
        reject({ name: "AbortError" });
      },
      resolve,
      reject,
      showChildModal: (modal) => {
        let promise = showModal(modal);
        resolve(promise);
        return promise;
      },
    });

    ReactDOM.render(withProps, MODAL_ROOT);
  }).finally(closeModal);
};

const closeModal = () => {
  ReactDOM.render(null, MODAL_ROOT);
};

module.exports = {
  showModal,
  closeModal,
};
