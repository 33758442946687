'use strict'
{appStore, appointmentsStore, patientStore, messagesStore} = require 'stores'
oldSynopsisStore = require('stores/synopsis')
authStore = require('stores/new-auth')
synopsisStore = require('stores/new-synopsis')
scheduleStore = require('stores/schedule').default
screeningStore = require('stores/screening')
careTeamsStore = require('stores/care-teams').default
Spinner = require('components/elements/new-spinner')
Calendar = require 'components/elements/Calendar'
Rating = require 'components/elements/Rating'
CalendarCard = require('./calendar-card').default
validate = require 'lib/validate'
LangSelect = require('components/elements/language-select').default
ProvidersModal = require './ProvidersModal'
ApptTypeSelect = require('components/elements/appt-type-select')
NativeSelect = require('components/elements/native-select').default
BackSwiper = require('components/elements/back-swiper')
BackToAppointmentsLink = require('components/elements/BackToAppointmentsLink')
AppointmentTypesAccordionContainer = require('components/appointments/AppointmentTypesAccordionContainer')
withMediaQueries = require('components/elements/with-media-queries')
TextUpdatesModal = require('components/modals/TextUpdatesModal')
ConfirmTextUpdatesModal = require('components/modals/ConfirmTextUpdatesModal')
{ISO_DATE} = require('lib/formats')
{smDown, mdDown} = require('lib/media-queries')
{Link, withRouter} = require('react-router')
{sortBy, capitalize, debounce} = require('lodash')
{observer} = require('mobx-react')
{some} = require('lodash')
ContentHeader = require('components/elements/content-header')
Main = require('components/elements/main')
PatientHeader = require('components/elements/PatientHeader')
Avatar = require('components/appointments/avatar')
ProvidersSelect = require('./ProvidersSelect').default
{ProvidersProfile, showProvidersProfiles} = require('./ProvidersProfile')
RadioButtonGroup = require('components/radio/group')

{Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody} = require('react-accessible-accordion')

Select = require('@material-ui/core/Select').default
MenuItem = require('@material-ui/core/MenuItem').default

utils = require 'lib/utils'
{cx} = utils
{formats, client, crkPPSTypes, crkFeesLink, locationsLink} = require 'config'
{b, div, span, h2, h4, h5, textarea, p, ul, li, button, a, tch, fa, strong, i, input, label, img, header, option, optgroup, fieldset, legend} = Exim.DOM

MiniScreening = utils.EximShim require 'components/appointments/MiniScreening'

renderSite = (site, siteIndex, sites) =>
  return React.createElement('option', {key: site.id, value: site.id, 'aria-label': "#{site.name}, #{siteIndex + 1} of #{sites.length}"}, site.name)

confirmLeave = (evt) =>
  if !confirm('You are now leaving the patient portal. Do you want to continue?', null, 'Confirm')
    evt.preventDefault()

BACK_TO = '/appointments'
MISSED_APPT_MODAL_URL = '/html/no_show_policy.html'

Schedule = React.createClass({
  displayName: 'Schedule',
  mixins: [
    oldSynopsisStore.connect('stars', 'noshows', 'limit', 'window'),
    appointmentsStore.connect('isScheduling', 'error'),
    patientStore.connect('patient'),
  ],

  getInitialState: ->
    reason: ''
    accommodations: null
    isPhoneValid: true
    isSMSChanged: false
    isConfirmed: false
    isProfilesShown: false
    isApptTypesShown: false
    sortBy: 'time'

  componentDidMount: ->
    screeningStore.fetchTemplates({interaction_type: "schedule"})
    scheduleStore.setIsProfilePage(false)
    scheduleStore.logEvent()

    {appointment_type, search_type} = this.props.location.query
    scheduleStore.setSearchBy(search_type || 'location')
    if appointment_type
      scheduleStore.setDeepLinkApptType(+appointment_type)

  componentDidUpdate: (prevProps, prevState) ->
    if this.state.isConfirmed && prevState == this.state && prevProps == this.props
      this.setState({ isConfirmed: false })

    {appointment_type} = this.props.location.query
    if appointment_type && !scheduleStore.apptType
      scheduleStore.setDeepLinkApptType(+appointment_type)

  componentWillMount: ->
    {user} = authStore
    if !user.isEligible
      appStore.actions.navTo('/appointments')
    patientStore.actions.fetch()
    oldSynopsisStore.actions.fetch()
    scheduleStore.fetchProvidersProfiles()

  componentWillUnmount: ->
    scheduleStore.clear()

  updateReason: debounce((el) ->
    this.setState({
      reason: el.value.trim(),
    })
  , 250)

  selectSlot: (slot) ->
    if slot.id == this.state.slot?.id
      this.setState({slot: null})
      screeningStore.slotClinicId = ""
    else
      this.setState({slot})
      screeningStore.slotClinicId = slot.clinic_id

  getProviderDays: (provider) ->
    return provider.days.map (day) ->
      [weekDay, hours] = day
      location = if day.length > 2 then day[2] else null
      str = ''
      if weekDay is -1
        str += "#{hours}"
      else
        str += "#{moment().day(weekDay).format('dddd')} (#{hours})"
      str += " #{location}" if location
      str

  canSubmit: ->
    return false if this.noAppointmentsLeft()
    { patient, reason } = this.state
    return false if patient.smss && !patient.cell_phone
    return false if !this.state.isPhoneValid
    {site} = scheduleStore
    return true if site.code == 'crk'
    return !!reason

  submit: ->
    {patient, slot, reason} = this.state

    if screeningStore.schedulingScreeningModel && !screeningStore.schedulingScreeningModel.completeLastPage()
      return

    patientStore.actions.update({
      cell_texts: patient.cell_texts,
      cell_phone: patient.cell_phone,
      preferred_language: patient.preferred_language,
      skip_insurance: true
    })

    if screeningStore.schedulingScreeningModel && screeningStore.schedulingScreeningModel.data
        screeningParams = [{
          template_id: screeningStore.schedulingScreening.id
          template_interaction_id: screeningStore.schedulingScreening.activeInteraction.id
          data: screeningStore.schedulingScreeningModel.data
        }]

    corkSite = (client == 'cork')
    appointmentsStore.actions.create({
      accommodations: Object.entries(this.state.accommodations || {}).filter(([k, v]) => v).map(([k]) => k)
      appointment_type: scheduleStore.apptType.id,
      clinic_id: slot.clinic_id || scheduleStore.site.id,
      date: scheduleStore.day.format(ISO_DATE), # slot.startAt
      provider_id: slot.provider_id,
      reason: if corkSite then null else reason, # Cork does *not* store RFV in the appt, only messaged to prov
      slot_id: slot.id,
      patient_screenings: screeningParams
    }).then =>
      if reason && corkSite
        messagesStore.actions.fetchRecipients().then(() =>
          this.sendReasonMessage(slot, reason)
        )
      patientStore.actions.fetch()
      this.setState({isConfirmed: true})

  findRecipient: (key) ->
    targ = messagesStore.get('recipients')

    recipients = targ.reduce (prev, next) ->
      prev.concat(next.users, next.admin, next.staff)
    , []

    key = key.toLowerCase().replace(/\W+/g, '')
    recipients.find (rcp) ->
      (rcp.name or '').toLowerCase().replace(/\W+/g, '').includes(key)

  sendReasonMessage: (slot, body) ->
    name = slot.whom
    return unless name

    key = if (bracketIndex = name.indexOf(' (')) isnt -1
      name.replace(name.slice(bracketIndex), '')
    else
      name

    recipient = @findRecipient(key)

    return unless recipient

    to = []
    recipient.id.toString().split(',').forEach (item) ->
      to.push(val) if (val = parseInt(item, 10)) and to.indexOf(val) < 0

    slotDate = slot.startAt.format(formats.corkDateFormat)
    typeName = scheduleStore.apptType?.name || 'Visit' # TODO: slot.apptType
    subject = "[CC] #{slotDate} - #{typeName} (#{name}) "

    message = {to, subject, body}

    onError = -> console.log 'error sending reason message'
    messagesStore.actions.send(message, onError, true).then(() =>
      this.setState({reason: ''})
    ).catch ->

  showMissedApptModal: (evt) ->
    evt.preventDefault()
    fetch(MISSED_APPT_MODAL_URL).then (resp) ->
      resp.text().then (text) ->
        if text.indexOf('<head>') is -1
          modal =
            title: 'Missed appointments'
            contentLabel: 'Missed appointments'
            body: div className: 'ConsentModal', div dangerouslySetInnerHTML: __html: " #{text}"
          appStore.actions.showModal(modal)

  showTextUpdatesModal: ->
    appStore.actions.showModal({
      title: 'Yes! Please text me appointment reminders or changes to my visit.'
      contentLabel: 'Text Updates'
      body: TextUpdatesModal({
        footer: true,
        confirm: () =>
          {patient} = this.state
          patient.cell_texts = true
          this.setState({
            isSMSChanged: true,
            patient,
          })
          appStore.actions.showModal({
            body: ConfirmTextUpdatesModal({
              focusId: 'accommodationsButton'
            })
            }).then -> 
             document.getElementById('ok-button-close')?.focus()
        close: () =>
          appStore.actions.showModal(null).then =>
            document.getElementById('accommodationsButton')?.focus()
      })
    })

  lastAppointmentLeft: ->
    {noshows, limit} = this.state
    return if authStore.isProxying then false else +noshows == (limit - 1)

  noAppointmentsLeft: ->
    {noshows, limit} = this.state
    return if authStore.isProxying then false else +noshows >= limit

  toggleAccommodations: ->
    if this.state.accommodations
      this.setState({accommodations: null})
    else
      this.setState({
        accommodations: {
          audio: false,
          visual: false,
          translation: false,
        },
      })

  toggleAudio: ->
    this.setState({
      accommodations: Object.assign({}, this.state.accommodations, {audio: !this.state.accommodations.audio})
    })

  toggleVisual: ->
    this.setState({
      accommodations: Object.assign({}, this.state.accommodations, {visual: !this.state.accommodations.visual})
    })

  toggleTranslation: ->
    this.setState({
      accommodations: Object.assign({}, this.state.accommodations, {translation: !this.state.accommodations.translation})
    })

  setPreferredLanguage: (evt) ->
    this.state.patient.preferred_language = evt.target.value
    this.setState(this.state)

  renderAccommodations: (expanded = false) ->
    return if ['apple', 'cork'].includes(client)
    Checkbox = require 'components/elements/Checkbox'

    {accommodations, patient} = this.state
    headerClass = cx('ScheduleAccomodations-header', {expanded: accommodations})

    return div className: 'ScheduleAccomodations',
      button className: headerClass, id: 'accommodationsButton', 'aria-label': "Reasonable accommodations: I'd like to request the following accommodations for my upcoming appointment.", 'aria-expanded': "#{if accommodations then true else false}", tabIndex: 0, onClick: this.toggleAccommodations, disabled: !expanded,
        h4 className: 'ScheduleAccomodations-title', strong 'Reasonable Accommodations'
        div className: 'ScheduleAccomodations-subTitle', "I'd like to request the following accommodations for my upcoming appointment."
      accommodations && div className: 'ScheduleAccomodations-accomodations',
        div className: 'ScheduleAccomodations-accomodation',
          Checkbox {checked: accommodations.audio, isDisabled: !expanded, 'aria-labelledby': 'hearing-accommodations', onChange: () => this.toggleAudio()}
          div className: 'ScheduleAccomodations-wrapper', id: 'hearing-accommodations',
            b className: 'ScheduleAccomodations-name', 'Hearing:'
            'Please have ASL or written assistance available.'
        div className: 'ScheduleAccomodations-accomodation',
          Checkbox {checked: accommodations.visual, isDisabled: !expanded, 'aria-labelledby': 'vision-accommodations', onChange: () => this.toggleVisual()}
          div className: 'ScheduleAccomodations-wrapper', id: 'vision-accommodations',
            b className: 'ScheduleAccomodations-name', 'Vision:'
            'Please have assistance available with written or printed materials.'
        div className: 'ScheduleAccomodations-accomodation',
          Checkbox {checked: accommodations.translation, isDisabled: !expanded, 'aria-labelledby': 'language-accommodations', onChange: () => this.toggleTranslation()}
          div className: 'ScheduleAccomodations-wrapper', id: 'language-accommodations',
            b className: 'ScheduleAccomodations-name', 'Language:'
            'Please have an Interpreter assist me with my Preferred Language:'
            React.createElement(LangSelect, {
              value: patient.preferred_language || '',
              'aria-label': 'Please have an Interpreter assist me with my Preferred Language',
              disabled: !expanded,
              onChange: (lang) => this.setPreferredLanguage(lang)
            })

  openAppointmentTypes: ->
    this.setState({isApptTypesShown: true})

  closeAppointmentTypes: ->
    this.setState({isApptTypesShown: false})

  isProfilesIsShown: ->
    this.setState({isProfilesShown: !this.state.isProfilesShown})

  isProfilesIsNotShown: ->
    this.setState({isProfilesShown: false})

  renderProvidersProfiles: ->
     return ProvidersProfile({
       isPhone: this.props.isPhone,
       isZoom: this.props.isZoom,
       closeModal: this.isProfilesIsNotShown,
       scheduleStore: scheduleStore,
       renderScheduler: true,
       className: 'profiles-appointment-page'
     })

  renderContactDetails: ->
    {site} = scheduleStore

    return React.createElement(React.Fragment, null,
      (appStore.get('config').app || {}).title2 || 'us',
      if site.contactTel
        span null,
          " ("
          a className: 'NewAppointment-contactTel', href: "tel:#{site.contactTelURL}",
            site.contactTel
          ")"
    )

  renderMissedWarning: ->
    lastAppointmentLeft = this.lastAppointmentLeft()
    noAppointmentsLeft = this.noAppointmentsLeft()

    return if !(lastAppointmentLeft || noAppointmentsLeft)

    {limit} = this.state

    return div className: 'NewAppointment-warning',
      div className: 'NewAppointment-warningLeft',
        fa 'exclamation-triangle'
      if lastAppointmentLeft
        div className: 'NewAppointment-warningRight',
          span,
            "You have missed #{limit - 1} appointments without cancelling. After the #{utils.humanizeOrdinal(limit)} no show in a rolling "
            this.state.window
            " month period, the following policies will be enforced:"
          ul {},
            li {},
              "You’ll temporarily lose the ability to schedule appointments online. You must call "
              this.renderContactDetails()
              " to schedule your next appointment."
            li {}, "You’ll only be allowed to schedule one appointment at a time until the prior appointment has been completed or canceled."
            li {}, "You’ll be charged a fee for any subsequent no-shows, which must be paid prior to booking your next appointment."
          span,
            ' See '
            button className: 'Link', onClick: this.showMissedApptModal, 'No-Show Policy'
            ' for more information.'
      else if noAppointmentsLeft
        div className: 'NewAppointment-warningRight',
          "You have missed #{limit} appointments without cancelling. You are now required to call "
          this.renderContactDetails()
          " to schedule your next appointment. See "
          button className: 'Link', onClick: this.showMissedApptModal, 'No-Show Policy'
          '.'

  updateCellPhone: (evt) ->
    {patient} = this.state
    patient.cell_phone = evt.target.value
    this.setState({
      isSMSChanged: true,
      patient,
    })

  validateCellPhone: ->
    {patient} = this.state

    this.setState({
      isPhoneValid: validate.cell_phone(patient.cell_phone),
    })

  renderSMS: (expanded = false) ->
    slotId = if @state.slot then this.state.slot.id else undefined
    {patient} = this.state
    return if !patient.smss
    return if !this.state.isSMSChanged && patient.cell_phone && patient.cell_texts

    return div className: 'NewAppointmentDropdown-textUpdates',
      div className: 'NewAppointmentDropdown-textUpdatesHeader',
        div id: "mobile-label", className: 'NewAppointmentDropdown-textUpdatesHeader-title',
          'Mobile (required)'
      div className: 'NewAppointmentDropdown-textUpdates-input',
        input {
          'aria-labelledby': 'mobile-label',
          value: patient.cell_phone || '',
          className: "Input #{if this.state.isPhoneValid then '' else 'error'}",
          onChange: this.updateCellPhone,
          onBlur: this.validateCellPhone,
          placeholder: "(650) 555-1111",
          disabled: !expanded
        }
        (!this.state.isPhoneValid) && div className: 'ErrorHelperText', 'aria-live': 'assertive', 'Mobile phone is a required field'

        if patient.cell_texts
          span className: 'NewAppointmentDropdown-textUpdates-label', 'Texting is enabled'
        else
          button className: 'Button', id: 'enable-text-reminders-button-new-appt', style: { margin: '8px', padding: '3px 8px 3px 8px' }, 'data-e2e': "text-updates-link-#{slotId}", onClick: this.showTextUpdatesModal, disabled: !expanded,
            'Enable text reminders'
      div className: 'NewAppointmentDropdown-textUpdatesFooter',
        'May be used by clinic or staff to reach you regarding appointment or account questions.'

  renderMiniScreen: (expanded = false) ->
    return if !screeningStore.schedulingScreeningModel
    return MiniScreening model: screeningStore.schedulingScreeningModel, expanded: expanded

  renderSlotDropdown: (slot, expanded) ->
    {id} = slot
    {error, patient, user} = this.state
    {site, apptType} = scheduleStore # TODO: slot.appointmentType

    noAppointmentsLeft = @noAppointmentsLeft()
    corkSite = site.code == 'crk'
    showReason = !noAppointmentsLeft && (!corkSite || !authStore.isProxying)

    return div className: 'AccordionGroup-content NewAppointmentDropdown', 'aria-hidden': "#{!expanded}", 'aria-label': 'dropdown',
      if showReason
        div className: 'NewAppointmentDropdown-head',
          div null,
            span 'Please share your reason for your visit'
            if corkSite
              i ' (optional)'
            else
              i ' (required)'

          # TODO: this should be apptType.note
          if corkSite && crkPPSTypes.includes(+apptType.id)
            div style: {marginTop: '6px'},
              span 'Please provide your PPS number to your provider at least 2 days before your appointment. It may reduce your upfront payment. '
              a target: '_blank', href: 'https://wellness.apple.com/ie/en/content/38#cost-amp-eligibility', className: 'NewAppointmentDropdown-textUpdatesFooter-link', 'Details.'

      if apptType.note
        div className: 'appointment-note',
          apptType.note

      if noAppointmentsLeft
        this.renderMissedWarning()
      else if showReason
        textarea {
          className: 'Input NewAppointmentDropdown-reason',
          'data-e2e': "new-appt-reason-#{id}"
          'aria-label': "Please share your reason for your visit. #{if apptType.note then apptType.note else ""}",
          'aria-required': "#{!corkSite}",
          onChange: (evt) => this.updateReason(evt.target),
          disabled: !expanded,
        }
      (!this.state.reason) && div className: 'ErrorHelperText', 'aria-live': 'assertive', 'Reason for visit is a required field'

      this.renderMiniScreen(expanded)
      this.renderSMS(expanded)
      this.renderAccommodations(expanded)
      if this.state.isScheduling
        React.createElement(Spinner)
      else
        React.createElement(React.Fragment, null,
          if error
            div className: 'error', error
          button className: "Button Button--primary Button--expand NewAppointmentDropdown-button u-right", 'data-e2e': "schedule-appt-#{id}", onClick: this.submit, disabled: !this.canSubmit(),
            if noAppointmentsLeft then 'Call to Schedule Appointment' else 'Schedule Appointment'
        )

  renderSlot: (slot) ->
    apptDisplayClass = if slot.isInternal then 'AccordionGroup-primaryText error' else 'AccordionGroup-secondaryText'
    {apptType, provider} = scheduleStore # TODO: slot.appointmentType
    isExpanded = this.state.slot?.id == slot.id
    groupClass = cx('AccordionGroup', 'tall', 'chevronDown', 'noSideBorders', {
      expanded: isExpanded,
    })

    return div key: slot.id, className: groupClass,
      button {
        className: 'AccordionGroup-target',
        'aria-expanded': isExpanded,
        'data-e2e': "expand-slot-#{slot.id}",
        style: {width: '100%'},
        onClick: () => this.selectSlot(slot),
        'aria-labelledby': "appointment_time_#{slot.id} provider_name_#{slot.id} appointment_type_#{slot.id} appointment_location_#{slot.id}" 
      },
        div className: 'AccordionGroup-leftContent AccordionGroup-leftContent--withAvatar',
          if provider.isAny
            div 'aria=hidden': "true", className: 'AccordionGroup-avatar',
              Avatar({name: slot.whom, shot: slot.shot})
          div className: 'AccordionGroup-leftContent',
            div 'aria-hidden': "true", id: "appointment_time_#{slot.id}", className: 'AccordionGroup-primaryText',
              strong slot.startAt.format(formats.scheduleTimeFormat)
              " "
              strong utils.timezone(slot.site.zone)
            div 'aria-hidden': "true", id: "provider_name_#{slot.id}", className: apptDisplayClass,
              strong slot.whom || slot.pvid || provider.name
            div 'aria-hidden': "true", id: "appointment_type_#{slot.id}", className: apptDisplayClass, ('"' + slot.type + '"') if slot.type
            div 'aria-hidden': "true", id: "appointment_location_#{slot.id}", className: apptDisplayClass,
              if apptType.method == 'in_person'
                slot.site.name || 'kek'
              else if client == 'nearsite' || client == 'docker'
                span null,
                  strong capitalize(apptType.method)
                  span " - #{slot.site.name}"
              else
                capitalize(apptType.method)
        div className: 'AccordionGroup-rightContent',
          span className: 'AccordionGroup-chevron',
            fa 'angle-down'
      this.renderSlotDropdown(slot, isExpanded)
  
  renderSortRadio: ->
    return if scheduleStore.searchBy == 'location' || !synopsisStore.hasScheduling

    sortByValue = this.state.sortBy
    return React.createElement(React.Fragment, null,
      label id: 'sortby',
        'Sort by',
      RadioButtonGroup
        name:'schedule-sort-by'
        ariaLabeledBy:'sortby'
        checked: sortByValue
        onChange: (value) => this.setState({sortBy: value})
        options:[
          {
            labelText:'Time'
            value: 'time'
            'aria-label': "Sort appointments by time",
          },
          {
            labelText:'Location'
            value: 'location'
            'aria-label': "Sort appointments by location",
          },
          
        ]
    )

  renderRating: ->
    {stars, noshows} = this.state
    return if !(stars || noshows)

    isApple = client == 'apple'
    style = if isApple then null else {marginLeft: 0}

    return React.createElement(React.Fragment, null,
      div className: 'NewAppointment-rating',
        if isApple
          Rating value: stars
        div className: 'NewAppointment-rating-note', style: style,
          span "#{noshows} Missed Appointments"
          button 'aria-label': 'Missed Appointments Info', onClick: this.showMissedApptModal,
            fa 'info-circle'
      this.renderMissedWarning()
    )

  renderSearchRadio: ->
    return if !synopsisStore.hasScheduling

    return React.createElement(React.Fragment, null,
      label id: "findappointmentsby", className: 'Appointments-appointmentInfo-title',
        strong 'Find appointments by',
      RadioButtonGroup
        name:'schedule-search-by'
        ariaLabeledBy:'findappointmentsby'
        role: 'radiogroup'
        checked: scheduleStore.searchBy
        onChange: (value) -> scheduleStore.setSearchBy(value)
        options:[
          {
            labelText:'Visit Location'
            value: 'location'
            'aria-label': "Find appointments by visit location",
          },
          {
            labelText:'Visit Type'
            value: 'appointment_type'
            'aria-label': "Find appointments by visit type",
          },
          {
            labelText:'Virtual Only'
            value: 'virtual_method'
            'aria-label': "Find appointments by virtual only",
          }
        ]
    )

  renderSelects: ->
    switch scheduleStore.searchBy
      when 'location'
        return React.createElement(React.Fragment, null,
          this.renderSiteSelect()
          this.renderApptTypeSelect()
          this.renderProviderSelect()
        )

      when 'appointment_type'
        return React.createElement(React.Fragment, null,
          this.renderApptTypeSelect()
          this.renderSiteSelect()
          this.renderProviderSelect()
        )

      when 'virtual_method'
        return React.createElement(React.Fragment, null,
          this.renderApptTypeSelect()
          this.renderSiteSelect()
          this.renderProviderSelect()
        )

  renderSiteSelect: ->
    return div className: 'Appointments-field',
      label className: 'Appointments-label',
        div id: 'visit-location-label', className: 'Appointments-labelText',
          strong 'Visit Location'
        React.createElement(NativeSelect, {
          id: 'schedule-site-select',
          'data-e2e': 'new-appointment-location-dropdown',
          value: scheduleStore.site.id,
          placeholder: 'Select location',
          'aria-labelledby': 'visit-location-label',
          onChange: (evt) =>
            scheduleStore.siteId = +evt.target.value
        }, scheduleStore.sites.map (element, index, array) ->
            renderSite(element, index, array)
          )
      if locationsLink
        a href: locationsLink, target: '_blank', className: cx('Appointments-link', 'external'), onClick: this.confirmLeave,
          'List of Locations'

  renderApptTypeSelect: ->
    {site, provider} = scheduleStore
    hasDescription = some(scheduleStore.apptTypes, 'description')

    return div className: 'Appointments-field',
      label className: 'Appointments-label',
        div id: 'appointment-type-label', className: 'Appointments-labelText',
          strong if scheduleStore.searchBy == 'virtual_method' then 'Virtual Visit Type' else 'Visit Type'
        React.createElement(ApptTypeSelect, {
          id: 'schedule-appt-type-select',
          'data-e2e': 'new-appointment-type-dropdown',
          'aria-labelledby': 'appointment-type-label',
          value: scheduleStore.apptType || '',
          types: scheduleStore.apptTypes,
          onChange: (apptType) =>
            scheduleStore.apptType = apptType
            scheduleStore.providerId = null
            scheduleStore.changeApptSelect = true
            careTeamsStore.clear()
        })
      if site.code == 'crk'
        a href: crkFeesLink, target: '_blank', className: cx('Appointments-link', 'external'), onClick: this.confirmLeave,
          'List of Fees'
      else if !provider && hasDescription
        button className: 'Button Button--appointment-types', "aria-label": "View appointment types information dialog", id: 'appt-types-accordian-activator-button', onClick: this.openAppointmentTypes, 'View Appointment Types', i className: 'fa fa-info-circle Appointments-info-icon'

  renderProviderSelectOnClick: ->
    return showProvidersProfiles({
      isPhone: this.props.isPhone,
      isZoom: this.props.isZoom,
      renderProvidersProfiles: this.renderProvidersProfiles,
      toggleModal: this.isProfilesIsShown
    })

  renderProviderSelect: ->
    {provider} = scheduleStore
    return if !provider

    return div className: 'Appointments-field',
      React.createElement(ProvidersSelect, {
        scheduleStore: scheduleStore,
      })
      if client != 'apple' && client != 'cork' && client != 'microsoft' && scheduleStore.providersProfiles.length
        button id: 'provider-bios-modal-activator', className: "Button Button--provider-bios", style: { margin: '60px 0px 8px 16px' }, onClick: this.renderProviderSelectOnClick, "aria-label": "View Provider Bios Dialog",
        'View Provider Bios', i className: 'fa fa-list-alt ProfileView-provs-profile-btn-white-icon'
      if this.state.isProfilesShown
        this.renderProvidersProfiles()

  renderProviderInfo: ->
    {provider} = scheduleStore
    return if !provider

    if provider.isAny
      {messages} = scheduleStore.apptType

      return div className: 'Appointments-appointmentTimes',
        span className: 'Appointments-appointmentTimes-title',
          'Click on a calendar date to see availability for all providers.'
        if messages.length
          ul className: 'Appointments-timesList',
            messages.map((message) =>
              return li key: message, dangerouslySetInnerHTML: __html: message
            )

    return div className: 'Appointments-appointmentTimes',
      div className: 'Appointments-appointmentTimesAvatar',
        Avatar(provider)
      div className: 'Appointments-appointmentTimesBody',
        span className: 'Appointments-appointmentTimes-title',
          "#{provider.show} is available at the following times:"
        ul className: 'Appointments-timesList',
          this.getProviderDays(provider).map((day) =>
            return li key: day, dangerouslySetInnerHTML: __html: day
          )

  renderCalendar: ->
    return React.createElement(Calendar, {
      modifier: 'schedule',
      disablePrev: true,
      date: scheduleStore.day,
      month: scheduleStore.month,
      isActive: (date) =>
        return scheduleStore.days.some((day) => day.isSame(date, 'day'))
      onChange: (date) =>
        scheduleStore.day = date
      onMonthChange: (date) =>
        scheduleStore.month = date
        utils.screenReaderSpeak("#{date.format("MMMM YYYY")}", "assertive")
    })

  renderWaitListWarning: ->
    {site} = scheduleStore
    return if !site.hasWaitList

    return div className: 'NewAppointment-warning NewAppointment-warning--waitList',
      div className: 'NewAppointment-warningLeft',
        fa 'calendar-times-o'
      div className: 'NewAppointment-warningRight',
        div className: 'NewAppointment-warningTitle', "Can't find what you're looking for?"
        site.waitListMessage
        if site.contactTel
          div className: 'NewAppointment-warningTitle',
            a href: "tel:#{site.contactTelURL}", 'aria-label': "Call the Health Center #{site.contactTelURL} to schedule your next appointment",
              site.contactTel

  renderAllLocationsButton: ->
    return if (client == 'apple' || client == 'cork')
    return if scheduleStore.site.isAny
    return if scheduleStore.sites.length <= 1

    return button {
      id: 'schedule-all-locations-button',
      className: 'Appointments-allLocationsButton',
      onClick: () =>
        scheduleByTypeElement = document.querySelector('[id="schedule-search-by-type"]')
        scheduleStore.setSearchBy('appointment_type')
        scheduleStore.siteId = null
        scheduleStore.providerId = null
        scheduleByTypeElement.focus()
    }, 'Search All Locations'

  renderSlots: ->
    return React.createElement(Spinner) if scheduleStore.isFetchingSlots

    selectedDate = scheduleStore.day.format(formats.fullDate)
    slots = scheduleStore.slots.filter((slot) => authStore.isProxying || !slot.type)
    slots = sortBy(slots, (slot) => slot.site.id) if this.state.sortBy == 'location'

    if slots.length
      return React.createElement(React.Fragment, null,
        header className: 'Appointments-slotsHeader',
          span className: 'Appointments-slotsTitle', 'aria-live': 'polite',
            h2 "Availability for #{selectedDate}:"
          this.renderAllLocationsButton()
        this.renderSortRadio()
        div className: 'Accordion',
          slots.map(this.renderSlot)
      )

    return React.createElement(React.Fragment, null,
      header className: 'Appointments-slotsHeader',
        span className: 'Appointments-slotsTitle', 'aria-live': 'polite', "No availability for #{selectedDate}"
        this.renderAllLocationsButton()
      this.renderWaitListWarning()
    )

  renderConfirmation: ->
    {slot} = this.state

    if slot
      return div className: 'Appointments-confirmed', 'aria-live': 'assertive', 'data-e2e': "appt-confirmation-card-#{slot.id}", role: 'alert',
        React.createElement(CalendarCard, {
          date: slot.startAt,
          zone: slot.site.zone,
        })


  renderContent: ->
    {isApptTypesShown} = this.state

    return React.createElement(Main, {
      className: 'Appointments-main',
      patientHeader: true,
    },
      React.createElement(BackSwiper, { to: BACK_TO })
      React.createElement(BackToAppointmentsLink, { to: BACK_TO })
      this.renderRating()
      div className: 'NewAppointment',
        div className: 'Appointments-schedule',
          div className: 'Appointments-appointmentInfo',
            this.renderSearchRadio()
            this.renderSelects()
            this.renderProviderInfo()
        if scheduleStore.provider && !isApptTypesShown
          div className: 'Appointments-timetable',
            div className: 'Appointments-appointmentInfo-title',
              h2 'Select an available date:'
            div className: 'Appointments-calendar',
              this.renderCalendar()
            if this.state.isConfirmed
              this.renderConfirmation()
            else if scheduleStore.day
              this.renderSlots()
            else
              this.renderWaitListWarning()
        if isApptTypesShown
          React.createElement(AppointmentTypesAccordionContainer, { closeAccordion: @closeAppointmentTypes, activatorId: 'appt-types-accordian-activator-button' })
      )

  render: ->
    return React.createElement(React.Fragment, null,
      React.createElement(ContentHeader, {title: 'New Appointment'})
      PatientHeader({className: 'Appointments-patientHeader'})
      this.renderContent()
    )
})

module.exports = withMediaQueries(withRouter(observer(Schedule)), {
  isPhone: smDown,
  isZoom: mdDown,
})
