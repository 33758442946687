{appStore, authStore, chargesStore} = require 'stores'

utils  = require 'lib/utils'
config = require 'config'
{div, strong, tch, fa, input, span} = Exim.DOM
{cx} = Exim.helpers

Progress          = require 'components/elements/Progress'
ConfirmationModal = require 'components/elements/ConfirmationModal'
Checkbox = require '../elements/Checkbox'

PaymentCard = React.createClass({
  displayName: 'PaymentCard',
  mixins: [
    authStore.connect('user'),
    chargesStore.connect('updatingCard'),
  ],

  getInitialState: ->
    updateToPrimary: false
    newCardName: ''

  _removeCard: ->
    {url} = @props

    return if @cardRemoving
    @toggleCardRemoving(true)

    chargesStore.actions.deleteCard(url).then =>
      @toggleCardRemoving(false)
      authStore.actions.fetchUser()
      chargesStore.actions.fetchCards(@state.user.id)
      @closeModal()
      alert('Payment method has been removed.')

  toggleCardRemoving: (cardRemoving=!@cardRemoving) ->
    @cardRemoving = cardRemoving
    @setState({cardRemoving})

  closeModal: ->
    appStore.actions.showModal(null)

  removeCard: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()

    digits = @props.last4
    cardTitle = @props.card_label or @props.card_brand
    cardIcon = if @props.card_brand is 'hsafsa' then 'credit-card-alt' else "cc-#{@props.card_brand?.toLowerCase()}"
    card = div className: 'PaymentCard PaymentCard--small',
      div className: 'PaymentCardContent',
        div className: 'PaymentCardHeader',
          div className: 'PaymentCardIcon', fa @cardIcon()
          div className: 'PaymentCardTitleWrapper',
            div className: 'PaymentCardTitle', cardTitle
            div className: 'PaymentCardDigits', "•••• #{digits}"

    body = ConfirmationModal
      title: 'Remove this payment method?'
      text: "Removing it means you won’t be able to use this payment method without adding it again."
      confirmText: 'Remove'
      onCancel: @closeModal
      onConfirm: @_removeCard
      body: card

    modal =
      className: 'Modal--small'
      contentLabel: 'Remove this payment method?'
      darker: true
      body: body

    appStore.actions.showModal(modal)

  makeCardPrimary: ->
    {url, card_label, card_brand, id} = @props
    @_updateCard(url, card_label, card_brand, id, true).then =>
      document.getElementById('focus-ref-primary-payment')?.focus()

  updateCardName: (evt) ->
    @setState {newCardName: evt.target.value}

  _updateCard: (url, card_label, card_brand, payment_method_id, primary) ->
    chargesStore.actions.updateCard(url, card_label, card_brand, payment_method_id, primary).then =>
      chargesStore.actions.fetchCards(@state.user.id)

  updateCard: ->
    digits = @props.last4
    cardTitle = @props.card_label or @props.card_brand
    cardIcon = if @props.card_brand is 'hsafsa' then 'credit-card-alt' else "cc-#{@props.card_brand?.toLowerCase()}"
    modalBody = div className: 'UpdateCard',
      div className: 'PaymentCard PaymentCard--small',
        div className: 'PaymentCardContent',
          div className: 'PaymentCardHeader',
            div className: 'PaymentCardIcon', fa @cardIcon()
            div className: 'PaymentCardTitleWrapper',
              div className: 'PaymentCardTitle', cardTitle
              div className: 'PaymentCardDigits', "•••• #{digits}"
      div style: {padding: '0 20px'},
        div className: 'InputAddonWrapper',
          div className: 'InputAddon',
            fa 'user'
          input type: 'text', name:'cardName', className: 'Input Input--expand', placeholder: 'Card nickname', onChange: @updateCardName
        div className: 'AddPaymentPrimary',
          Checkbox className: 'AddPaymentPrimary--Checkbox', 'aria-labelledby': 'make-primary', onChange: @onPrimarySelect
          span id: 'make-primary', 'Make Primary'

    body = ConfirmationModal
      title: 'Update your payment method'
      text: 'Edit your card information.'
      confirmText: 'Save'
      onCancel: @closeModal
      onConfirm: @confirmCardDetails
      body: modalBody

    modal =
      className: 'Modal--small'
      contentLabel: 'Update your payment method'
      darker: true
      body: body

    appStore.actions.showModal(modal)

  confirmCardDetails: ->
    {url, card_brand, payment_method_id} = @props
    utils.screenReaderSpeak("Payment method has been updated")
    @_updateCard(url, @state.newCardName, card_brand, payment_method_id, @state.updateToPrimary).then =>
      @closeModal()
      alert('Payment method has been updated.')

  onPrimarySelect: ->
    @setState updateToPrimary: !@state.updateToPrimary

  cardIcon: ->
    if @props.card_label is 'HSA/FSA' then 'credit-card-alt' else "cc-#{@props.card_brand?.toLowerCase()}"

  render: ->
    cardTitle = @props.card_label or @props.card_brand
    hsafsa = @props.card_brand is 'hsafsa'
    digits = @props.last4
    {cardRemoving} = @state
    div className: 'PaymentCard', key: @props.id,
      div className: 'PaymentCardContent',
        div className: 'PaymentCardHeader',
          div className: 'PaymentCardIcon', fa @cardIcon()
          div className: 'PaymentCardTitleWrapper',
            div className: 'PaymentCardTitle', cardTitle
            div className: 'PaymentCardDigits', "•••• #{digits}"
        div className: 'PaymentCardFooter',
          if @props.primary
            div className: 'PaymentCardAction PaymentCardAction--disabled', tabIndex: 0, id: 'focus-ref-primary-payment',
              'Primary',
              span className: 'sr-only', "#{cardTitle} ending in #{digits} is your primary payment method"
          else
            tch tagName: 'button', href: '#', 'aria-label': "Make #{cardTitle} your primary payment method", className: 'PaymentCardAction PaymentCardAction--active', handler: @makeCardPrimary, style: {color: '#757575', textTransform: 'uppercase', padding: 0},
              'Make Primary'
          div style: {marginLeft: 'auto'},
            tch tagName: 'button', href: '#', 'aria-label': 'Update payment method', className: 'PaymentCardAction', handler: @updateCard, role: 'button',
              'Update'
            tch tagName: 'button', href: '#', 'aria-label': 'Remove payment method', className: 'PaymentCardAction', handler: @removeCard, role: 'button',
              if cardRemoving
                Progress key: 'ccremove', className: 'CardModal-remove', wrapper: strong
              else
                'Remove'
})

module.exports = PaymentCard
