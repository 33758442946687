"use strict";
const authStore = require("stores/new-auth");
const synopsisStore = require("stores/new-synopsis");
const Model = require("./base");
const AppointmentType = require("./appointment-type");
const Appointment = require("./appointment");
const SlotSeries = require("./slot-series");
const { isEmpty, get } = require("lodash");

const { ISO_DATE, ISO_TIME } = require("lib/formats");
const {
  onlyDate,
  onlyTime,
  dateTime,
  computed,
  MomentRange,
  sortedFindClosest,
} = require("lib/utils");

const DUR_10_MIN = moment.duration(10, "minutes");
const DUR_15_MIN = moment.duration(15, "minutes");
const DUR_20_MIN = moment.duration(20, "minutes");

class Slot extends Model {
  static async fetchSlots(params) {
    // TODO: send in format, convenient for front end
    let { me } = authStore;
    let { site } = synopsisStore;

    if (!me.canReadCalendar) return [];

    let query = {
      flatten: true,
      clinic_id: site.id,
    };

    let { date, dateRange } = params;
    if (date) {
      query.start = date.format(ISO_DATE);
    } else if (dateRange) {
      query.start = dateRange.start.format(ISO_DATE);
      query.end = dateRange.end.format(ISO_DATE);
    }

    let { providerId, providerType } = params;
    if (providerId) {
      query.provider_id = providerId;
    } else if (providerType) {
      query.provider_type = providerType;
    }

    let rawSlots = await this.get({ data: query });
    return rawSlots.map((raw) => this.fromAPI(raw));
  }

  static async fetchConflicted() {
    let { me } = authStore;
    let { site } = synopsisStore;

    if (!me.canReadCalendar) return [];

    let rawSlots = await this.get({
      // TODO: path: '&/conflicted'
      data: {
        flatten: true,
        clinic_id: site.id,
        conflicted: true,
      },
    });

    return rawSlots.map((raw) => this.fromAPI(raw));
  }

  static fromAPI(raw) {
    let site = synopsisStore.findSite(raw.clinic_id);
    let { zone } = site;

    let startAt = moment.tz(raw.start_at, zone);
    let endAt = moment.tz(raw.end_at, zone);
    let appts = (raw.appointments || []).map((appt) =>
      Appointment.fromAPI(appt)
    );

    let slotAppointmentTypeID = get(raw, "appointment_types[0]", null);
    let seriesAppointmentTypeID = get(
      raw,
      "slot_series.appointment_types[0]",
      null
    );
    let mainAppointmentTypeID =
      slotAppointmentTypeID || seriesAppointmentTypeID;

    let slotMultipleAppointmentTypeIDs = get(
      raw,
      "appointment_types",
      []
    ).slice(1);
    let seriesMultipleAppointmentTypeIDs = get(
      raw,
      "slot_series.appointment_types",
      []
    ).slice(1);
    let multipleAppointmentTypeIDs =
      slotMultipleAppointmentTypeIDs || seriesMultipleAppointmentTypeIDs;

    return super.fromAPI({
      id: raw.id,
      date: onlyDate(startAt),
      appointments: appts,
      series: SlotSeries.fromAPI(raw.slot_series),

      site,
      provider: site.getProvider(raw.provider_id),
      _appointmentType: site.findApptType(mainAppointmentTypeID),
      _multipleAppointmentTypeIDs: multipleAppointmentTypeIDs,
      startTime: onlyTime(startAt),
      duration: moment.duration(endAt - startAt),
      maxPatients: raw.max_patients,
      maxOverbook: raw.max_overbook,
      visibility: raw.visibility,
      restrictedTo: raw.restricted_to,
    });
  }

  constructor(params = {}) {
    let {
      appointmentType,
      startTime = moment(0),
      duration = moment.duration(),
      provider,
      ...rest
    } = params;

    let _appointmentType = appointmentType || rest.slotLevelAppointmentType;
    let apptTypesBooked = get(rest, "_multipleAppointmentTypeIDs", []).length
      ? "multiple"
      : "single";

    super({
      date: null,
      appointments: [],
      series: null,
      site: provider && provider.site,
      provider,
      _appointmentType: _appointmentType,
      _multipleAppointmentTypeIDs: rest._multipleAppointmentTypeIDs || [],
      _selectedAppointmentType: null,
      _startTime: startTime,
      _duration: duration,
      maxPatients: 1,
      maxOverbook: 0,
      visibility: "hold",
      restrictedTo: null,
      apptTypesBooked: apptTypesBooked,
      ...rest,
    });

    if (this.isRepeated) {
      this.series.slot = this;
    }

    this.appointments.forEach((appt) => {
      appt.slot = this;
    });
  }

  update() {
    return super.put({
      data: this.toJSON(),
    });
  }

  createSeries() {
    this.series = SlotSeries.fromSlot(this);
  }

  removeSeries() {
    this.series = null;
  }

  resetMultipleAppointmentTypeIDs() {
    this._multipleAppointmentTypeIDs = [];
  }

  changeApptTypesBooked() {
    this.apptTypesBooked = (() => {
      if (this.isMultiple) {
        this.resetMultipleAppointmentTypeIDs();
        return "single";
      }
      if (this.isSingle) return "multiple";
    })();
  }

  setMultipleAppointments(apptTypeID) {
    let index = this.multipleAppointmentTypeIDs.indexOf(`${apptTypeID}`);

    if (index === -1) {
      this.multipleAppointmentTypeIDs.push(`${apptTypeID}`);
    } else {
      this.multipleAppointmentTypeIDs.splice(index, 1);
    }

    this.appointmentTypesIDsList;
  }

  changeVisibility() {
    this.visibility = (() => {
      if (this.isHold) return "internal";
      if (this.isPrivate) return "accessible";
      if (this.isPublic) return "hold";
    })();
  }

  toJSON() {
    return {
      ...super.toJSON(),
      appointments: this.appointments.map((appt) => appt.toJSON()),
      start_at: this.startAt,
      end_at: this.endAt,
      slot_series: this.series && this.series.toJSON(),
      ...this.sharedJSON,
    };
  }

  destroy() {
    let slotsStore = require("stores/new-slots");
    slotsStore.deleteSlot(this);
  }

  get isFuture() {
    return this.date >= this.site.today;
  }

  get isBookable() {
    return authStore.me.canReadCalendar && this.isFuture && !this.isConflicted;
  }

  get isEditable() {
    return authStore.me.canEditCalendar && this.isFuture && !this.isConflicted;
  }
}

Slot.endPoint = "/v1/slots";

computed(Slot.prototype, {
  get startAt() {
    return dateTime(this.date, this.startTime);
  },

  get endAt() {
    return dateTime(this.date, this.endTime);
  },

  // TODO: `bookedAppts` should be used instead of `appointments` in more places
  get bookedAppts() {
    return this.appointments.filter((appt) => appt.isScheduled);
  },

  get conflictedAppts() {
    return this.appointments.filter((appt) => appt.isConflicted);
  },

  get isConflicted() {
    return this.appointments.some((appt) => appt.isConflicted);
  },

  get isPartiallyConflicted() {
    return (
      this.isConflicted && !this.appointments.every((appt) => appt.isConflicted)
    );
  },

  get isBooked() {
    return this.appointments.length > 0;
  },

  get isFilled() {
    return this.appointments.length >= this.maxPatients;
  },

  get isOverfilled() {
    return this.bookedAppts.length > this.maxCapacity;
  },

  get patientName() {
    if (this.appointments.length > 1) return "Group";

    let [appt] = this.appointments;
    if (appt) return appt.patient.name;
  },

  get isRepeated() {
    return !!this.series;
  },

  get isReferralsOnly() {
    return this.restrictedTo == "virtual_visit_referral";
  },

  // Can be discarded once devops turns on all appointment types in all the clinics
  get checkSelectedApptIsBookable() {
    const isBookable = this.site.bookableApptTypes.some(
      (apptType) => apptType.id === this._selectedAppointmentType.id
    );

    if (isBookable || this._selectedAppointmentType.isInternal) {
      return this._selectedAppointmentType;
    } else {
      return AppointmentType.OPEN;
    }
  },

  get appointmentType() {
    if (this._selectedAppointmentType) {
      return this.checkSelectedApptIsBookable;
    } else if (this._appointmentType) {
      return this._appointmentType;
    } else {
      return AppointmentType.OPEN;
    }
  },

  get bookedAppointmentType() {
    return this.appointments.length ? this.appointments[0].type : null;
  },

  set appointmentType(type) {
    this._selectedAppointmentType = type;
  },

  get selectedApptType() {
    if (this._selectedAppointmentType) {
      return this._selectedAppointmentType;
    } else {
      return AppointmentType.OPEN;
    }
  },

  get availableApptTypes() {
    let types = [...this.provider.apptTypes];

    if (this.isHold) types.push(...AppointmentType.INTERNAL_TYPES);
    if (!this.appointmentType.isOpen) types.push(AppointmentType.OPEN);

    return types;
  },

  get multipleAppointmentTypeIDs() {
    return this._multipleAppointmentTypeIDs;
  },

  get multipleVisitTypesSelected() {
    if (!this.appointmentType.id) return false;

    return this.multipleAppointmentTypeIDs.length > 0;
  },

  get filteredByDurationApptTypes() {
    let types = this.availableApptTypes;
    let filteredTypes = [];

    if (this.appointmentType.duration) {
      let withDuration = types.filter((aT) => aT && aT.duration);
      filteredTypes = withDuration.filter(
        (aT) =>
          aT.duration.asMinutes() === this.appointmentType.duration.asMinutes()
      );
    }

    return filteredTypes;
  },

  get allOtherFilteredByDurationApptTypes() {
    let allOtherApptTypes = this.filteredByDurationApptTypes.filter(
      (aT) => aT !== this.appointmentType
    );
    return allOtherApptTypes.length;
  },

  get isMultiple() {
    return this.apptTypesBooked === "multiple";
  },

  get isSingle() {
    return this.apptTypesBooked === "single";
  },

  get minStartTime() {
    return this.isRepeated
      ? this.series.minStartTime
      : this.site.getWorkingRange(this.date).start;
  },

  get maxStartTime() {
    return this.maxEndTime.clone().subtract(this.duration);
  },

  get maxEndTime() {
    return this.isRepeated
      ? this.series.maxEndTime
      : this.site.getWorkingRange(this.date).end;
  },

  get maxDuration() {
    return moment.duration(this.maxEndTime - this.startTime);
  },

  get availableStartTimes() {
    let range = new MomentRange(this.minStartTime, this.maxStartTime);

    return range.by(5, "minutes");
  },

  get availableDurations() {
    let max = this.maxDuration;
    let range = new MomentRange(DUR_10_MIN, max);
    let durs = range.by(5, "minutes");

    // durs.unshift(DUR_10_MIN)
    // if (DUR_20_MIN < max) {
    //   durs.splice(2, 0, DUR_20_MIN)
    // }

    return durs;
  },

  get isHold() {
    return this.visibility == "hold";
  },

  get isPrivate() {
    return this.visibility == "internal";
  },

  get isPublic() {
    return this.visibility == "accessible";
  },

  get displayVisibility() {
    if (this.isHold) return "Hold";
    if (this.isPrivate) return "Private";
    if (this.isPublic) return "Public";
  },

  get isGroup() {
    return this.maxCapacity > 1;
  },

  get maxCapacity() {
    return this.maxPatients + this.maxOverbook;
  },

  get startTime() {
    return this._startTime;
  },

  set startTime(time) {
    this._startTime = sortedFindClosest(this.availableStartTimes, time);
  },

  get endTime() {
    return this.startTime.clone().add(this.duration);
  },

  set endTime(time) {
    this.duration = moment.duration(time - this.startTime);
  },

  get appointmentTypeWithDuration() {
    const selectedAppointmentType = this.selectedApptType.isOpen
      ? this.appointmentType
      : this.selectedApptType;
    return selectedAppointmentType;
  },

  get duration() {
    let apptType =
      this.bookedAppointmentType || this.appointmentTypeWithDuration;

    return this.isDurationFixed ? apptType.duration : this._duration;
  },

  set duration(dur) {
    this._duration = sortedFindClosest(this.availableDurations, dur);
  },

  get isDurationFixed() {
    return !!(this.bookedAppointmentType || this.appointmentTypeWithDuration)
      .duration;
  },

  get appointmentTypesList() {
    let appointmentTypesList = [];

    !isEmpty(this.appointmentTypesIDsList) &&
      this.appointmentTypesIDsList.map((aTID) => {
        let foundApptType = this.site.findApptType(aTID);
        !!foundApptType && appointmentTypesList.push(foundApptType);
      });

    return appointmentTypesList;
  },

  get appointmentTypesIDsList() {
    let appointmentTypesIDsList =
      this.allOtherFilteredByDurationApptTypes === 0
        ? []
        : [...this.multipleAppointmentTypeIDs];

    if (this.appointmentType.id) {
      let index = appointmentTypesIDsList.indexOf(`${this.appointmentType.id}`);

      if (index !== -1) {
        appointmentTypesIDsList.splice(index, 1);
      }

      appointmentTypesIDsList.unshift(`${this.appointmentType.id}`);
    } else {
      appointmentTypesIDsList = [];
    }

    return appointmentTypesIDsList;
  },

  get sharedJSON() {
    return {
      clinic_id: this.site.id,
      provider_id: this.provider.id,
      appointment_types: this.appointmentTypesIDsList,
      start_time: this.startTime.format(ISO_TIME),
      end_time: this.endTime.format(ISO_TIME),
      max_patients: this.maxPatients,
      max_overbook: this.maxOverbook,
      visibility: this.visibility,
      restricted_to: this.restrictedTo,
    };
  },

  get isCheckInPending() {
    return (
      this.isBooked &&
      this.appointments.every((appt) => appt.checkInStatus == "pending")
    );
  },

  get isCheckInCompleted() {
    return (
      this.isBooked &&
      this.appointments.every((appt) => appt.checkInStatus == "done")
    );
  },
});

module.exports = Slot;
