"use strict";
const { Modal } = require("components/elements/new-modal");
const { observer } = require("mobx-react");
const ApptTypeSelect = require("components/elements/appt-type-select");

class EditApptModal extends React.Component {
  async save() {
    try {
      let { appointment } = this.props;
      await appointment.patch();
      this.props.abort();
    } catch (err) {
      if (err.name != "AbortError") {
        console.error(err);
        alert("Failed to update the appointment.");
      }
    }
  }

  render() {
    let { abort, appointment } = this.props;
    let changeReason = (evt) => {
      appointment.reason = evt.target.value;
    };

    return (
      <Modal title="Edit Appointment" className="EditApptModal" close={abort}>
        <p className="EditApptModal-name">
          Patient name: <b>{appointment.patient.name}</b>
        </p>
        <ApptTypeSelect
          value={appointment.type}
          types={appointment.availableApptTypes}
          onChange={(aT) => (appointment.type = aT)}
        />
        <label className="EditApptModal-reasonLabel">
          Reason for visit:
          <input
            className="EditApptModal-reasonInput"
            value={appointment.reason}
            onChange={changeReason}
          />
        </label>
        <footer className="EditApptModal-footer">
          <button className="Button Button--cancel" onClick={abort}>
            Cancel
          </button>
          <button
            className="Button Button--primary"
            onClick={() => this.save()}
          >
            Save
          </button>
        </footer>
      </Modal>
    );
  }
}

module.exports = observer(EditApptModal);
