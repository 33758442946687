import React from "react";
import { appStore, metricsStore } from "stores";
import authStore from "stores/new-auth";
import appointmentsStore from "stores/new-appointments";
import synopsisStore from "stores/new-synopsis";
import AppointmentsTable from "./appointments-table";
import { mdDown } from "lib/media-queries";
import {
  ContentHeader,
  Main,
  Spinner,
  withMediaQueries,
} from "components/elements";
import { Link } from "react-router";
import { observer } from "mobx-react";
import PatientHeader from "components/elements/PatientHeader";

class AllAppointments extends React.Component {
  constructor(props) {
    super(props);
    this.buttonGroup = React.createRef();

    this.state = {
      currentUserId: null
    }
  }
  componentWillMount() {
    metricsStore.actions.fetch();
    appointmentsStore.clear();
    appointmentsStore.fetch();
    this.setState({ currentUserId: authStore.user.id });
  }

  componentDidMount() {
    appStore.actions.setTitle("Appointments Table List");
    // we have an old verions of react-router complicating the usage of refs in the Link
    // so we drop a ref on the containing div, then find the first link, and focus that on mount
    this.buttonGroup.current &&
      this.buttonGroup.current.querySelector("a").focus();
  }

  componentDidUpdate() {
    if (this.state.currentUserId !== authStore.user.id) {
      metricsStore.actions.fetch();
      appointmentsStore.fetch();
      this.setState({ currentUserId: authStore.user.id });
    }
  }

  componentWillUnmount() {
    appStore.actions.setTitle();
  }

  renderButtons() {
    const { user, isPortalReadOnlyForMember } = authStore;
    if (!user.isEligible || user.isAdmin || isPortalReadOnlyForMember) return;

    return (
      <div className="Appointments-newAppt" ref={this.buttonGroup}>
        <Link
          to="/appointments/new?search_type=virtual_method"
          className="Button Button--headerAction Button--headerAction-appointment"
          id="returnFocusAfterModal"
        >
          {this.props.isPhone ? "" : "Book"} Virtual Visit
        </Link>
        {!synopsisStore.hideLandingPageOfficeBooking && (
          <Link
            to="/appointments/new"
            className="Button Button--headerAction-appointment-white"
          >
            {this.props.isPhone ? "" : "Book"} Office Visit
          </Link>
        )}
      </div>
    );
  }

  renderTables() {
    if (!appointmentsStore.isFetched || !synopsisStore.isFetched)
      return <Spinner />;

    let { all } = appointmentsStore;
    let upcoming = all.filter((appt) => appt.isScheduled);
    let past = all.filter((appt) => !appt.isScheduled);

    return (
      <Main className="Appointments-main" patientHeader>
        <AppointmentsTable
          title="Upcoming Appointments"
          appointments={upcoming}
          sort={{ order: "ascending" }}
        />
        <AppointmentsTable
          title="Past Appointments"
          appointments={past}
          sort={{ order: "descending" }}
        />
      </Main>
    );
  }

  render() {
    return (
      <React.Fragment>
        <ContentHeader
          className="Appointments-contentHeader"
          title="Appointments"
        >
          {this.renderButtons()}
        </ContentHeader>
        {PatientHeader({ className: "Appointments-patientHeader" })}
        {this.renderTables()}
      </React.Fragment>
    );
  }
}

module.exports = withMediaQueries(observer(AllAppointments), {
  isPhone: mdDown,
});
