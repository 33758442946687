import { groupBy, map } from "lodash";

class MultipleApptCheckbox extends React.Component {
  handleChange = (apptTypeID) => {
    this.props.onChange(apptTypeID);
    this.forceUpdate();
  };

  renderModalityCheckbox = (apptTypes, name) => {
    return (
      <div key={name} className={"MultipleAppts-grouping"}>
        <p className={"MultipleAppts-modality-name"}>{name}</p>
        <div className={"MultipleAppts-modality-wrapper"}>
          {apptTypes.map(this.renderCheckbox)}
        </div>
      </div>
    );
  };

  renderCheckbox = (apptType) => {
    const { multipleApptsTypes, selectedApptType } = this.props;

    let className =
      selectedApptType.id === apptType.id
        ? "MultipleApptsAutoSelected"
        : "MultipleAppts";

    return (
      <label key={apptType.id} className={`${className}-label`}>
        <input
          key={apptType.id}
          className={`${className}-checkbox`}
          type="checkbox"
          disabled={selectedApptType.id === apptType.id}
          checked={
            multipleApptsTypes.includes(`${apptType.id}`) ||
            selectedApptType.id === apptType.id
          }
          onChange={() => this.handleChange(apptType.id)}
        />
        <span className={`${className}-checkmark`} />
        {apptType.nameAndDuration}
      </label>
    );
  };

  groupByModality = (apptTypes) => {
    apptTypes = apptTypes.filter((aT) => !!aT);
    let withMods = apptTypes.filter((aT) => aT.modality);
    let byMods = groupBy(withMods, (aT) => aT.modality.name);
    let noMods = apptTypes.filter((aT) => !aT.isOpen && !aT.modality);

    return [
      ...map(byMods, this.renderModalityCheckbox),
      ...noMods.map(this.renderCheckbox),
    ];
  };

  render() {
    const { types } = this.props;

    return <React.Fragment>{this.groupByModality(types)}</React.Fragment>;
  }
}

MultipleApptCheckbox.defaultProps = {
  multipleApptsTypes: [],
  types: [],
  onChange: () => {},
};

module.exports = MultipleApptCheckbox;
