# Search = require 'components/mixins/Search'
{client, touchDevice} = require 'config'
{labsStore, authStore, appStore}   = require 'stores'
attachHeader  = require 'components/mixins/attachHeader'
PullToRefresh = require 'components/elements/PullToRefresh'
PatientHeader = require 'components/elements/PatientHeader'
Footer        = require 'components/app/Footer'
ListLink      = require 'components/elements/ListLink'
{main, section, div, object, a} = Exim.DOM
{cx} = Exim.helpers

PDF_LINK = "/combo/info/XO_Crossover_Lab_Reference.pdf"

Labs =
  componentWillUnmount: ->
    labsStore.actions.clear()

  openInWindow: (url) ->
    window.open(url, '_blank').focus()

  viewPDF: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()
    body = div className: 'DocumentPreview',
      div className: 'ConfirmationModalTitle',
        ListLink type: 'action', action: @openInWindow.bind(this, PDF_LINK), icon: 'download', 'Download'
      div className: 'DocumentPreview-mime-pdf',
        if touchDevice
          tch tagName: 'a', href: '#', className: 'pdf-download', handler: @openInWindow.bind(this, PDF_LINK),
            fa 'file-pdf-o'
            'Open File'
        else
          div className: 'DocumentPreview-mime-pdf-object scroll-wrapper',
            object data: PDF_LINK, type: 'application/pdf', width: '100%', height: '100%', 'aria-label': "Document preview",
              a href: PDF_LINK, target: '_blank', PDF_LINK
    appStore.actions.showModal({body})

  render: ->
    main id: 'mainContent', className: "Content #{cx 'Content--withPatientHeader': @state.user.proxying}",
      PullToRefresh tag: section, className: 'Content-body', onRefresh: labsStore.actions.fetch, isLoading: @state.labsLoading,
        div className: 'ContentInnerWrapper',
          PatientHeader()

          div className: 'ContentInner Labs',
            @props.children

          Footer()

# CoffeeScript doesn't have getters
Object.defineProperty Labs, "mixins",
  get: ->
    actions = []
    unless ['apple', 'cork'].includes(client)
      actions.push({
        title: 'DEFINITIONS'
        icon: 'flask'
        params: null
        key: 'pdf-view',
        action: @viewPDF.bind(this)
      })

    [
      authStore.connect('user')
      attachHeader(name: 'Labs', actions: actions),
      labsStore.connect('labsLoading')
    ]

module.exports = Exim.createView module.id, Labs
