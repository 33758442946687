import { showModal } from "components/elements/new-modal";
import { cx } from "lib/utils";
import NotesModal from "components/elements/NewNotesModal";
import { observer } from "mobx-react";

let position = null;

class NotesButton extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.notes.length !== this.props.notes.length) {
      this.displayModal();
    }
  }

  displayModal() {
    const { notes, noNotes, ...remainingProps } = this.props;

    showModal(
      <NotesModal notes={notes} position={position} {...remainingProps} />
    );
  }

  handleClick = (evt) => {
    evt.stopPropagation();
    position = evt.target.getBoundingClientRect();

    this.displayModal();
  };

  render() {
    const { notes, noNotes } = this.props;

    const buttonClass = cx("ApptNotesButton", {
      hasNotes: noNotes === true || notes.length,
    });

    return <button className={buttonClass} onClick={this.handleClick} />;
  }
}

export default observer(NotesButton);
