"use strict";
const { Modal } = require("components/elements/new-modal");

const renderAppt = (appt) => {
  return (
    <tr key={appt.id}>
      <td>{appt.patient.name}</td>
      <td>{appt.displayStatus}</td>
    </tr>
  );
};

const renderApptsTable = (appts) => {
  return (
    <table className="NewTable">
      <thead>
        <tr>
          <th>Patient</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>{appts.map(renderAppt)}</tbody>
    </table>
  );
};

const EditBookedSlotModal = ({ abort, appts, resolve }) => {
  return (
    <Modal
      close={abort}
      title="Edit Booked Slot"
      className="EditBookedSlotModal"
    >
      <p className="EditBookedSlotModal-text">
        Would you like to keep original appointments or to save changes and
        cancel appointments?
      </p>
      {renderApptsTable(appts)}
      <footer className="NewModal-footer EditBookedSlotModal-footer">
        <button
          className="Button Button--primary"
          onClick={() => resolve("cancel")}
        >
          Cancel
          <br />
          appointments
        </button>
        <button
          className="Button Button--primary"
          onClick={() => resolve("keep")}
        >
          Keep
          <br />
          appointments
        </button>
      </footer>
    </Modal>
  );
};

module.exports = EditBookedSlotModal;
