"use strict";
const User = require("./user");
const synopsisStore = require("stores/new-synopsis");
const oldAuthStore = require("stores/auth");
const { browserHistory } = require("react-router");

class Patient extends User {
  static async findAll(query) {
    let rawPatients = await this.get({
      data: {
        q: query,
        clinic_id: synopsisStore.site.id,
      },
    });

    return rawPatients.map((raw) => this.fromAPI(raw));
  }

  proxy(appt) {
    let user = this.toJSON();

    oldAuthStore.actions.preloadImpersonation(user).then(() => {
      if (appt) {
        browserHistory.push({
          pathname: "/appointments/new",
          query: { appointment_type: appt.type.id },
        });
      } else {
        browserHistory.push("/profile");
      }
    });

    oldAuthStore.actions.startImpersonation(
      user.id,
      appt && appt.id,
      appt && appt.startAt
    );
  }
}

Patient.endPoint = "/v1/patients";

module.exports = Patient;
