import synopsisStore from "stores/new-synopsis";
import authStore from "stores/new-auth";
import { Select } from "components/elements";
import { client } from "config";
import { observer } from "mobx-react";
import { cx, getGenderLabel, getFormattedPronouns, getFormattedGenderIdentity } from "lib/utils";
import utils from "lib/utils";

const renderSite = (site) => {
  return (
    <option key={site.id} value={site.id}>
      {site.name}
    </option>
  );
};

class ProfileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.siteRef = React.createRef();
    this.state = {
      isEditingSite: false,
    };

    this.saveDefaultSite = async () => {
      let { value } = this.siteRef.current;
      let defaultSite = synopsisStore.findSite(value);

      let { user } = authStore;
      await user.update({ defaultSite });

      this.setState({ isEditingSite: false });
    };

    this.toggleSiteEdit = () => {
      this.setState({
        isEditingSite: !this.state.isEditingSite,
      });
    };
  }

  getFormattedPronouns() {
    const {hasSOGI, featureFlagsLoaded, user} = authStore
    const isAdultWithSOGI = featureFlagsLoaded && hasSOGI && !user.isMinor;
    const isCtm = user.isProxying;
    if (isAdultWithSOGI) {
      return getFormattedPronouns(user.pronouns, isCtm);
    } else {
      return null;
    };
  };

  getFormattedGenderIdentity() {
    const {hasSOGI, featureFlagsLoaded, user} = authStore;
    const isAdultWithSOGI = featureFlagsLoaded && hasSOGI && !user.isMinor;
    const isCtm = user.isProxying;
    if (isAdultWithSOGI) {
      return getFormattedGenderIdentity(user.gender_identity, isCtm);
    } else {
      return '';
    };
  };

  renderAdmin() {
    let { email } = authStore.user;

    return (
      <tbody>
        <tr>
          <th>email</th>
          <td>
            <a className="ProfileHeader-email" href={`mailto:${email}`}>
              {email}
            </a>
          </td>
        </tr>
        {this.renderAccount()}
      </tbody>
    );
  }

  renderSOGIIcon() {
    let { featureFlagsLoaded, hasSOGI, user } = authStore;
    const isAdultWithSOGI = featureFlagsLoaded && hasSOGI && !user.isMinor;
    if (!isAdultWithSOGI) return;
    return (
      <span>
        <i className='fa fa-info-circle ProfileHeader-sogi-info-icon' onClick={this.props.onClick} onClose={this.props.onClose} id='profile-header-sogi-info-icon' role='button' aria-label='Sexual Orientation and Gender Identity Information' tabIndex='0' onKeyPress={utils.a11yClick(this.props.onClick)} />
      </span>
    )
  }

  renderAccount() {
    let { user } = authStore;
    let statusClass = cx("ProfileHeader-status", {
      accountLocked: user.isAccountLocked,
    });

    return (
      <tr>
        <th>account</th>
        <td className={statusClass}>{user.status}</td>
      </tr>
    );
  }

  renderPatient() {
    let { featureFlagsLoaded, hasSOGI, user } = authStore;
    const isAdultWithSOGI = featureFlagsLoaded && hasSOGI && !user.isMinor;
    const isCork = user.default_site == "crk"
    const isSingapore = user.default_site in ['amk', 'inn']
    const genderLabel = getGenderLabel(client, isAdultWithSOGI, isCork, isSingapore);
    let dobLabel = "dob";
    let dobField = `${user.dob} (${user.age})`;

    if (genderLabel) {
      dobLabel += ` / ${genderLabel}`;
      dobField = `${user.dob} (${user.age}, ${user.gender})`;
    }

    return (
      <tbody>
        <tr>
          <th>{dobLabel}</th>
          <td>{dobField}</td>
        </tr>
        {isAdultWithSOGI && (
          <tr>
            <th>gender identity {this.renderSOGIIcon()}</th>
            <td>
              {this.getFormattedGenderIdentity()}
            </td>
          </tr>
        )}
        <tr>
          <th>employee / emr</th>
          <td>
            {user.employeeId || "None"} / {user.patientId || "None"} {user.xoId}
          </td>
        </tr>
        {this.renderAccount()}
        <tr>
          <th>
            {client !== "apple" && client !== "cork"
              ? "primary crossover provider"
              : "primary provider"}
          </th>
          <td id="profile-header-pcp-value">
            {" "}
            {this.providerDisplayName(user)}
          </td>
        </tr>
        {(client == "nearsite" || client == "microsoft") &&
          this.renderEmployer()}
        {this.renderDefaultSite()}
      </tbody>
    );
  }

  providerDisplayName(user) {
    let provider = "";
    if (
      synopsisStore.globalIdAsPCP ||
      user.xo_physician_global_id !== undefined
    ) {
      provider = this.getPCPName(user) || "TBD";
    } else {
      provider = user.pcp || "TBD";
    }
    return provider;
  }

  getPCPName(user) {
    if (user.xo_physician_global_id) {
      let provider = synopsisStore.providers.find(
        (prov) => prov["globalId"] == user.xo_physician_global_id
      );
      return provider ? provider["name"] : null;
    }
  }

  renderEmployer() {
    const { user } = authStore;

    const employerValue = user.isAdmin ? user.client : user.shortClient;

    return (
      <tr>
        <th>employer</th>
        <td>{employerValue || "N/A"}</td>
      </tr>
    );
  }

  renderDefaultSite() {
    return (
      <tr>
        <th>default wellness center</th>
        <td>
          {this.renderSiteSelect()}
          {this.renderSiteActions()}
        </td>
      </tr>
    );
  }

  renderSiteSelect() {
    let site = authStore.user.defaultSite;

    if (this.state.isEditingSite) {
      return (
        <Select
          id="profile-site-select"
          className="ProfileHeader-siteSelect"
          defaultValue={site.id}
          ref={this.siteRef}
        >
          {synopsisStore.sites.map(renderSite)}
        </Select>
      );
    }

    return <span id="profile-site-value">{site.name}</span>;
  }

  renderSiteActions() {
    if (!authStore.me.isAdmin) return;
    if (synopsisStore.sites.length < 2) return;

    let { isEditingSite } = this.state;

    return (
      <span className="ProfileHeader-siteActions">
        (
        <button id="profile-site-toggle" onClick={this.toggleSiteEdit}>
          {isEditingSite ? "cancel" : "edit"}
        </button>
        {isEditingSite && (
          <React.Fragment>
            {" "}
            /{" "}
            <button id="profile-site-save" onClick={this.saveDefaultSite}>
              save
            </button>
          </React.Fragment>
        )}
        )
      </span>
    );
  }

  render() {
    let { featureFlagsLoaded, hasSOGI, user } = authStore;
    const isAdultWithSOGI = featureFlagsLoaded && hasSOGI && !user.isMinor;
    const formattedPronouns = this.getFormattedPronouns();
    const pronouns = (isAdultWithSOGI && formattedPronouns) ? `(${formattedPronouns})`: "";

    return (
      <header className="ProfileHeader">
        <img className="ProfileHeader-avatar" src={user.avatarURL} alt="" />
        <div className="ProfileHeader-info">
          <h2 className="ProfileHeader-name">{user.legalAndPreferredName} {pronouns}</h2>
          <table aria-label="Account Information">
            {user.isAdmin ? this.renderAdmin() : this.renderPatient()}
          </table>
        </div>
      </header>
    );
  }
}

module.exports = observer(ProfileHeader);
