{div, h2, p, button, ul, li, tch} = Exim.DOM

SignInInfo = Exim.createView module.id,
  listen: ['app/config']
  render: ->
    title = @state.config?.frontend?.app?.title4 or 'Crossover Health Center'
    div className: 'WhichActivationTypeModel--content',
      p null,
        "Activate your #{title} account with your Employee credentials"
      ul null,
        li null, 'Your work email, name, and security credentials will be passed to the portal'
        li null, 'You can still choose a personal email for notifications from the portal'
        li null, 'Your personal contact information can be edited after activation'
      p null,
        'Or activate using account credentials that you specify'
      ul null,
        li null, 'You will still need to provide the work email address of the primary policy holder to help confirm your identity'
        li null, 'Your personal contact information can be edited after activation'

      tch tagName: 'button', className: "Button Button--primary Button--large Button--expand", handler: @props.close, 'Close'

module.exports = SignInInfo
