{authStore, urgentStore} = require 'stores'
{div, tch, span, ul, li, fa} = Exim.DOM
Widget = require './Widget'
utils = require 'lib/utils'
{formats} = require 'config'

UrgentNoticeWidget = Exim.createView module.id,
  listen: ['urgent/text']

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    innerHtml: null
    latest: null

  componentWillMount: ->
    urgentStore.actions.fetch()

  render: ->
    user = authStore.get('user')
    {text} = @state

    if text
      div className: 'UrgentLandingWidget',
        Widget
          icon: 'exclamation-triangle'
          title: "Important Notice"
          body:
            []
          innerHtml: text
    else
      null


module.exports = UrgentNoticeWidget
