import { observer } from "mobx-react";
import { Modal } from "components/elements/new-modal";
import {
  DayPickerSingleDateController,
  DayPickerRangeController,
} from "react-dates";
import synopsisStore from "stores/new-synopsis";
import oooStore from "stores/ooo";

const isInPast = (date) => date < synopsisStore.site.today;
const ConfirmOOOModal = ({ provider, dates, abort, resolve }) => {
  let title = `Book OOO day(s) for ${provider.name}?`;

  return (
    <Modal title={title} className="OOOModal-confirm" close={abort}>
      <div>
        <p>
          Are you sure you want to book Out of Office day(s) for{" "}
          <span className="OOOModal-provider">{provider.name}</span>?
        </p>
        <div className="OOOModal-dates">{dates}</div>
      </div>
      <footer className="ConfirmFooter">
        <div className="ConfirmFooter-buttons">
          <button className="Button" onClick={abort}>
            Cancel
          </button>
          <button className="Button ConfirmButton" onClick={resolve}>
            Confirm
          </button>
        </div>
      </footer>
    </Modal>
  );
};

class OOOModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "day",
      startDate: null,
      endDate: null,
      focused: false,
      focusedInput: "startDate",
    };

    this.changeTab = this.changeTab.bind(this);
    this.bookOOO = async () => {
      let { showChildModal, provider, abort } = this.props;

      await showChildModal(
        <ConfirmOOOModal provider={provider} dates={this.datesText} />
      );

      await oooStore.book({
        provider,
        startDay: this.state.startDate,
        endDay: this.state.endDate,
      });

      abort();
    };
  }

  changeTab(tab) {
    this.setState({
      tab,
      startDate: null,
      endDate: null,
      focused: false,
      focusedInput: "startDate",
    });
  }

  renderCalendar() {
    switch (this.state.tab) {
      case "day":
        return (
          <DayPickerSingleDateController
            date={this.state.startDate}
            onDateChange={(date) =>
              this.setState({ startDate: date, endDate: date })
            }
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            isOutsideRange={isInPast}
          />
        );
      case "range":
        return (
          <DayPickerRangeController
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={(dates) => this.setState(dates)}
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) => this.setState({ focusedInput })}
            isOutsideRange={isInPast}
          />
        );
    }
  }

  get datesText() {
    let { startDate, endDate } = this.state;
    if (startDate && endDate) {
      let startDateText = startDate.format("l");
      let endDateText = endDate.format("l");

      return startDate.isSame(endDate)
        ? startDateText
        : `${startDateText} to ${endDateText}`;
    }

    return "Select day(s)";
  }

  render() {
    let { provider } = this.props;
    let { tab } = this.state;

    return (
      <Modal
        title="Book OOO for Provider"
        className="OOOModal"
        close={this.props.abort}
      >
        <div className="BookOOOText">
          <p>
            Choose the OOO day(s) to book for{" "}
            <span className="OOOModal-provider">{provider.name}</span>:
          </p>
        </div>

        <div className="BookOOOToggle">
          <div
            className={`BookOOOToggle-button ${
              tab == "day" ? "is-active" : ""
            }`}
            onClick={() => this.changeTab("day")}
          >
            One Day
          </div>
          <div
            className={`BookOOOToggle-button ${
              tab == "range" ? "is-active" : ""
            }`}
            onClick={() => this.changeTab("range")}
          >
            Range of Days
          </div>
        </div>

        <div className="BookOOOCalendar">{this.renderCalendar()}</div>

        <div className="BookOOODatesPreview">
          <span>{this.datesText}</span>
        </div>

        <div className="BookOOOFooter">
          <div className="BookOOOFooter-buttons">
            <button className="Button" onClick={this.props.abort}>
              Cancel
            </button>
            <button
              className="Button ConfirmButton"
              onClick={this.bookOOO}
              disabled={!(this.state.startDate && this.state.endDate)}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

module.exports = observer(OOOModal);
