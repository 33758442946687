import WellnessCard from "components/elements/WellnessCard";
import Graph from "components/elements/Graph";
import { cardioResults } from "config";

class CVRiskResults extends React.Component {
  renderResultsText(value, { green, yellow, red }) {
    let score = null;
    if (value < green.high * 100) {
      score = "low";
    } else if (value >= yellow.low * 100 && value < yellow.high * 100) {
      score = "medium";
    } else if (value >= red.low * 100) {
      score = "high";
    }
    return cardioResults[score];
  }

  renderSkippedText() {
    const {
      values: { skipped_reason },
    } = this.props;
    return cardioResults[skipped_reason];
  }

  render() {
    const { values, graphData } = this.props;
    const { name, value, scale, skipped_reason, status } = values;
    const keys = ["low", "medium", "high", "your_score"];
    const legendLabels = ["Low Risk", "Medium Risk", "High Risk", "Your Score"];
    const data = graphData(values, "cv_risk_score");
    const tickFormat = (tick) => (tick == 60 ? `>${tick}%` : `${tick}%`);

    return (
      <WellnessCard
        title={name}
        iconClass="fa-heartbeat"
        className="CVRisk-MainWellnessCard"
      >
        <div className="WellnessResults-Content-CVRisk">
          <div className="WellnessResults-Content-CVRisk--intro">
            {cardioResults.intro}
          </div>{" "}
          <br />
          {status === "done" && (
            <React.Fragment>
              <br />
              <span className="WellnessResults-Content-CVRisk--subtitle">
                10-year risk of heart disease or stroke: {value}%
              </span>{" "}
              <br />
              <span className="WellnessResults-Content-CVRisk--msg">
                {this.renderResultsText(value, scale[0])}
              </span>
              <div className="Wellness-graph--cvrisk">
                <Graph
                  data={data}
                  keys={keys}
                  legendLabels={legendLabels}
                  rangeThreshhold={{
                    low: scale[0].green.low,
                    high: scale[0].red.high * 100,
                  }}
                  xAxisLabel={"Risk"}
                  labelClassName={"Hra-axisLabel"}
                  tickFormat={tickFormat}
                />
              </div>
            </React.Fragment>
          )}
          {status === "skipped" && (
            <React.Fragment>
              <span className="WellnessResults-Content-CVRisk--skipped">
                {" "}
                <br />
                {skipped_reason && this.renderSkippedText()}
              </span>{" "}
              <br />
            </React.Fragment>
          )}{" "}
          <br />
          <div className="WellnessResults-Content-CVRisk--disclaimer">
            {cardioResults.disclaimer}
          </div>
        </div>
      </WellnessCard>
    );
  }
}

export default CVRiskResults;
