Range = require './Range'

DateRange = Exim.createView module.id,
  getDefaultProps: ->
    end: new Date
    steps: 100

  changeRange: (data) ->
    dates = data.map (item) -> new Date(item)
    @props.onChange(dates)

  render: ->
    {start, end, steps, multiple} = @props
    diff = (end - start)
    step = diff / steps

    Range
      className: @props.className
      min: +start
      max: +end
      # step: diff / 100
      onStop: @changeRange
      multiple: multiple

module.exports = DateRange
