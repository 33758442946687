{documentsStore} = require 'stores'

utils = require 'lib/utils'
{cx}  = Exim.helpers
{div, input, span, input, span, fa, spinner} = Exim.DOM

FileItem = Exim.createView module.id,
  getInitialState: ->
    progress: 0
    name: @props.data.file.name
    newName: @props.data.file.name
    isEditingName: false

  onRemove: ->
    @props.onRemove(@props.id)

  upload: ->
    return if @state.isUploaded
    onProgress = (status) =>
      @setState progress: status.percents
    @setState isUploading: true
    documentsStore.actions.upload(@props.data.file, onProgress: onProgress, fileName: @state.name).then =>
      @setState isUploading: false, isUploaded: true, progress: 0
    .catch =>
      @setState isUploading: false, isUploaded: false, progress: 0, isError: true

  rename: ->
    @setState isEditingName: !@state.isEditingName

  onChangeName: (evt) ->
    {value} = evt.currentTarget
    @setState newName: value

  placeCursor: (evt) ->
    evt.currentTarget.value = evt.currentTarget.value

  handleKey: (evt) ->
    switch evt.keyCode
      when 13 then @setState isEditingName: false, name: @state.newName or @state.name
      when 27 then @setState isEditingName: false, newName: @state.name

  onBlur: (evt) ->
    @setState isEditingName: false, name: @state.newName or @state.name

  render: ->
    {file, src} = @props.data
    {name, isUploading, isUploaded, isEditingName, isError, progress} = @state
    perc = progress / 100

    div className: 'row',
      div className: "FileUploadItem #{cx uploading: isUploading, "is-uploaded": isUploaded, editing: isEditingName, error: isError}",
        div className: 'FileUploadItem-preview',
          fa utils.getIconName file.type
        div className: 'FileUploadItem-name',
          if isEditingName
            div className: 'text-input',
              input type: 'text', defaultValue: name, ref: 'rename', onChange: @onChangeName, onKeyDown: @handleKey, onBlur: @onBlur, autoFocus: true, onFocus: @placeCursor
          else
            span onDoubleClick: @rename,
              name
        div className: 'FileUploadItem-size',
          ' - '
          utils.humanizeSize file.size
        div className: 'FileUploadItem-spacer'
        div className: 'FileUploadItem-progress',
          if isError
            span onClick: @upload, fa 'repeat'
          else if isUploading
            spinner(null, 'small')
          else if isUploaded
            fa 'check'
          else
            span className: 'FileUploadItem-actions',
              span onClick: @rename, fa 'pencil-square-o FileUploadItem-actions-rename'
              span onClick: @props.onRemove, fa 'trash-o FileUploadItem-actions-remove'
      if isUploading
        div className: 'file-bg', style: width: "#{progress}%", opacity: perc

module.exports = FileItem
