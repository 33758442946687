"use strict";
/* globals React, Exim */
const { PropTypes } = React;
import { observer } from "mobx-react";
import synopsisStore from "stores/new-synopsis";

/*
FEATURE FLAG
Utility for controlling if components render based on server side feature flags
These flags go from v1/synopsis > synopsis.coffee > new-synopsis.js
Without options, disabled features return null. With options, warnings are rendered
*/
class FeatureFlag extends React.Component {
  render() {
    const { children, flag, warn, title, message } = this.props;
    // Check if this feature is explicitly defined, and false. Prevents FOUTs
    const featureFlag = synopsisStore[flag];
    if (typeof featureFlag === "boolean" && !featureFlag) {
      return warn ? (
        <div>
          <h4>{title}</h4>
          <p>{message}</p>
        </div>
      ) : null;
    }
    return children;
  }
}

FeatureFlag.propTypes = {
  children: PropTypes.any.isRequired,
  flag: PropTypes.string.isRequired,
  warn: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
};
FeatureFlag.defaultProps = {
  children: null,
  flag: "",
  warn: false,
  title: "Work in Progress",
  message: "This feature is unavailable",
};

const WrappedFeatureFlag = observer(FeatureFlag);

/*
NOTE: This exports a HOC
Props will be drilled normally, even those with namespace overlap
Example Usage:
module.exports = withFeatureFlag(
  observer(AdHocScreening), 'hasScreeningFramework', { warn: true, message: 'The XO Portal team is finishing this feature' }
)
*/
const withFeatureFlag = (Component, flag, options) => {
  return (props) => (
    <WrappedFeatureFlag flag={flag} {...options}>
      <Component {...props} />
    </WrappedFeatureFlag>
  );
};

module.exports = {
  FeatureFlag: WrappedFeatureFlag,
  withFeatureFlag,
};
// NOTE: To use in exim, select the FeatureFlag prop:
// FeatureFlag = EximShim require('components/util/FeatureFlag').FeatureFlag
