/* global Exim, React */
const { PropTypes, Fragment } = React;
import { differenceWith, isEqual } from "lodash";
import { configureSurvey } from "lib/utils";
import { Modal } from "components/elements/new-modal";
import * as SurveyJS from "survey-react";
import { disableLinkedField } from "lib/utils";

/*
INTERACTIVE SURVEY
Renders SurveyJS templates, e.g. Health Screenings
Framework assessment: https://trello.com/c/CkHVwDhr/697
*/
class InteractiveSurvey extends React.Component {
  constructor(props) {
    super(props);
    const model = this.handleCreateModel(props.template, props.data);
    this.state = { isCompleted: false, showModal: false, model };

    configureSurvey(SurveyJS);

    this.handleCreateModel = this.handleCreateModel.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.renderInfoButton = this.renderInfoButton.bind(this);
    this.closeInfoModal = this.closeInfoModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { template, data } = nextProps;
    const dataChange = data !== this.props.data;
    const pageChange =
      differenceWith(template.pages, this.props.template.pages, isEqual) > 0;

    if (pageChange || dataChange) {
      const model = this.handleCreateModel(template, data);
      this.setState({ isCompleted: false, showModal: false, model });
    }
  }

  handleCreateModel(template, data) {
    let model = new SurveyJS.Model(template);
    Object.assign(model, {
      data: JSON.parse(data),
      clearInvisibleValues: "none",
    });
    model = disableLinkedField(model);

    model.onAfterRenderQuestion.add((survey, options) => {
      const {
        question: { popupDescription },
      } = options;
      if (!popupDescription) return;

      const btn = this.renderInfoButton(popupDescription);
      const header = options.htmlElement.querySelector("h5");
      header.appendChild(btn);
    });

    return model;
  }

  handleComplete(survey, options) {
    this.props.onComplete(survey.data);
    this.setState({ isCompleted: true });
  }

  handleValueChange(survey, options) {
    const { onChange, passBothVisibleInvisible } = this.props;

    onChange(
      survey.data,
      passBothVisibleInvisible
        ? survey.currentPage.elements.length
        : survey.currentPage.elements.filter((el) => el.isVisible).length,
      options
    );
  }

  showInfoModal(popupDescription) {
    this.setState({
      showModal: true,
      popupDescription,
    });
  }

  closeInfoModal() {
    this.setState({
      showModal: false,
    });
  }

  renderInfoButton(popupDescription) {
    const btn = document.createElement("i");
    btn.className = "infoButton fa fa-info-circle";
    btn.onclick = () => this.showInfoModal(popupDescription);
    return btn;
  }

  onPageChange = () => {
    const { onPageChange } = this.props;
    if (onPageChange) {
      onPageChange();
    }
  };

  render() {
    const { data, prompt } = this.props;
    const { isCompleted, model } = this.state;

    Object.assign(
      model,
      {
        isCompleted, // Fixes a SurveyJS bug where survey state resets with render()
        completedHtml: `<h1>${prompt}</h1>`,
        showPageTitles: false,
        showQuestionNumbers: "off",
      },
      this.props.surveyOpts
    );

    const { popupDescription, showModal } = this.state;

    return (
      <section id="SurveyJS">
        <SurveyJS.Survey
          model={model}
          onComplete={this.handleComplete}
          onValueChanged={this.handleValueChange}
          onCurrentPageChanged={this.onPageChange}
        />
        {showModal && (
          <Modal close={this.closeInfoModal} className="infoModal--card">
            <div className="infoModal--description">{popupDescription}</div>
          </Modal>
        )}
      </section>
    );
  }
}

InteractiveSurvey.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    // NOTE: The data prop is an observable
    data: PropTypes.shape({
      pages: PropTypes.any,
    }),
  }),
  data: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  prompt: PropTypes.string,
  passBothVisibleInvisible: PropTypes.bool,
};
InteractiveSurvey.defaultProps = {
  template: {
    id: null,
    name: null,
    status: null,
    data: {},
  },
  data: "",
  onComplete: null,
  onChange: () => undefined,
  prompt: "Survey responses saved!",
  passBothVisibleInvisible: false,
};

module.exports = InteractiveSurvey;
