import checkInStore from "stores/new-check-in";
import CheckInCard from "../check-in-card";
import { observer } from "mobx-react";
import Step from "components/mixins/Step";

const ThanksStep = React.createClass({
  displayName: "ThanksStep",
  mixins: [Step],

  render() {
    let { user } = checkInStore;

    return (
      <CheckInCard>
        <h1 className="CheckInCard-title">Thank You</h1>
        <p className="CheckInCard-name">{user.name}</p>
        <p className="CheckInCard-title">Your check-in is completed</p>
        {!this.isPreflight() && (
          <p className="CheckInCard-note">
            You may return this device to the host.
          </p>
        )}
        <footer className="CheckInCard-footer">
          <button className="CheckInCard-leave" onClick={this.leave}>
            Leave Check-In
          </button>
        </footer>
      </CheckInCard>
    );
  },
});

export default observer(ThanksStep);
