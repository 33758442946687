const { Fragment } = React;
import ReactDOM from "react-dom";
import { observer } from "mobx-react";
import checkInStore from "stores/new-check-in";
import oldCheckInStore from "stores/checkin";
import ScreeningInteraction from "../../screenings/ScreeningInteraction";
import CheckInFooter from "../check-in-footer";
import { Expandable } from "components/elements";
import { showModal } from "components/elements/new-modal";
import ExitConfirmation from "components/modals/ExitConfirmation";

class Screenings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      currentPage: 0,
      canLeave: false,
    };
    this._nodes = new Map();

    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleSelectPage = this.handleSelectPage.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSaveAndExit = this.handleSaveAndExit.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.handleLeave);
    const { patient_screenings } = oldCheckInStore.get("checkin");
    this.setState({ templates: patient_screenings }, () =>
      this.handleScroll(this.state.currentPage)
    );
  }

  handleScroll = (index) => {
    const headerOffSet = 10;
    const messageOffSet = 160;
    const node = this._nodes.get(index);

    if (index === 0 || !node) {
      window.scroll({ top: headerOffSet, behavior: "smooth" });
    } else {
      const domNode = ReactDOM.findDOMNode(node);
      window.scroll({
        top: domNode.offsetTop - headerOffSet - messageOffSet,
        behavior: "smooth",
      });
    }
  };

  handleSelectPage(pageNumber) {
    const { currentPage, templates } = this.state;
    const isSamePage = pageNumber === currentPage;
    const isLastPage = pageNumber >= templates.length;

    const newCurrentPage = isSamePage || isLastPage ? null : pageNumber;
    this.setState({ currentPage: newCurrentPage }, () =>
      this.handleScroll(newCurrentPage)
    );
  }

  handleValueChange(index) {
    return (survey) => {
      this.setState({ canLeave: false });
      const { templates } = this.state;

      templates[index] = {
        ...templates[index],
        values: survey.data,
        completed: survey.mode,
      };
      this.setState({ templates });
    };
  }

  handleSave(pageNumber = null) {
    return async () => {
      await oldCheckInStore.actions.update({
        ...oldCheckInStore.get("checkin"),
        patient_screenings: this.state.templates,
      });

      if (pageNumber !== null) this.handleSelectPage(pageNumber);
    };
  }

  handleSaveAndExit(pathname) {
    return async () => {
      await oldCheckInStore.actions.update({
        ...oldCheckInStore.get("checkin"),
        patient_screenings: this.state.templates,
      });

      this.setState({ canLeave: true }, () => this.props.router.push(pathname));
    };
  }

  handleLeave(nextLocation) {
    if (this.state.canLeave || checkInStore.screeningCompleted()) {
      this.handleSave();
      return true;
    }

    showModal(
      <ExitConfirmation
        onExit={this.handleSaveAndExit(nextLocation.pathname)}
      />
    );
    return false;
  }

  handleNext() {
    const { next } = checkInStore.getStep();
    next.activate();
  }

  render() {
    const { currentPage, templates } = this.state;

    return (
      <Fragment>
        <section className="Screenings">
          <div className="Screenings-top-msg">
            Please answer the questions below to help us better understand the
            things that can affect your health. Since these things can change
            over time, we will ask the questions periodically, depending on the
            type of visit scheduled. Thank you in advance!
          </div>
          <div className="Screenings-accordion">
            {templates.map((template, index) => (
              <Expandable
                key={template.tmpl.id}
                title={`${index + 1} of ${templates.length} ${
                  template.tmpl.title
                }`}
                isExpanded={index === currentPage}
                isCompleted={template.completed === "display"}
                handleToggle={this.handleSave(index)}
                ref={(element) => this._nodes.set(index, element)}
              >
                <ScreeningInteraction
                  onValueChanged={this.handleValueChange(index)}
                  onCompleted={this.handleSave(index + 1)}
                  template={template}
                />
              </Expandable>
            ))}
          </div>
          <CheckInFooter
            onNext={this.handleNext}
            disableContinue={!checkInStore.screeningCompleted()}
          />
        </section>
      </Fragment>
    );
  }
}

export default observer(Screenings);
