import { observer } from "mobx-react";
import isEmpty from "lodash/isEmpty";
import ScreeningInteraction from "components/screenings/ScreeningInteraction";
import screeningStore from "stores/screening";
import authStore from "stores/new-auth";
import synopsisStore from "stores/new-synopsis";
import ExitConfirmation from "components/modals/ExitConfirmation";
import { showModal, closeModal } from "components/elements/new-modal";

class OutboundScreening extends React.Component {
  state = {
    screeningPayload: {},
    surveyData: {},
    surveyCompleted: false,
  };

  static contextTypes = {
    router: React.PropTypes.object.isRequired,
  };

  componentDidMount() {
    const {
      routeParams: { id },
      router,
    } = this.props;
    const screeningPayload = screeningStore.healthScreeners.find(
      (e) => e.id === +id
    );
    const { patientId } = authStore.user;
    if (!screeningPayload) {
      return router.push("/health-screenings");
    }

    this.setState({ screeningPayload });
    if (screeningPayload.screening_template) {
      screeningStore.setOutboundScreener(screeningPayload);
    } else {
      screeningStore.fetchOutboundScreeningTemplate(patientId, id);
    }
  }

  componentWillUnmount() {
    screeningStore.clearOutboundScreeningData();
  }

  goBack = () => {
    const {
      router: { goBack },
    } = this.props;
    closeModal();
    goBack();
  };

  saveAndGoback = () => {
    const { patientId } = authStore.user;
    const { surveyData } = this.state;

    screeningStore.saveOutboundScreeningData(patientId, surveyData);
    this.goBack();
  };

  backButtonClicked = () => {
    const { surveyCompleted, surveyData } = this.state;

    if (!surveyCompleted && !isEmpty(surveyData)) {
      showModal(
        <ExitConfirmation
          continueText="Continue Survey"
          onExit={this.saveAndGoback}
        >
          <div>
            <span>
              You have not answered all of the assigned screening questions. If
              you wish to leave now, your information will be saved so you can
              pick up where you left off.
            </span>
          </div>
        </ExitConfirmation>
      );
    } else {
      this.goBack();
    }
  };

  getTemplateData = () => {
    const { status } = this.state.screeningPayload;

    return {
      tmpl: screeningStore.outboundScreenerTemplate,
      values: JSON.parse(screeningStore.patientScreenerData),
      completed: status === "Complete" ? "display" : "edit",
    };
  };

  onSurveyChange = (payload) => {
    const { data, isCompleted } = payload;

    this.setState({
      surveyData: data,
      surveyCompleted: isCompleted,
    });
  };

  onSurveyComplete = (payload) => {
    const { patientId } = authStore.user;
    const { data } = payload;
    screeningStore.completeOutboundScreener(patientId, data);
  };

  render() {
    // temporarily hide this feature behind feature flag
    if (!synopsisStore.enableOutboundPros) return null;

    if (isEmpty(screeningStore.outboundScreenerTemplate)) return null;

    return (
      <div className="ContentInner outboundscreening">
        <span className="Button--back" onClick={this.backButtonClicked}>
          Back
        </span>
        <ScreeningInteraction
          template={this.getTemplateData()}
          onCompleted={this.onSurveyComplete}
          onValueChanged={this.onSurveyChange}
        />
      </div>
    );
  }
}

module.exports = observer(OutboundScreening);
