import hraStore from "stores/hra-survey";
import { observer } from "mobx-react";
import ResultsHeader from "./resultsHeader";
import ResultsContent from "./resultsContent";

class HraResultsWrapper extends React.Component {
  componentWillMount() {
    const {
      router,
      params: { magicToken },
    } = this.props;

    if (!hraStore.isVerified) {
      router.push(`/surveys/hra/${magicToken}/verify`);
    }

    hraStore.lastStepId = "results";
  }

  render() {
    return (
      <div className="Wellness">
        <ResultsHeader />
        <div className="Hra-wrapper">
          <ResultsContent />
        </div>
      </div>
    );
  }
}

export default observer(HraResultsWrapper);
