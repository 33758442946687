{cx} = Exim.helpers
{tch, button, input, spinner, div, fa, span} = Exim.DOM

ListLink = Exim.createView module.id,
  contextTypes:
    router: React.PropTypes.object.isRequired

  getDefaultProps: ->
    onlyActiveOnIndex: false

  transition: (e) ->
    e.preventDefault()
    console.log 'transition', @props
    {router} = @context
    [pathname, query, state] = [@props.to, @props.query, @props.state]
    router.push {pathname, query, state}

  stopPropagation: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()

  onUploadClick: (e) ->
    e.stopPropagation()
    @refs.fileinput.click()

  onKeyDown: (e) ->
    if e.which is 13 or e.which is 32
      handler = if @props.upload then @upload else if @props.action then @props.action else @transition
      handler(e)

  upload: (e) ->
    @props.upload(e).then ->
      console.log 'upload success'
      window.cache?.clear ((msg) -> console.log('Cache cleared', msg)), ((err) -> console.log('Cache clearing error', err))

  isActive: (route, indexOnly) ->
    @props.location.pathname.match ///#{if indexOnly then '^' else ''}#{route}///

  render: ->
    {props, context} = @
    {router} = context

    handler = if props.upload then @upload else if props.action then props.action else @transition
    className = if props.type is 'action' then 'Button Button--headerAction Button--squeezeOnXs ContentHeader-action u-flexChild' else 'ContentHeader-dropdownLink'
    className += ' is-disabled' if props.disabled

    idName = if props.children is 'New Document' then 'NewDocumentButton'

    if props.upload
      if props.uploading
        div className: 'ContentNav-spinner',
          spinner()
      else
        tch tagName: 'button', className: className, handler: @onUploadClick, id: idName,
          input type: 'file', ref: 'fileinput', 'data-e2e': 'content-header-upload-file', className: 'Input--fileHidden', onChange: handler, multiple: false, 'aria-hidden': 'true', tabIndex: '-1'
          fa props.icon if props.icon
          span className: 'Button-text Button-text',
            @props.children
    else
      div className: className, tabIndex: 0, role: 'button', 'aria-label': props.children, onKeyDown: @onKeyDown, onMouseDown: handler, onTouchStart: handler, onClick: @stopPropagation,
        fa props.icon if props.icon
        if props.type
          span className: 'Button-text',
            props.children
        else
          props.children

module.exports = ListLink
