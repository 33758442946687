"use strict";
const { Link } = require("react-router");
const { appStore } = require("stores");
const Spinner = require("components/elements/new-spinner");
const SignupError = require("components/auth/signup-error");
const AuthWrapper = require("components/auth/auth-wrapper");
const { cx } = require("lib/utils");
const request = require("lib/new-request");

class Verify extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVerifying: false,
      isVerified: false,
      welcomeMessage: "",
    };
  }

  async verifyEmail(code) {
    this.setState({ isVerifying: true });

    try {
      let { welcome_message: welcomeMessage } = await request.post(
        "/v1/user/email",
        { code }
      );

      this.setState({ isVerified: true, welcomeMessage });
    } catch {
      this.setState({ isVerified: false });
    }

    this.setState({ isVerifying: false });
  }

  componentWillMount() {
    appStore.actions.setTitle("Verify");

    let { code } = this.props.routeParams;
    this.verifyEmail(encodeURIComponent(code));
  }

  componentWillUnmount() {
    appStore.actions.setTitle();
  }

  renderCard() {
    let { isVerified } = this.state;
    let verifyClass = cx("Verify", { verified: isVerified });

    return (
      <div className={`AuthCard ${verifyClass}`}>
        {isVerified ? this.renderSuccess() : <SignupError />}
        <footer className="Verify-footer">
          <Link className="Verify-return" to="/signin">
            Return to Sign In
          </Link>
        </footer>
      </div>
    );
  }

  renderSuccess() {
    return <div className="Verify-success">{this.state.welcomeMessage}</div>;
  }

  render() {
    let { isVerified, isVerifying } = this.state;

    return (
      <AuthWrapper title={isVerified && "You're in!"}>
        {isVerifying ? (
          <Spinner className="Verify-spinner" />
        ) : (
          this.renderCard()
        )}
      </AuthWrapper>
    );
  }
}

module.exports = Verify;
