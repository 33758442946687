request = require 'lib/new-request'
marked = require 'marked'

module.exports = store = Exim.createStore
  path: 'urgent'
  actions: [
    'fetch'
  ]

  initial:
    content: ""

  fetch:
    while: (urgentLoading) ->
      @set {urgentLoading}
    on: ->
      request.get('v1/urgent_notice')
    did: (text) ->
      renderer = new marked.Renderer()
      renderer.link = (href, title, text) ->
        "<a target=\"_blank\" href=\"#{href}\" rel=\"noopener noreferrer\">#{text}</a>"

      this.set({
        text: marked(text, { renderer }),
      })