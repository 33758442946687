"use strict";
const { appointmentsStore } = require("stores");
const { observer } = require("mobx-react");
const { Modal } = require("components/elements/new-modal");
const Note = require("models/appointment-note");

class PayLaterModal extends React.Component {
  async handleConfirm() {
    let { appointment } = this.props;
    let owed = parseFloat(appointment.owed.replace("$", ""));
    if (isNaN(owed)) {
      this.props.abort();
      return;
    }

    await appointmentsStore.actions.generateApptCharge(
      appointment.id,
      appointment.patient.id,
      owed * 100
    );

    let text = this.$reason.value;
    let note = new Note({ text, appointment });
    if (note.isSaveable) {
      try {
        await note.post();
      } catch {
        alert("Failed to create note.");
      }
    }

    this.props.resolve();
  }

  render() {
    let { abort } = this.props;

    return (
      <Modal
        className="PayLaterModal"
        title="Send to Unpaid List"
        close={abort}
      >
        <textarea
          className="PayLaterModal-reason"
          placeholder="Unpaid reason (optional)"
          ref={(el) => (this.$reason = el)}
        />
        <footer className="PayLaterModal-footer">
          <button className="Button" onClick={abort}>
            Cancel
          </button>
          <button
            className="Button Button--primary"
            onClick={() => this.handleConfirm()}
          >
            Confirm
          </button>
        </footer>
      </Modal>
    );
  }
}

module.exports = observer(PayLaterModal);
