{authStore, patientStore} = require 'stores'

utils    = require 'lib/utils'
media = require 'components/mixins/MediaQuery'
{cx}  = Exim.helpers
{div, tch, fa, span, input}      = Exim.DOM

MessagesList = Exim.createView module.id,
  mixins: [
    media(mobile: 'md')
  ]

  filterDescriptions: {
    'unread': 'Show unread messages',
    'flagged': 'Show flagged messages',
    'attached': 'Show messages with attachments',
    'meds': 'Show medications messages',
  }

  getInitialState: ->
    showFilters: false

  focus: ->
    @refs.self.querySelector('.MessagesFilter')?.focus()

  filter: (newFilter) -> (evt) =>
    evt.preventDefault()
    evt.stopPropagation()
    {filter, onUnload, applyFilter} = @props
    newFilter = false if filter is newFilter
    existingFilterDescriptions = []
    updatedFilterDescriptions = []
    @props.filter?.map (filterDesc) => 
      existingFilterDescriptions.push(@filterDescriptions[filterDesc])
      updatedFilterDescriptions.push(@filterDescriptions[filterDesc])
    if newFilter in filter
      updatedFilterDescriptions = existingFilterDescriptions.filter ((filterDesc) => filterDesc != @filterDescriptions[newFilter])
      updatedFilterCount = @props.filter?.length - 1
    else 
      updatedFilterDescriptions.push(@filterDescriptions[newFilter])      
      updatedFilterCount = @props.filter?.length + 1
    onUnload?()
    applyFilter?(newFilter)
    utils.screenReaderSpeak("#{updatedFilterCount} #{if updatedFilterCount == 1 then 'filter' else 'filters'}, messages filtered by: #{updatedFilterDescriptions}")

  filterItem: (filter, label, ariaLabel, icon) ->
    return null if @props.filtersList and not (filter in @props.filtersList)

    isActive = filter in @props.filter
    tch tagName: 'button', 'aria-checked': isActive, 'role': 'checkbox', 'aria-label': ariaLabel, handler: @filter(filter), className: cx('MessagesFilter': true, 'is-active': isActive),
      span className: 'MessagesFilter-content',
        fa icon
        span className: 'MessagesFilter-contentText',
          label

  toggleFilters: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()
    @setState showFilters: !@state.showFilters

  search: (evt) ->
    searchInput = evt.currentTarget
    {value} = searchInput
    @props.onSearch?(value)
    @setState {searchValue: value}

  clearSearch: (evt) ->
    evt?.stopPropagation()
    @props.clearSearch?()
    @refs.searchInput?.value = ''
    @setState searchValue: ''

  render: ->
    {filter} = @props
    {showFilters, searchValue} = @state
    filtersLength = filter.length

    div className: "MessagesFilters #{cx 'MessagesFilters--showFilters': showFilters}", ref: 'self',
      if @state.mobile
        div className: 'MessagesFiltersTop',
          div className: 'MessagesFiltersSearch',
            input className: "MessagesFiltersSearch-input Input", type: 'search', ref: 'searchInput', defaultValue: searchValue, placeholder: 'Search', onChange: @search
            if searchValue?.length
              tch className: 'MessagesFiltersSearch-clear', handler: @clearSearch,
                fa 'times'

          div className: 'MessagesFiltersToggle', onClick: @toggleFilters, onKeyPress: utils.a11yClick(@toggleFilters), tabIndex: 0,
            'Filters'
            " (#{filtersLength}) "
            fa 'angle-down'

      if @state.mobile and !showFilters and filtersLength > 0
        tch className: "MessagesFiltersClear", handler: @filter(false),
          fa 'times'
          span 'Clear Filters'

      div className: "MessagesFiltersWrapper",
        div className: "MessagesFiltersWrapper-top",
          div className: 'MessagesFilters-label',
            'Filters'
            " (#{filtersLength})"
          if @state.mobile
            tch className: 'MessagesFilters-reset', handler: @filter(false),
              'RESET'

        div className: "MessagesFiltersWrapper-bottom", role: 'group', 'aria-label': "Filters (#{filtersLength})",
          @filterItem 'unread', 'Unread', "#{@filterDescriptions['unread']}", 'envelope'
          @filterItem 'flagged', 'Flagged', "#{@filterDescriptions['flagged']}", 'flag'
          @filterItem 'attached', 'Attachment', "#{@filterDescriptions['attached']}", 'paperclip'
          @filterItem 'meds', 'Medications', "#{@filterDescriptions['meds']}", 'flask'
          # @filterItem 'onlyme', 'Only to Me', 'user'

          if not @state.mobile and filtersLength > 0
            tch tagName: 'a', href: '#', 'aria-label': "Reset filters", className: 'MessagesFilters-reset', handler: @filter(false),
              fa 'times'

module.exports = MessagesList
