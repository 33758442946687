{ observer } = require('mobx-react')
{ withEscapeHandler } = require('components/elements/new-modal')
{ div, p, strong } = Exim.DOM

PopUp = withEscapeHandler(((props) =>
  return React.createElement(React.Fragment, null,
    div className: 'ProfileInfoPopUp-overlay', onClick: props.close
    props.children
  )), 'profile-header-sogi-info-icon')

SOGIInfoPopUp = Exim.createView module.id,
  componentDidMount: ->
    @refs.popUp?.focus()

  render: ->
    { rect: { bottom, right }, abort } = this.props
    { isCtm, hasRaceAndEthnicity, showSexualOrientation, hideNameUsed } = this.props
    popUpClassName = if (bottom < 535) then 'ProfileInfoPopUp-no-transform' else 'ProfileInfoPopUp-transform'

    ctmNameUsedText = 'This is the name that the member wants the Care Team to use.'
    ctmSexAtBirthText = 'Sex assigned at birth: Assigned to babies when born, based on appearance of genitalia. This is different from gender identity, and used for administrative purposes.'
    ctmPronounsText = 'How the member wants to be referred to in the third person.'
    
    memberNameUsedText = 'This is name you want your Care Team to use.'
    memberSexAtBirthText = 'Sex assigned at birth: Assigned to babies when born, based on appearance of genitalia. This is different from gender identity, and used for administrative purposes. If this is not accurate, please let your provider know.'
    memberPronounsText = 'How you want to be referred to in the third person. If your pronouns are not listed, please let your provider know.'
    
    nameUsedText = if !isCtm then memberNameUsedText else ctmNameUsedText
    sexAtBirthText = if !isCtm then memberSexAtBirthText else ctmSexAtBirthText
    pronounsText = if !isCtm then memberPronounsText else ctmPronounsText

    return React.createElement(PopUp, {close: abort},
      div ref: 'popUp', tabIndex: 0, className: popUpClassName, style: {top: "#{bottom}px", left: "#{right - 100}px"},
        if !isCtm
            p className: 'ProfileInfoPopUp-statement',
                strong 'Why do we need this information? '
                'Collecting sexual orientation, gender identity, race, and ethnicity data allows our care teams to provide high-quality, patient-centered care. We understand that certain demographic data may change over time, so we encourage you to keep this information updated to help us meet your healthcare needs.'
        if !hideNameUsed
            p className: 'ProfileInfoPopUp-statement',
                strong 'Name used: '
                "#{nameUsedText}"
        p className: 'ProfileInfoPopUp-statement',
            strong 'Pronouns: '
            "#{pronounsText}"
        p className: 'ProfileInfoPopUp-statement',
            strong 'Sex Assigned at Birth: '
            "#{sexAtBirthText}"
        p className: 'ProfileInfoPopUp-statement',
            strong 'Gender Identity: '
            'A person\'s internal sense of being a man, woman, and/or another gender (e.g., gender queer, gender fluid).'
        if showSexualOrientation
          p className: 'ProfileInfoPopUp-statement',
              strong 'Sexual orientation: '
              'Whom a person is physically, romantically, or sexually attracted to.'
        if hasRaceAndEthnicity
          p className: 'ProfileInfoPopUp-statement',
            strong 'Race: '
            'Refers to a group of people who share physical characteristics, such as skin color and facial features.'
        if hasRaceAndEthnicity
          p className: 'ProfileInfoPopUp-statement',
            strong 'Ethnicity: '
            'Refers to the group you share your social and cultural characteristics, backgrounds, or experiences with.'
    )


module.exports = observer(SOGIInfoPopUp)
