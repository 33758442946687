import Card from "./card";
import { fitnessResults, fitnessMessage, fitnessText } from "config";

class FitnessResults extends React.Component {
  renderResultsText({ current_age, non_exercise_crf }) {
    return fitnessResults.result(current_age, non_exercise_crf);
  }

  renderFitnessText({ fitness_age, crf }) {
    if (fitness_age < 20) {
      return fitnessText.less(crf);
    }
    return fitnessText.greater(fitness_age, crf);
  }

  renderResultMessage({ fitness_age, current_age }) {
    let score;

    if (fitness_age <= current_age) {
      score = "lessActualAge";
    } else if (fitness_age > current_age) {
      score = "greaterActualAge";
    } else {
      score = false;
    }
    return score ? fitnessMessage[score] : "";
  }

  render() {
    const { title, value } = this.props;

    return (
      <React.Fragment>
        <Card title={title} iconClass="fa-bicycle Hra-result--fitness--icon">
          <div className="Hra-body">
            {fitnessResults.intro}
            <br />
            <span className="Hra-result--fitness">
              * {this.renderResultsText(value)}
            </span>
            <span className="Hra-result--fitness">
              * {this.renderFitnessText(value)}
            </span>
            <span className="Hra-result--fitness">
              {this.renderResultMessage(value)}
            </span>
            <hr className="Hra-divider" />
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default FitnessResults;
