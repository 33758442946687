import withDirty from "./with-dirty";
import { first } from "lodash";

class Select extends React.Component {
  constructor(props) {
    super(props);
    this._mySelectRef = React.createRef();
    this.contentRef = React.createRef();
  }

  get selectRef() {
    return this.props.selectRef || this._mySelectRef;
  }

  componentDidMount() {
    this.updateText();
  }

  updateText() {
    let $select = this.selectRef.current;
    let $option = first($select.selectedOptions);
    let $content = this.contentRef.current;
    $content.textContent = $option ? $option.text : "";
  }

  render() {
    let {
      selectRef: _,
      className = "",
      onChange = () => {},
      grayOut = false,
      ...selectProps
    } = this.props;

    return (
      <div
        style={grayOut ? { color: "lightgrey" } : {}}
        className={`NewSelect ${className}`}
      >
        <select
          {...selectProps}
          style={grayOut ? { cursor: "default" } : {}}
          ref={this.selectRef}
          onChange={(evt) => {
            this.updateText();
            onChange(evt);
          }}
        />
        <div
          className="NewSelect-content"
          aria-hidden="true"
          ref={this.contentRef}
        />
      </div>
    );
  }
}

const DirtySelect = withDirty(Select, {
  type: "onFocus",
});

export default React.forwardRef((props, ref) => {
  return <DirtySelect {...props} selectRef={ref} />;
});
