{ authStore, checkinStore, synopsisStore } = require 'stores'
screenConfig    = require 'components/screen/config'
{checkinStates, defaultAdminRoute, defaultPatientRoute} = require 'config'
{capitalize} = require 'lib/utils'
newAuthStore = require 'stores/new-auth'

Exim = require('exim')
helpers = {}

_typeof = (obj) ->
  if obj and typeof Symbol != 'undefined' and obj.constructor == Symbol then 'symbol' else typeof obj

helpers.isActive = isActive = (name, pathname) ->
  pathname.indexOf(checkinStates[name]?.path) >= 0

helpers.getFilePath = getFilePath = (name) ->
  name = name.slice(1) if name[0] is '/'
  segments = name.split('-')
  filePath = undefined
  if segments.length > 1
    filePath = segments.map((name, i) ->
      if i > 0
        return name.charAt(0).toUpperCase() + name.slice(1)
      name
    ).join('/')
  else
    filePath = name + '/' + name.charAt(0).toUpperCase() + name.slice(1)
  filePath

helpers.onSignInEnter = ->
  if authStore.get('loggedIn')
    authStore.actions.signout().then ->
      window.location.reload()

helpers.onSignUpEnter = (nextState, replaceState) ->
  replaceState('/') if authStore.get('loggedIn')

helpers.onCalendarEnter = (nextState, replaceState) ->
  {user} = newAuthStore
  if !user.canReadCalendar
    replaceState('/')

helpers.onTravelerEnter = (nextState, replaceState) ->
  replaceState('/') if authStore.get('user')?.traveler

helpers.onAdminEnter = (opts) -> (nextState, replaceState) ->
  {isAdmin, allowProxying, pin} = opts
  isAdminRoute = isAdmin
  isAdmin = null

  user = authStore.get('user')
  userIsAdmin = user.admin
  userIsNotAdminOrProxying = if allowProxying
    not userIsAdmin and not user.proxying
  else
    not userIsAdmin

  if userIsAdmin and not isAdminRoute
    console.log 'mixins/adminRoute', 'Redirect to: defaultAdminRoute'
    replaceState defaultAdminRoute
    return
  else if isAdminRoute and userIsNotAdminOrProxying
    console.log 'mixins/adminRoute', 'Redirect to: defaultPatientRoute'
    replaceState defaultPatientRoute
    return
  return unless pin

  state = user.checkin_state ? 'lock'
  startRoute = checkinStates[state]?.path ? '/checkin/auth'

  if state isnt 'lock' and isActive('lock', nextState.location.pathname)
    console.log 'mixins/adminRoute', 'Changing state to lock'
    checkinStore.actions.changeState('lock')

  if state is 'list'
    console.log 'mixins/adminRoute', 'State is list'
    if isActive('welcome', nextState.location.pathname)
      console.log 'mixins/adminRoute', 'Active: welcome'
      return

    if isActive('checkin', nextState.location.pathname) or isActive('thanks', nextState.location.pathname)
      console.log 'mixins/adminRoute', 'Active: checkin or thanks'
      console.log 'mixins/adminRoute', 'Redirect to: startRoute', startRoute
      replaceState startRoute
  else if state is 'screen'
    console.log 'mixins/adminRoute', 'State is screen'
  else
    if not isActive(state, nextState.location.pathname) and
    not (state is 'checkin' and isActive('checkinStep', nextState.location.pathname))
      step = if startRoute is '/checkin/screen' then screenConfig.firstStep
      console.log 'mixins/adminRoute', 'Redirect to: startRoute', startRoute
      replaceState pathname: if step? then "#{startRoute}/#{step}" else startRoute

helpers.mount = mount = (name) ->
  unless name.includes('/')
    name = "#{name}/#{capitalize(name)}"
  el = require("components/#{name}")()
  el.type

helpers.mountEl = (name) ->
  el = React.createElement require("components/#{name}")
  el.type

helpers.match = match = (path, component, args, children) ->
  index = path.index if typeof path is 'object' and path.index
  if typeof children == 'undefined' and _typeof(args) == 'object' and (Array.isArray(args) or args?.type?.constructor is React.Component.constructor)
    children = args
  if typeof args == 'undefined' and (Array.isArray(component) or component?.type?.constructor is React.Component.constructor)
    children = component
    args = {}
    component = helpers.mount(getFilePath(path))
  else if typeof args == 'undefined' and (if typeof component == 'undefined' then 'undefined' else _typeof(component)) == 'object'
    args = component
    component = helpers.mount(getFilePath(path))
  else if (if typeof component == 'undefined' then 'undefined' else _typeof(component)) == 'object' and Array.isArray(args)
    children = args
    args = component
    component = helpers.mount(getFilePath(path))

  if (if typeof args == 'undefined' then 'undefined' else _typeof(args)) == 'object'
    {key, def, onEnter, onLeave, onChange} = args
    index ?= args.index

  if index
    key = 'index'
    Exim.Router['IndexRoute']({component, key, onEnter, onLeave, onChange}, children)
  else
    key ?= path
    Exim.Router['Route']({path, component, key, onEnter, onLeave, onChange}, children)

helpers.from = (from, arg) ->
  to = if typeof arg is 'string' then arg else arg.to
  Exim.Router['Redirect'] {from, to}

module.exports = helpers
