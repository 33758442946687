import { getRandomColor } from "lib/utils";
import tinycolor from "tinycolor2";

export const getRandomDarkerColor = (name) => {
  const lightColor = getRandomColor(name);

  const monochromaticColorsList = tinycolor(lightColor).monochromatic();

  // for accessibility purposes we need the color to pass the Contrast (Minimum) (AA) threshold
  const foundDarkerColor = monochromaticColorsList.find((hex) =>
    tinycolor.isReadable("#fff", hex, { level: "AA", size: "small" })
  );

  const darkerColor = !!foundDarkerColor
    ? foundDarkerColor.toHexString()
    : "#016395";

  return darkerColor;
};
