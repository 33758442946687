"use strict";
const { cx } = require("lib/utils");

const ASC = "ascending";
const DESC = "descending";

const resolvePath = (obj, path) => {
  return path
    .split(".")
    .filter((k) => k)
    .reduce((obj, key) => obj[key], obj);
};

const withSort = (Component, params) => {
  class Sort extends React.Component {
    static getDerivedStateFromProps({ sort }, state) {
      return {
        order: ASC,
        ...params,
        ...sort,
        ...state,
      };
    }

    constructor(props) {
      super(props);

      this.state = {};
      this._Sorter = (props) => {
        const changeSort = (by) => {
          if (by == this.state.by) {
            this.setState({
              order: this.state.order == DESC ? ASC : DESC,
            });
          } else {
            this.setState({ by });
          }
        };

        let { className, by } = props;
        let { order } = this.state;

        let isSorted = by == this.state.by;
        let sortClass = cx("Sorter", {
          sorted: isSorted,
          [order]: isSorted,
        });

        return (
          <th className={className} aria-sort={isSorted ? order : null}>
            <button
              className={sortClass}
              onClick={() => {
                changeSort(by);
              }}
            >
              {props.children}
            </button>
          </th>
        );
      };

      this._sortFn = (objA, objB) => {
        let { by, order } = this.state;

        let a = resolvePath(objA, by);
        let b = resolvePath(objB, by);

        let diff =
          typeof a == "string" && typeof b == "string"
            ? a.localeCompare(b)
            : a - b;

        return order == ASC ? diff : -diff;
      };
    }

    render() {
      let { sort: _, ...props } = this.props;
      let { data } = this.state;

      props[data] = [...props[data]].sort(this._sortFn);

      return <Component Sorter={this._Sorter} {...props} />;
    }
  }

  return Sort;
};

module.exports = withSort;
