module.exports = config = {api: {}}

config.isCordova = !!window.cordova
config.isIpad = /iPad/.test(navigator.platform)
config.iOS = /iPad|iPhone|iPod/.test(navigator.platform)
config.android = /(android)/i.test(navigator.userAgent)
config.touchDevice = 'ontouchstart' of window or 'onmsgesturechange' of window
config.isEdge = /Edge/.test(navigator.userAgent)
config.isMobileBrowser = config.isCordova or (config.touchDevice and /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent))

config.msie = navigator.userAgent.indexOf('MSIE ')
config.isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./)
config.isIE =  config.msie > 0 or config.isIE11
config.isIEunder11 =  config.msie > 0 and !config.isIE11
config.ieVersion = if config.isIE11 then 11 else if config.isIE then parseInt(navigator.userAgent.substring(config.msie + 5, navigator.userAgent.indexOf('.', config.msie))) else 0

config.production = config.isCordova or not /(local\.dev)/.test location.hostname

config.clients = []
knownClients =
  nearsite: ['go', 'nearsite-v2', 'nearsite', 'demo']
  apple: ['apple', 'apple-v2', 'apple-wellness', 'apple-wellnesscenter', 'austin', 'singapore', 'sacramento', 'scvx']
  cork: ['cork']
  magenta: ['heb', 'heb-v2', 'magenta']
  frost: ['frost']
  docker: ['docker', 'uat']
  applied: ['applied', 'amp']
  microsoft: ['microsoft']
  singapore: ['amk', 'inn']

config.nonUS = ['crk', 'amk', 'inn']

config.client = if (config.isCordova)
  '$client'
else
  first = window.location.hostname.split('.')[0]
  first = window.location.hostname.split('.')[1] if first is 'www'
  Object.keys(knownClients).find (key) ->
    first in knownClients[key]

# Also check initialize.coffee for production hostnames.
config.api.hostname = if config.isCordova
  '$api_hostname'
else
  "#{location.protocol}//#{location.host}/" # http://xo.local.dev/'

knownLocationsLinks = {
  magenta: 'https://magenta-health.com/locations/',
  nearsite: 'https://crossoverhealth.com/locations/',
  docker: 'https://crossoverhealth.com/locations/'
}
config.locationsLink = if config.client then knownLocationsLinks[config.client] else null
config.crkFeesLink = 'https://wellness.apple.com/ie/en/content/38#cost-amp-eligibility'

config.buildDate = '$build_date'

config.build =
  type: '$build_type'
  date: '$build_date'
  version: '$app_version'
  platform: '$app_platform'

config.api.root = 'v1/'
config.api.version = 1

config.loadStripe = true
config.stripeUrl = 'https://js.stripe.com/v2/'

config.buildStatusUrl = '$build_status_url'

config.crkPPSTypes = [
  13952
  13911
  13912
  11907
  11832
  13956
  13909
]

config.guestRoutes = ['home', 'signin', 'forgot', 'signup', 'reset', 'verify', 'activate', 'browsers', 'security', 'oidc-loading' ]

config.features =
  newApi: config.api.version is 2
  welcomeMessage: false
  apptsOnly: false
  appleDisablePayments: true
  xoCalRevokeCheckin: true
  showEHRSyncStatus: true

config.formats =
  date: 'M/D/YY'
  dob: 'MM/DD/YYYY'
  shortDob: 'M/D/YYYY'
  time: 'hh:mm A'
  latestTrend: 'M/D/YY'
  fullDate: 'MMMM D, YYYY'
  fullDateTime: 'MMMM D, YYYY, hh:mm A'
  fullDateWeekday: 'dddd, MMMM D, YYYY'
  checkinDateFormat: 'ddd, MMM D, YYYY [at] h:mm A'
  checkinShortDateFormat: 'M/D/YY h:mm a'
  scheduleTimeFormat: 'h:mm A'
  notesDateFormat: 'MM/DD, hh:mm A'
  shortDateFormat: 'MM/DD/YY, hh:mm A'
  corkDateFormat: 'DD/MM/YY hh.mm A'
  screeningDateFormat: 'MMMM D, YYYY [at] h:mm A'
  xopDateFormat: 'MMMM D, YYYY @ h:mm A'
  fullWithTimezone: 'YYYY-MM-DD hh:mm:ss Z'

  chart:
    year: '%b %Y'
    month: '%m/%d'
    week: '%m/%d'
    day: '%I%p'

config.shortWeekdays = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S']
config.firstStep = 'about'

config.defaultAvatar = 'images/image-placeholder.jpg'
config.avatarSize = {width: 164, height: 164}
config.defaultAdminRoute = '/landing'
config.defaultPatientRoute = '/landing'

config.metricTypes =
  #maintenance:
  # immunizations: [162,163,164,165,166,167]
  # maintenance: [104,112,101,103,107,100,114,108,106]
  # procedures: [158,159,160,161]
  Biometrics: [1,2,3,4,5,7,51,8,9,10,11,12,13]
  Laboratory: [14,15,16,30,31,25,17, 21,22,23,24,25,26,27,28,29,30,31,32,33,34, 35,39,78,36,37,38, 42,44,47,48,41,43,40,45,46,49,50]
  'Physical Medicine': [83,84,85,86,87,88,89,90]
  'Behavioral Health': [58,63,75,65,62,61,64,67,68,69,71,72]
  Fitness:   [51,52,53,54,57]
  # bmi: [74]

config.override_metrics =
  9:
    units: ''
    displayValue: (value) ->
      feet = +value / 12
      inches = Math.round(Math.round(12 * (feet - Math.floor(feet)) * 100) / 100)

      feet = Math.floor(feet)

      if inches is 12
        feet += 1
        inches = 0

      feet + '′' + inches + '″'
    axisLabel: (value) ->
      parseFloat(value.replace('′', ','))
  99:
    units: 'h'
    displayValue: (value) ->
      h = parseInt(value) / 60
      "#{Math.floor(h) + Math.round(60 * (h - Math.floor(h)) / 30) / 2}"

config.types =

    # "date"         => item["ENTEREDDT"          ], # "11/14/2012"
    # "bmi"          => item["BMI"                ], # "26.50"
    # "weight"       => item["WEIGHT"             ], # "185.00"
    # "waist"        => item["WAIST_CIRCUMFERENCE"], # "..."
    # "bp"           => item["BP"                 ], # "120/98"
    # "pulse"        => item["PULSE"              ], # "..."

    # "height"       => item["HEIGHT"             ], # "66.00"
    # "resp"         => item["RR"                 ], # "14"
    # "temp"         => item["TEMP"               ], # "98.60"

  health: [

    # Desired column 1
    # 'Blood Pressure'    #  1a
    # 'Heart Rate'        #  1b
    # 'BMI'               #  1c
    # 'Weight'            #  1d
    # 'Waist'             #  1e

    # Desired column 2
    # 'Cholesterol'       #  2a
    # 'HDL'               #  2b
    # 'LDL'               #  2c
    # 'Triglycerides'     #  2d
    # 'Glucose'           #  2e

    # For now...
    'Blood Pressure'
    'Cholesterol'
    'Heart Rate'
    'HDL'
    'Weight'
    'LDL'
    'Height'
    'Triglycerides'
    'Waist'
    'Glucose'
    'PHQ-9 Score'
    'GAD-7 Score'
    'Hemoglobin A1c'
  ]

  corkHealth: [
    'Sodium'
    'Potassium'
    'CO<sub>2</sub>'
    'Creatinine'
    'Alk Phos'
    'ALT'
    'AST'
    'Chloride'
    'Calcium'
    'BUN'
    'Total Bilibrubin'
    'Albumin'
    'Total Protein'
  ]

  lifestyle: [
    'Exercise per Week'   # 58 - Exercise per Week
    'Managing Health'     # 75 - Quality of Sleep
    'Happiness'           # 62 - Happiness
    'Stress Level'        # 61 - Daily Intake of Whole Grains
    'Whole Grains'        # 63 - Sleep per Night
    'Fruits & Veggies'    # 65 - Stress Level
    'Saturated Fats'      # 64 - Daily Intake of Saturated Fats
    'Caffeine'            # 67 - Fruit and Vegetable Servings per Day
    'Alcohol'             # 68 - Tobacco Use
    'Tobacco Use'         # 69 - Rate your ability to manage your health
    'Health Attitude'     #    - How do you feel about your health?
    'Health Goals'        #    - What are your health goals?
    'Quality of Sleep'    # 71 - Quality of Sleep
    'Problems with Sleep' # 72 - Problems with Sleep
    'Body Fat'            # 51
    'Curl Ups'            # 52
    'Push Ups'            # 53
    'VO<sub>2</sub> Max'  # 54
    'Sit and Reach'       # 57
    'Duration of Sleep'   # 99
  ]

# com.paulmillr.xo.premobile
config.HKMetrics = [

    'xo_name': 'Blood Pressure'
    'hk_name': 'HKQuantityTypeIdentifierBloodPressureSystolic'
    'unit'   : 'mmHg'
    'id'     : 1
  ,
    'xo_name': 'Blood Pressure'
    'hk_name': 'HKQuantityTypeIdentifierBloodPressureDiastolic'
    'unit'   : 'mmHg'
    'id'     : 1
  ,
    'xo_name': 'Cholesterol'
    'hk_name': 'HKQuantityTypeIdentifierDietaryCholesterol'
    'unit'   : 'mg'
    'id'     : 14
    'disabled':true
  ,
    'xo_name': 'Heart Rate'
    'hk_name': 'HKQuantityTypeIdentifierHeartRate'
    'unit'   : 'count/min'
    'id'     : 2
  ,
    'xo_name': 'HDL'
    'hk_name': null
    'unit'   : 'mg'
    'id'     : 15
  ,
    'xo_name': 'LDL'
    'hk_name': null
    'unit'   : 'mg'
    'id'     : 17
  ,
    'xo_name': 'Height'
    'hk_name': 'HKQuantityTypeIdentifierHeight'
    'unit'   : 'in'
    'id'     : 9
  ,
    'xo_name': 'Weight'
    'hk_name': 'HKQuantityTypeIdentifierBodyMass'
    'unit'   : 'lb'
    'id'     : 8
  ,
    'xo_name': 'Triglycerides'
    'hk_name': 'HKQuantityTypeIdentifierDietaryFatTotal'
    'unit'   : 'g'
    'id'     : 16
    'disabled':true
  ,
    'xo_name': 'Waist'
    'hk_name': null
    'unit'   : 'in'
    'id'     : 10
  ,
    'xo_name': 'Glucose'
    'hk_name': 'HKQuantityTypeIdentifierBloodGlucose'
    'unit'   : 'mg/dl'
    'id'     : 25
  ,
    'xo_name': null
    'hk_name': 'HKQuantityTypeIdentifierStepCount'
    'unit'   : 'count'
    'id'     : 145
  ,
    'xo_name': null
    'hk_name': 'HKQuantityTypeIdentifierActiveEnergyBurned'
    'unit'   : 'cal'
    'id'     : 150
  ,
    'xo_name': null
    'hk_name': 'HKQuantityTypeIdentifierBodyFatPercentage'
    'unit'   : 'count'
    'disabled':true
  ,
    'xo_name': null
    'hk_name': 'HKQuantityTypeIdentifierDietaryEnergyConsumed'
    'unit'   : 'cal'
    'id'     : 151
  ,
    'xo_name': null
    'hk_name': 'HKQuantityTypeIdentifierFlightsClimbed'
    'unit'   : 'count'
    'id'     : 146
  ,
    'xo_name': null
    'hk_name': 'HKQuantityTypeIdentifierDistanceWalkingRunning'
    'unit'   : 'mi'
  ,
    'xo_name': 'Duration of Sleep'
    'hk_name': 'HKCategoryTypeIdentifierSleepAnalysis'
    'unit'   : 'min'
    'id'     : 99
]

config.GFMetrics = [
    'xo_name'  : 'Heart Rate'
    'gf_name'  : 'HeartRate'
    'data_type': 'com.google.heart_rate.bpm'
    'unit'     : 'bpm'
    'id'       : 2
  ,
    'xo_name'  : 'Height'
    'gf_name'  : 'Height'
    'data_type': 'com.google.height'
    'unit'     : 'm'
    'id'       : 9
  ,
    'xo_name': 'Weight'
    'gf_name': 'Weight'
    'data_type': 'com.google.weight'
    'unit'     : 'kg'
    'id'       : 8
]

config.checkinConditions = [
  { name: 'Allergies (Hay Fever)'   , id: 'allergies'      }
  { name: 'Anemia'                  , id: 'anemia'         }
  { name: 'Anxiety'                 , id: 'anxiety'        }
  { name: 'Arthritis'               , id: 'arthritis'      }
  { name: 'Asthma'                  , id: 'asthma'         }
  { name: 'Back Pain'               , id: 'back-pain'      }
  { name: 'Cancer'                  , id: 'cancer'         }
  { name: 'Chronic Pain'            , id: 'chronic-pain'   }
  { name: 'Depression'              , id: 'depression'     }
  { name: 'Diabetes'                , id: 'diabetes'       }
  { name: 'Headaches'               , id: 'headaches'      }
  { name: 'Heart Disease'           , id: 'heart-disease'  }
  { name: 'Heart Murmur'            , id: 'heart-murmur'   }
  { name: 'High Blood Pressure'     , id: 'blood-pressure' }
  { name: 'High Cholesterol'        , id: 'cholesterol'    }
  { name: 'Kidney Disease'          , id: 'kidney-disease' }
  { name: 'Liver Disease'           , id: 'liver-disease'  }
  { name: 'Lung Disease'            , id: 'lung-disease'   }
  { name: 'Osteoporosis'            , id: 'osteoporosis'   }
  { name: 'Pre-Diabetes'            , id: 'pre-diabetes'   }
  { name: 'Seizures'                , id: 'seizures'       }
  { name: 'Skin Problems'           , id: 'skin-problems'  }
  { name: 'Sleep Apnea'             , id: 'sleep-apnea'    }
  { name: 'Stomach Pain / Heartburn', id: 'heartburn'      }
  { name: 'Stroke'                  , id: 'stroke'         }
  { name: 'Thyroid Disease'         , id: 'thyroid'        }
]

config.checkinSiblings = ['self', 'mother', 'father', 'sibling', 'grandmother', 'grandfather', 'other']

config.consents = [
  ['xo', 'Crossover Health Medical Group', 'TODO...', 'http://link-to-text']
  ['hc', 'Health Claims', 'TODO...', 'http://link-to-text']
  ['hf', 'Health Fitness', 'TODO...', 'http://link-to-text']
  ['dossia', 'Dossia', 'TODO...', 'http://link-to-text']
]

config.states = [
  'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC',
  'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
  'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO',
  'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP',
  'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN',
  'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
]

config.week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
config.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
config.monthsShort = 'jan feb mar apr may jun jul aug sep oct nov dec'.split ' '

config.checkinStates =
  lock: path: '/checkin/auth', name: 'pin'
  list: path: '/checkin', name: 'schedule'
  thanks: path: '/checkin/steps/thanks', name: 'ch-thanks'
  welcome: path: '/checkin/steps/welcome', name: 'ch-welcome'
  checkin: path: '/checkin/steps/about', name: 'ch-about'
  checkinStep: path: '/checkin/steps/about', name: 'ch-about'
  # screen: path: '/checkin/screen', name: 'screen-step'
  # screenStep: path: '/checkin/screen', name: 'screen-step'

config.welcomeMessages = {
  default: {
    id: 0
    received_at: new Date()
    date: new Date()
    from: {id: 0, name: 'Crossover Health'}
    unread: true
    attachments: 0
    subject: 'Welcome'
    body: 'Welcome to Crossover Health!\n\nSincerely yours, Crossover Team.'
  },
  nearsite: {
    id: 0
    received_at: new Date()
    date: new Date()
    from: {id: 0, name: 'The Crossover Health Team'}
    unread: true
    attachments: 0
    subject: 'Welcome - Next Steps'
    body: "
      Thanks for activating your Crossover Health account!\n\n

      You can start benefiting from Crossover's convenient medical services at the best prices in the area today:\n\n

      1. [Chose the Crossover services right for you](http://crossoverhealth.com/awesome-care/)\n\n

      2. [Make your first appointment](https://go.crossoverhealth.com/appointments/schedule)\n\n\n\n

      If you have any questions, send us a [message](/messages/new) or call us at (949) 891-0328\n\n

      Thanks!\n\n

      The Crossover Health Team\n
      Awesome Care. Close By.\n
    "
  }
}

config.welcomeMessage = if config.client and (msg = config.welcomeMessages[config.client]) then msg else config.welcomeMessages['default']

config.messageFlagsPlaceholder = {id: 0, name: 'flag', icon: 'flag', color: '#666'}

config.messageFlags = [
  {id: 'one', name: 'flag', icon: 'flag', color: '#D75452'}
  {id: 'two', name: 'task', icon: 'check-circle', color: '#EEAB57'}
  {id: 'three', name: 'important', icon: 'exclamation-triangle', color: '#5FB660'}
  {id: 'four', name: 'question', icon: 'question-circle', color: '#D584FC'}
  {id: 'five', name: 'reference', icon: 'book', color: '#60C0DC'}
]

config.mimeTypes = [
  'image/gif'
  'image/png'
  'image/jpeg'
  'image/svg+xml'
  'application/zip'
  'application/pdf'
  'application/msword'
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  'application/vnd.ms-excel'
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  'application/vnd.ms-powerpoint'
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  'application/x-iwork-keynote-sffkey'
  'application/x-iwork-pages-sffpages'
  'application/x-iwork-numbers-sffnumbers'
]

config.maxFileSize = 25 * 1024 * 1024

config.insuranceTypeMapping =
  insurance_1: 'ins1'
  insurance_2: 'ins2'
  insurance_3: 'ins3'

config.cardioResults = {
  low: 'Congratulations, based on this calculation your
    risk is considered relatively low.  Maintaining a healthy lifestyle
    is very important. AMP Wellness Centers are here with
    our primary care team and health coaches along with other
    services to help you optimize your health.'
  medium: 'You have at least borderline, if not intermediate
    risk for developing ASCVD.  Lifestyle changes will help lower
    this risk and you may also benefit from medication. Please
    discuss how you can lower your risk with your physician
    and/or the AMP Wellness Team.'
  high: 'This tool suggests you are at high risk for ASCVD –
    heart disease and stroke.  It is extremely important that
    you discuss this risk with your physician and/or AMP Wellness
    team so we can help you lower your risk for heart attack and stroke.
    This always includes emphasizing healthy lifestyle and you will likely
    benefit from medication as well.'
  no_score: 'This tool suggests you are at high risk for ASCVD or that you
    already have this condition.  It is extremely important that you discuss
    this risk with your physician and/or AMP Wellness team so we can help you
    lower your risk for heart attack and stroke.  This includes emphasizing healthy
    lifestyle and you will likely benefit from medication as well.'
  disclaimer: '*Certain groups are at higher cardiovascular risk regardless of
    these results.  If you already had a heart attack, stroke, have peripheral
    vascular disease, your LDL (bad cholesterol) is over 190, or you have
    diabetes it is important to review your cholesterol results with your
    physician as a part of your overall management. This is for informational
    use only and does not replace the advice you receive from your physician. The
    calculator is based on guidelines developed by the American Heart
    Association and American College of Cardiology'
  intro: 'This tool is to help understand your risk of a heart attack or stroke (Atherosclerotic
    Cardiovascular Disease [ASCVD]) in the next 10 years.  This tool is not intended for those who
    already have ASCVD (see below*). This calculator has its limitations and is less accurate for
    people under 40 or over 79 (particularly those under 25) years old and may
    underestimate risk for some ethnicities including South Asians. It is important
    to discuss your individual risk factors with your doctor as this calculator is for
    informational purposes only and should not be considered medical advice. Quitting
    smoking is one of the most important risk factors that will help lower your
    risk for heart attack and stroke.'
  age_under_25: 'Although you are currently too young for this tool to provide accurate
    results, you are not too young to focus on reducing your risk for heart attacks and
    strokes.  If you are a smoker, quitting is one of the most important improvements to
    lower your risk of death.  Other well know risk factors include high blood pressure,
    lack of physical activity, and being overweight.  It is important to visit your doctor
    regularly to monitor your health and keep your risk for heart attacks and strokes low.'
  history_of_heart_disease: 'This tool suggests you are at high risk for ASCVD (heart
    disease and stroke) or that you already have this condition.  It is extremely
    important that you discuss this risk with your physician and/or AMP Wellness
    team so we can help you lower your risk for heart attack and stroke. This includes
    emphasizing healthy lifestyle and you will likely benefit from medication as well.'
}

config.promisResults = {
  intro: 'This tool gauges your general sense of quality of life in terms of your mental and
     physical health.'
  message: 'These results reflect how your answers compare to the general U.S. population.
     It is based on tools developed for the National Institutes for Health for
     well-being research that can improve communication between patients and their
     health care providers. These factors along with your social well-being are helpful
     in predicting your future health.'
  result: {
    physical: {
      good: 'Your Global Physical Health Score is Good to Excellent:
        at least as good as the average score of the US population.  Lifestyle
        changes including improving your nutrition and excercise habits can help
        you to maintain or improve this score.  Teaming up with the AMP Wellness
        center and/or your physician can help you  set and achieve your lifestyle goals.'
      poor: 'Your Global Physical Health Score is Poor :  significantly lower than the average
        score of the US population.  If you are not already doing so, you are strongly encouraged
        to work with your physician.   Lifestyle changes including improving your nutrition
        and excercise habits can potentially help you to improve this score.
        Teaming up with your AMP Wellness center and/or physician can help you to set and achieve
        your lifestyle goals.  '
      fair: 'Your Global Physical Health Score is FAIR:  lower than the average score of
        the US population.  Lifestyle changes including improving your nutrition and
        excercise habits can help you improve this score.  Teaming up with your AMP Wellness
        center and/or physician can help you  set and achieve your lifestyle goals.'
    }

    mental: {
      good: 'Your Global Mental Health Score is Good to Excellent: at least as good as
        the average score of the US population.  Lifestyle changes including improving
        your nutrition, excercise, and sleeping habits can help you to maintain or improve
        this score. Teaming up with the AMP Wellness Center and/or physician can help
        you to set and achieve your lifestyle goals.  '
      fair: 'Your Global Mental Health Score is FAIR:  lower than the average score of the
        US population.  Lifestyle changes including improving your sleeping, nutrition, and
        excercise habits can help you improve this score.
        Teaming up with the AMP Wellness Center and/or physician can help you find the appropriate
        resources to help you improve your mental well being.'
      poor: 'Your Global Mental Health Score is Poor:
        significantly  lower than the average score of the US population.
        If you are feeling suicidal, it is extremely important that you seek help right away.
        The National Suicide Prevention Lifeline  can be reached at 1-800-273-8255.
        Teaming up with the AMP Wellness Center and/or physician is essential to help you find
        the appropriate resources to help you improve your mental well being.'
    }
  }

}

config.fitnessResults = {
  result: (age, non_exercise_crf) ->
    "You are #{age} years old and your expected VO2 max is #{non_exercise_crf}"
  intro: "This tool estimates your physical fitness, a vital factor for
    good health and a long life. The VO2 max reflects your body’s ability
    to transport and use oxygen while you are exercising, one of the most
    reliable measures of your overall cardiovascular fitness."
}

config.fitnessText = {
  less: (crf) ->
    "You have the fitness of someone under 20 years old and your estimated VO2 max is #{crf}"
  greater: (fitness_age, crf) -> "You have the fitness of an average #{fitness_age} year old
    and your estimated VO2 max is #{crf}"
}

config.fitnessMessage = {
  lessActualAge: 'Congratulations! This calculation suggests you have good
    fitness for your age/gender. Keep up the good work!'
  greaterActualAge: 'Room for improvement! Your fitness level is lower than
  is expected for your gender and age group. The good news is there is growing
  evidence that improving your fitness has been shown to improve your health
  while increasing your longevity. Now is a great time to improve your fitness;
  you are encouraged to work with your doctor to get started! '
}
moment?.updateLocale('en', {
  week: {dow: 0},
})

config.hideGenderFieldForClients = ['apple', 'cork']

config.outboundScreenersDescriptionMessage = [
  "Healthy living starts with understanding the whole picture of what impacts your health. How you live, work, play, and feel are important contributors to your overall health — as well as your personal environment.",
  "These personal health assessments allow your Crossover Care Team to check in with your health and ensure you are up to date on your recommended screenings. By working together, we are committed to keeping you as healthy as possible with an actionable and personalized review of your current health status.",
  "The following question sets will take about 5 to 10 minutes each to complete. The health indicators covered in these questions may change over time, so we will ask these questions periodically and track them here. Please note that your responses are a protected part of your care record and are never shared outside of your Care Team."
]

config.outstandingScreenerHeaders = [
  {
    title: 'Question Set',
    sort: true,
    key: 'screening_template_name',
    className: 'TableRow--screening',
  },
  {
    title: 'Status',
    key: 'status',
    className: 'TableRow--status'
  },
  {
    title: 'Date Assigned',
    sort: true,
    key: 'assigned_at',
    className: 'TableRow--date'
  },
  {
    title: 'Actions',
    key: 'action',
    isAction: true,
    className: 'TableRow--actions'
  }
]

config.completedScreenerHeaders = [
  {
    title: 'Question Set',
    sort: true,
    key: 'screening_template_name',
    className: 'TableRow--screening',
  },
  {
    title: 'Status',
    key: 'status',
    className: 'TableRow--status'
  },
  {
    title: 'Date Completed',
    sort: true,
    key: 'completed_at',
    className: 'TableRow--date'
  },
  {
    title: 'Actions',
    isAction: true,
    key: 'action',
    className: 'TableRow--actions'
  }
]

config.columnClasses = {
  assigned_at: 'TableColumn--date',
  completed_at: 'TableColumn--date'
  action: 'TableColumn--action'
}
