"use strict";
const { cx } = require("lib/utils");
const { withRouter } = require("react-router");
const ReactDOM = require("react-dom");

const ACTIVE_STEP_CLASS = "StepsNav-step--active";

const Step = ({ step, isActiveStep, newStyle }) => {
  let standardClassName = cx("StepsNav-step", {
    completed: step.isCompleted,
    disabled: step.isDisabled,
    new: newStyle,
  });
  const activeClassName = cx(ACTIVE_STEP_CLASS, {
    new: newStyle,
  });

  const stepClassName = `${standardClassName} ${
    isActiveStep ? activeClassName : ""
  }`;

  const ariaCurrent = isActiveStep ? "step" : "";

  return (
    <>
      <span aria-hidden key={step.to} className={stepClassName}>
        {step.content}
      </span>
      <li className="invisible" aria-current={ariaCurrent}>
        {step.content}
      </li>
    </>
  );
};

const Progress = ({ activeStepIndex, totalNumberOfSteps }) => {
  return (
    <span className="StepsNav-progress">
      <span>
        <span className="StepsNav-currentStep">{activeStepIndex + 1}</span> /{" "}
        {totalNumberOfSteps}
      </span>
    </span>
  );
};

// Note: After some refactoring, this component is now badly named, it it not a navigation element but
// a display element showing the user's progress through a series of steps. It is display only.
class StepsNav extends React.Component {
  // ensure the activeStep is visible when user moves between steps
  componentWillUpdate(prevProps, nextProps) {
    if (
      nextProps === null ||
      !prevProps.location.pathname !== nextProps.location.pathname
    ) {
      let nav = ReactDOM.findDOMNode(this);
      let active = nav.querySelector(`[class~=${ACTIVE_STEP_CLASS}]`);
      if (active) {
        active.scrollIntoView({
          block: "end",
        });
      }
    }
  }

  render() {
    let {
      modifier,
      steps,
      router,
      isMobile = false,
      mobileResponsive = false,
      showProgress = true,
      className = "",
      newStyle = false,
    } = this.props;
    let activeStepIndex = steps.findIndex((step) => router.isActive(step.to));
    let navClass = cx("StepsNav", modifier);

    if (mobileResponsive && isMobile) {
      steps = steps.filter((s) => s.isActive);
    }

    return (
      <div className={navClass}>
        {showProgress && (
          <Progress
            activeStepIndex={activeStepIndex}
            totalNumberOfSteps={steps.length}
          />
        )}
        <ol className={`StepsNav-steps ${className}`}>
          {steps.map((step, index) => (
            <Step
              key={step.to}
              step={step}
              isActiveStep={index === activeStepIndex}
              newStyle={newStyle}
            />
          ))}
        </ol>
      </div>
    );
  }
}

module.exports = withRouter(StepsNav);
