timelineStore = require 'stores/timeline'
authStore     = require 'stores/auth'

utils = require 'lib/utils'

Fields = require 'components/mixins/Fields'
FileAttach     = require 'components/mixins/FileAttach'
Orders = require './Orders'
{fa, div, h3, h5, span, label, button, img, input}  = Exim.DOM
{cx} = Exim.helpers

CreatePost = Exim.createView module.id,
  mixins: [
    authStore.connect('user')
    timelineStore.connect('postCreating')
    Fields
    FileAttach
  ]

  dataName: 'post'
  required: ['subject', 'body']

  getInitialState: ->
    post:
      subject: ''
      body: ''

  getInitialData: ->
    @state.post

  submit: ->
    {post} = @state
    post.documents = @state.files
    timelineStore.actions.create(post).then =>
      @setState post: {subject: '', body: ''}, files: []

  toggleExpanded: ->
    @props.toggleExpanded('new')

  render: ->
    {user} = @state
    { expanded } = @props

    avatar = if user.proxying
      utils.getAvatarUrl(user.admin_avatar_url)
    else
      utils.getAvatarUrl(user.avatar_url)
    name = if user.proxying
      user.admin_name
    else
      user.full_name

    div className: "timeline-post #{cx expanded: expanded is 'new'}",
      div className: 'timeline-post-author',
        div className: 'timeline-post-author-avatar',
          img src: avatar
        div className: 'timeline-post-author-name',
          name
      div className: 'timeline-post-content',
        div className: 'timeline-post-content-header',
          @field 'subject', className: 'timeline-post-content-header-input', useValue: true, placeholder: 'Subject', maxLength: 255
        div className: 'timeline-post-content-body', onDragOver: @onDragOver, onDragLeave: @onDragLeave, onDrop: @onDrop,
          @textarea 'body', useValue: true, placeholder: 'Type your message'
          if @state.dragging
            div className: 'timeline-post-content-body-hover-file',
              h3 'Drop Files To Attach'
        if @state.files.length
          div className: 'timeline-post-content-create-attachments',
            @state.files.map (item, i) =>
              div className: 'image-wrapper', key: "#{item.src} #{i}",
                img src: item.src, onClick: @preview(item),
                div className: 'image-wrapper-top',
                  div className: 'image-wrapper-top-close', onClick: @onFileRemove(item), '✕'
        div className: 'timeline-post-content-actions',
          div className: 'timeline-post-content-actions-wrapper',
            div className: 'timeline-post-content-actions-action',
              'Attach Files'
              input className: 'attach-file', type: 'file', onChange: @onFileAttach, multiple: true
            div className: "timeline-post-content-actions-action #{cx selected: expanded is 'new'}", onClick: @toggleExpanded,
              'Orders'
            div className: 'timeline-post-content-actions-action',
              'Add Data'
          div className: 'spacer'
          @button className: 'send', state: @state.postCreating, onSubmit: @submit, 'Post'
        div className: 'timeline-post-content-orders',
          Orders({post: @props.post, toggleExpanded: @props.toggleExpanded})

module.exports = CreatePost
