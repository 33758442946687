"use strict";

const withEscapeHandler = (Component, elementToFocusOn) => {
  class EscapeHandler extends React.Component {
    constructor(props) {
      super(props);

      this.handleEscape = (evt) => {
        if (evt.defaultPrevented) return;
        if (evt.key != "Escape") return;

        evt.preventDefault();
        this.props.close();
        if (elementToFocusOn) {
          document.getElementById(elementToFocusOn).focus()
        }
      };
    }

    componentDidMount() {
      document.addEventListener("keyup", this.handleEscape);
    }

    componentWillUnmount() {
      document.removeEventListener("keyup", this.handleEscape);
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return EscapeHandler;
};

module.exports = withEscapeHandler;
