utils       = require 'lib/utils'
{div, span, img, tch, fa, a, br, button} = Exim.DOM

PatientHeader = Exim.createView module.id,
  listen: [
    'auth/user'
    'auth/impersonatingUser', 'auth/impersonationTarget'
    'patient/patient'
  ]

  render: ->
    {user, patient, impersonatingUser, impersonationTarget} = @state
    target = impersonatingUser || impersonationTarget

    outside_id = target?.outside_id or patient?.patient_id

    className = 'PatientHeader'
    className += ' ' + @props.className if @props.className

    return null unless user.proxying

    return div { className },
      div className: 'PatientHeader-avatar',
        img className: 'DefaultAvatar', src: utils.getAvatarUrl(user.avatar_url)
      div className: 'PatientHeader-details',
        span className: 'PatientHeader-details-label','Viewing As:'
        span className: 'PatientHeader-name', "#{user.legal_and_preferred_name} (#{user.gender})"
        span className: 'PatientHeader-id',
          "#{user.dob} (#{utils.age(user.dob, 'MM/DD/YYYY')} y/o)"
          " | \##{outside_id}" if outside_id
      div className: 'PatientHeader-close',
        button className: 'PatientHeader-close-link', onClick: utils.closePatient,
          'Close'

module.exports = PatientHeader
