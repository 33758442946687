"use strict";
/* global Exim, React */
const { PropTypes } = React;
const { cx } = Exim.helpers;
const { withRouter } = require("react-router");

const { JSXShim } = require("lib/utils");
const { appStore, metricsStore } = require("stores");

const FontSizeToFit = JSXShim(
  require("components/app/FontSizeToFit"),
  "header"
);

/*
CONTENT HEADER TITLE
A header that goes at the top of a page's content.
Can render a dropdown with links for routes.
*/
class ContentHeaderTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.handleExpand = this.handleExpand.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }
  handleExpand() {
    this.setState((state) => ({ expanded: !state.expanded }));
  }
  handleMouseLeave() {
    if (this.state.expanded) this.setState({ expanded: false });
  }
  handleLinkClick(event, path) {
    event.stopPropagation();
    const { router } = this.props;
    router.push({ pathname: `checkin/screen/${path}` });
    if (this.state.expanded) this.setState({ expanded: false });
  }
  render() {
    const { name, links } = this.props;
    const { expanded } = this.state;
    return (
      <div
        aria-label="Screening"
        className={`ContentHeader-title ContentHeader-title--with-chevron ${cx({
          expanded: !!true,
        })}`}
        onClick={this.handleExpand}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="ContentHeader-text">
          <FontSizeToFit>{name}</FontSizeToFit>
        </div>
        <div className="ContentHeader-chevron">
          <i className={`fa icon fa-caret-${expanded ? "up" : "down"}`} />
        </div>
        {expanded && (
          <div className="ContentHeader-dropdown">
            {links.map((link, i) => (
              <div
                key={link.path}
                value={link.path}
                tabIndex="0"
                role="button"
                aria-label={link.name}
                className="ContentHeader-dropdownLink"
                onClick={(e) => this.handleLinkClick(e, link.path)}
              >
                {link.name}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

ContentHeaderTitle.propTypes = {
  router: React.PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
};
ContentHeaderTitle.defaultProps = {
  name: "...",
  links: [{ name: "None Available", path: "#" }],
};

module.exports = withRouter(ContentHeaderTitle);
