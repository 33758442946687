{div, table, tbody, thead, tr, td, th} = Exim.DOM

PaymentOwedModal = Exim.createView module.id,
  getInitialState: ->
    unpaidCharges: @props.unpaidCharges or []

  componentDidMount: ->
    document.addEventListener 'click', @props.close

  componentWillUnmount: ->
    document.removeEventListener 'click', @props.close

  onModalClick: (e) ->
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

  render: ->
    div onClick: @onModalClick, className: 'AppointmentItem-owedModal',
      table className: 'NewTable',
            thead {},
              tr {},
                th style: {width: '15%'}, 'Owed'
                th style: {width: '25%'}, 'Item'
                th style: {width: '35%'}, 'Description'
                th style: {width: '25%'}, 'Service Date'
            tbody {},
              @state.unpaidCharges?.map (charge) ->
                tr key: charge.id,
                  td "$#{(charge.amount/100).toFixed(2)}"
                  td charge.item
                  td charge.description
                  td moment(charge.charge_date).format('ll')

module.exports = PaymentOwedModal
