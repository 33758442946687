{appStore} = require 'stores'
{authStore} = require 'stores'
Validate   = require 'components/mixins/Validate'
{client} = require 'config'

{cx} = Exim.helpers
{div, form, label, span, tch, fa, h3, p, strong} = Exim.DOM

Identity = Exim.createView module.id,
  mixins: [Validate]
  listen: [
    'auth/xopEligible',
    'auth/sendingXoEligibilityCheck'
  ]

  getInitialState: ->
    Object.assign({selectFocused: null}, @props.state)
    keys: null

  componentDidMount: ->
    # need to wait 2 seconds before setting focus to allow screen reader to finish reading 'X step loaded' annoucement for a11y purposes
    setTimeout(() -> 
      firstElement = document.getElementById('labeled-input-first_name')
      firstElement?.focus()
    , 2000)

  dobFields: ->
    focusCallback = @focusSelect

    [
      div key: 'dob-label', className: @errClass('dob_m', 'dob_d', 'dob_y'),
        label className: 'dob-label', htmlFor: 'dob_m', 'Date of Birth *', span className: 'sr-only', '(Required)'
      div key: 'dob-fields', className: 'SignUpIdentity--dob',
        @unlabeledSelect 'dob_m', 'Month', @months, {html: {classes: "dob_m dob-wrap-dob_m SimpleSelect SimpleSelect--angle SimpleSelect--auth #{cx 'is-active': @state.selectFocused is 'dob_m'}", defaultValue: @state.dob_m}, onFocus: (-> focusCallback('dob_m')), onBlur: @blurSelect }
        @unlabeledSelect 'dob_d', 'Day',   @days,   {html: {classes: "dob_d dob-wrap-dob_d SimpleSelect SimpleSelect--angle SimpleSelect--auth #{cx 'is-active': @state.selectFocused is 'dob_d'}", defaultValue: @state.dob_d}, onFocus: (-> focusCallback('dob_d')), onBlur: @blurSelect }
        @unlabeledSelect 'dob_y', 'Year',  @years,  {html: {classes: "dob_y dob-wrap-dob_y SimpleSelect SimpleSelect--angle SimpleSelect--auth #{cx 'is-active': @state.selectFocused is 'dob_y'}", defaultValue: @state.dob_y}, onFocus: (-> focusCallback('dob_y')), onBlur: @blurSelect }
      ('dob_m' in @state.errors) && div className: 'ErrorHelperText', 'aria-live': 'assertive', 'Date of birth (month) is a required field'
      ('dob_d' in @state.errors) && div className: 'ErrorHelperText', 'aria-live': 'assertive', 'Date of birth (day) is a required field'
      ('dob_y' in @state.errors) && div className: 'ErrorHelperText', 'aria-live': 'assertive', 'Date of birth (year) is a required field'
    ]

  showAgreement: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()
    body = require('components/modals/Agreement')
    modal = {body}

    appStore.actions.showModal(modal)

  next: (evt) ->
    evt.preventDefault()
    
    document.querySelector('.hidden').innerHTML = "Interests step loading"

    focusCallback = @focusSelect

    {dob_m, dob_d, dob_y} = @state
    lastName = @state.last_name
    employeeId = @state.employee_id
    ssn = @state.ssn_last4
    dob = "#{dob_y}-#{dob_m}-#{dob_d}"

    BASE_KEYS = ['first_name', 'last_name', 'dob']

    additional_keys = switch @props.signUpType
      when "employee"
        ['employee_id', 'relationship']
      when "eid"
        ['employee_id']
      when "ssn"
        ['ssn_last4']
      else
        []

    keys = BASE_KEYS.concat(additional_keys)

    for key in keys.concat(['dob_y', 'dob_m', 'dob_d'])
      @removeErrors key
      @validate key

    @setState keys: keys

    if 'first_name' in @state.errors
      document.getElementById('labeled-input-first_name')?.focus()
    else if 'last_name' in @state.errors
      document.getElementById('labeled-input-last_name')?.focus()
    else if 'dob_m' in @state.errors
      focusCallback('dob_m')
      document.getElementById('unlabeled-select-dob_m')?.focus()
    else if 'dob_d' in @state.errors
      focusCallback('dob_d')
      document.getElementById('unlabeled-select-dob_d')?.focus()
    else if 'dob_y' in @state.errors
      focusCallback('dob_y')
      document.getElementById('unlabeled-select-dob_y')?.focus()

    if @state.errors?.length
      @forceUpdate()
    else
      authStore.actions.xopEligibilityCheck({dob, lastName, employeeId, ssn}).then(() => @proceedToInterests(@state.keys))

  proceedToInterests: (keys) ->
    if !@state.sendingXoEligibilityCheck and !@state.xopEligible
      @props.next(keys.reduce(((a,e) => a[e] = @state[e]; a), {}))

  focusSelect: (field) ->
    @setState {selectFocused: field}

  blurSelect: ->
    @setState {selectFocused: null}

  render: ->
    focusCallback = @focusSelect
    employeeLabel = if (client == 'applied') then 'Employee ID (use numeric portion only)' else 'Employee ID'

    if @state.xopEligible
      div className: 'SignUpIdentity--xop-eligible',
        div className: 'SignUpIdentity--xop-eligible-msg',
          p className: 'SignUpIdentity--xop-eligible-msg-first', "Hi there!"
          p "You have access to our new virtual care service."
          p "Please Sign Up at our new site below to access these services from Crossover providers:"
          div className: '',
            span "Click"
            strong " Sign Up! "
            span " at "
            tch className: 'SignUpIdentity--xop-eligible-link', tagName: 'a', href: 'https://care.crossoverhealth.com',
              span 'https://care.crossoverhealth.com'
          p "Thank you!"
        tch tagName: 'a', href: '#', handler: @props.leave, className: 'Auth--informational-link',
          fa 'times-circle', 'Button-icon'
          span className: 'Button-text', 'Leave Activation'
    else
      form className: 'SignUpIdentity',
        div className: 'SignUpIdentity--content',
          div className: 'SignUpIdentity--panes',
            div className: 'SignUpIdentity--pane',
              @labeledInput 'first_name', 'First Name *', requiredField: true, error: 'First name is a required field'
              @labeledInput 'last_name', 'Last Name *', requiredField: true, error: 'Last name is a required field'
            div className: 'SignUpIdentity--pane',
              @dobFields()
              if @props.signUpType is "ssn"
                @labeledInput 'ssn_last4', 'SSN (Last 4)', type: 'number'

          if @props.signUpType in ['employee', 'eid']
            div className: 'SignUpIdentity--employee-details',
              label className: 'SignUpIdentity--employee-details-label', 'Employee Information'
              div className: 'SignUpIdentity--panes',
                div className: 'SignUpIdentity--pane',
                  @labeledInput 'employee_id', employeeLabel, type: 'number'
                div className: 'SignUpIdentity--pane',
                  if @props.signUpType is 'employee'
                    div className: @errClass('relationship'),
                      label htmlFor: 'relationship', 'Relationship'
                      @unlabeledSelect 'relationship', 'Select One', ['Employee', 'Spouse', 'Domestic Partner', 'Child'], html: { classes: "relationship eid-wrap-relationship SimpleSelect SimpleSelect--angle SimpleSelect--auth #{cx 'is-active': @state.selectFocused is 'relationship'}", onFocus: (-> focusCallback('relationship')), onBlur: @blurSelect }

        div className: 'SignUp--actions',
          tch tagName: 'a', href: '#', handler: @props.leave, className: 'Auth--informational-link underline',
            fa 'times-circle', 'Button-icon'
            span className: 'Button-text', 'Leave Activation'
          tch tagName: 'a', href: '#', className: 'Button Button--action', handler: @next, 'Continue'

module.exports = Identity
