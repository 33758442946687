{isCordova} = require 'config'

enableAllowScrolling = false
SCROLL_DELAY = 300
ALLOW_DELAY = 300

InnerScroll =
  preventParentScrolling: (evt) ->
    width = document.body.clientWidth
    return if width < 768
    targ = evt.currentTarget
    preventAction = targ.scrollTop < 1 and @lastScroll > targ.scrollTop or (targ.clientHeight + targ.scrollTop) is targ.scrollHeight and @lastScroll < targ.scrollTop

    @prevent(evt, 'scroll', preventAction)
    @lastScroll = targ.scrollTop

  preventParentWheeling: (evt) ->
    width = document.body.clientWidth
    return if isCordova or width < 768
    targ = evt.currentTarget
    preventAction = (targ.scrollTop < 1 and evt.deltaY < 0 or (targ.clientHeight + targ.scrollTop) is targ.scrollHeight and evt.deltaY > 0) and !@state.allowScrolling
    @prevent(evt, 'wheel', preventAction)

  prevent: (evt, type, preventAction) ->
    targ = evt.currentTarget
    return if targ.scrollHeight is targ.clientHeight

    @setState scrolling: true
    clearTimeout(@["#{type}Tm"])
    if preventAction
      evt.preventDefault()
      if enableAllowScrolling
        fn = => @setState allowScrolling: true
        setTimeout fn, ALLOW_DELAY
    fn = => @setState scrolling: false, allowScrolling: false
    @["#{type}Tm"] = setTimeout(fn, SCROLL_DELAY)

module.exports = InnerScroll
