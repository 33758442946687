'use strict'
Widget       = require 'components/trends/Widget'
Allergens    = require 'components/trends/items/Allergens'
Medications  = require 'components/trends/items/Medications'
Step         = require 'components/mixins/Step'

{cx} = Exim.helpers
{main, section, div, label, h1, h2, span, tch, fa} = Exim.DOM

Meds = Exim.createView module.id,
  name: 'meds'
  mixins: [Step]

  toggleChanges: (type, value) ->
    {stepData} = @state
    stepData["has_#{type}_changes"] = value
    @setState {stepData}

  checkForValue: (type) -> (evt) =>
    value = evt.currentTarget.value
    @toggleChanges(type, !!value)

  expandMeds: ->
    @setState medsExpanded: !@state.medsExpanded

  medicationChangesLabel: -> 
    return 'Please write any changes in your medications.'

  allergyChangesLabel: ->
    return 'Please write any changes in your allergies.'

  render: ->
    {medsExpanded} = @state
    {medications, allergies} = @state.checkin
    medications ?= []
    allergies ?= []

    return div className: 'CheckinForm',
      h2 className: 'SectionHeading', 'Your medications'
      div className: 'ContentBlock ContentBlock--noTopMargin',
        Widget
          className: 'TableWidget'
          name: 'Medications'
          Item: Medications
          items: medications
          splitColumns: true
          ifEmpty: 'There are currently no medications on file.'
          type: 'bordered'
          ignoreScroll: true
          fields:
            name: 'Medication'
            dose_instructions: 'Instructions'
          expanded: false
          fallbackLabel: 'Not specified'
          rowHeight: 100

        div className: 'CheckinForm-row CheckinForm-row--marginTop CheckinForm-row--no-margin',
          div className: 'CheckinForm-notes full',
            div className: 'CheckinForm-notesTitle',
              @medicationChangesLabel()
            div className: "CollapsibleText #{cx 'CollapsibleText--expanded': medsExpanded}",
              div className: "CollapsibleText-icon", fa 'exclamation-circle'
              div className: "CollapsibleTextWrapper",
                div className: "CollapsibleTextGroup",
                  div className: "CollapsibleText-short",
                    "It is important for staff to know about changes to your medication use."
                  div className: "CollapsibleText-full",
                    "It is important for staff to know about changes to your medication use. Please note that updates you make here will not be reflected in your record until the medical team has verified the information, so if this is not a medical visit, these changes will not be saved in your portal account."
                tch tagName: 'a', href: '#', className: "CollapsibleText-button", handler: @expandMeds, 'aria-label': "Read #{if medsExpanded then 'less' else 'more'} about medication use",
                  if medsExpanded
                    span null,
                      'Read Less'
                      fa 'angle-up'
                  else
                    span null,
                      'Read More'
                      fa 'angle-down'

        div className: 'dropdown expanded',
          @textarea 'medication_change_info', useValue: true, className: 'Input Input--expand TextArea--xxSmall', onBlur: @checkForValue('medication'), description: @medicationChangesLabel()

      h2 className: 'SectionHeading', 'Your allergies'
      div className: 'ContentBlock',
        Widget
          className: 'TableWidget'
          name: 'Allergies'
          Item: Allergens
          items: allergies
          splitColumns: true
          ifEmpty: 'There are currently no allergies on file.'
          type: 'bordered'
          ignoreScroll: true
          fields:
            name: 'Allergy'
            note: 'Reaction'
          expanded: false

        div className: 'CheckinForm-row CheckinForm-row--marginTop CheckinForm-row--no-margin',
          div className: 'CheckinForm-notes full',
            div className: 'CheckinForm-notesTitle',
              @allergyChangesLabel()

        div className: 'dropdown expanded',
          @textarea 'allergies_change_info', useValue: true, className: 'Input Input--expand TextArea--xxSmall', onBlur: @checkForValue('allergies'), description: @allergyChangesLabel()

      this.renderCheckInFooter()

module.exports = Meds
