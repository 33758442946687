{div, h2, h3, h4, p, b, ul, ol, li, address, style, br, a} = Exim.DOM

HealthConsent = Exim.createView module.id,
  render: ->
    div className: 'ConsentModal',
      style null,
        """
          .ConsentModal li {
            margin: 25px 0;
          }

          .ConsentModal address {
            font-style: normal;
            padding-left: 40px;
          }
        """

      div className: 'ContentInner',
        h2 'Crossover Health Medical Group'
        div className: 'ContentBlock',
          h3 'New Patient Registration'
          p 'Please read this Agreement carefully: It is a legally binding contract. By accepting it, you are electronically signing and agreeing to be bound by this New Patient Registration and each of the demarcated documents contained herein including: the Notice of Privacy Practices, General Consent and Patient Rights and Responsibilities. If you do not wish to be bound by this Agreement, you may not receive any services provided by Crossover Health Medical Group.'
          h3 'Notice Of Privacy Practices (Effective Date: October 2018)'
          p 'THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.'
          p 'As required by the privacy regulations created as a result of the Health Insurance Portability and Accountability Act of 1996 (HIPAA):'
          h4 'A. Our commitment to your privacy:'
          p 'Our practice is dedicated to maintaining the privacy of your individually identifiable health information (also called protected health information, or PHI). In conducting our business, we will receive information and create records regarding you and the treatment and services we provide to you. We are required by law to maintain the confidentiality of health information that identifies you. We also are required by law to provide you with this notice of our legal duties and the privacy practices that we maintain in our practice concerning your PHI. By federal and state law, we must follow the terms of the Notice of Privacy Practices that we have in effect at the time.'
          p 'We realize that these laws are complicated, but we must provide you with the following important information:'
          ul null,
            li 'How we may use and disclose your PHI'
            li 'Your privacy rights in your PHI'
            li 'Our obligations concerning the use and disclosure of your PHI'
          p 'The terms of this notice apply to all records containing your PHI that are created or retained by our practice. We reserve the right to revise or amend this Notice of Privacy Practices (the "Notice"). Any revision or amendment to the Notice will be effective for all of your records that our practice has created or maintained in the past, and for any of your records that we may create or maintain in the future. Our practice will post a copy of our current Notice in our clinics in a visible location at all times and on our website, and you may request a copy of our most current Notice at any time. We are required to abide by the terms of the notice currently in effect. A revised Notice may be obtained by forwarding a written request to Crossover Health Medical Group, 101 W. Avenida Vista Hermosa Ste. 120, San Clemente, CA 92672.'
          h4 'B. Your personal information:'
          p 'We keep records of the medical care we provide you, and we may receive similar records from others. We use this information so that we, or other health care providers, can render quality medical care, obtain payment for services and enable us to meet our professional and legal responsibilities to operate our medical practice. We may store this information in a chart and in our computers. This information makes up your medical record. The medical record is our property; however, this notice explains how we use information about you and when we are allowed to share that information with others.'
          h4 'C. Our privacy practices:'
          p 'We have a HIPAA and Health Information Technology for Economic and Clinical Health ("HITECH") Act Policy in place to help ensure your PHI is protected. Crossover Health Medical Group not only uses traditional methods to deliver care but also cutting edge technology to help deliver quality care to our patients. It is our policy to maintain reasonable and feasible physical, electronic and process safeguards to restrict unauthorized access to and protect the availability and integrity of your health information. Our protective measures may include secured office facilities, locked file cabinets, managed computer network systems and password protected accounts. Access to health information is only granted on a "need-to-know" basis. Once the need is established the access is limited to the minimum necessary information to accomplish the intended purpose. Our staff are required to comply with the policies and procedures designed to protect the confidentiality of your health information. Any staff member who violates our privacy policy is subject to disciplinary action.'
          h4 'D. If you have questions about this Notice, please contact:'
          address null,
            '101 W. Avenida Vista Hermosa Ste. 120'
            br null
            'San Clemente, CA 92672'
          h4 'E. We may use and disclose your PHI in the following ways:'
          p 'The following categories describe the different ways in which we may use and disclose your PHI.'
          ol null,
            li null,
              'Treatment. Our practice may use your PHI to treat you. For example, we may ask you to have laboratory tests (such as blood or urine tests), and we may use the results to help us reach a diagnosis. We might use your PHI in order to write a prescription for you, or we might disclose your PHI to a pharmacy when we order a prescription for you. Many of the people who work for our practice - including, but not limited to, our doctors and nurses - may use or disclose your PHI in order to treat you or to assist others in your treatment. Additionally, we may also receive and disclose your PHI to other health care providers for purposes related to your treatment.'
            li null,
              'Payment. Our practice may use and disclose your PHI in order to bill and collect payment for the services and items you may receive from us. In addition, and by way of example of disclosures for payment purposes, we may disclose your PHI to other health care providers and entities to assist in their billing and collection efforts.'
            li null,
              'Health care operations. Our practice may use and disclose your PHI to operate our business. As examples of the ways in which we may use and disclose your information for our operations, our practice may use your PHI to evaluate the quality of care you received from us, or to conduct cost-management and business planning activities for our practice. We may disclose your PHI to other health care providers and entities to assist in their health care operations.'
            li null,
              'Appointment reminders. Our practice may use and disclose your PHI to contact you and remind you of an appointment.'
            li null,
              'Treatment options. Our practice may use and disclose your PHI to inform you of potential treatment alternatives or other health-related benefits and services that may be of interest to you.'
            li null,
              'Health-related benefits and services. Our practice may use and disclose your PHI to inform you of health-related benefits or services that may be of interest to you.'
            li null,
              'Release of information to family/friends. Our practice may release your PHI to a friend or family member that is involved in your care, or who assists in taking care of you. However, any such disclosure will be subject to legal requirements and our HIPAA and HITECH Policy.'
            li null,
              'Disclosures required by law. Our practice will use and disclose your PHI when we are required to do so by federal, state or local law.'
          h4 'F. Use and disclosure of your PHI in certain special circumstances:'
          p 'The following categories describe unique scenarios in which we may use or disclose your identifiable health information:'
          ol null,
            li null,
              'Public health risks. Our practice may disclose your PHI to public health authorities that are authorized by law to collect information for the purpose of:'
              ul null,
                li 'Maintaining vital records, such as births and deaths;'
                li 'Reporting child abuse or neglect;'
                li 'Preventing or controlling disease, injury or disability;'
                li 'Notifying a person regarding potential exposure to a communicable disease;'
                li 'Notifying a person regarding a potential risk for spreading or contracting a disease or condition;'
                li 'Reporting reactions to drugs or problems with products or devices;'
                li 'Notifying individuals if a product or device they may be using has been recalled;'
                li 'Notifying appropriate government agency(ies) and authority(ies) regarding the potential abuse or neglect of an adult patient (including domestic violence); however, we will only disclose this information if the patient agrees or we are required or authorized by law to disclose this information; or'
                li 'Notifying your employer under limited circumstances related primarily to workplace injury or illness or medical surveillance.'
            li null,
              'Health oversight activities. Our practice may disclose your PHI to a health oversight agency for activities authorized by law. Oversight activities can include, for example, investigations, inspections, audits, surveys, licensure and disciplinary actions; civil, administrative and criminal procedures or actions; or other activities necessary for the government to monitor government programs, compliance with civil rights laws and the health care system in general.'
            li null,
              'Lawsuits and similar proceedings. Our practice may use and disclose your PHI in response to a court or administrative order, if you are involved in a lawsuit or similar proceeding. We also may disclose your PHI in response to a discovery request, subpoena or other lawful process by another party involved in the dispute, but only if we have made an effort to inform you of the request or to obtain an order protecting the information the party has requested.'
            li null,
              'Law enforcement. We may release PHI if asked to do so by a law enforcement official:'
              ul null,
                li "Regarding a crime victim in certain situations, if we are unable to obtain the person's agreement;"
                li 'Concerning a death we believe has resulted from criminal conduct;'
                li 'Regarding criminal conduct at our offices;'
                li 'In response to a warrant, summons, court order, subpoena or similar legal process;'
                li 'To identify/locate a suspect, material witness, fugitive or missing person; or'
                li 'In an emergency, to report a crime (including the location or victim(s) of the crime, or the description, identity or location of the perpetrator).'
            li null,
              'Deceased patients. Our practice may release PHI to a medical examiner or coroner to identify a deceased individual or to identify the cause of death. If necessary, we also may release information in order for funeral directors to perform their jobs.'
            li null,
              'Organ and tissue donation. Our practice may release your PHI to organizations that handle organ, eye or tissue procurement or transplantation, including organ donation banks, as necessary to facilitate organ or tissue donation and transplantation if you are an organ donor.'
            li null,
              'Research. Our practice may use and disclose your PHI for research purposes in certain limited circumstances. We will obtain your written authorization to use your PHI for research purposes except when an Internal Review Board or Privacy Board has determined that the waiver of your authorization satisfies all of the following conditions:'
              ul null,
                li 'The use or disclosure involves no more than a minimal risk to your privacy based on the following: (a) an adequate plan to protect the identifiers from improper use and disclosure; (b) an adequate plan to destroy the identifiers at the earliest opportunity consistent with the research (unless there is a health or research justification for retaining the identifiers or such retention is otherwise required by law); and (c) adequate written assurances that the PHI will not be re-used or disclosed to any other person or entity (except as required by law) for authorized oversight of the research study, or for other research for which the use or disclosure would otherwise be permitted;'
                li 'The research could not practicably be conducted without the waiver; and'
                li 'The research could not practicably be conducted without access to and use of the PHI.'
            li null,
              'Serious threats to health or safety. Our practice may use and disclose your PHI when necessary to reduce or prevent a serious threat to your health and safety or the health and safety of another individual or the public. Under these circumstances, we will only make disclosures to a person or organization able to help prevent the threat.'
            li null,
              'Military. Our practice may disclose your PHI if you are a member of U.S. or foreign military forces (including veterans) and if required by the appropriate authorities.'
            li null,
              'National security. Our practice may disclose your PHI to federal officials for intelligence and national security activities authorized by law. We also may disclose your PHI to federal and national security activities authorized by law. We also may disclose your PHI to federal officials in order to protect the president, other officials or foreign heads of state, or to conduct investigations.'
            li null,
              'Inmates. Our practice may disclose your PHI to correctional institutions or law enforcement officials if you are an inmate or under the custody of a law enforcement official. Disclosure for these purposes would be necessary: (a) for the institution to provide health care services to you, (b) for the safety and security of the institution, and/or (c) to protect your health and safety or the health and safety of other individuals.'
            li null,
              "Workers' compensation. Our practice may release your PHI for workers' compensation and similar programs."
            li null,
              'Change of Ownership. In the event that our practice is sold or merged with another organization, your medical record will become the property of the new owner, although you will maintain the right to request that copies of your health information be transferred to another physician or medical group.'
            li null,
              'De-Identified Data. We may use or share your PHI once it has been "de-identified." PHI is considered de-identified when it has been processed in such a way that it can no longer personally identify you.'
            li null,
              'Limited Data Sets. We may also use a "limited data set" that does not contain any information that can directly identify you. This limited data set may only be used for the purposes of research, public health matters or health care operations. For example, a limited data set may include your city, county and zip code, but not your name or street address.'
          h4 'G. Receiving PHI from providers, insurance entities and their business associates:'
          p 'We want to make you aware that, just as Crossover Health Medical Group uses and discloses certain PHI in your treatment, our operations and management and certain payment practices, Crossover Health Medical Group receives PHI from other healthcare entities and their business associates including but not limited to: medical files, charts, laboratory testing results, imagining results, and insurance claims data. PHI that is received and maintained by Crossover Health Medical Group from outside entities is subject to the protections of relevant law and our HIPAA and HITECH policy.'
          h4 'H. Your written permission:'
          p "Except as described in this Notice, or as otherwise permitted by law, we must obtain your written permission - called an authorization - prior to using or sharing health information that identifies you as an individual. If you provide an authorization and then change your mind, you may revoke your authorization in writing at any time. Once an authorization has been revoked, we will no longer use or share your health information as outlined in the authorization form; however you should be aware that we won't be able to retract a use or disclosure that was previously made in good faith based on what was then a valid authorization from you."
          p 'Except as specified above under the applicable state law of the practice location, we may not share your health information with your employer or benefit plan unless you provide us an authorization to do so.'
          h4 'I. Other Restrictions:'
          p 'Under the applicable state law of the practice location, there may be additional laws regarding the use and disclosure of health information related to HIV status, communicable diseases, reproductive health, genetic test results, substance abuse, mental health and mental retardation. Generally, we will be bound by whatever law is more stringent and provides more protection for your privacy.'
          h4 'J. Your rights regarding your PHI:'
          p 'You have the following rights regarding the PHI that we maintain about you:'

          ol null,
            li null,
              'Confidential communications. You have the right to request that our practice communicate with you about your health and related issues in a particular manner or at a certain location. For instance, you may ask that we contact you at home, rather than work. In order to request a type of confidential communication, you must make a written request to Crossover Health Medical Group, 101 W. Avenida Vista Hermosa Ste. 120, San Clemente, CA 92672 and inform us of the requested method of contact, or the location where you wish to be contacted. Our practice will accommodate reasonable requests. You do not need to give a reason for your request.'
            li null,
              'Requesting restrictions. You have the right to request a restriction in our use or disclosure of your PHI for treatment, payment or health care operations. Additionally, you have the right to request that we restrict our disclosure of your PHI to only certain individuals involved in your care or the payment for your care, such as family members and friends. We are not required to agree to your request; however, if we do agree, we are bound by our agreement except when otherwise required by law, in emergencies or when the information is necessary to treat you. In order to request a restriction in our use or disclosure of your PHI, you must make your request in writing to Crossover Health Medical Group, 101 W. Avenida Vista Hermosa Ste. 120, San Clemente, CA 92672.'
            li null,
              'Your request must describe in a clear and concise fashion:'
              ul null,
                li 'The information you wish restricted;'
                li "Whether you are requesting to limit our practice's use, disclosure or both; and/or"
                li 'To whom you want the limits to apply.'
            li null,
              'Inspection and copies. You have the right to inspect and obtain a copy of the PHI that may be used to make decisions about you, including patient medical records and billing records, but not including psychotherapy notes. You must submit your request in writing to Crossover Health Medical Group, 101 W. Avenida Vista Hermosa Ste. 120, San Clemente, CA 92672 in order to inspect and/or obtain a copy of your PHI. Our practice may charge a fee for the costs of copying, mailing, labor and supplies associated with your request. Our practice may deny your request to inspect and/or copy in certain limited circumstances; however, you may request a review of our denial. Another licensed health care professional chosen by us will conduct reviews.'
            li null,
              'Amendment. You may ask us to amend your health information if you believe it is incorrect or incomplete, and you may request an amendment for as long as the information is kept by or for our practice. To request an amendment, your request must be made in writing and submitted to Crossover Health Medical Group, 101 W. Avenida Vista Hermosa Ste. 120, San Clemente, CA 92672.'
            li null,
              'You must provide us with a reason that supports your request for amendment. Our practice will deny your request if you fail to submit your request (and the reason supporting your request) in writing. Also, we may deny your request if you ask us to amend information that is in our opinion: (a) accurate and complete; (b) not part of the PHI kept by or for the practice; (c) not part of the PHI which you would be permitted to inspect and copy; or (d) not created by our practice, unless the individual or entity that created the information is not available to amend the information.'
            li null,
              'Accounting of disclosures. All of our patients have the right to request an "accounting of disclosures." An "accounting of disclosures" is a list of certain non-routine disclosures our practice has made of your PHI for purposes not related to treatment, payment or operations. Use of your PHI as part of the routine patient care in our practice is not required to be documented - for example, the doctor sharing information with the nurse; or the billing department using your information to file your insurance claim. In order to obtain an accounting of disclosures, you must submit your request in writing to Crossover Health Medical Group, 101 W. Avenida Vista Hermosa Ste. 120, San Clemente, CA 92672.'
            li null,
              'All requests for an "accounting of disclosures" must state a time period, which may not be longer than six (6) years from the date of disclosure. The first list you request within a 12-month period is free of charge, but our practice may charge you for additional lists within the same 12-month period. Our practice will notify you of the costs involved with additional requests, and you may withdraw your request before you incur any costs.'
            li null,
              'Right to a paper copy of this notice. You are entitled to receive a paper copy of our Notice. You may ask us to give you a copy of this Notice at any time. To obtain a paper copy of this Notice, contact Crossover Health Medical Group at (949) 891-0328.'
            li null,
              'Right to file a complaint. If you believe your privacy rights have been violated, you may file a complaint with our practice or with the Secretary of the Department of Health and Human Services. To file a complaint with our practice, contact Crossover Health Medical Group, 101 W. Avenida Vista Hermosa Ste. 120, San Clemente, CA 92672. All complaints must be submitted in writing. You will not be penalized for filing a complaint.'
            li null,
              'Right to provide an authorization for other uses and disclosures. Our practice will obtain your written authorization for uses and disclosures that are not identified by this Notice or permitted by applicable law. Any authorization you provide to us regarding the use and disclosure of your PHI may be revoked at any time in writing. After you revoke your authorization, we will no longer use or disclose your PHI for the reasons described in the authorization. Please note: we are required to retain records of your care.'
          p 'Again, if you have any questions regarding this Notice or our health information privacy policies, please contact Crossover Health Medical Group at (949) 891-0328.'
          p 'I acknowledge that I have received the Notice of Privacy Practices for Crossover Health Medical Group, and have been provided an opportunity to review it. If you have any questions or would like a hard copy of this Notice, please ask for one at the front desk or contact Crossover Health Medical Group at (949) 891-0328.'

          h2 'GENERAL CONSENT'
          div className: 'content-block',
            p 'I hereby give my consent for Crossover Health Medical Group to receive, use and disclose Protected Health Information ("PHI") about me, including but not limited to medical charts, records, laboratory results, imaging results, insurance claims data and information, to carry out treatment, payment and health care operation ("TPO") as described in the Notice of Privacy Practices. I recognize the need for medical care; authorize the Crossover Health Medical Group to render such medical and ancillary care, tests, procedures, drugs and other services and supplies under the general and specific instruction of the Crossover Health Medical Group. Except for emergency or extraordinary circumstances, it is my understanding that additional consents will be obtained by my treating physician if more invasive services are to be performed or if additional consents or authorizations are required by law. I understand and am aware that the practice of medicine is not an exact science and acknowledge that no guarantee has been made to me as to the result of treatment or examination. I understand that it is my right to consent, or to refuse consent, to any proposed procedure or therapeutic course.'
            p 'I understand that Crossover Health Medical Group uses advanced technology to deliver quality care, and I consent to the use of this technology. If you have any questions regarding the technology used by Crossover Health Medical Group, please contact Crossover Health Medical Group at (949) 891-0328.'
            p 'Also, with this consent Crossover Health Medical Group may:'

            ol null,
              li 'Call my home or other alternative location and leave a message on voice mail or in person in reference to any items that assist the practice in carrying out TPO, such as appointment reminders, insurance items and any calls pertaining to my clinical care, including laboratory test results, among others.'
              li 'Mail to my home or other alternative location any items that assist the practice in carrying out TPO, such as appointment reminder cards, patient statements, and any items pertaining to my clinical care, including laboratory test results, among others.'
              li 'May e-mail to my home or other alternative location any items that assist the practice in carrying out TPO, such as appointment reminder cards, patient statements, and any items pertaining to my clinical care, including laboratory test results, among others.'

          h2 'PATIENT RIGHTS AND RESPONSIBILITIES'
          div className: 'content-block',
            p 'As a partner in my health care, I have the following rights:'
            ol null,
              li 'The right to be treated with respect, consideration, and dignity.'
              li 'The right to appropriate privacy while receiving care from Crossover Health Medical Group.'
              li 'The right to be provided, to the degree known, information concerning diagnosis, evaluation, treatment, and prognosis. When it is medically inadvisable to provide such information to me, the information will be provided to a person designated by me or to a legally authorized person.'
              li 'The right to be provided an opportunity to participate in decisions involving my health care, except when such participation is contraindicated for medical reasons.'
              li 'The right to change providers if other qualified providers are available.'
              li 'The right to file a complaint or provide feedback regarding my care. To file a complaint with our practice, contact Crossover Health Medical Group, 101 W. Avenida Vista Hermosa Ste. 120, San Clemente, CA 92672. All complaints must be submitted in writing. You will not be penalized for filing a complaint. It is your right.'
              li 'The right to consent to or to refuse any treatment or procedure or refuse to participate in research.'
              li 'When the need arises, a reasonable attempt will be made for health care professionals and other staff to communicate in the language or manner primarily used by me.'
              li null,
                'The right to the following information:'
                ul null,
                  li 'Services available from Crossover Health Medical Group.'
                  li 'Provisions for after-hours and emergency care.'
                  li 'Fees for services.'
                  li 'Payment policies.'
                  li 'Advance directives, as required by state or federal law and regulations.'
                  li 'The credentials of my health care provider.'
                  li 'The existence of malpractice insurance.'

              li null,
                'As a partner in my health care, I have the following responsibilities:'
                ul null,
                  li 'I will provide accurate health information to my health care provider about any conditions that I may have and any medications, including over-the-counter products and dietary supplements I am taking, and any allergies or sensitivities that I may have.'
                  li 'I will schedule routine physical exams and other health maintenance exams recommended to me by my health care provider (pap smear, mammogram, bone density, colonoscopy, routine blood tests, immunizations, etc.). I put myself at risk for not detecting other medical diseases if I only see my health care provider for immediate problems. I will make appointments with my health care provider to discuss routine health screenings.'
                  li 'I will follow treatment plans recommended to me by my health care provider, including completing testing, making an appointment with a specialist, and taking my medications. I will participate in my treatment plan and be sure to clearly comprehend any treatment plan. I will ask questions when I do not understand. I understand that not following my treatment plans may put my health at risk.'
                  li 'I will keep my appointments and reschedule any missed appointments. I understand that my health care provider schedules these appointments to follow up on my response to treatment and to monitor my medical conditions. During these appointments, my health care provider may order tests, refer me to a specialist, change my medications, and diagnose a medical problem. If I do not follow up, I may put my health at risk and may have medical conditions go undetected.'
                  li 'I understand that the goal of Crossover Health Medical Group is to provide me with test results in a timely fashion. If I do not hear from Crossover Health Medical Group, I will call the office for test results. I understand that not hearing from the office about a particular test does not necessary mean that the test result is normal.'
                  li 'I will inform my health care provider if my medical condition changes, does not improve, or worsens. This will allow my health care provider to re-evaluate my condition and make changes in treatment. If I do not inform my health care provider, I may put my health at risk.'
                  li 'I will take charge of my health and try to make positive choices for my health including not smoking, not using illegal drugs, eating a healthy diet, and getting appropriate exercise.'
                  li 'I will take responsibility to understand the services provided by Crossover Health Medical Group and its limitations, and will ask Crossover Health Medical Group if I have any questions.'
                  li 'I will provide a responsible adult to transport me home from the Wellness Center and who will remain with me for twenty-four (24) hours, if required by my healthcare provider.'
                  li 'I will accept personal financial responsibility for any charges not covered by my insurance. I will ask questions if I have any regarding coverage of services.'
                  li 'I will be respectful of all the health care professionals and staff, as well as other patients.'

              li null,
                b 'MISCELLANEOUS PROVISIONS'
                ul null,
                  li null,
                    "Indemnity. You agree to indemnify, defend and hold harmless Crossover Health Medical Group and their respective affiliates from and against all losses, liability, expenses, damages and costs, including reasonable attorney's fees, arising out of or related to any breach of this agreement, any negligent or wrongful action or omission by you related to your use of services through Crossover Health, or any negligent or wrongful use of the Crossover Health services (including, without limitation, infringement of third party intellectual property rights or negligent or wrongful conduct)."
                  li null,
                    'Severability. The provisions of this Agreement are severable, and in the event any provision hereof is determined to be invalid or unenforceable, such invalidity or unenforceability shall not affect the validity or enforceability of the remaining provisions, but such provision shall be reformed, if reasonably possible, only to the extent necessary to make it enforceable.'
                  li null,
                    'Entire Agreement. This Agreement, together with any Crossover Health Medical Group rules or policies referred to herein, represents the complete agreement between you and Crossover Health concerning the subject matter hereof, and it replaces all prior oral or written communications concerning such subject matter. Crossover Health Medical Group may modify this Agreement as permitted by law.'
                  li null,
                    "Assignment. You may not assign, transfer or delegate this Agreement or any part of it without Crossover Health Medical Group's prior written consent. Crossover Health may freely transfer, assign or delegate all or any part of this Agreement, and any rights and duties hereunder. The Agreement will be binding upon and inure to the benefit of the heirs, successors and permitted assignees of the parties."
                  li null,
                    'Waiver. Failure to exercise or delay in exercising any right hereunder, or failure to insist upon or enforce strict performance of any provision of this Agreement, shall not be considered waiver thereof, which can only be made by signed writing. No single waiver shall be considered a continuing or permanent waiver.'
                  li null,
                    'Governing Law. This Agreement shall be governed by and construed in accordance with the laws of the state where the services are provided without giving effect to any choice of law rules or principles. Any cause of action or claim you may have with respect to Crossover Health Medical Group must be commenced within one (1) year after it arises, except to the extent such limitation is not enforceable. To the fullest extent permitted by law, each party to this Agreement waives its or his or her right to a jury trial with respect to any dispute or other controversy arising from hereunder.'
              li null,
                'For California residents:'
              li null,
                b 'NOTICE TO CONSUMERS'
              li null,
                'Medical doctors are licensed and regulated by the Medical Board of California.'
                ul null,
                  li null,
                    '(800) 633-2322'
                  li null,
                    a href: 'https://www.mbc.ca.gov/', target: '_blank',
                      'https://www.mbc.ca.gov/'
              li null,
                'For Texas residents:'
              li null,
                b 'NOTICE CONCERNING COMPLAINTS'
              li null,
                'Complaints about physicians, as well as other licensees and registrants of the Texas Medical Board, including physician assistants, acupuncturists, and surgical assistants may be reported for investigation at the following address:'
                ul null,
                  li null, 'Texas Medical Board'
                  li null, 'Attention: Investigations'
                  li null, '333 Guadalupe, Tower 3, Suite 610'
                  li null, 'P.O. Box 2018, MC-263'
                  li null, 'Austin, Texas 78768-2018'
              li null,
                'Assistance in filing a complaint is available by calling the following telephone number: 1-800-201-9353. For more information please visit our website at '
                a href: 'https://texas.gov/', target: '_blank',
                  'https://texas.gov/'
                ' (and navigate to the Texas Medical Board) '

                h4 'For Washington residents:'
                p 'Complaints about physicians, as well as other licensees and registrants of the Washington State Department of Health, including physician assistants, acupuncturists, and surgical assistants may be reported for investigation at the following address:'

                h4 'Washington State Department of Health'
                p null,
                  'Phone: (360) 236-4700 ',
                  a href: 'https://www.doh.wa.gov/', target: '_blank',
                    'https://www.doh.wa.gov/'

                h4 'For New York residents:'
                p 'Complaints about physicians, as well as other licensees and registrants of the New York State Office of the Professions, including physician assistants, acupuncturists, and surgical assistants may be reported for investigation at the following address:'

                h4 'New York State Education Department'
                p null,
                  'Phone: (518) 474-3852 ',
                  a href: 'https://www.health.ny.gov/professionals/doctors/conduct/file_a_complaint.htm', target: '_blank',
                    'https://www.health.ny.gov/'


module.exports = HealthConsent
