{formats, months} = require('config')
dobFormat = formats.dob

validate = require 'lib/validate'

{div, input, label, option, select, span} = Exim.DOM
{cx} = Exim.helpers

placeholders =
  gender: 'M/F'
  dob: dobFormat
  employee_id: '######'
  ssn_last4: '####'
  first_name: 'Michael'
  middle_name: 'J'
  last_name: 'Public'

ENTER_KEY = 13
SPACEBAR_KEY = 32

email_rx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

Validate =
  getInitialState: ->
    errors: []

  getValidator: (key) ->
    validate[key]

  getComplexChecker: (key) ->
    validate.complex[key]

  check: (creds) ->
    errors = []
    for key, value of creds
      fn = validate[key]
      value = if fn then fn(value) else value
      if not value
        errors.push(key)
    errors

  errClass: (names...) ->
    for name in names when name in (@state.errors or []) and @state.focused isnt name
      return ' error '
    ''

  _setV: (name, value) ->
    {errors} = @state
    if errors.length
      index = errors.indexOf(name)
      if index >= 0
        errors.splice(index, 1)

    if @state.focused isnt name and !value
      errors.push(name)

    state = {}
    state[name] = value
    state.errors = errors
    @setState state

  onSetV: (name) -> (event) =>
    @_setV name, @getValue event

  clearError: (name) -> (event) =>
    {errors} = @state
    if errors.length
      index = errors.indexOf(name)
      if index >= 0
        errors.splice(index, 1)
    @setState {errors}

  checkValidation: (field) -> (event) =>
    {value} = event.currentTarget
    checker = @getValidator field

    return unless checker and value.length

    if checker(value)
      state = {}
      state[field] = value
      @filterErrors field
    else
      @setState errors: @state.errors.concat(field)

  checkPresence: (field) -> (event) =>
    {value} = event.currentTarget

    if value
      @filterErrors field
    else
      @setState errors: @state.errors.concat(field)


  filterErrors: (fieldName) ->
    @setState errors: @state.errors.filter((item) -> item isnt fieldName)

  _inpt: (fieldName, options) ->
    opts = Object.assign({
      type: 'text',
      className: 'Input Input--auth'
      defaultValue: @state[fieldName]
      onFocus: @filterErrors.bind(this, fieldName)
    }, options)

    if opts.type is 'number'
      # so that iOS will present passcode-like keypad
      # instead of the numbers+symbols view
      opts.pattern = '[0-9]*'

    opts.placeholder ?= placeholders[fieldName]
    blurs = []

    # Check .validate.
    if opts.validate isnt false
      blurs.push @checkValidation(fieldName)
      opts.validate = null

    blurs.push opts.onBlur if opts.onBlur

    opts.onBlur = (event) =>
      @onSetV(fieldName)(event)
      blurs.forEach((fn) -> fn(event))

    opts.onFocus = (event) =>
      @clearError(fieldName)(event)

    input opts

  _slct: (fieldName, values=[], opts={}) ->
    select onChange: @onSetV(fieldName), value: @state[fieldName], #defaultValue: values[0],
      values.map (value) ->
        option value: value, key: value, value

  onSetDOB: (fieldName) -> (event) =>
    target = event.currentTarget
    value = target.value ? target.checked

    date = {dob_m, dob_d, dob_y} = @state
    date[fieldName] = value

    @_setV(fieldName, value)
    if date.dob_m and date.dob_d and date.dob_y
      @_setV 'dob', "#{date.dob_m}/#{date.dob_d}/#{date.dob_y}"

  dob: ->
    blur = (fieldName) => (event) =>
      @onSetDOB(fieldName)(event)
      @checkValidation('dob')

    div key: 'dob', className: 'column column-dob',
      label 'DOB'
      div className: 'dob-wrapper',
        div className: "SimpleSelect SimpleSelect--angle SimpleSelect--auth #{@errClass('dob')}",
          select onChange: @onSetDOB('dob_m'), value: @state.dob_m or 0, #defaultValue: 0,
            option disabled: true, value: 0, key: 0, 'Month'
            months.map (value, i) ->
              option value: value, key: i+1, value
        div className: "input-wrapper dob_d #{@errClass('dob')}",
          input type: 'number', pattern: '[0-9]*', className: 'Input Input--auth', onFocus: @clearError('dob'), onBlur: blur('dob_d'), placeholder: 'Day'
        div className: "input-wrapper dob_y #{@errClass('dob')}",
          input type: 'number', pattern: '[0-9]*', className: 'Input Input--auth', onFocus: @clearError('dob'), onBlur: blur('dob_y'), placeholder: 'Year'

  errorCheck: (name, passed, tip) ->
    unless passed
      unless -1 < @state.errors?.indexOf name
        (@state.errors ?= []).push name
      if tip
        (@state.errorTips ?= {})[name] = ': ' + tip
      else
        delete (@state.errorTips ?= {})[name]
    passed

  removeErrors: (name) ->
    if -1 < pos = @state.errors?.indexOf name
      @state.errors.splice pos, 1
    delete (@state.errorTips ?= {})[name]
    return

  validate: (name, isOptional) ->
    value = @state[name]
    if location.pathname is '/signin' and not /expired/i.exec(@state.error)
      @forceErrorState @state.error = false
    else if (!value and isOptional)
      @errorCheck name, true
    else
      switch name
        when 'first_name', 'last_name'
          if @errorCheck name, (!value or value?.match(/^[a-z][-a-z0-9 .]*$/i)), 'plain letters only'
            @errorCheck name, @state[name]?.length > 1
        when 'dob_y', 'dob_m', 'dob_d'
          @errorCheck name, value > 0
        when 'employee_id'
          @errorCheck name, value #and value?.match /^\d/
        when 'email'
          if @errorCheck name, value and value?.match email_rx
            @errorCheck name, !(@alreadyTakenEmails ?= {})[value], 'already taken'
        when 'work_email'
          @errorCheck name, value and value?.match email_rx
        when 'gender'
          @errorCheck name, value and validate.gender(value)
        when 'cell_phone'
          # need to handle for US and non-US numbers
          validUSPhoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
          if value and !value.match(validUSPhoneNum)
            value = value.replace(/-|\s|\.|\(|\)/g,"")
          validInternationalNumber = /^\+(?:[0-9]){8,15}$/
          @errorCheck name, value and (value.match(validUSPhoneNum) or value.match(validInternationalNumber))
        when 'relationship'
          @errorCheck name, value
        when 'password'
          @errorCheck name, /[a-z]/.test(value) and /[A-Z]/.test(value) and /\d/.test(value) and value?.length > 7
          @buildPwAlerts name, value
          if @state.password_confirmation
            @errorCheck 'password_confirmation', @state.password_confirmation is @state.password
        when 'password_confirmation'
          @errorCheck name, value and value is @state.password
        when 'agreement'
          @errorCheck name, value
        when 'employer'
          @errorCheck name, value
        when 'clinic_id'
          @errorCheck name, value
        when 'ssn_last4'
          @errorCheck name, value and value?.match /^\d{4}$/
    return

  pwApproval: (type) ->
    value = @state.password
    switch type
      when 'chars'
        if value?.length > 11
          return 'checked'
      when 'lower'
        if value and /[a-z]/.test value
          return 'checked'
      when 'upper'
        if value and /[A-Z]/.test value
          return 'checked'
      when 'number'
        if value and /\d/.test value
          return 'checked'
    ''

  bindBlur: (name, opts) ->
    (event) =>
      delete @state.focused
      @removeErrors name
      @[if name.split('_')[0] is 'dob' then 'onSetDOB' else 'onSetV'](name)(event)
      @validate name, opts?.isOptional
      return

  getValue: (event) ->
    target = event.currentTarget ? event.target
    if target?.getAttribute?('type') in ['checkbox', 'radio'] then target.checked else target.value

  bindChange: (name) ->
    (event) =>
      unless @state[name] is @getValue event
        @state._submitted = false
        @removeErrors name
        @[if name.split('_')[0] is 'dob' then 'onSetDOB' else 'onSetV'](name)(event)
      @validate name
      return

  onCheckboxChange: (name) ->
    (event) =>
      unless @state[name] is @getValue event
        @state._submitted = false
        @removeErrors name
        @onSetV(name)(event)
      return

  bindFocus: (name) ->
    (event) =>
      @state.focused = name
      @removeErrors name
      @clearError(name)(event)
      @validate name
      return

  bindKeyUp: (name) ->
    @bindChange name

  htmlClasses: (name = '', opts = {}, classes = '') ->
    focused = if @state.focused is name then 'focused' else ''
    disabled = if opts.disabled then 'is-disabled' else ''
    [classes, name, focused, disabled, @errClass(name), opts.html?.classes or ''].join ' '

  inputParams: (name, opts, keys, inf_object, default_label = null) ->
    inf_object.name = name
    if default_label then inf_object['aria-label'] = default_label
    inf_object['required'] = if opts.html?.required then opts.html?.required else !!(opts?.required)

    # html properties
    for key, value of opts.html?.props or {}
      inf_object[key] = value

    # event handlers
    for type in ['onBlur', 'onChange', 'onFocus', 'onKeyUp']
      handlers = if ~keys.indexOf(type) then [@['bind' + type.substr 2](name, opts)] else []
      val = if opts[type] instanceof Array then opts[type] else [opts[type]]
      for fn in val when typeof fn is 'function'
        handlers.push fn
      if handlers.length is 1
        inf_object[type] = handlers[0]
      else if handlers.length > 1
        do (handlers) ->
          inf_object[type] = (event) ->
            for handler in handlers
              handler event
            return

    inf_object

  labeledInput: (name, title, opts = {}) ->
    id = opts.id or 'labeled-input-' + name
    extra = opts.extra
    isPassword = opts.type is 'password'
    error = opts.error or ''
    requiredField = opts.requiredField or false

    div className: @htmlClasses(name, opts, 'labeled-input'), ref: opts.ref or id,
      extra if extra
      label 'aria-live': 'polite', htmlFor: id, title + (@state.errorTips?[name] or ''), span className: 'sr-only', if requiredField then '(Required)' else ''
      div className: 'InputContainer',
        input @inputParams name, opts, ['onBlur', 'onChange', 'onFocus', 'onKeyUp'],
          className:    "Input Input--expand Input--auth #{cx 'PasswordField': isPassword}"
          defaultValue: @state[name]
          id:           id
          type:         opts.type or 'text'
          tabIndex:     opts.tabIndex or 0
          disabled:     opts.disabled or false
          placeholder:  opts.placeholder
          "aria-required": ((@required && name in @required) or opts.required) or false
        @passwordToggle(opts.type) if isPassword
      div className: 'ErrorHelperText', 'aria-live': 'assertive', if name in @state.errors then error else ''

  buildPwAlerts: (name,value) ->
    msg = ''
    msg += "Password must contain a lowercase character. " if !(/[a-z]/.test(value))
    msg += "Password must contain an uppercase character. " if (!(/[A-Z]/.test(value)))
    msg += "Password must contain a number. " if (!(/\d/.test(value)))
    msg += "Password must be at least 8 characters. " if !(value?.length > 7)

    span className: 'invisible', 'aria-live': 'assertive', if ('password' in (@state.errors or [])) then msg else 'Password is fine.'

  passwordToggleClick: (e) ->
    currentType = e.target.previousSibling.getAttribute('type')
    passwordField = e.target.previousSibling
    passwordField.setAttribute('type', if currentType is 'password' then 'text' else 'password')
    e.target.className = if currentType is 'password' then 'fa fa-eye-slash PasswordToggle' else 'fa fa-eye PasswordToggle'
    passwordField.focus()

  passwordToggleKeyDown: (e) ->
    @passwordToggleClick(e) if e.keyCode is ENTER_KEY or e.keyCode is SPACEBAR_KEY

  passwordToggle: (type) ->
    Exim.DOM.i className: 'fa fa-eye PasswordToggle', tabIndex: 0, role: "button", onClick: @passwordToggleClick, onKeyUp: @passwordToggleKeyDown, 'aria-label': 'Password visibility toggle'

  checkboxPanel: (name, description, opts = {}) ->
    id = opts.id or 'checkbox-panel-' + name

    div className: @htmlClasses(name, opts, 'checkbox-panel'), ref: opts.ref or id,
      input
        name: name
        onChange: @onCheckboxChange(name)
        checked:    !!@state[name]
        id:             id
        disabled:    opts.disabled
        type:         'checkbox'
        required:    !!(opts?.required)
      label htmlFor: id, description


  labeledSelect: (name, default_label, values, opts = {}) ->
    default_value = opts.defaultValue or ''

    default_class = (if @state[name] is default_value or not @state[name]? then ' default-value' else '')

    id = opts.id or 'labeled-select-' + name

    div className: 'labeled-select-wrapper',
      label htmlFor: id, default_label
      div className: @htmlClasses(name, opts, 'labeled-select') + default_class, ref: opts.ref or id,
        select @inputParams(name, opts, ['onBlur', 'onChange', 'onFocus'], {id, defaultValue: default_value}),
          option disabled: true, value: '', key: '', default_label
          for inf in values
            option value: String(inf.value ? inf.label ? inf), key: String(inf.value ? inf), String inf.label ? inf

  unlabeledSelect: (name, default_label, values, opts = {}) ->
    default_value = opts.defaultValue or ''

    default_class = (if @state[name] is default_value or not @state[name]? then ' default-value' else '')

    id = opts.id or 'unlabeled-select-' + name
    disabled = opts.disabled

    div className: @htmlClasses(name, opts, 'unlabeled-select') + default_class, ref: opts.ref or id,
      select @inputParams(name, opts, ['onBlur', 'onChange', 'onFocus'], {id, defaultValue: default_value, disabled}, default_label),
        option disabled: true, value: '', key: '', default_label
        for inf in values
          option value: String(inf.value ? inf.label ? inf), key: String(inf.value ? inf), String inf.label ? inf

  months: ({label: name, value: i + 1} for name, i in months)
  days:   (i for i in [1 .. 31] by 1)
  years:  (i for i in [(new Date).getFullYear() .. 1900] by -1)

  column: (fieldName, labelName, opts) ->
    if typeof labelName is 'object'
      options = labelName
      labelName = null

    div key: fieldName, className: 'column',
      label labelName
      if Array.isArray(opts)
        div className: 'SimpleSelect SimpleSelect--angle SimpleSelect--auth',
          @_slct fieldName, opts
      else
        div className: "input-wrapper #{fieldName} #{@errClass(fieldName)}",
          @_inpt fieldName, opts

module.exports = Validate
