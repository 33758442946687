{appStore} = require 'stores'
ReactDOM = require 'react-dom'
{cx} = Exim.helpers
{div, span, tch, a, ul, li, b, button, h1} = Exim.DOM

ConfirmationModal = Exim.createView module.id,

  getInitialState: ->
    confirmed: false

  onCancel: (evt) ->
    evt.preventDefault()
    @props.onCancel?()

  onConfirm: (evt) ->
    evt.preventDefault()
    @setState confirmed: true
    @props.onConfirm?()

  onSecondary: (evt) ->
    evt.preventDefault()
    @props.onSecondary?()

  componentWillUnmount: ->
    if (@props.activatorElId)
      activatorEl =  document.getElementById(@props.activatorElId)
      activatorEl?.focus()

  render: ->
    {title, text, cancelText, confirmText, secondaryText, onSecondary, onCancel, onConfirm, srOnlyText, body, contentLabel} = @props
    div className: 'ConfirmationModal', id: 'confirmation-modal-parent-element',
      h1 className: 'ConfirmationModalTitle', title
      if text instanceof Array
        div className: 'ConfirmationModalText',
          text.shift().text
          ul className: 'ConfirmationModalText-listItems',
            text.map (item, i) ->
              li key: "textItem-#{i}",
                span className: 'textItem-label', item.label
                span className: 'textItem-text', item.text
      else
        div className: 'ConfirmationModalText', text
      div className: 'ConfirmationModalBody', body
      div className: 'ConfirmationModalFooter',
        button className: 'Button Button--cancel Button--pointer', onClick: @onCancel, cancelText || 'Cancel' if !!onCancel
        button className: 'Button Button--primary Button--icon-divided Button--pointer', onClick: @onSecondary, secondaryText || 'Action' if onSecondary
        button className: 'Button Button--primary Button--icon-divided Button--pointer', onClick: @onConfirm, confirmText || 'Confirm'
      span className: 'invisible', 'aria-live': 'assertive', if @state.confirmed then "#{srOnlyText}" else ''



module.exports = ConfirmationModal
