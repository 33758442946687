import { appStore } from "stores";

const CheckInCard = React.createClass({
  displayName: "CheckInCard",
  mixins: [appStore.connect("config")],

  renderLogo() {
    let { assets = {} } = this.state.config;
    return <img className="Auth-logo" alt="" {...assets.logo_on_splash} />;
  },

  render() {
    return (
      <div className="CheckInCard-wrapper">
        {this.renderLogo()}
        <div className="CheckInCard">{this.props.children}</div>
      </div>
    );
  },
});

export default CheckInCard;
