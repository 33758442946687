ReactDOM = require 'react-dom'
{cx} = Exim.helpers
{div, tch} = Exim.DOM
utils = require 'lib/utils'
cache = require 'lib/cache'
{touchDevice}   = require 'config'

FAST_TOUCH_DELAY = 250
DIFF_TO_ACTION = 25

Row = Exim.createView
  getInitialState: ->
    shifted: false
    x: null

  getDefaultProps: ->
    className: ''
    blockRight: true
    blockLeft: false

  componentDidMount: ->
    targ = @refs['top-layer']
    actions = @refs['actions']
    @rowWidth = parseInt(window.getComputedStyle(targ, null).width)
    @actionsWidth = parseInt(window.getComputedStyle(actions, null).width)

  componentWillUpdate: (nextProps, nextState) ->
    if nextState.x and not @state.x and not cache.preventX
      @toggleYScroll(true)
    else if not nextState.x and @state.x
      @toggleYScroll(false)
    else if nextState.shifted and !@state.shifted
      @toggleYScroll(false)

  toggleYScroll: (state) ->
    {classList} = document.querySelector('.PullToRefresh')
    action = if state then 'add' else 'remove'
    classList[action]('no-scroll')
    cache.preventY = state

  handleTouchStart: (evt) ->
    @clicked = false
    @fastTouch = true
    disableFast = => @fastTouch = false
    @fastTouchTm = setTimeout(disableFast, FAST_TOUCH_DELAY)
    @touchStartX = evt.touches[0].pageX
    @setState anim: false
    @startFromShifted = @state.shifted

  handleTouchMove: (evt) ->
    return if cache.preventX
    currX = if @state.shifted then @actionsWidth else 0
    @touchMoveX = evt.touches[0].pageX
    @moveX = @touchStartX - @touchMoveX
    if @state.x? or (@moveX > DIFF_TO_ACTION or @moveX < -DIFF_TO_ACTION)
      rightBlock = @props.blockRight and @moveX < 0
      leftBlock = @props.blockLeft and @moveX > 0
      prevent = !@state.shifted or (@startFromShifted and -@moveX > @actionsWidth)
      return if prevent and (rightBlock or leftBlock)
      @setState x: currX + @moveX

  handleTouchEnd: (evt) ->
    return if @clicked
    swiped = !!@state.x
    if swiped
      if @moveX > (DIFF_TO_ACTION * 2) or (@fastTouch and @moveX > 0)
        @setState shifted: true, anim: true, x: null
        @props.onShift?(@props.rowId)
      else if @moveX < -(DIFF_TO_ACTION * 2) or (@fastTouch and @moveX < 0)
        @setState shifted: false, anim: true, x: null
      else
        @setState anim: true, x: null
    else
      if @state.shifted
        @setState shifted: false, anim: true
      else if !@moveX
        @props.handler?(evt) unless cache.preventX

    @clearVars()

  handleClick: (evt) ->
    if touchDevice
      @handleTouchEnd(evt)
      @clicked = true
      return
    if @state.shifted
      @setState shifted: false, anim: true
    else if @props.toggleOnClick
      @setState shifted: true, anim: true
      @props.onShift?(@props.rowId)

  unShift: ->
    @setState shifted: false, anim: true

  clearVars: ->
    [@moveX, @touchMoveX, @longTouch, @fastTouch] = []
    clearTimeout(@fastTouchTm)

  runAction: (action) -> (evt) =>
    @setState shifted: false
    action?(evt)

  render: ->
    {className, tag, chevroned, actions, disabled} = @props
    {shifted, anim, x} = @state
    className += ' Row-wrapper'
    if @actionsWidth and !x
      x = if shifted then @actionsWidth else null
    style = {}
    style.transform = "translateX(#{-x}px)" if x?
    wrapperStyle = if @props.style then Object.assign({}, @props.style) else {}

    [handleTouchStart, handleTouchMove, handleTouchEnd] = if actions.length and !disabled then [@handleTouchStart, @handleTouchMove, @handleTouchEnd] else []

    div {className, tag, style: wrapperStyle},
      div style: style, className: "Row-layer Row-layer-top #{cx({shifted, anim, 'Row-layer-top--chevroned': chevroned})}", ref: 'top-layer', onTouchStart: handleTouchStart, onTouchMove: handleTouchMove, onTouchEnd: handleTouchEnd, onClick: @handleClick,
        @props.children
      div style: style, className: 'Row-layer Row-layer-child',
        @props.children
      div className: 'Row-layer Row-layer-bottom', ref: 'actions',
        actions.map (action) =>
          actionCls = "Row-action #{action.color or ''} #{action.className or ''}"
          tch className: actionCls, handler: @runAction(action.handler), tag: action.tag, key: action.name, style: action.style,
            action.title


module.exports = Row
