{appStore, authStore}   = require 'stores'
Account      = require './Account'
ContactInfo  = require './ContactInfo'
Identity     = require './Identity'
Interests    = require './Interests'
Verify       = require './Verify'
Details      = require './Details'
attachHeader = require 'components/mixins/attachHeader'
StepsNav       = require 'components/elements/steps-nav'
{div, span} = Exim.DOM
AuthWrapper = require 'components/auth/auth-wrapper'

STEPS = [
  {
    route: '/signup/account',
    title: 'Account',
    view: Account,
    preventEditing: true,
    allowDirectNavigation: true
  },
  {
    route: '/signup/contact_info',
    title: 'Contact Info',
    view: ContactInfo,
    allowDirectNavigation: true
  },
  {
    route: '/signup/identity',
    title: 'Identity',
    view: Identity
  },
  {
    route: '/signup/interests',
    title: 'Interests',
    view: Interests
  },
  {
    route: '/signup/verify',
    title: 'Verify',
    view: Verify
  }
]

Signup = Exim.createView module.id,
  listen: ['auth/error', 'app/config', 'auth/metadata', 'auth/finishSigningUp']

  contextTypes:
    router: React.PropTypes.object.isRequired

  componentWillMount: ->
    v = @findActiveView()
    # TODO: Add a message? This avoids a reload on any screen.
    unless v?.allowDirectNavigation
      @context.router.push pathname: STEPS[0].route
      authStore.actions.resetLastStep()

  componentDidMount: ->
    appStore.actions.setTitle('Sign Up')
    document.querySelector('.hidden').innerHTML = "Account step loading"
    @next {} if @state.oauth

  componentWillUnmount: ->
    appStore.actions.setTitle()

  getInitialState: ->
    state = {}

    if state.error
      authStore.actions.loadInitialError(state.error)
      delete state.error

    state

  findActiveIndex: ->
    STEPS.findIndex((s) => @context.router.isActive(s.route))

  findNextStep: ->
    STEPS[@findActiveIndex() + 1]

  findActiveView: ->
    STEPS.find((s) => @context.router.isActive(s.route))?.view

  leave: ->
    # TODO: Prompt user about losing all progress?
    @context.router.push pathname: '/signin'

  interestsIsActive: ->
    @state.metadata.lastStep == STEPS.length - 2 && @findActiveIndex() == 3

  next: (state) ->
    {metadata} = @state
    @setState state
    nextStep = @findNextStep()
    routeIndex = STEPS.map((step) => step.route).indexOf(@props.location.pathname)

    if (routeIndex != metadata.lastStep)
      authStore.actions.setLastStep(routeIndex + 1)
    else
      authStore.actions.setLastStep(metadata.lastStep + 1)

    @context.router.push nextStep.route
    setTimeout(() => window.scrollTo(0,0))

  finish: (state) ->
    return if @state.finishSigningUp

    @setState state
    # Pull in the new state as well, since @setState doesn't immediately
    # update @state it would seem?
    data = Object.assign({}, @state, state)

    delete data.error
    delete data.oauth
    delete data.config

    authStore.actions.setFinishSigningUp()

    authStore.actions.signup(data).then(=> @next({})).catch(=> @next({}))

  render: ->
    {metadata} = @state
    {lastStep} = metadata

    return React.createElement(AuthWrapper, {title: 'Activate Your Account'},
      div className: 'AuthCard',
        div className: 'SignUp',
          div className: 'hidden', role: 'alert'
          React.createElement(StepsNav, {
            modifier: 'signUp',
            steps: STEPS.map((s, at) =>
              return {
                content: s.title,
                to: s.route,
                isDisabled: s.preventEditing || lastStep < at,
                isCompleted: at < lastStep,
              }
            ),
          })
          @findActiveView() {
            next: (if @interestsIsActive() then @finish else @next)
            leave: @leave
            state: @state
            signUpType: @state.config?.signup?.type or 'employee'
            config: @state.config
          }
    )

module.exports = Signup
