const { logToRollbar } = require("lib/utils");
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasError && prevState.hasError === this.state.hasError) {
      this.setState({ hasError: false });
    }
  }

  handleBack() {
    this.props.router.goBack();
  }

  componentDidCatch(error, errorInfo) {
    logToRollbar(error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    return (
      <div className="NewContent" style={styles.wrapper}>
        <div style={styles.header}>Oops!</div>
        <div style={styles.text}>Something went wrong</div>
        <button style={styles.button} onClick={this.handleBack}>
          Go Back
        </button>
      </div>
    );
  }
}

export default ErrorBoundary;

const styles = {
  wrapper: {
    backgroundColor: "#FFFFFF",
    padding: "20px",
    textAlign: "center",
  },

  header: {
    color: "#EAA948",
    marginTop: "60px",
    lineHeight: "normal",
    letterSpacing: "1px",
    fontFamily: "ArialMT, Arial, sans-serif",
    fontWeight: "2200",
    fontStyle: "bold",
    fontSize: "190px",
  },

  text: {
    margin: "10px",
    fontStyle: "normal",
    fontSize: "20px",
  },

  button: {
    width: "140px",
    height: "40px",
    backgroundColor: "rgba(121, 166, 54, 1)",
    border: "1px solid rgba(170, 170, 170, 1)",
    borderRadius: "3px",
    color: "#FDF9F3",
    fontSize: "20px",
    textAlign: "center",
    margin: "0 auto",
  },
};
