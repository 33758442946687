"use strict";
import { isEmpty } from "lodash";
import request from "lib/new-request";
import { computed, logToRollbar } from "lib/utils";
import { observable, toJS } from "mobx";
import { now } from "mobx-utils";
import marked from "marked";

const renderer = new marked.Renderer();
renderer.link = (href, title, text) =>
  `<a target="_blank" href="${href}" rel="noopener noreferrer" title="${title}">${text}</a>`;

const wellnessStore = observable({
  _program: {},
  _programs: null,
  _activePrograms: null,
  _isFetchingPrograms: false,

  async fetchActivePrograms(id) {
    try {
      this._isFetchingPrograms = true;

      let { results } = await request.get(
        `/v1/patients/${id}/wellness_program_assignments?status=active`
      );
      this._activePrograms = results;
    } catch (e) {
      logToRollbar(e);
    } finally {
      this._isFetchingPrograms = false;
    }
  },

  async fetchPrograms(id) {
    try {
      this._isFetchingPrograms = true;

      let results = (
        await request.get(`/v1/patients/${id}/wellness_program_assignments`)
      ).results;
      if (!isEmpty(results)) {
        this._programs = results;
      }
    } catch (e) {
      logToRollbar(e);
    } finally {
      this._isFetchingPrograms = false;
    }
  },

  async fetchProgram(id) {
    try {
      this._isFetchingPrograms = true;

      this._program = await request.get(
        `/v1/wellness_program_assignments/${id}`
      );
    } catch (e) {
      logToRollbar(e);
    } finally {
      this._isFetchingPrograms = false;
    }
  },

  async saveConsent(id) {
    try {
      let res;
      this._isFetchingPrograms = true;
      const data = { consented: true };

      return await request.put(
        `/v1/wellness_program_assignments/${id}/consent`,
        data
      );
    } catch (e) {
      logToRollbar(e);
    } finally {
      this._isFetchingPrograms = false;
    }
  },

  async saveProgram(id, survey) {
    try {
      let res;
      this._isFetchingPrograms = true;
      const data = {
        patient_screening: { data: JSON.stringify(survey.values) },
      };

      res = await request.put(
        `/v1/wellness_program_assignments/${id}/patient_screening`,
        data
      );

      if (survey.completed === "display") {
        res = await request.post(
          `/v1/wellness_program_assignments/${id}/patient_screening/complete`
        );
      }
    } catch (e) {
      logToRollbar(e);
    } finally {
      this._isFetchingPrograms = false;
    }
  },

  async getUpdatedPrograms(userId, isAdmin) {
    !isAdmin && (await this.fetchActivePrograms(userId));
    !isAdmin && (await this.fetchPrograms(userId));
  },

  getSurvey(program) {
    const { status, wellness_program } = program;
    const nowMoment = moment(new Date(now(300000)));
    const surveyStatus = status.patient_screening;

    const overviewText = marked(wellness_program.overview, { renderer });
    const label =
      surveyStatus === "incomplete"
        ? "START"
        : surveyStatus === "active"
        ? "COMPLETE"
        : "VIEW";
    const ineligible =
      surveyStatus === "ineligible" ||
      nowMoment.isAfter(wellness_program.extended_end_date);
    const ongoing =
      wellness_program.stage === "ongoing" ||
      wellness_program.stage === "extended";

    return { ...program, label, ineligible, overviewText, ongoing };
  },

  graphData(results, graphType, resultType) {
    const { value, scale, status } = results;

    const graphItems = [0, 1];
    const data = [];
    let dotGraph;
    let horizontalBarGraph;
    let dataScale;
    let dataValue;
    if (graphType === "promis") {
      if (resultType === "physical_health") {
        dataScale = scale && scale.physical[0];
        dataValue = value["physical_health"].value;
      } else {
        dataScale = scale && scale.mental[0];
        dataValue = value["mental_health"].value;
      }
    } else {
      dataScale = scale && scale[0];
      dataValue = value;
    }

    switch (graphType) {
      case "cv_risk_score":
        if (status === "done") {
          // represents values used in the horizontal graph
          horizontalBarGraph = {
            low: dataScale.green.high * 100,
            medium: dataScale.red.low * 100 - dataScale.green.high * 100,
            high: dataScale.red.high * 100 - dataScale.red.low * 100,
            your_score: null,
            type: "bar",
          };

          // Represents values used in the circle 'dot' above the horizontal graph
          dotGraph = {
            low: 0,
            medium: 0,
            high: 0,
            your_score:
              dataValue > dataScale.red.high * 100
                ? dataScale.red.high * 100
                : dataValue,
            type: "circle",
          };

          graphItems.map((index) => {
            index === 0
              ? data.push({
                  index,
                  ...horizontalBarGraph,
                })
              : data.push({
                  index,
                  ...dotGraph,
                });
          });
        }
        break;
      case "promis":
        horizontalBarGraph = {
          low: dataScale.green.high - dataScale.green.low,
          medium: dataScale.green.low - dataScale.yellow.low,
          high: dataScale.red.high - dataScale.red.low,
          threshold: dataScale.red.low,
          your_score: null,
          average_score: null,
          type: "bar",
        };

        dotGraph = {
          low: 0,
          medium: 0,
          high: 0,
          your_score: dataValue,
          average_score: value[resultType].average,
          type: "circle",
        };

        graphItems.map((index) => {
          index === 0
            ? data.push({
                index,
                ...horizontalBarGraph,
              })
            : data.push({
                index,
                ...dotGraph,
              });
        });
        break;
      default:
        break;
    }
    return data;
  },

  clear() {
    _program = {};
    _programs = null;
    _activePrograms = null;
    _isFetchingPrograms = false;
  },
});

computed(wellnessStore, {
  get program() {
    return toJS(this._program);
  },

  get programs() {
    return toJS(this._programs);
  },

  get activePrograms() {
    return toJS(this._activePrograms);
  },

  get isFetching() {
    return toJS(this._isFetchingPrograms);
  },

  get programsWithWidgets() {
    return toJS(this._activePrograms).filter(
      (program) => program.display_program_widget
    );
  },
});

export default wellnessStore;
