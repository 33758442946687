"use strict";
const { capitalize } = require("lib/utils");
const reDuration = /\s*\((\d+) min\)/i;
const { pick } = require("lodash");

class AppointmentType {
  static fromAPI({ name, ...rest }) {
    let params = {
      ...pick(rest, ["id", "color", "label", "note", "method", "description"]),
      name: name.replace(reDuration, ""),
    };

    let match = reDuration.exec(name);
    if (match) {
      params.duration = moment.duration(+match[1], "minutes");
    }

    return new this(params);
  }

  constructor({ site, color, ...rest }) {
    this._site = site;
    this._color = color;

    Object.assign(
      this,
      {
        name: "",
        label: "",
        note: "",
        method: "",
        isOpen: false,
        isInternal: false,
        description: "",
      },
      rest
    );
  }

  setSite(site) {
    return new this.constructor({
      site,
      color: this._color,
      ...pick(this, [
        "id",
        "duration",
        "name",
        "note",
        "method",
        "label",
        "isOpen",
        "isInternal",
        "description",
      ]),
    });
  }

  get nameAndDuration() {
    let duration = this.duration ? ` (${this.duration.asMinutes()} min)` : "";

    return `${this.name}${duration}`;
  }

  get modality() {
    if (this.isInternal) {
      return {
        name: "Internal Hold Types",
        apptTypes: AppointmentType.INTERNAL_TYPES,
      };
    }

    let synopsisStore = require("stores/new-synopsis");
    let sites = this._site ? [this._site] : synopsisStore.sites;

    for (let site of sites)
      for (let modality of site.modalities)
        if (modality.apptTypes.some((aT) => aT.id == this.id)) return modality;
  }

  get site() {
    const synopsisStore = require("stores/new-synopsis");
    return this._site || synopsisStore.site;
  }

  get isBookable() {
    return !this.isOpen && !this.isInternal;
  }

  get color() {
    return this._color || (this.isBookable && this.modality.color) || "";
  }

  get isInitial() {
    return this.label.endsWith("- IE");
  }

  get messages() {
    return this.site.apptMessages
      .filter((m) => m.apptTypeIds.includes(this.id))
      .map((m) => m.html);
  }
}

AppointmentType.OPEN = new AppointmentType({
  id: "",
  name: "Open",
  isOpen: true,
});

// TODO: those should come from API
AppointmentType.INTERNAL_TYPES = [
  "care coordination",
  "lunch",
  "break",
  "meeting",
  "huddle",
  "provider unavailable",
  "holiday",
  "reschedule",
  "admin",
  "external booking",
].map((id) => {
  return new AppointmentType({
    id,
    name: capitalize(id),
    isInternal: true,
  });
});

module.exports = AppointmentType;
