{patientStore, authStore, appStore, synopsisStore} = require 'stores'

{div, p, span, sub, spinner, table, th, tr, td} = Exim.DOM

Metric = require 'components/trends/Metric'

subRegex = /\<sub\>(.*)\<\/sub\>/

NotesModal = Exim.createView module.id,
  mixins: [
    patientStore.connect('notes', 'notesLoading')
    synopsisStore.connect('clinics', 'site')
  ]

  componentWillMount: ->
    patientStore.actions.fetchNotes(@props.appointment_id)

  componentWillUnmount: ->
    patientStore.actions.clearNotes()

  getRange: (details) ->
    ranges = details?.ranges
    return '' if !ranges or details.type is 'lifestyle'

    if (normal = ranges.filter((r) -> !r.hide and (+r.color is 1))) and normal.length
      min = normal[0].min
      max = normal[normal.length - 1].max
      "#{min} - #{max} #{details.units or ''}"
    else
      ''

  getNoteDetails: (note) ->
    {metrics} = @props
    metric = metrics.find((m) -> m.id is note.metric)
    note.details = metric if metric
    note

  render: ->
    {default_site, full_name, dob} = authStore.get('user')

    {notes} = @state
    {visit, metrics, title} = @props
    notes = (notes or []).map(@getNoteDetails)

    {date, name, provider, site_code} = visit or {}

    {site_name, address, contact} = clinic = @state.clinics[site_code] or {}

    if default_site is 'crk' and notes
      notes = notes.filter((note) -> note.name?.toLowerCase() isnt 'reason for visit')

    div className: 'NotesModal-content',
      div className: 'InfoBlock',
        div className: 'InfoBlock-column',
          if full_name
            div className: 'InfoBlock-row',
              p 'Patient:'
              span  full_name
          if dob
            div className: 'InfoBlock-row',
              p 'DOB:'
              span  dob
          if date
            div className: 'InfoBlock-row',
              p 'Appointment Date:'
              span  date
          if name
            div className: 'InfoBlock-row',
              p 'Appointment Type:'
              span  name
          if provider
            div className: 'InfoBlock-row',
              p 'Appointment Physician:'
              span  provider
        if clinic
          div className: 'InfoBlock-column',
            if site_name
              div className: 'InfoBlock-row',
                p 'Site Name:'
                span site_name
            if address
              div className: 'InfoBlock-row',
                p 'Site Address:'
                span  address
            if contact
              div className: 'InfoBlock-row',
                p 'Site Contact:'
                span  contact
      if notes and notes.length
        table role: 'table', 'aria-label': title or 'Notes', 'aria-rowcount': notes.length, 'aria-colcount': "4", className: 'Table Table--striped Table--fullWidth',
          tr className: 'TableHeader',
            th className: 'TableHeader-column NotesModal-label u-left', 'Name'
            th className: 'TableHeader-column NotesModal-graph'
            th className: 'TableHeader-column NotesModal-value', 'Value'
            th className: 'TableHeader-column NotesModal-range', 'Range'
          div className: 'TableBody',
            notes.map (note) =>
              res = note.name.match subRegex
              details = note?.details or {}
              cls = details.cls or ''

              tr className: 'NotesModal-item TableBody-row', key: note.name + Math.random(),
                unless res
                  td className: 'TableBody-column NotesModal-label', note.name
                else
                  td className: 'TableBody-column NotesModal-label', note.name.replace(subRegex, ''),
                    sub res[1]
                td className: 'TableBody-column NotesModal-graph',
                  Metric
                    key: details.id
                    size: 'small'
                    className: 'Metric--chartOnly'
                    chartOnly: true
                    data: details
                    icon: details.icon
                td className: "TableBody-column NotesModal-value u-textLeft #{cls}", 'aria-label': "#{note.value} #{if note.units then note.units else ''}",
                  "#{note.value}"
                  " #{note.units}" if note.units
                td className: 'TableBody-column NotesModal-range', @getRange(details)
      else if @state.notesLoading
        div className: 'u-center',
          spinner()
      else
        span className: 'no-data', 'No data available.'

module.exports = NotesModal
