import NativeSelect from "components/elements/native-select";
import AddNewPatient from "components/elements/AddNewPatient";
const oldAppStore = require("stores/app");
const oldAuthStore = require("stores/auth");
const oldSynopsisStore = require("stores/synopsis");
const appStore = require("stores/new-app");
const authStore = require("stores/new-auth");
const synopsisStore = require("stores/new-synopsis");

const { observer } = require("mobx-react");
const { Link, withRouter } = require("react-router");
const PatientSearch = require("components/elements/patient-search");
const withMediaQueries = require("components/elements/with-media-queries");
const Spinner = require("components/elements/new-spinner");
const { mdToLg } = require("lib/media-queries");
const { proxyPatient, closePatient, cx } = require("lib/utils");
const { ISO_DATE } = require("lib/formats");
const ReactDOM = require("react-dom");
const { client } = require("config");

const { ScheduleTab, CalendarTab, MessagesTab } = require("./tabs");

const {
  DocumentsLink,
  TrendsLink,
  AppointmentsLink,
  MedicationsLink,
  LaboratoriesLink,
  EducationLink,
  ElationLink,
  ScreeningsLink,
  WellnessLink,
  HealthScreeningLink,
  BeWellLink,
} = require("./links");

const renderSite = (site) => {
  return (
    <option key={site.id} value={site.id}>
      {site.name}
    </option>
  );
};

const Sidebar = React.createClass({
  displayName: "Sidebar",
  mixins: [
    oldAppStore.connect("config"),
    oldAuthStore.connect("user", "avatarUploading"),
    oldSynopsisStore.connect("sites"),
  ],

  getDefaultProps() {
    return {
      onResize: () => {},
    };
  },

  getInitialState() {
    return {
      displayMode: "",
      isNewPatientModalOpen: false,
    };
  },

  onPatientAdd() {
    this.setState({ isNewPatientModalOpen: true });
  },

  onPatientModalClose() {
    this.setState({ isNewPatientModalOpen: false });
  },

  handleResize() {
    let nextWidth = window.innerWidth;
    if (nextWidth < this.prevWidth) {
      appStore.isSidebarOpen = false;
    }

    this.prevWidth = nextWidth;
    this.emitResize();
  },

  componentWillMount() {
    this.prevWidth = window.innerWidth;
    window.addEventListener("resize", this.handleResize);
    this.fetchUser();
  },

  componentDidMount() {
    this.emitResize();
    document.querySelectorAll(".SidebarNavLink--active").forEach((element) => {
      element.setAttribute("aria-current", "page");
    });
    document.querySelectorAll(".SidebarNav-item").forEach((element, index, array) => {
      element.setAttribute("aria-label", `Link ${index + 1} of ${array.length}`);
    });
  },

  componentDidUpdate() {
    document.querySelectorAll(".SidebarNavLink").forEach((element) => {
      element.removeAttribute("aria-current");
    });
    document.querySelectorAll(".SidebarNavLink--active").forEach((element) => {
      element.setAttribute("aria-current", "page");
    });
  },

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },

  fetchUser() {
    oldAuthStore.actions.fetchUser();
  },

  isCollapsed() {
    return this.props.isTablet && !appStore.isSidebarOpen;
  },

  emitResize() {
    let el = ReactDOM.findDOMNode(this);
    this.props.onResize(el.clientWidth);
  },

  changeSite(evt) {
    let siteId = evt.target.value;
    let newSite = this.state.sites.find((site) => site.id == siteId);

    oldSynopsisStore.actions.updateSite(newSite);
  },

  async signOut() {
    await oldAuthStore.actions.signout();
    this.props.router.push({ pathname: "/signin" });
  },

  async proxyPatient(patient) {
    await proxyPatient(patient);
    appStore.isSidebarOpen = false;
  },

  async closePatient() {
    await closePatient();
    appStore.isSidebarOpen = false;
  },

  renderBrand() {
    let { config } = this.state;
    let clientName = (config.app || {}).title4;
    if (clientName == "Health Center") clientName = "Crossover";

    let { assets = {} } = config;
    let logoClass = cx("Sidebar-brandLogo", { collapsed: this.isCollapsed() });
    let logoProps = this.isCollapsed()
      ? assets.logo_on_small_sidebar
      : assets.logo_on_large_sidebar;

    const blackLogoProps = this.isCollapsed()
      ? assets.logo_on_small_sidebar
      : assets.logo_on_white;

    return (
      <Link to="/landing" title="Return to Crossover Homepage">
        <picture className="Sidebar-brandLink">
          <source
            srcSet={blackLogoProps ? blackLogoProps.src : ""}
            media="screen and (forced-colors: active) and (prefers-color-scheme: light)"
          />
          <img className={logoClass} role="presentation" {...logoProps} />
        </picture>
      </Link>
    );
  },

  renderSiteSelect() {
    let { site, sites } = synopsisStore;

    let ariaLabel = site.name || "select site";

    return (
      <NativeSelect
        className="SiteSelect"
        value={site.id}
        onChange={this.changeSite}
        aria-label={ariaLabel}
      >
        {sites.map(renderSite)}
      </NativeSelect>
    );
  },

  renderNavHeader() {
    if (!authStore.isProxying) return;

    let { user } = authStore;
    let id = user.patientId;
    let linkClass = cx("SidebarNav-name", {
      traveler: user.isTraveler,
    });

    return (
      <header className="SidebarNav-header">
        <Link
          to="/profile"
          className={linkClass}
          style={{ color: "#0FBFFF" }}
          title={user.longName}
        >
          {user.legalAndPreferredName}
        </Link>
        <div className="SidebarNav-info">
          <time dateTime={user.bornAt.format(ISO_DATE)}>{user.dob}</time> (
          {user.shortGender})
        </div>
        <div className="SidebarNav-info">
          {id ? id : ""}
          {user.migratedToXOP && <span className="xop-badge">XOP</span>}
        </div>
        <button
          className="SidebarNav-close"
          title="Close patient"
          onClick={this.closePatient}
        />
      </header>
    );
  },

  renderNavLinks() {
    return (
      <ul className="SidebarNav-list" tabIndex="0" id="sidebar">
        <ScheduleTab />
        <CalendarTab />
        <TrendsLink />
        <AppointmentsLink />
        <MessagesTab />
        <MedicationsLink />
        <LaboratoriesLink />
        <DocumentsLink />
        <ScreeningsLink />
        <WellnessLink />
        <HealthScreeningLink
          enableOutboundPros={synopsisStore.enableOutboundPros}
        />
        <li className="SidebarNav-divider" />
        <EducationLink />
        <ElationLink />
        <BeWellLink />
      </ul>
    );
  },

  renderToggle() {
    if (!this.props.isTablet) return;

    let toggleClass = cx("Sidebar-toggle", {
      collapse: !this.isCollapsed(),
      reveal: this.isCollapsed(),
    });

    return (
      <button
        className={toggleClass}
        aria-label="Toggle sidebar"
        onClick={() => appStore.toggleSidebar()}
      />
    );
  },

  renderUserBlock() {
    let { me } = authStore;
    let site = me.defaultSite;

    return (
      <div className="SidebarUserBlock">
        <Link
          className="SidebarUserBlock-link"
          to={authStore.isProxying ? null : "/profile"}
          aria-label={`${me.name} Profile`}
        >
          {this.state.avatarUploading ? (
            <Spinner />
          ) : (
            <img
              className="SidebarUserBlock-avatar"
              src={me.avatarURL}
              alt=""
            />
          )}
          <div className="SidebarUserBlock-name">{me.name}</div>
        </Link>
        {site && <div className="SidebarUserBlock-site">{site.name}</div>}
      </div>
    );
  },

  render() {
    let { me } = authStore;
    let navClass = cx("SidebarNav", {
      proxying: authStore.isProxying,
    });

    return (
      <div className="Sidebar">
        {this.renderBrand()}
        {me.isAdmin && this.renderSiteSelect()}
        {me.isAdmin && (
          <PatientSearch modifier="sidebar" onSelect={this.proxyPatient} onPatientAdd={this.onPatientAdd} />
        )}
        <AddNewPatient isModalOpen={this.state.isNewPatientModalOpen} onClose={this.onPatientModalClose} />
        <nav className={navClass}>
          {this.renderNavHeader()}
          {this.renderNavLinks()}
        </nav>
        {this.renderToggle()}
        {this.renderUserBlock()}
        <button
          className="Sidebar-signOut"
          aria-label="Sign Out"
          onClick={this.signOut}
        >
          <i className="fa fa-sign-out" aria-hidden="true" />
          <span id="sign-out">Sign Out</span>
        </button>
      </div>
    );
  },
});

module.exports = withMediaQueries(withRouter(observer(Sidebar)), {
  isTablet: mdToLg,
});
