'use strict'
{getAvatarUrl, getRandomColor, getFirstChars} = require 'lib/utils'
{img, div} = Exim.DOM

Avatar = ({provider}) ->
  {avatar_url, name} = provider
  if avatar_url
    return img src: getAvatarUrl(avatar_url), alt: ''

  return div {
    className: 'DefaultAvatar no-image',
    style: {background: getRandomColor(name)},
    'aria-hidden': true,
  }, getFirstChars(name, true)

module.exports = Avatar
