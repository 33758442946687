const NDASH = "\u2013";
const BULLET = "\u2022";
const TIME_FORMAT = "h:mma";

const getPatientInfo = ({ patient }) => {
  return `${patient.name} ${BULLET} ${patient.shortDob} (${patient.shortGender})`;
};

const getUnBookedApptLines = (slot, nameAndDuration) => {
  let times = `${slot.startTime.format(
    TIME_FORMAT
  )} ${NDASH} ${slot.endTime.format(TIME_FORMAT)}`;

  return [
    `${nameAndDuration} ${BULLET} ${times}`,
    ...slot.appointments.map(getPatientInfo),
  ];
};

const getBookedApptLines = (slot, hideReason = false) => {
  let [appt] = slot.appointments;

  const { nameAndDuration } = appt.type;
  let lines = getUnBookedApptLines(slot, nameAndDuration);

  if (!hideReason && appt.reason) lines.push(`RFV: ${appt.reason}`);

  lines.push(appt.displayStatus);

  return lines;
};

export const getSlotTitle = (slot, opts = {}) => {
  let lines = [];

  if (slot.isBooked && (slot.multipleVisitTypesSelected || !slot.isGroup)) {
    lines = getBookedApptLines(slot, opts.hideReason);
  } else if (!slot.isBooked && slot.multipleVisitTypesSelected) {
    lines = slot.appointmentTypesList.map(
      ({ nameAndDuration }) => nameAndDuration
    );
  } else {
    const { nameAndDuration } = slot.appointmentType;
    lines = getUnBookedApptLines(slot, nameAndDuration);
  }

  return lines.join("\n");
};

export const getNotesTitle = (notes) => {
  let texts = notes.map((note) => `${BULLET} ${note.text}`).join("\n");
  return `Appointments Notes:\n${texts}`;
};
