"use strict";
const { appStore } = require("stores");
const { autorun } = require("mobx");
const { observer } = require("mobx-react");
const calStore = require("stores/cal");
const slotsStore = require("stores/new-slots");
const withIdleTimer = require("components/elements/with-idle-timer");
const ContentHeader = require("components/elements/content-header");
const Main = require("components/elements/main");
const PatientSearchHeader = require("components/elements/patient-search-header");
const Footer = require("components/app/Footer");

class Calendar extends React.Component {
  componentWillMount() {
    appStore.actions.setHeader(null);

    this.disposeFetchAutorun = autorun(() => {
      calStore.fetchSlots();
    });

    slotsStore.fetchConflicted();

    this.disposeOOOsAutorun = autorun(() => {
      calStore.fetchOOOs();
    });
  }

  componentWillUnmount() {
    this.disposeFetchAutorun();
    this.disposeOOOsAutorun();
  }

  render() {
    return (
      <div className="NewContent">
        <ContentHeader title="Calendar" />
        <Main className="ScheduleCalendar">
          <PatientSearchHeader />
          {this.props.children}
        </Main>
        {Footer()}
      </div>
    );
  }
}

module.exports = withIdleTimer(observer(Calendar));
