{ div, img, tch, p, span }  = Exim.DOM
{ healthScreening } = require('config')
{ getIdFromUrl } = require('lib/utils')

AMATHealthScreeningStatus = Exim.createView module.id,
  contextTypes:
    router: React.PropTypes.object.isRequired

  getHraImageUrl: (patient_screening, hasSurvey) ->
    switch patient_screening
      when 'active'
        return hasSurvey && '/combo/images/hra/icon-hra-incomplete.png'
      when 'incomplete'
        return hasSurvey && '/combo/images/hra/icon-hra-incomplete.png'
      when 'complete'
        return '/combo/images/hra/icon-hra-complete.png'
      when 'ineligible'
        return ''
      else
        return null

  renderHRAImage: (patient_screening, hasSurvey) ->
    router = @context.router
    routeID = getIdFromUrl(@props.wellnessProgramUrl)

    div className: 'HraScreeningContent-images',
      div className: 'HraScreeningContent-HraImageContent',
        img
          src: @getHraImageUrl(patient_screening, hasSurvey) || '',
          className: "HraScreeningContent-HraImageContent-ImageUrlClick",
          onClick: -> router.push "#{if hasSurvey then "survey/hra?id=#{routeID}" else "wellness/result?id=#{routeID}"}"

  render: ->
    { wellnessScreeningStatus, wellnessProgramInfo, patient_screening_status_msg } = @props
    { patient_screening } = wellnessScreeningStatus
    { stage } = wellnessProgramInfo

    hasSurvey = (patient_screening == 'incomplete' or patient_screening == 'active') and (stage == 'ongoing' or stage == 'extended')

    div className: 'HraHealthScreening',
      div className: 'HraScreeningContent',
        div className: 'HraScreeningContent-intro',
          div className: "#{"HraScreeningContent-intro-#{patient_screening}"}",
            "#{if hasSurvey then 'Click the icon below to complete your ' else ''}"
            p style: {margin: '0', fontWeight: 'bold'}, "#{if hasSurvey then 'Health Risk Assessment Survey' else ''}"
                p "#{if !hasSurvey then patient_screening_status_msg else ''}"
                if patient_screening == 'ineligible' and stage != 'ended'
                  p 'For questions, please contact Program Support at ',
                    tch tagName: 'a', href: 'mailto:wellnessincentive-amp@crossoverhealth.com', className: 'HraScreeningContent-intro-link',
                      'wellnessincentive-amp@crossoverhealth.com'
        @renderHRAImage(patient_screening, hasSurvey)

module.exports = AMATHealthScreeningStatus
