import { browserHistory } from "react-router";

const TITLES = {
  welcome: "Welcome",
  about: "About You",
  reason: "Reason for Visit",
  meds: "Medications",
  dental: "Dental Questionnaire",
  conditions: "Medical Conditions",
  lifestyle: "Lifestyle",
  patient_screenings: "Screenings",
  consents: "Consents",
  crossover: "Account",
  thanks: "Thanks",
};

class Step {
  constructor(id) {
    this.id = id;
  }

  get url() {
    return `/checkin/steps/${this.id}`;
  }

  get title() {
    return TITLES[this.id];
  }

  get isActive() {
    return location.pathname == this.url;
  }

  get isForm() {
    return !["welcome", "thanks"].includes(this.id);
  }

  activate() {
    browserHistory.push(this.url);
  }
}

export default Step;
