"use strict";
const { min, max } = require("lodash");
const isIntersecting = (a, b) => {
  return a.startTime < b.endTime && a.endTime > b.startTime;
};

class SlotGroup {
  constructor(slots) {
    this.slots = slots;
    this.startTime = min(slots.map((s) => s.startTime));
    this.endTime = max(slots.map((s) => s.endTime));
    this.duration = moment.duration(this.endTime - this.startTime);
  }

  mergeWith(group) {
    return new this.constructor([...this.slots, ...group.slots]);
  }
}

class SlotCluster extends SlotGroup {
  calcLanes() {
    let lanes = [];

    this.slots.forEach((slot) => {
      let notIntersecting = lanes.find((slots) => {
        return slots.every((s) => !isIntersecting(s, slot));
      });

      if (notIntersecting) {
        notIntersecting.push(slot);
      } else {
        lanes.push([slot]);
      }
    });

    return lanes.map((slots) => new SlotGroup(slots));
  }
}

const calcClusters = (slots) => {
  const clusters = [];
  const mergeAndPush = (cluster) => {
    let intersectingIndex = clusters.findIndex((c) =>
      isIntersecting(c, cluster)
    );
    if (intersectingIndex == -1) {
      clusters.push(cluster);
    } else {
      let [intersectingCluster] = clusters.splice(intersectingIndex, 1);
      mergeAndPush(cluster.mergeWith(intersectingCluster));
    }
  };

  slots.map((slot) => new SlotCluster([slot])).forEach(mergeAndPush);

  return clusters;
};

module.exports = calcClusters;
