"use strict";
const synopsisStore = require("stores/new-synopsis");
const authStore = require("stores/new-auth");
const { showModal } = require("components/elements/new-modal");
const ApptNotesModal = require("components/elements/appt-notes-modal");
const withMediaQueries = require("components/elements/with-media-queries");
const { smDown } = require("lib/media-queries");
const { observer } = require("mobx-react");
const { cx } = require("lib/utils");

const ApptNotesButton = ({ isPhone, appointment }) => {
  let { me } = authStore;
  if (isPhone || !me.isAdmin || !synopsisStore.hasScheduling) return null;

  let buttonClass = cx("ApptNotesButton", {
    hasNotes: appointment.notes.some((note) => note.isText),
  });

  let handleClick = (evt) => {
    evt.stopPropagation();

    showModal(
      <ApptNotesModal
        appointment={appointment}
        rect={evt.target.getBoundingClientRect()}
      />
    );
  };

  return (
    <button
      className={buttonClass}
      aria-label="appointment notes"
      onClick={handleClick}
    />
  );
};

module.exports = withMediaQueries(observer(ApptNotesButton), {
  isPhone: smDown,
});
