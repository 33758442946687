"use strict";
module.exports = {
  xsDown: "(max-width: 374px)",
  xsToSm: "(min-width: 375px) and (max-width: 575px)",
  xsUp: "(min-width: 375px)",

  smDown: "(max-width: 575px)",
  smToMd: "(min-width: 576px) and (max-width: 767px)",
  smUp: "(min-width: 576px)",

  mdDown: "(max-width: 767px)",
  mdToLg: "(min-width: 768px) and (max-width: 991px)",
  mdToXl: "(min-width: 768px) and (max-width: 1199px)",
  mdUp: "(min-width: 768px)",

  lgDown: "(max-width: 991px)",
  lgToXl: "(min-width: 992px) and (max-width: 1199px)",
  lgUp: "(min-width: 992px)",

  xlUp: "(min-width: 1200px)",
};
