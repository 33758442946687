import { cx } from "lib/utils";

const BOX_LINE = "\u2500";

class NativeSelect extends React.Component {
  componentDidMount() {
    this.updateText();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value != prevProps.value) {
      this.updateText();
    }
  }

  updateText() {
    if (this.props.value) {
      let [$option] = this.$select.selectedOptions;
      if (this.props.showplaceholder === "true") {
        this.$text.textContent = ($option ? $option.text : "") || this.props.placeholder;
      } else {
        this.$text.textContent = $option ? $option.text : "";
      }
    }
  }

  renderText() {
    let { className, value, placeholder } = this.props;

    return (
      <span
        className={`${className}-text`}
        aria-hidden="true"
        ref={(el) => (this.$text = el)}
      >
        {value || (
          <span className={`${className}-placeholder`}>{placeholder}</span>
        )}
      </span>
    );
  }

  renderPlaceholderOptions() {
    let { value, placeholder } = this.props;
    if (!value) {
      return (
        <React.Fragment>
          <option>{placeholder}</option>
          <option disabled aria-hidden="true">
            {BOX_LINE.repeat(placeholder.length / 2)}
          </option>
        </React.Fragment>
      );
    }
  }

  render() {
    let { className, modifier, placeholder, ...selectProps } = this.props;

    let wrapperClass = cx(className, modifier, {
      disabled: this.props.disabled,
    });

    return (
      <div className={wrapperClass}>
        {this.renderText()}
        <select
          {...selectProps}
          ref={(el) => (this.$select = el)}
          aria-label={this.props["aria-label"] || placeholder}
        >
          {placeholder && this.renderPlaceholderOptions()}
          {this.props.children}
        </select>
      </div>
    );
  }
}

NativeSelect.defaultProps = {
  className: "NativeSelect",
  value: "",
  placeholder: "",
  showplaceholder: "false"
};

export default NativeSelect;
