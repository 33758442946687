ReactDOM = require 'react-dom'
ReactSelect = require('react-select').default
ReactSelectValue = require('react-select').Value
{isEmpty}     = require 'lodash'
{cx} = Exim.helpers
{fa, div, span} = Exim.DOM
name = 'recipients-select'

# To allow recipient "x" icon focus
class FocusableValue extends ReactSelectValue
  renderRemoveIcon: () ->
    return if this.props.disabled or !this.props.onRemove
    span
      className: 'Select-value-icon'
      tabIndex: -1
      'aria-hidden': true
      onMouseDown: this.onRemove
      onKeyDown: this.onKeyDown
      onTouchEnd: this.handleTouchEndRemove
      onTouchStart: this.handleTouchStart
      onTouchMove: this.handleTouchMove
      '\u{00D7}'

  onKeyDown: (e) =>
    return unless e.keyCode is 13 or e.keyCode is 32
    e.preventDefault()
    e.stopPropagation()
    this.onRemove(e)

RecipientsList = Exim.createView module.id,
  propTypes:
    recipients: React.PropTypes.array

  componentDidMount: ->
    activeDescendant = document.querySelector('#react-select-2--value')
    activeDescendant?.setAttribute 'aria-label', 'Choose a recipient'

  componentWillReceiveProps: (newProps) ->
    if !isEmpty(newProps.value)
      document.querySelector('#react-select-2--value')?.setAttribute 'role', 'listbox'

  getDefaultProps: ->
    multi: true
    placeholder: ''

  focus: ->
    @refs.select?.focus()

  onChange: (income, total) ->
    @props.onChange?(income, total)

  value: (rec) ->
    if fn = @props.idPath then fn(rec) else rec

  label: (rec) ->
    if fn = @props.label then fn(rec) else rec
  
  # needed for workaround for aria-expanded
  onOpen: ->
    alert = document.querySelector('.hidden')
    alert.setAttribute('aria-hidden', false)
    alert.innerHTML = 'Choose a recipient list box is open'

    menu = document.querySelector('.Select-multi-value-wrapper')
    menu.scrollIntoView()

  onClose: ->
    alert = document.querySelector('.hidden')
    alert.innerHTML = 'Choose a recipient list box is closed'

  onBlur: ->
    alert = document.querySelector('.hidden')
    alert.setAttribute('aria-hidden', true)

  render: ->
    {recipients, value, disabled, multi, placeholder, description} = @props
    warnings = (value or [])
      .filter((rec) -> rec.inactive)
      .map((rec) -> "#{rec.name} has an inactive account.")
      .map (warning) ->
        div className: "InactiveWarning", key: "icon-#{warning}",
          fa 'exclamation-triangle'
          span warning

    recipients.forEach (rec) =>
      ['value', 'label'].forEach (name) =>
        rec[name] = @[name](rec)

    options = recipients

    div className: "RecipientList #{cx 'is-disabled': disabled}",
      React.createElement ReactSelect, {
        ref: 'select'
        name
        placeholder
        options
        multi
        @onChange
        value
        disabled
        tabIndex: (if disabled then "-1" else "0")
        clearable: false
        'aria-label': description
        valueComponent: FocusableValue
        @onOpen
        @onClose
      }
    
      warnings
      # needed for workaround for aria-expanded
      div className: 'hidden', 'aria-live': 'assertive', 'aria-hidden': true, 'Choose a recipient list box is open'

module.exports = RecipientsList
