ReactDOM       = require 'react-dom'
{observer}     = require 'mobx-react'
{appStore, authStore, screenStore, checkinStore,
appointmentsStore, chargesStore, synopsisStore, messagesStore} = require 'stores'
newAuthStore   = require('stores/new-auth')
apptsStore     = require 'stores/new-appts'
Note           = require 'models/appointment-note'
media          = require 'components/mixins/MediaQuery'
screenConfig   = require 'components/screen/config'
StripeModal    = require 'components/schedule/StripeModal'
PaymentOwedModal  = require 'components/schedule/PaymentOwedModal'
ConfirmationModal = require 'components/elements/ConfirmationModal'
{showModal}       = require 'components/elements/new-modal'
CancelApptModal   = require 'components/elements/cancel-appt-modal'
showVerifyPatientModal = require 'components/elements/VerifyPatientModal'
ActionDropdown = require 'components/elements/ActionDropdown'
ApptNotesButton = require 'components/elements/appt-notes-button'
VideoVisitButton = require './video-visit-button'
PayLaterModal  = require './pay-later-modal'
config         = require 'config'
confirm        = require 'lib/confirm'
{mem}          = require 'lib/utils'
request        = require 'lib/request'
{cx}           = Exim.helpers

{features, formats} = config
{div, spinner, textarea, tch, icn, b, span, button, a, i, fa, ul, li, p, h3, img} = Exim.DOM

# TODO: `/schedule` should return same structure as `/slots`
getNiceAppt = mem((uglyAppt) =>
  {site} = require 'stores/new-synopsis'
  Appointment = require 'models/appointment'

  rawAppt = Object.assign({
    clinic_id: site.id,
    no_show: uglyAppt.no_show,
    patient_info: {
      id: uglyAppt.patient_id,
      name: uglyAppt.name,
    },
  }, uglyAppt)

  return Appointment.fromAPI(rawAppt)
)

EHR_SYNC_STATUS_LABEL = {
  "pending": "EHR sync pending",
  "patient_not_in_practice": "Patient's EHR chart belongs to a different practice",
  "other_error": "EHR sync error",
  "skipped_patient_creation": "Patient's EHR chart is not yet created"
}

AUDIO_TITLE = 'Hearing Accommodation'
AUDIO_DESC = 'Please be prepared for assitance with American Sign Language or handwritten notes between the patient, yourself and the provider'
VISUAL_TITLE = 'Vision Accommodation'
VISUAL_DESC = 'Please be prepared to assist the patient with written or printed materials'
TRANSLATION_TITLE = 'Interpreter Requested'

Appointment = React.createClass({
  displayName: 'Appointment',
  mixins: [
    authStore.connect('user', 'impersonationLoading'),
    synopsisStore.connect('site', 'xo_scheduling'),
    media({isPhone: 'phone', isPrint: 'print'}),
  ],

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    activeBar: 'reason'
    reason: ''
    amount: null
    status: @props.appointment.status
    expanded: @props.expanded
    owedModal: false
    unpaidCharges: @props.appointment.unpaid_charges or []
    unpaid: false

  componentDidMount: ->
    newAuthStore.fetchFeatureFlags()

  componentWillUpdate: (newProps, newState) ->
    if @props.appointment?.action_status isnt newProps.appointment?.action_status
      newState.status = newProps.appointment?.action_status
    if (newProps.expanded isnt @state.expanded) and newProps.updateExpanded
      newState.expanded = newProps.expanded

  update: (providerId, id, amount, paid) ->
    if amount
      appointmentsStore.actions.setAmount providerId, id, amount
      amount = if typeof amount is 'number' then amount / 100 else amount
      @setState {amount}
    else if paid
      @setState paid: true
    apptsStore.fetchSchedule()

  proxy: (evt) ->
    @stopPropagation(evt)

    {appointment} = @props
    {id, patient_id, user_id, dob, email, name, sexage, patient_id, appointment_at_utc} = appointment

    [age, sex] = sexage?.split(' ')
    age = age.replace('y', ' years')

    patient =
      id: user_id
      name: name
      outside_id: patient_id
      gender: sex
      dob: dob
      age: age

    messagesStore.actions.clear()
    authStore.actions.preloadImpersonation(patient)
    authStore.actions.startImpersonation(user_id, id, appointment_at_utc).then =>
      messagesStore.actions.fetchMessages()
      @context.router.push {pathname: 'profile'}

  close: (evt) ->
    appStore.actions.showModal(null)

  forceCheckIn: ->
    confirm('Are you sure you would like to force checkin this patient?', null, 'Confirm').then (confirmed) =>
      return unless confirmed
      apptsStore.forceCheckIn({ id: @props.appointment.id, router: @context.router })

  revokeCheckin: ->
    confirm('Are you sure you would like to revoke the checkin for this appointment?', null, 'Confirm').then (confirmed) =>
      return unless confirmed
      apptsStore.revokeCheckIn({ id: @props.appointment.id })

  oneTimePayment: (evt) ->
    amount = 0
    {id, patient_id, user_id, summary, email, name, sexage, dob, patient_id} = @props.appointment
    @stopPropagation(evt)

    if user_id
      site = synopsisStore.get('site')?.code
      currency = if site is 'crk' then '€' else '$'

      promise = if user_id then chargesStore.actions.fetchPaymentData(user_id) else Promise.resolve()
      promise.then =>
        {digits} = if user_id then chargesStore.get('paymentData') else {}
        body = React.createElement(StripeModal, {id, patient_id, providerId: @props.providerId, amount, user_id, summary, email, digits, @close, @update, once: true, currency, oneTimePayment: true, name, dob, sexage})
        modal =
          badge: a href: 'https://stripe.com', className: 'badge stripe-badge'
          className: 'Modal--payment'
          body: body
          noClose: true
          shouldCloseOnOverlayClick: false

        appStore.actions.showModal(modal)
    else
      text = "To perform a One-Time Payment for this newly registered patient, you must first perform a Check-In to add the patient to the portal."
      title = "One-Time Payment"
      confirmText = "Close"
      body = ConfirmationModal {title, text, confirmText, onConfirm: @close}

      modal =
        className: 'Modal--small'
        darker: true
        body: body

      appStore.actions.showModal(modal)

  showStripeModal: (evt) ->
    return if @checkinRestricted()
    site = synopsisStore.get('site')?.code
    currency = if site is 'crk' then '€' else '$'
    appleSites = ['crk', 'scv', 'prk', 'wfe']
    paymentDisabled = !appStore.get('config')?.token or config.features?.appleDisablePayments and site in appleSites

    amount = @getAmount()

    {id, patient_id, user_id, summary, email, name, sexage, dob, patient_id} = @props.appointment
    @stopPropagation(evt)

    promise = if user_id then chargesStore.actions.fetchPaymentData(user_id) else Promise.resolve()
    promise.then =>
      {digits} = if user_id then chargesStore.get('paymentData') else {}
      body = React.createElement(StripeModal, {id, patient_id, providerId: @props.providerId, amount, user_id, summary, email, digits, @close, @update, paymentDisabled, currency, oneTimePayment: false, name, dob, sexage, unpaidCharges: @state.unpaidCharges})
      modal =
        badge: a href: 'https://stripe.com', className: 'badge stripe-badge'
        className: 'Modal--payment'
        body: body
        noClose: true

      appStore.actions.showModal(modal)

  checkinRestricted: ->
    user = authStore.get('user')
    return user.jail & 2048

  getAmount: ->
    if @state.amount?
      {amount} = @state
    else
      {owed} = @state.amount or @props.appointment
      amount = owed.replace(/[^.0-9]+/g, '')
    amount = if amount then parseFloat(amount) * 100 else 0

  selectBar: (activeBar) -> (evt) =>
    @stopPropagation(evt)
    activeBar = if @state.activeBar is activeBar then 'reason' else activeBar
    @setState {activeBar}

  onAction: (type) ->
    {appointment} = @props

    data = {
      appointment_id: appointment.id,
      patient_id: appointment.patient_id,
      reason: appointment.notes,
    }

    switch type
      when 'verify_and_checkin'
        showVerifyPatientModal(appointment.email, appointment.patient_id)
          .then => @onAction('checkin')
          .catch ->
      when 'checkin', 'review', 'forced_checkin'
        data.reason = appointment.notes
        data.summary = appointment.summary

        apptsStore.clearForCheckin().then =>
          checkinStore.actions.changeState('welcome', data).then =>
            @context.router.push '/checkin/steps/welcome'
      when 'click'
        data.one_click = true
        checkinStore.actions.create(data).then =>
          apptsStore.fetchSchedule()
      when 'passed'
        this.showCancelModal('no_show')
      when 'screen'
        screenStore.actions.setup(data.patient_id, data.appointment_id, appointment.appointment_at_utc).then =>
          @context.router.push "/checkin/screen/#{screenConfig.firstStep}"

  addData: (evt) ->
    @stopPropagation(evt)
    {appointment, provider} = this.props

    data = {
      appointment_id: appointment.id,
      appointment_at: appointment.appointment_at_utc,
      patient_id: appointment.patient_id,
    }

    additional_data = {
      summary: appointment.summary,
      providerData: {name: provider.name},
    }
    screenStore.actions.setup(data.patient_id, data.appointment_id, appointment.appointment_at_utc, additional_data).then =>
      authStore.actions.startImpersonation(appointment.user_id, appointment.id, data.appointment_at).then =>
        @context.router.push "/checkin/screen/#{screenConfig.firstStep}"

  showPayLaterModal: ->
    modal = React.createElement(PayLaterModal, {
      appointment: getNiceAppt(this.props.appointment),
    })

    showModal(modal).then(() =>
      # TODO: no need to refetch the schedule
      apptsStore.fetchSchedule()
      this.setState({unpaid: true})
    )

  stopPropagation: (evt) ->
    evt.preventDefault()
    evt.stopPropagation()

  showCancelModal: (type) ->
    modal = React.createElement(CancelApptModal, {
      appointment: getNiceAppt(this.props.appointment),
      type,
    })

    showModal(modal).then(() =>
      # TODO: no need to refetch the schedule
      apptsStore.fetchSchedule()
    )

  getSummary: ->
    {appointment} = @props
    if appointment.summary_sub
      appointment.summary_sub.replace(/\(.*\)/g, '')
    else
      appointment.summary

  getActionClass: (status) ->
    user = authStore.get('user')
    isForceEnabled = @state.xo_scheduling and
      user.admin and
      @props.appointment.display isnt 'complete' and
      status isnt 'verify_and_checkin'
    switch status
      when 'verify_and_checkin' then 'verify-and-checkin'
      when 'forced_checkin' then 'forced-checkin'
      when 'checkin'
        if isForceEnabled or !@state.xo_scheduling
          'checkin'
        else
          'review'
      when 'review' then 'review'
      when 'click' then 'one-click'
      when 'screen' then 'screen'
      when 'passed' then 'review'

  getActionValues: (status) ->
    user = authStore.get('user')
    {features} = config
    isTBD = @props.appointment?.owed?.includes 'TBD'
    isUnpaid = @state.unpaid or @state.unpaidCharges?.filter((x) => x.appointment_id is @props.appointment.id)[0]?.status is 'unpaid'
    isForceEnabled = user.admin and
      @state.xo_scheduling and
      @props.appointment.display isnt 'complete' and
      status isnt 'verify_and_checkin'
    isRevokeEnabled = user.admin and
      @state.xo_scheduling and
      @props.appointment.display is 'complete' and
      @props.appointment.can_revoke_checkin and
      features.xoCalRevokeCheckin
    name =
      switch status
        when 'verify_and_checkin' then 'Verify + Checkin'
        when 'checkin' then 'Checkin'
        when 'forced_checkin' then 'Finalize Checkin'
        when 'review' then 'Review'
        when 'click' then '1-Click'
        when 'screen' then '1-Click'
        when 'passed' then 'No Show'

    actionValue = {name, handler: => @onAction(status)}
    actionValue.icon = 'hand-o-up' if status in ['click', 'screen']

    site = synopsisStore.get('site')?.code
    appleSites = ['crk', 'scv', 'prk', 'wfe']
    isPaymentDisabled = !appStore.get('config')?.token or config.features?.appleDisablePayments and site in appleSites
    actionValues = [actionValue]
    actionValues.push {name: 'Checkin', handler: => @onAction('checkin')} if status is 'passed'
    actionValues.push {name: 'Screen', handler: @addData}
    actionValues.push {name: 'One-time payment', handler: @oneTimePayment} unless isPaymentDisabled
    actionValues.push {name: 'Pay Later', handler: () => this.showPayLaterModal()} unless isPaymentDisabled or @state.paid or @props.appointment.paid or isTBD or isUnpaid
    actionValues.push {name: 'Cancel', handler: () => this.showCancelModal()}
    actionValues.push {name: 'Late Cancel', handler: () => this.showCancelModal('late_cancellation')} if user.admin and this.state.xo_scheduling
    actionValues.push {name: 'No Show', handler: () => this.showCancelModal('no_show')} if user.admin and this.state.xo_scheduling
    actionValues.push {name: 'Force Checkin', handler: @forceCheckIn} if isForceEnabled
    actionValues.push {name: 'Revoke Checkin', handler: @revokeCheckin} if isRevokeEnabled
    actionValues

  renderAction: ->
    status = @state.status or @props.appointment.action_status
    actionClass = @getActionClass(status)
    dailyScheduleActionClass = "DSActionDropdown--#{actionClass}"

    return ActionDropdown
      className: "ActionDropdown--#{actionClass} #{dailyScheduleActionClass}"
      values: @getActionValues(status)
      withGlobalClick: true
      needsTwoTabs: true

  renderIcons: ->
    {appointment} = this.props
    {accommodations, preferred_language} = appointment

    return React.createElement(React.Fragment, null,
      appointment.rookie &&
        span className: 'AppointmentItem-icon AppointmentItem-icon-rookie', title: 'New patient'
      appointment.traveler &&
        span className: 'AppointmentItem-icon AppointmentItem-icon-traveler', title: 'Traveler'
      accommodations.includes('audio') &&
        span className: 'AppointmentItem-icon AppointmentItem-icon-audio', title: "#{AUDIO_TITLE}: #{AUDIO_DESC}"
      accommodations.includes('visual') &&
        span className: 'AppointmentItem-icon AppointmentItem-icon-visual', title: "#{VISUAL_TITLE}: #{VISUAL_DESC}"
      accommodations.includes('translation') &&
        span className: 'AppointmentItem-icon AppointmentItem-icon-translation', title: "#{TRANSLATION_TITLE}: #{preferred_language}"
      appointment.locked == 'vip' &&
        span className: 'AppointmentItem-label', 'VIP'
      appointment.locked == 'xo' &&
        span className: 'AppointmentItem-label', 'XO'
      appointment.xop &&
        span className: 'AppointmentItem-label-xop', 'XOP'
    )

  toggleOwedModal: (e) ->
    e.stopPropagation()
    @setState {owedModal: !@state.owedModal}

  getPronouns: (pronouns) ->
    pronounsMapping = 
      she_her_hers: 'She/Her'
      he_him_his: 'He/Him'
      they_them_theirs: 'They/Them'
      option_not_listed: 'Pronoun option not listed (check member chart)'
    pronounsMapping[pronouns] or null
  
  getMemberInfo: (featureFlag, sexage, pronouns) ->
    if !featureFlag and sexage
      " (#{sexage})"
    else if featureFlag and sexage and pronouns
      " (#{sexage}#{", " + pronouns})"
    else if featureFlag and sexage and !pronouns
      " (#{sexage})"
    else if featureFlag and !sexage and pronouns
      " (#{pronouns})"
    else
      ''

  render: ->
    {appointment} = @props
    {id, accommodations, preferred_language, video_conference} = appointment
    isExpanded = @state.expanded
    {featureFlagsLoaded} = newAuthStore

    zone = @state.site?.zone
    site = synopsisStore.get('site')?.code
    currency = if site is 'crk' then '€' else '$'

    reason = appointment.notes
    bottomUser = if !appointment.user_id then 'New Patient' else ''

    checkinRestrictedClass = if @checkinRestricted() then '-checkin-restricted' else '-checkin-unrestricted'
    videoConferenceClass = if video_conference?.url? then '' else 'video-conference-missing'
    hraBalance = appointment?.hra_balance

    if (am = @state.amount)?
      owed = if typeof am is 'number' then "#{currency}#{am} (set)" else '(TBD no plan)'
    else
      {owed} = appointment
      owed = owed.replace('$', '€') if site is 'crk'

    args =
      ref: 'scheduleItem'
      className: "ProviderSchedule-item TableBody-row #{cx 'is-expanded': isExpanded}"
      'data-e2e': appointment.id
      onClick: () => @setState {expanded: !@state.expanded}
      style: @props.style

    [age, sex] = appointment.sexage?.split(' ')
    if age and sex
      age = age.replace('y', ' yrs')
      sexage = "#{age}, #{sex}"

    displayPronouns = @getPronouns(appointment.pronouns)

    memberInfo = @getMemberInfo(appointment.can_see_sogi, sexage, displayPronouns)

    displayPatientName = if appointment.legal_and_preferred_name then appointment.legal_and_preferred_name else appointment.name

    starts_at = moment.tz(appointment.appointment_at, zone).format(formats.scheduleTimeFormat)
    sync_status_label = EHR_SYNC_STATUS_LABEL[appointment.ehr_sync_status]

    return div args,
      div className: 'AppointmentItem', 'data-e2e': id,
        div className: 'TableBody-column AppointmentItem-icons',
          this.renderIcons()
        div className: 'TableBody-column AppointmentItem-time-duration',
          div className: 'AppointmentItem-time-duration-core',
            div className: 'AppointmentItem-time',
              starts_at
            div className: 'AppointmentItem-duration',
              "#{appointment.duration} mins"
          if features.showEHRSyncStatus and site isnt 'crk' and appointment.ehr_sync_status not in ["none", "success"]
            div className: "AppointmentItem-sync-status AppointmentItem-sync-status-#{appointment.ehr_sync_status}",
              i className: "fa fa-exchange", 'aria-label': sync_status_label

        div className: 'TableBody-column AppointmentItem-patient',
          div className: 'AppointmentItem-patient-title',
            "#{displayPatientName}"
            "#{memberInfo}" if featureFlagsLoaded
          div className: 'AppointmentItem-client', appointment.client if appointment.client
        div className: 'TableBody-column AppointmentItem-spacer'
        div className: 'TableBody-column AppointmentItem-payment',
          if @state.unpaidCharges?.filter((x) -> x.status is 'unpaid').length
            div className: 'AppointmentItem-owed-icon', onClick: @toggleOwedModal,
              fa 'shopping-cart'
              PaymentOwedModal {unpaidCharges: @state.unpaidCharges, close: (x) => @setState {owedModal: false}} if @state.owedModal
          else
            div className: 'AppointmentItem-owed-icon', style: {minHeight: '1px'}
          div style: {display: 'inline-block', maxWidth: '80%'},
            if @state.paid or appointment.paid
              div className: 'AppointmentItem-sum',
                span 'Paid'
                span ": #{owed}" if appointment.owed
            else if @state.unpaid or @state.unpaidCharges?.filter((x) => x.appointment_id is @props.appointment.id)[0]?.status is 'unpaid'
              div className: 'AppointmentItem-sum', onClick: @showStripeModal,
                span 'Unpaid'
            else
              div className: 'AppointmentItem-sum',
                tch className: 'AppointmentItem-owed', key: owed, handler: @showStripeModal, owed
            div className: 'AppointmentItem-type',
              @getSummary()
            div className: 'AppointmentItem-hra',
              if hraBalance then "HRA Balance: #{currency}#{hraBalance}"
        div className: "TableBody-column AppointmentItem-action#{checkinRestrictedClass} #{videoConferenceClass}", key: 'last-action',
          if video_conference?.url?
            div className: "AppointmentItem-action-video-visit",
              React.createElement(VideoVisitButton, {video_conference})
          unless @checkinRestricted()
            @renderAction()
        div role: 'cell', className: 'AppointmentItem-notes TableBody-column',
          React.createElement(ApptNotesButton, {
            appointment: getNiceAppt(this.props.appointment),
          })
      tch className: "AppointmentDropdown #{cx 'has-user': appointment.user_id, 'has-reason': !!appointment.notes, 'is-expanded': isExpanded}", handler: @stopPropagation,
        div className: 'AppointmentDropdown-reason',
          div className: 'AppointmentDropdown-reason-leftSpace'
          div className: 'AppointmentDropdown-reason-columnReason',
            reason && site != 'crk' && p className: 'AppointmentDropdown-accomodation',
              b 'Chief Complaint:'
              reason
            accommodations.includes('audio') && p className: 'AppointmentDropdown-accomodation',
              b "#{AUDIO_TITLE}:"
              AUDIO_DESC
            accommodations.includes('visual') && p className: 'AppointmentDropdown-accomodation',
              b "#{VISUAL_TITLE}:"
              VISUAL_DESC
            accommodations.includes('translation') && p className: 'AppointmentDropdown-accomodation',
              b "#{TRANSLATION_TITLE}:"
              preferred_language
          div className: 'AppointmentDropdown-reason-spacer'
          div className: 'AppointmentDropdown-reason-columnInfo',
            if appointment.dob
              div className: 'AppointmentDropdown-reason-row',
                div className: 'AppointmentDropdown-reason-title', 'DOB:'
                div className: 'AppointmentDropdown-reason-text', appointment.dob
            if appointment.employee_id
              div className: 'AppointmentDropdown-reason-row',
                div className: 'AppointmentDropdown-reason-title', 'Employee ID:'
                div className: 'AppointmentDropdown-reason-text', appointment.employee_id
            if appointment.insurance_id
              div className: 'AppointmentDropdown-reason-row',
                div className: 'AppointmentDropdown-reason-title', 'Ins. ID:'
                div className: 'AppointmentDropdown-reason-text', appointment.insurance_id
            if features.showEHRSyncStatus and site isnt 'crk' and appointment.ehr_sync_status not in ["none", "success"]
              div className: 'AppointmentDropdown-reason-row',
                div className: 'AppointmentDropdown-reason-title', 'EHR Sync:'
                div className: 'AppointmentDropdown-reason-text', sync_status_label

          div className: 'AppointmentDropdown-reason-rightSpace'
          div className: 'AppointmentDropdown-reason-columnReason AppointmentDropdown-reason-last',
            div key: 'reason-title', className: 'AppointmentDropdown-reason-title', bottomUser
        if appointment.user_id and !features.apptsOnly
          div className: 'AppointmentDropdown-footer',
            if @state.impersonationLoading
              div key: 'link-profile', className: 'Button Button--plainText',
                div className: 'app-spinner-outer-content',
                  div className: 'app-spinner-outer',
                    spinner()
            else
              tch key: 'link-profile', className: 'Button Button--plainText', handler: @proxy,
                'View Patient Profile'
                fa 'caret-right'
            if features.newApi
              tch className: "AppointmentDropdown-link delete #{cx active: @state.activeBar is 'remove'}", handler: @selectBar('remove'),
                icn 'trash-a'
})

module.exports = observer(Appointment)
