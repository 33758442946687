import MenuItem from "@material-ui/core/MenuItem";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import utils from "lib/utils";

class ProviderSubMenu extends React.Component {
  renderProviderOptions = () => {
    const { menuItems, handleSubMenuClick } = this.props;

    return menuItems.map((item) => {
      const avatarURL = utils.getAvatarUrl(
        !item.shot || item.shot === "/images/team/photo-default.jpg"
          ? "/combo/images/team/photo-default.jpg"
          : item.shot
      );

      return (
        <MenuItem
          key={item.name}
          className="MenuItem-submenu"
          value={item.name}
          onClick={handleSubMenuClick(item)}
        >
          <img className="MenuItem-avatar" src={avatarURL} alt="" />
          <span className="MenuItem-provider">{item.name}</span>
        </MenuItem>
      );
    });
  };

  render() {
    const { careTeamsStore, onClose, schedulestore, shot, title } = this.props;
    const CTshot = utils.getAvatarUrl("/combo/images/team/photo-default.jpg");

    const handleClick = (event) => {
      const item = {
        name: event.currentTarget.innerText,
        shot: CTshot,
      };

      schedulestore.setIsCtOnly(true);
      careTeamsStore.setSelectedProvider(item);
      onClose();
    };

    return (
      <React.Fragment>
        <ExpansionPanel
          id="schedule-provider-select"
          className="Expandable-menu"
        >
          <ExpansionPanelSummary className="Expandable-menu">
            <Typography component={"span"}>
              <MenuItem className="list-item">
                <div>
                  <img
                    className="MenuItem-avatar Submenu-avatar"
                    src={shot}
                    alt=""
                  />
                  {title}
                </div>
                <i className="fa icon fa-caret-down Provider-droprightIcon"></i>
              </MenuItem>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="ExpandableMenu-details">
            <Typography>
              {title === "Care Team" && (
                <MenuItem className="MenuItem-submenu" onClick={handleClick}>
                  <img
                    className="MenuItem-avatar Submenu-avatar"
                    src={CTshot}
                    alt=""
                  />
                  Any Care Team Provider
                </MenuItem>
              )}

              {this.renderProviderOptions()}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
  }
}

export default ProviderSubMenu;
