"use strict";
const synopsisStore = require("stores/new-synopsis");
const authStore = require("stores/new-auth");
const Model = require("./base");
const Note = require("./appointment-note");
const Patient = require("./patient");
const { computed, timezone } = require("lib/utils");
const { now } = require("mobx-utils");
const { flatten } = require("lodash");

const byCreatedAtDesc = (a, b) => b.createdAt - a.createdAt;

class Appointment extends Model {
  static fromAPI(raw) {
    let site =
      synopsisStore.findSite(raw.clinic_id) ||
      synopsisStore.sites.find((s) => s.code == raw.site_code) ||
      synopsisStore.site;

    let { zone } = site;
    let zoneAbbr = timezone(zone);
    let type =
      site.findApptType(raw.appointment_type) ||
      synopsisStore.findApptType(raw.appointment_type);

    let provider = site.getProvider(raw.provider_id) || {
      name: raw.provider,
      id: raw.provider_id,
    };

    return super.fromAPI({
      id: raw.id,
      site,
      patient: raw.patient_info
        ? Patient.fromAPI(raw.patient_info)
        : authStore.user.toJSON(),
      provider: provider,
      zoneAbbr: zoneAbbr,
      type,
      typeName: raw.subtype || raw.type || (type || {}).name,
      notes: (raw.appointment_notes || []).map((note) => Note.fromAPI(note)),
      reason: raw.reason,
      cancelReason: raw.cancel_reason,
      status: (raw.status || "").toLowerCase(),
      isNoShow: raw.no_show,
      disposition: raw.disposition,
      isConflicted: raw.conflicted || false,
      patientStatus: raw.patient_status,
      startAt: moment.tz(raw.start_at || raw.starts_at, zone),
      endAt: moment.tz(raw.end_at || raw.ends_at, zone),
      checkInStatus: raw.checkin_status || raw.checkin,
      owed: raw.owed || raw.amount,
      method: raw.method,
      covid_risk_score: raw.covid_risk_score,
      video_conference: raw.video_conference,
      component_id: raw.component_id,
    });
  }

  static forSlot(slot) {
    return new this({
      slot,
      site: slot.site,
      provider: slot.provider,
      type: slot.appointmentType,
    });
  }

  constructor(params) {
    super({
      site: null,
      patient: null,
      provider: null,
      type: null,
      notes: [],
      reason: "",
      status: null,
      disposition: null,
      patientStatus: null,
      isConflicted: false,
      isNoShow: false,
      slot: null,
      ...params,
    });

    this.notes.forEach((note) => {
      note.appointment = this;
    });
  }

  async post() {
    await super.post({
      path: `/v1/slots/${this.slot.id}/appointment`,
    });

    this.slot.appointments.push(this);
  }

  async keep() {
    this.isConflicted = false;
    await this.patch();
  }

  async getCancellationPolicy() {
    return this.get({
      path: "&/cancellation_policy",
      data: null,
    });
  }

  async cancel(params) {
    await this.update({
      data: {
        id: this.id,
        patient_id: this.patient.id,
        ...params,
      },
    });

    this._removeFromSlot();
  }

  async patch() {
    return super.patch({
      data: {
        appointment_type: this.type.id,
        reason: this.reason,
        conflicted: this.isConflicted,
      },
    });
  }

  toJSON() {
    return {
      ...super.toJSON(),
      patient_info: this.patient.toJSON(),
      patient_id: this.patient.id,
      provider_id: this.provider.id,
      rendering_prov: this.provider.id,
      appointment_type: this.type.id,
      appointment_notes: this.notes.map((note) => note.toJSON()),
      clinic_id: this.site.id,
      reason: this.reason,
      cancel_reason: this.cancelReason,
      start_at: this.startAt,
      end_at: this.endAt,
      slot_id: this.slot.id,
      conflicted: this.isConflicted,
      checkin_status: this.checkInStatus,
      owed: this.owed,
      no_show: this.isNoShow,
      method: this.method,
      covid_risk_score: this.covid_risk_score,
      video_conference: this.video_conference,
    };
  }

  updateFromAPI(raw) {
    this.id = raw.id;
  }

  _removeFromSlot() {
    if (!this.slot) return;

    let index = this.slot.appointments.findIndex((appt) => appt.is(this));
    if (index == -1) return;

    this.slot.appointments.splice(index, 1);
    if (this.isConflicted && !this.slot.isBooked) {
      const slotsStore = require("stores/new-slots");
      slotsStore.deleteSlot(this.slot);
    }
  }

  get isStarted() {
    return this.startAt >= moment();
  }
}

Appointment.endPoint = "/v1/appointments";

computed(Appointment.prototype, {
  get duration() {
    return moment.duration(this.endAt - this.startAt);
  },

  get latestNotes() {
    return [...this.notes].sort(byCreatedAtDesc);
  },

  get displayStatus() {
    switch (this.disposition) {
      case "cancel":
      case "clinic_cancel":
        return "Canceled";

      case "complete":
        return "Completed";
      case "no_show":
        return "No Show";
      case "provider_unavailable":
        return "Provider Unavailable";
    }

    switch (this.patientStatus) {
      case "checked_in":
        return "Checked In";
      case "in_room":
        return "In Room";
    }

    return "Scheduled";
  },

  get isCompletedButBeforeTime() {
    const nowMoment = moment(new Date(now())).tz(this.site.zone);
    return (
      this.status === "completed" &&
      nowMoment.isBefore(this.endAt) &&
      (this.method === "phone" || this.method === "video")
    );
  },

  get isScheduled() {
    return this.status
      ? this.status === "scheduled" || this.isCompletedButBeforeTime
      : this.disposition == null;
  },

  get isCompleted() {
    return this.status
      ? this.status === "completed" && !this.isCompletedButBeforeTime
      : this.disposition == "complete";
  },

  get isActive() {
    return this.isScheduled || this.isCompleted;
  },

  get availableApptTypes() {
    return this.slot.appointments.length > 1
      ? [this.slot.appointmentType]
      : this.provider.apptTypes;
  },

  get canCheckIn() {
    const virtualCheck = this.method === "phone" || this.method === "video";
    return [
      "current",
      "checkin",
      "review",
      virtualCheck && "preflighted_current",
      virtualCheck && "non_preflighted_current",
    ].includes(this.checkInStatus);
  },

  get canScreen() {
    return this.canCheckIn && this.isStarted;
  },

  get isCanceled() {
    return ["canceled", "no_show", "late_cancel"].includes(this.status);
  },

  get isPassed() {
    return this.status == "passed";
  },

  get hasBeenPushedToXOP() {
    return !!this.component_id;
  },
});

module.exports = Appointment;
