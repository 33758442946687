graphUtils    = require('lib/graph')
{formats}     = require 'config'
dateFormat    = formats.fullDate
formats       = formats.chart
ChartTooltip  = require './ChartTooltip'
ReactDOM      = require 'react-dom'
d3Array       = require 'd3-array'
d3Shape       = require 'd3-shape'
d3Brush       = require 'd3-brush'
{easeLinear}  = require 'd3-ease'
{timeFormat}  = require 'd3-time-format'
{select}      = require 'd3-selection'
{scaleTime,
scaleLinear}  = require 'd3-scale'
{axisBottom,
axisRight}    = require 'd3-axis'
{extent}      = d3Array
{cx}          = Exim.helpers
{div, ul, li} = Exim.DOM
{flatten} = require('lodash')

{line, area, curveLinear} = d3Shape
{clone, capitalize, getRandomValue} = require 'lib/utils'

TOOLTIP_WIDTH  = 105

arrayMin = (array, prop) ->
  array.reduce (prev, curr) ->
    Math.min prev, curr[prop]
  , Infinity

arrayMax = (array, prop) ->
  array.reduce (prev, curr) ->
    Math.max prev, curr[prop]
  , -Infinity

getElementData = (el, key) ->
  if el.dataset? then el.dataset[key] else el.getAttribute("data-#{key}")

tween = (b, callback) ->
  (a) ->
    i = d3_interpolate.interpolateArray(a,b)
    (t) ->
      callback(i(t))

sizes =
  large:
    width: ((el) -> el.clientWidth), height: 220,
    padding: 20, paddingBottom: 40, paddingLeft: 55, pointRadius: 2, pointArea: 0.018
  big:
    width: ((el) -> el.clientWidth), height:  60,
    padding: 15, paddingBottom: 25, paddingLeft: 8, pointRadius: 2
  small:
    width: 75, height:  40,
    padding: 8, paddingBottom: 5, paddingLeft: 4, pointRadius: 2, pointArea: 0.05
  context:
    width: ((el)-> el.clientWidth)
    height: 45

Chart = Exim.createView module.id,
  getInitialState: ->
    tooltip: false
    mode: 4 # 0 - day 1 - week 2 - month 3 - year 4 - all
    date: new Date
    start: -Infinity
    end: Infinity
    dataPointsTooltip: []

  componentDidMount: ->
    @updateChart()
    window.addEventListener('resize', @updateChart)
    document.addEventListener('keydown', @escPressed)
    document.querySelector('.chart-point').focus()
    @getDataPoints()

  componentDidUpdate: (props, oldState) ->
    @updateChart() if oldState.start isnt @state.start or @props.data isnt props.data
    @getDataPoints() if oldState.start isnt @state.start or @props.data isnt props.data

  componentWillUnmount: ->
    window.removeEventListener('resize', @updateChart)
    document.removeEventListener('keydown', @escPressed)

  escPressed: (event) ->
    if event.keyCode == 27
      @hideTooltip()

  changeMode: (type) -> =>
    @updateMode(type)

  updateMode: (type) ->
    date = @state.date
    end = moment(date).endOf('day').toDate()
    switch type
      when 0
        @setState start: moment(date).startOf('day'), end: end, mode: type
      when 1
        @setState start: moment(date).subtract(1, 'week').toDate(), end: end, mode: type
      when 2
        @setState start: moment(date).subtract(1, 'month').toDate(), end: end, mode: type
      when 3
        @setState start: moment(date).subtract(1, 'year').toDate(), end: end, mode: type
      when 4
        @setState start: -Infinity, end: Infinity, mode: type

  updateChart: ->
    filterByMode = (item) =>
      date = moment(item.date, 'MM/DD/YYYY').toDate()
      date >= @state.start and date <= @state.end

    data = Object.assign({}, @props.data)
    {size, xTicks, yTicks, xTickFormat, plotsOnValues, chartArea} = @props
    propsSizes = @props.sizes or {}
    { label } = @props
    xTicks ?= 3
    yTicks ?= 5
    xTickFormat ?= formats.year
    points = if typeof @props.points is 'boolean' then @props.points else true
    {type} = data
    type ?= 'health'
    displayValue = data.displayValue or (value) -> value

    data.values = data.values.filter(filterByMode)

    madeSelection = (size is 'large' or size is 'big') and !@props.animationDisabled
    element = @refs.graph

    @refs.tooltip.setState display: false if @refs.tooltip #and size is 'large' or size is 'big'
    svg = element.querySelector('svg')
    element.removeChild(svg) if svg

    graph = data
    gs = size
    gs = 'small' if not gs and (type is 'health' or type is 'corkHealth')
    lt = 'linear'
    lt = 'step-after' if type is 'lifestyle'
    ranges = graphUtils.ranges(graph)
    datasets = graphUtils.datasets(graph)
    lineType = graphUtils.lineType(graph)
    size = gs or 'large'
    lineType = lt or 'linear' unless lineType
    defaultLine = curveLinear
    lineFn = if lineType
      splittedName = lineType.split('-').map((item)->capitalize(item)).join('')
      curveName = "curve#{splittedName}"
      d3Shape[curveName] or defaultLine
    else
      defaultLine

    @currentSize = currentSize = Object.assign({}, clone(sizes[size]), @props.sizes)

    {padding, paddingBottom, paddingLeft, pointRadius, pointArea} = currentSize
    paddingLeft += 20 if size is 'large' and type is 'lifestyle'
    paddingLeft += 30 if size is 'large' and type isnt 'lifestyle'
    el = ReactDOM.findDOMNode(this).parentNode or document.querySelector('.content')
    width = if typeof currentSize.width is 'function' then currentSize.width(el) else currentSize.width
    w = width - (padding + paddingLeft)
    h = currentSize.height - (padding + paddingBottom)
    size2 = Object.create sizes.context
    size2.width = if typeof size2.width is 'function' then size2.width(el) else size2.width
    titleId = "metric-graph-#{getRandomValue()}"

    # ----------------------------------
    #  Adds the chart object
    # ----------------------------------
    chart = select(element).append('svg')
      .attr('role', 'img')
      .attr('tabindex', 0)
      .attr('class', "chart chart--#{size}")
      .attr('width', w + (paddingLeft + padding))
      .attr('height', h + (padding + paddingBottom))
      .attr('transform', "translate(#{paddingLeft}, #{padding})")
      .attr('focusable', 'true')
      .append('g')
        .attr('transform', "translate(#{paddingLeft}, #{padding})")

    select(element)
      .select('svg')
      .append('title')
        .attr('id', "#{titleId}-title")
        .text("#{label} Graph")
        .lower()


    # ----------------------------------
    #  Adds axes, domains, and ranges
    # ----------------------------------

    x = scaleTime().range([0, w])
    x2 = scaleTime().range([0, size2.width]) if size is 'large'
    y = scaleLinear().range([h, 0])
    y2 = scaleLinear().range([size2.height, 0]) if size is 'large'
    allData = flatten(datasets)
    if @state.mode is 4
      x.domain extent(allData, (d) -> d.date)
    else
      x.domain [@state.start, @state.end]

    ticks = if allData.length is 1 then [x.invert(0)] else [x.invert(0), x.invert(w/2), x.invert(w)]
    window.c = chart
    xAxis = axisBottom().scale(x)
      .tickValues(ticks)
      .tickFormat(timeFormat(xTickFormat)) # make this dependent on the date range
      .tickSize(padding / 2, 0)
      .tickPadding(padding / 2)

    # xAxis = axisBottom().scale(x)
    #   .ticks(xTicks)
    #   .tickFormat(timeFormat(xTickFormat)) # make this dependent on the date range
    #   .tickSize(padding / 2)
    #   .tickPadding(padding / 5)

    xAxis2 = axisBottom().scale(x2)
    yAxisData = []
    allData.forEach (d) -> yAxisData.push d.value unless d.value in yAxisData

    ticks = if allData.length < 5 then allData.length else 5
    ticks = 1 if data.id is 9
    yAxis = axisRight().scale(y)
      .ticks(ticks)
      .tickSize(w, 0)
      .tickFormat((t, i)->
        value =
          if type is 'lifestyle'
            graphUtils.labelFor(t, datasets, graph, i)
          else
            Math.round(t)
        displayValue value
      )
      .tickPadding(-(w + paddingLeft - 12))

    if plotsOnValues
      yAxis.tickValues(yAxisData)

    if size is 'large' and allData.length is 1
      yAxis.tickValues(allData.map((d)->d.value))

    lineArea = line().x((d) -> x(d.date)).y((d) -> y d.value).curve(lineFn)#.interpolate(lineType)
    areaBg = area().x((d) -> x(d.date)).y((d) -> y d.value).curve(lineFn)#.interpolate(lineType)

    yMin = d3Array.min(allData, (d) -> d.value)
    yMax = d3Array.max(allData, (d) -> d.value)

    if ranges
      y.domain [
        d3Array.min([yMin, d3Array.min(ranges, (d) -> d.min)])
        d3Array.max([yMax, d3Array.max(ranges, (d) -> d.max)])
      ]

      rangeStartOpacity = if size isnt 'small' and madeSelection then 0 else 1

      showRanges = (range for range in ranges when !range.hide)

      if showRanges.length > 0
        chart.append('g')
          .selectAll('rect')
            .data(showRanges)
          .enter().append('rect')
            .attr('class', (d) -> "chart-range is-color-#{d.color}")
            .attr('role', (d) ->
              'graphics-symbol'
            )
            .attr('x', 0)
            .attr('y', (d) -> y(d.max))
            .attr('width', w)
            .attr('height', (d) -> y(d.min) - y(d.max))
            .attr('aria-label', (d, i) ->
              minLabel = graphUtils.labelFor(d.min, datasets, graph, i)
              maxLabel = graphUtils.labelFor(d.max, datasets, graph, i)
              "Normal Range: #{minLabel} to #{maxLabel}"
            )
            .attr('opacity', rangeStartOpacity)
        chart.selectAll('.chart-range').transition()
          .delay(0)
          .duration(1000)
          .attr('opacity', 1)

    else
      y.domain [
        yMin
        yMax
      ]

    # ----------------------------------
    #  Adds axes, if applicable
    # ----------------------------------

    unless size is 'small'
      xAxisGroup = chart.append('g')
        .attr('class', 'chart-axis chart-axis--x')
        .attr('transform', "translate(0, #{h + 10})")
        .attr('aria-label', 'X-Axis: Date & Time')
        .call(xAxis)
      firstPoint = xAxisGroup.selectAll('.tick:first-of-type text')
      lastPoint = xAxisGroup.selectAll('.tick:last-of-type text')
      singleDateRange = firstPoint._groups[0][0].innerHTML is lastPoint._groups[0][0].innerHTML
      if not singleDateRange
        firstPoint.style('text-anchor', 'start')
        lastPoint.style('text-anchor', 'end')
        xAxisGroup.selectAll('.tick:only-of-type text').style('text-anchor', 'start')
      # chart.append("g").attr("class", "chart-axis").call(yAxis) if size is 'large'

      if size is 'large'
        chart
          .append('g')
          .attr('class', 'chart-axis chart-axis--y')
          .attr('aria-label', "Y-Axis: #{label} Values")
          .call(yAxis)
          .attr('transform', 'translate(30, 0)')
          .selectAll('.tick text')
          .call(graphUtils.wrap, paddingLeft - 6)

    # ----------------------------------
    #  Graphs each dataset
    # ----------------------------------

    for dataset, i in datasets when dataset.length

      lineType = 'linear' # 'cardinal'

      if @state.mode is 4
        max = arrayMax(dataset, 'date')
        min = arrayMin(dataset, 'date')
      else
        max = @state.end
        min = @state.start
      diff = moment(max).diff(min, 'hour')

      items = dataset
      items = [[{date: items[0].date, value: 0}]].concat(items).concat([[{date: items[items.length-1].date, value: 0}]]) if chartArea

      path = chart.append('path')
        .datum(items)
        .attr('class', 'chart-line')
        .attr('d', lineArea)
      if size is 'large'
        defs = chart.append('defs')

        clipPath = defs.append('clipPath')
          .attr('class', 'clip')
          .append('rect')
          .attr('width', w + 40)
          .attr('height', h + 40)
          .attr('transform', 'translate(-20, -20)')

        if chartArea
          lg = defs.append('linearGradient')
            .attr('id', 'lineChart--gradientBackgroundArea')
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', 0)
            .attr('y2', 1)

          lg.append('stop')
            .attr('class', 'lineChart--gradientBackgroundArea--top')
            .attr('offset', '0%')

          lg.append('stop')
            .attr('class', 'lineChart--gradientBackgroundArea--bottom')
            .attr('offset', '100%')

          areaItem = chart.append('path')
            .datum(items)
            .attr( 'class', 'chart-lineArea' )
            .attr( 'd', areaBg )
            .attr('opacity', 0)

      if size isnt 'small' and madeSelection
        if node = path.node()
          if pathLength = node.getTotalLength()
            path
              .attr('stroke-dasharray', "#{pathLength}")
              .attr('stroke-dashoffset', pathLength)
              .transition()
                .delay(500)
                .duration(800)
                .ease(easeLinear)
                .attr('stroke-dashoffset', 0)
          if chartArea
            areaItem
              .transition()
              .delay(0)
              .duration(500)
              .ease(easeLinear)
              .attr('opacity', 1)
              .attrTween( 'd', tween( items, lineArea ) )

      # Adds a group for each dataset and plots datapoints
      # Point radius animates in time with line on large graphs

      pointStartRadius =
        if (size is 'large' or size is 'big') and madeSelection
          0
        else
          pointRadius

      plots = chart.append('g').attr('class', "group group--#{i}")

      plots.selectAll('line')
          .data(items)
        .enter().append("line")
          .attr("class", (d) ->
            "chart-vertical #{if d.length > 1 then 'bold' else ''}"
          )
          .attr('x1', (d) ->
            x d.date
          )
          .attr('x2', (d) ->
            x d.date
          )
          .attr('y1', -10)
          .attr('y2', h + 10)

      # draw the circles for each data point
      plotsData = items # if size is 'large' or size is 'big' then items else [items[items.length-1]]
      if plotsOnValues
        plotsData = plotsData.filter (d) -> +d.value
      if points
        plots.selectAll('circle')
            .data(plotsData)
          .enter().append("circle")
            .attr("class", (d) ->
              'chart-point'
            )
            .attr('role', (d) ->
              'graphics-symbol'
            )
            .attr('id', (d, index) ->
              if i is 0 then "data-point-#{index}"
            )
            .attr('cx',    (d) ->
              x d.date
            )
            .attr('cy',    (d) ->
              y +d.value
            )
            .attr('r', pointStartRadius)
            .attr('data-color', (d)    -> graphUtils.colorFor(d.value, ranges))
            .attr('data-date' , (d)    ->
              if d.length > 1
                min = arrayMin(d, 'date')
                max = arrayMax(d, 'date')
                dateStr = (item) -> moment(item).format('MM/DD/YY')
                if dateStr(min) is dateStr(max)
                  dateStr(min)
                else
                  "#{dateStr(min)} - #{dateStr(max)}"
              else
                moment(d.date).format('MM/DD/YYYY')
            )
            .attr('data-value', (d, i) ->
              if d.length > 1
                min = arrayMin(d, 'value')
                max = arrayMax(d, 'value')
                minValue = graphUtils.labelFor(min, datasets, graph, i)
                maxValue = graphUtils.labelFor(max, datasets, graph, i)
                # show one value if all values are same
                if minValue is maxValue then displayValue minValue else "#{displayValue min} - #{displayValue maxValue}"
              else
                displayValue graphUtils.labelFor(d.value, datasets, graph, i)
            )
            .attr('data-multiple', (d) -> d.length > 1)
            .attr('aria-label', (d, i) ->
              dateStr = (item) -> moment(item).format(dateFormat)
              if d.length > 1
                min = arrayMin(d, 'value')
                max = arrayMax(d, 'value')
                minValue = graphUtils.labelFor(min, datasets, graph, i)
                maxValue = graphUtils.labelFor(max, datasets, graph, i)
                # show one value if all values are same
                if minValue is maxValue then "#{dateStr(d.date)}: #{displayValue minValue}" else "#{dateStr(d.date)}: #{displayValue min} - #{displayValue maxValue}"
              else
                labelStr = (item) -> graphUtils.labelFor(item.value, datasets, graph, i)

                "#{dateStr(d.date)}: #{labelStr(d)}"
            )

        # fade in the data points on the big chart's line
        plots.selectAll('circle').transition()
          .delay((d, i) ->
            500 + 800 * x(d.date) / w
          )
          .duration(400)
          .attr('r', (data) =>
            radius = pointRadius
            if @props.data.size is 'large'
              if data.length > 1 and data.length <= 3
                radius += .5
              else if data.length > 3
                radius += 1
            radius
          )

      chart.select('.chart-axis .chart-axis--x').call xAxis

      # Add long description of data points to chart
      nodes = Array.from(@refs.graph.querySelectorAll('circle'))
      description = []
      # only pushes one of each aria-label into the description (as the points are doubled in the bp graph)
      nodes.forEach (node, i) -> 
        if node.id
          description.push(node.ariaLabel)
      select(@refs.graph).select('svg').attr('aria-label', ("#{label} graph, data points #{description.join(', ')}"))

  getDataPoints: () ->
    allDataPointsNodeList = document.querySelectorAll('[id^="data-point-"]')
    arrayAllDataPointsNodeList = Array.from(allDataPointsNodeList)
    # we only want the data points from the large graph
    filteredDataPoints = arrayAllDataPointsNodeList.filter (dataPoint) -> dataPoint.parentNode.parentNode.parentNode.classList.contains('chart--large')
    @setState dataPointsTooltip: filteredDataPoints
  
  showTooltip: (evt) ->
    {size, data} = @props
    {type} = data
    # if size is 'large' or size is 'big'
    node = @refs.graph
    points = node.querySelectorAll('circle')
    verticals = node.querySelectorAll('line[class~="chart-vertical"]')
    [].forEach.call points, (p) -> p.classList?.add 'chart-point'
    [].forEach.call verticals, (v) -> v.classList?.add 'chart-vertical'
    return unless firstPoint = points[0]
    [].forEach.call points, ( (point) ->
      firstPoint = point if point.getAttribute('cx') < firstPoint.getAttribute('cx')
    )
    event = evt.nativeEvent

    offset = event.offsetX - @currentSize.paddingLeft
    offsetY = event.offsetY - @currentSize.padding

    offset -= 20 if size is 'large' and type is 'lifestyle'
    offset -= 30 if size is 'large' and type isnt 'lifestyle'

    xOffsetsOriginal = [].map.call points, ( (p) -> Math.abs(p.getAttribute('cx')))
    xOffsets = [].map.call points, ( (p) -> Math.abs(p.getAttribute('cx') - offset))
    yOffsets = [].map.call points, ( (p) -> Math.abs(p.getAttribute('cy') - offsetY))

    xMinOffset = Math.min.apply(Math, xOffsets)
    yMinOffset = Math.min.apply(Math, yOffsets)

    if (event.type == "focus") then activePoints = [].filter.call points, ((item, i) -> (xOffsets[i] is xMinOffset)) else
      activePoints = [].filter.call points, ((item, i) -> (xOffsets[i] is xMinOffset) and xMinOffset < 10)
    [].forEach.call points, (el, i) -> el.setAttribute('class', 'chart-point')
    [].forEach.call activePoints, (el, i) -> el.setAttribute('class', 'chart-point is-chart-point-active')

    activePoint = activePoints.sort((a, b) ->
      parseInt(a.getAttribute('cy')) - parseInt(b.getAttribute('cy'))
    )[0]

    active = node.querySelectorAll('.is-chart-vertical-active')
    [].forEach.call(active, (e) -> e.setAttribute('class', 'chart-vertical'))
    vertical = verticals[[].indexOf.call(points, activePoint)]
    vertical?.setAttribute('class', 'chart-vertical is-chart-vertical-active')

    display = !!activePoint
    oldDisplay = @refs.tooltip.state.display or false

    if event.type is 'focus' and document.activeElement.classList.contains('chart--large')
      display = true
      [].forEach.call points, (el, i) -> el.setAttribute('class', 'chart-point is-chart-point-active')
      [].forEach.call verticals, (el, i) -> el.setAttribute('class', 'chart-vertical is-chart-vertical-active')
      leftValues = []
      [].forEach.call verticals, (el, i) -> 
        x1 = el.getAttribute('x1')
        leftValues.push(Number(x1))
      for point, i in @state.dataPointsTooltip 
        top = if i % 2 then -55 else 25
        left = leftValues[i] - TOOLTIP_WIDTH / 2 + (@currentSize.paddingLeft / 2)
        left = if size is 'large' then left + 20 else left
        if point.id then @refs["data-point-#{i}"].setState {value: point.getAttribute('data-value'), date: point.getAttribute('data-date'), style: {top, left, width: TOOLTIP_WIDTH}, angle: 'bottom', className: '', display: true}

    if !display and oldDisplay
      @hideTooltip()
      return

    return unless activePoint
    dataset = getElementData.bind(null, activePoint)
    value = dataset 'value'
    date = dataset 'date'
    multiple = dataset 'multiple'
    className = multiple or ''
    offsetRect = vertical.getBoundingClientRect()
    offsetLeft = offsetRect.left
    offsetTop = offsetRect.top
    tooltipCurrentWidth = TOOLTIP_WIDTH
    left = vertical.getAttribute('x1') - tooltipCurrentWidth / 2 + (@currentSize.paddingLeft / 2)
    left = if size is 'large' then left + 20 else left
    top = -15
    top = if size is 'small' then top - 10 else top
    {clientWidth} = document.body
    angle = 'bottom'

    style =
      left: left
      top: top
      width: tooltipCurrentWidth

    oldStyle = @refs.tooltip.state.style or {}
    if oldStyle.left isnt style.left or !oldDisplay
      @refs.tooltip.setState {value, date, style, angle, className, display}

  hideTooltip: ->
    node = @refs.graph
    points = node.querySelectorAll('circle')
    active = node.querySelectorAll('.is-chart-vertical-active')
    [].forEach.call active, (e) -> e.setAttribute('class', 'chart-vertical')
    [].forEach.call points, (el, i) -> el.setAttribute('class', 'chart-point')
    @refs.tooltip?.setState {display: false}
    for point, i in @state.dataPointsTooltip 
        if point.id then @refs["data-point-#{i}"].setState {display: false}

  render: ->
    {size} = @props
    {mode, dataPointsTooltip} = @state
    showTooltip = unless @props.noTooltip then @showTooltip else null

    div role: 'figure', className: 'profile-list-outer u-noselect', ref: 'outer',
      div
        className: 'profile-list-graph'
        ref: 'graph'
        onMouseOver: showTooltip
        onMouseMove: showTooltip
        onMouseLeave: @hideTooltip
        onTouchStart: showTooltip
        onTouchMove: showTooltip
        onFocus: showTooltip
        onBlur: @hideTooltip
        # if size is 'large' or size is 'big'
        ChartTooltip ref: 'tooltip'
        for point in dataPointsTooltip 
          ChartTooltip ref: point.id

module.exports = Chart
