presets =
  phone: '(max-width: 576px)'
  paymentModalDesktopSm: '(min-width: 768px) and (max-width: 880px)'
  phoneOrTablet: '(max-width: 767px)'
  print: 'print'
  md: '(max-width: 767px)'

module.exports = (opts) ->
  listeners = []

  componentWillMount: ->
    Object.keys(opts).forEach (prop) =>
      media = opts[prop]
      key = "_media-#{prop}"
      if Object.keys(presets).indexOf(media) isnt -1
        media = presets[media]

      listener = (mql, force) =>
        {matches} = mql
        return if matches is this[key]
        st = {}
        this[key] = st[prop] = matches
        @setState st if force or @isMounted()

      mql = matchMedia(media)
      mql.addListener listener
      listener(mql, true)
      listeners.push -> mql.removeListener(listener)

  componentWillUmnount: ->
    listeners.forEach (fn) ->
      fn()
