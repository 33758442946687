module.exports =
  clear: ->
    @messages = {}
    @preventX = false
    @preventY = false
    @documents = {scrollTop: null}

  messages: {}
  preventX: false
  preventY: false
  documents:
    scrollTop: null
