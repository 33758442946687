{authStore, appStore} = require 'stores'
AuthWrapper = require 'components/auth/auth-wrapper'

{cx} = Exim.helpers
{div, h1, p, input, spinner, span, tch, form, button, label} = Exim.DOM

defaultState = sent: false, email: ''

ResetPassword = Exim.createView module.id,
  listen: ['auth/sendingForgot', 'auth/error']

  getInitialState: ->
    Object.assign({}, defaultState, @props.location.query)

  componentDidMount: ->
    appStore.actions.setTitle('Reset Password')
    setTimeout(() ->
      firstElement = document.getElementById('login-email')
      firstElement?.focus()
    , 0)

  contextTypes:
    router: React.PropTypes.object.isRequired

  sendForgot: (e) ->
    e.preventDefault()
    {email} = @state
    if !@email then @setState error: "Email address is required."
    return unless email
    authStore.actions.forgot(email).then =>
      @setState sent: true

  refreshSent: ->
    @setState defaultState

  setEmail: (evt) ->
    @setState email: evt.currentTarget.value

  goToSignIn: ->
    @context.router.push pathname: '/signin'

  render: ->
    return React.createElement(AuthWrapper, {title: 'Password Reset'},
      div className: 'AuthCard', 
        form className: "ResetPasswordForm #{cx 'is-sent': @state.sent}", "aria-live": "polite", onSubmit: @sendForgot, autoComplete: 'off',
          if @state.sent
            [
              h1 key: 'success-title', className: 'FlippedCard-title', 'Password reset request sent'
              p key: 'success-check', 'Please check your email and click the link to finish resetting your password.'
              p key: 'success-check-2', className: 'check', "If you don't receive it in a few minutes, please check your spam folder."
              div key: 'success-spacer', className: 'spacer'
            ]
          else
            [
              div key: 'fail-email', className: 'input-wrapper email',
                p 'Enter your account email address to receive a password reset email.'
                label htmlFor: 'login-email', 'Email Address *'
                input
                  className: "Input Input--expand Input--auth  #{cx error: @state.error}"
                  id: 'login-email'
                  name: 'email'
                  onChange: @setEmail
                  ref: 'email'
                  type: 'email'
                  value: @state.email
                  'aria-required': true
              button key: 'fail-submit', className: "Button Button--primary Button--large Button--expand Button--auth reset-password-button #{cx 'reset-password-button--error': @state.error}", type: 'submit', onClick: @sendForgot,
                if @state.sendingForgot then spinner() else 'Reset Password'
              if @state.error
                document.getElementById('login-email')?.focus()
                span key: 'fail-error', className: 'error', 'aria-live': 'assertive', @state.error
            ]

          tch tagName: 'a', href: '#', className: 'Button--pointer Auth--informational-link Auth--informational-link-footer', handler: @goToSignIn, 'Go to Sign In'
    )

module.exports = ResetPassword
