{appStore} = require 'stores'
utils      = require 'lib/utils'
Img        = require 'components/elements/Img'
{div}      = Exim.DOM

{ mimeTypes, maxFileSize } = require 'config'

FileAttach =
  getInitialState: ->
    files: []
    dragging: false
    attachmentErrors: []

  addInitialFiles: (files) ->
    @setState files: @state.files.concat(files)

  addFiles: (files) ->
    # Preview in non-Edge browsers.
    attachmentErrors = []

    [].slice.call(files).forEach (file) =>
      # result = {src: base64, attachedAt: Date, file: File}
      # File: {lastModified: Number, name: String, size: Number, type: String}
      result = {file}
      # console.log 'addFiles', 'attaching one file', file

      if file.size >= maxFileSize
        attachmentErrors.push("Oversized file: #{file.name}")
      else if not (file.type in mimeTypes)
        attachmentErrors.push("Unsupported file type: #{file.name}")
      else
        utils.readImage(file).then (src) =>
          result.src = src
          result.attachedAt = new Date
          window.cache?.clear ((msg) -> console.log('Cache cleared', msg)), ((err) -> console.log('Cache clearing error', err))
          @setState files: @state.files.concat(result)

      @setState { attachmentErrors }

  # Edge function. Uploads file to the server & shows the preview.
  onFilesAttachPostUpload: (responseObjects, files) ->
    uploadedFiles = []

    responseObjects.forEach (json, index) ->
      json.originalUrl = json.url
      json.url = location.origin + json.originalUrl 

      uploadedFiles.push({
        attachedAt: new Date,
        src: json.url,
        file: files[index]
      })

    @setState files: @state.files.concat uploadedFiles

  onFileAttach: (evt) ->
    @addFiles evt.currentTarget.files
    evt.currentTarget.value = null

  onFileRemove: (item) -> (evt) =>
    evt.stopPropagation()
    @setState files: @state.files.filter((file) -> file isnt item ), attachmentErrors: []

  preview: (item) -> ->
    if utils.humanizeMime(item.file.type or item.file.mime) is 'Image'
      body = div className: 'image-modal', Img src: (item.src or item.file.url)
      modal = {body}
      appStore.actions.showModal(modal)
    else
      window.item(doc.src, '_blank').focus()

  onDragOver: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    evt.dataTransfer.dropEffect = 'copy'
    @setState dragging: true

  onDragLeave: (evt) ->
    @setState dragging: false

  onDrop: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    files = evt.dataTransfer.files
    @addFiles files
    @setState dragging: false

module.exports = FileAttach
