import langs from "langs";
import { uniq, uniqBy } from "lodash";
import NativeSelect from "./native-select";

const navigatorLangs = uniq(
  (navigator.languages || []).map((tag) => {
    let [lang] = tag.split("-", 1);
    return lang;
  })
);

const allLangs = langs.all();
const sortedLangs = navigatorLangs
  .map((l) => allLangs.find((lang) => lang[1] == l))
  .filter((l) => l)
  .concat(allLangs);

export const uniqueLanguageOptions = uniqBy(sortedLangs, "2");
export const languageOptions = uniqueLanguageOptions.map((lang, index) => {
  let iso = lang[2];
  return (
    <option key={iso} value={iso}>
      {lang.local}
      {lang.local != lang.name && ` (${lang.name})`}
    </option>
  );
});

const LanguageSelect = (props) => {
  return (
    <NativeSelect placeholder="Select Language" showplaceholder="true" {...props}>
      {languageOptions}
    </NativeSelect>
  );
};

export default LanguageSelect;
