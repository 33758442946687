loadMunchkin = (munchkin_id) ->
  didInit = false
  s = document.createElement('script')

  initMunchkin = ->
    if didInit == false
      didInit = true
      Munchkin.init munchkin_id

  s.type = 'text/javascript'
  s.async = true
  s.src = '//munchkin.marketo.net/munchkin.js'

  s.onreadystatechange = ->
    if @readyState == 'complete' or @readyState == 'loaded'
      initMunchkin()

  s.onload = initMunchkin
  document.getElementsByTagName('head')[0].appendChild s

module.exports = loadMunchkin
