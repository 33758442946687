# Contains state of all views.
ReactRouter     = require 'react-router'
globalViewState = {}

# View mixin that would add the view to global state.
GlobalStateMixin =
  componentWillMount: ->
    globalViewState[this._module.replace(/\.\w+$/, '')] = this

  componentWillUnmount: ->
    delete globalViewState[this._module]

# Prettify state keys e.g. state['components/Item'] => state.components.Item
setGlobalStateItem = (views, path, view) ->
  split = path.split('/')
  total = split.length
  iter = ''
  prevObject = null
  split.forEach (item, index) ->
    isLastItem = index is total - 1

    if isLastItem
      prevObject[item] = view
    else
      prevObject = if prevObject
        prevObject[item] ?= {}
      else
        views[item] ?= {}
      iter = item

storesStr = 'stores/'
getCurrentStateSnapshot = ->
  # state.stores
  stores = window.require
    .list()
    .filter((path) -> path.indexOf(storesStr) isnt -1)
    .reduce (data, path) ->
      data[path.replace(storesStr, '').replace(/\.\w+$/, '')] = require(path).initial
      data
    , {}

  # state.views
  views = {}
  for key, view of globalViewState
    setGlobalStateItem views, key, {state: view.state, props: view.props}

  # state.router
  lastLocation = require('stores/app').get('lastLocation')
  router = if lastLocation then {path: ReactRouter.HistoryLocation?.getCurrentPath()} else {}
  {views, stores, lastLocation}

# Overwrite Exim's view creation.
Exim.createView = (id, classArgs) ->
  if typeof id is 'string'
    classArgs._module = id
    classArgs.displayName ?= id.split('/').pop()
    classArgs.mixins ?= []
    classArgs.mixins.push GlobalStateMixin
    classArgs.mixins.push Exim.listen(classArgs.listen)... if classArgs.listen
  else
    classArgs = id
  React.createElement.bind React.createElement, React.createClass(classArgs)

Object.defineProperty window, 'state', get: getCurrentStateSnapshot
