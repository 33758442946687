# NOTE: This is v2, not the current branch. Different loading process
# https://github.com/zloirock/core-js/tree/v2
require('core-js/fn/map');
require('core-js/fn/promise');
require('core-js/fn/set');
require('core-js/fn/symbol');
require('core-js/fn/weak-set');
require('core-js/fn/array/from');
require('core-js/fn/array/find');
require('core-js/fn/array/find-index');
require('core-js/fn/array/includes');
require('core-js/fn/string/starts-with');
require('core-js/fn/string/includes');
require('core-js/fn/string/repeat');
require('core-js/fn/string/ends-with');
require('core-js/fn/number/parse-int');
require('core-js/fn/number/parse-float');
require('core-js/fn/object/is');
require('core-js/fn/object/assign');
require('core-js/fn/object/values');
require('core-js/fn/object/entries');
require('core-js/fn/object/get-own-property-descriptors');
require('regenerator-runtime/runtime');

require 'dom4'
require 'pepjs'
require 'shim-keyboard-event-key'
require 'shim-selected-options'
require('react-dates/initialize')

window.React = require('react')
window.Exim = require('exim')
window.moment = require('moment')

React.createClass = require('create-react-class')
React.PropTypes = require('prop-types')

require 'moment-timezone'

loadMunchkin = require 'lib/load-munchkin'
{REACT_APP_MUNCHKIN_ACCT_ID} = require 'environment'

window.addEventListener('unhandledrejection', (event) =>
  if (event && event.reason && event.reason.name == 'AbortError')
    event.preventDefault()
)

if typeof Symbol == 'function'
  {observable} = require 'mobx'
  ObservableArray = Object.getPrototypeOf(observable([]))
  ObservableArray[Symbol.isConcatSpreadable] = true
  ObservableArray[Symbol.toStringTag] = 'Array'

{stepNumber} = require 'lib/utils'

document.addEventListener('keydown', (event) =>
  return if event.defaultPrevented

  {target} = event
  return if !target.matches('input[type=number]')

  switch event.key
    when 'ArrowUp'
      event.preventDefault()
      stepNumber(target, 'up')
    when 'ArrowDown'
      event.preventDefault()
      stepNumber(target, 'down')
)

objectFitImages = require 'object-fit-images'
objectFitImages(null, {watchMQ: true})

document.addEventListener('backbutton', () =>
  isFirstPage = ['/home', '/signin', '/landing'].includes(location.pathname)
  if isFirstPage
    navigator.app.exitApp()
  else
    history.back()
)

if (msie = navigator.userAgent.indexOf('MSIE ')) > -1 #IE <= 10 only
  document.onreadystatechange = () ->
    state = document.readyState
    if state == 'complete'
      version = parseInt(navigator.userAgent.substring(msie + 5, navigator.userAgent.indexOf('.', msie)))
      style = if version < 9 then 'style=" padding-top: 24px; background: white; "' else ''
      parent = document.getElementById('app-outer')
      html = '<div class="App" style="top: 0px; left: 0px; right: 0px;"><div class="LeftSwiper master"></div><div class="Splash Splash--auth"><div class="BrowsersCard"><form class="card-logo"' + style + 'autocomplete="off"><p>Unfortunately your current web browser will not provide the best experience possible.</p><div class="BrowsersCard-text">If possible, please upgrade to one of the following web browsers.</div><div class="BrowsersCard-text">If you are using an employer owned computer, the Information Technology policies or workstation management may restrict your ability to upgrade or install web browsers. Please consult with your IT support team before proceeding.</div><div class="BrowsersCardImages"><a href="https://www.google.com/chrome/browser/desktop/" class="BrowsersCardImages--chrome"><img src="combo/images/chromelogo.png"></a><a href="https://www.mozilla.org/firefox/new/" class="BrowsersCardImages--firefox"><img src="combo/images/firefoxlogo.png"></a><a href="https://www.microsoft.com/en-us/windows/microsoft-edge" class="BrowsersCardImages--edge"><img src="combo/images/edgelogo.png"></a><a href="https://support.microsoft.com/en-us/help/18520/download-internet-explorer-11-offline-installer" class="BrowsersCardImages--ie"><img src="combo/images/IE11Logo.png"></a></div></form></div></div></div>'
      parent.insertAdjacentHTML('beforeend', html)

else
  require 'whatwg-fetch'
  require 'lib/global-state'
  {ready} = require 'lib/utils'
  stores = require 'stores'
  config = require 'config'

  Exim.DOM.icn = (icn, cls, key) -> Exim.DOM.i className: "icon ion-#{icn} #{cls or ''}", key: key
  Exim.DOM.fa = (icn, cls, key) -> Exim.DOM.i className: "fa icon fa-#{icn} #{cls or ''}", key: key
  Exim.DOM.spinner = (cls, size) -> Exim.DOM.div className: "spinner#{if size then '-' + size else ''} #{cls or ''}", [0...12].map (i) -> Exim.DOM.div(className:"bar#{i+1}", key: i)
  Exim.DOM.tch = require 'components/elements/TouchClick'

  if REACT_APP_MUNCHKIN_ACCT_ID
    loadMunchkin REACT_APP_MUNCHKIN_ACCT_ID

  ready().then ->
    window.buildTime = config.buildDate
    stores.app.actions.start()
