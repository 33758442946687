"use strict";
const { cx } = require("lib/utils");

const Sorter = (props) => {
  let { by, sort, className } = props;
  let { order } = sort;

  let isSorted = by == sort.by;
  let sortClass = cx("Sorter", {
    sorted: isSorted,
    [order]: isSorted,
  });

  return (
    <th className={className} aria-sort={isSorted ? order : "none"}>
      <button className={sortClass} onClick={() => sort.changeSort(by)}>
        {props.children}
      </button>
    </th>
  );
};

module.exports = Sorter;
