{ observer } = require('mobx-react')
{ withEscapeHandler } = require('components/elements/new-modal')
{ div, p, strong } = Exim.DOM

PopUp = withEscapeHandler(((props) => 
  return React.createElement(React.Fragment, null,
    div className: 'ProfileInfoPopUp-overlay', onClick: props.close
    props.children
)), 'profile-header-sogi-info-icon')

RaceEthnicityPopUp = Exim.createView module.id,
    componentDidMount: ->
        @refs.popUp?.focus()

    render: ->
        { rect: { bottom, right }, abort } = this.props
        popUpClassName = if (bottom < 535) then 'ProfileInfoPopUp-no-transform' else 'ProfileInfoPopUp-transform'

        return React.createElement(PopUp, {close: abort},
            div ref: 'popUp', tabIndex: 0, className: popUpClassName, style: {top: "#{bottom}px", left: "#{right - 100}px"},
                p className: 'ProfileInfoPopUp-statement',
                    strong 'Race: '
                    'Refers to a group of people who share physical characteristics, such as skin color and facial features.'
                p className: 'ProfileInfoPopUp-statement',
                    strong 'Ethnicity: '
                    'Refers to the group you share your social and cultural characteristics, backgrounds, or experiences with.'
        )


module.exports = observer(RaceEthnicityPopUp)