{authStore} = require 'stores'
sort        = require 'components/mixins/sort'
Spinner     = require 'components/elements/Spinner'
InnerScroll = require 'components/mixins/InnerScroll'
Search      = require 'components/mixins/Search'
{isEqual} = require 'lodash'
utils    = require 'lib/utils'
{cx} = Exim.helpers
{div, icn, span, input, fa, tch, h2, h3} = Exim.DOM

maxBodyHeight = 255

Widget = Exim.createView module.id,
  mixins: [
    Search
    InnerScroll
    sort(type: 'name', asc: false, dates: ['created_at', 'starts_at', 'start_at'])
  ]

  getInitialState: ->
    data: []

  getDefaultProps: ->
    type: 'striped'

  searchKeys: -> ['name', 'type']

  componentWillMount: ->
    @sortDefault()

  sortDefault: (props=@props)->
    if props.defaultSort
      {type, asc} = props.defaultSort
      @sort(type, undefined, asc)()

  toggleExpanded: (name) -> =>
    @props.toggleExpanded(name)

  cancel: ->
    @props.toggleExpanded(@props.name)

  confirm: ->
    @props.toggleExpanded(@props.name)

  removeItem: (item) -> =>
    {data} = @state
    @setState data: data.filter((i) -> not isEqual(i, item))

  addItem: (item) -> =>
    {data} = @state
    newItem = Object.create item
    newItem.id = Date.now()
    data.push newItem
    @setState {data}

  removeWidgetItem: (item) -> (evt) =>
    user = authStore.get('user')
    if (user.admin or user.proxying) and @props.onRemove and item and confirm("Delete #{item.name} (#{item.code})?")
      evt.preventDefault()
      @props.onRemove(item)

  toggleFilter: (sortKey) ->
    @sort(sortKey)

  getTableSortingInfo: (fields, sortKeys) ->
    fieldsKeys = Object.keys(fields)
    fieldsKeys?.map (key, i) =>
      sortKey = sortKeys?[key] or key
      sortDirection = @getAriaSortDirection(sortKey)
      if sortDirection then "column #{i+1} #{fields?[key]} sorted #{sortDirection}"

  render: ->
    {name, fields, items, state, expanded, Item, results, splitColumns, ifEmpty, type, ignoreScroll, sortKeys, note, fallbackLabel, rowHeight} = @props
    rowHeight = 50 unless rowHeight
    length = items.length
    totalHeight = length * rowHeight
    overflown = totalHeight > maxBodyHeight
    fieldsKeys = Object.keys(fields)
    [onScroll, onWheel] = if ignoreScroll then [] else [@preventParentScrolling, @preventParentWheeling]

    cls = "#{name.toLowerCase()}-widget" + ' ' + (@props.className or '') + ' widget'
    splitCls = if splitColumns then "u-1of#{fieldsKeys.length}" else ''

    div className: "#{cls} #{cx {expanded}}",
      div className: 'table-block',
        div role: 'table', 'aria-label': name, 'aria-colcount': fieldsKeys?.length, 'aria-rowcount': items?.length,  'aria-describedby': "#{name}-tableInfo", className: "Table Table--#{type} widget-table #{if overflown then 'overflown' else '' }",
          div role: 'rowgroup', className: 'TableHeader',
            div role: 'row', className: 'TableHeader-row',
              fieldsKeys.map (key, index) =>
                sortKey = sortKeys?[key] or key
                sortDirection = @getAriaSortDirection(sortKey)
                if index == 0
                  firstColumnName = "firstColumn"
                else
                  firstColumnName = ''
                span className: "#{fields[key].toLowerCase()}", role: 'columnheader',
                  tch tagName: 'button', 'aria-label': "#{fields[key]} table #{if sortDirection then "sorted #{sortDirection}" else ''}", className: "TableHeader-column widget-sort #{firstColumnName} #{@isSorted(sortKey)} #{splitCls} #{fields[key].toLowerCase()}", onKeyPress: utils.a11yClick(@toggleFilter(sortKey, sortDirection, fields[key])), handler: @toggleFilter(sortKey, sortDirection, fields[key]), key: sortKey,
                    h2 fields[key]
          div role: 'rowgroup', className: "TableBody #{cx scrolling: @state.scrolling}", onScroll: onScroll, onWheel: onWheel,
            unless expanded
              Spinner state: state,
                if items.length
                  items.sort(@getSortFn()).map (item,n) =>
                    div role: 'row', className: 'TableBody-row', onDoubleClick: @removeWidgetItem(item), key: "#{item[Object.keys(fields)[0]]}-#{n}", style: height: rowHeight,
                      div className: 'TableBody-rowIn',
                        fieldsKeys.map (key) ->
                          div role: 'cell', className: "TableBody-column #{splitCls} #{fields[key].toLowerCase()}", key: fields[key].toLowerCase(),
                            if typeof item[key] is 'string' and item[key].length > 0
                              item[key]
                            else if fallbackLabel
                              fallbackLabel
                            else
                              ''
                unless items.length or note
                  div role: 'row',
                    div role: 'cell', 'There are currently no items listed.'
            else
              div className: 'WidgetDropdown',
                div className: 'WidgetDropdownWrapper',
                  div className: 'WidgetDropdownMain',
                    div className: 'WidgetDropdownHeader',
                      name
                    div className: 'WidgetDropdownBody',
                      @state.data.map (item) =>
                        div className: 'WidgetDropdownSectionItem',
                          div className: 'WidgetDropdownSectionItem-header',
                            div className: 'title',
                              item.name
                            div className: 'spacer'
                            tch className: 'close', handler: @removeItem(item),
                              fa 'times-circle'
                          div className: 'WidgetDropdownSectionItem-body',
                            Item({item})
                  div className: 'WidgetDropdownFooter',
                    tch className: 'Button Button--pointer Button--cancel', handler: @cancel, 'Cancel'
                    tch className: 'Button Button--pointer Button--primary', handler: @confirm, 'Confirm'
                div className: 'WidgetDropdownSidebar',
                  div className: 'WidgetDropdownSearch',
                    div className: 'WidgetDropdownSearchInput',
                      input className: 'Input', type: 'search', placeholder: 'Search', onChange: @search
                  div className: 'WidgetDropdownTypes-header', 'Results'
                  div className: 'WidgetDropdownTypes-list',
                    results.filter(@filterer).map (result) =>
                      tch className: 'WidgetDropdownTypes-listItem', handler: @addItem(result),
                        div className: 'title',
                          result.name
                        div className: 'type',
                          result.type
          if note
            div className: 'WidgetNote',
              h3 className: 'WidgetNote-title', 'Notes'
              div className: 'WidgetNote-text',
                note
      div id: "#{name}-tableInfo", "aria-hidden": "true", "hidden": "true", @getTableSortingInfo(fields, sortKeys)

module.exports = Widget
