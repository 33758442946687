import { observable } from "mobx";
// Can't use mobx computed because of Exim conflicts
import { computed } from "lib/utils";
import request from "lib/new-request";

const metricStore = observable({
  types: [],

  async fetchMetricTypes(params) {
    return request
      .get("/v1/metric_types", params, { serializeObject: true })
      .then((res) => {
        this.types = res;
        return res;
      });
  },
});

computed(metricStore, {
  // Returns metric types transformed to dropdown options for use in the screenings editor
  get typesAsOptions() {
    return (
      metricStore.types
        // Ascending by id
        .sort((a, b) => a.id - b.id)
        // Return the "ItemValues" data type: https://surveyjs.io/Examples/Builder/?id=addproperties#content-docs
        .map((type) => {
          return { text: `[${type.id}] ${type.name}`, value: type.id };
        })
    );
  },
});

module.exports = metricStore;
