import ProviderMenu from "./ProviderMenu";
import careTeamsStore from "stores/care-teams";
import utils from "lib/utils";

class MainDropdownMenu extends React.Component {
  state = {
    anchorElement: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorElement: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorElement: null,
    });
  };

  render() {
    const { anchorElement } = this.state;
    const { scheduleStore } = this.props;
    const defaultProvider = scheduleStore && scheduleStore.providers[0];
    const defaultProviderSrc = utils.getAvatarUrl(
      !defaultProvider.shot ||
        defaultProvider.shot === "/images/team/photo-default.jpg"
        ? "/combo/images/team/photo-default.jpg"
        : defaultProvider.shot
    );
    const providerSrc = utils.getAvatarUrl(
      careTeamsStore.providerSrc === "/images/team/photo-default.jpg"
        ? "/combo/images/team/photo-default.jpg"
        : careTeamsStore.providerSrc
    );

    return (
      <label className="Appointments-label">
        <div className="Appointments-labelText">
          <strong>Provider</strong>
        </div>
        <div
          className="MaterialSelect Custom-providerDropdown"
          id="provider-dropdown-menu"
          aria-label="provider menu"
          onClick={this.handleClick}
        >
          <div className="Select-wrap">
            {(defaultProvider || careTeamsStore.providerSrc) && (
              <img
                className="MenuItem-avatar"
                src={
                  !careTeamsStore.providerSrc ? defaultProviderSrc : providerSrc
                }
                alt=""
              />
            )}
            <span className="selectedValue">
              {!careTeamsStore.providerName
                ? defaultProvider.name
                : careTeamsStore.providerName}
            </span>
          </div>
          <button
            className="Provider-dropdownIcon"
            aria-label="Toggle Provider Menu"
          ></button>
        </div>

        <ProviderMenu
          open={Boolean(anchorElement)}
          anchorElement={anchorElement}
          onClose={this.handleMenuClose}
          schedulestore={scheduleStore}
        />
      </label>
    );
  }
}

export default MainDropdownMenu;
