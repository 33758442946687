/*
CONFIGURE KNOCKOUT SURVEY (used by surveyjs-editor):
Configures the underlying dependencies for the EDITOR,
allowing you to use custom properties.
https://github.com/surveyjs/surveyjs_react_quickstart/issues/4#issuecomment-291759996
https://github.com/surveyjs/surveyjs/issues/719
USAGE:

import { screeningsFramework } from 'lib/utils'
screeningsFramework.configureSurveyEditor(SurveyJS)
import * as SurveyEditor from 'surveyjs-editor'
*/
import marked from "marked";
import { flatMap } from "lodash";

const renderer = new marked.Renderer();
renderer.link = (href, title, text) =>
  `<a target="_blank" href="${href}" rel="noopener noreferrer" title="${title}">${text}</a>`;

export const configureEditor = (
  SurveyKnockout,
  SurveyEditor,
  metricItemValues
) => {
  // Add a default metricItemValue - MUST be null, due to how SJS processes questions under the hood
  const defaultValue = { text: "Not Tracked", value: null };
  // NOTE: There's a SJS bug, 'Not Tracked' and placeholder properties fail to render. But this works
  metricItemValues.unshift(defaultValue);

  SurveyKnockout.JsonObject.metaData.addProperty("question", {
    name: "metricTypeId", // Do not set <name>:type - causes unthrown errors in SJS
    choices: metricItemValues,
  });
  SurveyEditor.SurveyQuestionEditorDefinition.definition[
    "question"
  ].properties.push({
    name: "metricTypeId",
    title: "Metric Type ID",
    default: defaultValue,
    // Do not add choices here
  });
};

export const disableLinkedField = (model) => {
  model.pages.forEach((page) =>
    page.questions.forEach((question) => {
      const hasLinkedValue =
        question.linkedTemplateQuestion && !question.isEmpty();

      if (hasLinkedValue) {
        question.setPropertyValue("readOnly", true);
      }
    })
  );

  return model;
};

export const setStartPage = (surveyModel) => {
  const startPage = surveyModel.pages.find((page) =>
    page.questions.find((question) => question.isEmpty() && question.isRequired)
  );

  if (startPage) {
    surveyModel.currentPage = startPage;
  }
  return surveyModel;
};

export const setQuestionError = (surveyModel, questionName) => {
  surveyModel.onTextMarkdown.add((survey, options) => {
    if (!/Please\s+answer\s+questions\s+in\s+all\s+rows/i.test(options.text))
      return;

    const htmlText = `<div>Please make sure to answer questions in all rows.</div>`;
    let str = marked(htmlText, { renderer });

    options.html = str;
  });

  const styles = {
    matrix: {
      error: {
        root: "sv_q_erbox_special",
      },
    },
  };

  Object.assign(surveyModel, { css: styles });

  return surveyModel;
};

// Configure the core survey. Survey-knockout and survey-react have the same API, works on the editor bundle too
export const configureSurvey = (SurveyJS) => {
  SurveyJS.JsonObject.metaData.addProperty("question", "popupDescription:text");
  SurveyJS.JsonObject.metaData.addProperty("page", "popupDescription:text");

  // Apply theme colors:
  // https://www.surveyjs.io/Examples/Library/?id=custom-theme&platform=Reactjs&theme=default
  const mainColor = "var(--color-brand-light)";
  const textColor = "black";
  Object.assign(SurveyJS.StylesManager.ThemeColors["default"], {
    "$main-color": mainColor,
    "$main-hover-color": mainColor,
    "$text-color": textColor,
    "$header-color": mainColor,
  });
  SurveyJS.StylesManager.applyTheme();
};

// Configure the additional settings
export const configureDefaultOptions = async (
  SurveyJS,
  fetchForwadableTemplates = () => Promise.resolve([])
) => {
  const options =
    SurveyJS && SurveyJS.JsonObject && SurveyJS.JsonObject.metaData;
  if (!options) {
    return;
  }

  const elationTypes = [
    { value: "", text: "None" },
    { value: "Social", text: "Social" },
    { value: "Habits", text: "Habits" },
    { value: "Psychological", text: "Psych" },
    { value: "Past", text: "Past" },
    { value: "Family", text: "Family" },
    { value: "Diet", text: "Diet" },
    { value: "Exercise", text: "Exercise" },
    { value: "Immunization", text: "Immunization" },
    { value: "Legal", text: "Legal" },
    { value: "Consultation", text: "Consultation" },
    { value: "Health Maintenance", text: "Health Maintenance" },
    { value: "Past Surgical", text: "Past Surgical" },
    { value: "Cognitive Status", text: "Cognitive Status" },
    { value: "Functional Status", text: "Functional Status" },
  ];

  const actionTypes = [
    { value: "", text: "Select" },
    { value: "provider_message", text: "Provider Message" },
    { value: "time_sensitive", text: "Time Sensitive" },
    { value: "provider_message_medium", text: "Provider Message Non Urgent" },
  ];

  options.addProperty("survey", { name: "rfvLabel" });
  options.addProperty("question", {
    name: "elationField",
    choices: elationTypes,
  });
  options.addProperty("question", { name: "generatedConsentName" });
  options.addProperty("question", { name: "rfvLabel" });
  options.addProperty("question", { name: "includeInRfv:boolean" });
  options.addProperty("question", {
    name: "actionIfTrue",
    choices: actionTypes,
  });
  options.addProperty("question", { name: "forwardable:boolean" });

  fetchForwadableTemplates().then((forwadableTemplates) => {
    const forwardables = forwadableTemplates.map(({ name }) => ({
      value: name,
      text: name,
    }));

    options.addProperty("question", {
      name: "linkedTemplateID",
      choices: forwardables,
      category: "Linked",
      categoryIndex: 1,
    });
    options.addProperty("question", {
      name: "linkedTemplateQuestion",
      dependsOn: "linkedTemplateID",
      category: "Linked",
      choices: function (obj, choicesCallback) {
        const template =
          obj &&
          forwadableTemplates.find((t) => t.name === obj.linkedTemplateID);

        if (template && template.data) {
          const fields = flatMap(
            JSON.parse(template.data).pages,
            (page) => page.elements
          )
            .filter((element) => element.forwardable)
            .map((element) => element.name);
          choicesCallback(fields);
        } else {
          choicesCallback([]);
        }
      },
    });
  });
};

export const hasPendingScreenings = (screenings) => {
  if (!screenings) return false;

  const unCompleted = screenings.findIndex((t) => t.completed !== "display");
  return unCompleted !== -1 ? true : false;
};

export const setTableHeaderAttributes = (options) => {
  let tdElements = options.htmlElement.querySelectorAll("td");
  let thElements = options.htmlElement.querySelectorAll("th");

  let tdElementsLengthCheck = tdElements.length > 0;
  let thElementsLengthCheck = thElements.length > 0;

  if (thElementsLengthCheck) {
    for (let i = 0; i < thElements.length; i++) {
      let currentHeader =
        tdElementsLengthCheck && !!tdElements[i] ? tdElements[i].headers : "";
      thElements[i].setAttribute("id", currentHeader);
    }
  }
};
