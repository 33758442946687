{ observer } = require('mobx-react')
{ withEscapeHandler } = require('components/elements/new-modal')
{ div, p, strong, ul, li, button } = Exim.DOM

PopUp = withEscapeHandler((props) =>
  return React.createElement(React.Fragment, null,
    div className: 'VideoVisitPopUp-overlay', onClick: props.close
    props.children
  ))

VideoVisitPopUp = Exim.createView module.id,
  componentDidMount: ->
    if @props.keyBoardPress
      @focusOnPopUp()

  componentDidUpdate: ->
    if @props.keyBoardPress
      @focusOnPopUp()

  focusOnPopUp: ->
    modalRoot = document.querySelector('#modal-root')
    modalRoot.getElementsByClassName("VideoVisitPopUp").item(0).focus()

  openVideoCallWindow: (url) ->
    videoCallTab = window.open()
    videoCallTab.opener = null
    videoCallTab.location = url

  handleStartVideo: ->
    { closeModal, video_conference } = this.props
    this.openVideoCallWindow(video_conference.url)
    closeModal()

  handleCancel: ->
    this.props.closeModal()

  render: ->
    { rect: { bottom, right }, abort } = this.props

    return React.createElement(PopUp, {close: abort},
      div className: 'VideoVisitPopUp', role: "dialog", style: {top: "#{bottom}px", left: "#{right - 28}px"}, tabIndex: 0,
        div className: 'VideoVisitPopUp-statement',
          strong 'Start Video Session ?'
          p 'Selecting "Start Video" will initiate the virtual session.'
          strong 'At the time of visit:'
          ul null,
            li null,
              'Verify two patient identifiers'
            li null,
              'Verify the member is in a state where you are licensed'
            li null,
              'Check in your patient'
        div className: 'VideoVisitPopUp-action-btns',
          button className: 'VideoVisitPopUp-action-btns--cancel', onClick: @handleCancel,
            'Cancel'
          button className: 'VideoVisitPopUp-action-btns--start', onClick: @handleStartVideo,
            'Start Video'
    )

module.exports = observer(VideoVisitPopUp)
