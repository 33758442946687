export const keyboardScrollTable = (event) => {
  const isMessageItem =
    document.activeElement.classList.contains("MessagesList-item");
  if (isMessageItem) {
    const messageNumber = Number(document.activeElement.id);
    const isFirstItem = messageNumber === 1;
    const isLastItem =
      messageNumber === document.querySelectorAll(".MessagesList-item").length;
    const nextMessageNumber = messageNumber + 1;
    const previousMessageNumber = Number(document.activeElement.id) - 1;

    if (!isFirstItem && event.keyCode === 38) {
      document.getElementById(`${previousMessageNumber}`).focus();
    }

    if (!isLastItem && event.keyCode === 40) {
      document.getElementById(`${nextMessageNumber}`).focus();
    }
  }
};