MessagesList = require './MessagesList'
Messages     = require 'components/mixins/Messages'
{appStore} = require 'stores'
{messageFlags} = require 'config'

utils = require 'lib/utils'
{cx}  = Exim.helpers
{div, tch, fa, span} = Exim.DOM

type = 'flagged'

InboxMessage = Exim.createView module.id,
  type: type

  contextTypes:
    router: React.PropTypes.object.isRequired

  show: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    name = @props.data?.from?.name
    avatar_url = @props.data?.from?.avatar_url
    subject = @props.data?.subject
    @context.router.push pathname: "/messages/received/#{@props.data.id}", query: {name, avatar_url, subject, filter: type}

  getDefaultProps: ->
    data: subject: ''

  render: ->
    {data} = @props

    opts = cx
      'is-unread': data.unread
      attachments: data.document_ids?.length > 0

    flag = messageFlags.find((f) => f.id == data.flags)

    tch className: "TableBody-row MessagesList-item tr #{opts}", handler: @show,
      if flag
        div style: {color: flag.color}, className: 'TableBody-column MessagesList-item-flag',
          fa flag.icon
      else
        div className: 'TableBody-column MessagesList-item-flag'
      div className: 'TableBody-column MessagesList-item-title u-1of3',
        data.from.name
      div className: 'TableBody-column MessagesList-item-subject u-1of3',
        if data.attachments
          span null,
            fa 'paperclip'
        utils.getSubject(data.subject)
      div className: 'TableBody-column MessagesList-item-dateTime u-1of3',
        utils.formatDateShort(data.date)

module.exports = Inbox = Exim.createView module.id,
  mixins: [Messages]
  type: type

  render: ->
    MessagesList
      messages: @getMessages()
      loading: @isFetching() and not @state.flaggedMessagesFetched
      View: InboxMessage
      fields: ['from', 'subject', 'received']
      pageControls: @pageControls
      page: @state[@type].page
      pages: @state[@type].pages
      type: @type
      sort: @props.sort
      onSortChange: @props.onSortChange
      onLoad: @props.onLoad
      onUnload: @props.onUnload
