import { createElement } from "react";

/*
EXIM SHIM:
A thunk that coerces React Components to support Exim's element API (as parents or children)
Useful for when you need React 16 class features (portals, error boundaries)
*/
export const EximShim = (component) => {
  return (props, children) => {
    // NOTE: Checking the name and/or typing of Exim children is unreliable
    return createElement(
      component,
      props,
      // Coerces shimmed children to components as well - doesn't need to be recursive
      typeof children === "function" ? createElement(children) : children
    );
  };
};

/*
EXAMPLE A: Using React in-between Exim tags (.coffee files)

// Shim your imports
{EximShim} = require('lib/utils')
AsyncPortal = EximShim require 'components/util/AsyncPortal'
ContentHeaderTitle = EximShim require 'components/elements/ContentHeaderTitle'

// Use Exim syntax with these components
...
main id: 'mainContent',
  AsyncPortal selector: '.ContentHeader-group',
    ContentHeaderTitle

// OR - use Exim tags/views as children
...
main id: 'mainContent',
  AsyncPortal selector: '.ContentHeader-group',
    div className: 'ContentHeader-title ContentHeader-title--with-chevron', 'Test'
*/

/*
EXAMPLE B: Decorating JSX components with shims

// Shim your imports
{EximShim} = require('lib/utils')
AsyncPortal = EximShim require 'components/util/AsyncPortal'
ContentHeaderTitle = EximShim require 'components/elements/ContentHeaderTitle'

// Use Exim syntax with these components
...
main id: 'mainContent',
  AsyncPortal selector: '.ContentHeader-group',
    ContentHeaderTitle

// OR - use Exim tags/views as children
...
main id: 'mainContent',
  AsyncPortal selector: '.ContentHeader-group',
    div className: 'ContentHeader-title ContentHeader-title--with-chevron', 'Test'
*/

/*
Example C: Using Exim Views inside React Components

const FontSizeToFit = require('components/app/FontSizeToFit')
...
return (
  <div className='foo'>
    {FontSizeToFit({ className: 'bar' }, (
      <span>Passed!</span>
    ))}
  </div>
)

// ...see the JSXShim utility if you want to use JSX syntax with Exim views

*/
