{patientStore, appStore} = require 'stores'
ReactDOM = require 'react-dom'
{debounce} = require 'lodash'
{cx} = Exim.helpers
{div, tch, fa, p, table, tbody, tr, td, th, span, input} = Exim.DOM

UpdateEHRModal = Exim.createView module.id,
  mixins: [
    patientStore.connect('patient', 'summaryLoading')
  ]

  getInitialState: ->
    existingSummary: {},
    newSummary: {},
    ehrId: ''

  componentDidMount: ->
    return if !@props.ehrId
    patientStore.actions.fetchPatientSummary(@props.ehrId).then (data) =>
      @setState existingSummary: data[0]

  onIdChange: (evt) ->
    @setState({
      ehrId: evt.target.value,
      newSummary: {}
    }, @fetchSummary)

  fetchSummary: debounce(() ->
    return if @state.ehrId is ''
    patientStore.actions.fetchPatientSummary(@state.ehrId).then (data) =>
      @setState newSummary: data[0]
  , 500, {leading: true})

  onCancel: (evt) ->
    evt.preventDefault()
    @props.close?()

  onConfirm: (evt) ->
    evt.preventDefault()
    return if @state.ehrId is '' or !@state.newSummary.first_name
    patientStore.actions.updateEhrId(@state.ehrId).then () =>
      @props.close?()

  render: ->
    console.log @props
    div className: 'UpdateEHRModal',
      div className: 'UpdateEHRModal-title', 'Update EHR ID'
      div className: 'UpdateEHRModalBody',
        div className: 'UpdateEHRModal-warning',
          div className: 'UpdateEHRModal-icon',
            fa 'exclamation-triangle fa-lg'
          div style: {display: 'inline-block', maxWidth: '85%'},
            p 'Please be careful when updating this ID. If the wrong Elation ID is linked, this patient will see another patient\'s private data.'
        div className: 'UpdateEHRModal-input',
          span style: {fontWeight: '600'}, 'Link ID'
          input className: "UpdateEHRModal-input-field #{cx 'error': @state.error?.error is 'Not found'}", placeholder: 'EHR ID', value: @state.ehrId, onChange: @onIdChange
          if @state.summaryLoading then fa 'circle-o-notch fa-spin'
        table className: 'UpdateEHRModal-table',
          tbody {},
            tr {},
              th ''
              th 'Portal'
              th 'New EHR',
                fa "check-circle #{cx 'EHR-Verified': @state.newSummary.first_name}"
              th 'Existing EHR'
            tr {},
              td 'First Name'
              td @state.patient.first_name
              td @state.newSummary.first_name or ''
              td @state.existingSummary.first_name or ''
            tr {},
              td 'Middle Name'
              td @state.patient.middle_name
              td @state.newSummary.middle_name or ''
              td @state.existingSummary.middle_name or ''
            tr {},
              td 'Last Name'
              td @state.patient.last_name
              td @state.newSummary.last_name or ''
              td @state.existingSummary.last_name or ''
            tr {},
              td 'DOB'
              td @state.patient.birthdate
              td if @state.newSummary.dob then moment(@state.newSummary.dob).format('MM/DD/YYYY') else ''
              td if @state.existingSummary.dob then moment(@state.existingSummary.dob).format('MM/DD/YYYY') else ''
            tr {},
              td 'Sex Assigned at Birth'
              td @state.patient.gender
              td @state.newSummary.sex or ''
              td @state.existingSummary.sex or ''
      div className: 'UpdateEHRModal-footer',
        tch className: 'Button Button--cancel Button--pointer', handler: @onCancel, 'Cancel'
        tch className: "Button Button--primary Button--icon-divided Button--pointer #{cx 'is-disabled': !@state.newSummary.first_name}", handler: @onConfirm, 'Confirm'


module.exports = UpdateEHRModal
