"use strict";
const { appStore } = require("stores");
const { client } = require("config");
const Main = require("components/elements/main");

const AuthWrapper = React.createClass({
  displayName: "AuthWrapper",
  mixins: [appStore.connect("config")],

  renderLogo() {
    let { assets = {} } = this.state.config;
    const { logoAssetName = "logo_on_splash", logoClassName = "" } = this.props;
    const logoOnAttribute = assets[logoAssetName];
    return (
      <img
        className={`Auth-logo ${logoClassName}`}
        alt=""
        {...logoOnAttribute}
      />
    );
  },

  renderFooter() {
    if (client === "apple") {
      return (
        <footer className={"AuthCard-footer"}>
          Are you in Santa Clara Valley?
          <a
            className="AuthCard-footer-link"
            href="https://apple-wellnesscenter.com/"
            target="_blank"
          >
            {" "}
            Click here
          </a>
        </footer>
      );
    }
  },

  render() {
    let { title, className = "" } = this.props;

    return (
      <div className={`Auth-wrapper ${className}`}>
        <div role="banner">
          {this.renderLogo()}
          {title && <h1 className="AuthCard-label">{title}</h1>}
        </div>
        <Main>
          {this.props.children}
          {this.renderFooter()}
        </Main>
      </div>
    );
  },
});

module.exports = AuthWrapper;
