import Step from "components/mixins/Step";
import { Form, CredentialsForm } from "components/elements";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import CheckInFooter from "../check-in-footer";
import checkInStore from "stores/new-check-in";
import oldCheckInStore from "stores/checkin";

const CrossoverStep = React.createClass({
  displayName: "CrossoverStep",
  mixins: [Step],

  componentWillMount() {
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
  },

  async afterSubmit() {
    let { next } = checkInStore.getStep();

    try {
      await oldCheckInStore.actions.update({
        ...this.getStepData(),
        portal: checkInStore.portal,
        finished: next.id == "thanks",
      });

      next.activate();
    } catch {
      let { error, errorField: field } = this.state;
      if (!error) return;

      let ref;
      if (field == "email") ref = this.emailRef;
      if (field == "password") ref = this.passwordRef;
      if (!ref) return;

      let input = ref.current;
      input.setCustomValidity(error);
      input.reportValidity();
    }
  },

  async skipSignUp() {
    let { next } = checkInStore.getStep();

    await oldCheckInStore.actions.update({
      ...this.getStepData(true),
      finished: next.id == "thanks",
    });

    next.activate();
  },

  renderOAuthMessage() {
    return (
      <div className="ContentBlock">
        <h3>Single sign-on access</h3>
        <p>
          To use your employer provided single-sign-on account, skip account
          creation here and activate your account from the portal sign in page
          at a later time.
        </p>
      </div>
    );
  },

  render() {
    return (
      <Form
        className="CheckinForm"
        dataSource={checkInStore.portal}
        afterSubmit={this.afterSubmit}
      >
        <h1>Account sign up</h1>
        <p className="CheckinForm-row CheckinForm-row--desc CheckinForm-row--no-margin">
          Sign up for a your personalized online health profile!
        </p>
        <p className="CheckinForm-row CheckinForm-row--desc CheckinForm-row--no-margin">
          Manage your Health Score, schedule appointments, private message your
          doctors, renew your prescription refills, and review your lab results
          from anywhere, at any time
        </p>
        <div className="ContentBlock">
          <h3>Create user account</h3>
          <CredentialsForm
            emailRef={this.emailRef}
            passwordRef={this.passwordRef}
          />
        </div>
        {checkInStore.hasOAuth && this.renderOAuthMessage()}
        <CheckInFooter>
          <button className="Button" onClick={this.skipSignUp}>
            Skip
          </button>
        </CheckInFooter>
      </Form>
    );
  },
});

export default withRouter(observer(CrossoverStep));
