d3Selection = require 'd3-selection'

exports.ranges = (graph) ->
  graph.ranges?.map (range) ->
    # debugger if graph.id is 70
    if range.value
      range.value = parseInt(range.value)
      range.min = range.value - 0.5
      range.max = range.value + 0.5
    else
      range.min = parseFloat(range.min)
      range.max = parseFloat(range.max)
    range.min = 0 if isNaN range.min
    range.max = 0 if isNaN range.max
    range

# TODO: review.
exports.datasets = (graph) ->
  separator = graph.dataSeparator
  return [] if graph.values.length is 0
  first = graph.values[0].value
  count = first.toString().split(separator).length
  for index in [0...count]
    graph.values
      .map (item) ->
        value = parseFloat item.value.toString().split(separator)[index]
        if isNaN value
          console.error 'Graph (%s) has invalid string value "%s"',
            graph.name, item.value
          needed = graph.ranges?.find((r) => r.name == item.value)
          neededIndex = graph.ranges?.indexOf needed
          neededIndex = '???' if neededIndex is -1 or neededIndex is undefined
          console.warn 'id="%s", current="%s", needed="%s"',
            graph.id, item.value, neededIndex
          value = 0
        value = +value.toFixed(1) if graph.name in ['BMI', 'Weight']
        {date: new Date(item.date), value}
      .sort (a, b) ->
        +a.date - +b.date

exports.lineType = (graph) ->
  graph.line_type

exports.lastValue = (graph, datasets) ->
  values = datasets.map (set) ->
    set[set.length - 1].value
  values.join(graph.dataSeparator)

getRangeValue = (ranges, value) ->
  if ranges?.length && ranges[0].value != undefined
    str = value.toString()
    filtered = ranges.filter (range) -> range.value.toString() is str
    first = filtered[0]
    if first and first.name
      first.name
    else
      first?.name
  else
    value

exports.lastValueLabel = (graph, datasets) ->
  getRangeValue exports.ranges(graph), exports.lastValue(graph, datasets)

exports.labelFor = (startVal, datasets, graph, index) ->
  if datasets.length > 1
    value = datasets.map((d) -> d[index].value).join(graph.dataSeparator)
  value = getRangeValue exports.ranges(graph), value or startVal
  value ?= startVal
  if value is undefined
    console.error "Graph '#{graph.name}' has undefined value at index #{index}"

  if graph.units
    "#{value} #{graph.units}"
  else
    "#{value}"

exports.colorFor = (value, ranges) ->
  return '0' unless ranges
  len = ranges.length
  range = ranges.filter (range) ->
    value >= range.min && value <= range.max
  color = if range[0]
    range[0]?.color
  else if value < ranges[0].min
    ranges[0].color
  else if value > ranges[ranges.length - 1].max
    ranges[ranges.length - 1].color
  color or 0

exports.statusClass = (graph, datasets) ->
  if lastValue = exports.lastValue(graph, datasets)
    "is-color-#{exports.colorFor(lastValue, exports.ranges(graph))}"
  else
    ''

exports.isMetricGood = (graph, datasets) ->
  lastValue = exports.lastValue(graph, datasets)
  !!(lastValue) and (+exports.colorFor(lastValue, exports.ranges(graph)) in [0,1])

exports.wrap = (text, width) ->
  text.each ->
    text = d3Selection.select(this)
    words = text.text().split(/\s+/).reverse()
    word = null
    line = []
    lineNumber = 0
    lineHeight = 1.1
    y = text.attr('y')
    x = text.attr('x')
    dy = parseFloat(text.attr('dy'))
    tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('dy', dy + 'em')
    while word = words.pop()
      line.push word
      tspan.text line.join(' ')
      if tspan.node().getComputedTextLength() > width
        line.pop()
        tspan.text line.join(' ')
        line = [ word ]
        tspan = text.append('tspan').attr('x', x).attr('y', y).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word)
    return
  return

Object.seal exports
