request = require 'lib/request'

module.exports = store = Exim.createStore
  path: 'clinics'
  actions: [
    'fetchClinics'
  ]

  initial:
    clinics: []

  fetchClinics:
    on: (ef_id) ->
      request.get '/v1/clinics_config', {ef_id}
    did: (data) ->
      @set {clinics: data.results}
    didNot: (response) -> Promise.resolve()
