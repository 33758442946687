timelineStore    = require 'stores/timeline'

CreatePost = require './CreatePost'
Post = require './Post'
utils = require 'lib/utils'
{cx} = Exim.helpers
{div, h3, h5, span, label, img, fa, tch}  = Exim.DOM

Recent = Exim.createView module.id,
  mixins: [timelineStore.connect('posts', 'entries', 'timelineFetching', 'postCreating', 'orders', 'orderTypes')]

  getInitialState: ->
    expanded: null
    start: moment().subtract(3, 'month')
    date: new Date
    senders: []
    types: []
    typesChecked: false
    sendersChecked: false
    rangeChecked: false

  componentWillMount: ->
    timelineStore.actions.fetchEntries()
    timelineStore.actions.fetch()
    timelineStore.actions.fetchOrders()

  componentWillUpdate: (newProps, newState) ->
    if @state.posts.length < newState.posts.length
      newState.posts.forEach (post) ->
        newState.senders.push item: post.sender, checked: false
        newState.types.push item: post.type, checked: false

  updateFilterer: (filterer) ->
    @setState filterBy: filterer

  toggleExpanded: (id) ->
    @setState expanded: if id is @state.expanded then null else id

  refresh: ->

  onChangeRange: ->

  check: (type) -> (evt) =>
    {checked} = evt.currentTarget
    name = "#{type}Checked"
    state = {}
    state[name] = !@state[name]
    @setState state

  render: ->
    {posts, orders, orderTypes, expanded, entries} = @state
    div className: 'posts',
      CreatePost({post: {}, toggleExpanded: @toggleExpanded, expanded: expanded})
      posts.filter(@props.filterer).map (post) =>
        Post({post: post, toggleExpanded: @toggleExpanded, expanded: expanded, key: post.id, entries: entries})

module.exports = Recent
