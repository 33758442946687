"use strict";
const { transaction, autorun, extendObservable, observe } = require("mobx");
const { computed } = require("lib/utils");
const { ISO_DATE } = require("lib/formats");
const Slot = require("models/slot");
const synopsisStore = require("stores/new-synopsis");
const observedSet = new WeakSet();
const request = require("lib/new-request");
const { flatMap, flattenDepth } = require("lodash");

class SlotsStore {
  constructor() {
    return extendObservable(this, {
      _slots: new Map(),
    });
  }

  async fetchSlots(params) {
    let slots = await Slot.fetchSlots(params);

    transaction(() => {
      this._deleteSlots(params);
      this.addSlots(slots);
    });
  }

  async fetchConflicted() {
    let slots = await Slot.fetchConflicted();

    transaction(() => {
      this.conflictedSlots.forEach((slot) => {
        this.deleteSlot(slot);
      });

      this.addSlots(slots);
    });
  }

  async clearSlotsByRange(params) {
    const response = await request.post("/v1/slots/delete_date_range", params);
    return response;
  }

  hasSlots(provider, date) {
    let slots = this.getSlots(provider, date);
    return slots.length > 0;
  }

  getSlots(provider, date) {
    if (!this._slots.has(provider.id)) return [];

    let dates = this._slots.get(provider.id);
    let isoDate = date.format(ISO_DATE);
    return dates.get(isoDate) || [];
  }

  updateSlot(slot) {
    let slots = this._getOrCreateSlots(slot.provider, slot.date);
    let index = slots.findIndex((s) => s.id == slot.id);
    if (index == -1) return;
    const newSlot = slot;
    newSlot.series = slots[index].series;

    slots.splice(index, 1, newSlot);

    autorun(() => {
      if (slot.isConflicted && !slot.appointments.length) {
        this.deleteSlot(slot);
      }
    });
  }

  addSlot(slot) {
    if (!observedSet.has(slot)) {
      observedSet.add(slot);
      observe(slot, "date", ({ oldValue, newValue }) => {
        if (oldValue.isSame(newValue)) return;

        let slots = this.getSlots(slot.provider, oldValue);
        let index = slots.findIndex((s) => s.id == slot.id);
        if (index == -1) return;

        slots.splice(index, 1);
        this.addSlot(slot);
      });
    }

    let slots = this._getOrCreateSlots(slot.provider, slot.date);
    let index = slots.findIndex((s) => s.id == slot.id);
    if (index == -1) {
      slots.push(slot);
    } else {
      slots.splice(index, 1, slot);
    }

    autorun(() => {
      if (slot.isConflicted && !slot.appointments.length) {
        this.deleteSlot(slot);
      }
    });
  }

  addSlots(slots) {
    transaction(() => {
      slots.forEach((slot) => {
        this.addSlot(slot);
      });
    });
  }

  getSeries({ id }) {
    return this.slots.filter((slot) => {
      return slot.isRepeated && slot.series.id == id;
    });
  }

  deleteSlot(slot) {
    let slots = this.getSlots(slot.provider, slot.date);
    let index = slots.findIndex((s) => s.is(slot));
    if (index != -1) slots.splice(index, 1);
  }

  deleteSeries(series) {
    transaction(() => {
      this.getSeries(series).forEach((slot) => {
        this.deleteSlot(slot);
      });
    });
  }

  _getOrCreateSlots(provider, date) {
    let id = (provider && provider.id) || null;

    if (!this._slots.has(id)) {
      this._slots.set(id, new Map());
    }

    let dates = this._slots.get(id);
    let isoDate = date.format(ISO_DATE);
    if (!dates.has(isoDate)) {
      dates.set(isoDate, []);
    }

    return dates.get(isoDate);
  }

  _deleteSlots(params) {
    let provIds = [];
    if (params.providerId) {
      provIds = [params.providerId];
    } else if (params.providerType) {
      let { site } = synopsisStore;
      provIds = site.providers
        .filter((prov) => prov.type == params.providerType)
        .map((prov) => prov.id);
    }

    let isoDates = [];
    if (params.date) {
      isoDates = [params.date];
    } else if (params.dateRange) {
      isoDates = params.dateRange.by(1, "day");
    }

    isoDates = isoDates.map((date) => date.format(ISO_DATE));

    for (let provId of provIds) {
      let dates = this._slots.get(provId);
      if (!dates) continue;

      for (let isoDate of isoDates) {
        dates.set(isoDate, []);
      }
    }
  }

  findSlot(id) {
    return this.slots.find((slot) => slot.id == id);
  }
}

computed(SlotsStore.prototype, {
  get slots() {
    let rough = Array.from(this._slots.values(), (dates) => {
      return [...dates.values()];
    });

    return flattenDepth(rough, 2); // TODO: use generator
  },

  get conflictedSlots() {
    return this.slots.filter((slot) => slot.isConflicted);
  },

  get conflictedAppts() {
    return flatMap(this.conflictedSlots, (slot) => slot.appointments).filter(
      (appt) => appt.isScheduled && appt.isConflicted
    );
  },
});

const slotsStore = new SlotsStore();
// TODO: Disabled for now, huge (400+ entries) conflict queue causing massive UI lag.
// autorun(() => {
//   slotsStore.fetchConflicted()
// })

module.exports = slotsStore;
