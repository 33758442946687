{cx} = Exim.helpers
{div, icn, span, input, fa, label} = Exim.DOM

DateRange = require 'components/elements/DateRange'
providers = ['Anand Singh, MD', 'Andrea Sullivan, MD', 'Melissa Lin, MD']
today = moment()

config = require 'config'
utils = require 'lib/utils'

Conditions = Exim.createView module.id,

  getInitialState: ->
    provider: providers[0]
    siblings: []

  toggleSibling: (sibling) -> =>
    {siblings} = @state
    if siblings.indexOf(sibling) >= 0
      siblings = siblings.filter((item)->item isnt sibling)
    else
      siblings.push(sibling)
    @setState {siblings}

  onChangeRange: (dates) ->
    console.log dates

  render: ->
    {siblings} = @state
    div className: 'widget-item conditions',
      div className: 'body',
        div className: 'widget-item-range',
          div className: 'axis',
            '2008'
            '2010'
            '2012'
            '2014'
            'TODAY'
          DateRange
            start: today.subtract(7, 'years')
            max: today
            onChange: @onChangeRange
            multiple: true
            className: 'xo-range'
        div className: 'widget-item-siblings',
          config.checkinSiblings.map (sibling) =>
            checked = siblings and sibling in siblings

            div className: "pill sibling #{cx {checked}}", key: sibling,
              label null,
                input type: 'checkbox', onChange: @toggleSibling(sibling), checked: checked
                utils.capitalize(sibling)

module.exports = Conditions
