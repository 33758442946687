{span} = Exim.DOM
MAX_DOTS = 3
INTERVAL = 500
INITIAL  = 1
CHAR = '.'

Progress = Exim.createView module.id,
  getDefaultProps: ->
    className: ''
    max:       MAX_DOTS
    interval:  INTERVAL
    initial:   INITIAL
    char:      CHAR
    wrapper: span

  getInitialState: ->
    dots: @props.initial

  componentWillMount: ->
    @start()

  componentWillUnmount: ->
    @end()

  start: ->
    this.int = setInterval(@updateDots, @props.interval)

  end: ->
    clearInterval(this.int)

  updateDots: ->
    {max, initial} = @props
    {dots} = @state

    dots = if dots >= max then initial else dots + 1

    @setState {dots}

  render: ->
    {dots} = @state
    {wrapper, char, className} = @props

    wrapper className: "Progress #{className}",
      [0...dots].map (i) -> span key: i, char

module.exports = Progress
