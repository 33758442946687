import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

const AppointmentTypesAccordion = ({
  appointmentTypes,
  handleCloseAccordionClick,
  handleScheduleAppointmentTypeClick,
  getAccordionPanelText,
}) => {
  return (
    <div className="Appointments-types" id="appointment-details-accordion">
      <button
        className="Appointments-types__close"
        aria-label="Close appointment types dialog"
        onClick={handleCloseAccordionClick}
      />
      <Accordion allowZeroExpanded={true}>
        {appointmentTypes.map((type, index) => (
          <AccordionItem key={type && type.id}>
            <AccordionItemHeading aria-level={1}>
              <AccordionItemButton
                data-testid={`accordion-item-button-${index}`}
              >
                <h4 className="title">{type.nameAndDuration}</h4>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="accordion-body">
                <ul>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: getAccordionPanelText(type),
                    }}
                  />
                </ul>
                <button
                  className="Button Button--schedule-appointment"
                  onClick={() => handleScheduleAppointmentTypeClick(type)}
                >{`Schedule ${type.nameAndDuration}`}</button>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

module.exports = AppointmentTypesAccordion;

AppointmentTypesAccordion.propTypes = {
  handleCloseAccordionClick: PropTypes.func.isRequired,
  handleScheduleAppointmentTypeClick: PropTypes.func.isRequired,
  getAccordionPanelText: PropTypes.func.isRequired,
  appointmentTypes: PropTypes.any.isRequired,
};
