/* global Exim, React, moment */
const { PropTypes, Fragment } = React;
import { Link } from "react-router";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import screeningStore from "stores/screening"; // MobX
import authStore from "stores/new-auth"; // MobX
import InteractiveSurvey from "components/screen/InteractiveSurvey";
import ReadOnlySurvey from "components/screen/ReadOnlySurvey";
import { withFeatureFlag } from "components/util/FeatureFlag";

/*
AD-HOC SCREENINGS
Container that manages the data flow for ad-hoc screenings
*/
class AdHocScreening extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tabIndex: 1, priorScreening: null, prompt: null };
    this.onComplete = this.onComplete.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.setPriorScreening = this.setPriorScreening.bind(this);
  }

  // CDM - fetch patient responses for this screening ID, save in state, pass to Survey
  componentDidMount() {
    this.setPriorScreening(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.template.id !== nextProps.template.id) {
      this.setPriorScreening(nextProps);
    }
  }

  setPriorScreening(currentProps) {
    const {
      patient_id,
      template: { id: screening_template_id },
    } = currentProps;
    screeningStore
      .fetchPatientScreeningById(patient_id, screening_template_id, {
        limit: 1,
      })
      // A screening has-many responses. Get the latest one, a computed prop
      .then(() =>
        this.setState({ priorScreening: screeningStore.priorScreeningData })
      );
  }
  onComplete(data) {
    const { template, patient_id, appointment_id } = this.props;
    const { template_interaction_id } = template;
    const params = { template_interaction_id, appointment_id, data };
    try {
      screeningStore
        .createScreeningData(patient_id, params)
        // NOTE: This is bugged, some render logic needs to be tweaked to fix this
        .then((screening) =>
          this.setState({
            prompt: `Health screening saved! (${moment(
              screening.updated_at
            ).format("LT")})`,
            priorScreening: screening,
          })
        );
    } catch (err) {
      this.setState({ prompt: "Sorry - an unexpected error has occured" });
      // Throw error anyways, so it gets logged to Rollbar
      console.error(err);
    }
  }
  handleTabClick(e) {
    e.stopPropagation();
    const { tabIndex } = e.target;
    this.setState({ tabIndex });
  }
  render() {
    const { title, template, appointment_id } = this.props;
    const { tabIndex, priorScreening, prompt } = this.state;
    // Render a warning instead if no appointment has been selected
    if (!appointment_id) {
      const { isProxying } = authStore.me;
      return (
        <Fragment>
          <h3 className="AdHoc-Screening--title">No appointment selected</h3>
          <p>
            To begin a health screening, start a screening using the dropdown
            menu for an appointment.
          </p>
          {isProxying ? (
            <Link to="/appointments" className="Button Button--full">
              View Patient Appointments
            </Link>
          ) : (
            <Link to="/schedule" className="Button Button--full">
              View Daily Schedule
            </Link>
          )}
        </Fragment>
      );
    }
    // Define the selected view
    let SelectedPanel;
    switch (tabIndex) {
      // case 1: Administer (aka default)
      case 2:
        // Triple check that we've got a complete dataset to pass - prevents future bugs
        if (priorScreening && priorScreening.data) {
          SelectedPanel = (
            <ReadOnlySurvey
              template={toJS(template.data)}
              data={priorScreening.data}
            />
          );
          break;
        }
      default:
        const priorData =
          priorScreening && priorScreening.status === "active"
            ? priorScreening.data
            : "null";
        SelectedPanel = (
          <InteractiveSurvey
            template={toJS(template.data)}
            data={priorData}
            onComplete={this.onComplete}
            prompt={prompt}
          />
        );
        break;
    }

    return (
      <Fragment>
        <h3 className="AdHoc-Screening--title">{title}</h3>
        <section className="AdHoc-Screening--tabs">
          {priorScreening ? (
            <Fragment>
              <button
                tabIndex="1"
                aria-label="Administer"
                onClick={this.handleTabClick}
                className={tabIndex === 1 ? "is-selected" : ""}
              >
                Administer Screening
              </button>
              <button
                tabIndex="2"
                aria-label="Previous"
                onClick={this.handleTabClick}
                className={tabIndex === 2 ? "is-selected" : ""}
              >
                {`Previous Response - ${moment(
                  priorScreening.updated_at
                ).format("MM/DD/YYYY")}`}
              </button>
            </Fragment>
          ) : (
            <small>No prior responses recorded</small>
          )}
        </section>
        <section>{SelectedPanel}</section>
      </Fragment>
    );
  }
}

AdHocScreening.propTypes = {
  patient_id: PropTypes.number.isRequired,
  appointment_id: PropTypes.number, // Warning thrown in UI
  title: PropTypes.string,
  // Template: The config, retrieved in Step.coffee via screeningStore. Observable
  template: PropTypes.shape({
    id: PropTypes.number.isRequired,
    template_interaction_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string,
    data: PropTypes.shape({
      pages: PropTypes.any,
    }).isRequired,
  }),
};
AdHocScreening.defaultProps = {
  patient_id: null,
  appointment_id: null,
  template_interaction_id: null,
  title: "Loading...",
  template: null, // Do not change, empty objects are truthy
};

// module.exports = observer(AdHocScreening)
module.exports = withFeatureFlag(
  observer(AdHocScreening),
  "hasScreeningFramework",
  {
    warn: true,
    message:
      "The XO Portal team is finishing work on the Health Screening framework",
  }
);
