{appStore} = require 'stores'
{div, fa, img, span, a, footer} = Exim.DOM
{client} = require 'config'
{isEmail} = require 'lib/utils'

currentYear = new Date().getFullYear()

Footer = Exim.createView module.id,
  listen: [ 'app/config', 'auth/user' ]

  getConsent: (href, suffix) ->
    name = 'Consents'
    name += " (#{suffix})" if suffix
    a key: href, href: href, target: '_blank', className: 'FooterLinks-item', name

  renderOther: ({other}) ->
    if Array.isArray(other)
      return other.map((l) => a key: l[1], href: l[1], target: '_blank', className: 'FooterLinks-item', l[0])
    else
      return []

  renderConsents: ({consents}) ->
    if Array.isArray(consents)
      return consents.map((site) => this.getConsent(site[1], site[0]))
    else if consents
      return this.getConsent(consents)

  render: ->
    {className} = @props
    {links} = this.state.config
    {clinic_contact} = @state.user
    contactIsEmail = isEmail(clinic_contact)

    return footer className: "Footer #{className or ''}", role: "contentinfo",
      div className: 'FooterBody',
        if clinic_contact
          div className: 'FooterContact',
            span className: 'FooterContact-text',
              fa if contactIsEmail then 'envelope' else 'phone'
              'Contact us '
            span className: 'FooterContact-contact',
              a 'aria-label': "contact-us-at-#{clinic_contact}", href: (if contactIsEmail then "mailto:#{clinic_contact}" else "tel:#{clinic_contact}"), clinic_contact
        div className: 'FooterTop',
          div className: 'FooterLinks',
            a href: links.terms, target: '_blank', className: 'FooterLinks-item', 'Terms and Conditions'
            if links.privacy
              a href: links.privacy, target: '_blank', className: 'FooterLinks-item', 'Privacy Policy'
            this.renderConsents(links)
            this.renderOther(links)
            span className: 'FooterLinks-item', "© #{currentYear}"

module.exports = Footer
