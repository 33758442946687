"use strict";
const {
  withEscapeHandler,
  closeModal,
} = require("components/elements/new-modal");

class ExitConfirmation extends React.Component {
  render() {
    const {
      onExit,
      children,
      continueText,
      onContinue = closeModal,
    } = this.props;

    const onClose = () => {
      closeModal();
      onExit();
    };

    const defaultTextSection = (
      <>
        <div>
          <span>
            You have not answered all of the assigned screening questions. If
            you wish to leave now, your information will be saved so you can
            pick up where you left off.
          </span>
        </div>
        <p />
        <div>
          <span>
            Please complete all of the assigned fields in order to check in for
            your visit.
          </span>
        </div>
      </>
    );

    return (
      <div className={`NewModal-wrapper`}>
        <div className={`NewModal-overlay`} style={{ background: "#2227" }} />
        <div className={`NewModal`} style={styles.wrapper} role="dialog">
          <div style={styles.text}>{children || defaultTextSection}</div>
          <div style={styles.button}>
            <button className={`NewModal-button-outline`} onClick={onClose}>
              Exit{" "}
            </button>
            <button className={`NewModal-button`} onClick={onContinue}>
              {continueText || "Continue"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

module.exports = withEscapeHandler(ExitConfirmation);

const styles = {
  wrapper: {
    width: "520px",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    borderRadius: "5px",
    padding: "20px",
  },

  text: {
    width: "100%",
    color: "#000000",
    margin: "20px 0 50px 0",
    textAlign: "left",
    wordWrap: "break-word",
    lineHeight: "normal",
    letterSpacing: "1px",
    fontFamily: "ArialMT, Arial, sans-serif",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "18px",
  },

  button: {
    display: "inline-block",
    fontWeight: "400",
    fontSize: "15px",
    float: "right",
  },
};
