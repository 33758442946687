"use strict";
const { observer } = require("mobx-react");
const authStore = require("stores/new-auth");

const CookiesNotice = () => {
  let { me } = authStore;
  if (me.isAdmin) return null;
  if (me.hasAcknowledgedCookies) return null;

  return (
    <div className="CookiesNotice">
      <span className="CookiesNotice-message">
        By using this website, you agree to our{" "}
        <a
          className="CookiesNotice-link"
          target="_blank"
          href="http://crossoverhealth.com/privacy/#cookies"
        >
          Cookie policy
        </a>
        , which is a part of our{" "}
        <a
          className="CookiesNotice-link"
          target="_blank"
          href="http://crossoverhealth.com/privacy"
        >
          Web Privacy policy
        </a>
        .
      </span>
      <button
        className="CookiesNotice-acknowledge"
        onClick={() => me.acknowledgeCookies()}
      >
        OK
      </button>
    </div>
  );
};

module.exports = observer(CookiesNotice);
