{div, input, label}  = Exim.DOM

RadioButton = Exim.createView module.id,

  render: ->
    {labelText, name, id, checked, value, onChange} = @props
    div className: 'RadionButton',
      input id: id, name: name, type: 'radio', checked: checked == value, 'aria-checked': checked == value, onChange: () -> onChange(value)
      label htmlFor: id, labelText

module.exports = RadioButton
