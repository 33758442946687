Switchery = require 'switchery-npm'
{input}  = Exim.DOM

DEFAULT_COLOR = '#64bd63'
SWIPE_POINT = 40

Switcher = Exim.createView module.id,
  getDefaultProps: ->
    className: ''

  componentDidMount: ->
    opts = {}
    opts.color = @props.color
    opts.secondaryColor = @props.secondaryColor

    @switcher = new Switchery(@refs.switcher, opts)
    @refs.switcher.addEventListener('change', @onChangeValue)
    @switcher.switcher.tabIndex = 0
    @switcher.switcher.setAttribute('aria-label', 'Opportunities Showing Toggle')
    @switcher.switcher.setAttribute('role', 'button')
    @switcher.switcher.children[0].style.border = '0.1px solid rgba(0, 0, 0, 0)'
    @switcher.switcher.addEventListener('click', @onClick)
    @switcher.switcher.addEventListener('keyup', @onKeyUp)
    @jackEl = @switcher.jack
    @jackEl.addEventListener('touchstart', @onTouchStart)
    @jackEl.addEventListener('touchmove', @onTouchMove)
    @jackEl.addEventListener('touchend', @onTouchEnd)

    if @props.checked isnt @switcher.isChecked()
      @jackEl.click()

  componentDidUpdate: (nextProps) ->
    @switcher.handleOnchange(nextProps.checked) unless @props.onChangeValue?
    @switcher.switcher.setAttribute('aria-label', "#{if @props.checked then 'Opportunities' else 'Successes'} Showing Toggle")

  componentWillUnmount: ->
    @refs.switcher.removeEventListener('change', @onChangeValue)
    @switcher.switcher.removeEventListener('click', @onClick)
    @jackEl.removeEventListener('touchstart', @onTouchStart)
    @jackEl.removeEventListener('touchmove', @onTouchMove)
    @jackEl.removeEventListener('touchend', @onTouchEnd)

  onClick: (evt) ->
    evt.stopPropagation()
    @switcher.switcher.blur() #IE11 / JAWS screen reader fix
    @switcher.switcher.focus()

  onTouchStart: (evt) ->
    evt.preventDefault()
    @prevX = evt.touches[0]?.pageX

  onTouchMove: (evt) ->
    evt.preventDefault()
    @moved = true
    @nextX = evt.touches[0]?.pageX

  onTouchEnd: ->
    diff = @prevX - @nextX
    if !@moved or (@prevX and @nextX and (@props.checked and diff > SWIPE_POINT) or (!@props.checked and diff < SWIPE_POINT))
      @jackEl.click()
    @moved = false

  onChangeValue: (evt) ->
    @props.onChangeValue?(evt.currentTarget.checked, true) unless @ignore

  onKeyUp: (evt) ->
    return unless evt?.which is 13
    @jackEl?.click()

  onChange: (evt) ->
    @props.onChange(evt)

  componentWillReceiveProps: (newProps) ->
    if !@highlighted and newProps.error
      @toggleHiglighting(true)
    else if @highlighted and !newProps.error
      @toggleHiglighting(false)

  toggleHiglighting: (state=@highlighted) ->
    color = if state then 'red' else DEFAULT_COLOR
    @switcher.options.color = color
    @switcher.colorize()
    @highlighted = state

  render: ->
    input
      type: 'checkbox'
      disabled: @props.disabled,
      className: "js-switch #{@props.className}"
      ref: 'switcher'
      onChange: @onChange
      checked: @props.checked
      onClick: @onClick
      onKeyUp: @onKeyUp

module.exports = Switcher
