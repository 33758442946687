import DateNav from "./date-nav";
import ProviderNav from "./provider-nav";
import ClearRangeNav from "./clear-range-nav";
import OOONav from "./ooo-nav";

const CalendarNav = () => {
  return (
    <div className="ScheduleCalendarNav">
      <div className="ScheduleCalendarNav-wrapper">
        <DateNav />
        <ProviderNav />
        <ClearRangeNav />
        <OOONav />
      </div>
    </div>
  );
};

module.exports = CalendarNav;
