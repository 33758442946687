import { observer } from "mobx-react";
import dvr from "mobx-react-form/lib/validators/DVR";
import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";
import hraStore from "stores/hra-survey";
import { Modal } from "components/elements/new-modal";
import AuthWrapper from "components/auth/auth-wrapper";
import HraConsentMessage from "./consent-message";
import DOBfields from "./DOBfields";
import HealthScreening from "../results/health-screening";

class HraAuth extends React.Component {
  state = {
    showModal: false,
    showHealthScreeningModal: false,
    isChecked: false,
    disabledBtnMsg: false,
  };

  openModal = (value) => (e) => {
    e.preventDefault();
    this.setState({
      [value]: true,
    });
  };

  closeModal = (value) => {
    this.setState({
      [value]: false,
    });
  };

  checkboxOnClick = () => {
    this.setState({
      isChecked: !this.state.isChecked,
      disabledBtnMsg: false,
    });

    hraStore.clearError();
  };

  onClickDisabledBtn = () => {
    !this.state.isChecked && this.setState({ disabledBtnMsg: true });
  };

  showErrorMessage = (form) => {
    const { disabledBtnMsg } = this.state;
    let message = "";

    if (hraStore.error) {
      message = hraStore.error;
    }

    if (
      form.$("day").error ||
      form.$("month").error ||
      form.$("year").error ||
      form.$("email").error
    ) {
      message = "Oops. All fields are required in their valid format.";
    }

    if (disabledBtnMsg) {
      message =
        "To proceed with the survey, please complete all fields above and agree to the policy/consent below.";
    }

    return message;
  };

  plugins = {
    dvr: dvr(validatorjs),
  };

  handlers = {
    onChange: (field) => {
      field.showErrors(false);
      hraStore.clearError();
    },
  };

  fields = [
    {
      name: "email",
      label: "Work Email Address",
      rules: "required|email|string",
      handlers: this.handlers,
    },
    {
      name: "day",
      label: "Day",
      rules: "required|string",
      handlers: this.handlers,
    },
    {
      name: "month",
      label: "Month",
      rules: "required|string",
      handlers: this.handlers,
    },
    {
      name: "year",
      label: "Year",
      rules: "required|string",
      handlers: this.handlers,
    },
  ];

  hooks = {
    onSuccess: async (form) => {
      const {
        params: { magicToken },
        router,
      } = this.props;
      const { email, day, month, year } = form.values();
      const dob = `${day}/${month}/${year}`;
      await hraStore.verify({ magicToken, email, dob });

      hraStore.isVerified && router.push(`/surveys/hra/${magicToken}/survey`);
    },
    onError: (form) => {
      const errors = hraStore.error;
      form.invalidate(errors);

      hraStore.clearError();
    },
  };

  form = new MobxReactForm(
    { fields: this.fields },
    { plugins: this.plugins, hooks: this.hooks }
  );

  render() {
    const { form } = this;
    const { isChecked, showModal, showHealthScreeningModal } = this.state;
    const { error, onVerifyScreeningStatus } = hraStore;

    return (
      <div className="Wellness">
        <AuthWrapper
          title="2019 Health Risk Assessment"
          className="HraAuth-wrapper"
          logoClassName="HraAuth-wrapper--logo"
          logoAssetName="logo_on_hra"
        >
          <div className="Hra">
            <form className="Hra-form" onSubmit={form.onSubmit}>
              <p className="Hra-welcome-message">
                Welcome to the 2019 AMAT Health Risk Assessment. Please enter
                your work email address and date of birth to verify your
                account. Once verified, your will be able to begin the survey.
              </p>

              <label htmlFor={form.$("email").id} className="Hra-email-label">
                {form.$("email").label}
              </label>
              <input
                onChange={form.$("email").onChange}
                className={
                  form.$("email").error ? "Hra-input-error" : "Hra-input"
                }
                type="email"
                field={form.$("email")}
              />

              <div className="Hra-date">
                <DOBfields form={form} />
              </div>

              <p className="Hra-form-error">
                {error && onVerifyScreeningStatus ? (
                  <a
                    className="HraScreening-link"
                    onClick={this.openModal("showHealthScreeningModal")}
                  >
                    {this.showErrorMessage(form)}. Click here to view your
                    Wellness Incentive Program Status.
                  </a>
                ) : (
                  this.showErrorMessage(form)
                )}
              </p>

              {showHealthScreeningModal && (
                <Modal
                  close={() => this.closeModal("showHealthScreeningModal")}
                  className="Modal-description HraScreeningModal-description"
                  wrapperClassName="HraModal-wrapper"
                >
                  <HealthScreening
                    title={"Your Personal Wellness Incentive Program Status"}
                    className={"HraScreening-modal"}
                    screeningStatus={onVerifyScreeningStatus}
                  />
                </Modal>
              )}

              {showModal && (
                <Modal
                  close={() => this.closeModal("showModal")}
                  title="Health Risk Assessment Notice and Consent"
                  className="Modal-description HraModal-description"
                  wrapperClassName="HraModal-wrapper"
                >
                  <div className="Modal-body HraModal-body">
                    <div className="Modal--statement Hra-statement">
                      <HraConsentMessage />

                      <div className="Hra-statement-footer">
                        <p>I have also read and consented to the</p>
                        <a
                          className="HraModal-link"
                          href="https://crossoverhealth.com/notice-of-privacy-practices/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          Notice of Privacy Practices
                        </a>
                        ,
                        <a
                          className="HraModal-link"
                          href="https://crossoverhealth.com/terms-and-conditions/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          Terms and Conditions
                        </a>{" "}
                        and
                        <a
                          className="HraModal-link"
                          href="https://microsite.ehr.com/Portals/110/Documents/Wellness-Programs-Notice.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          Applied Materials EEOC Wellness Notice.
                        </a>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}

              <div className="Hra-consent-label">
                <input
                  type="checkbox"
                  name="consent"
                  id="consent"
                  checked={isChecked}
                  onChange={this.checkboxOnClick}
                />
                <label htmlFor="consent">
                  {" "}
                  I Agree to the
                  <a className="Hra-link" onClick={this.openModal("showModal")}>
                    {" "}
                    policy/consent
                  </a>
                </label>
              </div>

              <span onClick={this.onClickDisabledBtn}>
                <button
                  type="submit"
                  onClick={form.onSubmit}
                  className={isChecked ? "Hra-button" : "Hra-disabled"}
                >
                  Verify Data
                </button>
              </span>
            </form>
          </div>
          <div className="Wellness-support">
            Contact Crossover customer support at{" "}
            <a href="mailto:wellnessincentive-amp@crossoverhealth.com ">
              wellnessincentive-amp@crossoverhealth.com
            </a>
            <br />
          </div>
        </AuthWrapper>
      </div>
    );
  }
}

export default observer(HraAuth);
