import React from "react";
import synopsisStore from "stores/new-synopsis";

const MemberTransitionBanner = () => {
  const { banner } = synopsisStore.transitionBannerInfo || {};
  if (!banner) return null;

  return (
    <div className="member-transition-banner">
      <span dangerouslySetInnerHTML={{ __html: banner }} />
    </div>
  );
};

export default MemberTransitionBanner;
