import hraStore from "stores/hra-survey";
import Card from "./card";
import Graph from "./graph";
import { promisResults } from "config";

class PromisResults extends React.Component {
  renderResultsText(value, { green, yellow, red }, type) {
    let score = null;
    if (value < red.high) {
      score = "poor";
    } else if (value >= yellow.low && value <= yellow.high) {
      score = "fair";
    } else if (value > green.low) {
      score = "good";
    }
    const results = promisResults.result[type];
    return results[score];
  }

  render() {
    const { title, value } = this.props;
    const keys = [
      "threshold",
      "high",
      "medium",
      "low",
      "your_score",
      "average_score",
    ];
    const legendLabels = [
      "High Risk",
      "Medium Risk",
      "Low Risk",
      "Your Score",
      "Average Score",
    ];
    const colors = [
      "#EAEDFF",
      "#E5001E",
      "#FAAE43",
      "#4d7c1d",
      "#000000",
      "#EAEDFF",
      "#FF00FF",
    ];
    const physicalData = hraStore.graphData("promis", "physical");
    const mentalData = hraStore.graphData("promis", "mental");

    const physicalScale = value.physical.scale;
    const mentalScale = value.mental.scale;

    return (
      <React.Fragment>
        <Card title={title} iconClass="fa-plus-square Hra-result--promis--icon">
          <div className="Hra-body">
            {promisResults.intro} <br />
            <div className="HraPromis-statement">
              <span>What this tells us: </span>
              {promisResults.message}
            </div>{" "}
            <br />
            <div className="Results--promis">
              <span>
                <b>Physical Health Score: </b>
                {value.physical.value}
              </span>
              <div className={"Results--card Hra-result--promis"}>
                {this.renderResultsText(
                  value.physical.value,
                  physicalScale,
                  "physical"
                )}
              </div>
              <div className="Results--graph">
                <Graph
                  data={physicalData}
                  keys={keys}
                  legendLabels={legendLabels}
                  colors={colors}
                  rangeThreshhold={{
                    low: physicalScale.red.low,
                    high: physicalScale.green.high,
                  }}
                  xAxisLabel={"Score"}
                  labelClassName={"Hra-axisLabel"}
                />
              </div>{" "}
              <br />
              <span>
                <b>Mental Health Score: </b>
                {value.mental.value}
              </span>
              <div className={"Results--card Hra-result--promis"}>
                {this.renderResultsText(
                  value.mental.value,
                  mentalScale,
                  "mental"
                )}
              </div>
              <div className="Results--graph">
                <Graph
                  data={mentalData}
                  keys={keys}
                  legendLabels={legendLabels}
                  colors={colors}
                  rangeThreshhold={{
                    low: mentalScale.red.low,
                    high: mentalScale.green.high,
                  }}
                  xAxisLabel="Score"
                  labelClassName={"Hra-axisLabel"}
                />
              </div>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default PromisResults;
