import checkInStore from "stores/new-check-in";
import { Input, Button } from "components/elements";
import { observer } from "mobx-react";
import { lowerCase } from "lodash";

class Contact extends React.Component {

  get contactType() {
    return this.props.name.replace("Phone", "");
  }

  get isPrimary() {
    return this.contactType == checkInStore.user.primaryContact;
  }

  setPrimary() {
    checkInStore.user.primaryContact = this.contactType;
  }

  unsetPrimary() {
    let { user } = checkInStore;
    if (user.primaryContact == this.contactType) {
      user.primaryContact = null;
    }
  }

  renderPrimary() {
    let label = lowerCase(this.props.label);

    if (this.isPrimary) {
      return (
        <Button className="AboutContact AboutContact--primary"
        onClick={() => this.unsetPrimary()}
        role='checkbox'
        aria-checked={this.isPrimary ? true : false}
        aria-label={`${label} is set as primary contact. Click to remove ${label} as primary contact`}
        >
          Primary
          </Button>
      );
    }

    return (
    
      <Button
        className="AboutContact"
        aria-label={`${label} is not set as primary contact. Click to set ${label} as primary contact`}
        onClick={() => this.setPrimary()}
        role='checkbox'
        aria-checked={this.isPrimary ? true : false}
      >
        Set as Primary
      </Button>

    );
  }

  render() {
    let { label, ...inputProps } = this.props;

    return (
      <div className="AboutStep-field AboutStep-field--contact">
        {label}
        <Input
          {...inputProps}
          id={this.id || this.contactType}
          aria-label={this.isPrimary ? `${label} (primary contact)` : label}
          onChange={() => this.setPrimary()}
          onInvalid={() => this.unsetPrimary()}
        />
        {this.renderPrimary()}
      </div>
    );
  }
}

export default observer(Contact);
