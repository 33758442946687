"use strict";
const authStore = require("stores/new-auth");
const { observer } = require("mobx-react");
const Link = require("../link");

const LaboratoriesLink = () => {
  let { user } = authStore;
  if (!user.canReadLaboratories) return null;

  return (
    <li className="SidebarNav-item">
      <Link to="/laboratories">
        <span className="SidebarNavLink-icon">
          <span aria-hidden={true} className="icon fa fa-flask" />
        </span>
        <span className="SidebarNavLink-text">Labs</span>
      </Link>
    </li>
  );
};

module.exports = observer(LaboratoriesLink);
