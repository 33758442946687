{authStore, checkinStore} = require 'stores'

config = require 'config'
screenConfig = require 'components/screen/config'
{defaultAdminRoute, defaultPatientRoute} = config

adminRoute = (isAdmin, ignoreNavigation, allowProxying, pin) ->
  if typeof isAdmin is 'object'
    {isAdmin, allowProxying, pin} = isAdmin

  isAdminRoute = isAdmin
  isAdmin = null

  mixin =
    statics:
      willTransitionTo: (transition, params, query) ->
        isActive = (name) ->
          transition.path.indexOf(states[name]?.path) >= 0
        user = authStore.get('user')
        userIsAdmin = user.admin
        userIsNotAdminOrProxying = if allowProxying
          not userIsAdmin and not user.proxying
        else
          not userIsAdmin

        if userIsAdmin and not isAdminRoute
          console.log 'mixins/adminRoute', 'Redirect to: defaultAdminRoute'
          transition.redirect defaultAdminRoute
          return
        else if isAdminRoute and userIsNotAdminOrProxying
          console.log 'mixins/adminRoute', 'Redirect to: defaultPatientRoute'
          transition.redirect defaultPatientRoute
          return
        return unless pin

        state = user.checkin_state ? 'lock'
        startRoute = states[state]?.name ? 'checkin/auth'

        if state isnt 'lock' and isActive('lock')
          console.log 'mixins/adminRoute', 'Changing state to lock'
          checkinStore.actions.changeState('lock')

        if state is 'list'
          console.log 'mixins/adminRoute', 'State is list'
          if isActive('welcome')
            console.log 'mixins/adminRoute', 'Active: welcome'
            return

          if isActive('checkin') or isActive('thanks')
            console.log 'mixins/adminRoute', 'Active: checkin or thanks'
            console.log 'mixins/adminRoute', 'Redirect to: startRoute', startRoute
            transition.redirect startRoute
        else
          if not isActive(state) and
          not (state is 'checkin' and isActive('checkinStep'))
            params = if startRoute is 'screen-step' then step: screenConfig.firstStep
            console.log 'mixins/adminRoute', 'Redirect to: startRoute', startRoute, params
            transition.redirect startRoute, params

  mixin

module.exports = adminRoute
