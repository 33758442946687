{div, strong, button} = Exim.DOM
{ appStore } = require 'stores'

ConfirmTextUpdatesModal = Exim.createView module.id,
confirmTextUpdatesCloseModal: -> 
    appStore.actions.showModal(null).then => 
      document.getElementById(@props.focusId)?.focus()

  render: ->
    div id: 'sms-reminders-modal', className: 'u-scroll', style: {display: 'flex', flexDirection: 'column', alignItems: 'center'},
      strong 'Text reminders are enabled.'
      button 'aria-label': 'Text reminders are enabled, press ok to close dialogue', style: {width: '25%', marginTop: '15px'}, id: 'ok-button-close', className: 'Button Button--primary', onClick: this.confirmTextUpdatesCloseModal, 'Ok'
      

module.exports = ConfirmTextUpdatesModal
