{cx} = Exim.helpers
{div, icn, span, input, fa} = Exim.DOM

DateRange = require 'components/elements/DateRange'
Select = require 'components/elements/Select'
doses = ['1 Dose', '2 Doses', '3 Doses']
freq = ['Four times a day', 'Before sleep', 'Once a day']
today = moment()

Medications = Exim.createView module.id,

  getInitialState: ->
    dose: doses[0]
    frequency: freq[0]

  updateDose: (dose) ->
    @setState {dose}

  updateFrequency: (frequency) ->
    @setState {frequency}

  render: ->
    div className: 'widget-item medications',
      div className: 'body',
        div className: 'widget-item-select',
          Select
            values: doses
            onChange: @updateDose
            selected: @state.dose
            angle: true
        div className: 'widget-item-select',
          Select
            values: freq
            onChange: @updateFrequency
            selected: @state.frequency
            angle: true
        div className: 'button submit',
          'Refill'

module.exports = Medications
