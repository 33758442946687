oldCheckInStore = require('stores/checkin')
checkInStore = require('stores/new-check-in').default
oldAuthStore = require('stores/auth')
authStore = require('stores/new-auth')
apptsStore = require 'stores/new-appts'
{clone} = require('lib/utils')
Fields = require './Fields'

module.exports =
  mixins: [
    Fields
    oldCheckInStore.connect('checkin', 'stepData', 'error', 'errorField', 'checkinState')
    oldAuthStore.connect('user', 'preflightCheckin')
  ]

  dataName: 'stepData'

  isPreflight: ->
    @state.preflightCheckin or @state.user.checkin_preflight

  contextTypes:
    router: React.PropTypes.object.isRequired

  componentWillMount: ->
    @step = @name or @props.location.pathname.match(/\/\w+$/g)[0].slice(1)
    oldCheckInStore.actions.setStep @step

  getCheckinData: (step) ->
    checkin = clone(@state.checkin)

    valid = {}
    (@required ?= []).forEach (key) ->
      valid[key] = false
    if checkin[step]
      Object.keys(checkin[step]).forEach (key) ->
        valid[key] = true if key isnt undefined or key isnt null
    result = {}
    errors = []
    tag = {lifestyle: 'SELECT', dental: 'RADIO'}[step]
    for key, value of this.state.stepData
      if @check(key, value, tag)
        valid[key] = true if key in @required
        result[key] = value
      else
        valid[key] = false if key in @required
    Object.keys(valid).forEach (key) ->
      errors.push(key) unless valid[key]

    if errors.length
      @setState {errors}
      return false

    checkin[step] = Object.assign({}, checkin[step], result)
    return checkin

  getStepData: (skipUpdate) ->
    {steps} = checkInStore
    stepId = checkInStore.getStep().id
    data = if skipUpdate then clone(this.state.checkin) else this.getCheckinData(stepId)
    return if !data

    data.lastStep = if data.lastStep
      prevIndex = steps.findIndex((s) => s.id == data.lastStep)
      newIndex = steps.findIndex((s) => s.id == stepId)
      if newIndex > prevIndex then stepId else data.lastStep
    else
      stepId

    checkInStore.lastStepId = data.lastStep
    return data

  getInitialData: ->
    this.state[this.dataName]

  # added an optional parameter of skipLinkFocus which is a boolean. if you pass
  # in the boolean true to the function leave, it will focus on the skip link
  # after the target page is loaded, if not, the typical focus will happen 
  leave: (event, skipLinkFocus) ->
    if this.isPreflight() && !authStore.isProxying
      state = 'thanks'
      to = '/appointments'
    else
      state = 'lock'
      to = '/checkin/auth'

    oldCheckInStore.actions.changeState(state).then(() =>
      checkInStore.portal = {}
      oldAuthStore.actions.endPreflight()
      apptsStore.forcedCheckinAppt = null
      this.context.router.push(to)
      if (skipLinkFocus) then document.querySelector('.SkipLink')?.focus()
    )

  renderCheckInFooter: (children) ->
    CheckInFooter = require('components/checkin/check-in-footer').default

    return React.createElement(CheckInFooter, {
      onNext: () =>
        data = this.getStepData()
        return if !data || this.state.errors.length

        oldCheckInStore.actions.update(data).then(() =>
          {next} = checkInStore.getStep()
          next.activate()
        )
    }, children)
