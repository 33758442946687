{div, h2, p, button, ul, li, tch, strong} = Exim.DOM

RecommendedEmailModal = Exim.createView module.id,
  render: ->
    div className: 'DependentSignUpModel--content',
      p null,
        'This address will be used for '
        'notification emails from Crossover.'
      p null,
        'Many employers re-use company email addresses for new employees with '
        ' similar names, so we strongly recommend you use your personal '
        ' email address for Sign In and Crossover communications.'
      p null,
        'No health data will be sent via email.'

      tch tagName: 'button', className: "Button Button--primary Button--large Button--expand", handler: @props.close, 'Close'

module.exports = RecommendedEmailModal
