"use strict";
const { editSlotOrSeries } = require("../../helpers");
const authStore = require("stores/new-auth");
const calStore = require("stores/cal");
const { mem, removeDragImage } = require("lib/utils");

const createDraggable = mem((slot) => {
  let memento;

  const movingSlotStart = (evt) => {
    let { user } = authStore;
    if (!user.canEditCalendar) {
      evt.preventDefault();
      alert("Write access is required to edit a slot.");
      return;
    }

    if (!slot.isFuture) {
      evt.preventDefault();
      alert("Unable to edit a slot in the past.");
      return;
    }

    removeDragImage(evt.dataTransfer);
    evt.dataTransfer.effectAllowed = "move";

    requestAnimationFrame(() => {
      memento = slot.saveState();
      calStore.moving = {
        startY: evt.offsetY,
        slot,
      };
    });
  };

  const movingSlotEnd = async (evt) => {
    let { slot } = calStore.moving;
    calStore.moving = null;

    let isCanceled = evt.dataTransfer.dropEffect == "none";
    if (isCanceled) {
      slot.restoreState(memento);
      return;
    }

    try {
      await editSlotOrSeries(memento, slot);
    } catch (err) {
      slot.restoreState(memento);

      if (err.name != "AbortError") {
        console.error(err);
        alert("Failed to edit the slot.");
      }
    }
  };

  let el = document.createElement("div");
  el.className = "ScheduleCalendarSlot-draggable";
  el.draggable = true;
  el.addEventListener("dragstart", movingSlotStart);
  el.addEventListener("dragend", movingSlotEnd);

  return el;
});

const SlotDraggable = ({ slot }) => {
  if (slot.isBooked) return null;

  const renderDraggable = (el) => {
    if (!el) return;

    el.textContent = "";
    el.append(createDraggable(slot));
  };

  return <div ref={renderDraggable} />;
};

module.exports = SlotDraggable;
