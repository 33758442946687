"use strict";

const resolvePath = (obj, path) => {
  return path
    .split(".")
    .filter((k) => k)
    .reduce((obj, key) => obj[key], obj);
};

class Sortable extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, props);
  }

  componentWillReceiveProps({ by, order }) {
    if (by != this.state.by) this.setState({ by });

    if (order != this.state.order) this.setState({ order });
  }

  changeSort(by) {
    let newState =
      by == this.state.by
        ? {
            order:
              this.state.order == "descending" ? "ascending" : "descending",
          }
        : { by };

    this.setState(newState);
  }

  fn(pathA, pathB) {
    let { by } = this.state;
    let a = resolvePath(pathA, by);
    let b = resolvePath(pathB, by);

    let res =
      typeof a == "string" && typeof b == "string" ? a.localeCompare(b) : a - b;

    return this.state.order == "ascending" ? res : -res;
  }

  render() {
    return React.cloneElement(this.props.children, {
      sort: {
        changeSort: this.changeSort.bind(this),
        fn: this.fn.bind(this),
        ...this.state,
      },
      ...this.props,
    });
  }
}

Sortable.defaultProps = {
  by: "",
  order: "ascending",
};

module.exports = Sortable;
