"use strict";
const { observer } = require("mobx-react");
const { ISO_DATE } = require("lib/formats");
const { compact } = require("lodash");
const { cx } = require("lib/utils");

const renderFull = (p) => {
  let full = compact([
    p.patientId && `#${p.patientId}`,
    p.employeeId,
    p.client,
  ]).join("\n");

  return <div className="PatientCard-full">{full}</div>;
};

const PatientCard = (props) => {
  let {
    patient: p,
    modifier,
    isFull,
    isSelected,
    isSelectable,
    ...rest
  } = props;

  let cardClass = cx("PatientCard", modifier, {
    inactive: !p.isActive,
    important: !!p.locked,
    asleep: p.isAsleep,
    accountLocked: p.isAccountLocked,
    selected: isSelected,
    selectable: isSelectable,
  });
  // Can't use <button> here because of Safari focus weirdness
  return (
    <div className={cardClass} tabIndex={isSelectable ? 0 : null} {...rest}>
      <div className="PatientCard-name">
        {p.isVIP && <span className="Label Label--vip">VIP</span>}
        {p.isXO && <span className="Label Label--xo">XO</span>}
        {p.migratedToXOP && <span className="xop-badge">XOP</span>}
        {p.legalAndPreferredName}
      </div>
      <div className="PatientCard-short">
        <time dateTime={p.bornAt.format(ISO_DATE)} title="Date of birth">
          {p.shortDob}
        </time>{" "}
        &bull;
        <span title="Age"> {p.shortAge}</span>
        <span title="Gender"> {p.shortGender}</span>
      </div>
      {isFull && renderFull(p)}
    </div>
  );
};

PatientCard.defaultProps = {
  isFull: true,
  isSelected: false,
  isSelectable: false,
};

module.exports = observer(PatientCard);
