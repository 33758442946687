"use strict";
import { Button, CheckBox } from "components/elements";
import { closeModal } from "components/elements/new-modal";
import marked from "marked";

const renderer = new marked.Renderer();
renderer.link = (href, title, text) =>
  `<a target="_blank" href="${href}" rel="noopener noreferrer" title="${title}">${text}</a>`;

class ConsentConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isAgreed: false };

    this.handleClose = this.handleClose.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  handleCheck() {
    this.setState({ isAgreed: !this.state.isAgreed });
  }

  handleClose() {
    const { onClose } = this.props;

    if (onClose) {
      closeModal();
      onClose();
    }
  }

  handleContinue() {
    const { onContinue } = this.props;

    if (this.state.isAgreed && onContinue) {
      closeModal();
      onContinue();
    }
  }

  render() {
    const consentText = marked(this.props.consentText, { renderer });

    return (
      <div className={`NewModal-wrapper`}>
        <div className={`NewModal-overlay`} style={{ background: "#A9A9A9" }} />
        <div className={`NewModal`} style={styles.wrapper} role="dialog">
          <button
            aria-label="Close"
            style={styles.close}
            onClick={this.handleClose}
          >
            X
          </button>
          <div style={styles.header}>Consent</div>
          <div style={{ ...styles.consent, ...styles.consentBorder }}>
            <div dangerouslySetInnerHTML={{ __html: consentText }} />
          </div>
          <div style={styles.checkbox}>
            <CheckBox
              name={""}
              value={this.state.isAgreed}
              onClick={this.handleCheck}
              required={true}
            >
              I accept the Health Risk Assessment Notice and Consent{" "}
              <strong>(required)</strong>
            </CheckBox>
          </div>
          <button
            style={this.state.isAgreed ? styles.button : styles.buttonDisabled}
            onClick={this.handleContinue}
          >
            CONTINUE
          </button>
        </div>
      </div>
    );
  }
}

module.exports = ConsentConfirmation;

const styles = {
  wrapper: {
    width: "600px",
    minHeight: "350px",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "rgba(244, 113, 38, 1)",
    borderRadius: "5px",
    textAlign: "center",
    wordWrap: "break-word",
  },

  header: {
    fontSize: "30px",
    margin: "20px 0",
    lineHeight: "normal",
    fontWeight: "250",
  },

  consent: {
    height: "130px",
    width: "100%",
    overflowY: "auto",
    padding: "5px",
    textAlign: "left",
    lineHeight: "normal",
    letterSpacing: "0.5px",
    font: "normal 100 14px ArialMT, Arial, sans-serif",
  },

  consentBorder: {
    border: "1px solid grey",
    borderRadius: "5px",
  },

  checkbox: {
    margin: "15px 0",
  },

  close: {
    top: "0",
    float: "right",
  },

  button: {
    width: "181px",
    height: "40px",
    margin: "15px 0",
    backgroundColor: "rgba(121, 166, 54, 1)",
    border: "1px solid rgba(170, 170, 170, 1)",
    borderRadius: "5px",
    color: "#FDF9F3",
    fontSize: "15px",
    textAlign: "center",
  },

  buttonDisabled: {
    width: "181px",
    height: "40px",
    margin: "15px 0",
    backgroundColor: "#696969",
    border: "1px solid rgba(170, 170, 170, 1)",
    borderRadius: "5px",
    color: "#FDF9F3",
    fontSize: "15px",
    textAlign: "center",
  },
};
