import checkInStore from "stores/new-check-in";
import { Spinner } from "components/elements";
import { observer } from "mobx-react";
import CheckInCard from "../check-in-card";
import oldCheckInStore from "stores/checkin";
import Step from "components/mixins/Step";
import confirm from "lib/confirm";

const WelcomeStep = React.createClass({
  displayName: "WelcomeStep",
  mixins: [Step],

  async handleBegin() {
    await oldCheckInStore.actions.changeState("checkin");
    checkInStore.getStep().next.activate();
  },

  async handleLeave() {
    let isSure = await confirm("Are you sure you want to quit?");
    if (isSure) this.leave();
  },

  renderBegin() {
    let { user } = checkInStore;

    return (
      <React.Fragment>
        <p id="check-in-welcome" className="CheckInCard-name">
          {user.name}
        </p>
        <button
          id="check-in-begin"
          className="Button Button--primary"
          onClick={this.handleBegin}
        >
          Begin Check-In
        </button>
      </React.Fragment>
    );
  },

  render() {
    return (
      <CheckInCard>
        <h1 className="CheckInCard-title">Welcome</h1>
        {checkInStore.isFetched ? this.renderBegin() : <Spinner />}
        <footer className="CheckInCard-footer">
          <span className="CheckInCard-notYou">Not you?</span>
          <button className="CheckInCard-leave" onClick={this.handleLeave}>
            Cancel Check-In
          </button>
        </footer>
      </CheckInCard>
    );
  },
});

export default observer(WelcomeStep);
