{appStore} = require 'stores'
SignInForm = require './SignInForm'
SignInResetForm = require './SignInResetForm'
Disclaimer = require 'components/auth/Disclaimer'
attachHeader = require 'components/mixins/attachHeader'
AuthWrapper = require 'components/auth/auth-wrapper'

{Link} = Exim.Router
{cx}   = Exim.helpers
{div, span, tch, fa}  = Exim.DOM

SignIn = Exim.createView module.id,
  staticView: true
  noHeader: true
  fadeIn: true
  mixins: [attachHeader(null)]
  listen: ['auth/error', 'auth/loggedIn', 'auth/oAuthProviderLinks']

  componentDidMount: ->
    appStore.actions.setTitle('Sign In')
    if window.utag 
      window.utag.view({
        page_name: "Crossover Health",
        platform: "Portal",
        customer_type: "",
        customer_efid: "",
        customer_class: "",
        customer_state: "",
        customer_postal_code: "",
        customer_last_name: "",
        customer_first_name: "",
        customer_email: "",
        customer_id: "",
        customer_country: "",
        customer_phone_number: "",
        user_logged_in: "0"
      })
    

  componentWillUnmount: ->
    appStore.actions.setTitle()

  render: ->
    {security} = appStore.get('config')

    return React.createElement(AuthWrapper, {title: 'Sign In To Your Account'},
      div className: 'AuthBlockGroup',
        div className: 'AuthCard AuthCard-SignIn',
          SignInForm {location: this.props.location}

        if Object.keys(security || {}).length
          Disclaimer()
    )

module.exports = SignIn
